import React, { useState, useEffect, useContext } from "react";
import AuthContext from "contexts/AuthContext";
import * as tagHelper from "../Helpers/tagHelper";

import { Select, Button } from "stories";

import { API, graphqlOperation } from "aws-amplify";
import {
  escalateConvo,
  getCampaignRunning,
  getCampaign,
  getFlowVersion,
} from "graphql/queries";
import {
  createCampaignManualSend,
  createChatbotConvoTag,
  createCampaignCompleted,
  deleteCampaignRunning,
  updateCampaignCompleted,
} from "graphql/mutations";

export default function DialogueActions(props) {
  const tokens = useContext(AuthContext);
  const [campaigns, setCampaigns] = useState([]);
  const [escalations, setEscalations] = useState([]);
  const [campResultMessage, setCampResultMessage] = useState(null);
  const [escResultMessage, setEscResultMessage] = useState(null);
  const [campaignSendDisabled, setCampaignSendDisabled] = useState(false);
  const [selectedEscalationId, setSelectedEscalationId] = useState(null);
  const [selectedCampaignId, setSelectedCampaignId] = useState(null);
  const [inProgressCampaignText, setInProgressCampaignText] = useState(
    "Checking..."
  );
  const [inProgressCampaignObject, setInProgressCampaignObject] = useState(
    null
  );

  const { convo } = props;

  useEffect(() => {
    console.log("AVAIL CAMPS: ", props.availableCampaigns);
    let campaignsOptions = formatOptions(props.availableCampaigns);
    if (convo.agentChatting || props.isOptedOutNow || props.isPausedNow) {
      setCampaignSendDisabled(true);
    }
    setCampaigns(campaignsOptions);
    setEscalations(props.escalationData);

    async function checkRunningCampaigns() {
      let resultDidError = false;
      let runningResp;

      try {
        let userId = convo.type === "sms" ? convo.phoneNumber : convo.asurite;
        runningResp = await API.graphql(
          graphqlOperation(getCampaignRunning, {
            userId: userId,
          })
        );
        console.log(runningResp);
      } catch (e) {
        console.log("failed getting camp: ", e);
        if (e && e.data && e.data.getCampaignRunning) {
          runningResp = e.data.getCampaignRunning;
        } else {
          resultDidError = true;
        }
      }

      if (!resultDidError) {
        runningResp = runningResp.data.getCampaignRunning;
        if (runningResp !== null) {
          runningResp.exitType = "stopped";
          delete runningResp.createdAt;
          delete runningResp.updatedAt;
          setInProgressCampaignObject(runningResp);
          setInProgressCampaignText("Stop Active Campaign");
        } else {
          setInProgressCampaignText("No Running Campaign");
        }
      } else {
        setInProgressCampaignText("No Running Campaign");
      }
    }

    checkRunningCampaigns();
  }, []);

  useEffect(() => {
    if (campResultMessage) {
      setTimeout(() => {
        setCampResultMessage(null);
      }, 5000);
    }
  }, [campResultMessage]);

  useEffect(() => {
    if (escResultMessage) {
      setTimeout(() => {
        setEscResultMessage(null);
      }, 5000);
    }
  }, [escResultMessage]);

  const formatOptions = (arr) => {
    let result = [];
    const campaignsRan = props.campaignsRan;

    console.log("CAMPS RAN: ", campaignsRan);
    let campaignsRanIds = [];

    if (campaignsRan.completed && campaignsRan.completed.length > 0) {
      campaignsRan.completed.forEach((camp) =>
        campaignsRanIds.push(camp.campaignId)
      );
    }
    if (campaignsRan.running) {
      campaignsRanIds.push(campaignsRan.running.campaignId);
    }
    // console.log(campaignsRanIds);

    arr.forEach((val) => {
      // if (!campaignsRanIds.includes(val.id)) {
      let option = { label: val.title, value: val.id };
      result.push(option);
      // }
    });

    result = result.sort(function(a, b) {
      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1;
      } else {
        return 1;
      }
    });
    return result;
  };

  const escalateConvoOnClick = async () => {
    if (selectedEscalationId) {
      setEscResultMessage({
        isError: true,
        text: "Escalation sending",
      });

      const payload = {
        id: selectedEscalationId,
        user: convo.type === "auth" ? convo.asurite : convo.phoneNumber,
        convoId: convo.convoId,
        isVisitor: convo.type === "visitor",
        escalator: tokens.asurite,
        hitType: "Conversation",
      };

      try {
        const escalatingConvo = await API.graphql(
          graphqlOperation(escalateConvo, JSON.stringify(payload))
        );

        setEscResultMessage({
          isError: false,
          text: "Escalation successfully sent!",
        });

        let payload2 = {
          convoId: convo.convoId,
          tag: `escalation ${selectedEscalationId}`,
        };

        await tagHelper.addTag(payload2, createChatbotConvoTag);
      } catch (e) {
        console.log(e);
        setEscResultMessage({
          isError: true,
          text: "Send escalation unsuccessful. Try again later.",
        });
      }
    } else {
      setEscResultMessage({
        isError: true,
        text: "Please select escalation before sending.",
      });
    }
  };

  const checkMatches = (treeCopy) => {
    // let treeCopy = JSON.parse(JSON.stringify(props.rawTree));
    let matchIds = [];
    let nodes = {};

    for (var i = 0; i < treeCopy.length; ++i) {
      nodes[treeCopy[i].id] = treeCopy[i].id;
      if (treeCopy[i].matches && treeCopy[i].matches.length > 0) {
        for (var j = 0; j < treeCopy[i].matches.length; ++j) {
          matchIds.push(treeCopy[i].matches[j].id);
        }
      }
    }

    let noConnectingNodes = [];
    for (var i = 0; i < matchIds.length; ++i) {
      if (!nodes[matchIds[i]]) {
        noConnectingNodes.push(matchIds[i]);
      }
    }

    return noConnectingNodes;
  };

  const verifyFlow = async (camp) => {
    let flow = JSON.parse(camp.flow).value;
    let flowVersion = JSON.parse(camp.flowVersion).value;

    let queryResp = await API.graphql(
      graphqlOperation(getFlowVersion, {
        id: flow,
        version: flowVersion,
      })
    );

    let flowInfo = queryResp.data.getFlowVersion;

    let nodes = checkMatches(JSON.parse(flowInfo.flowNodes));
    return nodes.length === 0;
  };

  const manualSendCampaignOnClick = async () => {
    let matchedCampaign = null;
    for (let camp of props.availableCampaigns) {
      if (camp.id === selectedCampaignId) {
        matchedCampaign = camp;
      }
    }

    if (matchedCampaign && selectedCampaignId) {
      let validFlow = await verifyFlow(matchedCampaign);

      if (validFlow) {
        const currentDate = new Date();
        const currentDatePlus3Hours = new Date(currentDate).setHours(
          currentDate.getHours() + 3
        );

        const payload = {
          campaignId: selectedCampaignId,
          status: 4,
          flow: matchedCampaign.flow,
          flowVersion: matchedCampaign.flowVersion,
          audienceFileContent: JSON.stringify({
            type: convo.type,
            data: [convo.type !== "sms" ? convo.asurite : convo.phoneNumber],
          }),
          startTime: currentDate.valueOf(),
          endTime: currentDatePlus3Hours,
          createdBy: tokens.asurite,
          type: JSON.stringify({ value: convo.type }),
          group: matchedCampaign.group,
        };

        try {
          let manualSendCampaign = await API.graphql(
            graphqlOperation(createCampaignManualSend, { input: payload })
          );

          for (let i = campaigns.length - 1; i >= campaigns.length; --i) {
            if (campaigns[i].value === selectedCampaignId) {
              campaigns.splice(i, 1);
            }
          }

          // props.refetchManualSendCampaigns(convo);
          setCampaigns(campaigns);
          setSelectedCampaignId(null);
          setInProgressCampaignObject(matchedCampaign);
          setCampaignSendDisabled(true);
          setInProgressCampaignText("Stop Active Campaign");
          setCampResultMessage({
            isError: false,
            text: "Campaign successfully sent!",
          });
        } catch (e) {
          setCampResultMessage({
            isError: true,
            text: "Send campaign unsuccessful. Try again later.",
          });
          console.log(e);
        }
      } else {
        setSelectedCampaignId(null);
        setCampResultMessage({
          isError: true,
          text: "Send campaign unsuccessful. Flow is not valid.",
        });
      }
    }
  };

  const stopUserCampaign = async () => {
    let campaignInfo = null;
    try {
      let campaignInfoResp = await API.graphql(
        graphqlOperation(getCampaign, {
          id: inProgressCampaignObject.campaignId,
        })
      );

      campaignInfoResp = campaignInfoResp.data.getCampaign;
      if (campaignInfoResp !== null) {
        campaignInfo = campaignInfoResp;
      }
    } catch (e) {
      console.log("campinfo: ", e);
    }

    let success = true;

    console.log("hitting stop user", campaignInfo, inProgressCampaignObject);

    if (
      campaignInfo !== null &&
      typeof campaignInfo.endTime !== "undefined" &&
      typeof campaignInfo.startTime !== "undefined"
    ) {
      let myNewCampaignObject = Object.assign(inProgressCampaignObject, {});
      myNewCampaignObject.finishTime = campaignInfo.endTime;
      myNewCampaignObject.expireTime = new Date().getTime() + "";
      myNewCampaignObject.startTime = campaignInfo.startTime;
      delete myNewCampaignObject.lastNode;
      myNewCampaignObject.asurite = convo.asurite;
      myNewCampaignObject.phoneNumber = convo.phoneNumber;

      console.log("writing to completed: ", myNewCampaignObject);
      try {
        await API.graphql(
          graphqlOperation(createCampaignCompleted, {
            input: myNewCampaignObject,
          })
        );
      } catch (e) {
        try {
          await API.graphql(
            graphqlOperation(updateCampaignCompleted, {
              input: myNewCampaignObject,
            })
          );
        } catch (e) {
          console.log("fai;ed update:", e);
        }
      }
    }

    try {
      let resp = await API.graphql(
        graphqlOperation(deleteCampaignRunning, {
          input: { userId: inProgressCampaignObject.userId },
        })
      );
      console.log("DEL: ", resp);
    } catch (error) {
      console.log(
        "deleteCampaignRunning error: ",
        error,
        inProgressCampaignObject
      );
      success = false;
    }

    if (success) {
      setInProgressCampaignText("Campaign Stopped");
    }
  };

  return (
    <div>
      <div style={styles.actionContainer}>
        <h2 style={{ margin: 0 }}>Escalation</h2>
        <p>Select flow below to escalate.</p>
        <Select
          //   value={selectedEscalationId}
          onChange={(val) => {
            setSelectedEscalationId(val);
          }}
          options={escalations}
        />
        <Button
          text="SEND NOW"
          style={styles.button}
          onClick={escalateConvoOnClick}
        />
        {escResultMessage && (
          <p style={{ color: escResultMessage.isError ? "red" : "green" }}>
            {escResultMessage.text}
          </p>
        )}
      </div>
      <div
        style={{
          ...(!campResultMessage
            ? styles.actionContainer
            : styles.actionContainerExtended),
          ...{ borderBottomWidth: 0 },
        }}
      >
        <h2 style={{ margin: 0 }}>Campaigns</h2>
        <p>Select a previously created Campaign to send to this Contact.</p>
        <Select
          onChange={(val) => {
            setSelectedCampaignId(val);
          }}
          options={campaigns}
          isDisabled={campaignSendDisabled}
        />
        <Button
          text="SEND NOW"
          onClick={manualSendCampaignOnClick}
          style={campaignSendDisabled ? styles.disabled : styles.button}
          disabled={campaignSendDisabled}
        />
        {campResultMessage && (
          <p style={{ color: campResultMessage.isError ? "red" : "green" }}>
            {campResultMessage.text}
          </p>
        )}
      </div>

      <div
        style={{
          borderBottom: "2px solid rgb(242, 242, 242)",
          marginBottom: "0.8em",
        }}
      >
        <div style={{ marginBottom: "0.8em" }}>Stop in-progress campaign:</div>

        <Button
          text={inProgressCampaignText}
          onClick={stopUserCampaign}
          style={{
            ...(inProgressCampaignText !== "Stop Active Campaign"
              ? inProgressCampaignText === "Campaign Stopped"
                ? styles.stopped
                : styles.disabled
              : styles.button),
            ...{ width: "auto" },
          }}
          disabled={inProgressCampaignText !== "Stop Active Campaign"}
        />
      </div>
    </div>
  );
}

const styles = {
  button: { background: "#3F88DF", color: "white", marginBottom: 20 },
  stopped: {
    opacity: 1,
    background: "rgb(193, 192, 191)",
    color: "#000",
    marginBottom: 20,
    fontWeight: "normal",
  },
  disabled: {
    opacity: 0.5,
    background: "#3F88DF",
    color: "white",
    marginBottom: 20,
  },
  actionContainer: {
    marginTop: 10,
    display: "flex",
    flexDirection: "column",
    height: 200,
    justifyContent: "space-around",
    borderBottom: "2px solid #f2f2f2",
  },
  actionContainerExtended: {
    marginTop: 10,
    display: "flex",
    flexDirection: "column",
    height: 230,
    justifyContent: "space-around",
    borderBottom: "2px solid #f2f2f2",
  },
};
