import React from "react";
import "./ItemNumberDisplay.css";

export default function ItemNumberDisplay(props) {
  return (
    <div className="overview-row">
      <div className="overview-row-item">{props.label}</div>
      <div className="overview-row-item overview-row-item--right">
        {props.value}
      </div>
    </div>
  );
}
