import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { useTheme } from '@material-ui/core/styles';
import { API, graphqlOperation } from 'aws-amplify';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import PaginationPanel from 'stories/Table/PaginationPanel';
import { SectionHeader } from 'components/SectionHeader';
import { listUsers, searchUsers } from 'graphql/queries';
import * as mutations from 'graphql/mutations';
import AuthContext from 'contexts/AuthContext';
import { store } from 'contexts/GlobalStore';
import { CreateNewButton, Table } from 'stories';
import { roleCheck } from 'services/utility/RoleMatrix';
import { PanelButton } from 'stories';
import { getUserGroups } from 'hooks';
import moment from 'moment';
import './UsersGroups.css';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function UsersGroups(props) {
  const tokens = useContext(AuthContext);
  const theme = useTheme();
  const GlobalState = useContext(store);
  const { dispatch } = GlobalState;
  const { tableDataEmpty, globalTableState } = GlobalState.state;

  const [pageLimit] = useState(7);
  const [totalResults, setTotalResults] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [prevPageClicked, setPrevPageClicked] = useState(false);
  const [rerenderKey, setRerenderKey] = useState(Date.now());
  const { keyedNames, selectListGroups, getGroup, benjiId } = getUserGroups();
  const [filter, setFilter] = useState('All');
  const [lastSortLabel, setLastSortLabel] = useState(null);
  const [sortDirection, setSortDirection] = useState(-1);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchActive, setSearchActive] = useState(false);

  const [editClicked, setEditClicked] = useState(false);
  const [data, setData] = useState([]);
  const [dataForFilter, setDataForFilter] = useState([]);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [rawUserData, setRawUserData] = useState([]);
  const [open, setOpen] = useState(false);

  const [loadedTableState, setLoadedTableState] = useState(false);
  const [loadedTableSearchState, setLoadedTableSearchState] = useState(false);

  useEffect(() => {
    dispatch({
      type: 'setTableDataEmpty',
      tableDataEmpty: false,
    });
  }, []);

  useEffect(() => {
    const indexOfLastRecord = currentPage * pageLimit; //7
    const indexOfFirstRecord = indexOfLastRecord - pageLimit; //0
    const currentPageRecords = data.slice(
      indexOfFirstRecord,
      indexOfLastRecord
    );

    setCurrentPageData(currentPageRecords);
  }, [data, currentPage]);

  useEffect(() => {
    dispatch({ type: 'updateEditInfo', editInfo: { empty: true } });
    fetchUsers();
  }, [dispatch, rerenderKey, filter]);

  useEffect(() => {
    if (tableDataEmpty) {
      dispatch({
        type: 'setLoadingTableData',
        loadingTableData: false,
      });
    } else if (!tableDataEmpty && !loadedTableState) {
      const pathName = window.location.pathname.replace('/', '');
      let storedPage =
        globalTableState &&
        globalTableState[pathName] &&
        globalTableState[pathName].pageNumber;
      if (storedPage) {
        setCurrentPage(storedPage);
        setLoadedTableState(true);
      }
    }
  }, [tableDataEmpty]);

  useEffect(() => {
    const pathName = window.location.pathname.replace('/', '');
    let newGlobalTableState = Object.assign({}, globalTableState);
    if (!newGlobalTableState[pathName]) {
      newGlobalTableState[pathName] = {};
    }
    newGlobalTableState[pathName].pageNumber = currentPage;
    dispatch({
      type: 'setGlobalTableState',
      globalTableState: newGlobalTableState,
    });
  }, [currentPage]);

  /*const fetchData = async (searchText, filter, sort) => {
    if (tableDataEmpty) {
      dispatch({
        type: "setLoadingTableData",
        loadingTableData: false,
      });
    } else {
      dispatch({
        type: "setLoadingTableData",
        loadingTableData: true,
      });
    }
    try {
      let sortQuery = { field: "timestamp", direction: "desc" };
      if (sort) {
        sortQuery = { field: sort.field, direction: sort.direction };
      }
     } catch (error) {
        console.log("users fetch data error: ", error);
      }
  };*/

  /*const createFetchData = () => {
    if (searchActive && filter !== "All" && tableSort) {
      fetchData(searchKeyword, filter, tableSort);
    } else if (searchActive && filter !== "All" && !tableSort) {
      fetchData(searchKeyword, filter, null);
    } else if (searchActive && filter === "All" && tableSort) {
      fetchData(searchKeyword, null, tableSort);
    } else if (searchKeyword === "" && filter !== "All" && tableSort) {
      fetchData(null, filter, tableSort);
    } else if (searchActive) {
      fetchData(searchKeyword);
    } else if (filter !== "All") {
      fetchData(null, filter);
    } else if (tableSort) {
      fetchData(null, null, tableSort);
    } else {
      fetchData();
    }
  };*/

  const fetchUsers = async searchKeyword => {
    //restore previous search
    const pathName = window.location.pathname.replace('/', '');
    let localSearchActive = searchActive;
    let localSearchKeyword = searchKeyword;
    if (
      !loadedTableSearchState &&
      globalTableState &&
      globalTableState[pathName] &&
      globalTableState[pathName].search !== ''
    ) {
      localSearchActive = true;
      localSearchKeyword = globalTableState[pathName].search;
      setSearchActive(true);
      setSearchKeyword(globalTableState[pathName].search);
    }
    setLoadedTableSearchState(true);

    console.log(localSearchKeyword, Boolean(localSearchKeyword));

    if (localSearchKeyword) {
      onClickSearch(localSearchKeyword);
      return;
    }

    if (tableDataEmpty) {
      dispatch({
        type: 'setLoadingTableData',
        loadingTableData: false,
      });
    } else {
      dispatch({
        type: 'setLoadingTableData',
        loadingTableData: true,
      });
    }
    try {
      const params = {
        limit: 9999999,
        filter: { isActive: { eq: true } },
      };

      /*if (typeof searchKeyword === "string" && searchKeyword !== "") {
        params.filter.displayName = { matchPhrasePrefix: searchKeyword };
      } else {
        //console.log("!!! not adding searchKeyword");
      }*/

      const result = await API.graphql(graphqlOperation(listUsers, params));

      const rawUserData = result.data.listUsers.items;

      setRawUserData(rawUserData);

      const parseOutData = () => {
        let parsedData = parseData(rawUserData);

        // console.log(rawUserData)
        // console.log(parsedData);
        setTotalResults(parsedData.length);
        setData(parsedData);
        setDataForFilter(parsedData);
      };
      parseOutData();

      dispatch({
        type: 'setLoadingTableData',
        loadingTableData: false,
      });
    } catch (error) {
      console.log('Users fetch data error: ', error);
    } finally {
      tableDataEmpty &&
        dispatch({
          type: 'setLoadingTableData',
          loadingTableData: false,
        });
    }
  };

  const lib__buildCSVData = rawUserData => {
    let csvData = [['asurite', 'displayName', 'groups', 'role']];

    rawUserData.forEach(object => {
      csvData.push([
        object.asurite,
        object.displayName,
        object.groups,
        object.role,
      ]);
    });

    return csvData;
  };

  const parseData = data => {
    let modifiedData = data.map(el => {
      let name = '';
      if (el.parentCategory && el.subcategoryNames) {
        name = `${el.parentCategory.title}: ${el.subcategoryNames[0]}`;
      }

      let grps = [];

      for (var i = 0; i < el.groups.length; ++i) {
        let n = keyedNames[el.groups[i]]
          ? keyedNames[el.groups[i]]
          : el.groups[i];
        grps.push(n);
      }

      // user is tier1
      el.role = el.role + (el.tier1Support ? '+' : '');

      // capitalize roles
      el.role = el.role.substring(0, 1).toUpperCase() + el.role.substring(1);

      //console.log("USER:", grps);
      return {
        asurite: el.asurite,
        displayName: el.displayName,
        role: el.role,
        groups: grps.join(', '),
        actions: {
          edit: roleCheck(tokens.role, 'users', 'edit'),
          delete: roleCheck(tokens.role, 'users', 'delete'),
        },
      };
    });

    //Remove Benji
    modifiedData = modifiedData.filter(
      el =>
        !el.groups.split(',').includes(benjiId) &&
        !el.groups.split(',').includes('Benji')
    );

    return modifiedData;
  };

  const onSubmitDelete = async e => {
    let userId;
    rawUserData.forEach(el => {
      if (el.asurite === e.asurite) {
        userId = el.id;
      }
    });

    const userToDelete = {};
    userToDelete.editedBy = tokens.asurite;
    userToDelete.editedAt = Date.now();
    userToDelete.isActive = false;
    userToDelete.id = userId;

    try {
      await API.graphql(
        graphqlOperation(mutations.updateUser, {
          input: userToDelete,
        })
      );
    } catch (error) {
      console.log('updateUser error: ', error);
    } finally {
      fetchUsers();
    }

    // toggle();
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const onClickEdit = el => {
    el.groups = el.groups.split(', ');

    if (el.role === 'admin') {
      el.role = 'Admin';
    } else if (el.role === 'publisher') {
      el.role = 'Publisher';
    } else if (el.role === 'approver') {
      el.role = 'Approver';
    } else if (el.role === 'content creator') {
      el.role = 'Content Creator';
    }

    let userToEdit;

    rawUserData.forEach(user => {
      if (el.asurite === user.asurite) {
        userToEdit = user;
      }
    });

    dispatch({ type: 'updateEditInfo', editInfo: { ...userToEdit } });
    dispatch({ type: 'setFormTitle', formTitle: 'EDIT USER' });
    props.history.push({
      pathname: '/user-add',
    });
  };

  const onClickFetchMore = () => {
    setPrevPageClicked(false);
    if (currentPage === Math.ceil(data.length / pageLimit)) {
      return;
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const onClickFetchLess = async () => {
    setPrevPageClicked(true);
    if (currentPage <= 1) {
      setCurrentPage(1);
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const onChangeJumpToPage = pageNumber => {
    setCurrentPage(pageNumber);
  };

  const jumpToPageOptions = () => {
    const totalPages = Math.ceil(data.length / pageLimit);
    const options = [];

    for (let i = 1; i <= data.length; i++) {
      options.push(i);
    }
    const mappedOptions = options.map(el => {
      return { value: el, label: el };
    });
    return mappedOptions;
  };

  const onClickCreateNew = () => {
    dispatch({ type: 'setFormTitle', formTitle: 'ADD USER' });
    dispatch({
      type: 'changeTableSort',
      tableSort: null,
    });
    dispatch({ type: 'changeTableFilter', tableFilter: 'All' });
  };

  const sortLocal = label => {
    changeTableSort(label);
  };

  const changeTableSort = label => {
    if (label !== null) {
      let currentSortDirection = sortDirection;
      /* set sort direction */
      if (lastSortLabel !== label) {
        currentSortDirection = -1;
        setSortDirection(-1);
        setLastSortLabel(label);
      } else {
        currentSortDirection = 1;
        setSortDirection(1);
        setLastSortLabel(null);
      }
      /* get sort key */
      const sortKey =
        label.toLowerCase() === 'name' ? 'displayName' : label.toLowerCase();

      const newData = Array.from(data);
      newData.sort((a, b) => {
        if (a[sortKey] < b[sortKey]) {
          return 1 * currentSortDirection;
        } else if (a[sortKey] === b[sortKey]) {
          return 0;
        } else {
          return -1 * currentSortDirection;
        }
      });
      setData(newData);
    }
  };

  const onChangeSearch = value => {
    setSearchKeyword(value);
  };

  const onClickSearch = async localSearchKeyword => {
    if (searchKeyword === '' && !localSearchKeyword) {
      return;
    } else {
      if (searchKeyword) {
        localSearchKeyword = searchKeyword;
      }
      dispatch({
        type: 'setLoadingTableData',
        loadingTableData: true,
      });

      // Search by Name and ASURITE

      let filterQuery = { isActive: { eq: true } };

      filterQuery.or = [
        { displayName: { matchPhrasePrefix: localSearchKeyword } },
        { asurite: { matchPhrasePrefix: localSearchKeyword } },
      ];

      let result = await API.graphql(
        graphqlOperation(searchUsers, {
          filter: filterQuery,
        })
      );

      let parsedData = parseData(result.data.searchUsers.items);

      if (parsedData.length === 0) {
        // search locally

        let newData;

        if (localSearchKeyword !== '') {
          newData = dataForFilter.filter((object, index) => {
            if (
              object.displayName
                .toLowerCase()
                .includes(localSearchKeyword.toLowerCase()) ||
              object.asurite
                .toLowerCase()
                .includes(localSearchKeyword.toLowerCase())
            ) {
              return true;
            }
          });

          setData(newData);
        }
      } else {
        setData(parsedData);
      }

      dispatch({
        type: 'setLoadingTableData',
        loadingTableData: false,
      });

      if (currentPage === 1) {
      } else {
        setCurrentPage(1);
      }
    }
  };

  const onClickReset = () => {
    setSearchKeyword('');
    dispatch({
      type: 'changeTableSort',
      tableSort: null,
    });

    if (currentPage === 1) {
    } else {
      setCurrentPage(1);
    }
    setRerenderKey(Date.now());
  };

  const onSelectFilter = async value => {
    if (value) {
      /*const newData = dataForFilter.filter((object, index) => {
        if (object.role.toLowerCase().includes(value.toLowerCase())) {
          return true;
        }
      });
      setData(newData);*/

      dispatch({
        type: 'setLoadingTableData',
        loadingTableData: true,
      });

      // Search by Role

      let filterQuery = { isActive: { eq: true } };

      filterQuery.role = { eq: value };

      let result = await API.graphql(
        graphqlOperation(searchUsers, {
          filter: filterQuery,
        })
      );

      let parsedData = parseData(result.data.searchUsers.items);

      setData(parsedData);

      dispatch({
        type: 'setLoadingTableData',
        loadingTableData: false,
      });

      if (currentPage === 1) {
      } else {
        setCurrentPage(1);
      }
    }
  };

  const onSelectFilter2 = async value => {
    let groupLabel; //matching label
    selectListGroups.some((object, index) => {
      if (object.value === value) {
        groupLabel = object.label;
        return true;
      }
    });
    if (value) {
      setCurrentPage(1);
      const newData = dataForFilter.filter((object, index) => {
        console.log(object.groups, value);
        if (
          object.groups.toLowerCase().includes(value.toLowerCase()) ||
          object.groups.toLowerCase().includes(groupLabel.toLowerCase())
        ) {
          return true;
        }
      });
      setData(newData);
    }
  };

  const filterOptions = [
    { value: 'admin', label: 'admin' },
    { value: 'publisher', label: 'publisher' },
    { value: 'content creator', label: 'content creator' },
    { value: 'approver', label: 'approver' },
    { value: 'chat agent', label: 'chat agent' },
  ];

  const tableLabels = [
    { title: 'Asurite' },
    { title: 'Name' },
    { title: 'Role' },
    { title: 'Groups' },
    { title: 'Actions', alignRight: true, notClickable: true },
  ];

  return (
    <div style={theme.components.pageContainer} key={rerenderKey}>
      <div className="SectionHeader">
        <SectionHeader
          onChangeSearch={onChangeSearch}
          onClickSearch={onClickSearch}
          onClickReset={onClickReset}
          onSelectFilter={onSelectFilter}
          filterOptions={filterOptions}
          noSort
          noModality
          noGroup
          rightView={
            <>
              <div className="ExportButton">
                <PanelButton
                  type="csv"
                  csvData={lib__buildCSVData(rawUserData)}
                  csvFileName={`chatbotUsers-${moment().format()}.csv`}
                  label="Export Data"
                />
              </div>
              {roleCheck(tokens.role, 'users', 'create') && (
                <button class="CreateUser">
                  <div onClick={onClickCreateNew}>
                    <CreateNewButton
                      to="/user-add"
                      label="Create New User"
                      noWrap={true}
                    />
                  </div>
                </button>
              )}
            </>
          }
          rightViewStyle={{ width: '23em', flexFlow: 'row' }}
        />
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          User has been deleted successfully!
        </Alert>
      </Snackbar>
      <div className="TablePage">
        <Paper style={theme.components.paper}>
          <div
            style={{
              width: '100%',
              borderLeft: `1px solid ${theme.palette.general.mediumGrey}`,
              paddingBottom: '2rem',
              ...props.style,
            }}
          >
            <Table
              title="Users Table"
              labels={tableLabels}
              data={currentPageData}
              onSubmitDelete={onSubmitDelete}
              onClickEdit={onClickEdit}
              filterOptions={filterOptions}
              onChangeSearch={onChangeSearch}
              onClickSearch={onClickSearch}
              onClickReset={onClickReset}
              onSelectFilter={onSelectFilter}
              filterOptions2={selectListGroups}
              onSelectFilter2={onSelectFilter2}
              filterButtonText={'Filter Role'}
              filterButtonText2={'Filter Group'}
              //getSortKey={getSortKey}
              defaultSortKey="asurite"
              noSort
              sortLocal={sortLocal}
              // noFilter
              noSearch
            />
            <PaginationPanel
              theme={theme}
              currentPage={currentPage}
              pageCount={Math.ceil(data.length / pageLimit) || 1}
              onClickFetchMore={onClickFetchMore}
              onClickFetchLess={onClickFetchLess}
              jumpToPageOptions={jumpToPageOptions}
              onChangeJumpToPage={onChangeJumpToPage}
            />
          </div>
        </Paper>
      </div>
    </div>
  );
}

UsersGroups.propTypes = {
  navigation: PropTypes.object,
};
