const SCROLL_AMT = 0.02;
const ZOOM_AMT = 0.2;
const MAX_ZOOM_AMT = 2;
const MIN_ZOOM_AMT = 0.4;

export function wheel(e,cZoom) {

  let amt = parseFloat(cZoom);
  if (e.deltaY < 0) {
    amt += SCROLL_AMT;
  } else {
    amt -= SCROLL_AMT;
  }

  e.preventDefault();
  amt = amt.toFixed(2);
  return approveAmt(amt,cZoom);

}

export function zoomUtil(t,cZoom) {
  let amt = parseFloat(cZoom);

  if (t === "in") {
    amt += ZOOM_AMT;
  } else {
    amt -= ZOOM_AMT;
  }

  amt = amt.toFixed(2);
  return approveAmt(amt,cZoom);
}

export function approveAmt(amt,cZoom) {

  let zoomUnavail = null;

  if (amt > MAX_ZOOM_AMT) {
    zoomUnavail = "in";
    amt = cZoom;
  } else if (amt < MIN_ZOOM_AMT) {
    zoomUnavail = "out";
    amt = cZoom;
  }

  if( !zoomUnavail ) {
    if( parseFloat(amt) === MAX_ZOOM_AMT ) {
      zoomUnavail = "in";
    } else if( parseFloat(amt) === MIN_ZOOM_AMT ) {
      zoomUnavail = "out";
    }
  }

  return {
    amt: amt,
    zoomUnavail: zoomUnavail
  }

};
