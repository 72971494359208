import React from 'react';

// import { ReactComponent as JumpIcon } from "../tempIcons/redo-alt-solid.svg";
// import { ReactComponent as BranchIcon } from "../tempIcons/call_split-24px.svg";
// import { ReactComponent as TextIcon } from "../tempIcons/comment-alt-regular.svg";
// import { ReactComponent as PromptIcon } from "../tempIcons/question-circle-regular.svg";
// import { ReactComponent as TriggerIcon } from "../tempIcons/bolt-solid.svg";

import AccountTreeIcon from '@material-ui/icons/AccountTree';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import ReplayIcon from '@material-ui/icons/Replay';
import SaveIcon from '@material-ui/icons/Save';

import TextProperties from './Sections/TextProperties';
import PromptProperties from './Sections/PromptProperties';
import BranchProperties from './Sections/BranchProperties';
import JumpProperties from './Sections/JumpProperties';
import TriggerProperties from './Sections/TriggerProperties';
import IfThenProperties from './Sections/IfThenProperties';
import RunSunny from './Sections/RunSunny';

let styleOptions = {
  height: '70%',
  width: '70%',
};

export var allNodeOptions = [
  {
    type: 'text',
    name: 'text',
    group: 'base',
    info: 'Text nodes are used to display basic text information',
    showId: true,
    icon: <ChatBubbleOutlineIcon style={{ ...styleOptions }} />,
    section: <TextProperties />,
    bots: ['sunny'],
  },
  {
    type: 'prompt',
    name: 'prompt',
    group: 'base',
    info:
      'Prompt nodes are simple text nodes that ask an open ended question. Answers are collected and sent back to the server.',
    showId: true,
    icon: <HelpOutlineIcon style={{ ...styleOptions }} />,
    section: <PromptProperties />,
    bots: ['sunny'],
  },
  {
    type: 'branch',
    name: 'branch',
    group: 'base',
    info:
      'Branch nodes can be used to create conditional logic based on a question.',
    showId: true,
    icon: <CallSplitIcon style={{ ...styleOptions }} />,
    section: <BranchProperties />,
    bots: ['sunny'],
  },
  {
    type: 'jump',
    name: 'jump',
    group: 'base',
    info:
      'Jumps are actions that allow you to jump to different parts of your flow, or external flows, by specifying a node ID',
    showId: false,
    icon: (
      <ReplayIcon style={{ ...styleOptions, transform: 'rotateY(180deg)' }} />
    ),
    section: <JumpProperties />,
    bots: ['sunny'],
  },
  {
    type: 'trigger',
    name: 'trigger',
    group: 'base',
    info:
      'Triggers will kick off an escalation. The flow can end or continue after this happens.',
    showId: false,
    icon: <FlashOnIcon style={{ ...styleOptions }} />,
    section: <TriggerProperties />,
    bots: ['sunny'],
  },
  {
    type: 'ifthen',
    name: 'If/Then',
    group: 'base',
    info: 'Will split based on variable value',
    showId: false,
    children: 'ifthen',
    icon: <AccountTreeIcon style={{ ...styleOptions }} />,
    bots: ['sunny'],
  },
  {
    type: 'ifthen-value',
    name: 'value',
    group: 'ifthen',
    customType: 'values',
    info: 'Split based on exact value',
    showId: false,
    icon: <AccountTreeIcon style={{ ...styleOptions }} />,
    section: <IfThenProperties />,
    bots: ['sunny'],
  },
  {
    type: 'ifthen-range-number',
    name: 'Number Range',
    customType: 'number',
    group: 'ifthen',
    info: 'Split based on range of numbers',
    showId: false,
    icon: <AccountTreeIcon style={{ ...styleOptions }} />,
    section: <IfThenProperties />,
    bots: ['sunny'],
  },
  {
    type: 'ifthen-range-letter',
    name: 'Letter Range',
    group: 'ifthen',
    customType: 'text',
    info: 'Split based on range of letters',
    showId: false,
    icon: <AccountTreeIcon style={{ ...styleOptions }} />,
    section: <IfThenProperties />,
    bots: ['sunny'],
  },
  {
    type: 'ifthen-existsnot',
    name: 'exists',
    group: 'ifthen',
    customType: 'existsnot',
    info: 'Split if a value exists or not',
    showId: false,
    icon: <AccountTreeIcon style={{ ...styleOptions }} />,
    section: <IfThenProperties />,
    bots: ['sunny'],
  },
  {
    type: 'ifthen-hasvalue', // DO NOT CHANGE
    name: 'Has Value', // ONLY CHANGE THIS FOR NAMING!!
    group: 'ifthen', // DO NOT CHANGE
    customType: 'hasvalue', // DO NOT CHANGE
    info: 'Split if variable is in array of values', // SUBTEXT CAN BE CHANGED
    showId: false,
    icon: <AccountTreeIcon style={{ ...styleOptions }} />,
    section: <IfThenProperties />,
    bots: ['sunny'],
  },
  {
    type: 'runBot',
    showId: false,
    section: <RunSunny />,
  },
];

export function getSingleNode(t) {
  for (var i = 0; i < allNodeOptions.length; ++i) {
    if (t === allNodeOptions[i].type) {
      return allNodeOptions[i];
    }
  }
  return {};
}
