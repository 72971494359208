/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCampaign = /* GraphQL */ `
  mutation CreateCampaign($input: CreateCampaignInput!) {
    createCampaign(input: $input) {
      id
      title
      titleKey
      description
      status
      statusKey
      audienceTargetType
      audience
      audienceFileContent
      audienceFileName
      fileUploadedBy
      flow
      flowVersion
      introFlow
      introFlowId
      introFlowVersion
      processing
      processingError
      startTime
      sendNumbers
      startTimeKey
      endTime
      endTimeKey
      timestamp
      timestampKey
      createdBy
      paused
      dryRun
      campaignTagAnswer
      campaignSelect
      pausedBy {
        asurite
        timestamp
      }
      approvedBy {
        asurite
        timestamp
      }
      type
      editedBy {
        asurite
        timestamp
      }
      deleted
      deletedBy {
        asurite
        timestamp
      }
      active
      group
      groupKey
      isSendingIntroMessage
      estSendMinutes
      smsButtonPresser
      smsButtonMsg
      numberProcessed
      numberToProcess
      createdAt
      updatedAt
    }
  }
`;
export const updateCampaign = /* GraphQL */ `
  mutation UpdateCampaign($input: UpdateCampaignInput!) {
    updateCampaign(input: $input) {
      id
      title
      titleKey
      description
      status
      statusKey
      audienceTargetType
      audience
      audienceFileContent
      audienceFileName
      fileUploadedBy
      flow
      flowVersion
      introFlow
      introFlowId
      introFlowVersion
      processing
      processingError
      startTime
      sendNumbers
      startTimeKey
      endTime
      endTimeKey
      timestamp
      timestampKey
      createdBy
      paused
      dryRun
      campaignTagAnswer
      campaignSelect
      pausedBy {
        asurite
        timestamp
      }
      approvedBy {
        asurite
        timestamp
      }
      type
      editedBy {
        asurite
        timestamp
      }
      deleted
      deletedBy {
        asurite
        timestamp
      }
      active
      group
      groupKey
      isSendingIntroMessage
      estSendMinutes
      smsButtonPresser
      smsButtonMsg
      numberProcessed
      numberToProcess
      createdAt
      updatedAt
    }
  }
`;
export const deleteCampaign = /* GraphQL */ `
  mutation DeleteCampaign($input: DeleteCampaignInput!) {
    deleteCampaign(input: $input) {
      id
      title
      titleKey
      description
      status
      statusKey
      audienceTargetType
      audience
      audienceFileContent
      audienceFileName
      fileUploadedBy
      flow
      flowVersion
      introFlow
      introFlowId
      introFlowVersion
      processing
      processingError
      startTime
      sendNumbers
      startTimeKey
      endTime
      endTimeKey
      timestamp
      timestampKey
      createdBy
      paused
      dryRun
      campaignTagAnswer
      campaignSelect
      pausedBy {
        asurite
        timestamp
      }
      approvedBy {
        asurite
        timestamp
      }
      type
      editedBy {
        asurite
        timestamp
      }
      deleted
      deletedBy {
        asurite
        timestamp
      }
      active
      group
      groupKey
      isSendingIntroMessage
      estSendMinutes
      smsButtonPresser
      smsButtonMsg
      numberProcessed
      numberToProcess
      createdAt
      updatedAt
    }
  }
`;
export const createCampaignManualSend = /* GraphQL */ `
  mutation CreateCampaignManualSend($input: CreateCampaignManualSendInput!) {
    createCampaignManualSend(input: $input) {
      id
      campaignId
      audience
      status
      flow
      flowVersion
      audienceFileContent
      startTime
      endTime
      createdBy
      group
      type
      isTest
      createdAt
      updatedAt
    }
  }
`;
export const updateCampaignManualSend = /* GraphQL */ `
  mutation UpdateCampaignManualSend($input: UpdateCampaignManualSendInput!) {
    updateCampaignManualSend(input: $input) {
      id
      campaignId
      audience
      status
      flow
      flowVersion
      audienceFileContent
      startTime
      endTime
      createdBy
      group
      type
      isTest
      createdAt
      updatedAt
    }
  }
`;
export const deleteCampaignManualSend = /* GraphQL */ `
  mutation DeleteCampaignManualSend($input: DeleteCampaignManualSendInput!) {
    deleteCampaignManualSend(input: $input) {
      id
      campaignId
      audience
      status
      flow
      flowVersion
      audienceFileContent
      startTime
      endTime
      createdBy
      group
      type
      isTest
      createdAt
      updatedAt
    }
  }
`;
export const createCampaignCompleted = /* GraphQL */ `
  mutation CreateCampaignCompleted($input: CreateCampaignCompletedInput!) {
    createCampaignCompleted(input: $input) {
      userId
      campaignId
      group
      flowId
      flowVersion
      lastNode
      exitType
      finishTime
      expireTime
      startTime
      type
      error
      isManualCamp
      expiredBeforeSend
      campaignInfo {
        items {
          id
          title
          titleKey
          description
          status
          statusKey
          audienceTargetType
          audience
          audienceFileContent
          audienceFileName
          fileUploadedBy
          flow
          flowVersion
          introFlow
          introFlowId
          introFlowVersion
          processing
          processingError
          startTime
          sendNumbers
          startTimeKey
          endTime
          endTimeKey
          timestamp
          timestampKey
          createdBy
          paused
          dryRun
          campaignTagAnswer
          campaignSelect
          pausedBy {
            asurite
            timestamp
          }
          approvedBy {
            asurite
            timestamp
          }
          type
          editedBy {
            asurite
            timestamp
          }
          deleted
          deletedBy {
            asurite
            timestamp
          }
          active
          group
          groupKey
          isSendingIntroMessage
          estSendMinutes
          smsButtonPresser
          smsButtonMsg
          numberProcessed
          numberToProcess
          createdAt
          updatedAt
        }
        nextToken
      }
      asurite
      phoneNumber
      createdAt
      updatedAt
    }
  }
`;
export const updateCampaignCompleted = /* GraphQL */ `
  mutation UpdateCampaignCompleted($input: UpdateCampaignCompletedInput!) {
    updateCampaignCompleted(input: $input) {
      userId
      campaignId
      group
      flowId
      flowVersion
      lastNode
      exitType
      finishTime
      expireTime
      startTime
      type
      error
      isManualCamp
      expiredBeforeSend
      campaignInfo {
        items {
          id
          title
          titleKey
          description
          status
          statusKey
          audienceTargetType
          audience
          audienceFileContent
          audienceFileName
          fileUploadedBy
          flow
          flowVersion
          introFlow
          introFlowId
          introFlowVersion
          processing
          processingError
          startTime
          sendNumbers
          startTimeKey
          endTime
          endTimeKey
          timestamp
          timestampKey
          createdBy
          paused
          dryRun
          campaignTagAnswer
          campaignSelect
          pausedBy {
            asurite
            timestamp
          }
          approvedBy {
            asurite
            timestamp
          }
          type
          editedBy {
            asurite
            timestamp
          }
          deleted
          deletedBy {
            asurite
            timestamp
          }
          active
          group
          groupKey
          isSendingIntroMessage
          estSendMinutes
          smsButtonPresser
          smsButtonMsg
          numberProcessed
          numberToProcess
          createdAt
          updatedAt
        }
        nextToken
      }
      asurite
      phoneNumber
      createdAt
      updatedAt
    }
  }
`;
export const deleteCampaignCompleted = /* GraphQL */ `
  mutation DeleteCampaignCompleted($input: DeleteCampaignCompletedInput!) {
    deleteCampaignCompleted(input: $input) {
      userId
      campaignId
      group
      flowId
      flowVersion
      lastNode
      exitType
      finishTime
      expireTime
      startTime
      type
      error
      isManualCamp
      expiredBeforeSend
      campaignInfo {
        items {
          id
          title
          titleKey
          description
          status
          statusKey
          audienceTargetType
          audience
          audienceFileContent
          audienceFileName
          fileUploadedBy
          flow
          flowVersion
          introFlow
          introFlowId
          introFlowVersion
          processing
          processingError
          startTime
          sendNumbers
          startTimeKey
          endTime
          endTimeKey
          timestamp
          timestampKey
          createdBy
          paused
          dryRun
          campaignTagAnswer
          campaignSelect
          pausedBy {
            asurite
            timestamp
          }
          approvedBy {
            asurite
            timestamp
          }
          type
          editedBy {
            asurite
            timestamp
          }
          deleted
          deletedBy {
            asurite
            timestamp
          }
          active
          group
          groupKey
          isSendingIntroMessage
          estSendMinutes
          smsButtonPresser
          smsButtonMsg
          numberProcessed
          numberToProcess
          createdAt
          updatedAt
        }
        nextToken
      }
      asurite
      phoneNumber
      createdAt
      updatedAt
    }
  }
`;
export const createCampaignUserQueue = /* GraphQL */ `
  mutation CreateCampaignUserQueue($input: CreateCampaignUserQueueInput!) {
    createCampaignUserQueue(input: $input) {
      userId
      campaignId
      flowId
      flowVersion
      expireTime
      startTime
      createdAt
      updatedAt
    }
  }
`;
export const updateCampaignUserQueue = /* GraphQL */ `
  mutation UpdateCampaignUserQueue($input: UpdateCampaignUserQueueInput!) {
    updateCampaignUserQueue(input: $input) {
      userId
      campaignId
      flowId
      flowVersion
      expireTime
      startTime
      createdAt
      updatedAt
    }
  }
`;
export const deleteCampaignUserQueue = /* GraphQL */ `
  mutation DeleteCampaignUserQueue($input: DeleteCampaignUserQueueInput!) {
    deleteCampaignUserQueue(input: $input) {
      userId
      campaignId
      flowId
      flowVersion
      expireTime
      startTime
      createdAt
      updatedAt
    }
  }
`;
export const createChatbotErrorMessages = /* GraphQL */ `
  mutation CreateChatbotErrorMessages(
    $input: CreateChatbotErrorMessagesInput!
  ) {
    createChatbotErrorMessages(input: $input) {
      errorCode
      errorMessage
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateChatbotErrorMessages = /* GraphQL */ `
  mutation UpdateChatbotErrorMessages(
    $input: UpdateChatbotErrorMessagesInput!
  ) {
    updateChatbotErrorMessages(input: $input) {
      errorCode
      errorMessage
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteChatbotErrorMessages = /* GraphQL */ `
  mutation DeleteChatbotErrorMessages(
    $input: DeleteChatbotErrorMessagesInput!
  ) {
    deleteChatbotErrorMessages(input: $input) {
      errorCode
      errorMessage
      type
      createdAt
      updatedAt
    }
  }
`;
export const createCampaignRunning = /* GraphQL */ `
  mutation CreateCampaignRunning($input: CreateCampaignRunningInput!) {
    createCampaignRunning(input: $input) {
      userId
      campaignId
      flowId
      flowVersion
      lastNode
      isManualCamp
      createdAt
      updatedAt
    }
  }
`;
export const updateCampaignRunning = /* GraphQL */ `
  mutation UpdateCampaignRunning($input: UpdateCampaignRunningInput!) {
    updateCampaignRunning(input: $input) {
      userId
      campaignId
      flowId
      flowVersion
      lastNode
      isManualCamp
      createdAt
      updatedAt
    }
  }
`;
export const deleteCampaignRunning = /* GraphQL */ `
  mutation DeleteCampaignRunning($input: DeleteCampaignRunningInput!) {
    deleteCampaignRunning(input: $input) {
      userId
      campaignId
      flowId
      flowVersion
      lastNode
      isManualCamp
      createdAt
      updatedAt
    }
  }
`;
export const createCampaignRunningVoice = /* GraphQL */ `
  mutation CreateCampaignRunningVoice(
    $input: CreateCampaignRunningVoiceInput!
  ) {
    createCampaignRunningVoice(input: $input) {
      userId
      campaignId
      flowId
      flowVersion
      lastNode
      isManualCamp
      createdAt
      updatedAt
    }
  }
`;
export const updateCampaignRunningVoice = /* GraphQL */ `
  mutation UpdateCampaignRunningVoice(
    $input: UpdateCampaignRunningVoiceInput!
  ) {
    updateCampaignRunningVoice(input: $input) {
      userId
      campaignId
      flowId
      flowVersion
      lastNode
      isManualCamp
      createdAt
      updatedAt
    }
  }
`;
export const deleteCampaignRunningVoice = /* GraphQL */ `
  mutation DeleteCampaignRunningVoice(
    $input: DeleteCampaignRunningVoiceInput!
  ) {
    deleteCampaignRunningVoice(input: $input) {
      userId
      campaignId
      flowId
      flowVersion
      lastNode
      isManualCamp
      createdAt
      updatedAt
    }
  }
`;
export const createAnswerVariables = /* GraphQL */ `
  mutation CreateAnswerVariables($input: CreateAnswerVariablesInput!) {
    createAnswerVariables(input: $input) {
      id
      varName
      active
      group
      createdAt
      updatedAt
    }
  }
`;
export const updateAnswerVariables = /* GraphQL */ `
  mutation UpdateAnswerVariables($input: UpdateAnswerVariablesInput!) {
    updateAnswerVariables(input: $input) {
      id
      varName
      active
      group
      createdAt
      updatedAt
    }
  }
`;
export const deleteAnswerVariables = /* GraphQL */ `
  mutation DeleteAnswerVariables($input: DeleteAnswerVariablesInput!) {
    deleteAnswerVariables(input: $input) {
      id
      varName
      active
      group
      createdAt
      updatedAt
    }
  }
`;
export const createCustomVariable = /* GraphQL */ `
  mutation CreateCustomVariable($input: CreateCustomVariableInput!) {
    createCustomVariable(input: $input) {
      id
      user
      key
      value
      botType
      group
      campaignId
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomVariable = /* GraphQL */ `
  mutation UpdateCustomVariable($input: UpdateCustomVariableInput!) {
    updateCustomVariable(input: $input) {
      id
      user
      key
      value
      botType
      group
      campaignId
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomVariable = /* GraphQL */ `
  mutation DeleteCustomVariable($input: DeleteCustomVariableInput!) {
    deleteCustomVariable(input: $input) {
      id
      user
      key
      value
      botType
      group
      campaignId
      createdAt
      updatedAt
    }
  }
`;
export const createStatus = /* GraphQL */ `
  mutation CreateStatus($input: CreateStatusInput!) {
    createStatus(input: $input) {
      id
      startTime
      submittedBy
      title
      active
      message
      severity
      endTime
      createdAt
      updatedAt
    }
  }
`;
export const updateStatus = /* GraphQL */ `
  mutation UpdateStatus($input: UpdateStatusInput!) {
    updateStatus(input: $input) {
      id
      startTime
      submittedBy
      title
      active
      message
      severity
      endTime
      createdAt
      updatedAt
    }
  }
`;
export const deleteStatus = /* GraphQL */ `
  mutation DeleteStatus($input: DeleteStatusInput!) {
    deleteStatus(input: $input) {
      id
      startTime
      submittedBy
      title
      active
      message
      severity
      endTime
      createdAt
      updatedAt
    }
  }
`;
export const createSawStatus = /* GraphQL */ `
  mutation CreateSawStatus($input: CreateSawStatusInput!) {
    createSawStatus(input: $input) {
      id
      asurite
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const updateSawStatus = /* GraphQL */ `
  mutation UpdateSawStatus($input: UpdateSawStatusInput!) {
    updateSawStatus(input: $input) {
      id
      asurite
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const deleteSawStatus = /* GraphQL */ `
  mutation DeleteSawStatus($input: DeleteSawStatusInput!) {
    deleteSawStatus(input: $input) {
      id
      asurite
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const createFlow = /* GraphQL */ `
  mutation CreateFlow($input: CreateFlowInput!) {
    createFlow(input: $input) {
      id
      title
      titleKey
      searchTitle
      description
      type
      statusX
      statusKey
      version
      versionInfo {
        id
        version
        createdAt
        title
        description
        variablesIncluded
        flowNodes
        submitted
        liveAgentEsc
        approved
        published
        disabled
        denied
        queryableData
        submittedBy {
          asurite
          timestamp
        }
        approvedBy {
          asurite
          timestamp
        }
        publishedBy {
          asurite
          timestamp
        }
        deniedBy {
          asurite
          timestamp
        }
        botType
        updatedAt
      }
      category
      timestamp
      submittedOn
      submittedOnKey
      editing {
        asurite
        timestamp
      }
      submittedBy
      submittedByKey
      paused
      approvedBy {
        asurite
        timestamp
      }
      editedBy {
        asurite
        timestamp
      }
      deleted
      deletedBy {
        asurite
        timestamp
      }
      group
      groupKey
      signOffMsg
      createdAt
      updatedAt
    }
  }
`;
export const updateFlow = /* GraphQL */ `
  mutation UpdateFlow($input: UpdateFlowInput!) {
    updateFlow(input: $input) {
      id
      title
      titleKey
      searchTitle
      description
      type
      statusX
      statusKey
      version
      versionInfo {
        id
        version
        createdAt
        title
        description
        variablesIncluded
        flowNodes
        submitted
        liveAgentEsc
        approved
        published
        disabled
        denied
        queryableData
        submittedBy {
          asurite
          timestamp
        }
        approvedBy {
          asurite
          timestamp
        }
        publishedBy {
          asurite
          timestamp
        }
        deniedBy {
          asurite
          timestamp
        }
        botType
        updatedAt
      }
      category
      timestamp
      submittedOn
      submittedOnKey
      editing {
        asurite
        timestamp
      }
      submittedBy
      submittedByKey
      paused
      approvedBy {
        asurite
        timestamp
      }
      editedBy {
        asurite
        timestamp
      }
      deleted
      deletedBy {
        asurite
        timestamp
      }
      group
      groupKey
      signOffMsg
      createdAt
      updatedAt
    }
  }
`;
export const deleteFlow = /* GraphQL */ `
  mutation DeleteFlow($input: DeleteFlowInput!) {
    deleteFlow(input: $input) {
      id
      title
      titleKey
      searchTitle
      description
      type
      statusX
      statusKey
      version
      versionInfo {
        id
        version
        createdAt
        title
        description
        variablesIncluded
        flowNodes
        submitted
        liveAgentEsc
        approved
        published
        disabled
        denied
        queryableData
        submittedBy {
          asurite
          timestamp
        }
        approvedBy {
          asurite
          timestamp
        }
        publishedBy {
          asurite
          timestamp
        }
        deniedBy {
          asurite
          timestamp
        }
        botType
        updatedAt
      }
      category
      timestamp
      submittedOn
      submittedOnKey
      editing {
        asurite
        timestamp
      }
      submittedBy
      submittedByKey
      paused
      approvedBy {
        asurite
        timestamp
      }
      editedBy {
        asurite
        timestamp
      }
      deleted
      deletedBy {
        asurite
        timestamp
      }
      group
      groupKey
      signOffMsg
      createdAt
      updatedAt
    }
  }
`;
export const createFlowMessage = /* GraphQL */ `
  mutation CreateFlowMessage($input: CreateFlowMessageInput!) {
    createFlowMessage(input: $input) {
      id
      name
      message
      nodeType
      group
      deleted
      deletedBy {
        asurite
        timestamp
      }
      submittedBy
      image
      audience
      response
      matches {
        id
        match
      }
      responseImage
      createdAt
      updatedAt
    }
  }
`;
export const updateFlowMessage = /* GraphQL */ `
  mutation UpdateFlowMessage($input: UpdateFlowMessageInput!) {
    updateFlowMessage(input: $input) {
      id
      name
      message
      nodeType
      group
      deleted
      deletedBy {
        asurite
        timestamp
      }
      submittedBy
      image
      audience
      response
      matches {
        id
        match
      }
      responseImage
      createdAt
      updatedAt
    }
  }
`;
export const deleteFlowMessage = /* GraphQL */ `
  mutation DeleteFlowMessage($input: DeleteFlowMessageInput!) {
    deleteFlowMessage(input: $input) {
      id
      name
      message
      nodeType
      group
      deleted
      deletedBy {
        asurite
        timestamp
      }
      submittedBy
      image
      audience
      response
      matches {
        id
        match
      }
      responseImage
      createdAt
      updatedAt
    }
  }
`;
export const createFlowVersion = /* GraphQL */ `
  mutation CreateFlowVersion($input: CreateFlowVersionInput!) {
    createFlowVersion(input: $input) {
      id
      version
      createdAt
      title
      description
      variablesIncluded
      flowNodes
      submitted
      liveAgentEsc
      approved
      published
      disabled
      denied
      queryableData
      submittedBy {
        asurite
        timestamp
      }
      approvedBy {
        asurite
        timestamp
      }
      publishedBy {
        asurite
        timestamp
      }
      deniedBy {
        asurite
        timestamp
      }
      botType
      updatedAt
    }
  }
`;
export const updateFlowVersion = /* GraphQL */ `
  mutation UpdateFlowVersion($input: UpdateFlowVersionInput!) {
    updateFlowVersion(input: $input) {
      id
      version
      createdAt
      title
      description
      variablesIncluded
      flowNodes
      submitted
      liveAgentEsc
      approved
      published
      disabled
      denied
      queryableData
      submittedBy {
        asurite
        timestamp
      }
      approvedBy {
        asurite
        timestamp
      }
      publishedBy {
        asurite
        timestamp
      }
      deniedBy {
        asurite
        timestamp
      }
      botType
      updatedAt
    }
  }
`;
export const deleteFlowVersion = /* GraphQL */ `
  mutation DeleteFlowVersion($input: DeleteFlowVersionInput!) {
    deleteFlowVersion(input: $input) {
      id
      version
      createdAt
      title
      description
      variablesIncluded
      flowNodes
      submitted
      liveAgentEsc
      approved
      published
      disabled
      denied
      queryableData
      submittedBy {
        asurite
        timestamp
      }
      approvedBy {
        asurite
        timestamp
      }
      publishedBy {
        asurite
        timestamp
      }
      deniedBy {
        asurite
        timestamp
      }
      botType
      updatedAt
    }
  }
`;
export const createResponseMessages = /* GraphQL */ `
  mutation CreateResponseMessages($input: CreateResponseMessagesInput!) {
    createResponseMessages(input: $input) {
      id
      group
      author
      asurite
      type
      message
      number
      flowId
      flowLabel
      flowVersion
      flowVersionLabel
      createdAt
      updatedAt
    }
  }
`;
export const updateResponseMessages = /* GraphQL */ `
  mutation UpdateResponseMessages($input: UpdateResponseMessagesInput!) {
    updateResponseMessages(input: $input) {
      id
      group
      author
      asurite
      type
      message
      number
      flowId
      flowLabel
      flowVersion
      flowVersionLabel
      createdAt
      updatedAt
    }
  }
`;
export const deleteResponseMessages = /* GraphQL */ `
  mutation DeleteResponseMessages($input: DeleteResponseMessagesInput!) {
    deleteResponseMessages(input: $input) {
      id
      group
      author
      asurite
      type
      message
      number
      flowId
      flowLabel
      flowVersion
      flowVersionLabel
      createdAt
      updatedAt
    }
  }
`;
export const createKnowledgeBaseCategory = /* GraphQL */ `
  mutation CreateKnowledgeBaseCategory(
    $input: CreateKnowledgeBaseCategoryInput!
  ) {
    createKnowledgeBaseCategory(input: $input) {
      id
      title
      group
      subcategories {
        title
      }
      deleted
      deletedBy {
        asurite
        timestamp
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateKnowledgeBaseCategory = /* GraphQL */ `
  mutation UpdateKnowledgeBaseCategory(
    $input: UpdateKnowledgeBaseCategoryInput!
  ) {
    updateKnowledgeBaseCategory(input: $input) {
      id
      title
      group
      subcategories {
        title
      }
      deleted
      deletedBy {
        asurite
        timestamp
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteKnowledgeBaseCategory = /* GraphQL */ `
  mutation DeleteKnowledgeBaseCategory(
    $input: DeleteKnowledgeBaseCategoryInput!
  ) {
    deleteKnowledgeBaseCategory(input: $input) {
      id
      title
      group
      subcategories {
        title
      }
      deleted
      deletedBy {
        asurite
        timestamp
      }
      createdAt
      updatedAt
    }
  }
`;
export const createKnowledgeBase = /* GraphQL */ `
  mutation CreateKnowledgeBase($input: CreateKnowledgeBaseInput!) {
    createKnowledgeBase(input: $input) {
      id
      categoryID
      categoryName
      subcategoryNames
      group
      groupKey
      comments
      commentsKey
      deletedBy {
        asurite
        timestamp
      }
      editedBy {
        asurite
        timestamp
      }
      editedByKey
      lastEdit
      lastEditKey
      approvedBy {
        asurite
        timestamp
      }
      question
      status
      statusKey
      submittedBy
      submittedByKey
      submittedOn
      submittedOnKey
      updatedOn
      variations
      variationsCount
      variationsCountKey
      answerData {
        answer
        flow
        flowVersion
        audience
        audienceEveryone
        type
        answerEscalation
        image
      }
      modality
      fallbackAnswer
      fallbackAnswerImage
      deleted
      escalation
      editing {
        asurite
        timestamp
      }
      processing
      processingError
      flow
      flowVersion
      createdAt
      updatedAt
    }
  }
`;
export const updateKnowledgeBase = /* GraphQL */ `
  mutation UpdateKnowledgeBase($input: UpdateKnowledgeBaseInput!) {
    updateKnowledgeBase(input: $input) {
      id
      categoryID
      categoryName
      subcategoryNames
      group
      groupKey
      comments
      commentsKey
      deletedBy {
        asurite
        timestamp
      }
      editedBy {
        asurite
        timestamp
      }
      editedByKey
      lastEdit
      lastEditKey
      approvedBy {
        asurite
        timestamp
      }
      question
      status
      statusKey
      submittedBy
      submittedByKey
      submittedOn
      submittedOnKey
      updatedOn
      variations
      variationsCount
      variationsCountKey
      answerData {
        answer
        flow
        flowVersion
        audience
        audienceEveryone
        type
        answerEscalation
        image
      }
      modality
      fallbackAnswer
      fallbackAnswerImage
      deleted
      escalation
      editing {
        asurite
        timestamp
      }
      processing
      processingError
      flow
      flowVersion
      createdAt
      updatedAt
    }
  }
`;
export const deleteKnowledgeBase = /* GraphQL */ `
  mutation DeleteKnowledgeBase($input: DeleteKnowledgeBaseInput!) {
    deleteKnowledgeBase(input: $input) {
      id
      categoryID
      categoryName
      subcategoryNames
      group
      groupKey
      comments
      commentsKey
      deletedBy {
        asurite
        timestamp
      }
      editedBy {
        asurite
        timestamp
      }
      editedByKey
      lastEdit
      lastEditKey
      approvedBy {
        asurite
        timestamp
      }
      question
      status
      statusKey
      submittedBy
      submittedByKey
      submittedOn
      submittedOnKey
      updatedOn
      variations
      variationsCount
      variationsCountKey
      answerData {
        answer
        flow
        flowVersion
        audience
        audienceEveryone
        type
        answerEscalation
        image
      }
      modality
      fallbackAnswer
      fallbackAnswerImage
      deleted
      escalation
      editing {
        asurite
        timestamp
      }
      processing
      processingError
      flow
      flowVersion
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      asurite
      displayName
      role
      createdAt
      createdBy
      editedAt
      editedBy
      isActive
      tier1Support
      lastLogin
      groups
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      asurite
      displayName
      role
      createdAt
      createdBy
      editedAt
      editedBy
      isActive
      tier1Support
      lastLogin
      groups
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      id
      asurite
      displayName
      role
      createdAt
      createdBy
      editedAt
      editedBy
      isActive
      tier1Support
      lastLogin
      groups
      updatedAt
    }
  }
`;
export const createChatbotGroup = /* GraphQL */ `
  mutation CreateChatbotGroup($input: CreateChatbotGroupInput!) {
    createChatbotGroup(input: $input) {
      id
      name
      isActive
      email
      dialoguesFolders {
        name
        tagValue
        parent
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateChatbotGroup = /* GraphQL */ `
  mutation UpdateChatbotGroup($input: UpdateChatbotGroupInput!) {
    updateChatbotGroup(input: $input) {
      id
      name
      isActive
      email
      dialoguesFolders {
        name
        tagValue
        parent
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteChatbotGroup = /* GraphQL */ `
  mutation DeleteChatbotGroup($input: DeleteChatbotGroupInput!) {
    deleteChatbotGroup(input: $input) {
      id
      name
      isActive
      email
      dialoguesFolders {
        name
        tagValue
        parent
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCriticalEscalation = /* GraphQL */ `
  mutation CreateCriticalEscalation($input: CreateCriticalEscalationInput!) {
    createCriticalEscalation(input: $input) {
      id
      triggerPhrase
      escalation
      variations
      status
      statusKey
      editing {
        asurite
        timestamp
      }
      processing
      processingError
      escalateSms
      answerData {
        answer
        flow
        flowVersion
        audience
        audienceEveryone
        type
        answerEscalation
        image
      }
      fallbackAnswer
      fallbackAnswerImage
      deleted
      group
      createdAt
      createdAtKey
      submittedBy
      submittedByKey
      response
      editedBy {
        asurite
        timestamp
      }
      deletedBy {
        asurite
        timestamp
      }
      timestamp
      timestampKey
      lastEdit
      updatedAt
    }
  }
`;
export const updateCriticalEscalation = /* GraphQL */ `
  mutation UpdateCriticalEscalation($input: UpdateCriticalEscalationInput!) {
    updateCriticalEscalation(input: $input) {
      id
      triggerPhrase
      escalation
      variations
      status
      statusKey
      editing {
        asurite
        timestamp
      }
      processing
      processingError
      escalateSms
      answerData {
        answer
        flow
        flowVersion
        audience
        audienceEveryone
        type
        answerEscalation
        image
      }
      fallbackAnswer
      fallbackAnswerImage
      deleted
      group
      createdAt
      createdAtKey
      submittedBy
      submittedByKey
      response
      editedBy {
        asurite
        timestamp
      }
      deletedBy {
        asurite
        timestamp
      }
      timestamp
      timestampKey
      lastEdit
      updatedAt
    }
  }
`;
export const deleteCriticalEscalation = /* GraphQL */ `
  mutation DeleteCriticalEscalation($input: DeleteCriticalEscalationInput!) {
    deleteCriticalEscalation(input: $input) {
      id
      triggerPhrase
      escalation
      variations
      status
      statusKey
      editing {
        asurite
        timestamp
      }
      processing
      processingError
      escalateSms
      answerData {
        answer
        flow
        flowVersion
        audience
        audienceEveryone
        type
        answerEscalation
        image
      }
      fallbackAnswer
      fallbackAnswerImage
      deleted
      group
      createdAt
      createdAtKey
      submittedBy
      submittedByKey
      response
      editedBy {
        asurite
        timestamp
      }
      deletedBy {
        asurite
        timestamp
      }
      timestamp
      timestampKey
      lastEdit
      updatedAt
    }
  }
`;
export const createFlowNode = /* GraphQL */ `
  mutation CreateFlowNode($input: CreateFlowNodeInput!) {
    createFlowNode(input: $input) {
      id
      flowId
      type
      customType
      text
      parent
      child
      ignore
      match
      matches {
        id
        match
      }
      matchText
      deleted
      deleteAudit {
        asurite
        timestamp
      }
      jumpToNodeId
      messageBody
      postSkipJump
      skipAfterTimes
      response
      escalationName
      escalationType
      image
      responseImage
      escalationDump
      templateId
      saveAsTemplate
      messageName
      ownerGroup
      audience
      branchDataCollector
      createdAt
      updatedAt
    }
  }
`;
export const updateFlowNode = /* GraphQL */ `
  mutation UpdateFlowNode($input: UpdateFlowNodeInput!) {
    updateFlowNode(input: $input) {
      id
      flowId
      type
      customType
      text
      parent
      child
      ignore
      match
      matches {
        id
        match
      }
      matchText
      deleted
      deleteAudit {
        asurite
        timestamp
      }
      jumpToNodeId
      messageBody
      postSkipJump
      skipAfterTimes
      response
      escalationName
      escalationType
      image
      responseImage
      escalationDump
      templateId
      saveAsTemplate
      messageName
      ownerGroup
      audience
      branchDataCollector
      createdAt
      updatedAt
    }
  }
`;
export const deleteFlowNode = /* GraphQL */ `
  mutation DeleteFlowNode($input: DeleteFlowNodeInput!) {
    deleteFlowNode(input: $input) {
      id
      flowId
      type
      customType
      text
      parent
      child
      ignore
      match
      matches {
        id
        match
      }
      matchText
      deleted
      deleteAudit {
        asurite
        timestamp
      }
      jumpToNodeId
      messageBody
      postSkipJump
      skipAfterTimes
      response
      escalationName
      escalationType
      image
      responseImage
      escalationDump
      templateId
      saveAsTemplate
      messageName
      ownerGroup
      audience
      branchDataCollector
      createdAt
      updatedAt
    }
  }
`;
export const createCampaignUserInput = /* GraphQL */ `
  mutation CreateCampaignUserInput($input: CreateCampaignUserInputInput!) {
    createCampaignUserInput(input: $input) {
      id
      userId
      flowId
      campaignId
      nodeId
      timestamp
      button
      inputDump
      type
      flowVersion
      botType
      createdAt
      updatedAt
    }
  }
`;
export const updateCampaignUserInput = /* GraphQL */ `
  mutation UpdateCampaignUserInput($input: UpdateCampaignUserInputInput!) {
    updateCampaignUserInput(input: $input) {
      id
      userId
      flowId
      campaignId
      nodeId
      timestamp
      button
      inputDump
      type
      flowVersion
      botType
      createdAt
      updatedAt
    }
  }
`;
export const deleteCampaignUserInput = /* GraphQL */ `
  mutation DeleteCampaignUserInput($input: DeleteCampaignUserInputInput!) {
    deleteCampaignUserInput(input: $input) {
      id
      userId
      flowId
      campaignId
      nodeId
      timestamp
      button
      inputDump
      type
      flowVersion
      botType
      createdAt
      updatedAt
    }
  }
`;
export const createEscalation = /* GraphQL */ `
  mutation CreateEscalation($input: CreateEscalationInput!) {
    createEscalation(input: $input) {
      id
      escalationName
      group
      groupKey
      escalationType
      emailTo
      ccemail
      emailSubject
      emailBody
      attachChatLog
      sendTextMessage
      status
      statusKey
      textMessageContent {
        to
        textBody
      }
      submittedBy
      submittedByKey
      submittedAt
      submittedAtKey
      liveAgentCategory
      attachedAlertIds
      editedBy {
        asurite
        timestamp
      }
      deleted
      timestamp
      timestampKey
      createdAt
      updatedAt
    }
  }
`;
export const updateEscalation = /* GraphQL */ `
  mutation UpdateEscalation($input: UpdateEscalationInput!) {
    updateEscalation(input: $input) {
      id
      escalationName
      group
      groupKey
      escalationType
      emailTo
      ccemail
      emailSubject
      emailBody
      attachChatLog
      sendTextMessage
      status
      statusKey
      textMessageContent {
        to
        textBody
      }
      submittedBy
      submittedByKey
      submittedAt
      submittedAtKey
      liveAgentCategory
      attachedAlertIds
      editedBy {
        asurite
        timestamp
      }
      deleted
      timestamp
      timestampKey
      createdAt
      updatedAt
    }
  }
`;
export const deleteEscalation = /* GraphQL */ `
  mutation DeleteEscalation($input: DeleteEscalationInput!) {
    deleteEscalation(input: $input) {
      id
      escalationName
      group
      groupKey
      escalationType
      emailTo
      ccemail
      emailSubject
      emailBody
      attachChatLog
      sendTextMessage
      status
      statusKey
      textMessageContent {
        to
        textBody
      }
      submittedBy
      submittedByKey
      submittedAt
      submittedAtKey
      liveAgentCategory
      attachedAlertIds
      editedBy {
        asurite
        timestamp
      }
      deleted
      timestamp
      timestampKey
      createdAt
      updatedAt
    }
  }
`;
export const createEscalationHistory = /* GraphQL */ `
  mutation CreateEscalationHistory($input: CreateEscalationHistoryInput!) {
    createEscalationHistory(input: $input) {
      id
      escalationId
      escalationName
      escalator
      hitType
      message
      messageId
      response
      user
      convoId
      timestamp
      group
      escalationType
      emailTo
      ccemail
      emailSubject
      emailBody
      attachChatLog
      sendTextMessage
      textMessageContent {
        to
        textBody
      }
      liveAgentCategory
      createdAt
      updatedAt
    }
  }
`;
export const updateEscalationHistory = /* GraphQL */ `
  mutation UpdateEscalationHistory($input: UpdateEscalationHistoryInput!) {
    updateEscalationHistory(input: $input) {
      id
      escalationId
      escalationName
      escalator
      hitType
      message
      messageId
      response
      user
      convoId
      timestamp
      group
      escalationType
      emailTo
      ccemail
      emailSubject
      emailBody
      attachChatLog
      sendTextMessage
      textMessageContent {
        to
        textBody
      }
      liveAgentCategory
      createdAt
      updatedAt
    }
  }
`;
export const deleteEscalationHistory = /* GraphQL */ `
  mutation DeleteEscalationHistory($input: DeleteEscalationHistoryInput!) {
    deleteEscalationHistory(input: $input) {
      id
      escalationId
      escalationName
      escalator
      hitType
      message
      messageId
      response
      user
      convoId
      timestamp
      group
      escalationType
      emailTo
      ccemail
      emailSubject
      emailBody
      attachChatLog
      sendTextMessage
      textMessageContent {
        to
        textBody
      }
      liveAgentCategory
      createdAt
      updatedAt
    }
  }
`;
export const createCustomDefaultMessage = /* GraphQL */ `
  mutation CreateCustomDefaultMessage(
    $input: CreateCustomDefaultMessageInput!
  ) {
    createCustomDefaultMessage(input: $input) {
      id
      owner
      author
      type
      message
      number
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomDefaultMessage = /* GraphQL */ `
  mutation UpdateCustomDefaultMessage(
    $input: UpdateCustomDefaultMessageInput!
  ) {
    updateCustomDefaultMessage(input: $input) {
      id
      owner
      author
      type
      message
      number
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomDefaultMessage = /* GraphQL */ `
  mutation DeleteCustomDefaultMessage(
    $input: DeleteCustomDefaultMessageInput!
  ) {
    deleteCustomDefaultMessage(input: $input) {
      id
      owner
      author
      type
      message
      number
      createdAt
      updatedAt
    }
  }
`;
export const createPromptResponse = /* GraphQL */ `
  mutation CreatePromptResponse($input: CreatePromptResponseInput!) {
    createPromptResponse(input: $input) {
      id
      messageId
      asurite
      message
      createdAt
      updatedAt
    }
  }
`;
export const updatePromptResponse = /* GraphQL */ `
  mutation UpdatePromptResponse($input: UpdatePromptResponseInput!) {
    updatePromptResponse(input: $input) {
      id
      messageId
      asurite
      message
      createdAt
      updatedAt
    }
  }
`;
export const deletePromptResponse = /* GraphQL */ `
  mutation DeletePromptResponse($input: DeletePromptResponseInput!) {
    deletePromptResponse(input: $input) {
      id
      messageId
      asurite
      message
      createdAt
      updatedAt
    }
  }
`;
export const createChatbotMessage = /* GraphQL */ `
  mutation CreateChatbotMessage($input: CreateChatbotMessageInput!) {
    createChatbotMessage(input: $input) {
      convoId
      createdAt
      messageId
      content
      sender
      type
      connectionId
      file
      agentAsurite
      agentAction
      agent
      botType
      sessionId
      updatedAt
    }
  }
`;
export const updateChatbotMessage = /* GraphQL */ `
  mutation UpdateChatbotMessage($input: UpdateChatbotMessageInput!) {
    updateChatbotMessage(input: $input) {
      convoId
      createdAt
      messageId
      content
      sender
      type
      connectionId
      file
      agentAsurite
      agentAction
      agent
      botType
      sessionId
      updatedAt
    }
  }
`;
export const deleteChatbotMessage = /* GraphQL */ `
  mutation DeleteChatbotMessage($input: DeleteChatbotMessageInput!) {
    deleteChatbotMessage(input: $input) {
      convoId
      createdAt
      messageId
      content
      sender
      type
      connectionId
      file
      agentAsurite
      agentAction
      agent
      botType
      sessionId
      updatedAt
    }
  }
`;
export const createChatbotMessageTag = /* GraphQL */ `
  mutation CreateChatbotMessageTag($input: CreateChatbotMessageTagInput!) {
    createChatbotMessageTag(input: $input) {
      id
      convoId
      messageId
      tag
      deleted
      addedBy
      deletedBy
      botType
      createdAt
      updatedAt
    }
  }
`;
export const updateChatbotMessageTag = /* GraphQL */ `
  mutation UpdateChatbotMessageTag($input: UpdateChatbotMessageTagInput!) {
    updateChatbotMessageTag(input: $input) {
      id
      convoId
      messageId
      tag
      deleted
      addedBy
      deletedBy
      botType
      createdAt
      updatedAt
    }
  }
`;
export const deleteChatbotMessageTag = /* GraphQL */ `
  mutation DeleteChatbotMessageTag($input: DeleteChatbotMessageTagInput!) {
    deleteChatbotMessageTag(input: $input) {
      id
      convoId
      messageId
      tag
      deleted
      addedBy
      deletedBy
      botType
      createdAt
      updatedAt
    }
  }
`;
export const createChatbotLexSession = /* GraphQL */ `
  mutation CreateChatbotLexSession($input: CreateChatbotLexSessionInput!) {
    createChatbotLexSession(input: $input) {
      id
      botType
      missedCount
      expireTime
      createdAt
      updatedAt
    }
  }
`;
export const updateChatbotLexSession = /* GraphQL */ `
  mutation UpdateChatbotLexSession($input: UpdateChatbotLexSessionInput!) {
    updateChatbotLexSession(input: $input) {
      id
      botType
      missedCount
      expireTime
      createdAt
      updatedAt
    }
  }
`;
export const deleteChatbotLexSession = /* GraphQL */ `
  mutation DeleteChatbotLexSession($input: DeleteChatbotLexSessionInput!) {
    deleteChatbotLexSession(input: $input) {
      id
      botType
      missedCount
      expireTime
      createdAt
      updatedAt
    }
  }
`;
export const createChatbotSession = /* GraphQL */ `
  mutation CreateChatbotSession($input: CreateChatbotSessionInput!) {
    createChatbotSession(input: $input) {
      id
      asurite
      baseStartDate
      startTime
      endTime
      origin
      originLocation
      flowStatus
      connectionId
      totalTime
      caseNumber
      liveagentEsc
      sentMessages
      receivedMessages
      totalMessages
      dialogueEntered
      agentAsurite
      leftBeforeTransfer
      userId
      botType
      createdAt
      updatedAt
    }
  }
`;
export const updateChatbotSession = /* GraphQL */ `
  mutation UpdateChatbotSession($input: UpdateChatbotSessionInput!) {
    updateChatbotSession(input: $input) {
      id
      asurite
      baseStartDate
      startTime
      endTime
      origin
      originLocation
      flowStatus
      connectionId
      totalTime
      caseNumber
      liveagentEsc
      sentMessages
      receivedMessages
      totalMessages
      dialogueEntered
      agentAsurite
      leftBeforeTransfer
      userId
      botType
      createdAt
      updatedAt
    }
  }
`;
export const deleteChatbotSession = /* GraphQL */ `
  mutation DeleteChatbotSession($input: DeleteChatbotSessionInput!) {
    deleteChatbotSession(input: $input) {
      id
      asurite
      baseStartDate
      startTime
      endTime
      origin
      originLocation
      flowStatus
      connectionId
      totalTime
      caseNumber
      liveagentEsc
      sentMessages
      receivedMessages
      totalMessages
      dialogueEntered
      agentAsurite
      leftBeforeTransfer
      userId
      botType
      createdAt
      updatedAt
    }
  }
`;
export const createChatbotUserHistorical = /* GraphQL */ `
  mutation CreateChatbotUserHistorical(
    $input: CreateChatbotUserHistoricalInput!
  ) {
    createChatbotUserHistorical(input: $input) {
      id
      botType
      asurite
      phoneNumber
      removedAt
      removeReason
      convoId
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateChatbotUserHistorical = /* GraphQL */ `
  mutation UpdateChatbotUserHistorical(
    $input: UpdateChatbotUserHistoricalInput!
  ) {
    updateChatbotUserHistorical(input: $input) {
      id
      botType
      asurite
      phoneNumber
      removedAt
      removeReason
      convoId
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteChatbotUserHistorical = /* GraphQL */ `
  mutation DeleteChatbotUserHistorical(
    $input: DeleteChatbotUserHistoricalInput!
  ) {
    deleteChatbotUserHistorical(input: $input) {
      id
      botType
      asurite
      phoneNumber
      removedAt
      removeReason
      convoId
      type
      createdAt
      updatedAt
    }
  }
`;
export const createChatbotUserStaging = /* GraphQL */ `
  mutation CreateChatbotUserStaging($input: CreateChatbotUserStagingInput!) {
    createChatbotUserStaging(input: $input) {
      id
      searchId
      peoplesoftData
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const updateChatbotUserStaging = /* GraphQL */ `
  mutation UpdateChatbotUserStaging($input: UpdateChatbotUserStagingInput!) {
    updateChatbotUserStaging(input: $input) {
      id
      searchId
      peoplesoftData
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const deleteChatbotUserStaging = /* GraphQL */ `
  mutation DeleteChatbotUserStaging($input: DeleteChatbotUserStagingInput!) {
    deleteChatbotUserStaging(input: $input) {
      id
      searchId
      peoplesoftData
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const createChatbotUser = /* GraphQL */ `
  mutation CreateChatbotUser($input: CreateChatbotUserInput!) {
    createChatbotUser(input: $input) {
      id
      paused
      optOut
      userOptOut
      stopped
      botType
      type
      latest
      asurite
      phoneNumber
      homeNumber
      convoId
      firstName
      lastName
      displayName
      agentPauseAsurite
      agentOptOutAsurite
      reinstateDate
      altConvoIds
      tags {
        items {
          id
          userId
          tag
          deleted
          addedBy
          deletedBy
          botType
          userInfo {
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateChatbotUser = /* GraphQL */ `
  mutation UpdateChatbotUser($input: UpdateChatbotUserInput!) {
    updateChatbotUser(input: $input) {
      id
      paused
      optOut
      userOptOut
      stopped
      botType
      type
      latest
      asurite
      phoneNumber
      homeNumber
      convoId
      firstName
      lastName
      displayName
      agentPauseAsurite
      agentOptOutAsurite
      reinstateDate
      altConvoIds
      tags {
        items {
          id
          userId
          tag
          deleted
          addedBy
          deletedBy
          botType
          userInfo {
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteChatbotUser = /* GraphQL */ `
  mutation DeleteChatbotUser($input: DeleteChatbotUserInput!) {
    deleteChatbotUser(input: $input) {
      id
      paused
      optOut
      userOptOut
      stopped
      botType
      type
      latest
      asurite
      phoneNumber
      homeNumber
      convoId
      firstName
      lastName
      displayName
      agentPauseAsurite
      agentOptOutAsurite
      reinstateDate
      altConvoIds
      tags {
        items {
          id
          userId
          tag
          deleted
          addedBy
          deletedBy
          botType
          userInfo {
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createChatbotUserTag = /* GraphQL */ `
  mutation CreateChatbotUserTag($input: CreateChatbotUserTagInput!) {
    createChatbotUserTag(input: $input) {
      id
      userId
      tag
      deleted
      addedBy
      deletedBy
      botType
      userInfo {
        items {
          id
          paused
          optOut
          userOptOut
          stopped
          botType
          type
          latest
          asurite
          phoneNumber
          homeNumber
          convoId
          firstName
          lastName
          displayName
          agentPauseAsurite
          agentOptOutAsurite
          reinstateDate
          altConvoIds
          tags {
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateChatbotUserTag = /* GraphQL */ `
  mutation UpdateChatbotUserTag($input: UpdateChatbotUserTagInput!) {
    updateChatbotUserTag(input: $input) {
      id
      userId
      tag
      deleted
      addedBy
      deletedBy
      botType
      userInfo {
        items {
          id
          paused
          optOut
          userOptOut
          stopped
          botType
          type
          latest
          asurite
          phoneNumber
          homeNumber
          convoId
          firstName
          lastName
          displayName
          agentPauseAsurite
          agentOptOutAsurite
          reinstateDate
          altConvoIds
          tags {
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteChatbotUserTag = /* GraphQL */ `
  mutation DeleteChatbotUserTag($input: DeleteChatbotUserTagInput!) {
    deleteChatbotUserTag(input: $input) {
      id
      userId
      tag
      deleted
      addedBy
      deletedBy
      botType
      userInfo {
        items {
          id
          paused
          optOut
          userOptOut
          stopped
          botType
          type
          latest
          asurite
          phoneNumber
          homeNumber
          convoId
          firstName
          lastName
          displayName
          agentPauseAsurite
          agentOptOutAsurite
          reinstateDate
          altConvoIds
          tags {
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createChatbotConvoTag = /* GraphQL */ `
  mutation CreateChatbotConvoTag($input: CreateChatbotConvoTagInput!) {
    createChatbotConvoTag(input: $input) {
      id
      convoId
      tag
      deleted
      addedBy
      deletedBy
      botType
      createdAt
      updatedAt
    }
  }
`;
export const updateChatbotConvoTag = /* GraphQL */ `
  mutation UpdateChatbotConvoTag($input: UpdateChatbotConvoTagInput!) {
    updateChatbotConvoTag(input: $input) {
      id
      convoId
      tag
      deleted
      addedBy
      deletedBy
      botType
      createdAt
      updatedAt
    }
  }
`;
export const deleteChatbotConvoTag = /* GraphQL */ `
  mutation DeleteChatbotConvoTag($input: DeleteChatbotConvoTagInput!) {
    deleteChatbotConvoTag(input: $input) {
      id
      convoId
      tag
      deleted
      addedBy
      deletedBy
      botType
      createdAt
      updatedAt
    }
  }
`;
export const createChatbotConversation = /* GraphQL */ `
  mutation CreateChatbotConversation($input: CreateChatbotConversationInput!) {
    createChatbotConversation(input: $input) {
      id
      latest
      asurite
      userId
      botType
      type
      paused
      new
      optOut
      unseenBy
      agents
      userOptOut
      agentPauseAsurite
      agentOptOutAsurite
      reinstateDate
      createdAt
      updatedAt
    }
  }
`;
export const updateChatbotConversation = /* GraphQL */ `
  mutation UpdateChatbotConversation($input: UpdateChatbotConversationInput!) {
    updateChatbotConversation(input: $input) {
      id
      latest
      asurite
      userId
      botType
      type
      paused
      new
      optOut
      unseenBy
      agents
      userOptOut
      agentPauseAsurite
      agentOptOutAsurite
      reinstateDate
      createdAt
      updatedAt
    }
  }
`;
export const deleteChatbotConversation = /* GraphQL */ `
  mutation DeleteChatbotConversation($input: DeleteChatbotConversationInput!) {
    deleteChatbotConversation(input: $input) {
      id
      latest
      asurite
      userId
      botType
      type
      paused
      new
      optOut
      unseenBy
      agents
      userOptOut
      agentPauseAsurite
      agentOptOutAsurite
      reinstateDate
      createdAt
      updatedAt
    }
  }
`;
export const createChatbotDialogueRule = /* GraphQL */ `
  mutation CreateChatbotDialogueRule($input: CreateChatbotDialogueRuleInput!) {
    createChatbotDialogueRule(input: $input) {
      id
      name
      displayName
      keywords
      active
      deleted
      assignedFolder
      assignedGroup
      assignedUser
      submittedBy
      editedBy {
        asurite
        timestamp
      }
      deletedBy {
        asurite
        timestamp
      }
      botType
      createdAt
      updatedAt
    }
  }
`;
export const updateChatbotDialogueRule = /* GraphQL */ `
  mutation UpdateChatbotDialogueRule($input: UpdateChatbotDialogueRuleInput!) {
    updateChatbotDialogueRule(input: $input) {
      id
      name
      displayName
      keywords
      active
      deleted
      assignedFolder
      assignedGroup
      assignedUser
      submittedBy
      editedBy {
        asurite
        timestamp
      }
      deletedBy {
        asurite
        timestamp
      }
      botType
      createdAt
      updatedAt
    }
  }
`;
export const deleteChatbotDialogueRule = /* GraphQL */ `
  mutation DeleteChatbotDialogueRule($input: DeleteChatbotDialogueRuleInput!) {
    deleteChatbotDialogueRule(input: $input) {
      id
      name
      displayName
      keywords
      active
      deleted
      assignedFolder
      assignedGroup
      assignedUser
      submittedBy
      editedBy {
        asurite
        timestamp
      }
      deletedBy {
        asurite
        timestamp
      }
      botType
      createdAt
      updatedAt
    }
  }
`;
export const createChatbotWebsocketConnection = /* GraphQL */ `
  mutation CreateChatbotWebsocketConnection(
    $input: CreateChatbotWebsocketConnectionInput!
  ) {
    createChatbotWebsocketConnection(input: $input) {
      connectionId
      convoId
      agentChatting
      agentDetails {
        asurite
        displayName
      }
      asurite
      domainName
      phoneNumber
      stage
      type
      webConnection
      createdAt
      updatedAt
    }
  }
`;
export const updateChatbotWebsocketConnection = /* GraphQL */ `
  mutation UpdateChatbotWebsocketConnection(
    $input: UpdateChatbotWebsocketConnectionInput!
  ) {
    updateChatbotWebsocketConnection(input: $input) {
      connectionId
      convoId
      agentChatting
      agentDetails {
        asurite
        displayName
      }
      asurite
      domainName
      phoneNumber
      stage
      type
      webConnection
      createdAt
      updatedAt
    }
  }
`;
export const deleteChatbotWebsocketConnection = /* GraphQL */ `
  mutation DeleteChatbotWebsocketConnection(
    $input: DeleteChatbotWebsocketConnectionInput!
  ) {
    deleteChatbotWebsocketConnection(input: $input) {
      connectionId
      convoId
      agentChatting
      agentDetails {
        asurite
        displayName
      }
      asurite
      domainName
      phoneNumber
      stage
      type
      webConnection
      createdAt
      updatedAt
    }
  }
`;
export const createTriggeredIntent = /* GraphQL */ `
  mutation CreateTriggeredIntent($input: CreateTriggeredIntentInput!) {
    createTriggeredIntent(input: $input) {
      id
      intentId
      asurite
      timestamp
      phrase
      kbId
      response
      originSite
      baseDate
      sessionId
      escalationInfo
      botType
      createdAt
      updatedAt
    }
  }
`;
export const updateTriggeredIntent = /* GraphQL */ `
  mutation UpdateTriggeredIntent($input: UpdateTriggeredIntentInput!) {
    updateTriggeredIntent(input: $input) {
      id
      intentId
      asurite
      timestamp
      phrase
      kbId
      response
      originSite
      baseDate
      sessionId
      escalationInfo
      botType
      createdAt
      updatedAt
    }
  }
`;
export const deleteTriggeredIntent = /* GraphQL */ `
  mutation DeleteTriggeredIntent($input: DeleteTriggeredIntentInput!) {
    deleteTriggeredIntent(input: $input) {
      id
      intentId
      asurite
      timestamp
      phrase
      kbId
      response
      originSite
      baseDate
      sessionId
      escalationInfo
      botType
      createdAt
      updatedAt
    }
  }
`;
export const createUtterances = /* GraphQL */ `
  mutation CreateUtterances($input: CreateUtterancesInput!) {
    createUtterances(input: $input) {
      phrase
      compKey
      baseDate
      origin
      count
      detected
      missed
      botType
      users
      alternates
      createdAt
      updatedAt
    }
  }
`;
export const updateUtterances = /* GraphQL */ `
  mutation UpdateUtterances($input: UpdateUtterancesInput!) {
    updateUtterances(input: $input) {
      phrase
      compKey
      baseDate
      origin
      count
      detected
      missed
      botType
      users
      alternates
      createdAt
      updatedAt
    }
  }
`;
export const deleteUtterances = /* GraphQL */ `
  mutation DeleteUtterances($input: DeleteUtterancesInput!) {
    deleteUtterances(input: $input) {
      phrase
      compKey
      baseDate
      origin
      count
      detected
      missed
      botType
      users
      alternates
      createdAt
      updatedAt
    }
  }
`;
export const createPendingCampaign = /* GraphQL */ `
  mutation CreatePendingCampaign($input: CreatePendingCampaignInput!) {
    createPendingCampaign(input: $input) {
      id
      sendTime
      flowId
      users
      type
      flowNodes
      parent
      expireTime
      status
      createdAt
      updatedAt
    }
  }
`;
export const updatePendingCampaign = /* GraphQL */ `
  mutation UpdatePendingCampaign($input: UpdatePendingCampaignInput!) {
    updatePendingCampaign(input: $input) {
      id
      sendTime
      flowId
      users
      type
      flowNodes
      parent
      expireTime
      status
      createdAt
      updatedAt
    }
  }
`;
export const deletePendingCampaign = /* GraphQL */ `
  mutation DeletePendingCampaign($input: DeletePendingCampaignInput!) {
    deletePendingCampaign(input: $input) {
      id
      sendTime
      flowId
      users
      type
      flowNodes
      parent
      expireTime
      status
      createdAt
      updatedAt
    }
  }
`;
export const createDialogueNotes = /* GraphQL */ `
  mutation CreateDialogueNotes($input: CreateDialogueNotesInput!) {
    createDialogueNotes(input: $input) {
      id
      convoId
      messageId
      content
      author
      deleted
      botType
      editedBy {
        asurite
        timestamp
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateDialogueNotes = /* GraphQL */ `
  mutation UpdateDialogueNotes($input: UpdateDialogueNotesInput!) {
    updateDialogueNotes(input: $input) {
      id
      convoId
      messageId
      content
      author
      deleted
      botType
      editedBy {
        asurite
        timestamp
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteDialogueNotes = /* GraphQL */ `
  mutation DeleteDialogueNotes($input: DeleteDialogueNotesInput!) {
    deleteDialogueNotes(input: $input) {
      id
      convoId
      messageId
      content
      author
      deleted
      botType
      editedBy {
        asurite
        timestamp
      }
      createdAt
      updatedAt
    }
  }
`;
export const createChatbotAnnouncements = /* GraphQL */ `
  mutation CreateChatbotAnnouncements(
    $input: CreateChatbotAnnouncementsInput!
  ) {
    createChatbotAnnouncements(input: $input) {
      id
      name
      text
      active
      createdBy
      editedBy {
        asurite
        timestamp
      }
      group
      showOn
      createdAt
      updatedAt
    }
  }
`;
export const updateChatbotAnnouncements = /* GraphQL */ `
  mutation UpdateChatbotAnnouncements(
    $input: UpdateChatbotAnnouncementsInput!
  ) {
    updateChatbotAnnouncements(input: $input) {
      id
      name
      text
      active
      createdBy
      editedBy {
        asurite
        timestamp
      }
      group
      showOn
      createdAt
      updatedAt
    }
  }
`;
export const deleteChatbotAnnouncements = /* GraphQL */ `
  mutation DeleteChatbotAnnouncements(
    $input: DeleteChatbotAnnouncementsInput!
  ) {
    deleteChatbotAnnouncements(input: $input) {
      id
      name
      text
      active
      createdBy
      editedBy {
        asurite
        timestamp
      }
      group
      showOn
      createdAt
      updatedAt
    }
  }
`;
export const createChatbotAnnouncementsHistorical = /* GraphQL */ `
  mutation CreateChatbotAnnouncementsHistorical(
    $input: CreateChatbotAnnouncementsHistoricalInput!
  ) {
    createChatbotAnnouncementsHistorical(input: $input) {
      id
      name
      createdAt
      text
      startTime
      startedBy
      endTime
      endedBy
      group
      showOn
      versionId
      updatedAt
    }
  }
`;
export const updateChatbotAnnouncementsHistorical = /* GraphQL */ `
  mutation UpdateChatbotAnnouncementsHistorical(
    $input: UpdateChatbotAnnouncementsHistoricalInput!
  ) {
    updateChatbotAnnouncementsHistorical(input: $input) {
      id
      name
      createdAt
      text
      startTime
      startedBy
      endTime
      endedBy
      group
      showOn
      versionId
      updatedAt
    }
  }
`;
export const deleteChatbotAnnouncementsHistorical = /* GraphQL */ `
  mutation DeleteChatbotAnnouncementsHistorical(
    $input: DeleteChatbotAnnouncementsHistoricalInput!
  ) {
    deleteChatbotAnnouncementsHistorical(input: $input) {
      id
      name
      createdAt
      text
      startTime
      startedBy
      endTime
      endedBy
      group
      showOn
      versionId
      updatedAt
    }
  }
`;
export const createChatbotAnnouncementAnalytics = /* GraphQL */ `
  mutation CreateChatbotAnnouncementAnalytics(
    $input: CreateChatbotAnnouncementAnalyticsInput!
  ) {
    createChatbotAnnouncementAnalytics(input: $input) {
      id
      asurite
      announcementId
      versionId
      action
      createdAt
      updatedAt
    }
  }
`;
export const updateChatbotAnnouncementAnalytics = /* GraphQL */ `
  mutation UpdateChatbotAnnouncementAnalytics(
    $input: UpdateChatbotAnnouncementAnalyticsInput!
  ) {
    updateChatbotAnnouncementAnalytics(input: $input) {
      id
      asurite
      announcementId
      versionId
      action
      createdAt
      updatedAt
    }
  }
`;
export const deleteChatbotAnnouncementAnalytics = /* GraphQL */ `
  mutation DeleteChatbotAnnouncementAnalytics(
    $input: DeleteChatbotAnnouncementAnalyticsInput!
  ) {
    deleteChatbotAnnouncementAnalytics(input: $input) {
      id
      asurite
      announcementId
      versionId
      action
      createdAt
      updatedAt
    }
  }
`;
export const createChatbotIntroMessage = /* GraphQL */ `
  mutation CreateChatbotIntroMessage($input: CreateChatbotIntroMessageInput!) {
    createChatbotIntroMessage(input: $input) {
      id
      message
      status
      statusKey
      botType
      matches
      group
      createdBy
      deleted
      deletedBy {
        asurite
        timestamp
      }
      searchKey
      createdAt
      updatedAt
    }
  }
`;
export const updateChatbotIntroMessage = /* GraphQL */ `
  mutation UpdateChatbotIntroMessage($input: UpdateChatbotIntroMessageInput!) {
    updateChatbotIntroMessage(input: $input) {
      id
      message
      status
      statusKey
      botType
      matches
      group
      createdBy
      deleted
      deletedBy {
        asurite
        timestamp
      }
      searchKey
      createdAt
      updatedAt
    }
  }
`;
export const deleteChatbotIntroMessage = /* GraphQL */ `
  mutation DeleteChatbotIntroMessage($input: DeleteChatbotIntroMessageInput!) {
    deleteChatbotIntroMessage(input: $input) {
      id
      message
      status
      statusKey
      botType
      matches
      group
      createdBy
      deleted
      deletedBy {
        asurite
        timestamp
      }
      searchKey
      createdAt
      updatedAt
    }
  }
`;
export const createChatbotIntroMessageHistorical = /* GraphQL */ `
  mutation CreateChatbotIntroMessageHistorical(
    $input: CreateChatbotIntroMessageHistoricalInput!
  ) {
    createChatbotIntroMessageHistorical(input: $input) {
      id
      introId
      message
      status
      statusKey
      botType
      matches
      startTime
      startedBy
      endTime
      endedBy
      versionId
      createdAt
      updatedAt
    }
  }
`;
export const updateChatbotIntroMessageHistorical = /* GraphQL */ `
  mutation UpdateChatbotIntroMessageHistorical(
    $input: UpdateChatbotIntroMessageHistoricalInput!
  ) {
    updateChatbotIntroMessageHistorical(input: $input) {
      id
      introId
      message
      status
      statusKey
      botType
      matches
      startTime
      startedBy
      endTime
      endedBy
      versionId
      createdAt
      updatedAt
    }
  }
`;
export const deleteChatbotIntroMessageHistorical = /* GraphQL */ `
  mutation DeleteChatbotIntroMessageHistorical(
    $input: DeleteChatbotIntroMessageHistoricalInput!
  ) {
    deleteChatbotIntroMessageHistorical(input: $input) {
      id
      introId
      message
      status
      statusKey
      botType
      matches
      startTime
      startedBy
      endTime
      endedBy
      versionId
      createdAt
      updatedAt
    }
  }
`;
export const createChatbotIntroMessageAnalytics = /* GraphQL */ `
  mutation CreateChatbotIntroMessageAnalytics(
    $input: CreateChatbotIntroMessageAnalyticsInput!
  ) {
    createChatbotIntroMessageAnalytics(input: $input) {
      id
      asurite
      introId
      action
      createdAt
      updatedAt
    }
  }
`;
export const updateChatbotIntroMessageAnalytics = /* GraphQL */ `
  mutation UpdateChatbotIntroMessageAnalytics(
    $input: UpdateChatbotIntroMessageAnalyticsInput!
  ) {
    updateChatbotIntroMessageAnalytics(input: $input) {
      id
      asurite
      introId
      action
      createdAt
      updatedAt
    }
  }
`;
export const deleteChatbotIntroMessageAnalytics = /* GraphQL */ `
  mutation DeleteChatbotIntroMessageAnalytics(
    $input: DeleteChatbotIntroMessageAnalyticsInput!
  ) {
    deleteChatbotIntroMessageAnalytics(input: $input) {
      id
      asurite
      introId
      action
      createdAt
      updatedAt
    }
  }
`;
export const createImage = /* GraphQL */ `
  mutation CreateImage($input: CreateImageInput!) {
    createImage(input: $input) {
      id
      imageId
      title
      description
      author
      group
      deleted
      deletedBy {
        asurite
        timestamp
      }
      botType
      createdAt
      updatedAt
    }
  }
`;
export const updateImage = /* GraphQL */ `
  mutation UpdateImage($input: UpdateImageInput!) {
    updateImage(input: $input) {
      id
      imageId
      title
      description
      author
      group
      deleted
      deletedBy {
        asurite
        timestamp
      }
      botType
      createdAt
      updatedAt
    }
  }
`;
export const deleteImage = /* GraphQL */ `
  mutation DeleteImage($input: DeleteImageInput!) {
    deleteImage(input: $input) {
      id
      imageId
      title
      description
      author
      group
      deleted
      deletedBy {
        asurite
        timestamp
      }
      botType
      createdAt
      updatedAt
    }
  }
`;
export const createLink = /* GraphQL */ `
  mutation CreateLink($input: CreateLinkInput!) {
    createLink(input: $input) {
      id
      originalURL
      linkText
      createdBy
      group
      createdAt
      updatedAt
    }
  }
`;
export const updateLink = /* GraphQL */ `
  mutation UpdateLink($input: UpdateLinkInput!) {
    updateLink(input: $input) {
      id
      originalURL
      linkText
      createdBy
      group
      createdAt
      updatedAt
    }
  }
`;
export const deleteLink = /* GraphQL */ `
  mutation DeleteLink($input: DeleteLinkInput!) {
    deleteLink(input: $input) {
      id
      originalURL
      linkText
      createdBy
      group
      createdAt
      updatedAt
    }
  }
`;
export const createLinkAnalytics = /* GraphQL */ `
  mutation CreateLinkAnalytics($input: CreateLinkAnalyticsInput!) {
    createLinkAnalytics(input: $input) {
      id
      linkId
      createdAt
      aliasId
      clickedBy
      userType
      updatedAt
    }
  }
`;
export const updateLinkAnalytics = /* GraphQL */ `
  mutation UpdateLinkAnalytics($input: UpdateLinkAnalyticsInput!) {
    updateLinkAnalytics(input: $input) {
      id
      linkId
      createdAt
      aliasId
      clickedBy
      userType
      updatedAt
    }
  }
`;
export const deleteLinkAnalytics = /* GraphQL */ `
  mutation DeleteLinkAnalytics($input: DeleteLinkAnalyticsInput!) {
    deleteLinkAnalytics(input: $input) {
      id
      linkId
      createdAt
      aliasId
      clickedBy
      userType
      updatedAt
    }
  }
`;
export const createLinkCreation = /* GraphQL */ `
  mutation CreateLinkCreation($input: CreateLinkCreationInput!) {
    createLinkCreation(input: $input) {
      id
      linkId
      aliasId
      tableId
      tableName
      createdAt
      updatedAt
    }
  }
`;
export const updateLinkCreation = /* GraphQL */ `
  mutation UpdateLinkCreation($input: UpdateLinkCreationInput!) {
    updateLinkCreation(input: $input) {
      id
      linkId
      aliasId
      tableId
      tableName
      createdAt
      updatedAt
    }
  }
`;
export const deleteLinkCreation = /* GraphQL */ `
  mutation DeleteLinkCreation($input: DeleteLinkCreationInput!) {
    deleteLinkCreation(input: $input) {
      id
      linkId
      aliasId
      tableId
      tableName
      createdAt
      updatedAt
    }
  }
`;
export const createChatbotAnalyticsTracker = /* GraphQL */ `
  mutation CreateChatbotAnalyticsTracker(
    $input: CreateChatbotAnalyticsTrackerInput!
  ) {
    createChatbotAnalyticsTracker(input: $input) {
      id
      event
      timestamp
      asurite
      phoneNumber
      convoId
      userId
      agentAsurite
      type
      firstName
      lastName
      emplid
      agentPauseAsurite
      agentOptOut
      userOptOut
      isPaused
      botType
      tempField
      duringCampaign
      campaigns {
        id
        status
        startTime
        endTime
        error
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateChatbotAnalyticsTracker = /* GraphQL */ `
  mutation UpdateChatbotAnalyticsTracker(
    $input: UpdateChatbotAnalyticsTrackerInput!
  ) {
    updateChatbotAnalyticsTracker(input: $input) {
      id
      event
      timestamp
      asurite
      phoneNumber
      convoId
      userId
      agentAsurite
      type
      firstName
      lastName
      emplid
      agentPauseAsurite
      agentOptOut
      userOptOut
      isPaused
      botType
      tempField
      duringCampaign
      campaigns {
        id
        status
        startTime
        endTime
        error
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteChatbotAnalyticsTracker = /* GraphQL */ `
  mutation DeleteChatbotAnalyticsTracker(
    $input: DeleteChatbotAnalyticsTrackerInput!
  ) {
    deleteChatbotAnalyticsTracker(input: $input) {
      id
      event
      timestamp
      asurite
      phoneNumber
      convoId
      userId
      agentAsurite
      type
      firstName
      lastName
      emplid
      agentPauseAsurite
      agentOptOut
      userOptOut
      isPaused
      botType
      tempField
      duringCampaign
      campaigns {
        id
        status
        startTime
        endTime
        error
      }
      createdAt
      updatedAt
    }
  }
`;
export const createChatbotInteractionSessions = /* GraphQL */ `
  mutation CreateChatbotInteractionSessions(
    $input: CreateChatbotInteractionSessionsInput!
  ) {
    createChatbotInteractionSessions(input: $input) {
      sessionId
      type
      callingNumber
      convoId
      status
      sendTranscript
      createdAt
      updatedAt
    }
  }
`;
export const updateChatbotInteractionSessions = /* GraphQL */ `
  mutation UpdateChatbotInteractionSessions(
    $input: UpdateChatbotInteractionSessionsInput!
  ) {
    updateChatbotInteractionSessions(input: $input) {
      sessionId
      type
      callingNumber
      convoId
      status
      sendTranscript
      createdAt
      updatedAt
    }
  }
`;
export const deleteChatbotInteractionSessions = /* GraphQL */ `
  mutation DeleteChatbotInteractionSessions(
    $input: DeleteChatbotInteractionSessionsInput!
  ) {
    deleteChatbotInteractionSessions(input: $input) {
      sessionId
      type
      callingNumber
      convoId
      status
      sendTranscript
      createdAt
      updatedAt
    }
  }
`;
