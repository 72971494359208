import React, { useState, useEffect } from "react";
import { useTheme } from "@material-ui/core/styles";
import { useFormContext } from "react-hook-form";

import "../forms.css";

import { ErrorLabel } from "stories";

export default function CheckboxAudienceForm(props) {
  const theme = useTheme();
  const { register, errors, setError, clearError } = useFormContext();
  const [value, setValue] = useState(
    props.stateValue ? props.stateValue : false
  );

  // custom checkbox
  const customCheckboxStyle = {
    position: "absolute",
    top: "0",
    left: "0",
    background: theme.palette.general.white,
    width: "14px",
    height: "14px",
    //color: "white",
    "&:hover": {
      background: theme.palette.general.mediumGrey
    },
    border: "2px solid",
    borderRadius: "2px",
    borderColor: theme.palette.general.darkGrey
  };

  // custom checkmark long rectangle
  const checkmarkIndicatorStyle1 = {
    content: "",
    position: "absolute",
    display: "block",
    backgroundColor: theme.palette.general.darkGrey,
    left: "7.5px",
    top: "1.5px",
    width: "1px",
    height: "9px",
    border: "solid black",
    borderWidth: "0 1px 1px 0",
    WebkitTransform: "rotate(45deg)",
    msTransform: "rotate(45deg)",
    transform: "rotate(45deg)"
  };

  // custom checkmark short rectangle
  const checkmarkIndicatorStyle2 = {
    content: "",
    position: "absolute",
    display: "block",
    backgroundColor: theme.palette.general.darkGrey,
    left: "3px",
    top: "5.5px",
    width: "1px",
    height: "5px",
    border: "solid black",
    borderWidth: "0 1px 1px 0",
    WebkitTransform: "rotate(135deg)",
    msTransform: "rotate(135deg)",
    transform: "rotate(135deg)"
  };

  // Text label style
  const labelStyle = {
    display: "block",
    position: "relative",
    paddingLeft: "28px",
    marginBottom: "12px",
    cursor: "pointer",
    fontSize: "16px",
    WebkitUserSelect: "none",
    MozUserSelect: "none",
    msUserSelect: "none",
    userSelect: "none"
  };

  // hide the default checkbox
  const hiddenCheckboxStyle = {
    position: "absolute",
    opacity: "0",
    cursor: "pointer",
    height: "0",
    width: "0"
  };

  // returns a checkmark shape if true
  const getCheckStyle = () => {
    if (value === true) {
      return (
        <div>
          <span style={checkmarkIndicatorStyle1}></span>
          <span style={checkmarkIndicatorStyle2}></span>
        </div>
      );
    } else {
      return null;
    }
  };

  const toggleValue = e => {
    setValue(e.target.checked);
  };

  const { form, cannotEqualList, errorMessage, ...validProps } = props;

  return (
    <div {...validProps}>
      <label style={labelStyle}>
        {" "}
        {props.displayname}
        <input
          {...validProps}
          ref={props.required ? register({ required: true }) : register}
          className="checkbox"
          type="checkbox"
          value={value}
          onClick={toggleValue}
          autoComplete="off"
          style={hiddenCheckboxStyle}
        />
        <span style={customCheckboxStyle}>{getCheckStyle()}</span>
      </label>
      {errors[props.name] && errors[props.name].message ? (
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <div style={{ display: "flex", flex: 1 }} />
          <ErrorLabel message={errors[props.name].message} />
          <div style={{ display: "flex", flex: 1 }} />
        </div>
      ) : null}
    </div>
  );
}
