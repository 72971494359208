import React, { useState, useEffect } from "react";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { useFormContext } from "react-hook-form";
import "./InputAdd.css";
import { idGenerator } from "services/utility/util.js";

export default function InpudAddForm(props) {
  let { invalidmatch } = props;

  const theme = useTheme();
  const { register } = useFormContext();

  const [currInput, setCurrInput] = useState("");
  const [matches, setMatches] = useState(props.matches);
  const [invalid, setInvalid] = useState(false);
  const [editInd, setEditInd] = useState(-1);
  // const [toDelete, setToDelete] = useState(-1);

  useEffect(() => {
    setMatches(props.matches);
  }, [props.matches]);

  const useStyles = makeStyles((theme) => ({
    required: {
      ...theme.typography.subtitle2,
      backgroundColor: theme.palette.general.lightOrange,
      textAlign: "center",
      padding: "0.5rem 1rem",
      margin: "4rem 0",
      borderRadius: "4px",
    },
    button: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "5rem",
      fontFamily: "Roboto",
      color: "white",
      backgroundColor: theme.palette.general.darkGrey,
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
      cursor: "pointer",
      pointerEvents: props.viewOnly ? "none" : "all"
    },
  }));

  const classes = useStyles();

  const addToMatches = () => {
    let matchStr = invalidmatch !== undefined;
    let isValid = false;

    if (matchStr && !invalidmatch.test(currInput)) {
      isValid = true;
    }

    if (isValid) {
      for (var i = 0; i < matches.length; ++i) {
        if (matches[i].text.toLowerCase() === currInput.toLowerCase()) {
          isValid = false;
          break;
        }
      }
    }

    if (editInd > -1) {
      let m = JSON.parse(JSON.stringify(matches));
      m[editInd].text = currInput;
      m[editInd].didEdit = true;
      setCurrInput("");
      setMatches(m);
      setEditInd(-1);
    } else if (currInput.length > 0 && (!matchStr || (matchStr && isValid))) {
      let m = matches.concat({ id: idGenerator(), text: currInput });
      setCurrInput("");
      setMatches(m);
      if (props.contref) {
        setTimeout(() => {
          props.contref.scrollTop = props.contref.scrollHeight;
        }, 200);
      }
    } else {
      setInvalid(true);
    }
  };

  const AddButton = (props) => {
    return (
      <label {...props} className={classes.button} onClick={addToMatches}>
        Add
      </label>
    );
  };

  const removeMatch = (e, ind) => {
    e.stopPropagation();
    if (props.deletewarning && !props.isNew) {
      props.deletewarning(matches[ind]);
    } else {
      let m = JSON.parse(JSON.stringify(matches));
      m.splice(ind, 1);
      setMatches(m);
    }
  };

  const editMatch = (ind) => {
    setEditInd(ind);
    setCurrInput(matches[ind].text);
  };

  const _renderMapping = (i, ind) => {
    let mainClasses = "mainMatch ";
    if (ind === matches.length - 1) {
      mainClasses += "bottomMatch";
    }

    return (
      <div
        className={mainClasses}
        key={"matchOn_" + ind}
        onClick={!props.viewOnly ? () => editMatch(ind) : () => {}}
      >
        <div className="matchInfo">
          [{ind + 1}] {i.text}
        </div>
        {props.noedit || props.viewOnly ? null : (
          <div
            className="removeBtn"
            style={{ color: theme.palette.general.lightMaroon }}
            onClick={(e) => removeMatch(e, ind)}
          >
            Remove
          </div>
        )}
      </div>
    );
  };

  let invalidClasses = "invalid ";
  if (invalid) {
    invalidClasses += "showInvalid";
  }

  return (
    <div style={{ cursor: "default" }}>
      {props.noedit ? null : (
        <div style={{ display: "flex" }}>
          <input
            className="input"
            value={currInput}
            readOnly={props.viewOnly ? "readonly" : false}
            style={{
              ...theme.typography.body1,
              width: "100%",
              borderTopRightRadius: "0px",
              borderBottomRightRadius: "0px",
              border: `1px solid ${theme.palette.general.deepGrey}`,
              color: theme.palette.general.darkGrey,
              ...props.style,
            }}
            type="text"
            maxlength={props.maxlength ? props.maxlength : null}
            onChange={(e) => {
              setInvalid(false);
              setCurrInput(e.target.value);
            }}
          />
          <AddButton htmlFor="add" />
        </div>
      )}
      {props.noedit || !props.maxlength ? null : (
        <div
          style={{
            width: props.allowextras ? "fit-content" : "100%",
            textAlign: "right",
          }}
        >
          {currInput.length + "/" + props.maxlength}
        </div>
      )}

      <div className={invalidClasses}>Invalid Entry</div>
      <div style={{ paddingTop: "20px" }}>
        {matches.map((item, index) => _renderMapping(item, index))}
      </div>
      <input
        style={{ display: "none" }}
        name={props.name}
        ref={
          props.form
            ? props.required
              ? register({ required: true })
              : register
            : null
        }
        type="hidden"
        value={JSON.stringify(matches)}
        onChange={(e) => console.log("change")}
      />
    </div>
  );
}
