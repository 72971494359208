import React, { useState, useEffect, useContext } from 'react';
import Paper from '@material-ui/core/Paper';
import { useTheme } from '@material-ui/core/styles';
import moment from 'moment';

import AuthContext from 'contexts/AuthContext';
import { roleCheck } from 'services/utility/RoleMatrix';
import { store as GlobalStore } from 'contexts/GlobalStore';
import { SectionHeader } from 'components/SectionHeader';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from 'graphql/mutations';
import * as queries from 'graphql/queries';
import { onUpdateCampaign } from 'graphql/subscriptions';
import PaginationPanel from 'stories/Table/PaginationPanel';
import { Table, Modal, ModalContent, CreateNewButton } from 'stories';
import './Campaign.scss';

let subscription = null;
// let campaigns = rawData;
let rawData = [];

export default function Campaigns(props) {
  const theme = useTheme();
  const tokens = useContext(AuthContext);
  const GlobalState = useContext(GlobalStore);
  const dispatchGlobal = GlobalState.dispatch;
  const {
    tableSort,
    spliceTableData,
    clearNextTokens,
    tableDataEmpty,
    globalTableState,
  } = GlobalState.state;

  const [data, setData] = useState([]);
  // const [rawData, setRawData] = useState([]);
  const [rerenderKey, setRerenderKey] = useState(Date.now());
  const [refetchKey, setRefetchKey] = useState(Date.now());
  const [editClicked, setEditClicked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showStartModal, setShowStartModal] = useState(false);
  const [campaignToPause, setCampaignToPause] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [prevPageClicked, setPrevPageClicked] = useState(false);
  const [filter, setFilter] = useState('All');
  const [group, setGroup] = useState('All');
  const [nextTokenList, setNextTokenList] = useState([]);
  const [searchActive, setSearchActive] = useState(false);
  const [totalResults, setTotalResults] = useState(null);
  const [loadedTableState, setLoadedTableState] = useState(false);
  const [loadedTableSearchState, setLoadedTableSearchState] = useState(false);

  const pageLimit = 7;

  useEffect(() => {
    dispatchGlobal({
      type: 'setTableDataEmpty',
      tableDataEmpty: false,
    });
  }, []);

  useEffect(() => {
    if (tableDataEmpty) {
      dispatchGlobal({
        type: 'setLoadingTableData',
        loadingTableData: false,
      });
    } else if (!tableDataEmpty && !loadedTableState) {
      const pathName = window.location.pathname.replace('/', '');
      let storedPage =
        globalTableState &&
        globalTableState[pathName] &&
        globalTableState[pathName].pageNumber;
      if (storedPage) {
        setCurrentPage(storedPage);
        setLoadedTableState(true);
      }
    }
  }, [tableDataEmpty]);

  useEffect(() => {
    const pathName = window.location.pathname.replace('/', '');
    let newGlobalTableState = Object.assign({}, globalTableState);
    if (!newGlobalTableState[pathName]) {
      newGlobalTableState[pathName] = {};
    }
    newGlobalTableState[pathName].pageNumber = currentPage;
    dispatchGlobal({
      type: 'setGlobalTableState',
      globalTableState: newGlobalTableState,
    });
  }, [currentPage]);

  useEffect(() => {
    createFetchData();
  }, [searchActive, refetchKey, rerenderKey, filter, group, tableSort]);

  const fetchData = async (searchText, filter, sort) => {
    if (tableDataEmpty) {
      dispatchGlobal({
        type: 'setLoadingTableData',
        loadingTableData: false,
      });
    } else {
      dispatchGlobal({
        type: 'setLoadingTableData',
        loadingTableData: true,
      });
    }
    try {
      let sortQuery = { field: 'timestampKey', direction: 'desc' };
      if (sort) {
        sortQuery = { field: sort.field, direction: sort.direction };
      }

      let result = '';
      if (tokens.role === 'approver' || tokens.role === 'content creator') {
        result = await API.graphql(
          graphqlOperation(queries.searchCampaigns, {
            limit: 100,
            filter: createFilterQuery(searchText, filter, true),
            sort: sortQuery,
          })
        );
      } else {
        result = await API.graphql(
          graphqlOperation(queries.searchCampaigns, {
            limit: 100,
            filter: createFilterQuery(searchText, filter, false),
            sort: sortQuery,
          })
        );
      }

      let loadedUpdate = true;
      let loadedDelete = false;
      let loadedNew = false;

      if (spliceTableData) {
        if (spliceTableData.type === 'deleteCampaign') {
          let itemIds = [];
          result.data.searchCampaigns.items.forEach((el, index) => {
            itemIds.push(el.id);
          });
          if (!itemIds.includes(spliceTableData.id)) {
            loadedDelete = true;
            dispatchGlobal({
              type: 'setSpliceTableData',
              spliceTableData: null,
            });
          } else {
            loadedDelete = false;
            dispatchGlobal({
              type: 'setSpliceTableData',
              spliceTableData: { ...spliceTableData },
            });
            return setRerenderKey(Date.now());
          }
        } else {
          result.data.searchCampaigns.items.forEach((el, index) => {
            if (spliceTableData.type === 'delete') {
              loadedNew = true;
            } else if (spliceTableData.type === 'editCampaign') {
              if (el.timestamp === spliceTableData.item.timestamp.toString()) {
                loadedNew = true;
                dispatchGlobal({
                  type: 'setSpliceTableData',
                  spliceTableData: null,
                });
              }
            } else if (spliceTableData.type === 'addCampaign') {
              if (el.id === spliceTableData.item.id) {
                loadedNew = true;
                dispatchGlobal({
                  type: 'setSpliceTableData',
                  spliceTableData: null,
                });
              }
            }
          });
        }
      } else if (!spliceTableData) {
        loadedNew = true;
        loadedDelete = true;
      }

      if (!result.data.searchCampaigns.items.length) {
        return dispatchGlobal({
          type: 'setTableDataEmpty',
          tableDataEmpty: true,
        });
      } else {
        dispatchGlobal({
          type: 'setTableDataEmpty',
          tableDataEmpty: false,
        });
      }

      if (!loadedUpdate || !loadedNew || !loadedDelete) {
        return setRerenderKey(Date.now());
      } else if (loadedUpdate && loadedNew && loadedDelete) {
        dispatchGlobal({
          type: 'setSpliceTableData',
          spliceTableData: null,
        });

        // setRawData(result.data.searchCampaigns.items);
        rawData = result.data.searchCampaigns.items;
        let parsedData = parseData(result.data.searchCampaigns.items);
        let nextToken = '';
        if (result.data.searchCampaigns.nextToken) {
          nextToken = result.data.searchCampaigns.nextToken;
        }
        const total = result.data.searchCampaigns.total;
        if (total) {
          setTotalResults(total);
        }

        if (total == 0 && currentPage > 1) setCurrentPage(currentPage - 1);

        setData(parsedData);

        if (
          result.data.searchCampaigns.total !==
          result.data.searchCampaigns.items.length
        ) {
          fetchMoreData(
            searchText,
            filter,
            sortQuery,
            parsedData,
            result.data.searchCampaigns.total,
            nextToken
          );
        }

        dispatchGlobal({
          type: 'setLoadingTableData',
          loadingTableData: false,
        });
      }
    } catch (error) {
      console.log('campaigns fetch data error: ', error);
    } finally {
      tableDataEmpty &&
        dispatchGlobal({
          type: 'setLoadingTableData',
          loadingTableData: false,
        });
    }
  };

  const fetchMoreData = async (
    searchText,
    filter,
    sortQuery,
    previousData,
    total,
    nextToken
  ) => {
    let result = null;

    if (tokens.role === 'approver' || tokens.role === 'content creator') {
      result = await API.graphql(
        graphqlOperation(queries.searchCampaigns, {
          limit: 100,
          filter: createFilterQuery(searchText, filter, true),
          sort: sortQuery,
          nextToken: nextToken,
        })
      );
    } else {
      result = await API.graphql(
        graphqlOperation(queries.searchCampaigns, {
          limit: 100,
          filter: createFilterQuery(searchText, filter, false),
          sort: sortQuery,
          nextToken: nextToken,
        })
      );
    }

    if (result) {
      let parsedData = parseData(result.data.searchCampaigns.items);
      let nextToken = '';
      if (result.data.searchCampaigns.nextToken) {
        nextToken = result.data.searchCampaigns.nextToken;
      }

      let newPreviousData = Array.from(previousData);

      newPreviousData = newPreviousData.concat(parsedData);
      setData(newPreviousData);

      if (
        previousData.length + parsedData.length <
          result.data.searchCampaigns.total &&
        nextToken !== ''
      ) {
        fetchMoreData(
          searchText,
          filter,
          sortQuery,
          newPreviousData,
          total,
          nextToken
        );
      }
    }
  };

  const createFetchData = () => {
    //restore previous search
    const pathName = window.location.pathname.replace('/', '');
    let localSearchActive = searchActive;
    let localSearchKeyword = searchKeyword;
    if (
      !loadedTableSearchState &&
      globalTableState &&
      globalTableState[pathName] &&
      globalTableState[pathName].search !== ''
    ) {
      localSearchActive = true;
      localSearchKeyword = globalTableState[pathName].search;
      setSearchActive(true);
      setSearchKeyword(globalTableState[pathName].search);
    }
    setLoadedTableSearchState(true);

    if (localSearchActive && filter !== 'All' && tableSort) {
      fetchData(localSearchKeyword, filter, tableSort);
    } else if (localSearchActive && filter !== 'All' && !tableSort) {
      fetchData(localSearchKeyword, filter, null);
    } else if (localSearchActive && filter === 'All' && tableSort) {
      fetchData(localSearchKeyword, null, tableSort);
    } else if (localSearchKeyword === '' && filter !== 'All' && tableSort) {
      fetchData(null, filter, tableSort);
    } else if (localSearchActive) {
      fetchData(localSearchKeyword);
    } else if (filter !== 'All') {
      fetchData(null, filter);
    } else if (tableSort) {
      fetchData(null, null, tableSort);
    } else {
      fetchData();
    }
  };

  const canPause = s => {
    if (!roleCheck(tokens.role, 'campaigns', 'pauseResume')) {
      return false;
    } else {
      if (s === 8) {
        return true;
      }
      return false;
    }
  };

  const canDelete = (s, group, asurite) => {
    if (!roleCheck(tokens.role, 'campaigns', 'delete')) {
      return false;
    } else {
      if (s < 6) {
        return true;
      }
      return false;
    }
  };

  const canView = s => {
    if (s >= 8) {
      return true;
    }
  };

  const canEdit = (s, group, asurite) => {
    if (tokens.asurite === asurite) {
      if (s < 6) {
        return true;
      }
      return false;
    }
    if (!roleCheck(tokens.role, 'campaigns', 'edit')) {
      return false;
    } else {
      if (roleCheck(tokens.role, 'campaigns', 'viewAllContent')) {
        if (s < 6) {
          return true;
        }
        return false;
      } else {
        if (tokens.groups.includes(group)) {
          if (s < 6) {
            return true;
          }
          return false;
        } else {
          return false;
        }
      }
    }
  };

  const parseData = data => {
    return data.map(el => {
      return {
        id: el.id,
        name: el.title,
        description: el.description,
        status: {
          statusID: el.status,
          paused: el.paused,
          processing: el.processing,
          error: el.processingError ? 'error' : null,
          tooltip:
            el.status === 7 ? (el.sendNumbers ? el.sendNumbers : null) : null,
        },
        group: el.group,
        startTime: el.startTime,
        endTime: el.endTime,
        targetAudience: el.audience,
        submittedBy: el.createdBy,
        actions: {
          edit: canEdit(el.status, el.group, el.createdBy),
          pause: el.paused ? false : canPause(el.status),
          start: el.paused,
          view: canView(el.status, el.createdBy),
          delete: canDelete(el.status, el.group, el.createdBy),
        },
      };
    });
  };

  useEffect(() => {
    if (!subscription || subscription._state === 'closed') {
      subscription = API.graphql(graphqlOperation(onUpdateCampaign)).subscribe(
        data => {
          let kbItem = data.value.data.onUpdateCampaign;
          let foundId = true;
          // console.log("HITTING SUB", rawData, kbItem);

          for (var i = 0; i < rawData.length; ++i) {
            if (rawData[i].id === kbItem.id) {
              foundId = true;
              for (let key in kbItem) {
                if (key === 'editedBy') {
                  rawData[i].editedBy = [];
                  if (!kbItem.editedBy) kbItem.editedBy = [];
                  for (var j = 0; j < kbItem.editedBy.length; ++j) {
                    rawData[i].editedBy.push({
                      asurite: kbItem.editedBy[j].asurite,
                      timestamp: kbItem.editedBy[j].timestamp,
                    });
                  }
                } else {
                  rawData[i][key] = kbItem[key];
                }
              }
              break;
            }
          }

          if (foundId) {
            if (kbItem.deleted) {
              createFetchData();
            } else {
              let parsedData = parseData(rawData);
              setData(parsedData);
            }
          }
        }
      );

      return () => {
        if (subscription) {
          subscription.unsubscribe();
        } else {
          console.warn('Failed to clean up subscription to Campaigns');
        }
      };
    }
  }, []);

  const createFilterQuery = (searchText, filter, groupFilterFlag) => {
    const filterConditions = [];

    // Remove Benji
    filterConditions.push({ group: { ne: 'Benji_1593621826' } });

    if (!roleCheck(tokens.role, 'campaigns', 'viewAllContent')) {
      tokens.groups.forEach(el => {
        filterConditions.push({ group: { eq: el } });
      });
      filterConditions.push({ createdBy: { eq: tokens.asurite } });
      filterConditions.push({ status: { eq: getStatusKey('Published') } });
      filterConditions.push({ status: { eq: getStatusKey('Approved') } });
      // filterConditions.push({ status: { eq: getStatusKey("Sending") } });
    }

    let filterQuery = {
      deleted: { eq: false },
    };

    if (groupFilterFlag) {
      filterQuery.or = [];
      let grps = tokens.groups;
      for (var i = 0; i < grps.length; ++i) {
        filterQuery.or.push({
          or: [{ group: { eq: grps[i] } }],
        });
      }
    } else if (group !== 'All') {
      filterQuery.or = [];
      let grp = group;
      filterQuery.or.push({
        or: [{ group: { eq: grp } }],
      });
    }

    filterQuery.and = [
      {
        or: [...filterConditions],
      },
    ];

    if (searchText && filter) {
      filterQuery.title = { matchPhrasePrefix: searchText };
      if (filter === 'Paused') {
        filterQuery.paused = { eq: true };
      } else {
        filterQuery.status = { eq: getStatusKey(filter) };
        filterQuery.paused = { eq: false };
      }
    } else if (filter) {
      if (filter === 'Paused') {
        filterQuery.paused = { eq: true };
      } else {
        filterQuery.status = { eq: getStatusKey(filter) };
        filterQuery.paused = { eq: false };
      }
    } else if (searchText) {
      filterQuery.title = { matchPhrasePrefix: searchText };
    }

    return filterQuery;
  };

  const getStatusKey = name => {
    if (name === 'Pending') {
      return 1;
    } else if (name === 'Approved') {
      return 3;
    } else if (name === 'Unapproved') {
      return 2;
    } else if (name === 'Published') {
      return 4;
    } else if (name === 'Sending') {
      return 7;
    } else if (name === 'Active') {
      return 8;
    } else if (name === 'Finished') {
      return 9;
    } else if (name === 'Paused') {
      return 14;
    } else {
      return null;
    }
  };

  const getSortKey = label => {
    label = label.toUpperCase();
    if (label === 'STATUS') {
      return 'statusKey';
    } else if (label === 'START') {
      return 'startTimeKey';
    } else if (label === 'END') {
      return 'endTimeKey';
    } else if (label === 'NAME') {
      return 'titleKey';
    } else if (label === 'GROUP') {
      return 'groupKey';
    } else if (label === 'TARGET AUDIENCE') {
      return 'audience';
    } else if (label === 'SUBMITTED BY') {
      return 'createdBy';
    }
  };

  const onChangeSearch = value => {
    setSearchKeyword(value);
  };

  const onClickSearch = () => {
    if (searchKeyword === '') {
      return;
    } else {
      setSearchActive(true);
      if (currentPage === 1) {
        setRerenderKey(Date.now());
      } else {
        setCurrentPage(1);
      }
    }
  };

  const onClickReset = () => {
    setSearchActive(false);
    dispatchGlobal({
      type: 'changeTableSort',
      tableSort: null,
    });

    if (group !== 'All') {
      setGroup('All');
    } else if (currentPage === 1) {
      setRerenderKey(Date.now());
    } else {
      setCurrentPage(1);
    }
  };

  const onSelectFilter = value => {
    setCurrentPage(1);
    dispatchGlobal({
      type: 'clearNextTokens',
      clearNextTokens: true,
    });
    if (value === filter || !value) {
      setFilter('All');
      dispatchGlobal({ type: 'changeTableFilter', tableFilter: 'All' });
    } else {
      setFilter(value);
      dispatchGlobal({ type: 'changeTableFilter', tableFilter: value });
    }
  };

  const onSelectGroup = value => {
    setCurrentPage(1);
    dispatchGlobal({
      type: 'clearNextTokens',
      clearNextTokens: true,
    });
    if (value === group || !value) {
      setGroup('All');
      dispatchGlobal({ type: 'changeTableFilter', tableFilter: 'All' });
    } else {
      setGroup(value);
      dispatchGlobal({ type: 'changeTableFilter', tableFilter: value });
    }
  };

  const onClickEdit = data => {
    dispatchGlobal({ type: 'setFormTitle', formTitle: 'EDIT CAMPAIGN' });
    let campaignToEdit = {};
    rawData.forEach(el => {
      if (data.id === el.id) {
        campaignToEdit = el;
      }
    });

    campaignToEdit.flow = JSON.parse(campaignToEdit.flow);
    dispatchGlobal({ type: 'updateEditInfo', editInfo: { ...campaignToEdit } });
    props.history.push({
      pathname: '/campaign-form',
    });
  };

  const onClickPause = dataX => {
    setCampaignToPause(dataX);
    setShowModal(true);
  };

  const onConfirmPause = async () => {
    let payload = {
      operation: 'pauseCampaign',
      campaign: {
        id: campaignToPause.id,
      },
    };

    await API.graphql(graphqlOperation(queries.runCampaign, payload))
      .then(resp => {
        // console.log("DONE PUASING");
      })
      .catch(err => {
        console.log('Errored pausing', err);
      });

    const pauseCampaign = await API.graphql(
      graphqlOperation(mutations.updateCampaign, {
        input: { id: campaignToPause.id, paused: true },
      })
    );
    if (pauseCampaign.data) {
      toggleModal();
    }
  };

  const onClickStart = dataX => {
    setCampaignToPause(dataX);
    setShowStartModal(true);
  };

  const onConfirmStart = async () => {
    let payload = {
      operation: 'restartCampaign',
      campaign: {
        id: campaignToPause.id,
      },
    };

    await API.graphql(graphqlOperation(queries.runCampaign, payload))
      .then(resp => {
        // console.log("DONE START");
      })
      .catch(err => {
        console.log('Errored START', err);
      });

    const pauseCampaign = await API.graphql(
      graphqlOperation(mutations.updateCampaign, {
        input: { id: campaignToPause.id, paused: false },
      })
    );
    if (pauseCampaign.data) {
      toggleStartModal();
    }
  };

  const onSubmitDelete = async item => {
    try {
      dispatchGlobal({
        type: 'setSpliceTableData',
        spliceTableData: { ...item, type: 'deleteCampaign' },
      });
      const updatedData = {
        id: item.id,
        deleted: true,
        deletedBy: { asurite: tokens.asurite, timestamp: moment().valueOf() },
      };

      API.graphql(
        graphqlOperation(mutations.updateCampaign, {
          input: updatedData,
        })
      );
    } catch (error) {
      console.log('updateCampaign error: ', error);
    } finally {
      // dispatchGlobal({ type: "setRefetchKey", refetchKey: Date.now() });
      setRerenderKey(Date.now());
    }
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const toggleStartModal = () => {
    setShowStartModal(!showStartModal);
  };

  const onClickFetchMore = () => {
    setPrevPageClicked(false);
    if (currentPage === Math.ceil(totalResults / pageLimit)) {
      return;
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const onClickFetchLess = async () => {
    setPrevPageClicked(true);
    if (currentPage <= 1) {
      setCurrentPage(1);
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const onClickView = data => {
    dispatchGlobal({ type: 'setFormTitle', formTitle: 'VIEW CAMPAIGN' });
    let campaignToEdit = {};
    rawData.forEach(el => {
      if (data.id === el.id) {
        // console.log("ID", el.id);
        campaignToEdit = el;
      }
    });

    // campaignToEdit.viewOnly = true;
    campaignToEdit.flow = campaignToEdit.flow
      ? JSON.parse(campaignToEdit.flow)
      : {};
    dispatchGlobal({
      type: 'updateEditInfo',
      editInfo: { ...campaignToEdit },
      viewOnly: true,
    });
    props.history.push({
      pathname: '/campaign-form',
    });
  };

  const onClickCreateNew = () => {
    dispatchGlobal({
      type: 'updateEditInfo',
      editInfo: { empty: true },
    });
    dispatchGlobal({
      type: 'changeTableSort',
      tableSort: null,
    });
    dispatchGlobal({ type: 'changeTableFilter', tableFilter: 'All' });
    setRerenderKey(Date.now());
    dispatchGlobal({ type: 'setFormTitle', formTitle: 'ADD CAMPAIGN' });
  };

  const onChangeJumpToPage = pageNumber => {
    setCurrentPage(pageNumber);
  };

  const jumpToPageOptions = () => {
    const totalPages = Math.ceil(totalResults / pageLimit);
    const options = [];

    for (let i = 1; i <= totalPages; i++) {
      options.push(i);
    }
    const mappedOptions = options.map(el => {
      return { value: el, label: el };
    });
    return mappedOptions;
  };

  const filterOptions = [
    { value: 'Pending', label: 'Pending' },
    { value: 'Approved', label: 'Approved' },
    { value: 'Unapproved', label: 'Unapproved' },
    { value: 'Published', label: 'Published' },
    { value: 'Active', label: 'Active' },
    { value: 'Paused', label: 'Paused' },
    { value: 'Finished', label: 'Finished' },
  ];

  let groupOptions = JSON.parse(
    sessionStorage.getItem('allGroups-' + tokens.env)
  ).map(object => {
    let newObject = {};
    newObject.value = object.id;
    newObject.label = object.name;
    return newObject;
  });

  let propertyName = 'label';

  groupOptions.sort((a, b) => {
    if (a[propertyName] < b[propertyName]) {
      return -1;
    }
    if (a[propertyName] > b[propertyName]) {
      return 1;
    }
    return 0;
  });

  const tableLabels = [
    { title: 'Name', wide: true },
    { title: 'Status', notClickable: true },
    { title: 'Group' },
    { title: 'Start' },
    { title: 'End' },
    { title: 'Target Audience' },
    { title: 'Submitted By' },
    { title: 'Actions', alignRight: true, notClickable: true },
  ];

  const indexOfLastRecord = currentPage * pageLimit;
  const indexOfFirstRecord = indexOfLastRecord - pageLimit;
  const currentPageRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);

  return (
    <div style={theme.components.pageContainer} key={rerenderKey}>
      <div className="SectionHeader">
        <SectionHeader
          onChangeSearch={onChangeSearch}
          onClickSearch={onClickSearch}
          onClickReset={onClickReset}
          onSelectFilter={onSelectFilter}
          onSelectGroup={onSelectGroup}
          filterOptions={filterOptions}
          groupOptions={groupOptions}
          noSort
          noModality
          rightView={
            <div onClick={onClickCreateNew}>
              <button className="CreateCampaign">
                <CreateNewButton
                  to="campaign-form"
                  label="CREATE NEW CAMPAIGN"
                  isCapitalized={true}
                  isNotBold={false}
                  labelFontSize=".875rem"
                  noWrap={true}
                />
              </button>
            </div>
          }
        />
      </div>
      <div className="TablePage">
        <Paper style={{ ...theme.components.paper }}>
          <Table
            title="Campaigns Table"
            labels={tableLabels}
            data={currentPageRecords}
            onChangeSearch={onChangeSearch}
            onClickSearch={onClickSearch}
            onClickReset={onClickReset}
            onSelectFilter={onSelectFilter}
            onSubmitDelete={onSubmitDelete}
            onClickView={onClickView}
            onClickEdit={onClickEdit}
            onClickPause={onClickPause}
            onClickStart={onClickStart}
            filterOptions={filterOptions}
            getSortKey={getSortKey}
            defaultSortKey={'timestampKey'}
            noSort
            noSearch
          />
          <PaginationPanel
            theme={theme}
            currentPage={currentPage}
            pageCount={Math.ceil(totalResults / pageLimit)}
            onClickFetchMore={onClickFetchMore}
            onClickFetchLess={onClickFetchLess}
            jumpToPageOptions={jumpToPageOptions}
            onChangeJumpToPage={onChangeJumpToPage}
          />
          <Modal
            isShowing={showModal}
            hide={toggleModal}
            content={
              <ModalContent
                confirmation
                title="Confirm pause campaign"
                onSubmit={onConfirmPause}
                toggle={toggleModal}
              />
            }
          />
          <Modal
            isShowing={showStartModal}
            hide={toggleStartModal}
            content={
              <ModalContent
                confirmation
                title="Confirm start campaign"
                onSubmit={onConfirmStart}
                toggle={toggleStartModal}
              />
            }
          />
        </Paper>
      </div>
    </div>
  );
}
