import React, { useContext } from "react";
import { useTheme } from "@material-ui/core/styles";
import LinearProgress, {
  LinearProgressProps,
} from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import { store } from "contexts/KBStore";
import { H6, Button, InputSubtext } from "../";

export default function ModalGeneral(props) {
  const theme = useTheme();
  const KBState = useContext(store);
  const { loadingFormSubmission } = KBState.state;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <H6 style={{ margin: "0px", marginBottom: "1rem" }}>{props.title}</H6>
      <div>{props.subtext}</div>
      {props.children}

      {props.progressBar ? (
        <div style={{ width: "100%" }}>
          <LinearProgressWithLabel value={props.progress} />
        </div>
      ) : (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button
            text={props.buttonLabel ? props.buttonLabel : "CONFIRM"}
            loading={loadingFormSubmission}
            onClick={props.onSubmit ? props.onSubmit : null}
            save
            style={{ width: "fit-content" }}
          />
        </div>
      )}
    </div>
  );
}

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
