import React, { useState, useEffect, useContext } from 'react';
import SaveNode from './SaveNode';

import { API, graphqlOperation } from 'aws-amplify';
import { listEscalations } from 'graphql/queries';
import { Form, Input, Textarea, Select } from 'stories';

import AuthContext from 'contexts/AuthContext';

export default function TriggerProperties(props) {
  const tokens = useContext(AuthContext);

  const { node, extras } = props;
  const [escalationName, setEscalationName] = useState(node.escalationName);
  const [triggerName, setTriggerName] = useState(node.escalationName);
  const [emailTo, setEmailTo] = useState(node.emailTo);
  const [emailFrom, setEmailFrom] = useState(node.emailFrom);
  const [emailSubject, setEmailSubject] = useState(node.emailSubject);
  const [emailBody, setEmailBody] = useState(node.emailBody);
  const [text, setText] = useState(node.text);
  const [image, setImage] = useState(null);

  const [currentEscalation, setCurrentEscalation] = useState({});

  const [escalations, setEscalations] = useState({});
  const [dropdownEscalation, setDropdownEscalations] = useState([]);

  const [viewName, setViewName] = useState(
    node.escalationDump ? JSON.parse(node.escalationDump).escalationName : null
  );

  const initNode = () => {
    console.log('NODE', node);

    let dump = {};
    try {
      dump = JSON.parse(node.escalationDump);
    } catch (e) {}

    console.log(dump);

    setEscalationName(dump.escalationName ? dump.escalationName : null);
    setTriggerName(node.triggerName);

    if (!dump) dump = {};

    setEmailTo(dump.emailTo);
    setEmailFrom(dump.emailFrom);
    setEmailSubject(dump.emailSubject);
    setEmailBody(dump.emailBody);

    setText(node.text);
    setImage(node.image);
  };

  const checkEsc = (e, x) => {
    let key = e.value;

    let i = x ? x : escalations;

    let esc = i[key];
    console.log('ESP', esc);

    if (esc) {
      setEmailTo(esc.emailTo);
      setEmailSubject(esc.emailSubject);
      setEmailBody(esc.emailBody);
      setViewName(esc.escalationName);

      delete esc.createdBy;
      delete esc.editedBy;
      setCurrentEscalation(esc);
    }
  };

  const getEscalations = async () => {
    let filter = { deleted: { eq: false }, status: { eq: 4 } };

    const result = await API.graphql(
      graphqlOperation(listEscalations, {
        limit: 1000,
        filter: filter,
      })
    );

    let escalationNames = [];
    let results = result.data.listEscalations.items;
    let keyedInfo = {};
    if (results) {
      for (let i = 0; i < results.length; i++) {
        keyedInfo[results[i].id] = results[i];
        escalationNames.push({
          value: results[i].id,
          label: results[i].escalationName,
        });
      }
    }
    console.log('KEYED', keyedInfo);
    setEscalations(keyedInfo);
    setDropdownEscalations(escalationNames);
    if (escalationName) {
      checkEsc({ value: escalationName }, keyedInfo);
    }
  };

  useEffect(() => {
    getEscalations();
  }, []);

  useEffect(() => {
    initNode();
  }, [props.node]);

  const acceptPreload = x => {
    let preFillData = props.rawPreloadedMessages[x];
    setImage(preFillData.image);
    setText(preFillData.message);
  };

  let formData = [
    {
      title: 'MESSAGE BODY',
      subtext: 'Message body to show before trigger.',
      component: (
        <Textarea
          name="text"
          form="true"
          image={image}
          allowextras={props.viewOnly ? false : extras}
          preloadOptions={
            !props.viewOnly ? props.preloadedMessages.text : false
          }
          acceptPreload={acceptPreload}
          value={text}
          required
          readOnly={props.viewOnly ? 'readonly' : false}
        />
      ),
    },
    {
      title: 'ESCALATION',
      component: (
        <Select
          form
          name="escalationName"
          placeholder="-- Select an Escalation --"
          options={dropdownEscalation}
          onChange={checkEsc}
          stateValue={viewName}
          required
          disabled={props.viewOnly ? true : false}
        />
      ),
    },
    {
      title: 'EMAIL TO',
      component: (
        <Input name="emailTo" form="true" disabled="disabled" value={emailTo} />
      ),
    },
    {
      title: 'EMAIL SUBJECT',
      component: (
        <Input
          name="emailSubject"
          form="true"
          disabled="disabled"
          value={emailSubject}
        />
      ),
    },
    {
      title: 'EMAIL BODY',
      component: (
        <Textarea
          name="emailBody"
          form="true"
          disabled="disabled"
          value={emailBody}
        />
      ),
    },
  ];

  let saveNode = SaveNode(props.groups, props.node, props.viewOnly);
  formData = formData.concat(saveNode);

  // emailInfo: {
  //   body: data.emailBody,
  //   from: data.emailFrom,
  //   to: data.emailTo,
  //   subject: data.emailSubject
  // }

  const onSubmit = (data, e) => {
    if (data.type !== 'submit') {
      delete data.emailTo;
      delete data.emailSubject;
      delete data.emailBody;

      try {
        data.escalationName = JSON.parse(data.escalationName);
      } catch (e) {}

      data.escalationName = data.escalationName;
      data.escalationDump = JSON.stringify(currentEscalation);
      // console.log("DATA", data);
      props.saveInfo(data);
    }
  };

  return (
    <Form
      data={formData}
      onSubmit={onSubmit}
      deleteWarning={props.deleteWarning}
      type="node"
      style={{ width: '100%' }}
    />
  );
}
