import React, { useState, useEffect, useContext } from "react";
import { useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";

import CallSplitIcon from "@material-ui/icons/CallSplit";

import { Select, ErrorLabel } from "stories";
import * as queries from "graphql/queries";
import { store as GlobalStore } from "contexts/GlobalStore";
import moment from "moment";

import "../forms.css";

const VERSION_ID_STARTER = "1-1";

export default function FlowSelect(props) {
  const theme = useTheme();
  const { register } = useFormContext();
  const GlobalState = useContext(GlobalStore);
  // const dispatchGlobal = GlobalState.dispatch;
  const { checkForErrors, editInfo } = GlobalState.state;

  const { type, ignoreFlowIds } = props;
  const history = useHistory();

  const getType = (t, i) => {
    try {
      return JSON.parse(t)[i];
    } catch (e) {
      return t;
    }
  };

  const [allCampaigns, setAllCampaigns] = useState([]);
  const [campSelectOptns, setCampSelectOptns] = useState([]);
  const [flowStateValue, setFlowStateValue] = useState(null);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [queryData, setQueryData] = useState([]);
  const [tagValues, setTagValues] = useState([]);

  const [selectReset, setSelectReset] = useState(false);
  const [tagReset, setTagReset] = useState(false);
  const [tagValue, setTagValue] = useState();
  const [tag, setTag] = useState();
  const [refreshKey, setRefreshKey] = useState(new Date().getTime());

  const [tagsValueStateValue, setTagsValueStateValue] = useState(null);
  const [tagsStateValue, setTagsStateValue] = useState(null);
  const [campStateValue, setCampStateValue] = useState(null);

  useEffect(() => {
    if (editInfo && editInfo.campaignTagAnswer) {
      let parsed = JSON.parse(editInfo.campaignTagAnswer);

      setCampStateValue(JSON.parse(editInfo.campaignSelect));
      setTag(getTagState("node"))
      setTagValue(getTagState("matches"))
      setSelectedCampaign(JSON.parse(editInfo.campaignSelect))

    } else {
      setCampSelectOptns([]);
      setFlowStateValue(null);
      setSelectedCampaign(null);
    }
  }, [editInfo]);

  useEffect(() => {
    setAllCampaigns(props.campaigns);
  },[props.campaigns]);

  const mapAllCampaigns = () => {
    const optns = allCampaigns.map((el) => {
      let d = new Date(parseInt(el.startTime));
      return { value: el.id, label: el.title + " ("+moment(d).format("MM/DD/YY")+")"};
    });
    return optns;
  }

  const getCampaignSelect = () => {
    return JSON.stringify(selectedCampaign);
  }


  const getCombinedData = () => {
    let data = {
      node: tag,
      matches: tagValue
    }
    return JSON.stringify(data)
  }

  useEffect(() => {
    if( selectedCampaign ) {
      let foundFlow = null;
      for( var i = 0; i < allCampaigns.length; ++i ) {
        if( allCampaigns[i].id === selectedCampaign.value ) {
          foundFlow = {
            flow: JSON.parse(allCampaigns[i].flow).value,
            version: JSON.parse(allCampaigns[i].flowVersion).value
          }
          break;
        }
      }
      if( foundFlow ) {
        queryForFlow(foundFlow);
      }
    }
  },[selectedCampaign,allCampaigns])

  const updateCamp = (c) => {
    setSelectedCampaign(c);
  }

  const getTags = () => {
    let allT = [];

    for( var i = 0; i < queryData.length; ++i ) {
      allT.push({
        label: queryData[i].text + " ("+queryData[i].type+")",
        value: queryData[i].id
      })
    }

    return allT;
  }

  useEffect(() => {
    let foundTag = null;
    if( tag ) {
      for( var i = 0; i < queryData.length; ++i ) {
        if( tag.value === queryData[i].id ) {
          foundTag = queryData[i];
          let matches = queryData[i].matches;
          setTagValues(matches);
        }
      }
    }

  },[tag,queryData])

  const updateTag = (t) => {
    setTag(t)
  }

  const updateTagValue = (h) => {
    setTagValue(h);
  }

  const getTagValues = () => {
    let selectData = [];
    for( var i = 0; i < tagValues.length; ++i ) {
      if( tagValues[i].match !== "NO MATCH" ) {
        selectData.push({
          label: tagValues[i].match,
          value: tagValues[i].id
        })
      }

    }

    selectData.push({
      label: "No Response",
      value: "dne"
    });

    selectData.push({
      label: "Exists",
      value: "exists"
    });

    return selectData;
  }

  const queryForFlow = async (f) => {
    let resp = await API.graphql(
      graphqlOperation(queries.getFlowVersion, {
        id: f.flow,
        version: f.version
      })
    );

    let flow = null;

    try {
      flow = resp.data.getFlowVersion;
    } catch(e) {}

    if( flow && flow.queryableData ) {
      let data = JSON.parse(flow.queryableData);
      setQueryData(data);
    }
  }

  const getTagState = (x) => {

    let p = null;
    try {
      p = JSON.parse(editInfo.campaignTagAnswer)[x]
    } catch(e) {}

    return p;
    // return JSON.parse(editInfo.campaignTagAnswer)[x]
  }

  return (
    <div key={refreshKey}>
      <Select
        form={props.form}
        required={props.required}
        name="camp"
        onChange={updateCamp}
        menuPlacement={props.menuPlacement ? props.menuPlacement : "bottom"}
        reset={selectReset}
        setReset={setSelectReset}
        options={mapAllCampaigns()}
        stateValue={editInfo.campaignSelect ? JSON.parse(editInfo.campaignSelect) : null}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          height: "1px",
        }}
      >
        <div style={{ display: "flex", flex: 1 }} />

        <input
          {...props.validProps}
          name={"campaignSelect"}
          ref={props.required ? register({ required: true }) : register}
          value={getCampaignSelect()}
          readOnly
          autoComplete="off"
          style={{
            opacity: 0,
            height: "1px",
            width: "1px",
            cursor: "default",
          }}
        />

        <div style={{ display: "flex", flex: 1 }} />
      </div>
      <div style={{ marginTop: "10px" }}>
        {selectedCampaign ? (
          <div>
            <div style={{display: "flex", justifyContent: "space-between"}}>
              <div style={{flex: 1}}>
              <Select
                form={props.form}
                required={true}
                menuPlacement={
                  props.menuPlacement ? props.menuPlacement : "bottom"
                }
                name="campaignTags"
                placeholder="-- Select a tag --"
                onChange={updateTag}
                reset={tagReset}
                setReset={setTagReset}
                options={getTags()}
                stateValue={editInfo.campaignTagAnswer ? getTagState("node") : null}
              />
              </div>
              <div style={{flex: 1}}>
                <Select
                  isMulti
                  isClearable={false}
                  form={props.form}
                  required={true}
                  menuPlacement={
                    props.menuPlacement ? props.menuPlacement : "bottom"
                  }
                  name="campaignTagsValue"
                  placeholder="-- Select a tag value --"
                  onChange={updateTagValue}
                  reset={tagReset}
                  setReset={setTagReset}
                  options={getTagValues()}
                  stateValue={editInfo.campaignTagAnswer ? getTagState("matches") : null}
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                height: "1px",
              }}
            >
              <div style={{ display: "flex", flex: 1 }} />

              <input
                {...props.validProps}
                name={"campaignTagAnswer"}
                ref={props.required ? register({ required: true }) : register}
                value={getCombinedData()}
                readOnly
                autoComplete="off"
                style={{
                  opacity: 0,
                  height: "1px",
                  width: "1px",
                  cursor: "default",
                }}
              />

              <div style={{ display: "flex", flex: 1 }} />
            </div>
          </div>
        ) : null}
      </div>
      <input type="text" value="" style={{ display: "none", height: "1px" }} />
    </div>
  );
}

FlowSelect.propTypes = {
  navigation: PropTypes.object,
};
