import React from "react";
import CategoryInput from "./CategoryInput";
import CategoryInputForm from "./CategoryInputForm";

export default function ConditionInput(props) {
  if (props.form) {
    return <CategoryInputForm {...props} />;
  } else {
    return <CategoryInput {...props} />;
  }
}
