import React, { useContext, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import SwapVertIcon from '@material-ui/icons/SwapVert';

import { store } from 'contexts/GlobalStore';
import { H6 } from '../';
import './TableLabel.css';

export default function TableLabel(props) {
  const theme = useTheme();
  const GlobalStore = useContext(store);
  const GlobalState = GlobalStore.state;
  const { dispatch } = GlobalStore;
  const { loadingTableData, tableSort } = GlobalState;

  const [showSortIcon, setShowSortIcon] = useState({ el: null, show: false });

  const getDefaultSortKey = () => {
    if (props.defaultSortKey) {
      return props.defaultSortKey;
    } else {
      console.log('no defaultSortKey prop provided to TableLabel.js');
    }
  };

  const [sortValue, setSortValue] = useState({
    field: tableSort ? tableSort.field : getDefaultSortKey(),
    direction: tableSort ? tableSort.direction : 'desc',
  });

  const onClickLabel = label => {
    if (loadingTableData) {
      return console.log('Table is currently loading...');
    }
    const field = getSortKey(label);
    const direction = getSortDirection(label);
    dispatch({
      type: 'clearNextTokens',
      clearNextTokens: true,
    });
    dispatch({
      type: 'changeTableSort',
      tableSort: { field, direction },
    });
    setSortValue({ field, direction });
    props.sortLocal(label);
  };

  const getSortDirection = label => {
    if (sortValue && getSortKey(label) === sortValue.field) {
      if (sortValue.direction === 'desc') {
        return 'asc';
      } else if (sortValue.direction === 'asc') {
        return 'desc';
      }
    } else {
      return 'desc';
    }
  };

  const getSortKey = label => {
    if (props.getSortKey) {
      return props.getSortKey(label);
    } else {
      console.log('no getSortKey prop provided to TableLabel.js');
    }
  };

  return (
    <div style={{ padding: '2rem 2rem 0 2rem' }}>
      <div class="tableLabel__total">
        <div
          class="tableLabel__rectangle"
          style={{ height: '70px', width: '6px', backgroundColor: 'gold' }}
        ></div>

        <div className="tableLabel__title">
          {props.title ? (
            <H6
              style={{
                margin: '0px',
                paddingLeft: '.9rem',
                fontSize: '28px',
                fontWeight: 'bold',
              }}
            >
              {props.title}
            </H6>
          ) : null}

          <div className="tableLabel__body">
            {props.body ? (
              <H6
                style={{
                  margin: '0px',
                  paddingLeft: '.9rem',
                  fontSize: '15px',
                  fontWeight: 'bold',
                  float: 'left',
                }}
              >
                {props.body}
              </H6>
            ) : null}
          </div>
        </div>
      </div>

      <div
        style={{
          ...props.style,
          padding: '2rem',
          ...theme.typography.subtitle2,
          display: 'flex',
          justifyContent: 'space-between',
          borderBottomStyle: 'solid',
          borderBottomWidth: '1px',
          borderColor: '#D1D1D1',
          paddingBottom: '1rem',
          paddingTop: '1rem',
          cursor: 'default',
          fontSize: '13px',
        }}
      >
        {props.items.map((el, index) => {
          return (
            <div
              key={index}
              style={{
                display: el.hide === true ? 'none' : 'flex',
                flex: el.wide ? 2 : 1,
                justifyContent: el.alignRight ? 'flex-end' : 'flex-start',
                alignItems: 'center',
                fontSize: '0.9rem',
              }}
              onMouseEnter={() => {
                setShowSortIcon({ el, show: true });
              }}
              onMouseLeave={() => {
                setShowSortIcon({ el, show: false });
              }}
            >
              <b
                className={
                  loadingTableData || el.notClickable ? null : 'headerHover'
                }
                // className="headerHover"
                onClick={() => {
                  return el.notClickable ? null : onClickLabel(el.title);
                }}
              >
                {el.title}
              </b>
              <div
                // className={
                //   loadingTableData || el.notClickable ? null : "headerHover"
                // }
                className="headerHover"
                onClick={() => {
                  return el.notClickable ? null : onClickLabel(el.title);
                }}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: '0.5rem',
                }}
              >
                {el.notClickable
                  ? null
                  : showSortIcon.el === el &&
                    showSortIcon.show && (
                      <SwapVertIcon style={{ fontSize: '1rem' }} />
                    )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
