import React, { useState, useEffect, useContext } from 'react';

import moment from 'moment-timezone';

export default function FilterAnchorLink(props) {
  let {
    duration,
    unit,
    setDateInputs,
    setBoxInput1,
    setBoxInput2,
    boxRef1,
    boxRef2,
    label
   } = props;

  const linkAction = (event) => {
    const usDateFormat = 'MM-DD-YYYY';
    let dates = [
      moment()
        .subtract(duration, unit)
        .format(usDateFormat),
      moment().format(usDateFormat),
    ];
    setDateInputs(dates);

    setBoxInput1(dates[0]);
    setBoxInput2(dates[1]);

    boxRef1.current.value = dates[0];
    boxRef2.current.value = dates[1];
  }

  return (
    <div
      className="filter-triage-queue-panel__link"
      onClick={linkAction}
    >
      {label}
    </div>
  );
}
