import React, { useState, useEffect, useContext } from 'react';
import Gallery from 'react-grid-gallery';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Button } from 'stories';
import AuthContext from 'contexts/AuthContext';

import { API, graphqlOperation, Storage } from 'aws-amplify';
import { listImages } from 'graphql/queries';
Storage.configure({ level: 'public' });

export default function ImageLibrary(props) {
  const tokens = useContext(AuthContext);

  const [selectedImage, setSelectedImage] = useState(false);
  const [imagesData, setImagesData] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    let resp = await API.graphql(
      graphqlOperation(listImages, {
        filter: {
          deleted: { eq: false },
        },
      })
    );

    let items = resp.data.listImages.items;

    let dataWithUrl = items.map(async image => {
      let arr = image.imageId.split('|');
      const url = await Storage.get(arr[1], { bucket: arr[0] });

      return { ...image, url };
    });

    let imagesData = await Promise.all(dataWithUrl);

    console.log(formatData(imagesData));

    setImagesData(formatData(imagesData));
    setLoading(false);
  };

  const formatData = data => {
    return data.map(image => {
      // console.log(image);
      return {
        src: image.url,
        thumbnail: image.url,
        title: image.title,
        description: image.description,
        author: image.author,
        id: image.id,
        imageId: image.imageId,
      };
    });
  };

  return (
    <div>
      {!selectedImage ? (
        <>
          <h2 style={{ marginTop: 0 }}>Image Library</h2>
          {isLoading ? (
            <CircularProgress size="2rem" />
          ) : (
            <Gallery
              images={imagesData}
              enableImageSelection={false}
              enableLightbox={false}
              onClickThumbnail={index => {
                console.log(imagesData[index]);
                setSelectedImage(imagesData[index]);
              }}
            />
          )}
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              alignSelf: 'flex-start',
              marginBottom: '1rem',
            }}
            onClick={() => setSelectedImage(null)}
          >
            <ArrowBackIcon viewBox="0 -3 30 30" />
            <h4>BACK TO IMAGE GALLERY</h4>
          </div>
          <img
            style={{ maxHeight: '60%', maxWidth: '60%' }}
            src={selectedImage.src}
            alt={selectedImage.title}
          />
          <div style={{ marginTop: '2rem' }}>
            <Button
              text="INSERT"
              style={{
                background: '#3F88DF',
                color: 'white',
                marginRight: 5,
              }}
              onClick={() => {
                console.log(props.type);
                if (props.type === 'dialogues') {
                  props.acceptFile(selectedImage.imageId, 'image');
                } else {
                  props.loadImage(selectedImage.imageId);
                  props.setTextImage(selectedImage.imageId);
                }
                props.onCloseHandler();
              }}
            />
            <Button
              text="CLOSE"
              style={{
                background: '#EDEDED',
                border: '1px solid #8D8D8D',
                color: 'black',
                marginLeft: 5,
              }}
              onClick={props.onCloseHandler}
            />
          </div>
        </div>
      )}
    </div>
  );
}
