import React, { useState, useContext, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { API, graphqlOperation } from 'aws-amplify';
import axios from 'axios';
import _ from 'lodash';
import { useTheme } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import * as mutations from 'graphql/mutations';
import { useModal, getUserGroups } from 'hooks';
import { SectionHeader } from 'components/SectionHeader';
import AuthContext from 'contexts/AuthContext';
import { store } from 'contexts/GlobalStore';
import { roleCheck } from 'services/utility/RoleMatrix';
import {
  Modal,
  H6,
  Form,
  Checkbox,
  Select,
  Input,
  Textarea,
  Button,
  CreateNewButton,
  ModalContent,
  FlowSelect,
} from 'stories';

const typeOptions = [
  { value: 'miss1', label: 'First Miss' },
  { value: 'miss2', label: 'Second Miss' },
  { value: 'maintText', label: 'Maintenance' },
  { value: 'unknownPhone', label: 'Unknown Phone Number' },
  { value: 'error', label: 'Error' },
  { value: 'beenOptedOut', label: 'Text In during Agent Opt Out' },
  { value: 'forceOptIn', label: 'User Override Agent Opt Out' },
  { value: 'keepSession', label: "Ask user if they're still there" },
  { value: 'keepSessionNo', label: "User says 'no' to extend session" },
  { value: 'keepSessionYes', label: "User says 'yes' to extend session" },
  // { value: "auth", label: "Authentication" },
  // { value: "intro", label: "Introduction" },
  // { value: "intro-fa", label: "Introduction-FA" },
  // { value: "rejectAgent", label: "Reject Agent" },
  // { value: "rejectLogin", label: "Reject Login" },
  // { value: "wait", label: "Wait" },
];
// { value: "", label: "" }

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AddResponse(props) {
  const tokens = useContext(AuthContext);
  //   const { isShowing, toggle } = useModal();
  const theme = useTheme();
  const GlobalState = useContext(store);
  const { dispatch } = GlobalState;
  const { editInfo } = GlobalState.state;
  const [userGroups, setUserGroups] = useState([]);
  let { keyedNames, selectListGroups, getGroup } = getUserGroups();

  const [rerenderKey, setRerenderKey] = useState(Date.now());
  const [formValues, setFormValues] = useState();
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [startingGroupValue, setStartingGroupValue] = useState('');
  const [authorList, setAuthorList] = useState(
    editInfo.empty || editInfo.asurite === tokens.asurite
      ? [
          { value: tokens.asurite, label: tokens.displayName },
          { value: 'Default', label: 'Default' },
        ]
      : [
          { value: editInfo.asurite, label: editInfo.author },
          { value: tokens.asurite, label: tokens.displayName },
        ]
  );

  useEffect(() => {
    // fetchData();
    //! Add forEach function to populate author fields with other authors
  }, []);

  selectListGroups = [{ label: 'Any', value: 'any' }].concat(selectListGroups);

  const getISearchData = async asurite => {
    //! temporary until peoplesoft is accessible
    let getData = await fetch(
        `${process.env.REACT_APP_APIGATEWAY_CHATBOT}/isearch?asurite=${asurite}`)
      .then(res => res.json())
      .then(result => {
        return result;
      })
      .catch(err => console.log(err));
  };

  const onSubmit = async (data, e) => {
    if (data.type !== 'submit') {
      dispatch({
        type: 'setLoadingFormSubmission',
        loadingFormSubmission: true,
      });
    }

    try {
      let postData;
      if (data.flow === '') {
        postData = {
          group: JSON.parse(data.group).value, // join in case of multiple groups
          author: JSON.parse(data.author).label, // user's asurite
          asurite: JSON.parse(data.author).value,
          type: JSON.parse(data.responseType).value,
          message: JSON.stringify({ default: data.message }), //data.message, // message state
        };
      } else {
        postData = {
          group: JSON.parse(data.group).value, // join in case of multiple groups
          author: JSON.parse(data.author).label, // user's asurite
          asurite: JSON.parse(data.author).value,
          type: JSON.parse(data.responseType).value,
          message: JSON.stringify({ default: data.message }), //data.message, // message state
          flowId: JSON.parse(data.flow).value,
          flowLabel: JSON.parse(data.flow).label,
          flowVersion: JSON.parse(data.flowVersion).value,
          flowVersionLabel: JSON.parse(data.flowVersion).label,
        };
      }
      console.log(postData);
      let userOperation;
      if (!editInfo.empty) {
        postData.id = editInfo.id;

        userOperation = await API.graphql(
          graphqlOperation(mutations.updateResponseMessages, {
            input: postData,
          })
        );
      } else {
        userOperation = await API.graphql(
          graphqlOperation(mutations.createResponseMessages, {
            input: postData,
          })
        );
      }

      if (userOperation && !userOperation.errors) {
        props.history.push({
          pathname: '/responses',
        });
        dispatch({ type: 'updateEditInfo', editInfo: { empty: true } });
      } else {
        console.log('something went wrong with userOperation');
      }
    } catch (error) {
      setErrorMessage('Something went wrong');
      setOpenError(true);
      console.log('add response error: ', error);
    }
  };

  const getGroupName = () => {
    if (tokens.role === 'publisher' && editInfo.empty) {
      let groupName = userGroups.filter(
        group => group.label === tokens.groups[0]
      )[0];
      return groupName;
    }

    if (!editInfo.empty) {
      if (editInfo.group === 'any') {
        return { label: 'Any', value: 'any' };
      } else {
        return { label: keyedNames[editInfo.group], value: editInfo.group };
      }
    } else return editInfo.group;
  };

  // useEffect(() => {
  //   //  console.log(tokens.groups.join(","))
  //   // console.log(formSuccessful);
  //   console.log(editInfo);
  // }, [formSuccessful]);

  let startingMessage = '';
  if (!editInfo.empty) {
    const message = JSON.parse(editInfo.message);
    startingMessage = message.default;

    if (editInfo.flowId && !editInfo.flow) {
      editInfo.flow = { value: editInfo.flowId, label: editInfo.flowLabel };
      let tempVersionString = editInfo.flowVersion;
      editInfo.flowVersion = {
        value: tempVersionString,
        label: editInfo.flowVersionLabel,
      };
    }
  }

  const getType = t => {
    for (var i = 0; i < typeOptions.length; ++i) {
      if (t === typeOptions[i].value) {
        return typeOptions[i];
      }
    }
  };

  const formData = [
    {
      title: 'TYPE',
      required: true,
      component: (
        <Select
          form
          name="responseType"
          required
          stateValue={editInfo.type ? getType(editInfo.type) : ''}
          options={typeOptions}
        />
      ),
    },
    {
      title: 'GROUP',
      required: true,
      component: (
        <Select
          form
          name="group"
          required
          //   stateValue={startingGroupValue}
          updateStateValue={getGroupName()}
          options={selectListGroups}
        />
      ),
    },
    {
      title: 'AUTHOR',
      required: true,
      component: (
        <Select
          form
          //   stateValue={type}
          name="author"
          required
          stateValue={authorList[0]}
          options={authorList}
        />
      ),
    },
    {
      title: 'MESSAGE',
      required: true,
      component: (
        <Textarea
          form
          required
          name="message"
          stateValue={startingMessage}
          //   stateValue={message ? message : null}
          allowextras="emoji,var"
        />
      ),
    },
    {
      title: 'FLOW TO EXECUTE',
      required: false,
      component: (
        <FlowSelect
          form
          required={false}
          menuPlacement="top"
          name="flow"
          editInfo={editInfo}
        />
      ),
    },
  ];
  return (
    <div style={theme.components.pageContainer}>
      <SectionHeader title="RESPONSES" noSort noFilter noSearch />
      <Snackbar
        open={openError}
        autoHideDuration={6000}
        //   onClose={handleClose}
      >
        {/* <Alert onClose={handleClose} severity="error">
                {errorMessage}
              </Alert> */}
      </Snackbar>

      <Paper style={theme.components.paper}>
        <div style={{ padding: '2rem', width: '50%' }}>
          {!editInfo.flow ? (
            <Form
              data={formData}
              title={'Add Responses'}
              lessTitleMargin={true}
              importantInfo={
                "For responses to work on SMS, group must be set to 'Any' "
              }
              onSubmit={onSubmit}
              style={{ width: '50%' }}
            />
          ) : (
            <Form
              data={formData}
              title={'Add Responses'}
              lessTitleMargin={true}
              importantInfo={
                "For responses to work on SMS, group must be set to 'Any' "
              }
              onSubmit={onSubmit}
              style={{ width: '50%' }}
            />
          )}
        </div>
      </Paper>
    </div>
  );
}
