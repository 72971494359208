import React, { useEffect, useState, useContext } from 'react';
import _ from 'lodash';
import moment from 'moment';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { listEscalations } from 'graphql/queries';
import { useTheme } from '@material-ui/core/styles';
import { API, graphqlOperation } from 'aws-amplify';

import AuthContext from 'contexts/AuthContext';
import { store as KBStore } from 'contexts/KBStore';
import { store as GlobalStore } from 'contexts/GlobalStore';
import ExtendSession from './ExtendSession';
import * as mutations from 'graphql/mutations';
import * as queries from 'graphql/queries';
import {
  listKnowledgeBaseCategorys,
  validateLexQuestion,
} from 'graphql/queries';
import { useModal, getUserGroups } from 'hooks';
import { roleCheck } from 'services/utility/RoleMatrix';
import {
  Modal,
  H4,
  Form,
  Select,
  Textarea,
  FlowSelect,
  ConditionInput,
  Variations,
  CategoryInput,
  ModalContent,
  ErrorLabel,
  Input,
} from 'stories';

var validIosReactions = [
  'loved',
  'liked',
  'disliked',
  'laughed at',
  'emphasized',
  'questioned',
];

let leftWithoutSaving = true;
let saving = false;
// let haveHit = false;

export default function KBForm(props) {
  const theme = useTheme();
  const { isShowing, toggle } = useModal();

  const tokens = useContext(AuthContext);

  const KBState = useContext(KBStore);
  const dispatchKB = KBState.dispatch;
  const { formTitle } = KBState.state;
  const GlobalState = useContext(GlobalStore);
  const dispatchGlobal = GlobalState.dispatch;
  const { editInfo } = GlobalState.state;
  const { keyedNames, selectListGroups, getGroup } = getUserGroups();

  const [escalationData, setEscalationData] = useState([]);
  // const [userGroups, setUserGroups] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [subcategoriesData, setSubcategoriesData] = useState([]);
  const [attachedEvent, setAttachedEvent] = useState(false);
  const [runningQuestion, setRunningQuestion] = useState(false);
  const [mainQuestionStatusMsg, setMainQuestionStatusMsg] = useState('');
  const [mainQuestionStatus, setMainQuestionStatus] = useState('');
  const [questRunnerError, setQuestRunnerError] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [triggerReset, setTriggerReset] = useState(false);

  const [hasEscalationData, setHasEscalationData] = useState(false);

  const [parentCategory, setParentCategory] = useState(null);
  const [modalContentType, setModalContentType] = useState('');

  const [haveHit, setHaveHit] = useState(false);

  useEffect(() => {
    fetchData();
  }, [props.rerenderKey]);

  useEffect(() => {
    if (editInfo && editInfo.id) {
      localStorage.setItem('editInfoId', editInfo.id);
      setEdit(true);
    }
    // Releases from editing on close and refresh only if current page is open
    window.addEventListener('beforeunload', ev => {
      ev.preventDefault();
      setEdit(false);
    });
    watchPath();
    function watchPath() {
      let path = window.location.pathname;

      if (path.indexOf('add-FAQ') > -1) {
        setTimeout(() => {
          watchPath();
        }, 500);
      } else {
        if (leftWithoutSaving) {
          if (path === '/knowledge-base') {
            let timesThru = 0;
            // Need to wait for rows to render & subscription ready
            // or it wont release from UI of current screen
            function waitForReady() {
              if (document.getElementById(editInfo.id) || timesThru > 500) {
                setEdit(false);
              } else {
                ++timesThru;
                setTimeout(() => {
                  waitForReady();
                }, 50);
              }
            }
            waitForReady();
          } else {
            setEdit(false);
          }
        }
      }
    }
  }, [editInfo]);

  useEffect(() => {
    if (!parentCategory) {
      return;
    } else {
      let subcats = [];
      categoriesData.forEach(el => {
        if (el.title === parentCategory) {
          subcats.push(...el.subcategories);
        }
      });
      setSubcategoriesData(subcats);
      const mappedSubcats = subcats.map(el => {
        return {
          value: el.title,
          label: el.title,
        };
      });
      mappedSubcats.sort((a, b) => {
        return a.value > b.value ? 1 : -1;
      });
      setSubcategories(mappedSubcats);
    }
  }, [parentCategory]);

  const fetchData = async () => {
    let categoriesData;
    try {
      categoriesData = await API.graphql(
        graphqlOperation(listKnowledgeBaseCategorys, {
          limit: 9999999,
          filter: {
            deleted: { eq: false },
          },
        })
      );
      const categories = [];
      if (categoriesData && categoriesData.data) {
        const { items } = categoriesData.data.listKnowledgeBaseCategorys;
        setCategoriesData(items);
        items.forEach(el => {
          if (el.title !== 'Uncategorized') {
            categories.push({ value: el.title, label: el.title });
          }
        });
      }
      categories.sort((a, b) => {
        return a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1;
      });
      setCategories(categories);
      if (
        !subcategories.length &&
        !subcategoriesData.length &&
        editInfo.subcategories
      ) {
        const newSubcats = editInfo.subcategories.map(el => el.name);
        setSubcategoriesData(newSubcats);
      }
      if (!parentCategory && editInfo.categoryName) {
        setParentCategory(editInfo.categoryName);
      }
    } catch (error) {
      console.log('listKnowledgeBaseCategorys error: ', error);
      alert('Error loading knowledge base form data');
    } finally {
      // add loading finished here
    }
  };

  const setEdit = editing => {
    let person = {
      asurite: tokens.asurite,
      timestamp: new Date().getTime().toString(),
    };
    let payload = {
      id: editInfo.id,
      editing: editing ? person : null,
    };
    API.graphql(
      graphqlOperation(mutations.updateKnowledgeBase, {
        input: payload,
      })
    );
  };

  const getStatusName = key => {
    if (typeof key === 'string') {
      key = parseInt(key);
    }
    if (key === 1) {
      return 'Pending';
    } else if (key === 2) {
      return 'Unapproved';
    } else if (key === 3) {
      return 'Approved';
    } else if (key === 4) {
      return 'Published';
    } else if (key === 5) {
      return 'Disabled';
    } else {
      return null;
    }
  };

  const getStatusKey = input => {
    const name = JSON.parse(input).value;
    if (name === 'Pending') {
      return 1;
    } else if (name === 'Unapproved') {
      return 2;
    } else if (name === 'Approved') {
      return 3;
    } else if (name === 'Published') {
      return 4;
    } else if (name === 'Disabled') {
      return 5;
    } else {
      return null;
    }
  };

  const onChangeParent = e => {
    setParentCategory(e);
  };

  const categoryInputStateValue = () => {
    if (editInfo.categoryName && editInfo.subcategoryNames) {
      let stateValueX = {
        parent: editInfo.categoryName,
        sub: editInfo.subcategoryNames[0],
      };
      return stateValueX;
    } else {
      return null;
    }
  };

  const getStatusStateValue = () => {
    return 'Pending';
    // if (tokens.role === "content creator") {
    //   return "Pending";
    // } else if (
    //   tokens.role === "approver" &&
    //   editInfo &&
    //   editInfo.status === 4
    // ) {
    //   return "Approved";
    // } else if (editInfo.status) {
    //   return getStatusName(editInfo.status);
    // } else {
    //   return null;
    // }
  };

  const generateUID = () => {
    let firstPart = (Math.random() * 46656) | 0;
    let secondPart = (Math.random() * 46656) | 0;
    firstPart = ('000' + firstPart.toString(36)).slice(-3);
    secondPart = ('000' + secondPart.toString(36)).slice(-3);
    return firstPart + secondPart;
  };

  const checkForSelfDupe = (questions, variations) => {
    let arr = [questions];
    let vars = variations ? variations.split(',') : [];

    arr = arr.concat(vars);
    let object = {};

    for (var i = 0; i < arr.length; ++i) {
      if (arr[i].length > 0) {
        if (!object[arr[i]]) {
          object[arr[i]] = 'taken';
        } else {
          console.log('WAS DUPE: ', arr[i]);
          return true;
        }
      }
    }

    return false;
  };

  const onSubmit = async (data, e) => {
    if (data.type !== 'submit') {
      if (checkForSelfDupe(data.question, data.variations)) {
        setModalContentType('selfDupe');
        return toggle();
      }

      // check for duplicate modality Type
      const parsedDataType = JSON.parse(data.answerData);
      //console.log(parsedDataType, 'Type1');
      const dataArrayType = Object.keys(parsedDataType);
      let concatArrayType = [];
      for (let i = 0; i < dataArrayType.length; i++) {
        const key = dataArrayType[i];
        if (parsedDataType[key].type) {
          const type = parsedDataType[key].type;
          concatArrayType = concatArrayType.concat(type);
        }
      }
      //console.log(concatArrayType, 'ConcatArrayType');
      let uniqueType = _.uniq(concatArrayType);
      //console.log(uniqueType, 'Unique Type');

      if (uniqueType.length !== concatArrayType.length) {
        /*
         // check for duplicate audience roles
      const parsedData = JSON.parse(data.answerData);
      console.log(parsedData,"Parsed")
      const dataArray = Object.keys(parsedData);
      let concatArray = [];
      for (let i = 0; i < dataArray.length; i++) {
        const key = dataArray[i];
        if (parsedData[key].audience) {
          const audience = parsedData[key].audience;
          concatArray = concatArray.concat(audience);
        }
      }
      console.log(concatArray,"ConcatArray")
      let unique = _.uniq(concatArray);
      console.log(unique,"Unique")


      if (unique.length !== concatArray.length) {
        setModalContentType("audienceRoles");
        return toggle();
      }

      if ((concatArrayType.includes("sms") && concatArrayType.length > 1) &&
         (unique.length !== concatArray.length)) {
          setModalContentType("modalityType");
          return toggle();
      }
 */

        let audiences = { sms: [], chat: [], voice: [] };

        Object.keys(parsedDataType).forEach(key => {
          //loop through each answer
          if (parsedDataType[key].type) {
            //if there is a type, save all the selected audiences for the answer to the array for that type either sms or chat
            audiences[parsedDataType[key].type] = audiences[
              parsedDataType[key].type
            ].concat(parsedDataType[key].audience);
          }
        });

        let uniqueAudiences = {
          sms: Array.from(new Set(audiences.sms)),
          chat: Array.from(new Set(audiences.chat)),
          voice: Array.from(new Set(audiences.voice)),
        }; //same as _.uniq()

        if (audiences.sms.length !== uniqueAudiences.sms.length) {
          //console.log('An audience was used twice for the sms modailty.');
          setModalContentType('modalityType');
          return toggle();
        }

        if (audiences.chat.length !== uniqueAudiences.chat.length) {
          //console.log('An audience was used twice for the chat (web) modailty.');
          setModalContentType('modalityType');
          return toggle();
        }

        if (audiences.voice.length !== uniqueAudiences.voice.length) {
          //console.log('An audience was used twice for the voice modailty.');
          setModalContentType('modalityType');
          return toggle();
        }
      }

      // parse variations data to an array of strings
      const variationsArray = data.variations ? data.variations.split(',') : [];
      const parsedVariations = [];
      variationsArray.forEach(el => {
        const newVal = el.replace(/~~~/g, ',');
        parsedVariations.push(newVal);
      });
      data.variations = parsedVariations;

      // parse answerData to remove UIDs and return an array of objects
      const dataKeys = Object.keys(data);

      let parsedAnswerData = [];
      const answerDataObj = JSON.parse(data.answerData);
      const answerDataKeys = Object.keys(answerDataObj);

      let imageKeys = [];

      dataKeys.forEach(key => {
        if (key.includes('image')) {
          imageKeys.push(key);
        }
      });

      //if image is available then add image url to respective answer
      if (imageKeys.length > 0) {
        answerDataKeys.forEach(key => {
          for (let i = 0; i < imageKeys.length; i++) {
            if (imageKeys[i].includes(key)) {
              answerDataObj[key].image = data[imageKeys[i]];
            }
          }
        });
      }

      let modalities = [];

      answerDataKeys.forEach(key => {
        if (answerDataObj[key].audience) {
          parsedAnswerData.push(answerDataObj[key]);
        }

        if (answerDataObj[key].type === 'sms') {
          modalities.push('sms');
        } else if (answerDataObj[key].type === 'chat') {
          modalities.push('chat');
        } else if (answerDataObj[key].type === 'voice') {
          modalities.push('voice');
        }
      });

      data.answerData = parsedAnswerData;

      // remove erraneous form data that was used only for error handling
      dataKeys.forEach(key => {
        if (
          key.includes('audienceAnswer') ||
          key.includes('textareaAnswer') ||
          key.includes('flowAnswer')
        ) {
          delete data[key];
        }
      });

      // return final form data
      const newKBData = {};
      newKBData.modality = JSON.stringify(modalities);
      const parsedDataArray = Object.keys(data);

      parsedDataArray.forEach(el => {
        if (el === 'question') {
          newKBData.question = data[el];
        } else if (el === 'variations') {
          newKBData.variations = data[el][0] !== '' ? data[el] : [];
          newKBData.variationsCount = data[el].length;
          newKBData.variationsCountKey = data[el].length + '-' + generateUID();
        } else if (el === 'answerData') {
          newKBData.answerData = data[el];
        } else if (el === 'fallbackAnswer') {
          newKBData.fallbackAnswer = data[el];
        } else if (el === 'fallbackAnswerImage') {
          newKBData.fallbackAnswerImage = data[el];
        } else if (el === 'escalation' && data[el] && data[el] !== '') {
          newKBData.escalation = JSON.parse(data[el]).value;
        } else if (el === 'status') {
          if (JSON.parse(data[el]).value === 'Approved') {
            newKBData.approvedBy = {
              asurite: tokens.asurite,
              timestamp: moment().valueOf(),
            };
          }
          newKBData.status = getStatusKey(data[el]);
          newKBData.statusKey = getStatusKey(data[el]) + '-' + generateUID();
        } else if (el === 'group') {
          const groupName = JSON.parse(data[el]).value;
          newKBData.group = groupName;
          newKBData.groupKey = groupName + '-' + generateUID();
        }
      });

      if (runningQuestion) {
        saving = false;
        setQuestRunnerError(true);
      } else {
        setQuestRunnerError(false);
        dispatchGlobal({
          type: 'setLoadingFormSubmission',
          loadingFormSubmission: true,
        });

        /*      // check for duplicate audience roles
        const dataArray = Object.keys(data.answerData);
        let concatArray = [];
        for (let i = 0; i < dataArray.length; i++) {
          const key = dataArray[i];
          const audience = data.answerData[key].audience;
          concatArray = concatArray.concat(audience);
        }
        let unique = _.uniq(concatArray);
        if (unique.length !== concatArray.length) {
          setModalContentType("audienceRoles");
          return toggle();
        }
*/
        // parse answerData to remove UIDs and return an array of objects
        let parsedAnswerData = [];
        const answerDataKeys = Object.keys(data.answerData);
        answerDataKeys.forEach(key => {
          if (data.answerData[key].answer) {
            parsedAnswerData.push(data.answerData[key]);
          }
        });
        data.answerData = parsedAnswerData;

        // remove erraneous form data that was used only for error handling
        const dataKeys = Object.keys(data);
        dataKeys.forEach(key => {
          if (
            key.includes('audienceAnswer') ||
            key.includes('textareaAnswer') ||
            key.includes('flowAnswer')
          ) {
            delete data[key];
          }
        });

        newKBData.submittedBy = tokens.asurite;
        newKBData.submittedByKey = tokens.asurite + '-' + generateUID();
        newKBData.submittedOn = moment().valueOf();
        newKBData.submittedOnKey = moment().valueOf() + '-' + generateUID();
        newKBData.editedBy = [
          {
            asurite: tokens.asurite,
            timestamp: moment().valueOf(),
          },
        ];
        newKBData.editedByKey = tokens.asurite + '-' + generateUID();
        newKBData.lastEdit = moment().valueOf();
        newKBData.lastEditKey = moment().valueOf() + '-' + generateUID();
        newKBData.deleted = false;
        newKBData.processing = true;
        newKBData.editing = null;
        const parentCategory = JSON.parse(data.parentCategory).value;
        newKBData.categoryName = parentCategory;

        let categoryID = '';
        categoriesData.forEach(el => {
          if (parentCategory === el.title) {
            categoryID = el.id;
          }
        });
        if (categoryID === '') {
          console.log('ERROR: something went wrong with parentCategory...');
          categoryID = '1';
        }
        newKBData.categoryID = categoryID;

        if (data.existingSubcategory) {
          const existingSubcategory = JSON.parse(data.existingSubcategory)
            .value;
          newKBData.subcategoryNames = [existingSubcategory];
        } else if (data.newSubcategory) {
          const cannotEqualList = subcategories.map(el =>
            el.value.toLowerCase()
          );
          if (cannotEqualList.includes(data.newSubcategory.toLowerCase())) {
            setModalContentType('duplicateSubcategory');
            return toggle();
          }

          let currentCategory;
          categoriesData.forEach(el => {
            if (el.title === parentCategory) {
              currentCategory = el;
            }
          });
          if (!currentCategory)
            return console.log(
              'ERROR: something went wrong with Subcategory...'
            );

          const newSubcat = toTitleCase(data.newSubcategory);
          newKBData.subcategoryNames = [newSubcat];

          let newCategoryData = {
            ...currentCategory,
            subcategories: [
              ...currentCategory.subcategories,
              {
                title: newSubcat,
              },
            ],
          };

          try {
            delete newCategoryData.createdAt;
            delete newCategoryData.updatedAt;
            const updateCategory = await API.graphql(
              graphqlOperation(mutations.updateKnowledgeBaseCategory, {
                input: newCategoryData,
              })
            );
          } catch (error) {
            console.log('updateKnowledgeBaseCategory error: ', error);
          }
        }

        let newKB;
        if (editInfo.id) {
          let editedByInfo = editInfo.editedBy;
          editedByInfo.push({
            asurite: tokens.asurite,
            timestamp: moment().valueOf(),
          });

          newKBData.editedBy = editedByInfo;
          newKBData.editedByKey = tokens.asurite + '-' + generateUID();
          newKBData.id = editInfo.id;
          newKBData.submittedOn = editInfo.submittedOn;
          newKBData.submittedOnKey = editInfo.submittedOnKey;
          newKBData.updatedOn = moment().valueOf();
          newKBData.submittedBy = editInfo.submittedBy;
          newKBData.submittedByKey = editInfo.submittedByKey;
          try {
            newKB = await API.graphql(
              graphqlOperation(mutations.updateKnowledgeBase, {
                input: newKBData,
              })
            );
          } catch (error) {
            console.log('updateKnowledgeBase error: ', error);
          }
        } else {
          try {
            newKB = await API.graphql(
              graphqlOperation(mutations.createKnowledgeBase, {
                input: newKBData,
              })
            );
          } catch (error) {
            console.log('createKnowledgeBase error: ', error);
          }
        }

        if (newKB && !newKB.errors) {
          leftWithoutSaving = false;
          dispatchGlobal({
            type: 'setLoadingFormSubmission',
            loadingFormSubmission: false,
          });
          dispatchGlobal({
            type: 'setCheckForErrors',
            checkForErrors: false,
          });
          dispatchGlobal({ type: 'updateEditInfo', editInfo: { ...props } });
          const FAQ = newKB.data.createKnowledgeBase
            ? newKB.data.createKnowledgeBase
            : newKB.data.updateKnowledgeBase;
          dispatchGlobal({
            type: 'setSpliceTableData',
            spliceTableData: {
              category: { title: newKBData.categoryName },
              FAQs: [FAQ],
              type: editInfo.id ? 'editFAQ' : 'addFAQ',
            },
          });
          props.history.push({
            pathname: '/knowledge-base',
          });
        } else {
          saving = false;
          alert('An error occurred with form submission');
          dispatchGlobal({
            type: 'setLoadingFormSubmission',
            loadingFormSubmission: false,
          });
          dispatchGlobal({
            type: 'setCheckForErrors',
            checkForErrors: false,
          });
        }
      }
    }
  };

  const onSubmitUnapproval = data => {
    if (data.type !== 'submit') {
      axios
        .put(
          process.env.REACT_APP_APIGATEWAY_CHATBOT +
          '/chatbot-unapproval-email',
          {
            asurite: tokens.asurite,
            submittedBy: editInfo.submittedBy,
            editedBy: editInfo.editedBy ? editInfo.editedBy : null,
            emailBody:
              'reason: ' +
              data.reasonForRejection +
              ' message: ' +
              data.message,
            details: "knowledge base question '" + formValues.question + "'",
          }
        )
        .then(res => {
          console.log('onSubmitUnapproval res: ', res);
        })
        .catch(e => {
          console.log('onSubmitUnapproval error: ', e);
        });
      onSubmit(formValues);
    }
  };

  const createOnSubmit = data => {
    console.log(data);
    dispatchGlobal({
      type: 'setCheckForErrors',
      checkForErrors: true,
    });
    saving = true;
    if (data.type !== 'submit') {
      let pCat = data.parentCategory + '';

      try {
        pCat = JSON.parse(pCat).label;
      } catch (e) {}

      if (
        data.status &&
        JSON.parse(data.status).value === 'Unapproved' &&
        !editInfo.empty &&
        editInfo.status !== 2
      ) {
        setFormValues(data);
        setModalContentType('unapproval');
        toggle();
      } else if (
        pCat.toLowerCase() === 'ios reaction' &&
        validIosReactions.indexOf(data.question) === -1
      ) {
        setModalContentType('invalidReaction');
        toggle();
      } else {
        onSubmit(data);
      }
    }
  };

  const createStatusOptions = () => {
    let statusOptions = [{ value: 'Pending', label: 'Pending' }];

    if (roleCheck(tokens.role, 'knowledgeBase', 'approveDenyContent')) {
      if (editInfo.id) {
        statusOptions.push({ value: 'Unapproved', label: 'Unapproved' });
      }
      statusOptions.push({ value: 'Approved', label: 'Approved' });
    }
    if (roleCheck(tokens.role, 'knowledgeBase', 'publishFAQ')) {
      statusOptions.push({ value: 'Published', label: 'Published' });
    }

    if (roleCheck(tokens.role, 'knowledgeBase', 'disableContent')) {
      statusOptions.push({ value: 'Disabled', label: 'Disabled' });
    }

    return statusOptions;
  };

  useEffect(() => {
    fetchEscalations();
  }, []);

  const getEscalationName = () => {
    if (!haveHit) {
      for (var i = 0; i < escalationData.length; ++i) {
        if (escalationData[i].value === editInfo.escalation) {
          setTimeout(() => {
            setHaveHit(true);
          }, 500);
          return {
            label: escalationData[i].label,
            value: escalationData[i].value,
          };
        }
      }
    } else {
      return 'IGNORE';
    }
  };

  const fetchEscalations = async () => {
    try {
      let filter = {
        deleted: { eq: false },
        status: { eq: 4 },
      };

      filter = {
        ...filter,
      };

      const result = await API.graphql(
        graphqlOperation(queries.listEscalations, {
          limit: 1000,
          filter: filter,
        })
      );

      let escalationNames = [];
      let results = result.data.listEscalations.items;
      escalationNames.push({ value: '', label: '-- No Escalation --' });
      if (results) {
        for (let i = 0; i < results.length; i++) {
          escalationNames.push({
            value: results[i].id,
            label: results[i].escalationName,
          });
        }
      }
      setHasEscalationData(true);
      setEscalationData(escalationNames);
      //setEscalationData(result.data.listEscalations.items);
    } catch (error) {
      console.log('fetchData error: ', error);
    } finally {
    }
  };

  let formData = [
    {
      title: 'GROUP',
      tooltip: 'Select a Group',
      required: true,
      component: (
        <Select
          form
          name="group"
          required
          placeholder="-- Select a Group --"
          options={selectListGroups}
          // stateValue={editInfo.group ? editInfo.group : null}
          updateStateValue={
            tokens.role === 'publisher' && !editInfo.group
              ? getGroup(tokens.groups[0])
              : getGroup(editInfo.group)
              ? getGroup(editInfo.group)
              : null
          }
        />
      ),
    },
    {
      title: 'SECTION',
      tooltip: 'Choose a Parent Section',
      required: true,
      component: (
        <CategoryInput
          form
          name="category"
          required
          parentCategories={categories}
          subcategories={subcategories}
          onChangeParent={onChangeParent}
          stateValue={categoryInputStateValue()}
        />
      ),
    },
    {
      title: 'QUESTION',
      tooltip: 'Write a Question',
      required: true,
      component: (
        <Textarea
          form
          required
          name="question"
          id="question"
          charLimit={parentCategory === 'Emoji' ? null : '120'}
          showRunningStatus={runningQuestion}
          validStatusMsg={mainQuestionStatusMsg}
          allowextras={parentCategory === 'Emoji' ? 'emoji' : null}
          validStatus={mainQuestionStatus}
          stateValue={editInfo.question ? editInfo.question : null}
        />
      ),
    },
    {
      title: 'VARIATIONS',
      tooltip: 'Add Variations',
      component: (
        <Variations
          kbVariation
          name="variations"
          editId={editInfo ? editInfo.id : null}
          charLimit={parentCategory === 'Emoji' ? null : '120'}
          allowextras={parentCategory === 'Emoji' ? 'emoji' : null}
          stateValue={editInfo.variations ? editInfo.variations : null}
          disabled={
            !(parentCategory && parentCategory.toLowerCase() !== 'ios reaction')
          }
        />
      ),
    },
    {
      title: 'ANSWER',
      tooltip: 'Write an Answer',
      subtext:
        'Audience members with more than one affiliation and phone numbers associated with more than one contact will receive the default response specified below.',
      required: true,
      component: (
        <Textarea
          form
          required
          name="fallbackAnswer"
          stateValue={editInfo.fallbackAnswer ? editInfo.fallbackAnswer : null}
          imageName="fallbackAnswerImage"
          image={
            editInfo.fallbackAnswerImage ? editInfo.fallbackAnswerImage : null
          }
          charLimit={160}
          allowextras="image,emoji,imgGallery,links"
          isSMS
          style={{ display: 'flex', width: '100%' }}
        />
      ),
    },
    {
      title: 'MODALITY',
      tooltip: 'Select an Option',
      component: (
        <ConditionInput
          answer
          required
          name="answer"
          stateValue={editInfo.answerData ? editInfo.answerData : null}
          escalationData={escalationData}
        />
      ),
    },
    /*
    What am I figuring out rn?
    : formPayload for new KB's now include answer escalation,
    now: how have to update schema,

    Types
    - will remain the same in backend
    - escalation will be added per answer (backend)
    - maybe more backend work to trigger escalation per answer

    First - front end changes
      Chat = WEB
      Text = SMS
    */
    // {
    //   title: "ESCALATION",
    //   tooltip: "Select an Escalation",
    //   component: (
    //     <Select
    //       form
    //       name="escalation"
    //       placeholder="-- Select an Escalation --"
    //       options={escalationData}
    //       updateStateValue={
    //         editInfo.escalation && editInfo.escalation != "null"
    //           ? getEscalationName()
    //           : null
    //       }
    //     />
    //   ),
    // },
    {
      title: 'STATUS',
      tooltip: 'Select a Status',
      required: true,
      component: (
        <Select
          form
          name="status"
          required
          placeholder="-- Select a Status --"
          isDisabled={tokens.role === 'content creator' ? true : false}
          options={createStatusOptions()}
          stateValue={getStatusStateValue()}
        />
      ),
    },
  ];

  const unapprovalFormData = () => {
    let emailList = [];
    emailList.push(tokens.asurite + '@asu.edu');
    emailList.push(editInfo.submittedBy + '@asu.edu');
    editInfo.editedBy &&
      editInfo.editedBy.forEach(el => {
        emailList.push(el.asurite + '@asu.edu');
      });

    const uniqueEmailList = Array.from(new Set(emailList));

    return [
      {
        component: (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                fontWeight: 400,
                marginLeft: '0.25rem',
              }}
            >
              <div>Message will be emailed to: </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {uniqueEmailList.map((el, index) => {
                  return (
                    <div
                      style={{
                        marginLeft: '0.25rem',
                      }}
                    >
                      {el}
                      {index !== uniqueEmailList.length - 1 && ','}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ),
      },
      {
        title: 'REASON FOR REJECTION',
        required: true,
        component: <Input form name="reasonForRejection" required />,
      },
      {
        title: 'MESSAGE',
        required: true,
        component: (
          <Textarea
            form
            required
            name="message"
            // stateValue={editInfo.emailBody ? editInfo.emailBody : ""}
          />
        ),
      },
    ];
  };

  const deleteButton = () => {
    if (props.deleteButton || formTitle === 'EDIT FAQ') {
      return true;
    } else {
      return false;
    }
  };

  const onClickDelete = () => {
    setModalContentType('deleteFAQ');
    toggle();
  };

  const onSubmitDelete = async () => {
    dispatchGlobal({
      type: 'setSpliceTableData',
      spliceTableData: { ...editInfo, type: 'delete' },
    });
    const updatedData = {
      id: editInfo.id,
      status: 6,
      deletedBy: { asurite: tokens.asurite, timestamp: moment().valueOf() },
    };
    try {
      // to show on current users side timeout

      const deleteOutput = await API.graphql(
        graphqlOperation(mutations.updateKnowledgeBase, {
          input: updatedData,
        })
      );
      if (
        deleteOutput &&
        deleteOutput.data &&
        deleteOutput.data.updateKnowledgeBase
      ) {
        props.history.push({
          pathname: '/knowledge-base',
        });
      }
    } catch (error) {
      console.log('updateKnowledgeBase error: ', error);
    } finally {
      dispatchGlobal({ type: 'setRefetchKey', refetchKey: Date.now() });
      props.setRerenderKey(Date.now());
    }
  };

  const runQuestion = e => {
    let value = e.target.value;
    if (value.length > 0) {
      setRunningQuestion(true);
      setMainQuestionStatusMsg('Checking for availability');
      let payload = {
        operation: 'runQuestion',
        inquiry: value,
        env: 'dev',
      };
      if (editInfo && editInfo.id) {
        payload.current = editInfo.id;
      }
      API.graphql(graphqlOperation(validateLexQuestion, payload)).then(resp => {
        try {
          let responseData = resp.data.validateLexQuestion;
          if (responseData.isSelf === true) {
            setMainQuestionStatusMsg('Question is already in current FAQ');
            setMainQuestionStatus('success');
          } else if (responseData.isTaken) {
            setMainQuestionStatusMsg('Question is not available');
            setMainQuestionStatus('error');
          } else {
            setMainQuestionStatusMsg('Question is available!');
            setMainQuestionStatus('success');
          }
        } catch (err) {
          console.log('There was an error checking the question', err);
          setMainQuestionStatusMsg(
            'There was a critical error checking the question'
          );
          setMainQuestionStatus('error');
        }
        setTimeout(() => {
          setRunningQuestion(false);
          setMainQuestionStatusMsg('');
          setMainQuestionStatus('');
        }, 2000);
      });
    }
  };

  const baseModal = text => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: 'fit-content',
          padding: '0.5rem',
          borderRadius: '4px',
        }}
      >
        <H4 style={{ margin: 0 }}>Error</H4>
        <p style={{ ...theme.typography.subtitle1 }}>{text}</p>
      </div>
    );
  };

  const toTitleCase = text => {
    text = text
      .toLowerCase()
      .split(' ')
      .map(s => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    return text;
  };

  const modalContent = () => {
    if (modalContentType === 'unapproval') {
      return (
        <ModalContent
          confirmation={null}
          form={true}
          formData={unapprovalFormData()}
          title="Unapproval reason"
          onSubmit={onSubmitUnapproval}
          toggle={toggle}
        />
      );
    } //else if (modalContentType === "audienceRoles") {
    //return baseModal("Audience roles can't overlap across answers");
    else if (modalContentType === 'modalityType') {
      return baseModal(
        'The same modality types cant include the same audience type!'
      );
    } else if (modalContentType === 'duplicateSubcategory') {
      return baseModal('This subcategory already exists');
    } else if (modalContentType === 'selfDupe') {
      return baseModal('Questions and variations cannot overlap');
    } else if (modalContentType === 'invalidReaction') {
      return baseModal(
        'iOS Reactions only support the following: ' +
          validIosReactions.toString()
      );
    } else if (modalContentType === 'deleteFAQ') {
      return (
        <ModalContent
          confirmation={true}
          form={null}
          formData={null}
          title="Confirm delete FAQ"
          onSubmit={onSubmitDelete}
          toggle={toggle}
        />
      );
    } else {
      return null;
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setQuestRunnerError(false);
  };

  const Alert = props => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  if (!attachedEvent) {
    setAttachedEvent(true);
    setTimeout(() => {
      let item = document.getElementById('question');
      if (item) {
        item.addEventListener('focusout', runQuestion);
      }
    }, 250);
  }
  let msgError =
    editInfo.processingError && editInfo.processingError !== 'null'
      ? editInfo.processingError +
        '. Please edit your FAQ and save to re-process.'
      : null;

  return (
    <div
      key={props.rerenderKey}
      style={
        props.noFormTitle
          ? { display: 'flex', flexDirection: 'column', width: '100%' }
          : {
              borderLeft: `1px solid ${theme.palette.general.mediumGrey}`,
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }
      }
    >
      <div
        style={props.noFormTitle ? {} : { padding: '2rem', width: '75%' }}
        key={props.rerenderKey}
      >
        {msgError ? <ErrorLabel message={msgError} /> : null}
        <Form
          title={editInfo.id ? 'EDIT FAQ' : 'CREATE NEW FAQ'}
          data={formData}
          onSubmit={createOnSubmit}
          noTitle={props.noFormTitle}
          deleteButton={deleteButton()}
          onClickDelete={onClickDelete}
        />
        <Modal
          isShowing={isShowing}
          hide={toggle}
          content={modalContent()}
          style={
            modalContentType !== 'unapproval'
              ? {
                  color: theme.palette.general.errorColor,
                  width: 'fit-content',
                  backgroundColor: 'white',
                }
              : null
          }
        />
        <Snackbar
          open={questRunnerError}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert onClose={handleCloseSnackbar} severity="error">
            "Cannot submit form while running question"
          </Alert>
        </Snackbar>
        {editInfo && <ExtendSession id={editInfo.id ? editInfo.id : null} />}
      </div>
    </div>
  );
}
