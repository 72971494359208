import React from "react";
import ModalConfirmation from "./ModalConfirmation";
import ModalForm from "./ModalForm";
import ModalGeneral from "./ModalGeneral";

export default function ModalContent(props) {
  if (props.confirmation && props.form) {
    return console.log(
      "ModalContent supports EITHER 'confirmation' OR 'form' at the moment. Please add just one as a prop."
    );
  } else if (props.form) {
    return <ModalForm {...props} />;
  } else if (props.confirmation) {
    return <ModalConfirmation {...props} />;
  } else {
    return <ModalGeneral {...props} />;
  }
}
