import React, { useState, useEffect, useContext } from "react";

import { useTheme, makeStyles } from "@material-ui/core/styles";
import AuthContext from "contexts/AuthContext";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "@material-ui/lab/TabPanel";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";

import SvgIcon from "@material-ui/core/SvgIcon";
import { ReactComponent as ExpandIcon } from "assets/icons/icon-arrow-right.svg";

import "../Dialogues.scss";
const useStyles = makeStyles({
  opened: {
    // transform: "scaleX(-1)",
    display: "block",
  },
  closed: {
    transform: "scaleX(-1)",
    display: "block",
  },
  tabsRoot: {
    flexGrow: 1,
    minHeight: "70px",
    height: "100%",
  },
  tabRoot: {
    flexGrow: 1,
    minHeight: "70px",
    height: "100%",
  },
});
const styles = (theme) => ({
  label: {
    color: "#FFF000",
  },
  indicator: {
    backgroundColor: "#FFF",
  },
});

export default function TopNavigation(props) {
  const classes = useStyles();

  const {
    topButtons,
    isDialogueOpen,
    //triageButtonWidthCalc,
    handleTabChange,
    selectedConvo,
    setDialogueOpen,
    counts,
    tab,
  } = props;
  return (
    <div
      className="triage-buttons-containers"
      style={{
        //width: triageButtonWidthCalc[0],
        flex: 1.91,
        backgroundColor: "white",
      }}
    >
      <div className="triage-buttons-containers-wrapper">
        <TabContext value={tab + ""} style={{ height: "100%" }}>
          <TabList
            variant="scrollable"
            onChange={handleTabChange}
            classes={{
              root: classes.tabsRoot,
            }}
            TabIndicatorProps={{
              style: {
                backgroundColor: "#ffc626",
                height: "4px",
              },
            }}
          >
            {topButtons.map((item, ind) => {
              let i = {
                ...item,
                ind: ind,
              };
              return (
                <Tab
                  classes={{
                    root: classes.tabRoot,
                  }}
                  label={
                    <div className="triage-message-button">
                      <p>{item.name}</p>{" "}
                      {item.id !== "sr" ? (
                        <span className="triage-message-tags">
                          {props.filteredConvoList[item.id].total}
                        </span>
                      ) : null}
                    </div>
                  }
                  value={ind + ""}
                  key={"tab_" + ind}
                />
              );
            })}
          </TabList>
        </TabContext>
        <button
          className="openDialogue"
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (selectedConvo) {
              setDialogueOpen(!isDialogueOpen);
            }
          }}
        >
          {/* <DoubleArrowIcon classes={{ root: classes.root }} /> */}
          <SvgIcon
            viewBox={isDialogueOpen ? "-5 -5 20 20" : "-2 -5 20 20"}
            classes={{
              root: isDialogueOpen ? classes.opened : classes.closed,
            }}
            component={ExpandIcon}
          />
        </button>
      </div>
    </div>
  );
}
