import React, { useState, useContext } from 'react';
import { useTheme, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import Alert from '@material-ui/lab/Alert';
import Switch from '@material-ui/core/Switch';
import AuthContext from 'contexts/AuthContext';

import { Button, Select, Accordion } from 'stories';
import { Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { audiences } from 'pages/FlowMessages/audiences.js';
import * as dateHelper from '../Helpers/dateHelper.js';

import { API, graphqlOperation } from 'aws-amplify';
import {
  chatbotUserByAsurite,
  chatbotUserByPhone,
  listCampaignCompleteds,
  listCampaignRunnings,
} from 'graphql/queries';

import { updateChatbotUser } from 'graphql/mutations';

import { store } from 'contexts/GlobalStore';

export default function DialogueSummary(props) {
  const {
    convo,
    isLoading,
    getUsername,
    getAvatar,
    summaryData,
    getErrorMessage,
    isOptedIn,
    setIsOptedIn,
    isUserOptedIn,
    isPaused,
    setIsPaused,
    reinstateDate,
    uId,
  } = props;

  const tokens = useContext(AuthContext);

  const Slider = withStyles(theme => ({
    root: {
      width: 45,
      height: 23,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(20px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#52d869',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#52d869',
        border: '6px solid #fff',
        // border: 'none',
      },
    },
    thumb: {
      marginTop: 0.5,
      marginLeft: 2,
      width: 19,
      height: 19,
    },
    track: {
      borderRadius: 23 / 2,
      // border: `1px solid ${theme.palette.grey[400]}`,
      border: `none`,
      // backgroundColor: theme.palette.grey[50],
      backgroundColor: '#D6D5D4',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });

  const getDaysRemaining = () => {
    let endDate = moment.unix(reinstateDate);
    let currDate = moment();

    let diff = moment.duration(endDate.diff(currDate));

    let daysDiff = diff.asDays();
    let hoursDiff = diff.asHours();
    let minutesDiff = diff.asMinutes();

    let actualDiff = null;

    if (daysDiff >= 1) {
      actualDiff = Math.ceil(daysDiff) + ' days ';
    } else if (hoursDiff >= 1) {
      actualDiff = Math.ceil(hoursDiff) + ' hours ';
    } else {
      actualDiff = Math.ceil(minutesDiff) + ' minutes ';
    }

    return actualDiff;
  };

  const formatPhoneNumber = num => {
    if (num === 'NONE') {
      return null;
    } else {
      let countryCode = num.substring(0, 2);
      let areaCode = num.substring(2, 5);
      let telephonePrefix = num.substring(5, 8);
      let lineNum = num.substring(8, 12);

      return `${areaCode}-${telephonePrefix}-${lineNum}`;
    }
  };

  const getConvoItem = key => {
    return convo[key] && convo[key] !== 'NONE' ? convo[key] : null;
  };

  const translateGender = g => {
    if (g === 'F') {
      return 'Female';
    } else if (g === 'M') {
      return 'Male';
    } else {
      return g;
    }
  };

  const translateAcadLevel = l => {
    if (l === 'UGRD') {
      return 'Undergraduate';
    } else if (l === 'GRAD') {
      return 'Graduate';
    } else if (l === 'LAW') {
      return 'Law';
    } else {
      return l;
    }
  };

  const renderAcademicInfo = user => {
    return (
      <div>
        {user.academicData.map((item, index) => {
          const summaryContent = (
            <div className="AccordionSummary">
              <div className="opportunity-collapsable-name">
                {user.displayName}
              </div>
              <div className="simpleEllipsis">
                {translateAcadLevel(item.acadLevel) +
                  ' - ' +
                  item.college +
                  '/' +
                  item.major}
              </div>
            </div>
          );

          const keyMapping = [
            {
              human: 'Admit Term',
              value: item.admitTerm,
            },
            {
              human: 'Start Term',
              value: item.startTerm,
            },
            {
              human: 'College',
              value: item.college,
            },
            {
              human: 'Major',
              value: item.major,
            },
            {
              human: 'Degree',
              value: item.degree,
            },
            {
              human: 'Plan Code',
              value: item.planCode,
            },
            {
              human: 'Campus',
              value: item.campus,
            },
            {
              human: 'Academic Level',
              value: translateAcadLevel(item.acadLevel),
            },
            {
              human: 'Enrollment Status',
              value: item.programStatus,
            },
          ];

          const detailsContent = (
            <div>
              {keyMapping.map((km, index) => {
                return (
                  <div className="opportunity-collapsable-row">
                    <div className="opportunity-collapsable-name">
                      {km.human + ':'}
                    </div>{' '}
                    <div className="opportunity-collapsable-content">
                      {km.value}
                    </div>
                  </div>
                );
              })}
            </div>
          );

          return (
            <Accordion
              summaryContent={summaryContent}
              expandedSummaryContent={summaryContent}
              detailsContent={detailsContent}
            />
          );
        })}
      </div>
    );
  };

  const parseOutAudience = roles => {
    let myAud = audiences;
    let audStr = '';
    for (var i = 0; i < roles.length; ++i) {
      let role = roles[i];
      for (var j = 0; j < myAud.length; ++j) {
        if (myAud[j].flag === role) {
          audStr += myAud[j].label + ', ';
        }
      }
    }
    return audStr;
  };

  const convoQuickInfo = [
    { human: 'First Name', value: getConvoItem('firstName') },
    { human: 'Middle Name', value: getConvoItem('middleName') },
    { human: 'Last Name', value: getConvoItem('lastName') },
    {
      human: 'Cell Number',
      value: convo.phoneNumber ? formatPhoneNumber(convo.phoneNumber) : '',
    },
    {
      human: 'Home Number',
      value: convo.homeNumber ? formatPhoneNumber(convo.homeNumber) : '',
    },
    { human: 'ASU Email', value: getConvoItem('email') },
    { human: 'ASURITE', value: getConvoItem('asurite') },
    { human: 'EMPLID', value: getConvoItem('emplid') },
    { human: 'Age', value: getConvoItem('age') },
    { human: 'Gender', value: translateGender(getConvoItem('gender')) },
    { human: 'State', value: getConvoItem('state') },
    { human: 'Country', value: getConvoItem('country') },
    {
      human: 'New Undergrad Status',
      value: getConvoItem('newUndergradStatus'),
    },
    { human: 'NRC Code', value: getConvoItem('nrcCode') },
    { human: 'Enrolled', value: getConvoItem('enrolled') ? 'True' : 'False' },
    { human: 'Residency', value: getConvoItem('residency') },
    {
      human: 'Method',
      value: convo.type === 'sms' ? 'SMS / Text Message' : 'Web',
    },
    {
      human: 'Last Online',
      value: moment(parseInt(convo.latest)).format('MM-DD-YYYY, hh:mm A'),
    },
    {
      human: 'Audience',
      value:
        tokens.devAccess && convo.roles ? parseOutAudience(convo.roles) : null,
    },
  ];

  return (
    <div>
      {convo !== '' && !isLoading ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '1rem',
          }}
        >
          {
            <>
              <div>
                {/* PREFERRED FIRST NAME */}
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  spacing={0}
                  style={{ marginBottom: '1.5rem' }}
                >
                  <Grid item xs={4}>
                    {getAvatar}
                  </Grid>
                  <Grid item xs>
                    <b style={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
                      Preferred First Name
                    </b>
                    <br />
                    {convo.preferredFirstName
                      ? convo.preferredFirstName
                      : convo.primaryFirstName}
                  </Grid>
                </Grid>
              </div>

              {convoQuickInfo.map(item => {
                if (item.value) {
                  return (
                    <Grid
                      container
                      spacing={0}
                      style={{ marginBottom: '0.5rem' }}
                    >
                      <Grid item xs={4}>
                        <b>{item.human}: </b>
                      </Grid>
                      <Grid item xs>
                        {item.value}
                      </Grid>
                    </Grid>
                  );
                } else {
                  return null;
                }
              })}
            </>
          }

          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            style={{ marginBottom: '-0.5rem' }}
          >
            <Grid item>
              <Typography noWrap style={{ fontSize: '14px' }}>
                <b>Opted in:</b>
              </Typography>
              {!props.isUserOptedIn && (
                <Grid style={{ lineHeight: '4px', marginTop: '-6px' }}>
                  <Typography noWrap style={{ fontSize: '12px' }}>
                    <p>User opted out</p>
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid item>
              <Slider
                checked={isOptedIn}
                onChange={e => props.toggleSlider(!e.target.checked, 'optOut')}
                disabled={props.isUserOptedIn ? false : true}
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            style={{ marginBottom: '0.5rem' }}
          >
            <Grid zeroMinWidth item>
              <Typography noWrap style={{ fontSize: '14px' }}>
                <b>Pause for two weeks:</b>
              </Typography>
              {isPaused && (
                <Grid style={{ lineHeight: '4px', marginTop: '-6px' }}>
                  <Typography noWrap style={{ fontSize: '12px' }}>
                    <p>
                      {getDaysRemaining()}
                      remaining
                    </p>
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid item>
              <Slider
                checked={isPaused}
                onChange={e => props.toggleSlider(e.target.checked, 'paused')}
              />
            </Grid>
          </Grid>
          {convo.academicData && renderAcademicInfo(convo)}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '1rem',
            }}
          >
            {!props.otherAgentChatting &&
            props.isUserOptedIn === true &&
            props.isOptedIn === true &&
            (convo.webId || convo.type === 'sms') ? (
              <Button
                save={true}
                style={{ width: '250px' }}
                disabled={props.otherAgentChatting}
                text={
                  props.selectedConvo.agentChatting
                    ? 'Leave Conversation'
                    : 'Enter Conversation'
                }
                onClick={() => {
                  props.modifyActiveConvos();
                }}
                lab
              ></Button>
            ) : (
              <Alert severity="info">{getErrorMessage()}</Alert>
            )}
          </div>

          {
            //! Escalation Dropdown
            // <div style={{ marginTop: "1rem" }}>
            //   <b>Escalation Name:</b>
            //   <Select options={props.escalationData} />
            // </div>
            // <div style={{ marginTop: "1rem" }}>
            //   <div>
            //     <p>
            //       <b>Notes:</b>
            //     </p>
            //     {/* Export */}
            //   </div>
            //   <div>{/* Notes */}</div>
            // </div>
          }
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <CircularProgress />
        </div>
      )}
    </div>
  );
}
