import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import _ from 'lodash';
import Paper from '@material-ui/core/Paper';
import { useTheme } from '@material-ui/core/styles';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { API, graphqlOperation } from 'aws-amplify';

import { updateFlowMessage, createFlowMessage } from 'graphql/mutations';
import { userByAsurite } from 'graphql/queries';
import { SectionHeader } from 'components/SectionHeader';
import AuthContext from 'contexts/AuthContext';
import { store } from 'contexts/GlobalStore';
import { Form, Select, Input, Textarea, InputAdd } from 'stories';
import { getUserGroups } from 'hooks';
import { idGenerator } from 'pages/Flows/FlowDiagram/utility.js';
import { audiences } from './audiences.js';

const invalidMatch = /^\[[0-9]*\]\s?[a-zA-Z0-9]+/;

export default function FlowMessagesForm(props) {
  const tokens = useContext(AuthContext);
  const theme = useTheme();
  const GlobalStore = useContext(store);
  const { dispatch } = GlobalStore;
  const { editInfo, formTitle } = GlobalStore.state;
  const {
    message,
    name,
    group,
    image,
    audience,
    responseImage,
    response,
  } = editInfo;

  const [userGroups, setUserGroups] = useState([]);
  const [displayName, setDisplayName] = useState({});
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [formSuccess, setFormSuccess] = useState(false);
  const [noMatch, setNoMatch] = useState({});
  const [matches, setMatches] = useState([]);
  const [nodeType, setNodeType] = useState(
    editInfo.nodeType ? editInfo.nodeType : null
  );

  let { keyedNames, selectListGroups } = getUserGroups();

  useEffect(() => {
    fetchUserGroups();

    let eMatches = editInfo.matches;
    let mArr = [];
    let foundNM = false;

    if (eMatches) {
      for (var i = 0; i < eMatches.length; ++i) {
        if (eMatches[i].match !== 'NO MATCH') {
          mArr.push({
            id: eMatches[i].id,
            text: eMatches[i].match,
          });
        } else {
          foundNM = true;
          setNoMatch(eMatches[i]);
        }
      }
      setMatches(mArr);
    }

    if (!foundNM) {
      setNoMatch({ id: idGenerator(), match: 'NO MATCH' });
    }
  }, []);

  const fetchUserGroups = () => {};

  const onSubmit = async (data, e) => {
    if (data.type !== 'submit') {
      dispatch({
        type: 'setLoadingFormSubmission',
        loadingFormSubmission: true,
      });
      let prePay = !editInfo.empty ? editInfo : {};

      if (data.matches) {
        data.matches = JSON.parse(data.matches);
        for (var i = 0; i < data.matches.length; ++i) {
          data.matches[i].match = data.matches[i].text;
          delete data.matches[i].text;
        }

        data.matches = [...data.matches, noMatch];
      }

      let payload = {
        ...prePay,
        ...data,
      };

      let g = payload.group;
      let audience = payload.audience;

      try {
        g = JSON.parse(payload.group).value;
      } catch (e) {}

      if (payload.audience !== '') {
        payload.audience = JSON.parse(payload.audience).value;
      } else payload.audience = null;

      if (keyedNames[group] === payload.group) {
        payload.group = group;
      } else {
        payload.group = g;
      }

      try {
        payload.nodeType = JSON.parse(payload.nodeType).value;
      } catch (e) {}

      if (editInfo.empty) {
        payload.submittedBy = tokens.asurite;
        payload.deleted = false;
      } else {
        delete payload.createdAt;
        delete payload.updatedAt;
      }

      let resp = await API.graphql(
        graphqlOperation(
          !editInfo.empty ? updateFlowMessage : createFlowMessage,
          {
            input: payload,
          }
        )
      );

      dispatch({ type: 'updateEditInfo', editInfo: { empty: true } });
      dispatch({
        type: 'setLoadingFormSubmission',
        loadingFormSubmission: false,
      });
      props.history.push({
        pathname: '/flow-messages',
      });
    }
  };

  const getArrValue = (g, arr) => {
    for (var j = 0; j < arr.length; ++j) {
      if (arr[j].value === g) {
        return arr[j];
      }
    }
    return g;
  };

  let audienceOptions = audiences;

  const nodeTypes = [
    { label: 'Text', value: 'text' },
    { label: 'Branch', value: 'branch' },
    { label: 'Prompt', value: 'prompt' },
  ];

  let formData = [
    {
      title: 'NAME',
      required: true,
      component: (
        <Input form stateValue={name ? name : null} name="name" required />
      ),
    },
    {
      title: 'GROUP',
      required: true,
      component: (
        <Select
          form
          updateStateValue={group ? getArrValue(group, selectListGroups) : null}
          name="group"
          required
          options={selectListGroups}
        />
      ),
    },
    {
      title: 'NODE TYPE',
      required: true,
      component: (
        <Select
          form
          updateStateValue={nodeType ? getArrValue(nodeType, nodeTypes) : null}
          name="nodeType"
          onChange={e => setNodeType(e.value)}
          required
          options={nodeTypes}
        />
      ),
    },
    {
      title: 'AUDIENCE',
      required: false,
      component: (
        <Select
          form
          updateStateValue={
            audience ? getArrValue(audience, audienceOptions) : null
          }
          name="audience"
          options={audienceOptions}
        />
      ),
    },
    {
      title: 'MESSAGE',
      component: (
        <Textarea
          name="message"
          form="true"
          charLimit="160"
          isSMS
          allowextras="emoji,var,image,imgGallery"
          value={message}
          image={image}
          required
        />
      ),
    },
  ];

  let nodeExtras = {
    branch: [
      {
        title: 'MATCH AGAINST',
        subtext:
          "Format: Yes, I'm going. SMS will display this as text. Mobile/Web will convert this to buttons.",
        component: (
          <InputAdd
            name="matches"
            required
            form="true"
            maxlength="100"
            matches={matches}
            invalidmatch={invalidMatch}
          />
        ),
      },
    ],
    text: [],
    prompt: [
      {
        title: 'RESPONSE',
        subtext: 'Message to display after the users response.',
        component: (
          <Textarea
            name="response"
            form="true"
            image={responseImage}
            imageName={'responseImage'}
            charLimit="160"
            isSMS
            allowextras="emoji,var,image,imgGallery"
            value={response}
            required
          />
        ),
      },
    ],
  };

  if (nodeExtras[nodeType]) {
    formData = formData.concat(nodeExtras[nodeType]);
  }

  return (
    <div style={theme.components.pageContainer}>
      <SectionHeader title="Flow Message Form" />
      <Paper style={theme.components.paper}>
        <div style={{ padding: '2rem', width: '50%' }}>
          <Form
            title={(!editInfo.empty ? 'Edit' : 'Add') + ' Flow Message'}
            data={formData}
            onSubmit={onSubmit}
            style={{ width: '50%' }}
          />
        </div>
      </Paper>
    </div>
  );
}
