import React from 'react';
import PropTypes from 'prop-types';
import '../components.css';

import SearchBar from 'stories/Table/SearchBar';

export default function SectionHeader(props) {
  const title = props.title ? (
    <p className="SectionHeader-title-text">{props.title}</p>
  ) : null;
  const description = props.description ? <p>{props.description}</p> : null;

  const onChangeSearch = value => {
    if (props.onChangeSearch) {
      return props.onChangeSearch(value);
    } else {
      return console.log('no onChangeSearch prop provided to Table.js');
    }
  };

  const onClickSearch = () => {
    if (props.onClickSearch) {
      return props.onClickSearch();
    } else {
      return console.log('no onClickSearch prop provided to Table.js');
    }
  };

  const onClickReset = () => {
    if (props.onClickReset) {
      props.onSelectFilter();
      return props.onClickReset();
    } else {
      return console.log('no onClickReset prop provided to Table.js');
    }
  };

  const onSelectFilter = value => {
    if (props.onSelectFilter) {
      return props.onSelectFilter(value);
    } else {
      return console.log('no onSelectFilter prop provided to Table.js');
    }
  };

  const onSelectGroup = value => {
    if (props.onSelectGroup) {
      return props.onSelectGroup(value);
    } else {
      return console.log('no onSelectGroup prop provided to Table.js');
    }
  };

  const onSelectModality = value => {
    if (props.onSelectModality) {
      return props.onSelectModality(value);
    } else {
      return console.log('no onSelectModality prop provided to Table.js');
    }
  };

  const onSelectFilter2 = value => {
    if (props.onSelectFilter2) {
      return props.onSelectFilter2(value);
    } else {
      return console.log('no onSelectFilter2 prop provided to Table.js');
    }
  };

  const enterEvent = e => {
    onClickSearch();
  };

  const getFilterOptions = () => {
    if (props.filterOptions) {
      return props.filterOptions;
    } else {
      return null;
    }
  };

  const getGroupOptions = () => {
    if (props.groupOptions) {
      return props.groupOptions;
    } else {
      return null;
    }
  };

  const getModalityOptions = () => {
    if (props.modalityOptions) {
      return props.modalityOptions;
    } else {
      return null;
    }
  };

  const getSortOptions = () => {
    if (props.sortOptions) {
      return props.sortOptions;
    } else {
      return null;
    }
  };

  const getSortKey = label => {
    if (props.getSortKey) {
      return props.getSortKey(label);
    } else {
      console.log('no getSortKey prop provided to Table.js');
    }
  };

  const getDefaultSortKey = () => {
    if (props.defaultSortKey) {
      return props.defaultSortKey;
    } else {
      console.log('no defaultSortKey prop provided to Table.js');
    }
  };

  {
    //   props.data.map((el, index) => {
    return (
      <div className="SectionHeader-container">
        {!props.noSearch && (
          <SearchBar
            //        data={el}
            onChange={onChangeSearch}
            onClickSearch={onClickSearch}
            onClickReset={onClickReset}
            filterOptions={getFilterOptions()}
            groupOptions={getGroupOptions()}
            modalityOptions={getModalityOptions()}
            sortOptions={getSortOptions()}
            filterOptions2={props.filterOptions2}
            filterButtonText={props.filterButtonText}
            filterButtonText2={props.filterButtonText2}
            groupButtonText={props.groupButtonText}
            modalityButtonText={props.modalityButtonText}
            onSelectFilter2={onSelectFilter2}
            onSelectFilter={onSelectFilter}
            onSelectGroup={onSelectGroup}
            onSelectModality={onSelectModality}
            enterEvent={enterEvent}
            noSort={props.noSort ? true : false}
            noFilter={props.noFilter ? true : false}
            noGroup={props.noGroup ? true : false}
            noModality={props.noModality ? true : false}
            style={{ borderWidth: '0 0 1px 0' }}
          />
        )}
        <div className="SectionHeader-box">
          {title}
          {description}
        </div>
        <div
          className="SectionHeader-box"
          style={props.rightViewStyle ? props.rightViewStyle : {}}
        >
          {props.rightView}
        </div>
      </div>
    );
    // });
  }
}

SectionHeader.propTypes = {
  title: PropTypes.string,
  shortDescription: PropTypes.string,
  rightView: PropTypes.element,
};
