import React from "react";

import { Form } from "../";

export default function ModalForm(props) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "75%",
      }}
    >
      <Form
        summary = {props.summary || null}
        title={props.title ? props.title : null}
        data={props.formData ? props.formData : null}
        onSubmit={props.onSubmit ? props.onSubmit : null}
        {...props}
        // style={{ width: "75%" }}
      />
    </div>
  );
}
