import React, { createContext, useReducer } from "react";
const initialState = {
  rerenderKey: Date.now(),
  FAQSort: null,
  clearNextTokens: false,
  loadingFAQs: false,
  formTitle: "",
  blockAudienceSelect: false,
  activeCategory: null,
};
const store = createContext(initialState);
const { Provider } = store;
const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      // =============================================================
      case "changeFAQSort":
        const changeFAQSortState = {
          ...state,
          FAQSort: action.FAQSort,
        };
        return changeFAQSortState;
      // =============================================================
      case "clearNextTokens":
        const clearNextTokensState = {
          ...state,
          clearNextTokens: action.clearNextTokens,
        };
        return clearNextTokensState;
      // =============================================================
      case "setLoadingFAQs":
        const loadingFAQsState = {
          ...state,
          loadingFAQs: action.loadingFAQs,
        };
        return loadingFAQsState;
      // =============================================================
      case "setFormTitle":
        const formTitleState = {
          ...state,
          formTitle: action.formTitle,
        };
        return formTitleState;
      // =============================================================
      case "setBlockAudienceSelect":
        const blockAudienceSelectState = {
          ...state,
          blockAudienceSelect: action.blockAudienceSelect,
        };
        return blockAudienceSelectState;
      // =============================================================
      case "setActiveCategory":
        const setActiveCategoryState = {
          ...state,
          activeCategory: action.activeCategory,
        };
        return setActiveCategoryState;
      // =============================================================
      default:
        console.log("ERROR: dispatch type is not included in store...");
        console.log("store action: ", action);
        throw new Error();
      // =============================================================
    }
  }, initialState);
  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};
export { store, StateProvider };
