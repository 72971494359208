export function arraysEqual(arr1, arr2, id) {
  if (arr1.length !== arr2.length) {
    return false;
  } else if (arr1 === null || arr2 === null) {
    return false;
  } else {
    for (var i = 0; i < arr1.length; ++i) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }
    return true;
  }
}

export function embedTree(t) {
  if (t.length === 0) {
    return [];
  } else {
    let treeInd = -1;

    for (var i = 0; i < t.length; ++i) {
      if (t[i].parent === null || t[i].parent === undefined) {
        treeInd = i;
      }

      t[i].children = [];
      let children = [];

      if (t[i].matches) {
        for (var m = 0; m < t[i].matches.length; ++m) {
          t[i].matches[m].parent = t[i].id;
        }
        children = t[i].matches;
      }

      t[i].children = findChildren(t, t[i].id);

      if (t[i].children.length > children.length) {
        t[i].children = mergeArrays(t[i].children, children);
      } else {
        t[i].children = mergeArrays(children, t[i].children);
      }
    }

    if (t.length > 1) insertChildren(t[treeInd].children, t);

    return [t[treeInd]];
  }
}

export function setTypeById(tree) {
  let obj = {};

  for (var i = 0; i < tree.length; ++i) {
    obj[tree[i].id] = tree[i].type;
  }

  return obj;
}

export function insertChildren(returnArr, fullArr) {
  // console.log("LENS",returnArr.length, fullArr.length)
  for (var i = 0; i < returnArr.length; ++i) {
    for (var j = 0; j < fullArr.length; ++j) {
      if (fullArr[j].id === returnArr[i].id) {
        returnArr[i].children = fullArr[j].children;
        insertChildren(fullArr[j].children, fullArr);
      }
    }
  }
}

export function mergeArrays(mainArr, subArr) {
  for (var i = 0; i < mainArr.length; ++i) {
    let currId = mainArr[i].id;
    for (var j = 0; j < subArr.length; ++j) {
      if (currId === subArr[j].id) {
        for (let key in subArr[j]) {
          mainArr[i][key] = subArr[j][key];
        }
      }
    }
  }

  return mainArr;
}

export function findChildren(t, id) {
  let children = [];

  for (var i = 0; i < t.length; ++i) {
    if (t[i].parent === id) {
      children.push(t[i]);
    }
  }

  return children;
}

export function idGenerator() {
  var S4 = function() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (
    S4() +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    S4() +
    S4()
  );
}

export function getDeletes(item, tree) {
  let t = JSON.parse(JSON.stringify(tree));

  console.log(item);

  let foundSelf = false;
  let children = [item.id];
  let matchDeletes = [];
  let cleanRun = false;
  let parentInfo = {
    id: null,
    isBranch: null,
  };

  let markedForDelete = [];

  while (!cleanRun) {
    let currentClean = true;

    for (var i = 0; i < t.length; ++i) {
      if (parentInfo.id === null && item.id === t[i].id) {
        foundSelf = true;
        parentInfo.id = t[i].parent;
      }

      if (
        parentInfo.id !== null &&
        parentInfo.isBranch === null &&
        parentInfo.id === t[i].id
      ) {
        parentInfo.isBranch =
          t[i].type === "branch" || t[i].type.indexOf("ifthen") > -1;
      }

      if (
        t[i].id === item.id ||
        t[i].parent === item.id ||
        children.indexOf(t[i].parent) > -1
      ) {
        if (children.indexOf(t[i].id) === -1) {
          children.push(t[i].id);
          currentClean = false;
        }
      }
      if (t[i].matches) {
        for (var m = 0; m < t[i].matches.length; ++m) {
          if (t[i].matches[m].id === item.id) {
            if (matchDeletes.indexOf(t[i].matches[m].id) === -1) {
              matchDeletes.push(t[i].matches[m].id);
              currentClean = false;
            }
          }
        }
      }
    }

    if (currentClean) {
      cleanRun = true;
    }
  }

  if (!foundSelf) {
    children.splice(children.indexOf(item.id), 1);
  }

  return {
    markedForDelete: children,
    parentInfo: parentInfo,
    matchDeletes: matchDeletes,
  };
}

export function collapseFromNode(rawTree, itemToCollapse) {
  let t = JSON.parse(JSON.stringify(rawTree));

  let parents = [].concat(itemToCollapse);
  let children = [];
  let cleanRun = false;

  while (!cleanRun) {
    let currentClean = true;

    for (var i = 0; i < t.length; ++i) {
      if (parents.indexOf(t[i].id) > -1) {
        //Parent of the selected Node
        t[i].show = true;
        if (parents.indexOf(t[i].parent) === -1) {
          parents.push(t[i].parent);
          currentClean = false;
        }
      } else if (
        itemToCollapse.indexOf(t[i].parent) > -1 ||
        children.indexOf(t[i].parent) > -1
      ) {
        //If it's a child of the selected Node
        t[i].show = false;
        if (children.indexOf(t[i].id) === -1) {
          children.push(t[i].id);
          currentClean = false;
        }
      } else {
        t[i].show = false;
      }
    }

    if (currentClean) {
      cleanRun = true;
    }
  }

  return t;
}
