import React, { Fragment } from "react";
import ReactDom from "react-dom";
import CloseIcon from "@material-ui/icons/Close";

import "../elements.css";

const Modal = (props) => {
  const { isShowing, hide } = props;

  const closeModal = () => {
    hide();
    if (props.didExit) props.didExit();
    if (props.onCloseModal) {
      props.onCloseModal();
    } else {
      console.log("no onCloseModal handler...");
    }
  };

  let btnColor = "black";
  if (props.style) btnColor = props.style.color;

  return isShowing
    ? ReactDom.createPortal(
        <Fragment>
          <div className="modal-overlay" />
          <div
            className="modal-wrapper"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "33vh",
            }}
          >
            <div
              className="modal"
              style={{ ...props.style, maxHeight: "80vh", overflowY: props.noOverflowY ? null : "scroll" }}
            >
              <div className="modal-header">
                <button
                  type="button"
                  className="modal-close-button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closeModal}
                  style={{ color: btnColor }}
                >
                  <CloseIcon aria-hidden="true" />
                </button>
              </div>
              <div style={{ padding: "0px 1rem 1rem 1rem", height: props.noOverflowY ? "100%": "auto"}}>
                {props.content}
              </div>
            </div>
          </div>
        </Fragment>,
        document.body
      )
    : null;
};
export default Modal;
