import React from "react";
import { ReactComponent as UndoIcon } from "./tempIcons/undo-24px.svg";
import "./Undo.css";

export default function Undo(props) {

  const { show, undo } = props;

  let classes = "undoSection ";
  if (show) {
    classes += "showSection";
  }

  return (
    <div className={classes} onClick={() => undo()} style={{ right: "50px" }}>
      <div className="align undoIcon">
        <UndoIcon />
      </div>
      <div className="align undo">Undo Delete</div>
    </div>
  );

}
