import React from "react";
import { makeStyles } from "@material-ui/core/styles";

export default function H4(props) {
  const useStyles = makeStyles(theme => ({
    header: {
      ...theme.typography.h4,
      ...props.style
    }
  }));
  const classes = useStyles();

  return (
    <h4 {...props} className={classes.header}>
      {props.children}
    </h4>
  );
}
