import React, { useEffect, useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

import { Select, Input } from "stories";

export default function CategoryInput(props) {
  const theme = useTheme();
  const [radioValue, setRadioValue] = useState("existing");
  const [parentCategory, setParentCategory] = useState(null);
  const [resetSubcat, setResetSubcat] = useState(false);

  useEffect(() => {
    if (props.stateValue) {
      setParentCategory(props.stateValue.parent);
    }
  }, [props.stateValue]);

  const handleRadioChange = (e) => {
    const { value } = e.target;
    setRadioValue(value);
  };

  const onChangeParent = (e) => {
    setParentCategory(e);
    setResetSubcat(true);
    if (props.onChangeParent) {
      props.onChangeParent(e.value);
    }
  };

  const getValue = (type) => {
    if (type === "parent") {
      return props.stateValue.parent;
    } else if (type === "sub") {
      return props.stateValue.sub;
    } else {
      return null;
    }
  };

  const cannotEqualList = () => {
    const list = props.subcategories.map((el) => el.value.toLowerCase());
    return list;
  };

  return (
    <div key={props.key}>
      <FormControl component="fieldset" style={{ width: "100%" }}>
        <div>
          <Select
            form
            required
            name="parentCategory"
            options={props.parentCategories}
            style={{ paddingLeft: "2rem" }}
            placeholder={
              !props.stateValue ? "-- Choose a Parent Section --" : null
            }
            noOptionsText="No Categories..."
            stateValue={props.stateValue ? getValue("parent") : null}
            onChange={onChangeParent}
          />
        </div>
        {parentCategory && (
          <RadioGroup
            aria-label="category"
            name="category"
            value={radioValue}
            onChange={handleRadioChange}
            column="true"
          >
            <FormControlLabel
              value="existing"
              control={
                <Radio
                  selected
                  color="default"
                  // color={theme.palette.general.lightGrey}
                />
              }
              label="Existing Type"
              labelPlacement="end"
            />
            {radioValue === "existing" && (
              <div style={{ marginTop: "0.5rem", marginLeft: "2rem" }}>
                <Select
                  form
                  required
                  reset={resetSubcat}
                  setReset={setResetSubcat}
                  name="existingSubcategory"
                  options={props.subcategories}
                  placeholder={!props.stateValue ? "-- Choose a Type --" : null}
                  noOptionsText="The selected category currently has no subcategories."
                  stateValue={props.stateValue ? getValue("sub") : null}
                  style={{ paddingLeft: "2rem" }}
                />
              </div>
            )}
            <FormControlLabel
              value="new"
              control={
                <Radio
                  color="default"
                  // color={theme.palette.general.lightGrey}
                />
              }
              label="New Type"
              labelPlacement="end"
            />
            {radioValue === "new" && (
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  width: "100%",
                  marginTop: "0.5rem",
                }}
              >
                <Input
                  form
                  required
                  name="newSubcategory"
                  style={{ marginLeft: "2rem" }}
                  placeholder="Name of Type"
                  cannotEqualList={cannotEqualList()}
                  errorMessage="This type already exists"
                />
              </div>
            )}
          </RadioGroup>
        )}
      </FormControl>
    </div>
  );
}
