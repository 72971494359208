import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { scroller as scroll, Events } from 'react-scroll';
import { MiniTable, Modal, PanelButton, Accordion, Button } from 'stories';
import { useModal } from 'hooks';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import CircularProgress from '@material-ui/core/CircularProgress';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckIcon from '@material-ui/icons/Check';
import { store as GlobalStore } from 'contexts/GlobalStore';
import Tooltip from '@material-ui/core/Tooltip';

import '../../Dialogues.scss';

import * as tagHelper from '../../Helpers/tagHelper.js';

import TableHelper from './TableHelper.js';

import {
  queryWithAthena,
  escHistoryByConvoId,
  compCampByUserId,
} from 'graphql/queries';
import { createChatbotConvoTag } from 'graphql/mutations';

import { API, graphqlOperation } from 'aws-amplify';

export default function Notes(props) {
  const GlobalState = useContext(GlobalStore);
  const dispatchGlobal = GlobalState.dispatch;

  const [needsReview, setNeedsReview] = useState([]);
  const [isResolving, setIsResolving] = useState(false);
  const [loading, setIsLoading] = useState(false);

  useEffect(() => {
    setNeedsReview([]);
    alterReviewData();
  }, [props.convo, props.needsReview, props.keyedNames]);

  const alterReviewData = () => {
    setIsLoading(true);
    let myReview = [];

    for (var i = 0; i < props.needsReview.length; ++i) {
      let record = props.needsReview[i];
      let dateString = props.dateFormatter(record.latest, true);
      let tagx = record.tags.find(a => a.includes('needsReview'));
      let assigned = tagx.split(' ')[1];

      record.agentDisplay =
        assigned === 'true'
          ? 'General'
          : props.keyedNames[assigned]
          ? props.keyedNames[assigned]
          : props.getAssigneeName(assigned);
      record.date = dateString;
      myReview.push(record);
    }
    setIsLoading(false);
    setNeedsReview(myReview);
  };

  let csvKeys = {
    agentDisplay: 'Agent',
    content: 'Content',
    date: 'Date/Time',
  };

  let tableKeys = {
    date: 'Date',
    agentDisplay: 'Agent',
    IGNORE: ' ',
  };

  let extraButton = (
    <PanelButton
      type="resolve"
      label={isResolving ? 'Resolving...' : 'Resolve All'}
      onClick={e => handleResolveAll(e, props.needsReview)}
    />
  );

  const handleResolveAll = async (e, msgs) => {
    if (!isResolving) {
      setIsResolving(true);
      let cId = props.convo.convoId;
      for (var i = 0; i < msgs.length; ++i) {
        let msg = msgs[i];
        for (var t = 0; t < msg.tags.length; ++t) {
          if (msg.tags[t].indexOf('needsReview') > -1) {
            await tagHelper.resolveNeedsReview(
              msg.messageId,
              cId,
              props.listFilter,
              props.myReviewTags,
              msg.tags[t]
            );
            props.updateReviewCounts('remove');
          }
        }
      }
      //unstuck users who don't have a 1:1 needsReview ratio
      await tagHelper.removeAllConvoTag('needsReview', cId);
      let extraTags = props.convo.tags.filter(string => {
        if (string.includes('needsReview')) {
          return true;
        }
      });

      //unassign all assigned tags
      await tagHelper.removeAllConvoTag('agentAssigned', cId);
      await tagHelper.removeAllConvoTag('groupAssigned', cId);
      /*  For users that have no message tags

        let tagId = await tagHelper.addTag(
          { convoId: cId, tag: extraTags[i]},
          createChatbotConvoTag,
          true
        );

        tagId = tagId.data && tagId.data.createChatbotConvoTag && tagId.data.createChatbotConvoTag.id;

        if (extraTags.length > 0) {
          for (let i = 0; i < extraTags.length; i++) {
            if(typeof tagId === "string") {
              await tagHelper.removeConvoTag(cId, extraTags[i],true);
            }
          }
        }
        */
      setTimeout(() => {
        setIsResolving(false);
      }, 1500);
      props.setUpdateNeedsReview(prev => prev + 1);
    }
  };

  return (
    <TableHelper
      rawData={needsReview}
      title={'Needs Review'}
      tableKeys={tableKeys}
      csvKeys={csvKeys}
      convo={props.convo}
      dateFormatter={props.dateFormatter}
      onEditHandler={props.onEditHandler}
      onViewHandler={props.onViewHandler}
      extraButton={extraButton}
      loading={loading}
    />
  );
}
