import React, { useState, useEffect, useContext } from 'react';
import './NodeProperties.css';
import './NodeProps.scss';

import { ReactComponent as CloseIcon } from '../tempIcons/times-solid.svg';
import { ReactComponent as CopyIcon } from '../tempIcons/file_copy-24px.svg';

import { idGenerator } from '../utility.js';
import { getSingleNode } from './utility';
import { getUserGroups } from 'hooks';

import { Button } from 'stories';

import { API, graphqlOperation, Storage } from 'aws-amplify';
import { listFlowMessages } from 'graphql/queries';

import AuthContext from 'contexts/AuthContext';

import { translateAudience } from 'pages/FlowMessages/audiences.js';

var scrollContRef = null;

export default function NodeProperties(props) {
  const tokens = useContext(AuthContext);
  const [prod, setProd] = useState(false);
  const [copied, setCopied] = useState(false);
  const [nodeId, setNodeId] = useState(null);
  const [preloadedMessages, setPreloadedMessages] = useState([]);
  const [rawPreloadedMessages, setRawPreloadedMessages] = useState([]);

  let { node, show } = props;
  let renderNode = null;

  let pos = '-400px';
  if (node && node.type) {
    renderNode = getSingleNode(node.type);
  }

  if (show) {
    pos = '0';
  }

  useEffect(() => {
    getPreloadedMessages();
  }, []);

  const getPreloadedMessages = async () => {
    let resp = await API.graphql(
      graphqlOperation(listFlowMessages, {
        limit: 9999999,
        filter: {
          deleted: { eq: false },
        },
      })
    );

    let msgs = resp.data.listFlowMessages.items;

    let keyedMsgs = {};
    let selectMsgs = {
      branch: [],
      prompt: [],
      text: [],
      trigger: [],
    };

    const getSelectLabel = m => {
      let aud = m.audience ? translateAudience(m.audience) : 'N/A';
      return (
        <div>
          <div>{m.name}</div>
          <div style={{ fontSize: '10px', color: 'gray' }}>
            <em>{aud}</em>
          </div>
        </div>
      );
    };

    for (var i = 0; i < msgs.length; ++i) {
      keyedMsgs[msgs[i].id] = msgs[i];
      selectMsgs[msgs[i].nodeType].push({
        label: getSelectLabel(msgs[i]),
        value: msgs[i].id,
      });
    }

    setPreloadedMessages(selectMsgs);
    setRawPreloadedMessages(keyedMsgs);
  };

  let { keyedNames, selectListGroups } = getUserGroups();

  const _renderHeader = t => {
    let headerText = 'NODE PROPERTIES';
    let extraStyle = {};

    if (t === 'runBot') {
      headerText = 'RUNNING TESTBOT';
      extraStyle = {
        color: 'white',
        backgroundColor: '#3b4252',
      };
    }

    return (
      <div className="editHeader borderBottom" style={extraStyle}>
        <div className="text">{headerText}</div>
        <div className="exit">
          <CloseIcon style={extraStyle} onClick={() => props.close()} />
        </div>
      </div>
    );
  };

  const _renderNodeInfo = n => {
    if (n.type !== 'runBot') {
      let title = n.name;

      if (n.group === 'ifthen') {
        title = 'IF/THEN (' + n.name + ')';
      }

      return (
        <div className="typeCont borderBottom">
          <div className="typeText">{title}</div>
          <div className="typeInfo">{n.info}</div>
        </div>
      );
    } else {
      if (props.node.flowId) {
        return null;
      } else {
        return (
          <div style={{ position: 'relative' }}>
            <div className="typeCont borderBottom">
              <div className="flexOptions">
                <div
                  className="typeText botEnvText"
                  onClick={() => setProd(false)}
                >
                  Test
                </div>
                <div
                  className="typeText botEnvText"
                  onClick={() => setProd(true)}
                >
                  Prod
                </div>
              </div>
            </div>
            <div
              className="underline"
              style={{ left: prod ? '50%' : '0%' }}
            ></div>
          </div>
        );
      }
    }
  };

  const _renderNodeId = (show, n) => {
    if (show) {
      return (
        <div className="nodeIdCont borderBottom">
          <div className="inline nodeIdText">NODE ID:</div>
          <textarea
            className="inline nodeId nodeTextArea"
            readOnly
            value={n.id}
            ref={n => setNodeId(n)}
          ></textarea>
          <CopyIcon className="copyBtn" onClick={e => copy(e)} />
          {_showCopiedStatus()}
        </div>
      );
    } else {
      return null;
    }
  };

  const _showCopiedStatus = () => {
    let classNames = 'copiedOverlay ' + (copied ? 'showCopied' : '');

    return (
      <div className={classNames}>
        <div className="copiedText">Copied to Clipboard!</div>
      </div>
    );
  };

  const copy = e => {
    nodeId.select();
    document.execCommand('copy');
    nodeId.setSelectionRange(0, 0);
    nodeId.focus();

    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 2200);
  };

  const _renderSection = (section, n) => {
    let extras = 'emoji,';
    if (props.flowType && props.flowType.toLowerCase() !== 'sms') {
      extras += 'var,image,imgGallery,link';
    }

    return (
      <div
        className={
          'paddedSection' + (props.viewOnly ? ' paddedSection--viewOnly' : '')
        }
        style={{ padding: n.type === 'runBot' ? '0px' : null }}
      >
        {React.cloneElement(
          section,
          {
            env: prod ? 'prod' : 'dev',
            containerRef: scrollContRef,
            node: n,
            extras: extras,
            flowType: props.flowType,
            clickedFillNodeId: props.clickedFillNodeId,
            saveInfo: e => props.saveNode(e),
            deleteWarningBranch: e => props.deleteWarningBranch(e),
            deleteWarning: e => props.deleteWarning(n),
            groups: selectListGroups,
            preloadedMessages: preloadedMessages,
            rawPreloadedMessages: rawPreloadedMessages,
            viewOnly: props.viewOnly,
          },
          null
        )}
      </div>
    );
  };

  return (
    <div
      elevation={4}
      className="fullCont"
      ref={e => (scrollContRef = e)}
      style={{
        right: pos,
        overflow:
          renderNode && renderNode.type === 'runBot' ? 'hidden' : 'scroll',
      }}
    >
      {node && renderNode ? (
        <div>
          {_renderHeader(node.type)}
          <div className="offSetDiv">
            {renderNode.id !== 'runBot' ? (
              <div>
                {_renderNodeInfo(renderNode)}
                {_renderNodeId(renderNode.showId, node)}
              </div>
            ) : null}
            {_renderSection(renderNode.section, node)}
          </div>
        </div>
      ) : null}
    </div>
  );
}
