import React, { useState } from "react";
import Select from "react-select";

export default function SearchBarDropdown(props) {
  const [value, setValue] = useState(null);

  const options = props.options
    ? props.options
    : ["add options as a prop...", "blah", "blah", "blah"];

  const onSelect = e => {
    if (value && value.value === e.value) {
      setValue(null);
    } else {
      setValue(e);
    }
    if (props.onSelect) {
      props.onSelect(e.value);
    }
  };

  const customStyles = {
    valueContainer: () => ({
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }),
    control: (_, { selectProps: { width, height } }) => ({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width,
      height,
      borderLeft: "1px solid #d5d5d5",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#ededed"
      }
    }),
    menu: (provided, state) => ({
      ...provided,
      width: "9rem",
      margin: "1px",
      borderTopLeftRadius: "0px",
      borderTopRightRadius: "0px",
      border: "0px"
    }),
    option: (provided, state) => ({
      ...provided,
      cursor: "pointer",
      backgroundColor: optionBG(state),
      color: "#3B4252",
      "&:active": {
        backgroundColor: "#d5d5d5"
      }
    }),
    dropdownIndicator: () => ({
      display: "none"
    }),
    indicatorSeparator: () => ({
      display: "none"
    }),
    placeholder: () => ({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#3B4252"
    })
  };

  const optionBG = state => {
    if (state.isSelected) return "#d5d5d5";
    else if (state.isFocused) return "#ededed";
    else return "white";
  };

  const formatOptionLabel = ({ optionValue, label, customAbbreviation }) => {
    if (value && value.label === label) {
      return <div style={{ fontWeight: "bold", textTransform: "capitalize" }}>{label}</div>;
    } else {
      return <div style={{ textTransform: "capitalize" }}>{label}</div>;
    }
  };

  return (
    <Select
      // className="selectContainer"
      styles={customStyles}
      width="9rem"
      height="3rem"
      value={props.text.toUpperCase()}
      placeholder={props.text.toUpperCase()}
      onChange={onSelect}
      options={options}
      isSearchable={true}
      formatOptionLabel={formatOptionLabel}
    />
  );
}
