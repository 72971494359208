import React from 'react';
import { audiences } from 'pages/FlowMessages/audiences.js';

export const optionsGenerateUID = props => {
  let firstPart = (Math.random() * 46656) | 0;
  let secondPart = (Math.random() * 46656) | 0;
  firstPart = ('000' + firstPart.toString(36)).slice(-3);
  secondPart = ('000' + secondPart.toString(36)).slice(-3);
  const keys = props.data ? Object.keys(props.data) : [];
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (props.data[key] === firstPart + secondPart) {
      firstPart = (Math.random() * 46656) | 0;
      secondPart = (Math.random() * 46656) | 0;
      i = 0;
    }
  }
  return firstPart + secondPart;
};
export const answerGenerateUID = () => {
  let firstPart = (Math.random() * 46656) | 0;
  let secondPart = (Math.random() * 46656) | 0;
  firstPart = ('000' + firstPart.toString(36)).slice(-3);
  secondPart = ('000' + secondPart.toString(36)).slice(-3);
  return firstPart + secondPart;
};
export const onClickRemove = props => {
  const dataArray = Object.keys(props.data);
  const removeKey = dataArray[props.dataKey];
  const newData = props.data;
  delete newData[removeKey];
  if (document.getElementsByName('answerData')[0].value.includes(removeKey)) {
    let newAnswerData = JSON.parse(
      document.getElementsByName('answerData')[0].value
    );
    delete newAnswerData[removeKey];
    document.getElementsByName('answerData')[0].value = JSON.stringify(
      newAnswerData
    );
  }
  props.setData(newData);
  const newOptions = props.options.filter(
    el => parseInt(props.dataKey) !== props.options.indexOf(el)
  );
  props.setOptions(newOptions);
};
export const onChangeAudience = (
  newAudience,
  props,
  data,
  setData,
  setAudience,
  propertyID
) => {
  if (Array.isArray(newAudience)) {
    let myAud = audiences;

    const formattedAudience = newAudience.map(item => {
      for (var i = 0; i < myAud.length; ++i) {
        if (myAud[i].value === item) {
          return myAud[i].flag;
        }
      }
    });

    if (formattedAudience[0] === 'EV') {
      const everyone = ['EV'];
      setData({ ...data, audienceEveryone: true });
      props.setData({
        ...props.data,
        [propertyID]: { ...data, audienceEveryone: true },
      });
      setAudience(everyone);
    } else {
      if (data) {
        props.setData({
          ...props.data,
          [propertyID]: { ...data, audienceEveryone: false },
        });
      }
      setData({
        ...data,
        audience: formattedAudience,
        audienceEveryone: false,
      });
      setAudience(formattedAudience);
    }
    if (data) {
      const type = data.flowName ? 'flow' : 'text';
      return props.setData({
        ...props.data,
        [propertyID]: { ...data, type },
      });
    }
  }
};
export const computeAudienceData = data => {
  if (!data || !data.audience || !data.audience.length) {
    return '';
  } else {
    return JSON.stringify(data.audience);
  }
};

export const computeTextareaData = (data, stateValueAnswer) => {
  if (!data || !data.answer || data.answer === '') {
    return '';
  } else {
    return JSON.stringify(data.answer);
  }
};

export const computeFlowData = (data, stateValueFlowName) => {
  if ((!data || !data.flowName) && stateValueFlowName) {
    return JSON.stringify(stateValueFlowName);
  } else if (!data || !data.flowName || data.flowName === '') {
    return null;
  } else {
    return JSON.stringify(data.flowName);
  }
};
