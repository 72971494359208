import React from "react";
import DateTime from "./DateTime";
import DateTimeForm from "./DateTimeForm";

export default function DateTimeX(props) {
  if (props.form) {
    return <DateTimeForm {...props} />;
  } else {
    return <DateTime {...props} />;
  }
}
