import React from "react";
import "./Sunny.css";

export default function OpenSunny(props) {

  return (
    <div className="showBot" onClick={props.onClick}></div>
  );

}
