import React, { useContext, useEffect, useRef, useState } from "react";
import useForm, { FormContext } from "react-hook-form";
import { store } from "contexts/GlobalStore";
import { H6, InputLabel, InputSubtext, Button, Modal, ModalContent } from "../";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { useModal } from "hooks";

const useFormAsRef = (setFormRef) => {
  const ref = useRef(null);

  useEffect(() => {
    // console.log("!!! useFormAsRef", ref.current);
    if (ref.current) {
      setFormRef(ref.current);
    }
  }, []);

  return ref;
};

export default function Form(props) {
  // console.log("PROPS FORM: ", props);
  const methods = useForm();
  const GlobalState = useContext(store);
  const { loadingFormSubmission } = GlobalState.state;
  const [formRef, setFormRef] = useState(null);
  const { isShowing, toggle } = useModal();
  const [formData, setFormData] = useState(props.data ? props.data : []);

  useEffect(() => {
    setFormData(props.data);
  }, [props.data])

  // const onClickReset = () => {
  //   methods.reset({});
  // };

  const ref = useFormAsRef(setFormRef);

  const onClickDelete = () => {
    if (props.onClickDelete) {
      props.onClickDelete();
    } else {
      console.log("no onClickDelete prop provided to form.js");
    }
  };

  const modalContent = () => {
    if (formRef) {
      return (
        <ModalContent
          confirmation={true}
          title={`By submitting this change, the Campaign Status is now ${
            formRef.status ? formRef.status[0]?.value : "NO VALUE"
          }, do you wish to continue?`}
          onSubmit={methods.handleSubmit(props.onSubmit)}
          toggle={toggle}
        />
      );
    }
  };

  return (
    <div
      {...props}
      style={{ position: "relative", width: props.width ? props.width : null }}
    >
      {!props.noTitle && props.title ? (
        <H6
          style={{
            fontWeight: 900,
            marginTop: "0px",
            marginBottom: props.lessTitleMargin ? "0px" : null,
          }}
        >
          {props.title}
        </H6>
      ) : null}
      {props.summary && props.summary}
      {props.importantInfo ? (
        <div
          style={{
            padding: "10px 20px",
            background: "#ececec",
            borderRadius: "5px",
            fontWeight: "normal",
            display: "flex",
            alignItems: "center",
          }}
        >
          <ErrorOutlineIcon style={{ color: "dodgerblue" }} />
          <div style={{ paddingLeft: "10px" }}>{props.importantInfo}</div>
        </div>
      ) : null}
      <FormContext {...methods}>
        <form
          onSubmit={methods.handleSubmit(props.onSubmit)}
          autoComplete="off"
          ref={ref}
        >
          {props.isEditable && !props.viewOnly && !props.isDuplicate && (
            <div>
              <Button
                style={{
                  backgroundColor: "#EDEDED",
                  border: "1px solid #8D8D8D",
                  color: "black",
                  marginRight: "1rem",
                }}
                onClick={props.editHandlerObj.backHandler}
                label="BACK"
              />
              <Button
                // type="submit"
                loading={loadingFormSubmission ? 1 : 0}
                test={"true"}
                onClick={toggle}
                text="SAVE"
              />
            </div>
          )}
          {props.viewOnly && (
            <Button
              label="DUPLICATE"
              style={{
                backgroundColor: "#EDEDED",
                border: "1px solid #8D8D8D",
                color: "black",
                marginRight: "2rem",
                pointerEvents: "auto",
              }}
              onClick={props.duplicateHandler}
            />
          )}
          {formData.length > 0 && formData.map((el, index) => {
            return (
              <div title={el.tooltip} key={el + index}>
                <InputLabel title={el.title} required={el.required} />
                {el.subtext ? <InputSubtext subtext={el.subtext} /> : null}
                {el.component}
              </div>
            );
          })}
          {props.type === "node" ? (
            <div className="buttonCont">
              {props.jump !== "true" ? (
                <Button
                  style={{
                    backgroundColor: "white",
                    color: "#313131",
                    width: "fit-content",
                    padding: "0px",
                  }}
                  label="DELETE"
                  onClick={props.deleteWarning ? props.deleteWarning : null}
                />
              ) : (
                <div style={{ height: "1px", width: "1px" }}>&nbsp;</div>
              )}
              <Button
                type="submit"
                style={{
                  backgroundColor: "#8b1e3f",
                  color: "white",
                  width: "fit-content",
                  padding: "0.2rem 1.4rem",
                }}
                label="SAVE"
              />
            </div>
          ) : props.viewOnly ? null : !props.isEditable || props.isDuplicate ? (
            <div style={{ marginTop: "2rem" }}>
              {props.back && (
                <Button
                  style={{
                    backgroundColor: "#EDEDED",
                    border: "1px solid #8D8D8D",
                    color: "black",
                    marginRight: "2rem",
                  }}
                  onClick={props.backHandler}
                  label="BACK"
                />
              )}
              <Button
                type="submit"
                loading={loadingFormSubmission ? 1 : 0}
                save={"true"}
                style={{
                  display: props.hideSubmit ? "none" : null,
                  pointerEvents: loadingFormSubmission ? "none" : "unset",
                }}
                text={props.text ? props.text : null}
              />
              {props.deleteButton && (
                <Button
                  delete="true"
                  style={{ marginLeft: "2rem" }}
                  onClick={onClickDelete}
                />
              )}
              {props.close && (
                <Button
                  style={{
                    backgroundColor: "#EDEDED",
                    border: "1px solid #8D8D8D",
                    color: "black",
                    marginLeft: "10rem",
                  }}
                  onClick={props.closeHandler}
                  label="CLOSE"
                />
              )}
            </div>
          ) : null}
        </form>
      </FormContext>
      <Modal isShowing={isShowing} hide={toggle} content={modalContent()} />
    </div>
  );
}
