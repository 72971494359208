import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import { useTheme } from "@material-ui/core/styles";

import { SectionHeader } from "components/SectionHeader";

export default function Settings(props) {
  const theme = useTheme();

  return (
    <div style={{ background: "#F4F4F4", height: "100vh" }}>
      <SectionHeader title="Settings" />
      <Paper style={theme.components.paper}>
        <h3>Settings Page</h3>
      </Paper>
    </div>
  );
}

Settings.propTypes = {
  navigation: PropTypes.object
};
