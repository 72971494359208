import React, { useState, useEffect, useContext } from "react";
import TextField from "@material-ui/core/TextField";
import {
  Form,
  Input,
  Textarea,
  InputVerify,
  FlowSelect,
  Select,
} from "stories";

import { store as GlobalStore } from "contexts/GlobalStore";
import { getFlowNode } from "graphql/queries";

export default function JumpProperties(props) {
  const { node, extras, flowType } = props;

  const GlobalState = useContext(GlobalStore);
  const dispatch = GlobalState.dispatch;

  // console.log(props);

  const initItem = (item) => {
    try {
      return JSON.parse(JSON.parse(node.jumpToNodeId)[item]);
    } catch (e) {
      return null;
    }
  };

  const [messageBody, setMessageBody] = useState(null);
  const [jumpToNodeId, setJumpToNodeId] = useState(null);
  const [skipAfterTimes, setSkipAfterTimes] = useState(null);
  const [postSkipJump, setPostSkipJump] = useState(null);
  const [jumpType, setJumpType] = useState(null);
  const [match, setMatch] = useState("");
  const [image, setImage] = useState(null);

  const [flow, setFlow] = useState(initItem("flow"));
  const [flowVersion, setFlowVersion] = useState(initItem("version"));
  const [isFocused, setIsFocused] = useState(false);
  const [hasInit, setHasInit] = useState(false);

  const initNode = () => {
    setMessageBody(node.messageBody);

    let jumpInfo = node.jumpToNodeId;
    try {
      jumpInfo = JSON.parse(node.jumpToNodeId);
      setFlow(JSON.parse(jumpInfo.flow));
      setFlowVersion(JSON.parse(jumpInfo.version));
      setJumpType("flow");
    } catch (e) {
      if (node.jumpToNodeId) {
        setJumpToNodeId(node.jumpToNodeId);
        setJumpType("nodeId");
      }
    }

    // setJumpToNodeId();
    setSkipAfterTimes(node.skipAfterTimes);
    setPostSkipJump(node.postSkipJump);
    setMatch(node.match);
    setImage(node.image);
  };

  useEffect(() => {
    console.log("JUMP", props);
    initNode();
  }, [props.node]);

  useEffect(() => {
    console.log(
      "DID CHANGE",
      props.clickedFillNodeId,
      document.activeElement.name
    );
    if (
      document.activeElement &&
      document.activeElement.name === "jumpToNodeId"
    ) {
      setJumpToNodeId(props.clickedFillNodeId);
    }
  }, [props.clickedFillNodeId]);

  // ,{
  //   title: "JUMP TO",
  //   subtext: "Please specify node ID to jump to.",
  //   component: <Input name="jumpToNodeId" required form="true" value={jumpToNodeId} onChange={(e)=>setJumpToNodeId(e.target.value)} />
  // }

  const getEditInfo = () => {
    try {
      let jumpInfo = JSON.parse(node.jumpToNodeId);

      return {
        flow: JSON.parse(jumpInfo.flow),
        flowVersion: jumpInfo.version,
      };
    } catch (e) {}
  };

  const getFilter = () => {
    return {
      key: "flowId",
      value: node.flowId,
    };
  };

  const getQueryInfo = () => {
    return {
      idName: "id",
      query: getFlowNode,
      name: "getFlowNode",
    };
  };

  const types = () => {
    return [
      { label: "Flow", value: "flow" },
      { label: "Node ID", value: "nodeId" },
    ];
  };

  const getType = (val) => {
    let t = types();
    for (var i = 0; i < t.length; ++i) {
      if (val === t[i].value) {
        return t[i].label;
      }
    }
    return val;
  };

  const acceptJumpType = (e) => {
    setJumpType(e.value);
  };

  const formData = [
    {
      title: "MESSAGE BODY (OPTIONAL)",
      subtext: "Optional message body to show before jump.",
      component: (
        <Textarea
          name="messageBody"
          image={image}
          allowextras={props.viewOnly ? false : extras}
          charLimit="160"
          isSMS
          readOnly={props.viewOnly ? "readonly" : false}
          form="true"
          value={messageBody}
        />
      ),
    },
    {
      title: "SKIP AFTER",
      subtext:
        'Please specify the number of jumps before moving to the next child node. For example, a value of "1" will loop only one time before moving on to the post skip jump',
      component: (
        <div style={{ display: "flex" }}>
          <Input
            name="skipAfterTimes"
            required
            style={{ width: "80px" }}
            type="number"
            min="0"
            form="true"
            readOnly={props.viewOnly ? "readonly" : false}
            value={skipAfterTimes}
          />{" "}
          <div style={{ marginTop: "11px", marginRight: "200px" }}>Times</div>{" "}
        </div>
      ),
    },
    {
      title: "POST SKIP JUMP",
      subtext:
        "Please specify the Flow or Node ID to jump to after skips. If value is left blank the flow will stop.",
      component: (
        <Select
          form
          name="jumpType"
          placeholder=" "
          disabled={props.viewOnly}
          options={types()}
          onChange={acceptJumpType}
          updateStateValue={jumpType ? getType(jumpType) : null}
        />
      ),
    },
  ];

  if (jumpType === "flow") {
    formData.push({
      component: (
        <FlowSelect
          form
          menuPlacement="top"
          name="flow"
          type={flowType}
          ignoreFlowIds={[node.flowId]}
          hardSetFlow={getEditInfo()}
          disabled={props.viewOnly ? true : false}
        />
      ),
    });
  } else if (jumpType === "nodeId") {
    formData.push({
      subtext:
        "Either type in your node ID, or place your cursor inside the textbox, hold SHIFT and click on the node to jump to.",
      component: (
        <InputVerify
          name="jumpToNodeId"
          verifyQuery={getQueryInfo()}
          filter={getFilter()}
          form="true"
          value={jumpToNodeId}
          viewOnly={props.viewOnly}
        />
      ),
    });
  }

  const onSubmit = (data, e) => {
    if (data.type !== "submit") {
      console.log("DATA", data);
      let jumpToNodeInfo = {
        flow: null,
        version: null,
      };
      for (let key in data) {
        if (data[key] === "") {
          data[key] = null;
        }
        if (key === "flow") {
          if (data[key] !== null) jumpToNodeInfo.flow = data[key];
          delete data[key];
        }
        if (key === "flowVersion") {
          if (data[key] !== null) jumpToNodeInfo.version = data[key];
          delete data[key];
        }

        if (
          key.indexOf("jumpType") > -1 ||
          key.indexOf("flowAnswer") > -1 ||
          key.indexOf("flowVersionAnswer") > -1
        ) {
          delete data[key];
        }
      }

      try {
        if (jumpToNodeInfo.flow)
          data.jumpToNodeId = JSON.stringify(jumpToNodeInfo);
      } catch (e) {}
      // dispatch({ type: "updateEditInfo", editInfo: { empty: true } });
      props.saveInfo(data);
    }
  };

  return (
    <Form
      data={formData}
      onSubmit={onSubmit}
      deleteWarning={props.deleteWarning}
      jump={match === "NO MATCH" ? "true" : null}
      type="node"
      style={{ width: "100%" }}
    />
  );
}
