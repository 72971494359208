import React, { useState, useEffect, useContext } from 'react';

import AuthContext from 'contexts/AuthContext';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItem from '@material-ui/core/ListItem';
import { API, graphqlOperation } from 'aws-amplify';
import * as tagHelper from '../Helpers/tagHelper.js';
import { tagByMessageId, convoTagByConvoId } from 'graphql/queries';
import {
  createChatbotConvoTag,
  updateChatbotConvoTag,
} from 'graphql/mutations';

export default function ConvoActionSubMenu(props) {
  const {
    subanchorEl,
    closeSubMenu,
    subMenuType,
    allUsers,
    allGroups,
    selectedConvo,
    handleMenuClose,
    listFilter,
  } = props;

  let arr = [];

  if (subMenuType === 'user') {
    arr = allUsers;
  } else if (subMenuType === 'group') {
    arr = allGroups;
  } else if (subMenuType === 'folder') {
    arr = allGroups;
  } else if (subMenuType === 'unassign') {
    arr = []
  }

  function getAsurite() {
    const tokens = JSON.parse(localStorage.getItem('usertokens'));
    return tokens.asurite;
  }

  const updateSingleNeedsReviewTag = async (groupId, convoId) => {
    let resp = await API.graphql(
      graphqlOperation(convoTagByConvoId, {
        convoId: convoId,
        filter: {
          tag: {
            beginsWith: 'needsReview',
          },
          deleted: {
            eq: false,
          },
        },
      })
    );

    try {
      let toUpdate = resp.data.convoTagByConvoId.items;

      let genericTag = toUpdate.filter((convoTagRowObject, index) => {
        if (convoTagRowObject.tag.includes('true')) {
          return true;
        }
      });

      //console.log("!!! trueTags is", trueTags);

      if (genericTag.length > 0) {
        let updateResp = await API.graphql(
          graphqlOperation(updateChatbotConvoTag, {
            input: {
              id: genericTag[0].id,
              deleted: true,
              deletedBy: getAsurite(),
            },
          })
        );

        let createResp = await API.graphql(
          graphqlOperation(createChatbotConvoTag, {
            input: {
              convoId: convoId,
              addedBy: getAsurite(),
              deleted: false,
              tag: 'needsReview ' + groupId,
            },
          })
        );
      } else {
        console.log('COULD NOT UPDATE: toUpdate is', toUpdate, genericTag);
      }
    } catch (e) {
      console.log('COULD NOT UPDATE: ', e);
    }

    return;
  };

  const addToFolder = async (folderTag, groupId) => {
    //console.log("!!! convo", selectedConvo.convoId);
    await tagHelper.removeAllConvoTag('groupAssigned', selectedConvo.convoId);
    await tagHelper.removeAllConvoTag('folder', selectedConvo.convoId);
    await tagHelper.removeAllConvoTag('unseenBy', selectedConvo.convoId);

    let tagPay = {
      convoId: selectedConvo.convoId,
      tag: folderTag,
    };

    tagHelper.addTag(tagPay, createChatbotConvoTag);

    let tagPay2 = {
      convoId: selectedConvo.convoId,
      tag: 'groupAssigned ' + groupId,
    };

    tagHelper.addTag(tagPay2, createChatbotConvoTag);

    let tagPay3 = {
      convoId: selectedConvo.convoId,
      tag: 'unseenBy ' + groupId,
    };

    tagHelper.addTag(tagPay3, createChatbotConvoTag);

    props.setSelectedConvo('');
    // props.setReRenderKey(new Date().getTime());groupId
    props.acceptTagChange(null, null, folderTag);

    closeSubMenu();
    handleMenuClose();
  };

  const clickedOnSubMenuItem = async (item, type) => {
    let tagKey = 'groupAssigned';
    let grpName = null;

    if (type === 'user') {
      tagKey = 'agentAssigned';
    }

    if (type === 'group') {
      if (listFilter && listFilter.indexOf('agentAssigned') === -1) {
        grpName = listFilter.split(' ')[1];
      }

      if (grpName && grpName !== 'true') {
        if (selectedConvo.tags.indexOf('needsReview ' + grpName) > -1) {
          await tagHelper.removeConvoTag(
            selectedConvo.convoId,
            'needsReview ' + grpName
          );
          await tagHelper.addTag(
            { convoId: selectedConvo.convoId, tag: 'needsReview ' + item },
            createChatbotConvoTag
          );
        }
      }
    }

    let tag = tagKey + ' ' + item;

    await tagHelper.removeAllConvoTag(tagKey, selectedConvo.convoId);
    if (type !== 'user') {
      await tagHelper.removeAllConvoTag('folder', selectedConvo.convoId);
    }

    let tagPay = {
      convoId: selectedConvo.convoId,
      tag: tag,
    };

    tagHelper.addTag(tagPay, createChatbotConvoTag);

    let tagPay2 = {
      convoId: selectedConvo.convoId,
      tag: 'unseenBy ' + item,
    };
    tagHelper.addTag(tagPay2, createChatbotConvoTag);

    if (type === 'group') {
      //Skip conversation close
      //props.setSelectedConvo("");
    }

    /*console.log(
      "!!! Changing group to",
      item,
      "for",
      selectedConvo.phoneNumber
    );*/
    if (type === 'group') {
      updateSingleNeedsReviewTag(item, selectedConvo.convoId);
    }

    props.acceptTagChange(item, grpName);
    closeSubMenu();
    handleMenuClose();
  };

  const FolderGroupOwner = React.forwardRef(function FolderGroupOwner(
    { disabled, ...other },
    ref
  ) {
    return (
      <ListItem
        style={{
          fontSize: '12px',
          padding: '10px',
          color: 'gray',
        }}
        {...other}
        ref={ref}
      />
    );
  });

  return (
    <Menu
      keepMounted
      anchorEl={subanchorEl}
      open={Boolean(subanchorEl)}
      onClose={closeSubMenu}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      PaperProps={{
        style: {
          maxHeight: '20em',
        },
      }}
    >
      {arr.map(item => {
        if (subMenuType !== 'folder') {
          return (
            <MenuItem
              onClick={() => clickedOnSubMenuItem(item.id, subMenuType)}
            >
              {item.display}
            </MenuItem>
          );
        } else if (item.folders && item.folders.length) {
          return (
            <div>
              <FolderGroupOwner>{item.display}</FolderGroupOwner>
              {item.folders.map(folder => {
                return (
                  <MenuItem
                    onClick={async () => {
                      addToFolder(folder.tagValue, item.id);
                    }}
                  >
                    {folder.name}
                  </MenuItem>
                );
              })}
            </div>
          );
        }
      })}
    </Menu>
  );
}
