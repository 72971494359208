import React, { useState, useEffect } from "react";
import { Form, Textarea } from "stories";
import SaveNode from "./SaveNode";

export default function TextProperties(props) {
  const { node, extras } = props;
  const [text, setText] = useState(node.text);
  const [image, setImage] = useState(node.image);

  const initNode = () => {
    setText(node.text);
    setImage(node.image);
  };

  useEffect(() => {
    initNode();
  }, [props.node]);

  const flag = (e) => {
    setText(e.target.value);
  };

  const acceptPreload = (x) => {
    let preFillData = props.rawPreloadedMessages[x];
    setImage(preFillData.image);
    setText(preFillData.message);
  };

  let formData = [
    {
      title: "MESSAGE BODY",
      component: (
        <Textarea
          name="text"
          preloadOptions={!props.viewOnly ? props.preloadedMessages.text : false}
          acceptPreload={acceptPreload}
          form="true"
          image={image}
          charLimit="160"
          isSMS
          allowextras={props.viewOnly ? false : extras}
          value={text}
          required
          readOnly={props.viewOnly ? "readonly" : false}
        />
      ),
    },
  ];

  let saveNode = SaveNode(props.groups, props.node, props.viewOnly);
  formData = formData.concat(saveNode);

  const onSubmit = (data, e) => {
    if (data.type !== "submit") {
      props.saveInfo(data);
    }
  };

  return (
    <Form
      data={formData}
      onSubmit={onSubmit}
      deleteWarning={props.deleteWarning}
      type="node"
      style={{ width: "100%" }}
    />
  );
}
