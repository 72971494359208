import React from "react";
import { useTheme } from "@material-ui/core/styles";

import "../forms.css";

export default function Input(props) {
  const theme = useTheme();

  const defaultKeyPressHandler = (e) => {
    if (e.key === "Enter") e.preventDefault();
  };

  return (
    <div style={{ display: "flex", flex: 1 }}>
      <input
        {...props}
        // onKeyPress={(e) => {
        //   if (e.key === "Enter") e.preventDefault();
        // }}
        onKeyPress={
          props.onKeyPress ? props.onKeyPress : defaultKeyPressHandler
        }
        className="input"
        placeholder={props.placeholder ? props.placeholder : null}
        autoComplete="off"
        ref={(e) => {
          // setCurrentTextField(e);
          if (props.setTextFieldRef) props.setTextFieldRef(e);
        }}
        style={{
          ...theme.typography.body1,
          flex: 1,
          color: theme.palette.general.darkGrey,
          border: `1px solid ${theme.palette.general.deepGrey}`,
          padding: "0.5rem 1rem",
          ...props.style,
        }}
      />
    </div>
  );
}
