import React, { useState, useEffect, useContext } from 'react';
import { useTheme } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { useFormContext } from 'react-hook-form';

import AuthContext from 'contexts/AuthContext';
import { Input } from 'stories';

export default function Variations(props) {
  const tokens = useContext(AuthContext);
  const theme = useTheme();
  const { register } = useFormContext();
  const [value, setValue] = useState('');
  const [variations, setVariations] = useState([]);

  const [regex, setRegex] = useState([]);
  const [charNotSupported, setCharNotSupported] = useState(null);

  useEffect(() => {
    if (props.stateValue) {
      setVariations([...props.stateValue]);
    }
  }, [props.stateValue]);

  useEffect(() => {
    if (props.regex) {
      setRegex(new RegExp(props.regex));
    }
  }, [props.regex]);

  const style = index => {
    if (variations.length === 1) {
      return {
        display: 'flex',
        padding: '0.5rem',
        border: `1px solid ${theme.palette.general.deepGrey}`,
        borderRadius: '4px',
      };
    } else if (index === 0) {
      return {
        display: 'flex',
        padding: '0.5rem',
        border: `1px solid ${theme.palette.general.deepGrey}`,
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
      };
    } else if (variations.length >= 3 && index <= variations.length - 2) {
      return {
        display: 'flex',
        padding: '0.5rem',
        border: `1px solid ${theme.palette.general.deepGrey}`,
        borderTopWidth: '0px',
      };
    } else {
      return {
        display: 'flex',
        padding: '0.5rem',
        borderStyle: 'solid',
        borderWidth: '0 1px 1px 1px',
        borderColor: theme.palette.general.deepGrey,
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px',
      };
    }
  };

  const handleInput = event => {
    setValue(event.target.value);
  };

  const handleAdd = () => {
    if (value === '') return null;
    const tempValue = value.replace(/,/g, '~~~');
    setVariations([...variations, tempValue]);
    setValue('');
  };

  const handleRemove = index => {
    const start = variations.slice(0, index);
    const end = variations.slice(index + 1);
    const newVariations = start.concat(end);
    setVariations(newVariations);
  };

  return (
    <div style={{ ...props.style }}>
      <div
        style={{
          display: 'flex',
          backgroundColor: theme.palette.general.lightGrey,
          padding: '.5rem',
          border: `1px solid ${theme.palette.general.deepGrey}`,
          borderRadius: '4px',
        }}
      >
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Input
              id="variations-input"
              value={value}
              onChange={handleInput}
              charLimit={props.charLimit ? props.charLimit : ''}
              style={{
                backgroundColor: 'white',
                width: '100%',
              }}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <AddCircleOutlineIcon
                style={{
                  fontSize: '30px',
                  marginLeft: '0.5rem',
                  color: theme.palette.general.deepGrey,
                  cursor: 'pointer',
                }}
                onClick={handleAdd}
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'center',
              alignItems: 'center',
              height: '1px',
            }}
          >
            <div style={{ display: 'flex', flex: 1 }} />
            <input
              {...props}
              type="select"
              ref={props.required ? register({ required: true }) : register}
              value={variations}
              autocomplete="off"
              style={{
                display: 'flex',
                flex: 1,
                opacity: 0,
                height: '1px',
                width: '1px',
                cursor: 'default',
              }}
            />
            <div style={{ display: 'flex', flex: 1 }} />
          </div>
        </div>
      </div>

      <div style={{ marginTop: '0.5rem' }}>
        {variations.map((el, index) => {
          return (
            <div key={index} style={style(index)}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                {el.replace(/~~~/g, ',')}
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <RemoveCircleOutlineIcon
                  style={{
                    fontSize: '30px',
                    marginLeft: '0.5rem',
                    color: theme.palette.general.deepGrey,
                    cursor: 'pointer',
                  }}
                  onClick={() => handleRemove(index)}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
