import { API, graphqlOperation, Storage } from "aws-amplify";

import { flowByFlowId, sendToEditQueue } from "graphql/queries";
import {
  updateFlowNode,
  createFlowNode,
  updateFlow,
  createFlowMessage,
  updateFlowMessage,
} from "graphql/mutations";
import { idGenerator } from "./utility.js";

var tempMatches = [];
var flowNodes = [];

Storage.configure({ level: "public" });

function getAsurite() {
  const tokens = JSON.parse(localStorage.getItem("usertokens"));
  return tokens.asurite;
}

export function alterFlowMessages(payload, isNew) {
  let op = isNew ? createFlowMessage : updateFlowMessage;

  if (isNew) {
    payload.submittedBy = getAsurite();
    payload.deleted = false;
  } else if (payload.deleted) {
    payload.deletedBy = {
      asurite: getAsurite(),
      timestamp: new Date().getTime() + "",
    };
  }

  API.graphql(
    graphqlOperation(op, {
      input: payload,
    })
  );
}

export function editFlow(flowId) {
  API.graphql(
    graphqlOperation(updateFlow, {
      input: {
        id: flowId,
        editing: {
          asurite: getAsurite(),
          timestamp: new Date().getTime(),
        },
      },
    })
  );

  API.graphql(
    graphqlOperation(sendToEditQueue, {
      flowId: flowId,
      operation: "addAsEditing",
    })
  );
}

export function releaseEdit(flowId) {
  API.graphql(
    graphqlOperation(updateFlow, {
      input: {
        id: flowId,
        editing: null,
      },
    })
  ).then((resp) => {
    console.log("RELEASED FROM EDIT", flowId);
  });
}

export function getTree(flowId, nextToken) {
  if (!nextToken) flowNodes = [];

  return new Promise(function tryagain(resolve, reject) {
    console.log("GETTING FLOWID", flowId);
    let params = {
      flowId: flowId,
      limit: 100,
      filter: { deleted: { eq: false } },
    };

    if (nextToken) {
      params.nextToken = nextToken;
    }

    API.graphql(graphqlOperation(flowByFlowId, params))
      .then((resp) => {
        nextToken = resp.data.flowByFlowId.nextToken;
        let all = resp.data.flowByFlowId.items;

        for (var i = 0; i < all.length; ++i) {
          if (!all[i].deleted) {
            flowNodes.push(all[i]);
          }
        }

        if (nextToken) {
          tryagain(resolve, reject);
        } else {
          resolve(flowNodes);
        }
      })
      .catch((err) => {
        console.log("There was an issue getting tree data", err);
        resolve([]);
      });
  });
}

export function postNode(payload, flowId, parent, versionNumber) {
  let promises = [];

  delete payload.show;
  delete payload.createdAt;
  delete payload.updatedAt;

  return new Promise(function(resolve, reject) {
    if (!payload.deleteAudit) {
      payload.deleted = false;
    }

    console.log("SAVING: ", payload);

    let toSend = { ...payload };

    toSend.flowId = flowId;
    let op = updateFlowNode;

    delete toSend.createdAt;
    delete toSend.updatedAt;

    if (payload.isNew) {
      delete toSend.isNew;
      op = createFlowNode;

      if (toSend.type === "branch") {
        let noMatchId = null;

        for (var i = toSend.matches.length - 1; i >= 0; --i) {
          if (toSend.matches[i].match === "NO MATCH") {
            noMatchId = toSend.matches[i].id;
            break;
          }
        }

        let jumpNode = {
          id: noMatchId,
          flowId: flowId,
          deleted: false,
          parent: toSend.id,
          match: "NO MATCH",
        };

        if (toSend.type === "branch") {
          jumpNode.jumpToNodeId = null;
          jumpNode.skipAfterTimes = 0;
          jumpNode.type = "jump";
          promises.push(executePost(createFlowNode, jumpNode));
        }
      }
    } else if (toSend.type === "branch" || toSend.type === "ifthen") {
      for (var i = 0; i < toSend.matches.length; ++i) {
        if (toSend.matches[i].didEdit) {
          delete toSend.matches[i].didEdit;
          let updateMatch = {
            id: toSend.matches[i].id,
            match: toSend.matches[i].match,
          };
          promises.push(executePost(updateFlowNode, updateMatch));
        }
      }
    }

    promises.push(executePost(op, toSend));

    Promise.all(promises)
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        console.log("DID ERR ->", err);
        reject(err);
      });
  });
}

function executePost(op, toSend) {
  return new Promise(function(resolve, reject) {
    API.graphql(graphqlOperation(op, { input: toSend }))
      .then((resp) => {
        let data = resp.data.createFlowNode
          ? resp.data.createFlowNode
          : resp.data.updateFlowNode;
        resolve(data);
      })
      .catch((err) => {
        console.log("ERRORED", err);
        reject();
      });
  });
}

export function startDelete(branchUpdates, markedForDelete, flowId) {
  return new Promise(async function(resolve, reject) {
    let toDelete = markedForDelete;

    let promises = [];
    let deleteDate = new Date().getTime();

    if (branchUpdates !== null) {
      let branchUpdate = {
        id: branchUpdates.id,
        matches: branchUpdates.newMatches,
      };
      promises.push(postNode(branchUpdate, flowId));
    }

    for (var i = 0; i < toDelete.length; ++i) {
      let payload = {
        id: toDelete[i],
        deleted: true,
        deleteAudit: {
          timestamp: deleteDate,
          asurite: getAsurite(),
        },
      };
      promises.push(postNode(payload, flowId));
    }

    await Promise.all(promises);

    resolve();
  });
}

export function startUndo(branchUpdates, markedForDelete, flowId) {
  return new Promise(function(resolve, reject) {
    let toDelete = markedForDelete;
    let promises = [];

    if (branchUpdates !== null) {
      let branchUpdate = {
        id: branchUpdates.id,
        matches: branchUpdates.matches,
      };
      promises.push(postNode(branchUpdate, flowId));
    }

    for (var i = 0; i < toDelete.length; ++i) {
      let payload = {
        id: toDelete[i],
        deleted: false,
        deleteAudit: null,
      };
      promises.push(postNode(payload, flowId));
    }

    Promise.all(promises)
      .then((resp) => {
        console.log("UNDID", resp);
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// when deleting from a match the match id needs to regernate
// so you can add another node after delete
export function regenerateBranchId(
  deleteParentInfo,
  rawTree,
  markedForDelete,
  flowId
) {
  let updateBranch = {};

  if (deleteParentInfo.isBranch === true) {
    for (var i = 0; i < rawTree.length; ++i) {
      if (rawTree[i].id === deleteParentInfo.id) {
        updateBranch = rawTree[i];
        for (var m = 0; m < updateBranch.matches.length; ++m) {
          let ind = markedForDelete.indexOf(updateBranch.matches[m].id);
          if (ind > -1) {
            let newId = idGenerator();

            deleteParentInfo.newMatchId = newId;
            deleteParentInfo.oldMatchId = updateBranch.matches[m].id;
            deleteParentInfo.child = markedForDelete[ind];

            updateBranch.matches[m].id = newId;
            postNode(updateBranch, flowId);
          }
        }
        break;
      }
    }
  }

  return deleteParentInfo;
}

// Reset node to previous id when undo
export function resetBranchId(
  deleteParentInfo,
  rawTree,
  markedForDelete,
  flowId
) {
  let updateBranch = {};

  if (deleteParentInfo.isBranch === true) {
    for (var i = 0; i < rawTree.length; ++i) {
      if (rawTree[i].id === deleteParentInfo.id) {
        updateBranch = rawTree[i];
        for (var m = 0; m < updateBranch.matches.length; ++m) {
          if (updateBranch.matches[m].id === deleteParentInfo.newMatchId) {
            updateBranch.matches[m].id = deleteParentInfo.oldMatchId;
            postNode(updateBranch, flowId);
          }
        }

        break;
      }
    }
  }
}
