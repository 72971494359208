import React from "react";
import Checkbox from "./Checkbox";
import CheckboxAudienceForm from "./CheckboxAudienceForm";
import CheckboxEscalationsForm from "./CheckboxEscalationsForm";
import CheckboxInput from "./CheckboxInput";

export default function CheckboxX(props) {
  if (props.form && props.audience) {
    return <CheckboxAudienceForm {...props} />;
  } else if (props.form && props.escalations) {
    return <CheckboxEscalationsForm {...props} />;
  } else if (props.form && props.checkboxInput) {
    return <CheckboxInput {...props} />;
  } else {
    return <Checkbox {...props} />;
  }
}
