import React, { useState, useEffect, useContext } from 'react';
import AuthContext from 'contexts/AuthContext';
import * as tagHelper from '../Helpers/tagHelper';

import { Select, Button, ThreeSquare, Accordion } from 'stories';

import { API, graphqlOperation } from 'aws-amplify';
import { searchMessages } from 'graphql/queries';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { files } from '@storybook/addon-knobs';

export default function DialogueActions(props) {
  const [data, setData] = useState([]);
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [summary, setSummary] = useState([]);
  const [details, setDetails] = useState([]);

  const { convo } = props;

  useEffect(() => {
    fetchData();
  }, []);

  async function queryForData(asurite) {
    let payload = { asurite: asurite };

    let resp;

    if (typeof asurite !== "undefined") {
      resp = await API.graphql(
        graphqlOperation(searchMessages, {
          operation: 'getOpportunityInfo',
          payload: JSON.stringify(payload),
        })
      );
    } else {
      resp = {
        data: {
          searchMessages: JSON.stringify({})
      }};
    }

    let tempData = JSON.parse(resp.data.searchMessages);

    let allData = [];

    if (Array.isArray(tempData.undergradOpp)) {
      allData = tempData.undergradOpp.concat(tempData.graduateOpp);
    }

    allData = allData.filter(opp => {
      if (Object.keys(opp).length === 0) {
        return false;
      } else {
        return true;
      }
    });

    return allData;
  }

  const fetchData = async () => {
    let promises = [];

    if (convo.asurite && convo.asurite !== 'NONE') {
      promises.push(queryForData(convo.asurite));
    }

    let resps = await Promise.all(promises);

    let allSums = [];
    let allDets = [];
    let newData = [];

    for (var i = 0; i < resps.length; ++i) {
      let currResp = handleAllOppData(resps[i]);
      allSums = allSums.concat(currResp.allSums);
      allDets = allDets.concat(currResp.allDets);
      newData = newData.concat(currResp.newData);
    }

    setSummary(allSums);
    setDetails(allDets);
    setData(newData);

    setLoadingComplete(true);
  };

  const handleAllOppData = resps => {
    let allSums = [];
    let allDets = [];
    let newData = [];

    for (var i = 0; i < resps.length; ++i) {
      let dataObj = handleOppData(resps[i]);
      allSums = allSums.concat(dataObj.allSums);
      allDets = allDets.concat(dataObj.allDets);
      newData.push(dataObj.newData);
    }

    return { allSums, allDets, newData };
  };

  const handleOppData = tempData => {
    let allSums = [];
    let allDets = [];
    let newData = [tempData];

    let all_data = {
      name: 'Name',
      career: 'Career',
      type: 'Type',
      stageName: 'Stage',
      termCode: 'Term',
      internationalStudent: 'International Student',
      ownerName: 'Opportunity Owner',
      territoryManagerEmail: 'Territory Manager email', //(We don't have in API?)
      deferred: 'Deferred',
      locationName: 'Location',
      collegeName: 'College',
      academicPlanName: 'Plan/Major Description',
      createdDate: 'Application Date',
      enrollmentDepositStatus: 'Enrollment Deposit Status',
      orientationDate: 'Orientation Date', //  (We don't have in API?)
      orientationStatus: 'Orientation Status',
      barrettApplicationStatus: 'Barrett Application Status', // (We don't have in API?)
      barrettApplicationDecision: 'Barrett Application Decision', //  (We don't have in API?)
      houseingStatus: 'Housing Status',
      currentCiScore: 'CI Score',
      militaryStatus: 'Military Status',
      territoryManagerPhone: 'Territory Manager phone', // (We don't have in API?)
      opportunityId: 'Opportunity ID',
    };

    let names = [];

    newData = newData.map(incomingObject => {
      let newObject = [];

      for (var key in all_data) {
        let tmp = {
          name: all_data[key],
          value: incomingObject[key],
        };
        if (tmp.name === 'Name') {
          names.push(tmp.value);
        } else if (tmp.value) {
          newObject.push(tmp);
        }
      }

      return newObject;
    });

    for (var i = 0; i < newData.length; ++i) {
      let s = (
        <div className="AccordionSummary" style={{ display: 'flex' }}>
          <div
            className="opportunity-collapsable-name"
            style={{ paddingRight: '10px' }}
          >
            Name:
          </div>
          <div className="simpleEllipsis">{names[i]}</div>
        </div>
      );

      let nDetails = newData[i].map(item => {
        return (
          <div className="opportunity-collapsable-row">
            <div className="opportunity-collapsable-name">
              {item.name + ':'}
            </div>{' '}
            <div className="opportunity-collapsable-content">{item.value}</div>
          </div>
        );
      });

      allSums.push(s);
      allDets.push(nDetails);
    }

    return { allSums, allDets, newData };
  };

  const collapsableEvent = event => {
    if (event.currentTarget.parentElement.className.includes('open')) {
      event.currentTarget.parentElement.className =
        'dialogues-opportunity-collapsable';
    } else {
      event.currentTarget.parentElement.className =
        'dialogues-opportunity-collapsable dialogues-opportunity-collapsable--open';
    }
  };

  if (!loadingComplete) {
    return (
      <div style={{ textAlign: 'center' }}>
        <div
          style={{
            textAlign: 'center',
            color: 'rgb(0, 0, 0)',
            marginBottom: '1em',
          }}
        >
          Finding opportunities...
        </div>
        <div
          style={{
            width: '3rem',
            margin: '0 auto',
          }}
        >
          <ThreeSquare />
        </div>
      </div>
    );
  } else if (data.length === 0) {
    return (
      <div style={{ textAlign: 'center', color: 'rgb(115, 115, 115)' }}>
        No opportunities found.
      </div>
    );
  } else {
    return (
      <div className="dialogues-opportunity-page">
        {summary.map((item, index) => {
          return (
            <Accordion
              summaryContent={item}
              expandedSummaryContent={item}
              detailsContent={details[index]}
            />
          );
        })}
      </div>
    );
  }
}
