import React, { useState, useContext, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';

import { useTheme, withStyles } from '@material-ui/core/styles';
import { API, graphqlOperation } from 'aws-amplify';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Switch from '@material-ui/core/Switch';

import { useModal } from 'hooks';
import * as queries from 'graphql/queries';
import * as mutations from 'graphql/mutations';
import * as utility from './utility.js';
import AuthContext from 'contexts/AuthContext';
import { store } from 'contexts/GlobalStore';
import { SectionHeader } from 'components/SectionHeader';
import { roleCheck } from 'services/utility/RoleMatrix';
import {
  Form,
  Select,
  Input,
  Variations,
  ErrorLabel,
  CreateNewButton,
  ConditionInput,
  Modal,
  ModalContent,
  Textarea,
  H6,
} from 'stories';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const PauseSlider = withStyles(theme => ({
  root: {
    width: 45,
    height: 23,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(20px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
      // border: 'none',
    },
  },
  thumb: {
    marginTop: 1,
    marginLeft: 2,
    width: 19,
    height: 19,
  },
  track: {
    borderRadius: 23 / 2,
    // border: `1px solid ${theme.palette.grey[400]}`,
    border: `none`,
    // backgroundColor: theme.palette.grey[50],
    backgroundColor: '#D6D5D4',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <H6
        style={{
          fontWeight: 900,
          margin: '0px',
          fontSize: '0.875rem',
          marginRight: '10px',
        }}
      >
        {props.title ? props.title : 'PAUSE CAMPAIGN'}
      </H6>
      <Switch
        // checked={props.checked}
        // onChange={props.onChange}
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    </div>
  );
});

export default function AlertAdd(props) {
  const tokens = useContext(AuthContext);
  const theme = useTheme();
  let history = useHistory();
  const { isShowing, toggle } = useModal();

  const GlobalState = useContext(store);
  const { dispatch } = GlobalState;
  const { editInfo } = GlobalState.state;

  console.log('EDIT INFO: ', editInfo);

  const [rerenderKey, setRerenderKey] = useState(Date.now());
  const [modalContentType, setModalContentType] = useState('');
  const [formValues, setFormValues] = useState(null);
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setSuccess] = useState(false);
  const [escalationData, setEscalationData] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [runningQuestion, setRunningQuestion] = useState(false);
  const [mainQuestionStatusMsg, setMainQuestionStatusMsg] = useState('');
  const [mainQuestionStatus, setMainQuestionStatus] = useState('');
  const [attachedEvent, setAttachedEvent] = useState(false);
  const [escalateSms, setEscalateSms] = useState(
    editInfo.escalateSms ? true : false
  );

  useEffect(() => {
    fetchEscalations();
  }, []);

  const fetchEscalations = async () => {
    try {
      let filter = { deleted: { eq: false }, status: { eq: 4 } };

      const result = await API.graphql(
        graphqlOperation(queries.listEscalations, {
          limit: 1000,
          filter: filter,
        })
      );

      let escalationNames = [];
      let results = result.data.listEscalations.items;
      if (results) {
        for (let i = 0; i < results.length; i++) {
          escalationNames.push({
            value: results[i].id,
            label: results[i].escalationName,
          });
        }
      }

      setEscalationData(escalationNames);
    } catch (error) {
      console.log('fetchData error: ', error);
    } finally {
    }
  };

  const generateUID = () => {
    let firstPart = (Math.random() * 46656) | 0;
    let secondPart = (Math.random() * 46656) | 0;
    firstPart = ('000' + firstPart.toString(36)).slice(-3);
    secondPart = ('000' + secondPart.toString(36)).slice(-3);
    return firstPart + secondPart;
  };

  const onSubmit = async (data, e) => {
    if (data.type !== 'submit') {
      dispatch({
        type: 'setLoadingFormSubmission',
        loadingFormSubmission: true,
      });

      try {
        const postData = {
          status: getStatusKey(JSON.parse(data.status).value),
          statusKey:
            getStatusKey(JSON.parse(data.status).value) + '-' + generateUID(),
          triggerPhrase: data['trigger phrase'],
          // response: data["response"],
          fallbackAnswer: data.fallbackAnswer,
          variations: data.variations ? data.variations.split(',') : [],
          // escalation: JSON.parse(data.escalation).value,
          timestamp: new Date().getTime().toString(),
          timestampKey: new Date().getTime().toString() + '-' + generateUID(),
          escalateSms: escalateSms,
          editedBy: editInfo.editedBy
            ? [
                ...JSON.parse(editInfo.editedBy),
                {
                  asurite: tokens.asurite,
                  timestamp: new Date().getTime().toString(),
                },
              ]
            : [],
          deleted: false,
          processing: true,
        };

        // check for duplicate audience roles
        const parsedData = JSON.parse(data.answerData);
        const dataArray = Object.keys(parsedData);
        let concatArray = [];
        for (let i = 0; i < dataArray.length; i++) {
          const key = dataArray[i];
          if (parsedData[key].audience) {
            const audience = parsedData[key].audience;
            concatArray = concatArray.concat(audience);
          }
        }
        let unique = _.uniq(concatArray);
        if (unique.length !== concatArray.length) {
          setModalContentType('audienceRoles');
          return toggle();
        }

        // parse answerData to remove UIDs and return an array of objects
        const dataKeys = Object.keys(data);

        let parsedAnswerData = [];
        const answerDataObj = JSON.parse(data.answerData);
        const answerDataKeys = Object.keys(answerDataObj);

        let imageKeys = [];

        dataKeys.forEach(key => {
          if (key.includes('image')) {
            imageKeys.push(key);
          }
        });

        //if image is available then add image url to respective answer
        if (imageKeys.length > 0) {
          answerDataKeys.forEach(key => {
            for (let i = 0; i < imageKeys.length; i++) {
              if (imageKeys[i].includes(key)) {
                answerDataObj[key].image = data[imageKeys[i]];
              }
            }
          });
        }

        answerDataKeys.forEach(key => {
          if (answerDataObj[key].audience)
            parsedAnswerData.push(answerDataObj[key]);
        });

        postData.answerData = parsedAnswerData;

        delete postData.response;

        if (data.fallbackAnswerImage)
          postData.fallbackAnswerImage = data.fallbackAnswerImage;

        // console.log(postData);

        if (editInfo.empty) {
          postData.submittedBy = tokens.asurite;
          postData.submittedByKey = tokens.asurite + '-' + generateUID();
          postData.createdAt = new Date().getTime().toString();
          postData.createdAtKey =
            new Date().getTime().toString() + '-' + generateUID();
        }

        let mutateAlert;
        if (editInfo.id) {
          mutateAlert = await API.graphql(
            graphqlOperation(mutations.updateCriticalEscalation, {
              input: {
                id: editInfo.id,
                ...postData,
                lastEdit: new Date().getTime() + '',
              },
            })
          );

          // if escalation is changed, unmark old escalation as attached to this alert

          let JSONparsed = editInfo.escalation;

          try {
            JSONparsed = JSON.parse(data.escalation).value;
          } catch (e) {}

          if (editInfo.escalation && editInfo.escalation !== JSONparsed) {
            const getEscalation = await API.graphql(
              graphqlOperation(queries.getEscalation, {
                id: editInfo.escalation,
              })
            );

            const { attachedAlertIds } = getEscalation.data.getEscalation;
            const newEscalations = attachedAlertIds.filter(
              e => e !== editInfo.id
            );

            const mutateEscalation = await API.graphql(
              graphqlOperation(mutations.updateEscalation, {
                input: {
                  id: editInfo.escalation,
                  attachedAlertIds: newEscalations,
                },
              })
            );
          }
        } else {
          mutateAlert = await API.graphql(
            graphqlOperation(mutations.createCriticalEscalation, {
              input: { ...postData, lastEdit: new Date().getTime() + '' },
            })
          );
        }

        if (
          mutateAlert.data.createCriticalEscalation ||
          mutateAlert.data.updateCriticalEscalation
        ) {
          // mark escalation as attached to this alert
          // const getEscalation = await API.graphql(
          //   graphqlOperation(queries.getEscalation, {
          //     id: JSON.parse(data.escalation).value,
          //   })
          // );

          let newAlertId = editInfo.id
            ? editInfo.id
            : mutateAlert.data.createCriticalEscalation.id;

          // if (
          //   !getEscalation.data.getEscalation.attachedAlertIds ||
          //   !getEscalation.data.getEscalation.attachedAlertIds.includes(
          //     newAlertId
          //   )
          // ) {
          //   const mutateEscalation = await API.graphql(
          //     graphqlOperation(mutations.updateEscalation, {
          //       input: {
          //         id: JSON.parse(data.escalation).value,
          //         attachedAlertIds: getEscalation.data.getEscalation
          //           .attachedAlertIds
          //           ? [
          //               ...getEscalation.data.getEscalation.attachedAlertIds,
          //               newAlertId,
          //             ]
          //           : [newAlertId],
          //       },
          //     })
          //   );
          // }

          dispatch({
            type: 'setSpliceTableData',
            spliceTableData: {
              item: { timestamp: postData.timestamp },
              type: editInfo.id ? 'editFAQ' : 'addFAQ',
            },
          });
          setSuccess(true);
          history.push({ pathname: `/alerts` });
        }
      } catch (error) {
        console.log('confirmAlert error: ', error);
        setErrorMessage('Something went wrong with alert creation/update');
        setOpenError(true);
      } finally {
        dispatch({
          type: 'setLoadingFormSubmission',
          loadingFormSubmission: false,
        });
        dispatch({
          type: 'setCheckForErrors',
          checkForErrors: false,
        });
      }
    }
  };

  const onSubmitUnapproval = data => {
    if (data.type !== 'submit') {
      axios
        .put(
          process.env.REACT_APP_APIGATEWAY_CHATBOT + '/chatbot-unapproval-email',
          {
            asurite: tokens.asurite,
            submittedBy: editInfo.submittedBy,
            editedBy: editInfo.editedBy ? JSON.parse(editInfo.editedBy) : null,
            emailBody:
              'reason: ' +
              data.reasonForRejection +
              ' message: ' +
              data.message,
            details: "alert phrase '" + formValues['trigger phrase'] + "'",
          }
        )
        .then(res => {
          console.log('onSubmitUnapproval res: ', res);
        })
        .catch(e => {
          console.log('onSubmitUnapproval error: ', e);
        });
      onSubmit(formValues);
    }
  };

  const createOnSubmit = data => {
    dispatch({
      type: 'setCheckForErrors',
      checkForErrors: true,
    });
    if (data.type !== 'submit') {
      if (runningQuestion) {
        setErrorMessage(
          'Cannot submit while trigger phrase checker is running'
        );
        setOpenError(true);
      } else if (
        JSON.parse(data.status).value === 'Unapproved' &&
        !editInfo.empty &&
        editInfo.status !== 2
      ) {
        setFormValues(data);
        setModalContentType('unapproval');
        toggle();
      } else {
        onSubmit(data);
      }
    }
  };

  const getModalContent = () => {
    if (modalContentType === 'unapproval') {
      return (
        <ModalContent
          confirmation={null}
          form={true}
          formData={unapprovalFormData()}
          title="Unapproval reason"
          onSubmit={onSubmitUnapproval}
          toggle={toggle}
        />
      );
    } else if (modalContentType === 'audienceRoles') {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: 'fit-content',
            padding: '0.5rem',
            borderRadius: '4px',
          }}
        >
          <H6 style={{ margin: 0 }}>Error</H6>
          <p style={{ ...theme.typography.subtitle1 }}>
            {"Audience roles can't overlap across answers"}
          </p>
        </div>
      );
    } else {
      return null;
    }
  };

  const unapprovalFormData = () => {
    let emailList = [];
    emailList.push(tokens.asurite + '@asu.edu');
    emailList.push(editInfo.submittedBy + '@asu.edu');
    editInfo.editedBy &&
      JSON.parse(editInfo.editedBy).forEach(el => {
        emailList.push(el.asurite + '@asu.edu');
      });

    const uniqueEmailList = Array.from(new Set(emailList));

    return [
      {
        component: (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                fontWeight: 400,
                marginLeft: '0.25rem',
              }}
            >
              <div>Message will be emailed to: </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {uniqueEmailList.map((el, index) => {
                  return (
                    <div
                      style={{
                        marginLeft: '0.25rem',
                      }}
                    >
                      {el}
                      {index !== uniqueEmailList.length - 1 && ','}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ),
      },
      {
        title: 'REASON FOR REJECTION',
        required: true,
        component: <Input form name="reasonForRejection" required />,
      },
      {
        title: 'MESSAGE',
        required: true,
        component: (
          <Textarea
            form
            required
            name="message"
            // stateValue={editInfo.emailBody ? editInfo.emailBody : ""}
          />
        ),
      },
    ];
  };

  const onClickCreateNew = () => {
    dispatch({ type: 'updateEditInfo', editInfo: { empty: true } });
    dispatch({
      type: 'changeTableSort',
      tableSort: null,
    });
    dispatch({ type: 'changeTableFilter', tableFilter: 'All' });
    setRerenderKey(Date.now());
  };

  const getStatusKey = name => {
    switch (name) {
      case 'Pending':
        return 1;
      case 'Unapproved':
        return 2;
      case 'Approved':
        return 3;
      case 'Published':
        return 4;
      default:
        return null;
    }
  };

  const getStartingStatus = () => {
    return 'Pending';
    // if (tokens.role === "content creator") {
    //   return "Pending";
    // } else if (
    //   tokens.role === "approver" &&
    //   editInfo &&
    //   editInfo.status === 4
    // ) {
    //   return "Approved";
    // } else if (editInfo.status) {
    //   switch (editInfo.status) {
    //     case 1:
    //       return "Pending";
    //     case 2:
    //       return "Unapproved";
    //     case 3:
    //       return "Approved";
    //     case 4:
    //       return "Published";
    //     default:
    //       return null;
    //   }
    // } else {
    //   return null;
    // }
  };

  const runQuestion = async e => {
    let value = e.target.value;
    if (value.length > 0) {
      setRunningQuestion(true);
      setMainQuestionStatusMsg('Checking for availability');

      let resp = await utility.runQuestion(
        value,
        editInfo.id,
        'Trigger phrase'
      );
      setMainQuestionStatus(resp.status);
      setMainQuestionStatusMsg(resp.msg);

      setTimeout(() => {
        setRunningQuestion(false);
        setMainQuestionStatusMsg('');
        setMainQuestionStatus('');
      }, 2000);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenError(false);
    setSuccess(false);
  };

  const getEscalationName = () => {
    for (var i = 0; i < escalationData.length; ++i) {
      if (escalationData[i].value === editInfo.escalation) {
        return escalationData[i];
      }
    }
    return editInfo.escalation;
  };

  const createStatusOptions = () => {
    let statusOptions = [{ value: 'Pending', label: 'Pending' }];

    if (roleCheck(tokens.role, 'alerts', 'approveDenyContent')) {
      if (editInfo.id) {
        statusOptions.push({ value: 'Unapproved', label: 'Unapproved' });
      }
      statusOptions.push({ value: 'Approved', label: 'Approved' });
    }
    if (roleCheck(tokens.role, 'alerts', 'publish')) {
      statusOptions.push({ value: 'Published', label: 'Published' });
    }

    return statusOptions;
  };

  const formData = [
    // {
    //   tooltip: "Select an Escalation",
    //   title: "ESCALATION",
    //   required: true,
    //   component: (
    //     <Select
    //       form
    //       //reset={true}
    //       //setReset={true}
    //       required
    //       name="escalation"
    //       placeholder="-- Select an Escalation --"
    //       options={escalationData}
    //       updateStateValue={
    //         editInfo.escalation && editInfo.escalation != "null"
    //           ? getEscalationName()
    //           : null
    //       }
    //     />
    //   ),
    // },
    {
      tooltip: 'Write a Trigger Phrase',
      title: 'TRIGGER PHRASE',
      required: true,
      component: (
        <Input
          form
          stateValue={editInfo.triggerPhrase ? editInfo.triggerPhrase : ''}
          name="trigger phrase"
          id="question"
          charLimit="120"
          showRunningStatus={runningQuestion}
          validStatusMsg={mainQuestionStatusMsg}
          validStatus={mainQuestionStatus}
          required
        />
      ),
    },
    {
      tooltip: 'Add Variations',
      title: 'VARIATIONS',
      component: (
        <Variations
          // required
          kbVariation
          name="variations"
          charLimit="120"
          editId={editInfo ? editInfo.id : null}
          stateValue={editInfo.variations ? editInfo.variations : null}
        />
      ),
    },
    {
      tooltip: 'Write an Answer',
      title: 'ANSWER',
      subtext:
        'Audience members with more than one affiliation and phone numbers associated with more than one contact will receive the default response specified below.',
      required: true,
      component: (
        <Textarea
          form
          required
          name="fallbackAnswer"
          stateValue={editInfo.fallbackAnswer ? editInfo.fallbackAnswer : null}
          imageName="fallbackAnswerImage"
          image={
            editInfo.fallbackAnswerImage ? editInfo.fallbackAnswerImage : null
          }
          charLimit={160}
          allowextras="image,emoji,imgGallery"
          isSMS
          style={{ display: 'flex', width: '100%' }}
        />
      ),
    },
    {
      tooltip: 'Select an Option',
      component: (
        <ConditionInput
          answer
          required
          name="answer"
          stateValue={editInfo.answerData ? editInfo.answerData : null}
          escalationData={escalationData}
        />
      ),
    },
    // {
    //   title: "RESPONSE TO USER",
    //   required: true,
    //   component: (
    //     <Textarea
    //       form
    //       required
    //       name="response"
    //       stateValue={editInfo.response ? editInfo.response : ""}
    //       allowextras="emoji,var"
    //     />
    //   ),
    // },
    {
      tooltip: 'Select a Status',
      title: 'STATUS',
      required: true,
      component: (
        <Select
          form
          stateValue={getStartingStatus()}
          name="status"
          required
          options={createStatusOptions()}
        />
      ),
    },
    // {
    //   title: "",
    //   component: (
    //     <PauseSlider
    //       checked={escalateSms}
    //       title="CREATE SALESFORCE CASE FOR SMS"
    //       onChange={() => setEscalateSms((prev) => !escalateSms)}
    //     />
    //   ),
    // },
  ];

  if (!attachedEvent) {
    setAttachedEvent(true);
    setTimeout(() => {
      let item = document.getElementById('question');
      if (item) {
        item.addEventListener('focusout', runQuestion);
      }
    }, 250);
  }

  return (
    <div style={theme.components.pageContainer} key={rerenderKey}>
      <SectionHeader
        title="ALERTS"
        noSort
        noModality
        noFilter
        noSearch
        rightView={
          roleCheck(tokens.role, 'alerts', 'create') && (
            <div onClick={onClickCreateNew}>
              <CreateNewButton to="/alert-add" label="Create New Alert" />
            </div>
          )
        }
      />
      <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={openSuccess} autoHideDuration={6000} nClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Alert details saved successfully!
        </Alert>
      </Snackbar>

      <Paper style={theme.components.paper}>
        <div style={{ padding: '2rem', width: '50%' }}>
          {editInfo.processingError ? (
            <ErrorLabel message={editInfo.processingError} />
          ) : null}
          <Form
            title={editInfo.id ? 'EDIT ALERT' : 'ADD ALERT'}
            data={formData}
            onSubmit={createOnSubmit}
            style={{ width: '50%' }}
          />
        </div>
        <Modal
          isShowing={isShowing}
          hide={toggle}
          confirmation
          content={getModalContent()}
        />
      </Paper>
    </div>
  );
}
