import React, { useState, useEffect, useContext } from 'react';
import AuthContext from 'contexts/AuthContext';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from 'graphql/queries';
import moment from 'moment-timezone';
import { PanelButton, MiniTable, ThreeSquare } from 'stories';
import { convertToProperCSV } from 'stories/PanelButton/csv-manipulation';

//import "../CampaignTabs.scss";

export default function CampaignAnalyticsFailures(props) {
  const [panelSectionData, setPanelSectionData] = React.useState(null);
  const [
    searchingForDisplayNames,
    setSearchingForDisplayNames,
  ] = React.useState(false);

  const tokens = useContext(AuthContext);

  function getSendLevelErrors() {
    let allInfo = [];
    let nextToken = null;
    return new Promise(async function tryagain(resolve, reject) {
      // resolve([]);
      let params = {
        campaignId: props.id,
        filter: {
          or: [
            { exitType: { eq: 'error' } },
            { expiredBeforeSend: { eq: true } },
          ],
        },
      };

      if (nextToken) {
        params.nextToken = nextToken;
      }

      let result = await API.graphql(
        graphqlOperation(queries.compCampById, params)
      );

      allInfo = allInfo.concat(result.data.compCampById.items);
      nextToken = result.data.compCampById.nextToken;

      if (nextToken) {
        tryagain(resolve, reject);
      } else {
        for (var i = 0; i < allInfo.length; ++i) {
          if (allInfo[i].expiredBeforeSend)
            allInfo[i].error = 'Expired before send';
        }

        resolve(allInfo);
      }
    });
  }

  const getTwilioLevelErrors = () => {
    return new Promise(async function(resolve, reject) {
      let resp = await API.graphql(
        graphqlOperation(queries.searchMessages, {
          operation: 'getTwilioLevelErrors',
          payload: JSON.stringify({
            campaignId: props.id,
          }),
        })
      );

      resolve(JSON.parse(resp.data.searchMessages));
    });

    // console.log("twilio erros: ", resp);
  };

  const getFailuresNew = async () => {
    let [sendLevelErrors, twilioLevelErrors] = await Promise.all([
      getSendLevelErrors(),
      getTwilioLevelErrors(),
    ]);

    // let allErrors = sendLevelErrors.concat(twilioLevelErrors);

    let errorsToGet = {};
    let usersToGet = {};

    for (var i = 0; i < sendLevelErrors.length; ++i) {
      let currErr = sendLevelErrors[i].error;
      errorsToGet[currErr] = null;
      usersToGet[sendLevelErrors[i].userId] = null;
    }

    for (var i = 0; i < twilioLevelErrors.length; ++i) {
      let currErr = twilioLevelErrors[i].tags.filter(string => {
        if (string.includes('error')) {
          return true;
        }
      });

      if (currErr[0]) {
        currErr = currErr[0].replace('error ', '');
        errorsToGet[currErr] = null;
      }
    }

    for (var key in errorsToGet) {
      errorsToGet[key] = await getErrorMessage(key);
    }

    let pData = [];

    let allErrors = sendLevelErrors.concat(twilioLevelErrors);

    let mapOfConvoIds = {};

    if (twilioLevelErrors.length > 0) {
      //create map
      props.importSummaryData.newNums.forEach(object => {
        if (object.convoId) {
          mapOfConvoIds[object.convoId] = object.user;
        }
      });

      //handle found number failures
      for (let i2 = 0; i2 < twilioLevelErrors.length; i2++) {
        if (twilioLevelErrors[i2].convoId) {
          let c_result = await API.graphql(
            graphqlOperation(queries.chatbotUserByConvoId, {
              convoId: twilioLevelErrors[i2].convoId,
            })
          );

          c_result = c_result.data.chatbotUserByConvoId.items;

          if (c_result.length > 0) {
            mapOfConvoIds[twilioLevelErrors[i2].convoId] =
              c_result[0].phoneNumber;
          }
        }
      }
    }

    for (var i = 0; i < allErrors.length; ++i) {
      let phone;

      if (allErrors[i].userId) {
        phone = allErrors[i].userId;
      } else if (allErrors[i].phoneNumber) {
        phone = allErrors[i].phoneNumber;
      } else {
        //convert twilioLevelError data format using props.importSummaryData.newNums
        phone = mapOfConvoIds[allErrors[i].convoId];
      }

      let date = allErrors[i].startTime
        ? allErrors[i].startTime
        : allErrors[i].latest;

      let errorCode;

      if (allErrors[i].error) {
        errorCode = errorsToGet[allErrors[i].error];
      } else if (allErrors[i].tags) {
        errorCode = allErrors[i].tags.filter(string => {
          if (string.includes('error')) {
            return true;
          }
        });

        if (errorCode[0]) {
          errorCode = errorsToGet[errorCode[0].replace('error ', '')];
        } else {
          errorCode = null;
        }
      }

      let displayName = allErrors[i].displayName
        ? allErrors[i].displayName
        : 'N/A';

      let userObj = {
        phone: phone,
        date:
          parseInt(date).toString().length < 11
            ? moment(date, 'X').format('MM/DD/YYYY')
            : moment(date, 'x').format('MM/DD/YYYY'),
        errorCode: errorCode,
      };
      pData.push(userObj);

      //remove duplicates
      pData = pData.filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            element =>
              element.phone === value.phone &&
              element.date === value.date &&
              element.errorCode === value.errorCode
          )
      );
    }

    setPanelSectionData(pData);

    //update tabDataCache
    let newTabDataCache = Object.assign({}, props.tabDataCache);
    newTabDataCache[props.panelTitle] = pData;
    props.setTabDataCache(newTabDataCache);

    // setPanelSectionData(newPanelSectionData);
    //
    // //update tabDataCache
    // let newTabDataCache = Object.assign({}, props.tabDataCache);
    // newTabDataCache[props.panelTitle] = newPanelSectionData;
    // props.setTabDataCache(newTabDataCache);

    setSearchingForDisplayNames('done');
  };

  async function getFailures() {
    // getCampaignInfo();
    let result;
    const errorCodesToGet = [];

    // try {
    //   result = await API.graphql(
    //     graphqlOperation(queries.compCampById, {
    //       campaignId: props.id,
    //     })
    //   );
    //
    //   result = result.data.compCampById.items.filter((object) => {
    //     if (object.exitType === "error" || object.expiredBeforeSend) {
    //       if (object.expiredBeforeSend) object.error = "Expired before send";
    //       return true;
    //     }
    //   });
    // } catch (e) {
    //   console.log("graphql: The query compCampById failed with errors.");
    //   console.error(e);
    // }

    console.log('campaign id: ', props.id);
    /// message failures

    let result2 = [];
    let messageIdsToGet = [];
    //
    // try {
    //   result2 = await API.graphql(
    //     graphqlOperation(queries.messageByTag, {
    //       tag: "campaignId " + props.id,
    //     })
    //   );
    //
    //   result2 = result2.data.messageByTag.items;
    //
    //   result2.forEach((object) => {
    //     if (
    //       typeof object.messageId !== "undefined" &&
    //       object.messageId !== null
    //     ) {
    //       messageIdsToGet.push(object.messageId);
    //     }
    //   });
    // } catch (e) {
    //   console.log("graphql: The query messageByTag failed with errors.");
    //   console.error(e);
    // }

    /// individual error codes

    let results_3 = [];

    try {
      for (let res3_i = 0; res3_i < messageIdsToGet.length; res3_i++) {
        let tempResult3 = await API.graphql(
          graphqlOperation(queries.tagByMessageId, {
            messageId: messageIdsToGet[res3_i],
          })
        );

        tempResult3.data.tagByMessageId.items.forEach(object => {
          if (typeof object.tag !== 'undefined' && object.tag !== null) {
            if (object.tag.includes('error')) {
              results_3.push(object);
            }
          }
        });
      }
    } catch (e) {
      console.log('graphql: The query tagByMessageId failed with errors.');
      console.error(e);
    }

    /// message metadata

    let result4 = [];

    if (results_3.length > 0) {
      try {
        for (let res4_i = 0; res4_i < results_3.length; res4_i++) {
          let tempResult4 = await API.graphql(
            graphqlOperation(queries.chatbotUserByConvoId, {
              convoId: results_3[res4_i].convoId,
            })
          );

          result4 = tempResult4.data.chatbotUserByConvoId.items;
        }
      } catch (e) {
        console.log(
          'graphql: The query chatbotUserByConvoId failed with errors.'
        );
        console.error(e);
      }

      if (result4.length > 0) {
        result4 = result4.map((object, index) => {
          let newObject = {};

          let phone_number = object.phoneNumber.replace('+1', '');
          phone_number = `${phone_number.slice(0, 3)}-${phone_number.slice(
            3,
            6
          )}-${phone_number.slice(6)}`;

          let startTimeFromMessage = results_3[index].createdAt;

          let errorCode = results_3[index].tag.replace('error ', '');

          errorCodesToGet.push(errorCode);

          let returnObject = {
            phone: phone_number,
            date:
              parseInt(startTimeFromMessage).toString().length < 11
                ? moment(startTimeFromMessage).format('MM/DD/YYYY')
                : moment(startTimeFromMessage).format('MM/DD/YYYY'),
            errorCode: errorCode,
          };

          return returnObject;
        });
      }
    }

    let failuresArray = result.map(object => {
      let phone_number = object.userId.replace('+1', '');
      phone_number = `${phone_number.slice(0, 3)}-${phone_number.slice(
        3,
        6
      )}-${phone_number.slice(6)}`;

      errorCodesToGet.push(object.error);

      let returnObject = {
        phone: phone_number,
        date:
          parseInt(object.startTime).toString().length < 11
            ? moment(object.startTime, 'X').format('MM/DD/YYYY')
            : moment(object.startTime, 'x').format('MM/DD/YYYY'),
        errorCode: object.error,
      };

      if (typeof object.displayName !== 'undefined') {
        returnObject.displayName = object.displayName;
      }

      return returnObject;
    });

    if (result4.length > 0) {
      failuresArray = failuresArray.concat(result4);
    }

    if (errorCodesToGet.length > 0) {
      //get error messages
      // event__getErrorMessages(failuresArray);
    } else {
      setPanelSectionData(failuresArray);

      //update tabDataCache
      let newTabDataCache = Object.assign({}, props.tabDataCache);
      newTabDataCache[props.panelTitle] = failuresArray;
      props.setTabDataCache(newTabDataCache);
    }
  }

  React.useEffect(() => {
    if (panelSectionData === null) {
      if (
        typeof props.tabDataCache[props.panelTitle] === 'undefined' ||
        props.tabDataCache[props.panelTitle] === null
      ) {
        getFailuresNew();
        // getFailures();
      } else {
        setPanelSectionData(props.tabDataCache[props.panelTitle]);
        setSearchingForDisplayNames('done');
      }
    }
  }, [panelSectionData]);

  const getErrorMessage = async errorCode => {
    const errorMessageFromDynamo = await API.graphql(
      graphqlOperation(queries.getChatbotErrorMessages, {
        errorCode: errorCode,
      })
    );
    let msg = errorCode + '';

    if (errorMessageFromDynamo.data.getChatbotErrorMessages !== null) {
      //update panelSectionData array
      msg +=
        ' ' + errorMessageFromDynamo.data.getChatbotErrorMessages.errorMessage;
    }

    return msg;
  };

  // const event__getErrorMessages = async (newDataArray) => {
  //   /* get error message text from numeric error code*/
  //
  //   console.log("NEW DATA: ", newDataArray);
  //
  //   for (let fa_index = 0; fa_index < newDataArray.length; fa_index++) {
  //     const errorMessageFromDynamo = await API.graphql(
  //       graphqlOperation(queries.getChatbotErrorMessages, {
  //         errorCode: newDataArray[fa_index].errorCode,
  //       })
  //     );
  //
  //     if (errorMessageFromDynamo.data.getChatbotErrorMessages !== null) {
  //       //update panelSectionData array
  //       newDataArray[fa_index].errorCode +=
  //         " " +
  //         errorMessageFromDynamo.data.getChatbotErrorMessages.errorMessage;
  //     }
  //   }
  //
  //   setPanelSectionData(newDataArray);
  //
  //   //update tabDataCache
  //   let newTabDataCache = Object.assign({}, props.tabDataCache);
  //   newTabDataCache[props.panelTitle] = newDataArray;
  //   props.setTabDataCache(newTabDataCache);
  // };

  const searchForDisplayNames = async usersToFind => {
    if (searchingForDisplayNames === true) {
      return;
    }

    setSearchingForDisplayNames(true);

    let isFinal = false;

    let foundDisplayNames = {};
    let foundCrmIds = {};

    for (let i_found = 0; i_found < usersToFind.length; i_found++) {
      let pay = {};
      if (!isNaN(usersToFind[i_found])) {
        pay.phoneNumber = usersToFind[i_found];
      } else {
        pay.asurite = usersToFind[i_found];
      }
      pay.botType = 'sunny';

      let resp = await API.graphql(
        graphqlOperation(queries.searchMessages, {
          operation: 'queryForUsers',
          payload: JSON.stringify(pay),
        })
      );

      resp = JSON.parse(resp.data.searchMessages);

      //console.log("!!!", resp, searchingForDisplayNames);

      try {
        foundDisplayNames[usersToFind[i_found]] = resp[0].displayName.includes(
          'undefined'
        )
          ? 'N/A'
          : resp[0].displayName;
      } catch (e) {
        foundDisplayNames[usersToFind[i_found]] = 'N/A';
      }
    }

    let newPanelSectionData = [...panelSectionData];
    newPanelSectionData.map(object => {
      if (typeof foundDisplayNames[object.phone] !== 'undefined') {
        object.displayName = foundDisplayNames[object.phone];
      } else {
        object.displayName = 'N/A';
      }
      if (typeof foundCrmIds[object.phone] !== 'undefined') {
        object.crmId = foundCrmIds[object.phone];
      }

      return object;
    });

    setPanelSectionData(newPanelSectionData);

    //update tabDataCache
    let newTabDataCache = Object.assign({}, props.tabDataCache);
    newTabDataCache[props.panelTitle] = newPanelSectionData;
    props.setTabDataCache(newTabDataCache);

    setSearchingForDisplayNames('done');
  };

  const lib__buildCSVData = panelSectionData => {
    let usersToFind = [];
    let csvData = [];

    csvData = [['phone', 'date', 'Error Code', 'displayName']];

    panelSectionData.forEach(object => {
      if (typeof object['displayName'] !== 'undefined') {
        csvData[csvData.length - 1].push([
          object.phone,
          object.date,
          object.errorCode,
          object.displayName,
        ]);
      } else {
        csvData.push([object.phone, object.date, object.errorCode]);
        usersToFind.push(object.phone);
      }
    });

    if (usersToFind.length > 0 && searchingForDisplayNames === 'done') {
      //console.log("!!! Searching for display names", searchingForDisplayNames);
      searchForDisplayNames(usersToFind);
    }

    return csvData;
  };

  const event__refreshData = () => {
    //update tabDataCache
    let newTabDataCache = Object.assign({}, props.tabDataCache);
    newTabDataCache[props.panelTitle] = null;
    props.setTabDataCache(newTabDataCache);

    setPanelSectionData(null);
  };

  return (
    <>
      <div className="panelSection__title">
        {props.panelTitle}
        {panelSectionData === null ? (
          typeof props.tabDataCache[props.panelTitle] === 'undefined' ||
          props.tabDataCache[props.panelTitle] === null ? (
            <ThreeSquare />
          ) : null
        ) : (
          <>
            <div style={{ position: 'absolute', top: '0px', right: '9.5em' }}>
              <PanelButton
                type="refresh"
                label="Refresh"
                onClick={event__refreshData}
              />
            </div>
            <div
              style={{
                position: 'absolute',
                top: '0px',
                right: '1.2em',
              }}
            >
              <PanelButton
                type="csv"
                csvData={convertToProperCSV(
                  lib__buildCSVData(panelSectionData)
                )}
                csvFileName={`${
                  props.editInfo && props.editInfo.title
                    ? props.editInfo.title
                    : 'chatbot'
                }-campaignFailures-${moment().format()}.csv`}
                disabled={
                  !panelSectionData ||
                  panelSectionData.length === 0 ||
                  searchingForDisplayNames !== 'done'
                }
                label="Export Data"
                //disabled={searchingForDisplayNames !== "done" ? true : false}
              />
            </div>
            <div className="panelHeader">
              Campaign failures: {panelSectionData.length}
            </div>
            <MiniTable
              tableData={panelSectionData}
              columnTitles={['Phone', 'Date', 'Error Code']}
              dataKeys={['phone', 'date', 'errorCode']}
            />
          </>
        )}
      </div>
    </>
  );
}
