import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import { useTheme } from "@material-ui/core/styles";
import { API, graphqlOperation } from "aws-amplify";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { Redirect } from "react-router-dom";

import { SectionHeader } from "components/SectionHeader";
import { listStatuss } from "graphql/queries";
import { createStatus, updateStatus } from "graphql/mutations";
import AuthContext from "contexts/AuthContext";
import { store } from "contexts/GlobalStore";
import { CreateNewButton, Table, Button, ModalContent, Modal } from "stories";
import { roleCheck } from "services/utility/RoleMatrix";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

// import { devs } from "main/listItems";

import * as utility from "./MaintenanceForm.js";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AdminMaintenance(props) {
  const tokens = useContext(AuthContext);
  const theme = useTheme();
  const GlobalState = useContext(store);

  const dispatchGlobal = GlobalState.dispatch;

  const [editClicked, setEditClicked] = useState(false);
  const [data, setData] = useState([]);
  const [rawUserData, setRawUserData] = useState([]);
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [active, setActive] = useState(false);

  const {
    tableSort,
    spliceTableData,
    editInfo,
    formTitle,
    clearNextTokens,
    tableDataEmpty,
  } = GlobalState.state;

  useEffect(() => {
    dispatchGlobal({
      type: "setTableDataEmpty",
      tableDataEmpty: false,
    });
  }, []);

  useEffect(() => {
    // dispatch({ type: "updateEditInfo", editInfo: { empty: true } });
    fetchUsers();
  }, [props.type]);

  const fetchUsers = async () => {
    const result = await API.graphql(
      graphqlOperation(listStatuss, {
        sortDirection: "DESC",
        id: props.type,
        startTime: { ge: "1000" },
        limit: 20,
      })
    );

    console.log("STATUS", result, props.type);

    const rawUserData = result.data.listStatuss.items;
    const parsedData = parseData(rawUserData);

    for (var i = 0; i < rawUserData.length; ++i) {
      if (rawUserData[i].active) {
        setActive(true);
        break;
      }
    }

    setData(parsedData);
    setRawUserData(rawUserData);
  };

  const getTitle = () => {
    if (props.type === "status") {
      return "Status";
    } else {
      return "Maintenance";
    }
  };

  const parseData = (data) => {
    return data.map((el) => {
      return {
        name: el.title,
        description: el.message,
        createdBy: el.submittedBy.slice(el.submittedBy.indexOf("-") + 1),
        startTime: el.startTime,
        endTime: el.endTime,
        actions: {
          resolve: el.active === true,
        },
      };
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const onClickCreateNew = () => {
    dispatchGlobal({
      type: "updateEditInfo",
      editInfo: { empty: true },
    });
    dispatchGlobal({
      type: "changeTableSort",
      tableSort: null,
    });
    dispatchGlobal({
      type: "setFormTitle",
      formTitle: "START " + props.type.toUpperCase(),
    });
    toggle();
  };

  const onClickResolve = async (elem) => {
    let payload = {
      id: props.type,
      submittedBy: elem.startTime + "-" + elem.createdBy,
      active: false,
      endTime: new Date().getTime() + "",
    };

    await API.graphql(graphqlOperation(updateStatus, { input: payload }));
  };

  const toggle = () => {
    setShowModal(!showModal);
    if (!editInfo.empty) {
      dispatchGlobal({
        type: "updateEditInfo",
        editInfo: { empty: true },
      });
    }
  };

  const rightViewContent = () => (
    <div style={{ display: "flex", width: "100%" }}>
      <Button
        style={{ display: "flex", flex: 1, width: "100%" }}
        onClick={onClickCreateNew}
      >
        <AddCircleOutlineIcon style={{ color: theme.palette.general.maroon }} />
        <span
          style={{
            marginLeft: "0.5rem",
          }}
        >
          {"Start " + getTitle()}
        </span>
      </Button>
    </div>
  );

  const tableLabels = [
    { title: "NAME", notClickable: true, wide: true },
    { title: "SUBMITTED BY", notClickable: true },
    { title: "START TIME", notClickable: true },
    { title: "END TIME", notClickable: true },
    { title: "ACTIONS", alignRight: true, notClickable: true },
  ];

  const modalContent = () => {
    let formData = utility.getFormData(props.type);

    return (
      <ModalContent
        form
        title={formTitle}
        formData={formData}
        onSubmit={onSubmit}
        toggle={toggle}
      />
    );
  };

  const onSubmit = async (data, e) => {
    if (data.type !== "submit") {
      toggle();

      let payload = {
        id: props.type,
        message: data.message,
        title: data.title,
        severity: data.severity ? JSON.parse(data.severity).value : "warning",
        active: true,
        startTime: new Date().getTime(),
        submittedBy: new Date().getTime() + "-" + tokens.asurite,
      };

      await API.graphql(graphqlOperation(createStatus, { input: payload }));
    }
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  if (!tokens.devAccess) {
    return <Redirect to="/" />;
  } else {
    return (
      <div style={theme.components.pageContainer}>
        <SectionHeader
          title={getTitle()}
          rightView={active ? null : rightViewContent()}
        />
        <Modal
          isShowing={showModal}
          hide={toggleModal}
          content={modalContent()}
        />
        <Paper style={theme.components.paper}>
          <div
            style={{
              width: "100%",
              borderLeft: `1px solid ${theme.palette.general.mediumGrey}`,
              paddingBottom: "2rem",
              ...props.style,
            }}
          >
            <Table
              title={getTitle() + " Table"}
              labels={tableLabels}
              data={data}
              onClickResolve={onClickResolve}
              noSort
              noFilter
              noSearch
            />
          </div>
        </Paper>
      </div>
    );
  }
}

AdminMaintenance.propTypes = {
  navigation: PropTypes.object,
};
