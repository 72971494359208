import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { scroller as scroll, Events } from 'react-scroll';
import { MiniTable, Modal, PanelButton, Accordion, Button } from 'stories';
import { useModal } from 'hooks';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import CircularProgress from '@material-ui/core/CircularProgress';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckIcon from '@material-ui/icons/Check';
import { store as GlobalStore } from 'contexts/GlobalStore';
import Tooltip from '@material-ui/core/Tooltip';

import '../../Dialogues.scss';

import * as tagHelper from '../../Helpers/tagHelper.js';

import TableHelper from './TableHelper.js';

import {
  queryWithAthena,
  escHistoryByConvoId,
  compCampByUserId,
} from 'graphql/queries';

import { API, graphqlOperation } from 'aws-amplify';

export default function ImportRecord(props) {
  const GlobalState = useContext(GlobalStore);
  const dispatchGlobal = GlobalState.dispatch;

  const [importHistory, setImportHistory] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [prevConvoId, setPrevConvoId] = useState(null);

  useEffect(() => {
    if (props.convo.convoId !== prevConvoId) {
      setPrevConvoId(props.convo.convoId);
    }
  }, [props.convo]);

  useEffect(() => {
    setIsLoading(true);
    setImportHistory([]);
    queryForImportHistory();
  }, [prevConvoId]);

  let keys = [];

  let csvKeys = {
    user: 'User',
    type: 'Type',
    newuser: 'New User',
    isvalid: 'Is Valid',
    campaignId: 'Campaign ID',
    originalvalue: 'Original Valud',
    date: 'Timestamp',
  };
  let tableKeys = {
    date: 'Send Date',
    valid: 'Valid',
    campaign: 'Campaign',
  };

  const forwardToCamp = c => {
    dispatchGlobal({ type: 'setFormTitle', formTitle: 'VIEW CAMPAIGN' });
    let campaignToEdit = c;

    campaignToEdit.flow = JSON.parse(campaignToEdit.flow);
    dispatchGlobal({
      type: 'updateEditInfo',
      editInfo: { ...campaignToEdit },
      viewOnly: c.status > 4 ? true : false,
    });

    props.history.push({
      pathname: 'campaign-form',
    });
  };

  const queryForImportHistory = async () => {
    let userId = null;

    if (props.convo.type === 'sms') {
      userId = props.convo.phoneNumber;
    } else {
      userId = props.convo.asurite;
    }

    if (userId) {
      let params = {
        queryName: 'historicalUpload',
        params: JSON.stringify({
          userId: userId,
        }),
      };

      let queryRes = await API.graphql(
        graphqlOperation(queryWithAthena, params)
      );
      let res = JSON.parse(queryRes.data.queryWithAthena).items;

      let altered = [];

      let validIcon = <CheckIcon />;

      let invalidIcon = <HighlightOffIcon style={{ color: 'red' }} />;

      const renderCamp = camp => {
        return (
          <div className="simpleEllipsis" onClick={() => forwardToCamp(camp)}>
            {camp.title}
          </div>
        );
      };

      for (var i = 0; i < res.length; ++i) {
        res[i].date = props.dateFormatter(
          parseInt(
            res[i].campaignName
              ? res[i].campaignName.startTime
              : res[i].timestamp
          ),
          false
        );

        res[i].valid = res[i].isvalid ? validIcon : invalidIcon;
        res[i].campaign = res[i].campaignName
          ? renderCamp(res[i].campaignName)
          : '';
      }

      res.sort((a, b) => parseInt(b.timestamp) - parseInt(a.timestamp));

      setImportHistory(res);
    }
    setIsLoading(false);
  };

  return (
    <TableHelper
      rawData={importHistory}
      title={'Import History'}
      tableKeys={tableKeys}
      csvKeys={csvKeys}
      convo={props.convo}
      dateFormatter={props.dateFormatter}
      loading={loading}
    />
  );
}
