import { API, graphqlOperation } from 'aws-amplify';
import _ from 'lodash';
import {
  handleDialogues,
  tagByMessageId,
  convoTagByConvoId,
} from 'graphql/queries';
import {
  createChatbotMessageTag,
  deleteChatbotMessageTag,
  createDialogueNotes,
  updateChatbotMessageTag,
  createChatbotConvoTag,
  updateChatbotConvoTag,
} from 'graphql/mutations';

function getAsurite() {
  const tokens = JSON.parse(localStorage.getItem('usertokens'));
  return tokens.asurite;
}

function getBotType() {
  const tokens = JSON.parse(localStorage.getItem('usertokens'));
  return 'sunny';
}

export const updateTags = (items, arr, key) => {
  let aClone = _.clone(arr);
  for (var i = 0; i < aClone.length; ++i) {
    let a = aClone[i];
    let id = a[key];

    if (items[id]) {
      let addTag = items[id].operation === 'add';
      let tag = items[id].tag;
      if (!a.tags) {
        a.tags = [];
      }
      if (addTag) {
        a.tags.push(tag);
      } else {
        a.tags.splice(a.tags.indexOf(tag), 1);
      }
    }
  }
  return aClone;
};

export const updateTagsNew = (items, arr, key) => {
  let aClone = _.clone(arr);

  let needsReview = { add: [], remove: [] };
  let previouslyAssisted = { add: [], remove: [] };

  let keyedConvos = {};

  for (var i = 0; i < aClone.length; ++i) {
    let a = aClone[i];
    let id = a[key];

    if (items[id]) {
      let addTag = items[id].operation === 'add';
      let tag = items[id].tag;
      if (!a.tags) {
        a.tags = [];
      }
      if (addTag) {
        a.tags.push(tag);
        if (tag.indexOf('needsReview ') > -1) {
          needsReview.add.push(a);
        } else if (tag.indexOf('previouslyAssisted ') > -1) {
          previouslyAssisted.add.push(a);
        }
      } else {
        a.tags.splice(a.tags.indexOf(tag), 1);
        if (
          tag.indexOf('needsReview ') > -1 &&
          needsReview.remove.indexOf(id) === -1
        ) {
          needsReview.remove.push(id);
        } else if (
          tag.indexOf('previouslyAssisted ') > -1 &&
          previouslyAssisted.remove.indexOf(id) === -1
        ) {
          previouslyAssisted.remove.push(id);
        }
      }
    }
  }

  return {
    arr: aClone,
    needsReview: needsReview,
    previouslyAssisted: previouslyAssisted,
  };
};

const searchForTags = (cId, tag, deleted, nextToken = null, items = []) => {
  return new Promise(async function tryagain(resolve, reject) {
    let payload = {
      convoId: cId,
      filter: {
        tag: tag.includes('needsReview')
          ? {
              beginsWith: tag.split(' ')[0],
            }
          : { eq: tag },
        deleted: { eq: deleted ? true : false },
      },
    };

    if (nextToken) {
      payload.nextToken = nextToken;
    }

    let resp = await API.graphql(graphqlOperation(convoTagByConvoId, payload));

    let item = resp.data.convoTagByConvoId;
    let returnItem = item.items[0];

    if (returnItem && returnItem.id) {
      resolve(returnItem);
    } else if (item.items.length === 0 && item.nextToken) {
      nextToken = item.nextToken;
      tryagain(resolve, reject);
    } else {
      resolve(null);
    }
  });
};

export const removeConvoTag = async (cId, tag, deleted) => {
  let itemToDelete = await searchForTags(cId, tag, deleted);

  try {
    let toDeleteId = itemToDelete.id;
    let delResp = await API.graphql(
      graphqlOperation(updateChatbotConvoTag, {
        input: {
          id: toDeleteId,
          deleted: true,
          deletedBy: getAsurite(),
        },
      })
    );
  } catch (e) {
    console.log('!!! could not remove convo tag', e);
  }
};

export const removeAllConvoTag = async (tag, convoId) => {
  let resp = await API.graphql(
    graphqlOperation(convoTagByConvoId, {
      convoId: convoId,
      limit: 9999999,
      filter: {
        tag: {
          beginsWith: tag,
        },
        deleted: {
          eq: false,
        },
      },
    })
  );

  try {
    let toDelete = resp.data.convoTagByConvoId.items;
    for (var i = 0; i < toDelete.length; ++i) {
      let delResp = await API.graphql(
        graphqlOperation(updateChatbotConvoTag, {
          input: {
            id: toDelete[i].id,
            deleted: true,
            deletedBy: getAsurite(),
          },
        })
      );
    }
  } catch (e) {
    console.log('COULD NOT DELETE: ', e);
  }
  return;
};

export const removeMessageTag = async (mId, tag) => {
  // console.log("!!! removeMessageTag passed tag is mId, tag", mId, tag);
  let resp = await API.graphql(
    graphqlOperation(tagByMessageId, {
      messageId: mId,
      filter: {
        tag: tag.includes('needsReview')
          ? {
              beginsWith: tag.split(' ')[0],
            }
          : { eq: tag },
        deleted: {
          eq: false,
        },
      },
    })
  );

  try {
    if (tag.includes('needsReview')) {
      let toDeleteIds = resp.data.tagByMessageId.items.map(
        (messageTagObjects, index) => {
          return messageTagObjects.id;
        }
      );
      for (var i = 0; i < toDeleteIds.length; ++i) {
        let delResp = await API.graphql(
          graphqlOperation(updateChatbotMessageTag, {
            input: {
              id: toDeleteIds[i],
              deleted: true,
              deletedBy: getAsurite(),
            },
          })
        );
      }
    } else {
      let toDeleteId = resp.data.tagByMessageId.items[0].id;

      let delResp = await API.graphql(
        graphqlOperation(updateChatbotMessageTag, {
          input: {
            id: toDeleteId,
            deleted: true,
            deletedBy: getAsurite(),
          },
        })
      );
    }
    // console.log("DEL RESP: ", delResp);
  } catch (e) {
    console.log('FAILED TO REMOVE: ', e);
  }
};

export const addTag = async (tagPay, op) => {
  let payload = {
    ...tagPay,
    addedBy: getAsurite(),
    botType: getBotType(),
    deleted: false,
  };
  console.log('pay', payload);
  let resp = await API.graphql(
    graphqlOperation(op, {
      input: payload,
    })
  );
  console.log('response', resp);

  return resp;
};

const addPrevAssistedTag = async (cId, tag) => {
  let payload = {
    convoId: cId,
    tag: tag,
  };
  await addTag(payload, createChatbotConvoTag);
  return;
};

export const resolveNeedsReview = async (
  mId,
  cId,
  listFilter,
  myTags,
  exactTag
) => {
  //console.log("!!! exactTag is", exactTag);
  let tag = exactTag
    ? exactTag
    : getTagValue('needsReview', listFilter, myTags);

  //console.log("!!! tag is", tag);
  let prevAssisTag = getTagValue('previouslyAssisted', listFilter, myTags);
  let promises = [];
  promises.push(addPrevAssistedTag(cId, prevAssisTag));
  promises.push(addPrevAssistedTag(cId, 'previouslyAssisted ' + getAsurite()));
  //console.log("!!! resolveNeedsReview called with mId and tag:", mId, tag);
  promises.push(removeMessageTag(mId, tag));
  promises.push(removeConvoTag(cId, tag));
  await Promise.all(promises);
  return;
};

const getTagValue = (tagStarter, listFilter, myTags) => {
  let tag = tagStarter + ' ';
  console.log('!!MyTags', myTags);
  if (listFilter) {
    let listSplit = listFilter.split(' ');
    if (listSplit[0] === 'groupAssigned') {
      tag += listSplit[1];
    } else {
      tag += myTags[1] ? myTags[1].split(' ')[1] : 'true';
    }
  } else {
    tag += 'true';
  }

  return tag;
};
