export const RoleMatrix = {
  // CHAT AGENT

  "chat agent": {
    users: {
      create: false,
      edit: false,
      delete: false,
      viewUsersByGroup: true,
    },
    knowledgeBase: {
      createNewFAQ: false,
      createAddDeleteSection: false,
      addNewType: false,
      editFAQ: false,
      publishFAQ: false,
      commentOnFAQ: false,
      deleteFAQ: false,
      approveDenyContent: false,
      disableContent: false,
      viewTable: true,
      viewAllContent: false,
    },
    escalations: {
      create: false,
      edit: false,
      publish: false,
      comment: false,
      delete: false,
      approveDenyContent: false,
      disable: false,
      viewTable: false,
    },
    alerts: {
      create: false,
      edit: false,
      publish: false,
      comment: false,
      delete: false,
      approveDenyContent: false,
      disable: false,
      viewTable: false,
      viewAllContent: false,
    },
    flows: {
      create: false,
      edit: false,
      publish: false,
      delete: false,
      approveDenyContent: false,
      disable: false,
      viewTable: false,
    },
    campaigns: {
      create: false,
      edit: false,
      publish: false,
      comment: false,
      pauseResume: false,
      delete: false,
      approveDenyContent: false,
      disable: false,
      viewTable: false,
      viewAllContent: false,
    },
    dialogues: {
      searchDialogues: true,
      enterConversation: true,
      viewTable: true,
    },
    responses: {
      create: false,
      edit: false,
      delete: false,
    },
  },

  // CONTENT CREATOR

  "content creator": {
    users: {
      create: false,
      edit: false,
      delete: false,
      viewUsersByGroup: true,
    },
    knowledgeBase: {
      createNewFAQ: true,
      createAddDeleteSection: false,
      addNewType: true,
      editFAQ: true,
      publishFAQ: false,
      commentOnFAQ: false,
      deleteFAQ: false,
      approveDenyContent: false,
      disableContent: false,
      viewTable: true,
      viewAllContent: false,
    },
    escalations: {
      create: true,
      edit: "only in group",
      publish: false,
      comment: false,
      delete: false,
      approveDenyContent: false,
      disable: false,
      viewTable: true,
      viewAllContent: false,
    },
    alerts: {
      create: true,
      edit: "only in group",
      publish: false,
      comment: false,
      delete: false,
      approveDenyContent: false,
      disable: false,
      viewTable: true,
      viewAllContent: false,
    },
    flows: {
      create: true,
      edit: true,
      publish: false,
      delete: false,
      approveDenyContent: false,
      disable: false,
      viewTable: true,
      viewAllContent: false,
    },
    flowMessage: {
      create: true,
      edit: "group",
      delete: "group",
      viewTable: true,
    },
    campaigns: {
      create: true,
      edit: true,
      publish: false,
      comment: false,
      pauseResume: false,
      delete: false,
      approveDenyContent: false,
      disable: false,
      viewTable: true,
      viewAllContent: false,
    },
    dialogues: {
      searchDialogues: true,
      enterConversation: true,
      viewTable: true,
    },
    responses: {
      create: true,
      edit: true,
      delete: true,
    },
  },

  // APPROVER

  approver: {
    users: {
      create: false,
      edit: false,
      delete: false,
      viewUsersByGroup: true,
    },
    knowledgeBase: {
      createNewFAQ: true,
      createAddDeleteSection: false,
      addNewType: true,
      editFAQ: true,
      publishFAQ: false,
      commentOnFAQ: true,
      deleteFAQ: false,
      approveDenyContent: true,
      disableContent: false,
      viewTable: true,
      viewAllContent: false,
    },
    escalations: {
      create: true,
      edit: true,
      publish: false,
      comment: true,
      delete: false,
      approveDenyContent: true,
      disable: false,
      viewTable: true,
      viewAllContent: false,
    },
    alerts: {
      create: true,
      edit: true,
      publish: false,
      comment: true,
      delete: false,
      approveDenyContent: true,
      disable: false,
      viewTable: true,
      viewAllContent: false,
    },
    flows: {
      create: true,
      edit: true,
      publish: false,
      delete: false,
      approveDenyContent: true,
      publishVersion: false,
      disable: false,
      viewTable: true,
      viewAllContent: false,
    },
    flowMessage: {
      create: true,
      edit: "group",
      delete: "group",
      viewTable: true,
    },
    campaigns: {
      create: true,
      edit: true,
      publish: false,
      comment: true,
      pauseResume: false,
      delete: false,
      approveDenyContent: true,
      disable: false,
      viewTable: true,
      viewAllContent: false,
    },
    dialogues: {
      searchDialogues: true,
      enterConversation: true,
      viewTable: true,
    },
    responses: {
      create: true,
      edit: true,
      delete: true,
    },
  },

  // PUBLISHER

  publisher: {
    users: {
      create: true,
      edit: true,
      delete: true,
      viewUsersByGroup: true,
    },
    knowledgeBase: {
      createNewFAQ: true,
      createAddDeleteSection: true,
      addNewType: true,
      editFAQ: true,
      publishFAQ: true,
      commentOnFAQ: true,
      deleteFAQ: true,
      approveDenyContent: true,
      disableContent: true,
      viewTable: true,
      viewAllContent: true,
    },
    escalations: {
      create: true,
      edit: true,
      publish: true,
      comment: true,
      delete: true,
      approveDenyContent: true,
      disable: true,
      viewTable: true,
      viewAllContent: true,
    },
    alerts: {
      create: true,
      edit: true,
      publish: true,
      comment: true,
      delete: true,
      approveDenyContent: true,
      disable: true,
      viewTable: true,
      viewAllContent: true,
    },
    flows: {
      create: true,
      edit: true,
      publish: true,
      delete: true,
      approveDenyContent: true,
      publishVersion: true,
      disable: true,
      viewTable: true,
      viewAllContent: true,
    },
    flowMessage: {
      create: true,
      edit: true,
      delete: true,
      viewTable: true,
    },
    campaigns: {
      create: true,
      edit: true,
      publish: true,
      comment: true,
      pauseResume: true,
      delete: true,
      approveDenyContent: true,
      disable: true,
      viewTable: true,
      viewAllContent: true,
    },
    dialogues: {
      searchDialogues: true,
      enterConversation: true,
      viewTable: true,
    },
    responses: {
      create: true,
      edit: true,
      delete: true,
    },
  },

  // ADMIN

  admin: {
    users: {
      create: true,
      edit: true,
      delete: true,
      viewUsersByGroup: true,
    },
    knowledgeBase: {
      createNewFAQ: true,
      createAddDeleteSection: true,
      addNewType: true,
      editFAQ: true,
      publishFAQ: true,
      commentOnFAQ: true,
      deleteFAQ: true,
      approveDenyContent: true,
      disableContent: true,
      viewTable: true,
      viewAllContent: true,
    },
    escalations: {
      create: true,
      edit: true,
      publish: true,
      comment: true,
      delete: true,
      approveDenyContent: true,
      viewAllContent: true,
      disable: true,
      viewTable: true,
    },
    alerts: {
      create: true,
      edit: true,
      publish: true,
      comment: true,
      delete: true,
      approveDenyContent: true,
      disable: true,
      viewTable: true,
      viewAllContent: true,
    },
    flows: {
      create: true,
      edit: true,
      publish: true,
      delete: true,
      approveDenyContent: true,
      publishVersion: true,
      disable: true,
      viewTable: true,
      viewAllContent: true,
    },
    flowMessage: {
      create: true,
      edit: true,
      delete: true,
      viewTable: true,
    },
    campaigns: {
      create: true,
      edit: true,
      publish: true,
      comment: true,
      pauseResume: true,
      delete: true,
      approveDenyContent: true,
      disable: true,
      viewTable: true,
      viewAllContent: true,
    },
    dialogues: {
      searchDialogues: true,
      enterConversation: true,
      viewTable: true,
    },
    responses: {
      create: true,
      edit: true,
      delete: true,
    },
  },
};

export const roleCheck = (role, section, action) => {
  try {
    return RoleMatrix[role][section][action];
  } catch (error) {
    console.log("role: ", role);
    console.log("section: ", section);
    console.log("action: ", action);
    console.log("roleCheck error: ", error);
    return error;
  }
};
