import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useReducer,
} from 'react';
import { useTheme, makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { SectionHeader } from 'components/SectionHeader';
import './Announcements.css';
import { store as GlobalStore } from 'contexts/GlobalStore';

import AuthContext from 'contexts/AuthContext';
import { roleCheck } from 'services/utility/RoleMatrix';
import { store } from 'contexts/GlobalStore';
import Switch from '@material-ui/core/Switch';

import * as mutations from 'graphql/mutations';
import * as queries from 'graphql/queries';

import { CreateNewButton, Table, Button, Input, Textarea, Form } from 'stories';

import { API, graphqlOperation, Storage } from 'aws-amplify';

import moment from 'moment-timezone';

Storage.configure({ level: 'public' });

let ampEnv = 'dev';

if (window.location.href.indexOf('chatbot-test.m.asu.edu') > -1) {
  ampEnv = 'test';
} else if (window.location.href.indexOf('chatbot.m.asu.edu') > -1) {
  ampEnv = 'prod';
} else if (window.location.href.indexOf('chatbot-qa.m.asu.edu') > -1) {
  ampEnv = 'pretest';
}

const announcementDict = { 'fin-aid': 'FINANCE BOT', 'it-bot': 'IT BOT' };

export default function Announcements(props) {
  const theme = useTheme();
  const tokens = useContext(AuthContext);

  const GlobalState = useContext(GlobalStore);
  const dispatchGlobal = GlobalState.dispatch;

  let [activeRow, setActiveRow] = useState({ 'fin-aid': '', 'it-bot': '' });
  let [editMode, setEditMode] = useState(false);
  let [editName, setEditName] = useState('');
  let [editText, setEditText] = useState('');
  let [announcementData, setAnnouncementData] = useState([{}]);

  const { editInfo } = GlobalState.state;

  let inputName = useRef(null);
  let inputContent = useRef(null);

  const addActionsToData = rowData => {
    let anyActive = { 'fin-aid': false, 'it-bot': false };
    let anyActiveRows = { 'fin-aid': '', 'it-bot': '' };

    rowData.sort((a, b) => {
      if (moment(a.updatedAt).isBefore(moment(b.updatedAt))) {
        return 1;
      } else {
        return -1;
      }
    });

    rowData = rowData.map(object => {
      console.log(': ', object);
      if (object.active === true) {
        anyActive[object.showOn] = true;
        anyActiveRows[object.showOn] = object;
      }

      object.actions = {};

      let updatedBy = object.editedBy;

      if (updatedBy !== null) {
        updatedBy.sort((a, b) => {
          if (moment(a.timestamp).isBefore(moment(b.timestamp))) {
            return 1;
          } else {
            return -1;
          }
        });
      }

      object.updatedBy =
        updatedBy == null ? object.createdBy : updatedBy[0].asurite;

      let actions = object.actions;
      typeof object.actions !== 'undefined' && delete object.actions;
      object.actions = actions;

      object.updatedAt = moment(object.updatedAt)
        .tz('America/Phoenix')
        .format('MMMM DD, YYYY');

      typeof object.group !== 'undefined' && delete object.group;
      typeof object.editedBy !== 'undefined' && delete object.editedBy;
      typeof object.createdAt !== 'undefined' && delete object.createdAt;
      typeof object.createdBy !== 'undefined' && delete object.createdBy;

      return object;
    });

    console.log(anyActiveRows);

    setActiveRow(anyActiveRows);

    for (var i = 0; i < rowData.length; ++i) {
      if (rowData[i].active === false) {
        if (anyActive[rowData[i].showOn]) {
          rowData[i].actions = {
            edit: true,
          };
        } else {
          rowData[i].actions = {
            custom1: true,
            custom1_name: 'Activate',
            edit: true,
          };
        }
      } else {
        rowData[i].actions = {
          custom1: true,
          custom1_name: 'Deactivate',
        };
      }
    }

    // if (anyActive === true) {
    //   rowData = rowData.map((object) => {
    //     if (object.active === false) {
    //       object.actions = {
    //         edit: true,
    //       };
    //     } else {
    //       object.actions = {
    //         custom1: true,
    //         custom1_name: "DEACTIVATE",
    //       };
    //     }
    //
    //     return object;
    //   });
    // } else {
    //   setActiveRow("");
    //
    //   rowData = rowData.map((object) => {
    //     if (typeof object.name !== "undefined") {
    //       object.actions = {
    //         custom1: true,
    //         custom1_name: "ACTIVATE",
    //         edit: true,
    //       };
    //     }
    //
    //     return object;
    //   });
    // }

    return rowData;
  };

  const getAnnouncementData = async () => {
    //console.log("getting announcements");

    let getAllResult = await API.graphql(
      graphqlOperation(queries.listChatbotAnnouncementss)
    );

    if (getAllResult.data.listChatbotAnnouncementss.items.length > 0) {
      let newAnnouncementData = addActionsToData(
        getAllResult.data.listChatbotAnnouncementss.items
      );

      setAnnouncementData(newAnnouncementData);
    }
  };

  useEffect(() => {
    getAnnouncementData();
  }, []);

  const tableLabels = [
    { title: 'Name', wide: true },
    { title: 'Updated Date' },
    { title: 'Updated By' },
    { title: 'Active', hide: true },
    { title: 'Text', hide: true },
    { title: 'Actions', alignRight: true, notClickable: true },
  ];

  const onClickEdit = rowData => {
    if (rowData.id) {
      dispatchGlobal({ type: 'updateEditInfo', editInfo: rowData });
      setEditMode(true);
    }
  };

  function idGenerator() {
    var S4 = function() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (
      S4() +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      S4() +
      S4()
    );
  }

  const onClickCustom1 = async rowData => {
    try {
      if (rowData.active === false) {
        await API.graphql(
          graphqlOperation(mutations.updateChatbotAnnouncements, {
            input: {
              id: rowData.id,
              active: true,
            },
          })
        );

        await API.graphql(
          graphqlOperation(mutations.createChatbotAnnouncementsHistorical, {
            input: {
              id: rowData.id,
              name: rowData.name,
              createdAt: moment().format('MM/DD/YYYY HH:mm:ss'),
              text: rowData.text,
              startTime: moment().format('MM/DD/YYYY HH:mm:ss'),
              startedBy: tokens.asurite,
              versionId: idGenerator(),
            },
          })
        );
      } else {
        await API.graphql(
          graphqlOperation(mutations.updateChatbotAnnouncements, {
            input: {
              id: rowData.id,
              active: false,
            },
          })
        );

        const listOfStarted = await API.graphql(
          graphqlOperation(queries.listChatbotAnnouncementsHistoricals, {
            id: rowData.id,
          })
        );

        console.log(
          listOfStarted.data.listChatbotAnnouncementsHistoricals.items
        );

        let onlyStarted = listOfStarted.data.listChatbotAnnouncementsHistoricals.items.filter(
          historicalRow => {
            if (historicalRow.endTime === null) {
              return true;
            }
          }
        );

        console.log(onlyStarted);

        if (onlyStarted.length > 0) {
          await API.graphql(
            graphqlOperation(mutations.updateChatbotAnnouncementsHistorical, {
              input: {
                id: rowData.id,
                createdAt: onlyStarted[0].createdAt,
                endTime: moment().format('MM/DD/YYYY HH:mm:ss'),
                endedBy: tokens.asurite,
              },
            })
          );
        }
      }
    } catch (e) {
      console.log('Could not set active state', e);
    }
    getAnnouncementData();
  };

  const createNewAnnouncement = () => {
    dispatchGlobal({ type: 'updateEditInfo', editInfo: { empty: true } });
    setEditMode('new');
  };

  const cancelEdit = () => {
    setEditMode(false);
  };

  const saveEdit = async data => {
    if (data.type !== 'submit') {
      try {
        if (editMode === 'new') {
          await API.graphql(
            graphqlOperation(mutations.createChatbotAnnouncements, {
              input: {
                name: data.name,
                text: data.text,
                active: false,
                createdBy: tokens.asurite,
              },
            })
          );
        } else {
          await API.graphql(
            graphqlOperation(mutations.updateChatbotAnnouncements, {
              input: {
                id: editInfo.id,
                name: data.name,
                text: data.text,
                active:
                  activeRow[editInfo.showOn] !== '' &&
                  activeRow[editInfo.showOn].id === editInfo.id,
                editedBy: activeRow[editInfo.showOn].editedBy
                  ? activeRow[editInfo.showOn].editedBy.push({
                      asurite: tokens.asurite,
                      timestamp: moment().format('MM/DD/YYYY HH:mm:ss'),
                    })
                  : [
                      {
                        asurite: tokens.asurite,
                        timestamp: moment().format('MM/DD/YYYY HH:mm:ss'),
                      },
                    ],
              },
            })
          );
        }
      } catch (e) {
        console.log('Could not save announcement', e);
      }

      dispatchGlobal({ type: 'updateEditInfo', editInfo: { empty: true } });
      setEditMode(false);
      getAnnouncementData();
    }
  };

  const formData = [
    {
      title: 'NAME',
      tooltip: 'Interal name for announcement',
      required: true,
      component: (
        <Input
          form
          name="name"
          required
          stateValue={editInfo.name ? editInfo.name : null}
        />
      ),
    },
    {
      title: 'MESSAGE',
      tooltip: 'Message that will be shown to the user',
      required: true,
      component: (
        <Textarea
          form
          name="text"
          maxcharacters="500"
          required
          stateValue={editInfo.text ? editInfo.text : null}
        />
      ),
    },
  ];

  let announcementTypes = Object.keys(announcementDict);

  return (
    <div style={theme.components.pageContainer}>
      <SectionHeader
        noSort
        noModality
        noFilter
        noSearch
        title={'EXPERIENCE CENTER ANNOUNCEMENT'}
      />

      {editMode !== false ? (
        <Paper style={theme.components.paper}>
          <div
            style={{
              paddingBottom: '2em',
              paddingLeft: '3em',
              paddingTop: '2em',
              paddingRight: '3em',
              width: '50%',
            }}
          >
            <Form
              title={
                (editMode === 'new' ? 'CREATE NEW' : 'EDIT') + ' ANNOUNCEMENT'
              }
              data={formData}
              onSubmit={saveEdit}
              back
              backHandler={e => setEditMode(false)}
            />
          </div>
        </Paper>
      ) : (
        <>
          {announcementTypes.map((type, index) => {
            return (
              <Paper
                className={
                  index > 0
                    ? 'announcementPaperStyle2'
                    : 'announcementPaperStyle'
                }
                style={{ minHeight: '10px', marginBottom: '1em' }}
              >
                <div className="announcementTopPartHolder">
                  <div className="announcementLabelHolder">
                    {announcementDict[type]} ACTIVE ANNOUNCEMENT
                  </div>
                  <div className="announcementActiveLabel">
                    {activeRow[type] === '' ? 'None' : activeRow[type].name}
                  </div>

                  {activeRow[type] === '' ? null : (
                    <>
                      <div
                        style={{
                          color: 'maroon',
                          marginLeft: '3rem',
                          marginRight: '0.75rem',
                          display: 'inline-block',
                        }}
                        onClick={() => {
                          onClickCustom1(activeRow[type]);
                        }}
                        className="actionButton"
                      >
                        DEACTIVATE
                      </div>
                    </>
                  )}
                </div>
              </Paper>
            );
          })}

          <Paper className="announcementPaperStyle2">
            {'\u00A0' /* prevent margin collapse */}

            <Table
              title="ANNOUNCEMENTS"
              defaultSortKey="createdAt"
              labels={tableLabels}
              data={announcementData}
              onClickEdit={onClickEdit}
              onClickCustom1={onClickCustom1}
              onClickCustom={''}
              onSelectFilter={() => {}}
              noSort={true}
              noFilter={true}
              noSearch={true}
              style={{ marginTop: '0em' }}
            />
          </Paper>
        </>
      )}
    </div>
  );
}
