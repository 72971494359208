import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { API, graphqlOperation } from 'aws-amplify';
import _ from 'lodash';
import axios from 'axios';

import { OpenSunny } from 'components/Sunny';

import PaginationPanel from 'stories/Table/PaginationPanel';
import { roleCheck } from 'services/utility/RoleMatrix';
import { SectionHeader } from 'components/SectionHeader';
import * as queries from 'graphql/queries';
import * as mutations from 'graphql/mutations';
import AuthContext from 'contexts/AuthContext';
import { store } from 'contexts/GlobalStore';
import { CreateNewButton, Table } from 'stories';

import './Responses.scss';

const pageLimit = 7;

let subscription = null;
// let rawData = [];

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const keepValues = [
  'miss1',
  'miss2',
  'maintText',
  'error',
  'unknownPhone',
  'beenOptedOut',
  'forceOptIn',
];

const typeOptions = [
  { value: 'miss1', label: 'First Miss' },
  { value: 'miss2', label: 'Second Miss' },
  { value: 'maintText', label: 'Maintenance' },
  { value: 'unknownPhone', label: 'Unknown Phone Number' },
  { value: 'error', label: 'Error' },
  { value: 'beenOptedOut', label: 'Text In during Agent Opt Out' },
  { value: 'forceOptIn', label: 'User Override Agent Opt Out' },
  // { value: "auth", label: "Authentication" },
  // { value: "intro", label: "Introduction" },
  // { value: "intro-fa", label: "Introduction-FA" },
  // { value: "rejectAgent", label: "Reject Agent" },
  // { value: "rejectLogin", label: "Reject Login" },
  // { value: "wait", label: "Wait" },
];
export default function Responses(props) {
  const tokens = useContext(AuthContext);
  const theme = useTheme();
  const GlobalState = useContext(store);
  const { dispatch } = GlobalState;
  const {
    tableSort,
    spliceTableData,
    clearNextTokens,
    tableDataEmpty,
  } = GlobalState.state;

  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchActive, setSearchActive] = useState(false);
  const [filter, setFilter] = useState('All');
  const [totalResults, setTotalResults] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [prevPageClicked, setPrevPageClicked] = useState(false);
  const [nextTokenList, setNextTokenList] = useState([]);
  const [rerenderKey, setRerenderKey] = useState(Date.now());
  const [refetchKey, setRefetchKey] = useState(Date.now());
  const [editClicked, setEditClicked] = useState(false);
  const [rawData, setRawData] = useState([]);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);

  const fetchData = async (searchText, filter, sort) => {
    if (tableDataEmpty) {
      dispatch({
        type: 'setLoadingTableData',
        loadingTableData: false,
      });
    } else {
      dispatch({
        type: 'setLoadingTableData',
        loadingTableData: true,
      });
    }
    try {
      let sortQuery = { field: 'timestamp', direction: 'desc' };
      if (sort) {
        sortQuery = { field: sort.field, direction: sort.direction };
      }

      let result = await API.graphql(
        graphqlOperation(queries.listResponseMessagess)
      );

      let rawResultData = result.data.listResponseMessagess.items;

      //Remove Benji
      rawResultData = rawResultData.filter(
        el => el.group !== 'Benji_1593621826'
      );

      let newData = [];

      for (var i = 0; i < rawResultData.length; ++i) {
        if (keepValues.indexOf(rawResultData[i].type) > -1)
          newData.push(rawResultData[i]);
      }

      let groupCheck = (group, arr) => {
        for (let i of arr) {
          if (i === group) {
            return true;
          }
        }
        return false;
      };

      if (tokens.role === 'approver' || tokens.role === 'content creator') {
        newData = newData.filter(el => groupCheck(el.group, tokens.groups));
      }

      const total = newData.length;
      if (total == 0 && currentPage > 1) setCurrentPage(currentPage - 1);
      setTotalResults(total);

      setRawData(newData);
      setData(parseData(newData));

      dispatch({
        type: 'setLoadingTableData',
        loadingTableData: false,
      });
    } catch (error) {
      console.log('Responses fetch data error: ', error);
    } finally {
      tableDataEmpty &&
        dispatch({
          type: 'setLoadingTableData',
          loadingTableData: false,
        });
    }
  };

  useEffect(() => {
    fetchData();
    dispatch({
      type: 'setLoadingFormSubmission',
      loadingFormSubmission: false,
    });
  }, [rerenderKey, refetchKey, searchActive, currentPage, filter, tableSort]);

  const getTypeLabel = type => {
    let returnType = type;
    typeOptions.forEach(typeOption => {
      if (type === typeOption.value) {
        returnType = typeOption.label;
      }
    });
    return returnType;
  };

  const parseData = data => {
    return data
      .filter(el => el.message) // fitler out data containing empty messages
      .map(el => {
        let a = JSON.parse(el.message);
        // console.log(el.type)
        // console.log(getTypeLabel(el.type))

        // console.log(el.group)

        return {
          excerpt: a.default, //excerpts
          type: getTypeLabel(el.type),
          group: el.group === 'any' ? 'Any' : el.group,
          author: el.author ? el.author : '',
          formattedCreatedAt: moment(el.createdAt).format('MM-DD-YYYY'),
          createdAt: el.createdAt,
          updatedAt: el.updatedAt,
          id: el.id,
          actions: {
            edit: roleCheck(tokens.role, 'responses', 'edit'),
            delete:
              el.author.toLowerCase() !== 'default'
                ? roleCheck(tokens.role, 'responses', 'edit')
                : false, // block if author is "default"
          },
        };
      });
  };

  useEffect(() => {
    fetchData();
    dispatch({ type: 'updateEditInfo', editInfo: { empty: true } });
  }, []);

  const onClickEdit = el => {
    console.log(el);

    rawData.forEach(data => {
      if (el.id === data.id) {
        // console.log("match!", data);
        dispatch({
          type: 'updateEditInfo',
          editInfo: data,
        });
      }
    });

    props.history.push({
      pathname: '/response-add',
    });
  };

  const roleCheckEdit = (group, asurite) => {
    if (roleCheck(tokens.role, 'responses', 'edit')) {
      if (tokens.asurite === asurite) {
        return true;
      } else {
        if (tokens.role === 'content creator' || tokens.role === 'approver') {
          if (tokens.groups.includes(group)) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
    } else {
      return false;
    }
  };

  const onSubmitDelete = async el => {
    let rawResponse = {};
    rawData.forEach(data => {
      if (el.id === data.id) {
        rawResponse.id = data.id;
      }
    });

    await API.graphql(
      graphqlOperation(mutations.deleteResponseMessages, {
        input: rawResponse,
      })
    );

    dispatch({
      type: 'setSpliceTableData',
      spliceTableData: {
        response: el,
        type: 'deleteResponse',
      },
    });
    setOpen(true);
    setRerenderKey(Date.now());
  };

  const roleCheckDel = (group, asurite) => {
    if (roleCheck(tokens.role, 'responses', 'delete')) {
      if (tokens.asurite === asurite) {
        return true;
      } else {
        if (tokens.role === 'content creator' || tokens.role === 'approver') {
          if (tokens.groups.includes(group)) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
    } else {
      return false;
    }
  };

  const onClickFetchMore = () => {
    setPrevPageClicked(false);
    if (currentPage === Math.ceil(totalResults / pageLimit)) {
      return;
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const onClickFetchLess = async () => {
    setPrevPageClicked(true);
    if (currentPage <= 1) {
      setCurrentPage(1);
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const onChangeJumpToPage = pageNumber => {
    setCurrentPage(pageNumber);
  };

  const jumpToPageOptions = () => {
    const totalPages = Math.ceil(totalResults / pageLimit);
    const options = [];

    for (let i = 1; i <= totalPages; i++) {
      options.push(i);
    }
    const mappedOptions = options.map(el => {
      return { value: el, label: el };
    });
    return mappedOptions;
  };

  const onClickCreateNew = () => {
    dispatch({ type: 'updateEditInfo', editInfo: { empty: true } });
    dispatch({
      type: 'changeTableSort',
      tableSort: null,
    });
    dispatch({ type: 'changeTableFilter', tableFilter: 'All' });
  };

  // const filterOptions = [
  //   { value: "Pending", label: "Pending" },
  //   { value: "Approved", label: "Approved" },
  //   { value: "Unapproved", label: "Unapproved" },
  //   { value: "Published", label: "Published" },
  //   // { value: "Disabled", label: "Disabled" }
  // ];

  const tableLabels = [
    { title: 'Excerpt', wide: true },
    { title: 'Type' },
    { title: 'Group' },
    { title: 'Author' },
    { title: 'Created' },
    { title: 'Actions', alignRight: true, notClickable: true },
  ];

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const indexOfLastRecord = currentPage * pageLimit;
  const indexOfFirstRecord = indexOfLastRecord - pageLimit;
  const currentPageRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);

  return (
    <div style={theme.components.pageContainer} key={rerenderKey}>
      <div className="SectionHeader">
        <SectionHeader
          noSort={true}
          noSearch={true}
          noModality
          rightView={
            roleCheck(tokens.role, 'responses', 'create') && (
              <div onClick={onClickCreateNew}>
                <button class="CreateAlert">
                  <CreateNewButton
                    to="/response-add"
                    label="Create New Response"
                  />
                </button>
              </div>
            )
          }
        />
      </div>
      <div className="TablePage">
        <Paper style={theme.components.paper}>
          <div>
            <Table
              title="Responses"
              labels={tableLabels}
              data={currentPageRecords}
              onClickEdit={onClickEdit}
              onSubmitDelete={onSubmitDelete}
              noSort={true}
              noSearch={true}
              responseTable
            />
            <PaginationPanel
              theme={theme}
              currentPage={currentPage}
              pageCount={Math.ceil(totalResults / pageLimit)}
              onClickFetchMore={onClickFetchMore}
              onClickFetchLess={onClickFetchLess}
              jumpToPageOptions={jumpToPageOptions}
              onChangeJumpToPage={onChangeJumpToPage}
            />
          </div>
        </Paper>
      </div>
    </div>
  );
}

Responses.propTypes = {
  navigation: PropTypes.object,
};
