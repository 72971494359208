import React, { useState, useEffect, useContext } from "react";
import AuthContext from "contexts/AuthContext";
import { MiniTable, PanelButton } from "stories";
import moment from "moment";

import "../CampaignTabs.scss";

export default function ImportSummaryCustomVars(props) {
  const [csvData, setCsvData] = useState(null);

  useEffect(() => {
    let keys = ["user", "key", "value"];

    let data = [keys];

    let fullarry = props.importVariables;

    for (var i = 0; i < fullarry.length; ++i) {
      let tArr = [];
      for (var k = 0; k < keys.length; ++k) {
        tArr.push(fullarry[i][keys[k]]);
      }
      data.push(tArr);
    }

    setCsvData(data);
  }, [props.importVariables]);

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          top: "0px",
          right: "1.2em",
          zIndex: "2",
        }}
      >
        <PanelButton
          type="csv"
          csvData={csvData}
          csvFileName={`${
            props.editInfo && props.editInfo.title
              ? props.editInfo.title
              : "chatbot"
          }-importVariables-${moment().format()}.csv`}
          label="Export Data"
        />
      </div>

      <div className="panelSection__title">
        <div className="panelHeader panelHeaderText">
          Campaign variables: {props.importVariables.length}
        </div>
      </div>

      <MiniTable
        tableData={props.importVariables}
        columnTitles={["User", "Variable", "Value"]}
        dataKeys={["user", "key", "value"]}
      />
    </div>
  );
}
