import React, { useState, useEffect, useContext } from "react";
import { useTheme } from "@material-ui/core/styles";
import DatePicker, { registerLocale } from "react-datepicker";
import { useFormContext } from "react-hook-form";
import moment from "moment";
import { ErrorLabel } from "stories";

import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";

import AuthContext from "contexts/AuthContext";

registerLocale("es", es);

export default function StartComponent(props) {
  const tokens = useContext(AuthContext);

  const theme = useTheme();
  const { register, clearError, errors } = useFormContext();
  const [date, setDate] = useState(null);
  const [error, setError] = useState(null);

  const { minDate, maxDate, dateChecker } = props;

  useEffect(() => {
    if (props.stateValue) {
      const formatted = moment(props.stateValue.toString(), "x").format();
      const initialDate = new Date(formatted);
      const timestamp = moment(initialDate).valueOf();
      setDate(timestamp);
    } else {
      setDate(null);
    }
  }, [props.stateValue]);

  const handleDateChange = newDate => {
    const timestamp = moment(newDate).valueOf();

    console.log("DATES", timestamp, minDate, maxDate);

    let errorMsg = null;
    if (minDate && timestamp < minDate) {
      errorMsg = "Date selected is before the minimum allowed time";
    }

    if (maxDate && timestamp > maxDate) {
      errorMsg = "Date selected is after the maximum allowed time";
    }

    if (errorMsg) {
      console.log("DATE WAS INVALID");
      setError(errorMsg);
    } else {
      setDate(timestamp);
      setError(null);
      if (props.onChange) props.onChange(timestamp);
    }
  };

  const CustomInput = ({ value, onClick }) => {
    return (
      <button
        style={{
          ...theme.typography.body1,
          width: "100%",
          color: theme.palette.general.darkGrey,
          border: `1px solid ${theme.palette.general.deepGrey}`,
          borderRadius: "4px",
          padding: "0.5rem 1rem",
          outline: 0,
          cursor: "pointer",
          ...props.style
        }}
        onClick={() => {
          clearError();
          return onClick();
        }}
      >
        {value ? value : "Click to select a date"}
      </button>
    );
  };

  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        <DatePicker
          // locale={es}
          selected={date}
          onChange={handleDateChange}
          showTimeSelect
          minDate={minDate ? minDate : null}
          maxDate={maxDate ? maxDate : null}
          timeIntervals={tokens.devAccess ? 1 : 15}
          dateFormat="MMMM d, yyyy h:mm a"
          customInput={<CustomInput />}
        />
        {error ? <ErrorLabel message={error} /> : null}
        {`${props.name}Date` === "startDate" && errors.startDate && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "fit-content"
            }}
          >
            <ErrorLabel message="This field is required" />
          </div>
        )}
        {`${props.name}Date` === "endDate" && errors.endDate && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "fit-content"
            }}
          >
            <ErrorLabel message="This field is required" />
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: "1px"
          }}
        >
          <div style={{ display: "flex", flex: 1 }} />
          <input
            name={`${props.name}Date`}
            ref={props.required ? register({ required: true }) : register}
            value={date}
            style={{
              opacity: 0,
              height: "1px",
              width: "1px"
            }}
          />
          <div style={{ display: "flex", flex: 1 }} />
        </div>
      </div>
    </div>
  );
}
