import React, { useContext } from "react";
import { useTheme } from "@material-ui/core/styles";

import { store } from "contexts/KBStore";
import { H6, Button } from "../";

export default function ModalConfirmation(props) {
  const theme = useTheme();
  const KBState = useContext(store);
  const { loadingFormSubmission } = KBState.state;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <H6 style={{ margin: "0px", marginBottom: "2rem" }}>{props.title}</H6>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Button
          label="CONFIRM"
          loading={loadingFormSubmission}
          spinnerSize="0.8rem"
          spinnerColor="black"
          onClick={props.onSubmit ? props.onSubmit : null}
          style={{
            border: `1px solid black`,
            borderRadius: "4px",
            marginRight: "2rem"
          }}
        />
        <Button label="Cancel" onClick={props.toggle} />
      </div>
    </div>
  );
}
