import React, { useState, useEffect, useContext } from 'react';
// import { useHistory, Redirect } from "react-router-dom";
// import clsx from "clsx";
// import { useTheme } from "@material-ui/core/styles";
// import { makeStyles } from "@material-ui/core/styles";

// import Drawer from "@material-ui/core/Drawer";
// import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
// import CssBaseline from "@material-ui/core/CssBaseline";
// import AppBar from "@material-ui/core/AppBar";
// import Toolbar from "@material-ui/core/Toolbar";
// import List from "@material-ui/core/List";
// import Divider from "@material-ui/core/Divider";
// import IconButton from "@material-ui/core/IconButton";
// import MenuIcon from "@material-ui/icons/Menu";
// import { devs } from "./listItems";
// import { sendAnalytics } from "../services/api/analytics";
// import Routes from "./Routes";
import AuthContext from 'contexts/AuthContext';
// import AuthService from "../services/auth.service";
// import { H6, Button } from "stories";
// import { store as GlobalStore } from "contexts/GlobalStore";
import { Landing } from 'pages/Landing';
// import Logo from "../assets/asu-logo.png";
import './App.scss';
import MainApp from './MainApp';

import { createSawStatus } from 'graphql/mutations';
import { listStatuss, getSawStatus } from 'graphql/queries';
import { onUpdateStatus, onCreateStatus } from 'graphql/subscriptions';
import { getUserGroups } from 'hooks';

import { API, graphqlOperation } from 'aws-amplify';

// const drawerWidth = 240;
var onUpdateSub = null;
var onCreateSub = null;

var trial = 0;

export default function Dashboard(props) {
  const tokens = useContext(AuthContext);
  const { keyedNames, selectListGroups, getGroup } = getUserGroups();

  const [checkingMode, setCheckingMode] = useState(true);
  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const [status, setStatus] = useState(false);
  const [errorMode, setErrorMode] = useState(false);

  const writeAsSeen = async s => {
    let payload = {
      id: s.id + '-' + s.submittedBy,
      asurite: tokens.asurite,
      timestamp: new Date().getTime(),
    };

    let haveSeen = await API.graphql(
      graphqlOperation(getSawStatus, {
        id: payload.id,
        asurite: tokens.asurite,
      })
    );
    let result = haveSeen.data.getSawStatus;

    if (!result) {
      try {
        await API.graphql(
          graphqlOperation(createSawStatus, { input: payload })
        );
      } catch (e) {
        console.log(e);
      }
    }
  };

  const checkForStatusMode = async () => {
    try {
      let date = new Date().getTime();

      let m = await API.graphql(
        graphqlOperation(listStatuss, {
          filter: { active: { eq: true } },
          limit: 10000,
        })
      );
      let resp = m.data.listStatuss.items;

      let maint = false;
      let s = null;

      for (var i = 0; i < resp.length; ++i) {
        if (resp[i].id === 'maintenance') {
          maint = true;
          s = resp[i];
          break;
        } else if (!maint) {
          s = resp[i];
        }
      }

      if (maint) {
        s.title = 'Maintenance Mode Active';
        setMaintenanceMode(true);
      }
      if (s && s.id) {
        writeAsSeen(s);
      }

      setStatus(s);
      setCheckingMode(false);
    } catch (err) {
      if (++trial < 3) {
        setTimeout(() => {
          checkForStatusMode();
        }, 1000);
      } else {
        setErrorMode(true);
      }
    }
  };

  useEffect(() => {
    if (!onCreateSub || onCreateSub._state === 'closed') {
      onCreateSub = API.graphql(graphqlOperation(onCreateStatus)).subscribe(
        subData => {
          window.location.reload();
        }
      );
    }

    if (!onUpdateSub || onUpdateSub._state === 'closed') {
      onUpdateSub = API.graphql(graphqlOperation(onUpdateStatus)).subscribe(
        subData => {
          window.location.reload();
        }
      );
    }

    return () => {
      if (onCreateSub) {
        onCreateSub.unsubscribe();
      } else {
        console.warn('Failed to clean up subscription to Campaigns');
      }

      if (onUpdateSub) {
        onUpdateSub.unsubscribe();
      } else {
        console.warn('Failed to clean up subscription to Campaigns');
      }
    };
  }, []);

  useEffect(() => {
    checkForStatusMode();
  }, []);

  const stayAtLandingPage = () => {
    if (checkingMode) {
      return true;
    } else {
      if (maintenanceMode && tokens) {
        if (tokens.devAccess) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }
  };

  if (stayAtLandingPage()) {
    return (
      <div>
        <Landing
          maintenanceMode={maintenanceMode}
          checkingMode={checkingMode}
          errorMode={errorMode}
        />
      </div>
    );
  } else {
    return <MainApp maintenanceMode={maintenanceMode} status={status} />;
  }
}
