export function insertAtPosition(toInsert, currentValue, fieldRef) {
  let refId = null;

  let position = currentValue - 1;

  if (fieldRef) {
    position = fieldRef.selectionStart;
    refId = fieldRef.id;
  }

  let start = currentValue.substring(0, position);
  let end = currentValue.substring(position);

  let newStr = start + toInsert;

  let heldPos = newStr.length;

  newStr += end;

  if (
    window.location.href.indexOf("/add-FAQ") > -1 &&
    (refId === "question" || refId === "variations-input")
  ) {
    newStr = toInsert;
    heldPos = newStr.length;
  }

  if (fieldRef) {
    setTimeout(() => {
      fieldRef.focus();
      fieldRef.selectionStart = heldPos;
      fieldRef.selectionEnd = heldPos;
    }, 50);
  }

  return newStr;
}
