import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import SearchModalContent from './SearchModalContent';
import { scroller as scroll, Events } from 'react-scroll';
import { MiniTable, Modal, PanelButton, Accordion, Button } from 'stories';
import { useModal } from 'hooks';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import AuthContext from 'contexts/AuthContext';

import CircularProgress from '@material-ui/core/CircularProgress';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckIcon from '@material-ui/icons/Check';
import { store as GlobalStore } from 'contexts/GlobalStore';
import Tooltip from '@material-ui/core/Tooltip';

import '../Dialogues.scss';
import * as tagHelper from '../Helpers/tagHelper.js';
import { getUserGroups } from 'hooks';

import {
  ImportRecord,
  CampaignHistory,
  EscalationHistory,
  Notes,
  NeedsReview,
} from './LogsTables';

import {
  queryWithAthena,
  escHistoryByConvoId,
  compCampByUserId,
} from 'graphql/queries';

import { API, graphqlOperation } from 'aws-amplify';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const searchMsg = {
  text: 'Searching for message',
  type: 'info',
};

const searchMsgErr = {
  text: 'Could not find message',
  type: 'error',
};

export default function DialogueLogs(props) {
  const GlobalState = useContext(GlobalStore);
  const dispatchGlobal = GlobalState.dispatch;
  const tokens = useContext(AuthContext);

  const { convo, convoNotes, msgsInReview } = props;
  const { isShowing, toggle } = useModal();
  const [isResolving, setIsResolving] = useState(false);
  const [searchModalData, setSearchModalData] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openErrorData, setOpenErrorData] = useState(searchMsg);
  const [escalations, setEscalations] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [campTableData, setCampTableData] = useState([]);
  const [msgId, setMsgId] = useState(null);
  const [importHistory, setImportHistory] = useState({ raw: [], table: [] });
  const { keyedNames } = getUserGroups();

  useEffect(() => {}, [props.convo, msgsInReview]);

  useEffect(() => {
    if (props.searchForMessage === null && msgId !== null) {
      if (document.getElementById(msgId)) {
        scroll.scrollTo(msgId, {
          duration: 700,
          delay: 100,
          smooth: true,
          containerId: 'messageContainer',
        });
        setOpenError(false);
        setMsgId(null);
      } else {
        setOpenErrorData(searchMsgErr);
        setTimeout(() => {
          setOpenErrorData(searchMsg);
          setOpenError(false);
        }, 30000);
      }
    }
  }, [props.searchForMessage]);

  const headerDivStyle = {
    display: 'flex',
    height: '30px',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '1rem 0',
  };

  const exportNeedsReivewHistory_buildCSVData = data => {
    let csvData = [['Sender', 'Agent', 'Content', 'Date/Time']];

    if (typeof data !== 'undefined' && data !== null && data.length > 0) {
      data.forEach(msg => {
        let dateString = props.dateFormatter(msg.latest, true);

        let tagx = msg.tags.find(a => a.includes('needsReview'));

        let assigned = tagx.split(' ')[1];

        let csvRow = {
          sender: msg.sender,
          agent: props.getAssigneeName(assigned),
          content: msg.content,
          dateTime: dateString,
        };

        csvData.push([
          csvRow.sender,
          csvRow.agent,
          csvRow.content,
          csvRow.dateTime,
        ]);
      });
    }

    return csvData;
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenErrorData(searchMsg);
    setOpenError(false);
  };

  Events.scrollEvent.register('end', function(to, element) {
    try {
      document.getElementById(to).parentElement.classList.add('changeBgColor');
      setTimeout(() => {
        if (document.getElementById(to)) {
          document
            .getElementById(to)
            .parentElement.classList.remove('changeBgColor');
        }
      }, 900);
    } catch (e) {}
  });

  const handleScrollToMessage = note => {
    if (document.getElementById(note.messageId)) {
      scroll.scrollTo(note.messageId, {
        duration: 700,
        delay: 100,
        smooth: true,
        containerId: 'messageContainer',
      });
    } else {
      setOpenError(true);
      setMsgId(note.messageId);
      props.setSearchForMessage(note.messageId);
    }
  };

  return (
    <div>
      <NeedsReview
        convo={props.convo}
        updateReviewCounts={props.updateReviewCounts}
        dateFormatter={props.dateFormatter}
        onViewHandler={handleScrollToMessage}
        getAssigneeName={props.getAssigneeName}
        needsReview={msgsInReview}
        keyedNames={keyedNames}
        setUpdateNeedsReview={props.setUpdateNeedsReview}
      />

      <Notes
        convo={props.convo}
        toggle={toggle}
        dateFormatter={props.dateFormatter}
        onEditHandler={props.handleSelectEditMessage}
        onViewHandler={handleScrollToMessage}
        getAssigneeName={props.getAssigneeName}
        notes={convoNotes.rawData}
      />

      <CampaignHistory
        convo={props.convo}
        dateFormatter={props.dateFormatter}
      />

      <ImportRecord convo={props.convo} dateFormatter={props.dateFormatter} />

      <EscalationHistory
        convo={props.convo}
        dateFormatter={props.dateFormatter}
        handleScrollToMessage={handleScrollToMessage}
        getAssigneeName={props.getAssigneeName}
      />
      <Modal
        isShowing={isShowing}
        hide={toggle}
        content={
          <SearchModalContent
            getUsername={props.getUsername}
            tableData={props.convoNotes.notesTableData}
            rawData={props.convoNotes.rawData}
            formatNotesTableData={props.formatNotesTableData}
            getAssigneeName={props.getAssigneeName}
            dateFormatter={props.dateFormatter}
            setConvoNotes={props.setConvoNotes}
            toggle={toggle}
          />
        }
      />
      <Snackbar open={openError} autoHideDuration={60000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={openErrorData.type}>
          {openErrorData.text}
        </Alert>
      </Snackbar>
    </div>
  );
}
