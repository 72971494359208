import React, { useState, useEffect, useContext } from "react";

import AuthContext from "contexts/AuthContext";
import Paper from "@material-ui/core/Paper";
import SvgIcon from "@material-ui/core/SvgIcon";

import { Button } from "stories";

import { ReactComponent as ExpandIcon } from "assets/icons/icon-arrow-right.svg";
import { ReactComponent as HumanIcon } from "assets/icons/icon-human.svg";
import { ReactComponent as BotIcon } from "assets/icons/icon-bot.svg";
import { ReactComponent as AlertIcon } from "assets/icons/icon-alert-2.svg";
import { ReactComponent as NeedsReviewIcon } from "assets/icons/icon-needs-review.svg";
import { ReactComponent as ReadIcon } from "assets/icons/icon-read@3x.svg";
import { ReactComponent as UnreadIcon } from "assets/icons/icon-unread.svg";
import { ReactComponent as HasNoteIcon } from "assets/icons/icon-has-note.svg";
import { ReactComponent as EscalationIcon } from "assets/icons/maroon-arrow.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Truncate from "react-truncate";

import { faSearch, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import * as dateHelper from "../Helpers/dateHelper.js";

export default function Conversation(props) {
  const {
    el,
    convosWithNotesById,
    modifyActiveConvos,
    setOtherAgentChatting,
    selectedConvo,
    getAvatar,
    getAssigneeName,
    handleMenuOpen,
  } = props;

  let [hasMultipleUsers, setHasMultipleUsers] = useState(false);
  let [selectedUser, setSelectedUser] = useState(false);
  let [currentType, setCurrentType] = useState(el.type);

  let agentAssigned = null;
  let groupAssigned = null;

  let currentDateString = dateHelper.dateFormatter(el.latest, true)

  let didFindGroup = false;
  let foundGroup = false;
  if (el.tags) {
    let agentTag = el.tags.find((a) => a && a.includes("agentAssigned"));
    let groupTag = el.tags.find((a) => a && a.includes("groupAssigned"));

    try {
      groupAssigned = groupTag.split(" ")[1];
    } catch (e) {}
    try {
      agentAssigned = agentTag.split(" ")[1];
    } catch (e) {}
  }

  let needsReview = false;
  let hasNote = false;
  let hasEscalation = false;

  if (el.tags) {
    for (let tag of el.tags) {
      if (tag && tag.includes("needsReview")) {
        needsReview = true;
      }
      if (tag && tag.includes("escalation")) {
        hasEscalation = true;
      }
    }
  }

  if (convosWithNotesById.includes(el.convoId)) {
    hasNote = true;
  }

  let convoIcons = {
    read: el.webId, //! true === read, false === unread
    human: el.agentChatting, //! false === bot
    hasAlert: el.didError,
    hasNote,
    needsReview,
    hasEscalation,
  };

  const getDisplayName = () => {
    if (el.type === "auth") {
      return el.displayName ? el.displayName : el.asurite;
    } else if (el.type === "visitor") {
      return "Visitor (" + el.asurite + ")";
    } else if (el.type === "sms") {
      return el.displayName ? el.displayName : el.phoneNumber;
    }
  };

  useEffect(() => {
    // if (el.users.length > 1) {
    //   setHasMultipleUsers(true);
    //   setSelectedUser(el.users[0]);
    // }
  }, []);

  // useEffect(() => {

  // }, [hasMultipleUsers]);

  return (
    <div name={el.id} key={el.id}>
      <div
        className={`clickable hoverBackground ${
          selectedConvo.id === el.id ? "selectedConvo" : "nonSelectedConvo"
        }`}
        style={{
          display: "flex",
          backgroundColor: selectedConvo.id === el.id ? "#f2f2f2" : null,
        }}
        key={el.convoId}
        onClick={() => {
          setOtherAgentChatting(false);
          modifyActiveConvos(el);
        }}
      >
        <div
          style={{
            padding: "1rem 0.3rem 0 0.8rem",
            position: "relative",
          }}
        >
          <div>{getAvatar(el, 35)}</div>
        </div>
        <div
          style={{
            fontSize: "0.8rem",
            padding: "1rem 0",
            // color: "grey",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p>
              <b style={{ fontSize: "1rem" }}>
                <Truncate lines={1}>{el.viewName}</Truncate>
              </b>
            </p>
            <div
              //! icon div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "20px",
              }}
            >
              {convoIcons.hasEscalation ? (
                <SvgIcon viewBox={"-6 -7.5 32 32"} component={EscalationIcon} />
              ) : null}
              {convoIcons.human ? (
                <SvgIcon viewBox={"-6 -6 25 25"} component={HumanIcon} />
              ) : (
                <SvgIcon viewBox={"-6 -6.5 25 25"} component={BotIcon} />
              )}
              {convoIcons.hasNote ? (
                <SvgIcon viewBox={"-6 -6 25 25"} component={HasNoteIcon} />
              ) : null}
              {convoIcons.hasAlert ? (
                <SvgIcon viewBox={"-6 -6 25 25"} component={AlertIcon} />
              ) : null}
              {convoIcons.needsReview ? (
                <SvgIcon viewBox={"-6 -6 25 25"} component={NeedsReviewIcon} />
              ) : null}
              {convoIcons.read ? (
                <SvgIcon viewBox={"-6 -6 25 25"} component={ReadIcon} />
              ) : (
                <SvgIcon viewBox={"-6 -5 25 25"} component={UnreadIcon} />
              )}
              <FontAwesomeIcon
                style={{ marginLeft: "5px" }}
                size="lg"
                icon={faEllipsisV}
                onClick={(e) => handleMenuOpen(e, el)}
              />{" "}
            </div>
          </div>
          <div style={{ fontSize: "0.85rem" }}>
            <Truncate lines={1}>
              {el.lastMessageContent ? el.lastMessageContent : el.content}
            </Truncate>
            <br />
            <p style={{ fontSize: "0.7rem" }}>{currentDateString}</p>
            <br />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginRight: "20px",
            }}
          >
            <div style={{ fontSize: "0.85rem" }}>
              <p style={{ fontSize: "0.7rem" }}>
                Assignee: <b>{getAssigneeName(agentAssigned, groupAssigned)}</b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
