import React, { useState, useEffect } from "react";
// import { useTheme } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
// import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useFormContext } from "react-hook-form";

import { Input, Textarea, ErrorLabel } from "stories";
import "../forms.css";

export default function SelectCheckbox(props) {
  const { register, errors, setError, clearError } = useFormContext();
  const [inputValue, setInputValue] = useState(
    props.inputValue ? props.inputValue : ""
  );
  const [textareaValue, setTextareaValue] = useState(
    props.textareaValue ? props.textareaValue : ""
  );
  const [textMessage, setTextMessage] = useState(
    props.textMessage ? props.textMessage : false
  );

  useEffect(() => {
    if (props.textMessage) {
      setTextMessage(props.textMessage);
    } else {
      setTextMessage(false);
    }
  }, []);

  useEffect(() => {
    if (props.inputValue) {
      setInputValue(props.inputValue);
    }
  }, []);

  useEffect(() => {
    if (props.textareaValue) {
      setTextareaValue(props.textareaValue);
    }
  }, []);

  const handleChangeTextMessage = (event) => {
    setTextMessage(event.target.checked);
  };

  const onChangeInput = (e) => {
    if (validatePhone(e.target.value)) {
      clearError("textMessagePhoneNum");
    } else {
      setError("textMessagePhoneNum", {
        type: "invalidPhoneNumber",
        message: "Phone number format invalid",
      });
    }
    setInputValue(e.target.value);
  };

  const validatePhone = (phoneNumber) => {
    const validPhoneNumber = /^\d{10}$/;
    const validPhoneNumberDashes = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (
      phoneNumber.match(validPhoneNumber) ||
      phoneNumber.match(validPhoneNumberDashes)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onChangeTextarea = (value) => {
    setTextareaValue(value);
  };

  const computePhoneValue = () => {
    if (validatePhone(inputValue)) {
      return inputValue;
    } else {
      return "";
    }
  };

  const CheckboxComponent2 = () => {
    return (
      <div>
        <FormControlLabel
          control={
            <Checkbox
              name="sendTextMessage"
              disabled={false}
              checked={textMessage}
              value={textMessage}
              onChange={handleChangeTextMessage}
              color="primary"
            />
          }
          label="Send text message to:"
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: "1px",
          }}
        >
          <div style={{ display: "flex", flex: 1 }} />
          <input
            style={{
              opacity: 0,
              height: "1px",
              width: "1px",
              cursor: "default",
            }}
          />
          <div style={{ display: "flex", flex: 1 }} />
        </div>
      </div>
    );
  };

  return (
    <div>
      <CheckboxComponent2 />
      <Input
        style={{ margin: "1rem 0 0 2rem" }}
        disabled={!textMessage}
        onChange={onChangeInput}
        value={inputValue}
        placeholder="123-456-7890"
      />
      {errors.textMessagePhoneNum &&
      errors.textMessagePhoneNum.types &&
      errors.textMessagePhoneNum.types.type === "invalidPhoneNumber" ? (
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "flex-start",
            alignItems: "center",
            paddingLeft: "2rem",
          }}
        >
          <ErrorLabel message={errors.textMessagePhoneNum.types.message} />
        </div>
      ) : null}
      {errors.textMessagePhoneNum &&
      errors.textMessagePhoneNum.type === "required" ? (
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "flex-start",
            alignItems: "center",
            paddingLeft: "2rem",
          }}
        >
          <ErrorLabel message="This field is required" />
        </div>
      ) : null}
      {textMessage ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: "1px",
          }}
        >
          <div style={{ display: "flex", flex: 1 }} />
          <input
            name="textMessagePhoneNum"
            ref={register({ required: true })}
            value={computePhoneValue()}
            readOnly
            autoComplete="off"
            style={{
              opacity: 0,
              height: "1px",
              width: "1px",
              cursor: "default",
            }}
          />
          <div style={{ display: "flex", flex: 1 }} />
        </div>
      ) : null}

      <div style={{ margin: "2rem 0 0 2rem" }}>
        <Textarea
          disabled={!textMessage}
          onChange={onChangeTextarea}
          value={textMessage ? textareaValue : textareaValue}
          placeholder="Text message content - if left blank will use email body"
          allowextras="emoji,var"
        />
      </div>
      {/* {errors.textMessageContent &&
        errors.textMessageContent.type === "required" ? (
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "flex-start",
              alignItems: "center",
              paddingLeft: "2rem"
            }}
          >
            <ErrorLabel message="This field is required" />
          </div>
        ) : null} */}
      {textMessage ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: "1px",
          }}
        >
          <div style={{ display: "flex", flex: 1 }} />
          <input
            name={"textMessageContent"}
            ref={register({ required: false })}
            value={textMessage ? textareaValue : textareaValue}
            readOnly
            autoComplete="off"
            style={{
              opacity: 0,
              height: "1px",
              width: "1px",
              cursor: "default",
            }}
          />
          <div style={{ display: "flex", flex: 1 }} />
        </div>
      ) : null}
    </div>
  );
}
