import * as React from "react";

const DELTA_AMT = 10;

export interface IDragData {
  x: number;
  y: number;
  dx: number;
  dy: number;
}

export interface IReactPanZoomStateType {
  dragging: boolean;
  dragData: IDragData;
  matrixData: number[];
}
export interface IReactPanZoomProps {
  height?: string;
  width?: string;
  className?: string;
  enablePan?: boolean;
  zoom?: number;
  pandx?: number;
  pandy?: number;
  onPan?: (x: number, y: number) => void;
}

export default class ReactPanZoom extends React.PureComponent<IReactPanZoomProps, IReactPanZoomStateType> {

  static defaultProps: Partial<IReactPanZoomProps> = {
    enablePan: true,
    onPan: () => undefined,
    pandx: 0,
    pandy: 0,
    zoom: 1,
  };
  getInitialState = () => {
    const { pandx, pandy, zoom } = this.props;
    const defaultDragData = {
      dx: pandx,
      dy: pandy,
      x: 0,
      y: 0,
    };

    return {
      dragData: defaultDragData,
      dragging: false,
      midPoint: [0,0],
      recenterNum: 0,
      recenterStart: 0,
      matrixData: [
        zoom, 0, 0, zoom, pandx, pandy, // [zoom, skew, skew, zoom, dx, dy]
      ],
    };
  };
  // Used to set cursor while moving.
  panWrapper: any;
  // Used to set transform for pan.
  panContainer: any;
  state = this.getInitialState();

  onMouseDown = (e: React.MouseEvent<EventTarget>) => {
    if (!this.props.enablePan) {
      return;
    }
    const { matrixData } = this.state;
    const offsetX = matrixData[4];
    const offsetY = matrixData[5];
    const newDragData: IDragData = {
      dx: offsetX,
      dy: offsetY,
      x: e.pageX,
      y: e.pageY,
    };
    this.setState({
      dragData: newDragData,
      dragging: true,
    });
    if (this.panWrapper) {
      this.panWrapper.style.cursor = "move";
    }
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateMidpoint.bind(this));
    this.updateMidpoint();
  }

  componentDidUpdate(prevProps, prevState) {

    const { matrixData } = this.state;
    if (matrixData[0] !== this.props.zoom) {

      const newMatrixData = [...this.state.matrixData];
      newMatrixData[0] = this.props.zoom || newMatrixData[0];
      newMatrixData[3] = this.props.zoom || newMatrixData[3];

      this.setState({
        matrixData: newMatrixData,
      });

    }

    if( prevProps.center !== this.props.center ) {
      this.centerChart();
    }

    if( prevProps.fitChart !== this.props.fitChart ) {
      this.fitToScreen();
    }

  }

  fitToScreen() {
    let wrapper = this.panWrapper.getBoundingClientRect();
    let rect = this.props.treeElem.getBoundingClientRect();

    let boundW = rect.width > rect.height ? wrapper.width : wrapper.height;
    let boundR = rect.width > rect.height ? rect.width : rect.height;

    if( boundW < boundR ) {
      this.props.continueFitting(true);
    } else {
      this.props.continueFitting(false);
    }

  }

  centerChart() {

    let rect = this.props.treeElem.getBoundingClientRect();
    let rectMid = [rect.width/2,rect.height/2];

    const newMatrixData = [...this.state.matrixData];
    let zoom = newMatrixData[0];

    let currLocation = [this.state.matrixData[4],this.state.matrixData[5]];

    let goalX = (this.state.midPoint[0])*zoom - rectMid[0];
    let goalY = (this.state.midPoint[1])*zoom - rectMid[1];

    let nextX = this.nextLoc(newMatrixData[4],goalX);
    let nextY = this.nextLoc(newMatrixData[5],goalY);

    newMatrixData[4] = nextX;
    newMatrixData[5] = nextY;

    this.setState({
      matrixData: newMatrixData,
      recenterNum: this.state.recenterNum+1
    })

    if( this.incompleteMove(currLocation,[goalX,goalY]) && (this.state.recenterNum - this.state.recenterStart < 250) ) {
      setTimeout(() => {
        this.centerChart();
      },5)
    } else {
      this.setState({
        recenterStart: this.state.recenterNum
      })
    }
  }

  incompleteMove(curr,goal) {
    if( curr[0] !== goal[0] || curr[1] !== goal[1] ) {
      return true;
    } else {
      return false;
    }
  }

  nextLoc(nX, oX) {
    if( nX > oX ) {
      return this.subNum(nX,oX);
    } else if ( nX < oX ) {
      return this.addNum(nX,oX);
    }
    return oX;
  }

  subNum(nX, oX) {
    let diff = Math.abs(nX-oX)/100;
    if( diff < 1 ) {
      diff = 1
    }
    let x = nX - (DELTA_AMT*diff);
    if( x < oX ) {
      return oX
    } else {
      return x;
    }
  }

  addNum(nX, oX) {
    let diff = Math.abs(nX-oX)/100;
    if( diff < 1 ) {
      diff = 1
    }
    let x = nX + (DELTA_AMT*diff);
    if( x > oX ) {
      return oX
    } else {
      return x;
    }
  }

  updateMidpoint = () => {
    if( this.panWrapper ) {
      let wrapper = this.panWrapper.getBoundingClientRect();
      let midPoint = [wrapper.width/2,wrapper.height/2];

      if( midPoint[0] !== this.state.midPoint[0] || midPoint[1] !== this.state.midPoint[1] ) {
        this.setState({
          midPoint: midPoint
        })
      }
    }
  }

  onMouseUp = () => {
    this.setState({
      dragging: false,
    });
    if (this.panWrapper) {
      this.panWrapper.style.cursor = "";
    }
    if (this.props.onPan) {
      this.props.onPan(this.state.matrixData[4], this.state.matrixData[5]);
    }
  };

  getNewMatrixData = (x: number, y: number): number[] => {
    const { dragData, matrixData } = this.state;
    const deltaX = dragData.x - x;
    const deltaY = dragData.y - y;
    matrixData[4] = dragData.dx - deltaX;
    matrixData[5] = dragData.dy - deltaY;
    return matrixData;
  };

  onMouseMove = (e: React.MouseEvent<EventTarget>) => {
    if (this.state.dragging) {
      const matrixData = this.getNewMatrixData(e.pageX, e.pageY);
      this.setState({
        matrixData,
      });
      if (this.panContainer) {
        this.panContainer.style.transform = `matrix(${this.state.matrixData.toString()})`;
      }
    }
  };

  render() {
    return (
      <div
        className={`pan-container ${this.props.className || ""}`}
        onMouseDown={this.onMouseDown}
        onMouseUp={this.onMouseUp}
        onMouseMove={this.onMouseMove}
        style={{
          height: this.props.height,
          userSelect: "none",
          width: this.props.width,
        }}
        ref={(ref) => this.panWrapper = ref}
        id="PanHolder"
      >
        <div
          ref={(ref) => ref ? this.panContainer = ref : null}
          style={{
            transform: `matrix(${this.state.matrixData.toString()})`
          }}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}
