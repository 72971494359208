import React, { useEffect, useState, useRef } from "react";

import "./Tree.scss";
import { arraysEqual, embedTree, setTypeById } from "../utility.js";

import { allNodeOptions } from "../Edit/utility.js";

import { ReactComponent as AddIcon } from "../tempIcons/plus-solid.svg";
import { ReactComponent as DeleteIcon } from "../tempIcons/times-solid.svg";
import { ReactComponent as CollapseIcon } from "../tempIcons/chevron-up-solid.svg";

import { ReactComponent as JumpIcon } from "../tempIcons/redo-alt-solid.svg";
import { ReactComponent as BranchIcon } from "../tempIcons/call_split-24px.svg";
import { ReactComponent as TextIcon } from "../tempIcons/comment-alt-regular.svg";
import { ReactComponent as PromptIcon } from "../tempIcons/question-circle-regular.svg";
import { ReactComponent as TriggerIcon } from "../tempIcons/bolt-solid.svg";
import SaveIcon from "@material-ui/icons/Save";

let buttonPressTimer = null;
var baseRef = null;
var tLength = 0;
var trial = 0;
var haveRun = false;

export default function GenerateTree(props) {
  let { selected } = props;
  const [tree, setTree] = useState([]);
  const [matchParents, setMatchParents] = useState({});

  const initTree = (r) => {
    if (r.length > 0 && !haveRun) {
      haveRun = true;
      watchForReady();
    }

    let x = setTypeById(r);
    setMatchParents(x);

    let rTreeCopy = JSON.parse(JSON.stringify(r));
    let t = embedTree(rTreeCopy);

    setTree(t);
  };

  const watchForReady = () => {
    let items = document.getElementsByClassName("itemHolder");

    if ((items.length === 0 || items.length < tLength) && ++trial < 500) {
      setTimeout(() => {
        watchForReady();
      }, 25);
    } else {
      if (trial < 499) {
        props.doneWithTree();
      }
    }
  };

  useEffect(() => {
    haveRun = false;
    setTimeout(() => {
      props.setTree(baseRef);
    }, 200);
  }, []);

  useEffect(() => {
    tLength = props.rawTree.length;
    initTree(props.rawTree);
  }, [props.rawTree]);

  const addNode = (item) => {
    let pid = null;
    let id = null;

    if (!item.type && item.match) {
      pid = item.parent;
      id = item.id;
    } else if (item.type && item.id) {
      pid = item.id;
    }

    let info = {
      parent: pid,
      id: id,
      type: item.type,
      match: item.type ? null : item.match,
    };

    props.addNode(info);
  };

  const confirmDelete = (id) => {
    console.log("NEED CONFIRM: ", id);
    props.deleteItem(id);
  };

  const selectItem = (e, item) => {
    e.stopPropagation();

    let ids = [];

    if (e.shiftKey) {
      // console.log("SHIFT",item.id)
      props.fillNodeId(item.id);
    } else {
      if (props.selected.length === 1 && props.selected[0] === item.id) {
        ids = [];
      } else {
        ids = [item.id];
      }
      props.setSelected(ids);
    }
  };

  const addButton = (item) => {
    return (
      <li key={"add_" + item.id}>
        <span
          className="tf-nc item"
          onClick={(e) => addNode(item)}
          key={"addLabel_" + item.id}
        >
          <div className="addItem">
            <div className="addIconHolder">
              <AddIcon
                style={{
                  color: "black",
                  width: "15px",
                  display: 'flex',
                  justifyContent: 'center',
                }}
              />
            </div>
          </div>
        </span>
      </li>
    );
  };

  const _branch = (item, index) => {
    if (item) {
      return (
        <MatchNode
          item={item}
          matchParents={matchParents}
          index={index}
          viewOnly={props.viewOnly}
          key={"matchFull_" + item.id}
        >
          {item.type ? (
            <CollapseButton
              item={item}
              requestCollapse={(item) => props.collapse(item.id)}
              key={"collapseFull_" + item.id}
            >
              {item.show !== false ? (
                <li key={"child_" + item.id}>
                  <RenderNode
                    item={item}
                    selected={props.selected.indexOf(item.id) > -1}
                    confirmDelete={confirmDelete}
                    viewOnly={props.viewOnly}
                    clicked={selectItem}
                  />

                  {!props.viewOnly && item.text ? (
                    <ul key={"parent_" + item.id}>
                      {item.children && item.children.length > 0
                        ? item.children.map((c, ind) => _branch(c, ind))
                        : props.viewOnly
                        ? null
                        : addButton(item)}
                    </ul>
                  ) : null}

                  {props.viewOnly &&
                  item.text &&
                  item.children &&
                  item.children.length > 0 ? (
                    <ul key={"parent_" + item.id}>
                      {item.children.map((c, ind) => _branch(c, ind))}
                    </ul>
                  ) : null}
                </li>
              ) : null}
            </CollapseButton>
          ) : props.viewOnly ? null : (
            addButton(item)
          )}
        </MatchNode>
      );
    } else {
      return null;
    }
  };

  return (
    <div
      className="tf-tree"
      id="fullTree"
      ref={(e) => (baseRef = e)}
    >
      <ul key="baseTreeStart">
        <li key="start">
          <span className="tf-nc item" key="startLabel">
            <div className="start">
              <div className="headerHolder">Start</div>
            </div>
          </span>
          <ul key="treeStart">
            {tree && tree.length > 0
              ? tree.map((item, index) => _branch(item, index))
              : addButton({})}
          </ul>
        </li>
      </ul>
    </div>
  );
}

function MatchNode(props) {
  let { item, index, children, requestCollapse, matchParents } = props;

  const selectItem = (e, item) => {
    requestCollapse(item);
  };

  if (item.match) {
    let bgClass = "matchBoxBase ";
    try {
      // console.log(matchParents[item.parent]);

      if (matchParents[item.parent].indexOf("ifthen") > -1) {
        bgClass += "ifthenMatch";
      } else {
        bgClass += "matchBox20";
      }
    } catch (e) {
      bgClass += "matchBox20";
    }

    return (
      <li key={"matchInfo_" + item.id}>
        <span className="tf-nc item" key={"matchInfoSpan_" + item.id}>
          <div className={bgClass}>
            {item.match !== "NO MATCH" &&
            item.match !== "EXISTS" &&
            item.match !== "NOT EXISTS" ? (
              <div>
                <div className="matchLine matchHeader">Match</div>
                <div className="matchLine matchSubtext ellipsis">
                  [{index + 1}] {item.match}
                </div>
              </div>
            ) : (
              <div className="noMatch">
                <div className="matchLine matchHeader">{item.match}</div>
              </div>
            )}
          </div>
        </span>
        {props.viewOnly && children === null ? null : (
          <ul key={"matchParent_" + item.id}>{children}</ul>
        )}
      </li>
    );
  } else {
    return children;
  }
}

function CollapseButton(props) {
  let { item, requestCollapse, children } = props;
  let cName = "addItem ";

  if (item.show === false) {
    cName += "collapsedBtn";
  }

  const selectItem = (e, item) => {
    requestCollapse(item, "use_state");
  };

  return (
    <li key={"collapse_" + item.id}>
      <span
        className="tf-nc item"
        onClick={(e) => selectItem(e, item)}
        key={"collapseLabel_" + item.id}
      >
        <div className={cName}>
          <div
            className="collapseHolder"
            style={{
              transform: item.show === false ? "rotate(180deg)" : null,
            }}
          >
            <CollapseIcon fill="white" />
          </div>
        </div>
      </span>
      {item.show !== false ? (
        <ul key={"collapseparent_" + item.id}>{children}</ul>
      ) : null}
    </li>
  );
}

function RenderNode(props) {
  let { selected, item, longPress, clicked, confirmDelete } = props;

  const selectItem = (e, item) => {
    clicked(e, item);
  };

  const tryDelete = (e, item) => {
    e.stopPropagation();
    confirmDelete({ id: item.id });
  };

  const getJump = () => {
    if (item.jumpToNodeId) {
      try {
        let str = "";
        let x = JSON.parse(item.jumpToNodeId);
        let f = JSON.parse(x.flow);
        let v = JSON.parse(x.version);

        return `${f.label} - ${v.label}`;
      } catch (e) {
        return item.jumpToNodeId;
      }
    } else {
      return "NONE";
    }
  };

  const getNodeBody = (item) => {
    switch (item.type) {
      case "jump":
        return (
          <div className="nodeText nodeRowPadding">
            <div className="singleLineEllipsis">
              Skip After: {item.skipAfterTimes} Times
            </div>
            <div className="singleLineEllipsis">Jump to: {getJump()}</div>
          </div>
        );
      case "trigger":
        let dump = {};

        try {
          dump = JSON.parse(item.escalationDump);
        } catch (e) {}

        return (
          <div className="nodeText nodeRowPadding">
            <div className="singleLineEllipsis">
              Escalation Name: {dump.escalationName}
            </div>
            <div className="singleLineEllipsis">{item.emailBody}</div>
          </div>
        );
      default:
        return <div className="nodeText nodeRowPadding">{item.text}</div>;
    }
  };

  const handleButtonPress1 = () => {};
  const handleButtonRelease = () => {};

  // console.log("ITEM:",item);

  return (
    <span
      className="tf-nc item"
      onClick={(e) => selectItem(e, item)}
      onTouchStart={handleButtonPress1}
      onTouchEnd={handleButtonRelease}
      onMouseDown={handleButtonPress1}
      onMouseUp={handleButtonRelease}
      onMouseLeave={handleButtonRelease}
    >
      <div
        className="itemHolder"
        style={{ borderColor: selected ? "blue" : null }}
      >
        <div className="nodeHeaderHolder nodeRowPadding">
          <div className="nodeTopLeft">
            <NodeLabel type={item.type} />
          </div>
          {props.viewOnly ||
          (item.type === "jump" && item.match === "NO MATCH") ? null : (
            <div className="nodeTopRight">
              <div className="deleteBtn" onClick={(e) => tryDelete(e, item)}>
                <DeleteIcon className="deleteSvg" />
              </div>
            </div>
          )}
        </div>

        {getNodeBody(item)}
      </div>
    </span>
  );
}

function NodeLabel(props) {
  let { type } = props;

  let className = "typeHolder " + type + "LabelColor";

  let name = type;
  let group = null;

  for (var i = 0; i < allNodeOptions.length; ++i) {
    if (type === allNodeOptions[i].type) {
      name = allNodeOptions[i].name;
      group = allNodeOptions[i].group;
    }
  }

  const getIcon = () => {
    let icon = <TextIcon />;
    switch (type) {
      case "text":
        icon = <TextIcon />;
        break;
      case "prompt":
        icon = <PromptIcon fill="white" />;
        break;
      case "jump":
        icon = <JumpIcon fill="white" />;
        break;
      case "trigger":
        icon = (
          <TriggerIcon fill="white" style={{ height: "13px", width: "13px" }} />
        );
        break;
      case "datacollector":
        icon = (
          <SaveIcon fill="white" style={{ height: "13px", width: "13px" }} />
        );
        break;
      case "branch":
        icon = (
          <BranchIcon
            fill="white"
            style={{
              transform: "rotate(180deg)",
              width: "18px",
              marginTop: "-3px",
            }}
          />
        );
        break;
      case "ifthen-value":
      case "ifthen-range-number":
      case "ifthen-range-letter":
      case "ifthen-existsnot":
      case "ifthen-hasvalue":
        icon = (
          <BranchIcon
            fill="white"
            style={{
              transform: "rotate(180deg)",
              width: "18px",
              marginTop: "-3px",
            }}
          />
        );
        break;
      default:
        icon = null;
    }
    return icon;
  };

  if (type) {
    return (
      <div className={className}>
        <div className="typeIcon">{getIcon()}</div>
        <div className="typeText">{group === "ifthen" ? "IF/THEN" : name}</div>
      </div>
    );
  } else {
    return null;
  }
}
