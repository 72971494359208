import React, { useState, useContext, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { API, graphqlOperation } from 'aws-amplify';
import axios from 'axios';
import _ from 'lodash';
import { useTheme } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import * as mutations from 'graphql/mutations';
import { useModal, getUserGroups } from 'hooks';
import { SectionHeader } from 'components/SectionHeader';
import AuthContext from 'contexts/AuthContext';
import { store } from 'contexts/GlobalStore';
import { roleCheck } from 'services/utility/RoleMatrix';
import {
  Modal,
  H6,
  Form,
  Checkbox,
  Select,
  Input,
  Textarea,
  Button,
  CreateNewButton,
  ModalContent,
} from 'stories';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

//escalations email:
//https://us-west-2.console.aws.amazon.com/lambda/home?region=us-west-2#/functions/chatbot_escalation_email?tab=configuration
//https://5gc11ii4uj.execute-api.us-west-2.amazonaws.com/dev/chatbot_escalation_email

export default function EscalationAdd(props) {
  const tokens = useContext(AuthContext);
  const { isShowing, toggle } = useModal();
  const theme = useTheme();

  const [rerenderKey, setRerenderKey] = useState(Date.now());
  const [formValues, setFormValues] = useState();
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setSuccess] = useState(false);
  const [formSuccessful, setFormSuccessful] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [userGroups, setUserGroups] = useState([]);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [modalContentType, setModalContentType] = useState('');
  const [escalationType, setEscalationType] = useState(null);

  const GlobalState = useContext(store);
  const { dispatch } = GlobalState;
  const { editInfo } = GlobalState.state;

  const { keyedNames, selectListGroups, getGroup } = getUserGroups();
  const typeOptions = [{ label: 'Email', value: 'Email' }];

  typeOptions.push({ label: 'Live Agent', value: 'Live Agent' });

  useEffect(() => {
    !editInfo.empty && setEscalationType(editInfo.escalationType);
    fetchData();
  }, []);

  const fetchData = async () => {};

  const validateEmail = data => {
    if (
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data['email to'])
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = async (data, e) => {
    if (data.type !== 'submit') {
      console.log('data: ', data);
      if (validateEmail(data) || escalationType !== 'Email') {
        setEmailError(false);
        dispatch({
          type: 'setLoadingFormSubmission',
          loadingFormSubmission: true,
        });
        setOpenError(false);

        try {
          let postData = {};

          postData.escalationName = data['escalation name'];
          postData.escalationType = JSON.parse(data['escalation type']).value;
          postData.group = JSON.parse(data.group).value;
          postData.groupKey =
            JSON.parse(data.group).value + '-' + generateUID();

          postData.deleted = false;
          postData.timestamp = new Date().getTime().toString();
          postData.timestampKey =
            new Date().getTime().toString() + '-' + generateUID();
          postData.status = getStatusKey(JSON.parse(data['status']).value);
          postData.statusKey =
            getStatusKey(JSON.parse(data['status']).value) +
            '-' +
            generateUID();

          if (escalationType === 'Email') {
            postData.emailTo = data['email to'];
            postData.ccemail = data.ccemail === '' ? null : data.ccemail;
            postData.emailSubject = data['email subject'];
            postData.emailBody = data['email body'];
            postData.attachChatLog = true;
          } else if (escalationType === 'Live Agent') {
            postData.liveAgentCategory = 'Financial';
          }

          if (editInfo.empty) {
            postData.submittedBy = tokens.asurite;
            postData.submittedByKey = tokens.asurite + '-' + generateUID();
            postData.submittedAt = new Date().getTime().toString();
            postData.submittedAtKey =
              new Date().getTime().toString() + '-' + generateUID();
          }

          // if the formValue "textMessagePhoneNum" exists then it implies that the sendTextMessage checkbox is checked
          if (data['textMessagePhoneNum']) {
            postData.sendTextMessage = true;
          } else {
            postData.sendTextMessage = false;
          }

          if (postData.sendTextMessage) {
            postData.textMessageContent = {
              to: data['textMessagePhoneNum'],
              textBody:
                data['textMessageContent'] === ''
                  ? data['email body']
                  : data['textMessageContent'],
            };
          }

          let escalationOperation;
          if (!editInfo.empty) {
            console.log('got here 1');
            let newEditedBy = [];
            if (editInfo.editedBy) {
              newEditedBy = editInfo.editedBy;
            }
            newEditedBy.push({
              asurite: tokens.asurite,
              timestamp: new Date().getTime().toString(),
            });
            postData.editedBy = newEditedBy;
            postData.id = editInfo.id;
            escalationOperation = await API.graphql(
              graphqlOperation(mutations.updateEscalation, {
                input: postData,
              })
            );
          } else {
            escalationOperation = await API.graphql(
              graphqlOperation(mutations.createEscalation, {
                input: postData,
              })
            );
            console.log('createEscalation: ', escalationOperation);
          }

          if (escalationOperation && !escalationOperation.errors) {
            dispatch({
              type: 'setSpliceTableData',
              spliceTableData: {
                escalation: escalationOperation.data.createEscalation
                  ? escalationOperation.data.createEscalation
                  : escalationOperation.data.updateEscalation,
                type: escalationOperation.data.createEscalation
                  ? 'addEscalation'
                  : 'updateEscalation',
              },
            });
            props.history.push({
              pathname: '/escalations',
            });
          } else {
            console.log('something went wrong with escalationOperation');
          }
        } catch (error) {
          setOpenError(true);
          setErrorMessage('Something went wrong with form submission');
          console.log('add escalation error: ', error);
        } finally {
          dispatch({
            type: 'setLoadingFormSubmission',
            loadingFormSubmission: false,
          });
        }
      } else {
        setEmailError(true);
      }
    }
  };

  const onSubmitUnapproval = data => {
    if (data.type !== 'submit') {
      axios
        .put(
          process.env.REACT_APP_APIGATEWAY_CHATBOT +
          '/chatbot-unapproval-email',
          {
            asurite: tokens.asurite,
            submittedBy: editInfo.submittedBy,
            editedBy: editInfo.editedBy ? editInfo.editedBy : null,
            emailBody:
              'reason: ' +
              data.reasonForRejection +
              ' message: ' +
              data.message,
            details: "escalation name '" + formValues['escalation name'] + "'",
          }
        )
        .then(res => {
          console.log('onSubmitUnapproval res: ', res);
        })
        .catch(e => {
          console.log('onSubmitUnapproval error: ', e);
        });
      onSubmit(formValues);
    }
  };

  const createOnSubmit = data => {
    if (data.type !== 'submit') {
      if (
        JSON.parse(data.status).value === 'Unapproved' &&
        !editInfo.empty &&
        editInfo.status !== 2
      ) {
        setFormValues(data);
        setModalContentType('unapproval');
        toggle();
      } else {
        onSubmit(data);
      }
    }
  };

  // const confirmEscalation = async () => {};

  const generateUID = () => {
    let firstPart = (Math.random() * 46656) | 0;
    let secondPart = (Math.random() * 46656) | 0;
    firstPart = ('000' + firstPart.toString(36)).slice(-3);
    secondPart = ('000' + secondPart.toString(36)).slice(-3);
    return firstPart + secondPart;
  };

  const getModalTitle = () => {
    if (
      GlobalState.state.editInfo &&
      GlobalState.state.editInfo.empty !== true
    ) {
      return (
        <H6 style={{ margin: '0px', marginBottom: '2rem' }}>
          Edit escalation?
        </H6>
      );
    } else {
      return (
        <H6 style={{ margin: '0px', marginBottom: '2rem' }}>
          Create escalation?
        </H6>
      );
    }
  };

  const unapprovalFormData = () => {
    let emailList = [];
    emailList.push(tokens.asurite + '@asu.edu');
    emailList.push(editInfo.submittedBy + '@asu.edu');
    editInfo.editedBy &&
      editInfo.editedBy.forEach(el => {
        emailList.push(el.asurite + '@asu.edu');
      });

    const uniqueEmailList = Array.from(new Set(emailList));

    return [
      {
        component: (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                fontWeight: 400,
                marginLeft: '0.25rem',
              }}
            >
              <div>Message will be emailed to: </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {uniqueEmailList.map((el, index) => {
                  return (
                    <div
                      style={{
                        marginLeft: '0.25rem',
                      }}
                    >
                      {el}
                      {index !== uniqueEmailList.length - 1 && ','}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ),
      },
      {
        title: 'REASON FOR REJECTION',
        required: true,
        component: <Input form name="reasonForRejection" required />,
      },
      {
        title: 'MESSAGE',
        required: true,
        component: (
          <Textarea
            form
            required
            name="message"
            // stateValue={editInfo.emailBody ? editInfo.emailBody : ""}
          />
        ),
      },
    ];
  };

  const getModalContent = () => {
    if (modalContentType === 'unapproval') {
      return (
        <ModalContent
          confirmation={null}
          form={true}
          formData={unapprovalFormData()}
          title="Unapproval reason"
          onSubmit={onSubmitUnapproval}
          toggle={toggle}
        />
      );
    } else {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {getModalTitle()}
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button
              // onClick={confirmEscalation}
              style={{
                border: `1px solid black`,
                borderRadius: '4px',
                marginRight: '2rem',
              }}
            >
              {'CONFIRM'}
            </Button>
            <Button label="Cancel" onClick={toggle} />
          </div>
        </div>
      );
    }
  };

  const getStatusKey = name => {
    switch (name) {
      case 'Pending':
        return 1;
      case 'Unapproved':
        return 2;
      case 'Approved':
        return 3;
      case 'Published':
        return 4;
      default:
        return null;
    }
  };

  const getStartingStatus = () => {
    return 'Pending';
    // if (tokens.role === "content creator") {
    //   return "Pending";
    // } else if (
    //   tokens.role === "approver" &&
    //   editInfo &&
    //   editInfo.status === 4
    // ) {
    //   return "Approved";
    // }

    // if (editInfo.status) {
    //   switch (editInfo.status) {
    //     case 1:
    //       return "Pending";
    //     case 2:
    //       return "Unapproved";
    //     case 3:
    //       return "Approved";
    //     case 4:
    //       return "Published";
    //     default:
    //       return null;
    //   }
    // } else {
    //   return null;
    // }
  };

  const getStartingLiveAgentCategory = () => {
    return editInfo.liveAgentCategory ? editInfo.liveAgentCategory : '';
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenError(false);
    setSuccess(false);
  };

  const getEmailError = () => {
    if (emailError) {
      return true;
    } else {
      return null;
    }
  };

  const createStatusOptions = () => {
    let statusOptions = [{ value: 'Pending', label: 'Pending' }];

    if (roleCheck(tokens.role, 'escalations', 'approveDenyContent')) {
      if (editInfo.id) {
        statusOptions.push({ value: 'Unapproved', label: 'Unapproved' });
      }
      statusOptions.push({ value: 'Approved', label: 'Approved' });
    }
    if (roleCheck(tokens.role, 'escalations', 'publish')) {
      statusOptions.push({ value: 'Published', label: 'Published' });
    }

    return statusOptions;
  };

  const onChangeEscType = e => {
    console.log('e: ', e);
    setEscalationType(e.value);
  };

  const onClickCreate = () => {
    dispatch({ type: 'updateEditInfo', editInfo: { empty: true } });
    dispatch({
      type: 'changeTableSort',
      tableSort: null,
    });
    dispatch({ type: 'changeTableFilter', tableFilter: 'All' });
    setRerenderKey(Date.now());
  };

  const formData = [
    {
      title: 'GROUP',
      required: true,
      component: (
        <Select
          form
          updateStateValue={
            tokens.role === 'publisher' && !editInfo.group
              ? getGroup(tokens.groups[0])
              : getGroup(editInfo.group)
              ? getGroup(editInfo.group)
              : null
          }
          name="group"
          required
          options={selectListGroups}
        />
      ),
    },
    {
      title: 'ESCALATION NAME',
      required: true,
      component: (
        <Input
          form
          stateValue={editInfo.escalationName ? editInfo.escalationName : ''}
          name="escalation name"
          required
        />
      ),
    },
    {
      title: 'ESCALATION TYPE',
      required: true,
      component: (
        <Select
          form
          stateValue={editInfo.escalationType ? editInfo.escalationType : ''}
          name="escalation type"
          required
          options={typeOptions}
          onChange={onChangeEscType}
        />
      ),
    },
  ];

  if (escalationType === 'Email') {
    formData.push(
      {
        title: 'EMAIL TO',
        required: true,
        component: (
          <Input
            form
            setError={getEmailError()}
            errorMessage="Please enter a valid email address"
            stateValue={editInfo.emailTo ? editInfo.emailTo : ''}
            name="email to"
            required
            autocomplete="off"
          />
        ),
      },
      {
        title: 'CC EMAIL',
        subtext: 'enter emails separated by semicolon',
        component: (
          <Textarea
            form
            stateValue={editInfo.ccemail ? editInfo.ccemail : ''}
            name="ccemail"
          />
        ),
      },
      {
        title: 'EMAIL SUBJECT',
        required: true,
        component: (
          <Input
            form
            stateValue={editInfo.emailSubject ? editInfo.emailSubject : ''}
            name="email subject"
            required
            allowextras="emoji,var"
          />
        ),
      },
      {
        title: 'EMAIL BODY',
        required: true,
        component: (
          <Textarea
            form
            required
            name="email body"
            stateValue={editInfo.emailBody ? editInfo.emailBody : ''}
            allowextras="emoji,var"
          />
        ),
      },
      {
        component: (
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', fontWeight: 'bold' }}>
              NOTE:
              <div style={{ fontWeight: 400, marginLeft: '0.25rem' }}>
                Chat Log Attached
              </div>
            </div>
          </div>
        ),
      },
      {
        component: (
          <Checkbox
            form
            escalations
            required
            name="escalations-log-text"
            inputValue={
              editInfo.textMessageContent
                ? editInfo.textMessageContent.to
                : null
            }
            textareaValue={
              editInfo.textMessageContent &&
              editInfo.textMessageContent !== 'null'
                ? editInfo.textMessageContent.textBody
                : null
            }
            textMessage={editInfo.sendTextMessage ? true : false}
          />
        ),
      },
      {
        title: 'STATUS',
        required: true,
        component: (
          <Select
            form
            required
            name="status"
            options={createStatusOptions()}
            stateValue={getStartingStatus()}
          />
        ),
      }
    );
  } else if (escalationType === 'Live Agent') {
    formData.push({
      title: 'STATUS',
      required: true,
      component: (
        <Select
          form
          required
          name="status"
          options={createStatusOptions()}
          stateValue={getStartingStatus()}
        />
      ),
    });
  }

  return (
    <div style={theme.components.pageContainer} key={rerenderKey}>
      <SectionHeader
        noSort
        noModality
        noFilter
        noSearch
        title="ESCALATIONS"
        rightView={
          roleCheck(tokens.role, 'escalations', 'create') && (
            <div onClick={onClickCreate}>
              <CreateNewButton
                to="/escalation-add"
                label="Create New Escalation"
              />
            </div>
          )
        }
      />
      <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={openSuccess} autoHideDuration={6000} nClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Escalation details saved successfully!
        </Alert>
      </Snackbar>

      <Paper style={theme.components.paper}>
        <div style={{ padding: '2rem', width: '50%' }}>
          <Form
            title={editInfo.id ? 'EDIT ESCALATION' : 'ADD ESCALATION'}
            data={formData}
            onSubmit={createOnSubmit}
            style={{ width: '50%' }}
            autocomplete="off"
          />
          <Modal
            isShowing={isShowing}
            hide={toggle}
            content={getModalContent()}
            style={
              modalContentType !== 'unapproval'
                ? {
                    color: theme.palette.general.errorColor,
                    width: 'fit-content',
                    backgroundColor: 'white',
                  }
                : null
            }
          />
        </div>
      </Paper>
    </div>
  );
}
