import React, { useEffect, useState, useContext } from 'react';
import _ from 'lodash';
import moment from 'moment';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {
  listEscalations,
  introMessageByStatus,
  introMessageHistoricalByIntroId,
} from 'graphql/queries';
import { useTheme } from '@material-ui/core/styles';
import { API, graphqlOperation } from 'aws-amplify';

import AuthContext from 'contexts/AuthContext';
import { store as KBStore } from 'contexts/KBStore';
import { store as GlobalStore } from 'contexts/GlobalStore';
//import ExtendSession from './ExtendSession';
import * as mutations from 'graphql/mutations';
import * as queries from 'graphql/queries';
import {
  listKnowledgeBaseCategorys,
  validateLexQuestion,
} from 'graphql/queries';
import { useModal, getUserGroups } from 'hooks';
import { roleCheck } from 'services/utility/RoleMatrix';
import {
  Modal,
  H4,
  Form,
  Select,
  Textarea,
  FlowSelect,
  ConditionInput,
  Variations,
  CategoryInput,
  ModalContent,
  ErrorLabel,
  Input,
} from 'stories';

import { idGenerator } from 'services/utility/util.js';

var validIosReactions = [
  'loved',
  'liked',
  'disliked',
  'laughed at',
  'emphasized',
  'questioned',
];

let leftWithoutSaving = true;
let saving = false;
// let haveHit = false;

export default function KBForm(props) {
  const theme = useTheme();
  const { isShowing, toggle } = useModal();

  const tokens = useContext(AuthContext);

  const KBState = useContext(KBStore);
  const dispatchKB = KBState.dispatch;
  const { formTitle } = KBState.state;
  const GlobalState = useContext(GlobalStore);
  const dispatchGlobal = GlobalState.dispatch;
  const {
    editInfo,
    foundPublishedRows,
    loadingFormSubmission,
  } = GlobalState.state;
  const { keyedNames, selectListGroups, getGroup } = getUserGroups();

  const [escalationData, setEscalationData] = useState([]);
  // const [userGroups, setUserGroups] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [subcategoriesData, setSubcategoriesData] = useState([]);
  const [formSuccessful, setFormSuccessful] = useState(false);
  const [attachedEvent, setAttachedEvent] = useState(false);
  const [runningQuestion, setRunningQuestion] = useState(false);
  const [mainQuestionStatusMsg, setMainQuestionStatusMsg] = useState('');
  const [mainQuestionStatus, setMainQuestionStatus] = useState('');
  const [questRunnerError, setQuestRunnerError] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [triggerReset, setTriggerReset] = useState(false);

  const [hasEscalationData, setHasEscalationData] = useState(false);

  const [parentCategory, setParentCategory] = useState(null);
  const [modalContentType, setModalContentType] = useState('');

  const [msgError, setMsgError] = useState('');

  const [haveHit, setHaveHit] = useState(false);

  useEffect(() => {
    fetchData();
  }, [props.rerenderKey]);

  const botIDs = [
    { label: 'fin-aid', value: 'fin-aid' },
    { label: 'it-bot', value: 'it-bot' },
  ];

  useEffect(() => {
    if (editInfo && editInfo.id) {
      localStorage.setItem('editInfoId', editInfo.id);
      setEdit(true);
    }
    // Releases from editing on close and refresh only if current page is open
    window.addEventListener('beforeunload', ev => {
      ev.preventDefault();
      setEdit(false);
    });
    watchPath();
    function watchPath() {
      let path = window.location.pathname;

      if (path.indexOf('add-FAQ') > -1) {
        setTimeout(() => {
          watchPath();
        }, 500);
      } else {
        if (leftWithoutSaving) {
          if (path === '/CustomIntroductions') {
            let timesThru = 0;
            // Need to wait for rows to render & subscription ready
            // or it wont release from UI of current screen
            function waitForReady() {
              if (document.getElementById(editInfo.id) || timesThru > 500) {
                setEdit(false);
              } else {
                ++timesThru;
                setTimeout(() => {
                  waitForReady();
                }, 50);
              }
            }
            waitForReady();
          } else {
            setEdit(false);
          }
        }
      }
    }
  }, [editInfo]);

  useEffect(() => {
    if (!parentCategory) {
      return;
    } else {
      let subcats = [];
      categoriesData.forEach(el => {
        if (el.title === parentCategory) {
          subcats.push(...el.subcategories);
        }
      });
      setSubcategoriesData(subcats);
      const mappedSubcats = subcats.map(el => {
        return {
          value: el.title,
          label: el.title,
        };
      });
      mappedSubcats.sort((a, b) => {
        return a.value > b.value ? 1 : -1;
      });
      setSubcategories(mappedSubcats);
    }
  }, [parentCategory]);

  const fetchData = async () => {
    let categoriesData;
    /*try {
      categoriesData = await API.graphql(
        graphqlOperation(listKnowledgeBaseCategorys, {
          limit: 9999999,
          filter: {
            deleted: { eq: false },
          },
        })
      );
      const categories = [];
      if (categoriesData && categoriesData.data) {
        const { items } = categoriesData.data.listKnowledgeBaseCategorys;
        setCategoriesData(items);
        items.forEach(el => {
          if (el.title !== 'Uncategorized') {
            categories.push({ value: el.title, label: el.title });
          }
        });
      }
      categories.sort((a, b) => {
        return a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1;
      });
      setCategories(categories);
      if (
        !subcategories.length &&
        !subcategoriesData.length &&
        editInfo.subcategories
      ) {
        const newSubcats = editInfo.subcategories.map(el => el.name);
        setSubcategoriesData(newSubcats);
      }
      if (!parentCategory && editInfo.categoryName) {
        setParentCategory(editInfo.categoryName);
      }
    } catch (error) {
      console.log('listKnowledgeBaseCategorys error: ', error);
      alert('Error loading knowledge base form data');
    } finally {
      // add loading finished here
    }*/
  };

  const setEdit = editing => {
    /*let person = {
      asurite: tokens.asurite,
      timestamp: new Date().getTime().toString(),
    };
    let payload = {
      id: editInfo.id,
      editing: editing ? person : null,
    };
    API.graphql(
      graphqlOperation(mutations.updateKnowledgeBase, {
        input: payload,
      })
    );*/
  };

  const getStatusName = key => {
    if (typeof key === 'string') {
      key = parseInt(key);
    }
    if (key === 1) {
      return 'Pending';
    } else if (key === 2) {
      return 'Unapproved';
    } else if (key === 3) {
      return 'Approved';
    } else if (key === 4) {
      return 'Published';
    } else if (key === 5) {
      return 'Disabled';
    } else {
      return null;
    }
  };

  const getStatusKey = input => {
    const name = input;
    if (name === 'Pending') {
      return 1;
    } else if (name === 'Unapproved') {
      return 2;
    } else if (name === 'Approved') {
      return 3;
    } else if (name === 'Published') {
      return 4;
    } else if (name === 'Disabled') {
      return 5;
    } else {
      return null;
    }
  };

  const onChangeParent = e => {
    setParentCategory(e);
  };

  const categoryInputStateValue = () => {
    if (editInfo.categoryName && editInfo.subcategoryNames) {
      let stateValueX = {
        parent: editInfo.categoryName,
        sub: editInfo.subcategoryNames[0],
      };
      return stateValueX;
    } else {
      return null;
    }
  };

  const getStatusStateValue = () => {
    if (tokens.role === 'content creator') {
      return 'Pending';
    } else if (
      tokens.role === 'approver' &&
      editInfo &&
      editInfo.status === 4
    ) {
      return 'Approved';
    } else if (editInfo.status) {
      return getStatusName(editInfo.status);
    } else {
      return null;
    }
  };

  const generateUID = () => {
    let firstPart = (Math.random() * 46656) | 0;
    let secondPart = (Math.random() * 46656) | 0;
    firstPart = ('000' + firstPart.toString(36)).slice(-3);
    secondPart = ('000' + secondPart.toString(36)).slice(-3);
    return firstPart + secondPart;
  };

  const checkForSelfDupe = (questions, variations) => {
    let arr = [questions];
    let vars = variations ? variations.split(',') : [];

    arr = arr.concat(vars);
    let object = {};

    for (var i = 0; i < arr.length; ++i) {
      if (arr[i].length > 0) {
        if (!object[arr[i]]) {
          object[arr[i]] = 'taken';
        } else {
          console.log('WAS DUPE: ', arr[i]);
          return true;
        }
      }
    }

    return false;
  };

  const onSubmit = async (data, e) => {
    if (loadingFormSubmission) {
      return;
    }
    dispatchGlobal({
      type: 'setLoadingFormSubmission',
      loadingFormSubmission: true,
    });

    dispatchGlobal({
      type: 'setLoadingFormSubmission',
      loadingFormSubmission: false,
    });

    setMsgError('');
    /*let editedByInfo = editInfo.editedBy;
      editedByInfo.push({
        asurite: tokens.asurite,
        timestamp: moment().valueOf(),
      });

      newKBData.editedBy = editedByInfo;
      newKBData.editedByKey = tokens.asurite + '-' + generateUID();
      newKBData.id = editInfo.id;
      newKBData.submittedOn = editInfo.submittedOn;
      newKBData.submittedOnKey = editInfo.submittedOnKey;
      newKBData.updatedOn = moment().valueOf();
      newKBData.submittedBy = editInfo.submittedBy;
      newKBData.submittedByKey = editInfo.submittedByKey;
      try {
        newKB = await API.graphql(
          graphqlOperation(mutations.updateKnowledgeBase, {
            input: newKBData,
          })
        );
      } catch (error) {
        console.log('updateKnowledgeBase error: ', error);
      }*/

    if (data.group) {
      let answerData;
      if (data.flow) {
        answerData = JSON.parse(
          document.getElementsByName('answerData')[0].value
        );
      }
      let buttonTexts = document.getElementsByName('buttonText');

      try {
        let failure = false;

        if (
          answerData === undefined &&
          buttonTexts[0] &&
          buttonTexts[0].value !== ''
        ) {
          setMsgError('Please select a flow and a version.');
          failure = true;
        }

        typeof answerData !== 'undefined' &&
          Object.keys(answerData).map((key, index) => {
            console.log(answerData[key]);
            if (
              !answerData[key].flow ||
              !answerData[key].flowVersion ||
              answerData[key].flowVersion === 'null'
            ) {
              setMsgError('Please select a flow and a version.');
              failure = true;
            }
          });

        if (failure) {
          throw new Error('No flow or flowVersion');
        }

        let newIntro = {
          id: editInfo.id ? editInfo.id : idGenerator(),
          botType: JSON.parse(data.group).value,
          //"createdAt": new Date().toJSON(),
          createdBy: tokens.asurite,
          group: tokens.groups[0],
          deleted: false,
          matches: data.flow
            ? JSON.stringify(
                Object.keys(answerData).map((key, index) => {
                  let flowObject = JSON.parse(answerData[key].flow);
                  let flowVersionObject = JSON.parse(
                    answerData[key].flowVersion
                  );

                  flowObject =
                    flowObject === null ? { label: '', value: '' } : flowObject;
                  flowVersionObject =
                    flowVersionObject === null
                      ? { label: '', value: '' }
                      : flowVersionObject;

                  let matchObject = {};

                  matchObject.id = idGenerator();
                  matchObject.flowVersion = {
                    id: flowObject.value,
                    version: flowVersionObject.value,
                  };
                  matchObject.flowVersionLabel = {
                    flow: flowObject.label,
                    flowVersion: flowVersionObject.label,
                  };
                  matchObject.match = buttonTexts[index].value;
                  matchObject.type = 'intro';

                  return matchObject;
                })
              )
            : null,
          message: data.fallbackAnswer,
          status: getStatusKey(JSON.parse(data.status).value) + '',
          statusKey:
            getStatusKey(JSON.parse(data.status).value) + '-' + generateUID(),
          searchKey: data.fallbackAnswer.toLowerCase(),
          //"updatedAt": new Date().toJSON()
        };

        let prevVersions = await API.graphql(
          graphqlOperation(queries.introMessageHistoricalByIntroId, {
            introId: newIntro.id,
          })
        );

        prevVersions =
          prevVersions.data.introMessageHistoricalByIntroId.items.length + 1;

        let newIntroHistorical = Object.assign({}, newIntro);
        delete newIntroHistorical.searchKey;
        delete newIntroHistorical.group;
        newIntroHistorical.startedBy = newIntroHistorical.createdBy;
        delete newIntroHistorical.createdBy;
        newIntroHistorical.startTime = moment().format('MM/DD/YYYY HH:mm:ss');
        newIntroHistorical.versionId = prevVersions + '';
        newIntroHistorical.introId = newIntro.id;
        delete newIntroHistorical.id;
        if (typeof newIntroHistorical.deleted !== 'undefined') {
          delete newIntroHistorical.deleted;
        }
        if (newIntroHistorical.deletedBy) {
          delete newIntroHistorical.deletedBy;
        }
        newIntroHistorical.id = idGenerator();

        //console.log(newIntroHistorical);

        if (!editInfo.id) {
          await API.graphql(
            graphqlOperation(mutations.createChatbotIntroMessage, {
              input: newIntro,
            })
          );
        } else {
          await API.graphql(
            graphqlOperation(mutations.updateChatbotIntroMessage, {
              input: newIntro,
            })
          );
        }

        await API.graphql(
          graphqlOperation(mutations.createChatbotIntroMessageHistorical, {
            input: newIntroHistorical,
          })
        );

        if (newIntro.status === '4') {
          let removePublishedRow = null;

          const publishedRowsFromDynamo = await API.graphql(
            graphqlOperation(introMessageByStatus, {
              status: '4',
              filter: {
                botType: { eq: newIntro.botType },
                id: { ne: newIntro.id },
              },
            })
          );

          let allRows = publishedRowsFromDynamo.data.introMessageByStatus.items;

          for (var i = 0; i < allRows.length; ++i) {
            await API.graphql(
              graphqlOperation(mutations.updateChatbotIntroMessage, {
                input: {
                  id: allRows[i].id,
                  status: '5',
                },
              })
            );
          }
        }
        props.history.push({
          pathname: '/CustomIntroductions',
        });
        dispatchGlobal({
          type: 'setLoadingFormSubmission',
          loadingFormSubmission: false,
        });
      } catch (error) {
        console.log('createChatbotIntroMessage error: ', error);
        dispatchGlobal({
          type: 'setLoadingFormSubmission',
          loadingFormSubmission: false,
        });
      }
    }
  };

  const onSubmitUnapproval = data => {
    if (data.type !== 'submit') {
      axios
        .put(
          process.env.REACT_APP_APIGATEWAY_CHATBOT +
          '/chatbot-unapproval-email',
          {
            asurite: tokens.asurite,
            submittedBy: editInfo.submittedBy,
            editedBy: editInfo.editedBy ? editInfo.editedBy : null,
            emailBody:
              'reason: ' +
              data.reasonForRejection +
              ' message: ' +
              data.message,
            details: "knowledge base question '" + formValues.question + "'",
          }
        )
        .then(res => {
          console.log('onSubmitUnapproval res: ', res);
        })
        .catch(e => {
          console.log('onSubmitUnapproval error: ', e);
        });
      onSubmit(formValues);
    }
  };

  const createOnSubmit = data => {
    //console.log(Object.keys(data));
    /*dispatchGlobal({
      type: 'setCheckForErrors',
      checkForErrors: true,
    });*/

    onSubmit(data);
  };

  const createStatusOptions = () => {
    let statusOptions = [{ value: 'Pending', label: 'Pending' }];

    if (roleCheck(tokens.role, 'knowledgeBase', 'approveDenyContent')) {
      if (editInfo.id) {
        statusOptions.push({ value: 'Unapproved', label: 'Unapproved' });
      }
      statusOptions.push({ value: 'Approved', label: 'Approved' });
    }
    if (roleCheck(tokens.role, 'knowledgeBase', 'publishFAQ')) {
      statusOptions.push({ value: 'Published', label: 'Published' });
    }

    if (roleCheck(tokens.role, 'knowledgeBase', 'disableContent')) {
      statusOptions.push({ value: 'Disabled', label: 'Disabled' });
    }

    return statusOptions;
  };

  useEffect(() => {
    fetchEscalations();
  }, []);

  const getEscalationName = () => {
    if (!haveHit) {
      for (var i = 0; i < escalationData.length; ++i) {
        if (escalationData[i].value === editInfo.escalation) {
          setTimeout(() => {
            setHaveHit(true);
          }, 500);
          return {
            label: escalationData[i].label,
            value: escalationData[i].value,
          };
        }
      }
    } else {
      return 'IGNORE';
    }
  };

  const fetchEscalations = async () => {
    try {
      let filter = {
        deleted: { eq: false },
        status: { eq: 4 },
      };

      filter = {
        ...filter,
      };

      const result = await API.graphql(
        graphqlOperation(queries.listEscalations, {
          limit: 1000,
          filter: filter,
        })
      );

      let escalationNames = [];
      let results = result.data.listEscalations.items;
      escalationNames.push({ value: '', label: '-- No Escalation --' });
      if (results) {
        for (let i = 0; i < results.length; i++) {
          escalationNames.push({
            value: results[i].id,
            label: results[i].escalationName,
          });
        }
      }
      setHasEscalationData(true);
      setEscalationData(escalationNames);
      //setEscalationData(result.data.listEscalations.items);
    } catch (error) {
      console.log('fetchData error: ', error);
    } finally {
    }
  };

  let formData = [
    {
      title: 'MESSAGE',
      tooltip: 'Write a Message',
      required: true,
      component: (
        <Textarea
          form
          required
          name="fallbackAnswer"
          stateValue={editInfo.message ? editInfo.message : null}
          allowextras="image,emoji,imgGallery,links"
          isSMS
          style={{ display: 'flex', width: '100%' }}
        />
      ),
    },

    {
      title: 'STATUS',
      tooltip: 'Select a Status',
      required: true,
      component: (
        <Select
          form
          name="status"
          required
          placeholder="-- Select a Status --"
          isDisabled={tokens.role === 'content creator' ? true : false}
          options={createStatusOptions()}
          stateValue={getStatusStateValue()}
        />
      ),
    },

    {
      title: 'BOT ID',
      tooltip: 'Select a Bot ID',
      required: true,
      component: (
        <Select
          form
          name="group"
          required
          placeholder="-- Select a Bot ID --"
          options={botIDs}
          // options={selectListGroups}
          // stateValue={editInfo.group ? editInfo.group : null}
          updateStateValue={editInfo.botType}
        />
      ),
    },
    {
      title: 'BUTTON(S)',
      tooltip: 'Select an Option',
      component: (
        <ConditionInput
          type={'text'}
          answer
          stateValue={
            editInfo.matches
              ? JSON.parse(editInfo.matches).map(object => {
                  return {
                    answer: object.match,
                    flow: `{\"value\":\"${object.flowVersion.id}\",\"label\":\"${object.flowVersionLabel.flow}\"}`,
                    flowVersion: `{\"label\":\"${object.flowVersionLabel.flowVersion}\",\"value\":\"${object.flowVersion.version}\"}`,
                    audience: ['EV'],
                    audienceEveryone: true,
                    type: object.match,
                    answerEscalation: null,
                    image: '',
                  };
                })
              : null
          }
          //name="answer"
          /*stateValue={
            editInfo.matches
              ? JSON.parse(editInfo.matches).map(object => {
                  return {
                    answer: object.match,
                    flow: `{\"value\":\"${
                      object.flowVersion ? object.flowVersion.id : ''
                    }\",\"version\":\"${
                      object.flowVersion ? object.flowVersion.version : ''
                    }\"}`,
                    flowVersion: `{\"value\":\"${
                      object.flowVersionLabel
                        ? object.flowVersionLabel.flowVersion
                        : ''
                    }\",\"version\":\"${
                      object.flowVersion ? object.flowVersion.version : ''
                    }\"}`,
                  };
                })
              : null
          }*/
        />
      ),
    },
  ];

  const unapprovalFormData = () => {
    let emailList = [];
    emailList.push(tokens.asurite + '@asu.edu');
    emailList.push(editInfo.submittedBy + '@asu.edu');
    editInfo.editedBy &&
      editInfo.editedBy.forEach(el => {
        emailList.push(el.asurite + '@asu.edu');
      });

    const uniqueEmailList = Array.from(new Set(emailList));

    return [
      {
        component: (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                fontWeight: 400,
                marginLeft: '0.25rem',
              }}
            >
              <div>Message will be emailed to: </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {uniqueEmailList.map((el, index) => {
                  return (
                    <div
                      style={{
                        marginLeft: '0.25rem',
                      }}
                    >
                      {el}
                      {index !== uniqueEmailList.length - 1 && ','}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ),
      },
      {
        title: 'REASON FOR REJECTION',
        required: true,
        component: <Input form name="reasonForRejection" required />,
      },
      {
        title: 'MESSAGE',
        required: true,
        component: (
          <Textarea
            form
            required
            name="message"
            // stateValue={editInfo.emailBody ? editInfo.emailBody : ""}
          />
        ),
      },
    ];
  };

  const deleteButton = () => {
    if (props.deleteButton || formTitle === 'EDIT FAQ') {
      return true;
    } else {
      return false;
    }
  };

  const onClickDelete = () => {
    setModalContentType('deleteFAQ');
    toggle();
  };

  const onSubmitDelete = async () => {
    dispatchGlobal({
      type: 'setSpliceTableData',
      spliceTableData: { ...editInfo, type: 'delete' },
    });
    const updatedData = {
      id: editInfo.id,
      status: 6,
      deletedBy: { asurite: tokens.asurite, timestamp: moment().valueOf() },
    };
    try {
      // to show on current users side timeout

      const deleteOutput = await API.graphql(
        graphqlOperation(mutations.updateKnowledgeBase, {
          input: updatedData,
        })
      );
      if (
        deleteOutput &&
        deleteOutput.data &&
        deleteOutput.data.updateKnowledgeBase
      ) {
        props.history.push({
          pathname: '/CustomIntroductions',
        });
      }
    } catch (error) {
      console.log('updateKnowledgeBase error: ', error);
    } finally {
      dispatchGlobal({ type: 'setRefetchKey', refetchKey: Date.now() });
      props.setRerenderKey(Date.now());
    }
  };

  const runQuestion = e => {
    let value = e.target.value;
    if (value.length > 0) {
      setRunningQuestion(true);
      setMainQuestionStatusMsg('Checking for availability');
      let payload = {
        operation: 'runQuestion',
        inquiry: value,
        env: 'dev',
      };
      if (editInfo && editInfo.id) {
        payload.current = editInfo.id;
      }
      API.graphql(graphqlOperation(validateLexQuestion, payload)).then(resp => {
        try {
          let responseData = resp.data.validateLexQuestion;
          if (responseData.isSelf === true) {
            setMainQuestionStatusMsg('Question is already in current FAQ');
            setMainQuestionStatus('success');
          } else if (responseData.isTaken) {
            setMainQuestionStatusMsg('Question is not available');
            setMainQuestionStatus('error');
          } else {
            setMainQuestionStatusMsg('Question is available!');
            setMainQuestionStatus('success');
          }
        } catch (err) {
          console.log('There was an error checking the question', err);
          setMainQuestionStatusMsg(
            'There was a critical error checking the question'
          );
          setMainQuestionStatus('error');
        }
        setTimeout(() => {
          setRunningQuestion(false);
          setMainQuestionStatusMsg('');
          setMainQuestionStatus('');
        }, 2000);
      });
    }
  };

  const baseModal = text => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: 'fit-content',
          padding: '0.5rem',
          borderRadius: '4px',
        }}
      >
        <H4 style={{ margin: 0 }}>Error</H4>
        <p style={{ ...theme.typography.subtitle1 }}>{text}</p>
      </div>
    );
  };

  const toTitleCase = text => {
    text = text
      .toLowerCase()
      .split(' ')
      .map(s => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    return text;
  };

  const modalContent = () => {
    if (modalContentType === 'unapproval') {
      return (
        <ModalContent
          confirmation={null}
          form={true}
          formData={unapprovalFormData()}
          title="Unapproval reason"
          onSubmit={onSubmitUnapproval}
          toggle={toggle}
        />
      );
    } //else if (modalContentType === "audienceRoles") {
    //return baseModal("Audience roles can't overlap across answers");
    else if (modalContentType === 'modalityType') {
      return baseModal(
        'The same modality types cant include the same audience type!'
      );
    } else if (modalContentType === 'duplicateSubcategory') {
      return baseModal('This subcategory already exists');
    } else if (modalContentType === 'selfDupe') {
      return baseModal('Questions and variations cannot overlap');
    } else if (modalContentType === 'invalidReaction') {
      return baseModal(
        'iOS Reactions only support the following: ' +
          validIosReactions.toString()
      );
    } else if (modalContentType === 'deleteFAQ') {
      return (
        <ModalContent
          confirmation={true}
          form={null}
          formData={null}
          title="Confirm delete FAQ"
          onSubmit={onSubmitDelete}
          toggle={toggle}
        />
      );
    } else {
      return null;
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setQuestRunnerError(false);
  };

  const Alert = props => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  if (!attachedEvent) {
    setAttachedEvent(true);
    setTimeout(() => {
      let item = document.getElementById('question');
      if (item) {
        item.addEventListener('focusout', runQuestion);
      }
    }, 250);
  }

  return (
    <div
      key={props.rerenderKey}
      style={
        props.noFormTitle
          ? { display: 'flex', flexDirection: 'column', width: '100%' }
          : {
              borderLeft: `1px solid ${theme.palette.general.mediumGrey}`,
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              backgroundColor: 'white',
            }
      }
    >
      <div
        style={props.noFormTitle ? {} : { padding: '2rem', width: '75%' }}
        key={props.rerenderKey}
      >
        <Form
          title={editInfo.id ? 'EDIT FAQ' : 'Add/Edit Custom Introduction'}
          data={formData}
          onSubmit={createOnSubmit}
          noTitle={props.noFormTitle}
          deleteButton={deleteButton()}
          onClickDelete={onClickDelete}
        />
        {msgError ? <ErrorLabel message={msgError} /> : null}
        <Modal
          isShowing={isShowing}
          hide={toggle}
          content={modalContent()}
          style={
            modalContentType !== 'unapproval'
              ? {
                  color: theme.palette.general.errorColor,
                  width: 'fit-content',
                  backgroundColor: 'white',
                }
              : null
          }
        />
      </div>
    </div>
  );
}
