import moment from 'moment-timezone';

export function parseOutResponses(
  userInputs,
  listOfCompleted,
  listOfRunning,
  listOfPending,
  sendLevelErrors,
  twilioLevelErrors,
  campaignMessages,
  flowId,
  flowVersion,
  getQueryableData
) {
  return new Promise(async function(resolve, reject) {
    let dataObj = {};

    dataObj.campaignAnalyticsOverview = {
      clicks: userInputs.length ? userInputs.length : '0',
    };

    let completedCount = listOfCompleted.filter(object => {
      if (
        object.exitType !== 'error' &&
        !object.expiredBeforeSend &&
        !object.isManualCamp
      ) {
        return true;
      }
    }).length;

    let pendingCount = listOfPending.length;
    let runningCount = listOfRunning.length;
    let campaignsCount = listOfCompleted.length;
    let campaignsRaw = listOfCompleted;
    let runningRaw = listOfRunning;

    let manualCount = [0, 0];

    for (var i = 0; i < campaignsRaw.length; ++i) {
      if (campaignsRaw[i].isManualCamp) {
        --campaignsCount;
        ++manualCount[1];
        if (
          campaignsRaw[i].exitType !== 'error' &&
          !campaignsRaw[i].expiredBeforeSend
        ) {
          ++manualCount[0];
        }
      }
    }

    for (var i = 0; i < runningRaw.length; ++i) {
      if (runningRaw[i].isManualCamp) {
        ++manualCount[1];
        ++manualCount[0];
      }
    }

    dataObj.campaignAnalyticsOverview.delivered = `${runningCount +
      completedCount} of ${campaignsCount + runningCount + pendingCount}`;

    dataObj.campaignAnalyticsOverview.inQueue = `${pendingCount}`;

    dataObj.campaignAnalyticsOverview.manualDelivers = `${manualCount[0]} of ${manualCount[1]}`;

    dataObj.campaignAnalyticsResponses = {
      userInputs,
      nodes: [],
      queryableData: [],
      foundDisplayNames: {},
    };

    dataObj.campaignAnalyticsResponses.sendLevelErrors = sendLevelErrors;
    dataObj.campaignAnalyticsResponses.twilioLevelErrors = twilioLevelErrors;
    dataObj.campaignAnalyticsResponses.campaignMessages = campaignMessages;

    userInputs.forEach(userInput => {
      if (
        !dataObj.campaignAnalyticsResponses.nodes.includes(userInput.nodeId)
      ) {
        dataObj.campaignAnalyticsResponses.nodes.push(userInput.nodeId);
      }
    });

    //* Querying flow data
    dataObj.campaignAnalyticsResponses.queryableData = await getQueryableData(
      flowId,
      flowVersion
    );

    /* Build Accordions */
    let newResponseAccordions = null;
    if (dataObj.campaignAnalyticsResponses.queryableData) {
      newResponseAccordions = dataObj.campaignAnalyticsResponses.queryableData.map(
        node => {
          const summary = {
            question: node.text,
            type: node.type,
          };

          const responses = node.matches
            ? node.matches.filter(match => match.match !== 'NO MATCH')
            : [];

          let responseCount = {};

          dataObj.campaignAnalyticsResponses.userInputs.forEach(input => {
            let matchId = input.button;
            if (responseCount[matchId]) responseCount[matchId].count += 1;
            else responseCount[matchId] = { count: 1, nodeId: input.nodeId };
          });

          const responsesWithNumbers = [];

          for (var i = 0; i < responses.length; ++i) {
            responsesWithNumbers.push({
              label: responses[i].match.toLowerCase(),
              count: responseCount[responses[i].id]
                ? responseCount[responses[i].id].count
                : 0,
            });
          }

          let promptInput = dataObj.campaignAnalyticsResponses.userInputs.reduce(
            (finalString, object) => {
              if (object.type === 'prompt' && node.id === object.nodeId) {
                return finalString.length > 0
                  ? finalString + '\n' + object.inputDump
                  : finalString + object.inputDump;
              } else {
                return finalString + '';
              }
            },
            ''
          );

          let total =
            summary.type === 'prompt'
              ? dataObj.campaignAnalyticsResponses.userInputs.reduce(
                  (totalResponses, object) => {
                    if (object.type === 'prompt' && node.id === object.nodeId) {
                      return totalResponses + 1;
                    } else {
                      return totalResponses;
                    }
                  },
                  0
                )
              : calculateTotal(node.id, responseCount);

          return {
            summaryQuestion: summary.question,
            summaryType: summary.type,
            responsesWithNumbers: responsesWithNumbers,
            type: summary.type === 'prompt' ? 'textarea' : 'count',
            promptInput: promptInput,
            total: total,
          };
        }
      );
    }

    dataObj.responseAccordions = newResponseAccordions;
    resolve(dataObj);
  });
}

const calculateTotal = (id, countsObj) => {
  let total = { nodeId: id, count: 0 };

  for (const match in countsObj) {
    if (id === countsObj[match]['nodeId']) {
      total.count += countsObj[match].count;
    }
  }
  return total.count;
};

function getItemFromDict(x, dict) {
  return dict[x] ? dict[x] : 'N/A';
}

function groupByKey(info, key) {
  let dict = {};

  for (var i = 0; i < info.length; ++i) {
    if (!dict[info[i][key]]) {
      dict[info[i][key]] = [];
    }

    dict[info[i][key]].push(info[i]);
  }

  return dict;
}

export const getCsvData = (
  panelSectionData,
  tokens,
  importSummaryData,
  userDictionary
) => {
  let {
    queryableData: queryableDataResponse,
    userInputs,
    sendLevelErrors,
    twilioLevelErrors,
    campaignMessages,
  } = panelSectionData.campaignAnalyticsResponses;

  let usersToFind = [];
  let csvData = [
    [
      'FirstName',
      'LastName',
      'UserPhone',
      'ASUFailureCode',
      'OptOut',
      'TotalMessageSendSuccessful',
      'TotalMessageSendFailure',
      'TwilioErrorCode',
      'Date/Time',
    ],
  ];
  const isNonSunnyUser = false;
  if (!isNonSunnyUser) {
    csvData[0].unshift('EMPLID');
  }

  let twilioErrorsByUser = groupByKey(twilioLevelErrors, 'convoId');
  let asuErrorsByUser = groupByKey(sendLevelErrors, 'userId');
  let userInputsByUser = groupByKey(userInputs, 'userId');
  let messagesByUser = groupByKey(campaignMessages, 'convoId');
  let nodesById = groupByKey(queryableDataResponse, 'id');

  let qMax = 0;
  for (var key in userInputsByUser) {
    if (userInputsByUser[key].length > qMax) {
      qMax = userInputsByUser[key].length;
    }
  }

  for (let j = 0; j < qMax; j++) {
    csvData[0].push(`Question ${j + 1}`);
    csvData[0].push(`Question ${j + 1} Node Type`);
    csvData[0].push(`Question ${j + 1} Response`);
    csvData[0].push(`Question ${j + 1} Button Match`);
  }

  let users = Object.keys(userDictionary);

  for (var i = 0; i < users.length; ++i) {
    let userItem = userDictionary[users[i]];

    let row = getRowForUser(
      userItem,
      twilioErrorsByUser[userItem.convoId],
      asuErrorsByUser[users[i]],
      userInputsByUser[users[i]],
      messagesByUser[userItem.convoId],
      nodesById,
      tokens,
      csvData[0]
    );

    csvData.push(row);
  }

  return csvData;
};

function getRowForUser(
  userItem,
  twilioErrors = [],
  asuErrors = [],
  userInput = [],
  messages = [],
  nodes,
  tokens,
  headers
) {
  let userCSVRow = [];

  userInput = userInput.sort(function(a, b) {
    return parseInt(a.timestamp) - parseInt(b.timestamp);
  });

  let asuFailure = '';
  let twilioFailure = '';

  if (asuErrors && asuErrors[0]) {
    if (asuErrors[0].error && asuErrors[0].error.indexOf('278-') > -1) {
      asuFailure = asuErrors[0].error;
    } else {
      twilioFailure = asuErrors[0].error;
    }
  }

  let date = '';

  if (messages && messages[0]) {
    if (messages[0].createdAt) {
      date = messages[0].createdAt;
    } else if (messages[0].latest) {
      date = moment(new Date(messages[0].latest)).format('MM/DD/YY hh:mma');
    }
  }

  let userObj = {
    EMPLID: getItemFromDict('emplid', userItem),
    FirstName: getItemFromDict('firstName', userItem),
    LastName: getItemFromDict('lastName', userItem),
    CRMID: getItemFromDict('crmId', userItem),
    ASURITE: getItemFromDict('asurite', userItem),
    UserPhone: getItemFromDict('phoneNumber', userItem)
      ? getItemFromDict('phoneNumber', userItem)
      : getItemFromDict('homeNumber', userItem),
    ASUFailureCode: asuFailure,
    OptOut: asuFailure === '278-0003',
    TotalMessageSendSuccessful: messages.length,
    TotalMessageSendFailure: twilioErrors.length + asuErrors.length,
    TwilioErrorCode: twilioFailure,
    'Date/Time': date,
  };

  for (var i = 0; i < userInput.length; ++i) {
    let node = nodes[userInput[i].nodeId][0];

    let buttonText = null;

    if (node.matches) {
      for (var j = 0; j < node.matches.length; ++j) {
        if (node.matches[j].id === userInput[i].button) {
          buttonText = node.matches[j].match;
          break;
        }
      }
    }

    userObj[`Question ${i + 1}`] = node.text;
    userObj[`Question ${i + 1} Node Type`] = userInput[i].type;
    userObj[`Question ${i + 1} Response`] = userInput[i].inputDump;
    userObj[`Question ${i + 1} Button Match`] = buttonText;
  }

  for (var i = 0; i < headers.length; ++i) {
    userCSVRow.push(userObj[headers[i]]);
  }

  return userCSVRow;
}

const checkForResponse = queryableDataResponse => {
  return (
    typeof queryableDataResponse !== 'undefined' &&
    queryableDataResponse !== null &&
    queryableDataResponse.length > 0
  );
};

const getButtonMatch = (matchId, matches) => {
  let name = '';

  for (var i = 0; i < matches.length; ++i) {
    if (matches[i].id === matchId) {
      name = matches[i].match;
      break;
    }
  }

  return name;
};
