import React from "react";
import { makeStyles } from "@material-ui/core/styles";

export default function InputSubtext(props) {
  const useStyles = makeStyles(theme => ({
    subtitle: {
      ...theme.typography.subtitle2,
      margin: "-5px 0 0.5rem 0",
      color: "#3a3a3a",
      fontSize: "0.95rem",
      lineHeight: "1.2rem"
    }
  }));
  const classes = useStyles();

  return (
    <div {...props} className={classes.subtitle}>
      {props.subtext}
    </div>
  );
}
