import React from "react";
import Variations from "./Variations";
import VariationsForm from "./VariationsForm";

export default function VariationsX(props) {
  if (props.form) {
    return <VariationsForm {...props} />;
  } else {
    return <Variations {...props} />;
  }
}
