import React, { useState, useEffect, useContext } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import AuthContext from 'contexts/AuthContext';
import CallSplitIcon from '@material-ui/icons/CallSplit';

import { InputLabel, Select } from 'stories';
import * as queries from 'graphql/queries';
import { store as GlobalStore } from 'contexts/GlobalStore';

import '../forms.css';
import Checkbox from '../Checkbox/Checkbox';

const VERSION_ID_STARTER = '1-1';

let setupFlowData = false;

let editInfo = { empty: true };

export default function FlowSelect(props) {
  const { register } = useFormContext();
  const GlobalState = useContext(GlobalStore);
  // const dispatchGlobal = GlobalState.dispatch;
  const tokens = useContext(AuthContext);

  if (!props.noDefaultState) {
    editInfo = GlobalState.state.editInfo;
  }

  const { type, ignoreFlowIds } = props;
  const history = useHistory();

  const getType = (t, i) => {
    try {
      return JSON.parse(t)[i];
    } catch (e) {
      return t;
    }
  };

  const getMoreFlows = async (filter, foundFlows, nextToken) => {

    let moreFlows = await API.graphql(
      graphqlOperation(queries.listFlows, {
        filter: filter,
        limit: 500,
        nextToken: nextToken
      })
    );

    console.log("moreFlows is", moreFlows);

    if (moreFlows.data.listFlows.items) {
      foundFlows = [
        ...foundFlows,
        ...moreFlows.data.listFlows.items
      ]
    }

    let previousNextToken = nextToken;

    if (moreFlows.data.listFlows.nextToken !== null) {
      nextToken = moreFlows.data.listFlows.nextToken;
    }

    //console.log(previousNextToken !== nextToken, previousNextToken, nextToken);

    if (nextToken !== null && previousNextToken !== nextToken) {
      getMoreFlows(filter, foundFlows, nextToken);
    } else {
      //console.log("multi query flows", foundFlows);
      console.log("getMoreFlows nextToken is null or same");
      console.log("getMoreFlows: foundFlows last entry title is", foundFlows[foundFlows.length - 1].title);
      if (foundFlows[foundFlows.length - 1].title !== "-- No Flow --") {
        foundFlows.sort((a, b) =>
          a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
        );

        foundFlows.push({
          title: '-- No Flow --',
          id: '',
        });
      }
      setFlows(foundFlows);
    }
  };

  const [flows, setFlows] = useState([]);
  const [flowReset, setFlowReset] = useState(false);
  const [flowVersionReset, setFlowVersionReset] = useState(false);

  const [refreshKey, setRefreshKey] = useState(new Date().getTime());

  const [flow, setFlow] = useState(null);
  const [flowVersion, setFlowVersion] = useState(
    editInfo ? editInfo.flowVersion : null
  );

  const [flowVersions, setFlowVersions] = useState(null);
  const [lastType, setLastType] = useState('sms');
  const [useLatestVersion, setUseLatestVersion] = useState(false);

  useEffect(() => {
    if (editInfo && editInfo.flow && !props.noDefaultState) {
      setFlow(editInfo.flow);
      initVersions(editInfo.flow.value);
    } else if (
      props.validProps &&
      props.validProps.data &&
      props.validProps.data[props.validProps.uid] &&
      props.validProps.data[props.validProps.uid].flow &&
      props.validProps.data[props.validProps.uid].flowVersion &&
      !props.noDefaultState
    ) {
      try {
        props.setData(prevData => ({
          ...prevData,
          flow: props.validProps.data[props.validProps.uid].flow,
          flowVersion: props.validProps.data[props.validProps.uid].flowVersion,
          useLatestVersion:
            props.validProps.data[props.validProps.uid].useLatestVersion,
        }));
        setFlow(JSON.parse(props.validProps.data[props.validProps.uid].flow));
        initVersions(
          JSON.parse(props.validProps.data[props.validProps.uid].flow).value
        );
      } catch (error) {
        console.log('formSelect init error: ', error);
      }
    } else {
      setFlowReset(true);
      setFlowVersionReset(true);
      setFlow(null);
    }
  }, [editInfo]);

  const fetchFlows = async () => {
    let filter = { version: { ge: '1-100000002' }, deleted: { eq: false } };

    filter = {
      ...filter,
    };

    if (tokens.role === 'approver' || tokens.role === 'content creator') {
      filter.or = [];
      tokens.groups.forEach(element => {
        filter.or.push({ group: { eq: element } });
      });
    }

    const flows = await API.graphql(
      graphqlOperation(queries.listFlows, {
        filter: filter,
        limit: 10000,
      })
    );

    let foundFlows = flows.data.listFlows.items;
    let nextToken = flows.data.listFlows.nextToken;

    //console.log("flows is", flows);

    if (nextToken !== null) {
      foundFlows = getMoreFlows(filter, foundFlows, nextToken);
    } else {
      //console.log("1 query flows", foundFlows);
      console.log("fetchFlows: nextToken is null");
      console.log("fetchFlows: foundFlows last entry title is", foundFlows[foundFlows.length - 1].title);
      if (foundFlows[foundFlows.length - 1].title !== "-- No Flow --") {
        foundFlows.sort((a, b) =>
          a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
        );

        foundFlows.push({
          title: '-- No Flow --',
          id: '',
        });
      }
      setFlows(foundFlows);
    }

  };

  useEffect(() => {
    // if (props.type) {
    fetchFlows();
    if (lastType !== null && lastType !== props.type) {
      setFlowReset(true);
      setFlowVersionReset(true);
    }
    // }
  }, [props.type]);

  const directToFlow = () => {
    let id = null;
    let fType = null;

    console.log('HITTTING: ', flow, type, flowVersion);

    if (typeof flow === 'string') {
      id = getType(flow, 'value');
    } else {
      id = flow.value;
    }

    let value =
      typeof flowVersion === 'string'
        ? JSON.parse(flowVersion).value
        : flowVersion.value;

    history.push({
      pathname: '/view-flow',
      state: {
        flowId: id,
        flowVersion: value,
        viewOnly: true,
      },
    });
  };

  const initVersions = async id => {
    let queryVersions = await API.graphql(
      graphqlOperation(queries.listFlowVersions, {
        id: id,
        version: { beginsWith: VERSION_ID_STARTER },
        filter: { published: { eq: true }, disabled: { ne: true } },
        sortDirection: 'DESC',
        limit: 15,
      })
    );
    let items = queryVersions.data.listFlowVersions.items;

    console.log('VERSIONS: ', items);

    let flowOptions = [];

    if (props.selectType === 'autoTag') {
      // flowOptions = parseOutQueryableData(items);
    } else {
      flowOptions = parseOutVersions(items);
    }

    if (window.location.href.includes('CustomIntro')) {
      setFlowVersions([
        { label: 'LATEST', value: '1-100000000' },
        ...flowOptions,
      ]);
    } else {
      setFlowVersions([
        { label: 'LATEST', value: '1-100000000' },
        ...flowOptions,
      ]);
    }

    setRefreshKey(new Date().getTime());
  };

  const parseOutVersions = items => {
    let flowOptions = [];

    for (var i = 0; i < items.length; ++i) {
      let parsedVNum = items[i].version;

      try {
        parsedVNum = items[i].version.split(VERSION_ID_STARTER)[1];
        parsedVNum = parseInt(parsedVNum);
      } catch (e) {}

      let label =
        (items[i].title ? items[i].title : '') +
        ' (Version ' +
        (parsedVNum ? parsedVNum : items[i].version) +
        ')';

      flowOptions.push({
        label: label,
        value: items[i].version,
      });
    }

    return flowOptions;
  };

  useEffect(() => {
    // console.log("FLOW VERSION CHANGED: ", flowVersion);
  }, [flowVersion]);

  const updateFlow = e => {
    editInfo.flow = null;
    editInfo.flowVersion = null;
    console.log('UPDATING FLOW: ', e);
    if (props.updateFlow) {
      console.log('HAVE PROPS UPDATE');
      props.updateFlow(e);
    }

    if (e.value) {
      setFlow(e);
      initVersions(e.value);
      setFlowVersion(null);
      setFlowVersionReset(true);
      updateFlowVersion(null);
    } else {
      setFlow(null);
      setFlowVersion(null);
      setFlowReset(true);
      setFlowVersionReset(true);
      updateFlowVersion(null);
    }
  };

  const updateFlowVersion = e => {
    if (props.updateFlowVersion) {
      props.updateFlowVersion(e);
    }
    setFlowVersion(e);
  };

  const mapFlows = () => {
    const mappedFlows = flows.map(el => {
      return { value: el.id, label: el.title };
    });
    return mappedFlows;
  };

  const getFlowVersion = () => {
    if (editInfo.flowVersion) {
      try {
        return JSON.parse(editInfo.flowVersion);
      } catch (e) {
        return editInfo && editInfo.flowVersion ? editInfo.flowVersion : null;
      }
    } else if (
      props.validProps &&
      props.validProps.data &&
      props.validProps.data[props.validProps.uid]
    ) {
      try {
        return JSON.parse(
          props.validProps.data[props.validProps.uid].flowVersion
        );
      } catch (e) {
        return props.validProps.data &&
          props.validProps.data[props.validProps.uid]
          ? props.validProps.data[props.validProps.uid].flowVersion
          : null;
      }
    } else {
      return flowVersion;
    }
  };

  const getFlow = () => {
    if (editInfo.flow) {
      try {
        return JSON.parse(editInfo.flow);
      } catch (e) {
        return editInfo && editInfo.flow ? editInfo.flow : null;
      }
    } else if (
      props.editInfo &&
      !props.editInfo.empty &&
      props.validProps &&
      props.validProps.data &&
      props.validProps.data[props.validProps.uid]
    ) {
      try {
        return JSON.parse(props.validProps.data[props.validProps.uid].flow);
      } catch (e) {
        return props.validProps.data &&
          props.validProps.data[props.validProps.uid]
          ? props.validProps.data[props.validProps.uid].flow
          : null;
      }
    } else {
      return flow;
    }
  };

  return (
    <div key={refreshKey} className="flowComponent">
      <Select
        form={props.form}
        required={props.required}
        name="flow"
        onChange={updateFlow}
        menuPlacement={props.menuPlacement ? props.menuPlacement : 'bottom'}
        reset={flowReset}
        setReset={setFlowReset}
        options={mapFlows()}
        stateValue={getFlow()}
        disabled={props.disabled ? true : false}
      />

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          height: '1px',
        }}
      >
        <div style={{ display: 'flex', flex: 1 }} />

        <input
          {...props.validProps}
          name={'flowAnswer ' + props.propertyID}
          ref={props.required ? register({ required: true }) : register}
          value={getFlow()}
          readOnly
          autoComplete="off"
          style={{
            opacity: 0,
            height: '1px',
            width: '1px',
            cursor: 'default',
          }}
        />

        <div style={{ display: 'flex', flex: 1 }} />
      </div>
      <div style={{ marginTop: '10px' }}>
        {flow && !useLatestVersion ? (
          <div>
            <Select
              form={props.form}
              required={true}
              menuPlacement={
                props.menuPlacement ? props.menuPlacement : 'bottom'
              }
              name="flowVersion"
              placeholder="-- Select a version --"
              onChange={updateFlowVersion}
              sidebutton={
                flowVersion && flowVersion.label !== 'LATEST' ? (
                  <CallSplitIcon />
                ) : null
              }
              sidebuttonaction={directToFlow}
              reset={flowVersionReset}
              setReset={setFlowVersionReset}
              options={flowVersions}
              stateValue={getFlowVersion()}
              disabled={props.disabled ? true : false}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                height: '1px',
              }}
            >
              <div style={{ display: 'flex', flex: 1 }} />

              <input
                {...props.validProps}
                name={'flowVersionAnswer ' + props.propertyID}
                ref={props.required ? register({ required: true }) : register}
                value={getFlowVersion()}
                readOnly
                autoComplete="off"
                style={{
                  opacity: 0,
                  height: '1px',
                  width: '1px',
                  cursor: 'default',
                }}
              />
              <div style={{ display: 'flex', flex: 1 }} />
            </div>
          </div>
        ) : null}
      </div>
      <input type="text" value="" style={{ display: 'none', height: '1px' }} />
    </div>
  );
}

FlowSelect.propTypes = {
  navigation: PropTypes.object,
};
