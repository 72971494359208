import React, { useState, useEffect, useContext } from 'react';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { SectionHeader } from 'components/SectionHeader';
import AuthContext from 'contexts/AuthContext';
import { roleCheck } from 'services/utility/RoleMatrix';
import { store } from 'contexts/GlobalStore';

import {
  Form,
  Input,
  Textarea,
  CreateNewButton,
  UploadFile,
  Select,
} from 'stories';

import { getUserGroups } from 'hooks';

import { API, graphqlOperation, Storage } from 'aws-amplify';
import { createImage, updateImage } from 'graphql/mutations';
Storage.configure({ level: 'public' });

export default function AddImage(props) {
  const theme = useTheme();
  const tokens = useContext(AuthContext);
  const GlobalState = useContext(store);
  const dispatchGlobal = GlobalState.dispatch;
  const { dispatch } = GlobalState;
  const { editInfo } = GlobalState.state;
  const { keyedNames, selectListGroups, getGroup } = getUserGroups();

  const [imgUploadData, setImgUploadData] = useState(null);

  const [imageEdited, setImageEdited] = useState(false);
  const [uploadFileLoaded, setUploadFileLoaded] = useState(
    !editInfo.empty ? true : false
  );
  // const [initialImage, setInitialImage] = useState(null);
  // let initialImage = editInfo.empty ? null : editInfo.image;

  useEffect(() => {
    if (!editInfo.empty) {
      // console.log("editInfo Here", editInfo, editInfo.imageId.split("/")[2]);
      showEditImagePreview(editInfo.imageId);
    }
  }, []);

  useEffect(() => {
    console.log('uploadFileLoaded', uploadFileLoaded);
    if (!uploadFileLoaded) {
      setImgUploadData(false);
    }
  }, [uploadFileLoaded]);

  useEffect(() => {
    console.log(imgUploadData);
    if (imgUploadData && !imageEdited) {
      if (!editInfo.empty && imgUploadData.fileType) {
        // if imgUploadData.fileType, then a new image has been loaded
        setImageEdited(true);
      }
    }
  }, [imgUploadData]);

  // useEffect(() => {
  //   console.log(imageEdited);
  // }, [imageEdited]);

  const showEditImagePreview = async id => {
    const arr = id.split('|');
    const url = await Storage.get(arr[1], { bucket: arr[0] });
    setImgUploadData({ file: url });
  };

  const formData = [
    {
      title: 'IMAGE TITLE',
      required: true,
      component: (
        <Input
          form
          name="title"
          id="image-title"
          required
          stateValue={editInfo.title ? editInfo.title : ''}
        />
      ),
    },
    {
      title: 'GROUP',
      required: true,
      component: (
        <Select
          form
          name="group"
          id="group"
          required
          placeholder="-- Select a Group --"
          options={selectListGroups}
          // stateValue={editInfo.group ? editInfo.group : null}
          updateStateValue={
            tokens.role === 'publisher' && !editInfo.group
              ? getGroup(tokens.groups[0])
              : getGroup(editInfo.group)
              ? getGroup(editInfo.group)
              : null
          }
        />
      ),
    },
    {
      title: 'DESCRIPTION',
      required: true,
      component: (
        <Textarea
          form
          name="description"
          id="description"
          required
          stateValue={editInfo.description ? editInfo.description : ''}
        />
      ),
    },
    {
      title: editInfo.empty ? 'Image' : 'Update Image',
      required: true,
      component: (
        <UploadFile
          form
          csvUpload={false}
          required
          env={tokens.env}
          setImgUploadData={setImgUploadData}
          setUploadFileLoaded={setUploadFileLoaded}
          updateImg={!editInfo.empty ? true : false}
          uploadText={true}
          noDownload={true}
          //   name="targetAudience"
          placeholder="Upload an Image"
          //   campaigntype={type}
          //   accept=".csv"
          fileName={editInfo.imageId ? editInfo.imageId.split('/')[2] : null}
          //   fileContent={editInfo ? editInfo.audienceFileContent : null}
          //   csvFileAdded={csvFileAdded}
          didReset={e => setUploadFileLoaded(false)}
        />
      ),
    },
  ];

  const addImageOnSubmit = async e => {
    if (e.nativeEvent && !GlobalState.state.loadingFormSubmission) {
      dispatchGlobal({
        type: 'setLoadingFormSubmission',
        loadingFormSubmission: true,
      });

      let dataInput = {
        title: e.nativeEvent.target[0].value,
        group: JSON.parse(e.nativeEvent.target[4].value).value,
        description: e.nativeEvent.target[5].value,
        upload: e.nativeEvent.target[8].value,
      };
      // console.log(uploadFileLoaded)
      if (
        dataInput.title.length <= 0 ||
        dataInput.group.length <= 0 ||
        dataInput.description.length <= 0 ||
        !uploadFileLoaded
      ) {
        console.log('missing field');
      } else {
        let payload = {
          imageId: await uploadFile(imgUploadData.fileType, imgUploadData.file),
          title: dataInput.title,
          description: dataInput.description,
          group: dataInput.group,
          author: tokens.asurite,
          deleted: false,
        };
        console.log(payload);

        if (payload.imageId) {
          try {
            const addImage = await API.graphql(
              graphqlOperation(createImage, { input: payload })
            );
            console.log('Successful upload: ', addImage.data.createImage);

            dispatchGlobal({
              type: 'setLoadingFormSubmission',
              loadingFormSubmission: false,
            });
            props.history.push({
              pathname: '/image-gallery',
            });
          } catch (err) {
            console.log('Error adding image to dyanmo: ' + JSON.stringify(err));
          }
        } else {
          console.log('Error uploadFile returning falsy: ' + payload.imageId);
        }
      }
    }
  };

  const updateImageOnSubmit = async e => {
    // console.log(e.nativeEvent);
    if (e.nativeEvent && !GlobalState.state.loadingFormSubmission) {
      dispatchGlobal({
        type: 'setLoadingFormSubmission',
        loadingFormSubmission: true,
      });

      let dataInput = {
        title: e.nativeEvent.target[0].value,
        group: JSON.parse(e.nativeEvent.target[4].value).value,
        description: e.nativeEvent.target[5].value,
        upload: e.nativeEvent.target[8].value,
      };
      if (
        dataInput.title.length <= 0 ||
        dataInput.group.length <= 0 ||
        dataInput.description.length <= 0 ||
        !uploadFileLoaded
      ) {
        console.log('missing field', dataInput, uploadFileLoaded);
      } else {
        let payload = {
          id: editInfo.id,
          imageId: !imageEdited
            ? editInfo.imageId
            : await uploadFile(imgUploadData.fileType, imgUploadData.file),
          title: dataInput.title,
          description: dataInput.description,
          group: dataInput.group,
          // author: tokens.asurite,
        };
        console.log(payload, imageEdited);

        try {
          const updatingImage = await API.graphql(
            graphqlOperation(updateImage, { input: payload })
          );
          console.log('Successful update: ', updatingImage.data.updateImage);

          //removes previous image only if image was updated
          if (imageEdited) {
            const previousImageStr = editInfo.imageId.split('|');
            const previousImage = await Storage.remove(previousImageStr[1], {
              bucket: previousImageStr[0],
            });
          }
          dispatchGlobal({
            type: 'setLoadingFormSubmission',
            loadingFormSubmission: false,
          });

          props.history.push({
            pathname: '/image-gallery',
          });
        } catch (err) {
          console.log('Error updating image: ' + err);
        }
      }
    }
  };

  const uploadFile = async (contentType, file) => {
    let ranId = idGenerator();
    let fileExt = contentType.split('/')[1];

    let fileData = file + '';
    let imgData = file.split(',')[1];
    fileData = new Buffer.from(imgData, 'base64');

    // let type = props.type ? props.type : "public";

    let options = {
      bucket:
        'chatbot-dashboard-assets' + (tokens.env === 'truedev' ? '-dev' : ''),
      contentType: contentType,
    };

    let filePath = 'imageGallery/' + tokens.env + '/' + ranId + '.' + fileExt;

    let isUploadSuccessful;

    await Storage.put(filePath, fileData, options)
      .then(res => (isUploadSuccessful = true))
      .catch(err => {
        console.log('Error uploading image to S3' + err);
        isUploadSuccessful = false;
      });

    if (isUploadSuccessful) {
      return options.bucket + '|' + filePath;
    } else return false;
  };

  const idGenerator = () => {
    var S4 = function() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (
      S4() +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      S4() +
      S4()
    );
  };

  const ImagePreview = props => {
    return (
      <img
        src={props.src}
        style={{ marginTop: '0.7rem', maxWidth: '15rem', maxHeight: '15rem' }}
      />
    );
  };

  //   const acceptFile = async (e, type) => {
  //     let signed = await getUrl(e);

  //     if (type === "image") {
  //       setImageFile(e);
  //       setImageSignedFile(signed);
  //     } else {
  //       setTextFile(e);
  //       setTextSignedFile(signed);
  //     }
  //   };

  return (
    <div
      style={theme.components.pageContainer}
      // key={rerenderKey}
    >
      <SectionHeader noSort noFilter noSearch title="IMAGE GALLERY" />
      <Paper style={{ ...theme.components.paper, display: 'flex' }}>
        <div style={{ padding: '2rem', width: '50%' }}>
          <Form
            data={formData}
            title={editInfo.empty ? 'Add Image' : 'Edit Image'}
            onSubmit={editInfo.empty ? addImageOnSubmit : updateImageOnSubmit}
            style={{ width: '50%' }}
          />
        </div>
        <div
          style={{
            padding: '6.8rem 2rem 0 0',
            width: '50%',
            display: 'flex',
            flexDirection: 'column',
            ...theme.typography.subtitle2,
            fontWeight: 'bold',
          }}
        >
          {imgUploadData && (
            <>
              IMAGE THUMBNAIL
              <ImagePreview src={imgUploadData.file} />
            </>
          )}
        </div>
      </Paper>
    </div>
  );
}
