import React, { useState, useContext, useEffect, useRef } from 'react';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';

import { Button } from 'stories';
import SearchBarDropdown from './SearchBarDropdown';

import { store as GlobalStore } from 'contexts/GlobalStore';

export default function SearchBar(props) {
  const inputRef = useRef(null);

  const GlobalState = useContext(GlobalStore);
  const dispatchGlobal = GlobalState.dispatch;
  const { globalTableState } = GlobalState.state;

  const pathName = window.location.pathname.replace('/', '');

  const [value, setValue] = useState(
    globalTableState &&
      globalTableState[pathName] &&
      globalTableState[pathName].search
      ? globalTableState[pathName].search
      : ''
  );

  const useStyles = makeStyles(theme => ({
    searchbar: {
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'space-between',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: 'white',
    },
    searchIcon: {
      margin: '0 1rem 0 1rem',
      color: '#8D8D8D',
    },
  }));
  const classes = useStyles();

  const onSelect = (value, type) => {
    if (type === 'sort' && props.onSelectSort) {
      props.onSelectSort(value);
    } else if (type === 'filter' && props.onSelectFilter) {
      props.onSelectFilter(value);
    } else if (type === 'filter2' && props.onSelectFilter2) {
      props.onSelectFilter2(value);
    } else if (type === 'group' && props.onSelectGroup) {
      props.onSelectGroup(value);
    } else if (type === 'modality' && props.onSelectModality) {
      props.onSelectModality(value);
    } else {
      console.log('something went wrong in SearchBar...');
    }
  };

  const updateGlobalSearch = () => {
    const input = inputRef.current;
    const pathName = window.location.pathname.replace('/', '');

    let newGlobalTableState = Object.assign({}, globalTableState);
    if (!newGlobalTableState[pathName]) {
      newGlobalTableState[pathName] = {};
    }
    newGlobalTableState[pathName].search = input.value ? input.value : '';
    dispatchGlobal({
      type: 'setGlobalTableState',
      globalTableState: newGlobalTableState,
    });
  };

  const enterEvent = keyPress => {
    updateGlobalSearch();

    if (keyPress && keyPress.key === 'Enter' && props.enterEvent) {
      props.enterEvent();
    }
  };

  const onChange = e => {
    setValue(e.target.value);
    if (props.onChange) {
      props.onChange(e.target.value);
    }
  };

  const onClickSearch = () => {
    updateGlobalSearch();

    if (props.onClickSearch) {
      props.onClickSearch();
    }
  };

  const onClickReset = () => {
    const input = inputRef.current;
    input.value = '';
    setValue('');
    updateGlobalSearch();
    if (props.onClickReset) {
      props.onClickReset();
    }
  };

  return (
    <div
      className={classes.searchbar}
      style={{ ...props.style, height: '3rem' }}
    >
      <div
        style={{
          display: 'flex',
          flex: 5,
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <SearchIcon className={classes.searchIcon} />
        <InputBase
          inputRef={inputRef}
          placeholder="Search"
          onChange={onChange}
          onKeyPress={e => {
            enterEvent(e);
          }}
          value={value}
        />
        <Button
          label="SEARCH"
          style={{ color: 'maroon', width: '4rem' }}
          onClick={onClickSearch}
        />
        <Button
          label="RESET"
          style={{ color: 'maroon', width: '4rem' }}
          onClick={onClickReset}
        />
      </div>
      <div style={{ display: 'flex' }}>
        {!props.noSort && (
          <SearchBarDropdown
            text="sort"
            options={props.sortOptions}
            onSelect={e => onSelect(e, 'sort')}
          />
        )}
        {!props.noFilter && (
          <SearchBarDropdown
            text={props.filterButtonText || 'filter'}
            options={props.filterOptions}
            onSelect={e => onSelect(e, 'filter')}
          />
        )}
        {!props.noGroup && (
          <SearchBarDropdown
            text={props.groupButtonText || 'group'}
            options={props.groupOptions}
            onSelect={e => onSelect(e, 'group')}
          />
        )}
        {!props.noModality && (
          <SearchBarDropdown
            text={props.modalityButtonText || 'modality'}
            options={props.modalityOptions}
            onSelect={e => onSelect(e, 'modality')}
          />
        )}
        {props.filterOptions2 && (
          <SearchBarDropdown
            text={props.filterButtonText2 || 'filter 2'}
            options={props.filterOptions2}
            onSelect={e => onSelect(e, 'filter2')}
          />
        )}
      </div>
    </div>
  );
}
