import React, { useEffect, useState, useContext } from "react";

import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";

import { insertAtPosition } from "../utility";

export default function Emojis(props) {
  let { currentValue, acceptOptn, fieldRef } = props;

  // console.log("EMOJI REF: ",fieldRef)

  const onEmojiClick = (e) => {
    let newStr = insertAtPosition(e.native, currentValue, fieldRef);

    if (acceptOptn) {
      acceptOptn(newStr);
    }
  };

  return (
    <div className="emojiHolder">
      <Picker
        darkMode={false}
        emojiTooltip={false}
        showPreview={false}
        onSelect={onEmojiClick}
      />
    </div>
  );
}
