import { API, graphqlOperation } from 'aws-amplify';
import * as queries from 'graphql/queries';

import { roleCheck } from 'services/utility/RoleMatrix';

export const fetchCampaignList = async (tokens, searchText, filter, sort) => {
  return new Promise(async function(resolve, reject) {
    let sortQuery = { field: 'timestampKey', direction: 'desc' };
    /*if (sort) {
          sortQuery = { field: sort.field, direction: sort.direction };
        }*/

    let result = '';
    if (tokens.role === 'approver' || tokens.role === 'content creator') {
      result = await API.graphql(
        graphqlOperation(queries.searchCampaigns, {
          limit: 100,
          filter: createFilterQuery(tokens, searchText, filter, true),
          sort: sortQuery,
        })
      );
    } else {
      result = await API.graphql(
        graphqlOperation(queries.searchCampaigns, {
          limit: 100,
          filter: createFilterQuery(tokens, searchText, filter, false),
          sort: sortQuery,
        })
      );
    }

    let rawData = result.data.searchCampaigns.items;

    if (!result.data.searchCampaigns.items.length) {
      //no data
    } else {
      //found data

      let nextToken = '';
      if (result.data.searchCampaigns.nextToken) {
        nextToken = result.data.searchCampaigns.nextToken;
      }

      if (
        result.data.searchCampaigns.total !==
        result.data.searchCampaigns.items.length
      ) {
        let moreData = await fetchMoreData(
          tokens,
          searchText,
          filter,
          sortQuery,
          rawData,
          result.data.searchCampaigns.total,
          nextToken
        );

        resolve(moreData);
      } else {
        resolve(rawData);
      }
    }
  });
};

const fetchMoreData = async (
  tokens,
  searchText,
  filter,
  sortQuery,
  previousData,
  total,
  nextToken
) => {
  let allData = [];
  let localToken = null;
  let localPreviousData = false;
  return new Promise(async function tryagain(resolve, reject) {

    let result = null;

    if (tokens.role === 'approver' || tokens.role === 'content creator') {
      result = await API.graphql(
        graphqlOperation(queries.searchCampaigns, {
          limit: 100,
          filter: createFilterQuery(tokens, searchText, filter, true),
          sort: sortQuery,
          nextToken: localToken ? localToken : nextToken,
        })
      );
    } else {
      result = await API.graphql(
        graphqlOperation(queries.searchCampaigns, {
          limit: 100,
          filter: createFilterQuery(tokens, searchText, filter, false),
          sort: sortQuery,
          nextToken: localToken ? localToken : nextToken,
        })
      );
    }

    if (result) {
      let parsedData = result.data.searchCampaigns.items;

      if (result.data.searchCampaigns.nextToken) {
        localToken = result.data.searchCampaigns.nextToken;
      }

      if (localPreviousData === false) {
        let newPreviousData = Array.from(previousData);
        newPreviousData = newPreviousData.concat(parsedData);
        allData = allData.concat(newPreviousData);
        localPreviousData = true;
      } else {
        allData = allData.concat(parsedData);
      }

      if (allData.length < result.data.searchCampaigns.total) {
        tryagain(resolve, reject);
      } else {
        resolve(allData);
      }
    }
  });
};

const createFilterQuery = (tokens, searchText, filter, groupFilterFlag) => {
  const filterConditions = [];

  //Remove Benji
  filterConditions.push({ group: { ne: 'Benji_1593621826' } });

  if (!roleCheck(tokens.role, 'campaigns', 'viewAllContent')) {
    tokens.groups.forEach(el => {
      filterConditions.push({ group: { eq: el } });
    });
    filterConditions.push({ createdBy: { eq: tokens.asurite } });
    filterConditions.push({ status: { eq: getStatusKey('Published') } });
    filterConditions.push({ status: { eq: getStatusKey('Approved') } });
  }

  let filterQuery = {
    deleted: { eq: false },
  };

  if (groupFilterFlag) {
    filterQuery.or = [];
    let grps = tokens.groups;
    for (var i = 0; i < grps.length; ++i) {
      filterQuery.or.push({
        or: [{ group: { eq: grps[i] } }],
      });
    }
  }

  filterQuery.and = [
    {
      or: [...filterConditions],
    },
  ];

  if (searchText && filter) {
    filterQuery.title = { matchPhrasePrefix: searchText };
    if (filter === 'Paused') {
      filterQuery.paused = { eq: true };
    } else {
      filterQuery.status = { eq: getStatusKey(filter) };
      filterQuery.paused = { eq: false };
    }
  } else if (filter) {
    if (filter === 'Paused') {
      filterQuery.paused = { eq: true };
    } else {
      filterQuery.status = { eq: getStatusKey(filter) };
      filterQuery.paused = { eq: false };
    }
  } else if (searchText) {
    filterQuery.title = { matchPhrasePrefix: searchText };
  }

  return filterQuery;
};

const getStatusKey = name => {
  if (name === 'Pending') {
    return 1;
  } else if (name === 'Approved') {
    return 3;
  } else if (name === 'Unapproved') {
    return 2;
  } else if (name === 'Published') {
    return 4;
  } else if (name === 'Sending') {
    return 7;
  } else if (name === 'Active') {
    return 8;
  } else if (name === 'Finished') {
    return 9;
  } else if (name === 'Paused') {
    return 14;
  } else {
    return null;
  }
};
