function convertToProperCSV(csvData) {
  if (!Array.isArray(csvData)) {
    console.error('Provided non array to convertToProperCSV');
    return [];
  }

  if (csvData.length === 0) {
    // Empty
    return [];
  }

  if (csvData.length === 1 && typeof csvData[0][0] === 'string') {
    // need conversion
    const newGeneratedCSV = [[]];

    for (let csvIndex = 0; csvIndex < csvData[0].length; csvIndex++) {
      if (typeof csvData[0][csvIndex] === 'string') {
        newGeneratedCSV[0].push(csvData[0][csvIndex]);
      } else {
        newGeneratedCSV.push(csvData[0][csvIndex]);
      }
    }

    return newGeneratedCSV;
  }

  return csvData; // properly formatted
}

module.exports = {
  convertToProperCSV,
};
