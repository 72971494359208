/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const validateLexQuestion = /* GraphQL */ `
  query ValidateLexQuestion(
    $operation: String
    $inquiry: String
    $env: String
    $current: String
  ) {
    validateLexQuestion(
      operation: $operation
      inquiry: $inquiry
      env: $env
      current: $current
    ) {
      message
      newIntent
      intentName
      isTaken
      isSelf
    }
  }
`;
export const testLexQuestion = /* GraphQL */ `
  query TestLexQuestion(
    $operation: String
    $inquiry: String
    $userInfo: String
    $env: String
    $current: String
  ) {
    testLexQuestion(
      operation: $operation
      inquiry: $inquiry
      userInfo: $userInfo
      env: $env
      current: $current
    ) {
      content
      smsFormattedContent
      matches {
        id
        match
      }
      image
    }
  }
`;
export const runCampaign = /* GraphQL */ `
  query RunCampaign($operation: String, $campaign: inputCampaign) {
    runCampaign(operation: $operation, campaign: $campaign) {
      content
      smsFormattedContent
      matches {
        id
        match
      }
      image
    }
  }
`;
export const updateFlowVersion = /* GraphQL */ `
  query UpdateFlowVersion(
    $operation: String
    $flowVersionInfo: inputFlowVersion
  ) {
    updateFlowVersion(
      operation: $operation
      flowVersionInfo: $flowVersionInfo
    ) {
      status
      message
    }
  }
`;
export const sendToEditQueue = /* GraphQL */ `
  query SendToEditQueue($operation: String, $flowId: String) {
    sendToEditQueue(operation: $operation, flowId: $flowId) {
      message
      newIntent
      intentName
      isTaken
      isSelf
    }
  }
`;
export const getTokenForWss = /* GraphQL */ `
  query GetTokenForWss($operation: String) {
    getTokenForWSS(operation: $operation)
  }
`;
export const handleDialogues = /* GraphQL */ `
  query HandleDialogues(
    $operation: String
    $startFrom: Int
    $page: Int
    $convoId: String
    $messageId: String
    $user: String
    $type: String
    $searchTerm: String
    $rolesToGet: [String]
    $rolesToGet2: [String]
    $newUndergradStatus: String
    $newProspectiveStatus: String
    $includeConvos: [String]
  ) {
    handleDialogues(
      operation: $operation
      startFrom: $startFrom
      page: $page
      convoId: $convoId
      messageId: $messageId
      user: $user
      type: $type
      searchTerm: $searchTerm
      rolesToGet: $rolesToGet
      rolesToGet2: $rolesToGet2
      newUndergradStatus: $newUndergradStatus
      newProspectiveStatus: $newProspectiveStatus
      includeConvos: $includeConvos
    )
  }
`;
export const searchMessages = /* GraphQL */ `
  query SearchMessages($operation: String, $payload: String) {
    searchMessages(operation: $operation, payload: $payload)
  }
`;
export const getIntroMessageTag = /* GraphQL */ `
  query GetIntroMessageTag($payload: String) {
    getIntroMessageTag(payload: $payload)
  }
`;
export const sendIntroMessage = /* GraphQL */ `
  query SendIntroMessage($campaignId: String, $message: String, $flow: String) {
    sendIntroMessage(campaignId: $campaignId, message: $message, flow: $flow)
  }
`;
export const escalateConvo = /* GraphQL */ `
  query EscalateConvo(
    $id: String
    $user: String
    $convoId: String
    $isVisitor: Boolean
    $hitType: String
    $escalator: String
    $messageId: String
    $message: String
  ) {
    escalateConvo(
      id: $id
      user: $user
      convoId: $convoId
      isVisitor: $isVisitor
      hitType: $hitType
      escalator: $escalator
      messageId: $messageId
      message: $message
    ) {
      file
      type
      category
    }
  }
`;
export const processMixedFile = /* GraphQL */ `
  query ProcessMixedFile($id: String!, $group: String!) {
    processMixedFile(id: $id, group: $group)
  }
`;
export const processMixedFileNew = /* GraphQL */ `
  query ProcessMixedFileNew($id: String!, $group: String!) {
    processMixedFileNew(id: $id, group: $group)
  }
`;
export const campaignQueueUsersForProcessing = /* GraphQL */ `
  query CampaignQueueUsersForProcessing($files: [String]!, $group: String!) {
    campaignQueueUsersForProcessing(files: $files, group: $group)
  }
`;
export const campaignQueueUsersForProcessingNew = /* GraphQL */ `
  query CampaignQueueUsersForProcessingNew(
    $campaignId: String!
    $group: String!
  ) {
    campaignQueueUsersForProcessingNew(campaignId: $campaignId, group: $group)
  }
`;
export const queryWithAthena = /* GraphQL */ `
  query QueryWithAthena($queryName: String!, $params: String) {
    queryWithAthena(queryName: $queryName, params: $params)
  }
`;
export const updateUserInfo = /* GraphQL */ `
  query UpdateUserInfo($user: String, $botType: String, $operation: String) {
    updateUserInfo(user: $user, botType: $botType, operation: $operation)
  }
`;
export const getCampaign = /* GraphQL */ `
  query GetCampaign($id: ID!) {
    getCampaign(id: $id) {
      id
      title
      titleKey
      description
      status
      statusKey
      audienceTargetType
      audience
      audienceFileContent
      audienceFileName
      fileUploadedBy
      flow
      flowVersion
      introFlow
      introFlowId
      introFlowVersion
      processing
      processingError
      startTime
      sendNumbers
      startTimeKey
      endTime
      endTimeKey
      timestamp
      timestampKey
      createdBy
      paused
      dryRun
      campaignTagAnswer
      campaignSelect
      pausedBy {
        asurite
        timestamp
      }
      approvedBy {
        asurite
        timestamp
      }
      type
      editedBy {
        asurite
        timestamp
      }
      deleted
      deletedBy {
        asurite
        timestamp
      }
      active
      group
      groupKey
      isSendingIntroMessage
      estSendMinutes
      smsButtonPresser
      smsButtonMsg
      numberProcessed
      numberToProcess
      createdAt
      updatedAt
    }
  }
`;
export const listCampaigns = /* GraphQL */ `
  query ListCampaigns(
    $filter: ModelcampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCampaigns(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        titleKey
        description
        status
        statusKey
        audienceTargetType
        audience
        audienceFileContent
        audienceFileName
        fileUploadedBy
        flow
        flowVersion
        introFlow
        introFlowId
        introFlowVersion
        processing
        processingError
        startTime
        sendNumbers
        startTimeKey
        endTime
        endTimeKey
        timestamp
        timestampKey
        createdBy
        paused
        dryRun
        campaignTagAnswer
        campaignSelect
        pausedBy {
          asurite
          timestamp
        }
        approvedBy {
          asurite
          timestamp
        }
        type
        editedBy {
          asurite
          timestamp
        }
        deleted
        deletedBy {
          asurite
          timestamp
        }
        active
        group
        groupKey
        isSendingIntroMessage
        estSendMinutes
        smsButtonPresser
        smsButtonMsg
        numberProcessed
        numberToProcess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCampaignManualSend = /* GraphQL */ `
  query GetCampaignManualSend($id: ID!) {
    getCampaignManualSend(id: $id) {
      id
      campaignId
      audience
      status
      flow
      flowVersion
      audienceFileContent
      startTime
      endTime
      createdBy
      group
      type
      isTest
      createdAt
      updatedAt
    }
  }
`;
export const listCampaignManualSends = /* GraphQL */ `
  query ListCampaignManualSends(
    $filter: ModelcampaignManualSendFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCampaignManualSends(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        campaignId
        audience
        status
        flow
        flowVersion
        audienceFileContent
        startTime
        endTime
        createdBy
        group
        type
        isTest
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCampaignCompleted = /* GraphQL */ `
  query GetCampaignCompleted($campaignId: ID!, $userId: ID!) {
    getCampaignCompleted(campaignId: $campaignId, userId: $userId) {
      userId
      campaignId
      group
      flowId
      flowVersion
      lastNode
      exitType
      finishTime
      expireTime
      startTime
      type
      error
      isManualCamp
      expiredBeforeSend
      campaignInfo {
        items {
          id
          title
          titleKey
          description
          status
          statusKey
          audienceTargetType
          audience
          audienceFileContent
          audienceFileName
          fileUploadedBy
          flow
          flowVersion
          introFlow
          introFlowId
          introFlowVersion
          processing
          processingError
          startTime
          sendNumbers
          startTimeKey
          endTime
          endTimeKey
          timestamp
          timestampKey
          createdBy
          paused
          dryRun
          campaignTagAnswer
          campaignSelect
          pausedBy {
            asurite
            timestamp
          }
          approvedBy {
            asurite
            timestamp
          }
          type
          editedBy {
            asurite
            timestamp
          }
          deleted
          deletedBy {
            asurite
            timestamp
          }
          active
          group
          groupKey
          isSendingIntroMessage
          estSendMinutes
          smsButtonPresser
          smsButtonMsg
          numberProcessed
          numberToProcess
          createdAt
          updatedAt
        }
        nextToken
      }
      asurite
      phoneNumber
      createdAt
      updatedAt
    }
  }
`;
export const listCampaignCompleteds = /* GraphQL */ `
  query ListCampaignCompleteds(
    $campaignId: ID
    $userId: ModelIDKeyConditionInput
    $filter: ModelcampaignCompletedFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCampaignCompleteds(
      campaignId: $campaignId
      userId: $userId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userId
        campaignId
        group
        flowId
        flowVersion
        lastNode
        exitType
        finishTime
        expireTime
        startTime
        type
        error
        isManualCamp
        expiredBeforeSend
        campaignInfo {
          items {
            id
            title
            titleKey
            description
            status
            statusKey
            audienceTargetType
            audience
            audienceFileContent
            audienceFileName
            fileUploadedBy
            flow
            flowVersion
            introFlow
            introFlowId
            introFlowVersion
            processing
            processingError
            startTime
            sendNumbers
            startTimeKey
            endTime
            endTimeKey
            timestamp
            timestampKey
            createdBy
            paused
            dryRun
            campaignTagAnswer
            campaignSelect
            type
            deleted
            active
            group
            groupKey
            isSendingIntroMessage
            estSendMinutes
            smsButtonPresser
            smsButtonMsg
            numberProcessed
            numberToProcess
            createdAt
            updatedAt
          }
          nextToken
        }
        asurite
        phoneNumber
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCampaignUserQueue = /* GraphQL */ `
  query GetCampaignUserQueue($campaignId: ID!, $userId: ID!) {
    getCampaignUserQueue(campaignId: $campaignId, userId: $userId) {
      userId
      campaignId
      flowId
      flowVersion
      expireTime
      startTime
      createdAt
      updatedAt
    }
  }
`;
export const listCampaignUserQueues = /* GraphQL */ `
  query ListCampaignUserQueues(
    $campaignId: ID
    $userId: ModelIDKeyConditionInput
    $filter: ModelcampaignUserQueueFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCampaignUserQueues(
      campaignId: $campaignId
      userId: $userId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userId
        campaignId
        flowId
        flowVersion
        expireTime
        startTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChatbotErrorMessages = /* GraphQL */ `
  query GetChatbotErrorMessages($errorCode: ID!) {
    getChatbotErrorMessages(errorCode: $errorCode) {
      errorCode
      errorMessage
      type
      createdAt
      updatedAt
    }
  }
`;
export const listChatbotErrorMessagess = /* GraphQL */ `
  query ListChatbotErrorMessagess(
    $errorCode: ID
    $filter: ModelchatbotErrorMessagesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listChatbotErrorMessagess(
      errorCode: $errorCode
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        errorCode
        errorMessage
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCampaignRunning = /* GraphQL */ `
  query GetCampaignRunning($userId: ID!) {
    getCampaignRunning(userId: $userId) {
      userId
      campaignId
      flowId
      flowVersion
      lastNode
      isManualCamp
      createdAt
      updatedAt
    }
  }
`;
export const listCampaignRunnings = /* GraphQL */ `
  query ListCampaignRunnings(
    $userId: ID
    $filter: ModelcampaignRunningFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCampaignRunnings(
      userId: $userId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userId
        campaignId
        flowId
        flowVersion
        lastNode
        isManualCamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCampaignRunningVoice = /* GraphQL */ `
  query GetCampaignRunningVoice($userId: ID!) {
    getCampaignRunningVoice(userId: $userId) {
      userId
      campaignId
      flowId
      flowVersion
      lastNode
      isManualCamp
      createdAt
      updatedAt
    }
  }
`;
export const listCampaignRunningVoices = /* GraphQL */ `
  query ListCampaignRunningVoices(
    $userId: ID
    $filter: ModelcampaignRunningVoiceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCampaignRunningVoices(
      userId: $userId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userId
        campaignId
        flowId
        flowVersion
        lastNode
        isManualCamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAnswerVariables = /* GraphQL */ `
  query GetAnswerVariables($id: ID!) {
    getAnswerVariables(id: $id) {
      id
      varName
      active
      group
      createdAt
      updatedAt
    }
  }
`;
export const listAnswerVariabless = /* GraphQL */ `
  query ListAnswerVariabless(
    $filter: ModelanswerVariablesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnswerVariabless(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        varName
        active
        group
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomVariable = /* GraphQL */ `
  query GetCustomVariable($id: ID!) {
    getCustomVariable(id: $id) {
      id
      user
      key
      value
      botType
      group
      campaignId
      createdAt
      updatedAt
    }
  }
`;
export const listCustomVariables = /* GraphQL */ `
  query ListCustomVariables(
    $filter: ModelcustomVariableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomVariables(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        key
        value
        botType
        group
        campaignId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStatus = /* GraphQL */ `
  query GetStatus($id: ID!, $submittedBy: String!) {
    getStatus(id: $id, submittedBy: $submittedBy) {
      id
      startTime
      submittedBy
      title
      active
      message
      severity
      endTime
      createdAt
      updatedAt
    }
  }
`;
export const listStatuss = /* GraphQL */ `
  query ListStatuss(
    $id: ID
    $submittedBy: ModelStringKeyConditionInput
    $filter: ModelstatusFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStatuss(
      id: $id
      submittedBy: $submittedBy
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        startTime
        submittedBy
        title
        active
        message
        severity
        endTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSawStatus = /* GraphQL */ `
  query GetSawStatus($id: ID!, $asurite: String!) {
    getSawStatus(id: $id, asurite: $asurite) {
      id
      asurite
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const listSawStatuss = /* GraphQL */ `
  query ListSawStatuss(
    $id: ID
    $asurite: ModelStringKeyConditionInput
    $filter: ModelsawStatusFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSawStatuss(
      id: $id
      asurite: $asurite
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        asurite
        timestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFlow = /* GraphQL */ `
  query GetFlow($id: ID!) {
    getFlow(id: $id) {
      id
      title
      titleKey
      searchTitle
      description
      type
      statusX
      statusKey
      version
      versionInfo {
        id
        version
        createdAt
        title
        description
        variablesIncluded
        flowNodes
        submitted
        liveAgentEsc
        approved
        published
        disabled
        denied
        queryableData
        submittedBy {
          asurite
          timestamp
        }
        approvedBy {
          asurite
          timestamp
        }
        publishedBy {
          asurite
          timestamp
        }
        deniedBy {
          asurite
          timestamp
        }
        botType
        updatedAt
      }
      category
      timestamp
      submittedOn
      submittedOnKey
      editing {
        asurite
        timestamp
      }
      submittedBy
      submittedByKey
      paused
      approvedBy {
        asurite
        timestamp
      }
      editedBy {
        asurite
        timestamp
      }
      deleted
      deletedBy {
        asurite
        timestamp
      }
      group
      groupKey
      signOffMsg
      createdAt
      updatedAt
    }
  }
`;
export const listFlows = /* GraphQL */ `
  query ListFlows(
    $filter: ModelflowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFlows(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        titleKey
        searchTitle
        description
        type
        statusX
        statusKey
        version
        versionInfo {
          id
          version
          createdAt
          title
          description
          variablesIncluded
          flowNodes
          submitted
          liveAgentEsc
          approved
          published
          disabled
          denied
          queryableData
          submittedBy {
            asurite
            timestamp
          }
          approvedBy {
            asurite
            timestamp
          }
          publishedBy {
            asurite
            timestamp
          }
          deniedBy {
            asurite
            timestamp
          }
          botType
          updatedAt
        }
        category
        timestamp
        submittedOn
        submittedOnKey
        editing {
          asurite
          timestamp
        }
        submittedBy
        submittedByKey
        paused
        approvedBy {
          asurite
          timestamp
        }
        editedBy {
          asurite
          timestamp
        }
        deleted
        deletedBy {
          asurite
          timestamp
        }
        group
        groupKey
        signOffMsg
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFlowMessage = /* GraphQL */ `
  query GetFlowMessage($id: ID!) {
    getFlowMessage(id: $id) {
      id
      name
      message
      nodeType
      group
      deleted
      deletedBy {
        asurite
        timestamp
      }
      submittedBy
      image
      audience
      response
      matches {
        id
        match
      }
      responseImage
      createdAt
      updatedAt
    }
  }
`;
export const listFlowMessages = /* GraphQL */ `
  query ListFlowMessages(
    $filter: ModelflowMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFlowMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        message
        nodeType
        group
        deleted
        deletedBy {
          asurite
          timestamp
        }
        submittedBy
        image
        audience
        response
        matches {
          id
          match
        }
        responseImage
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFlowVersion = /* GraphQL */ `
  query GetFlowVersion($id: ID!, $version: ID!) {
    getFlowVersion(id: $id, version: $version) {
      id
      version
      createdAt
      title
      description
      variablesIncluded
      flowNodes
      submitted
      liveAgentEsc
      approved
      published
      disabled
      denied
      queryableData
      submittedBy {
        asurite
        timestamp
      }
      approvedBy {
        asurite
        timestamp
      }
      publishedBy {
        asurite
        timestamp
      }
      deniedBy {
        asurite
        timestamp
      }
      botType
      updatedAt
    }
  }
`;
export const listFlowVersions = /* GraphQL */ `
  query ListFlowVersions(
    $id: ID
    $version: ModelIDKeyConditionInput
    $filter: ModelflowVersionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFlowVersions(
      id: $id
      version: $version
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        version
        createdAt
        title
        description
        variablesIncluded
        flowNodes
        submitted
        liveAgentEsc
        approved
        published
        disabled
        denied
        queryableData
        submittedBy {
          asurite
          timestamp
        }
        approvedBy {
          asurite
          timestamp
        }
        publishedBy {
          asurite
          timestamp
        }
        deniedBy {
          asurite
          timestamp
        }
        botType
        updatedAt
      }
      nextToken
    }
  }
`;
export const getResponseMessages = /* GraphQL */ `
  query GetResponseMessages($id: ID!) {
    getResponseMessages(id: $id) {
      id
      group
      author
      asurite
      type
      message
      number
      flowId
      flowLabel
      flowVersion
      flowVersionLabel
      createdAt
      updatedAt
    }
  }
`;
export const listResponseMessagess = /* GraphQL */ `
  query ListResponseMessagess(
    $filter: ModelresponseMessagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResponseMessagess(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        author
        asurite
        type
        message
        number
        flowId
        flowLabel
        flowVersion
        flowVersionLabel
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getKnowledgeBaseCategory = /* GraphQL */ `
  query GetKnowledgeBaseCategory($id: ID!) {
    getKnowledgeBaseCategory(id: $id) {
      id
      title
      group
      subcategories {
        title
      }
      deleted
      deletedBy {
        asurite
        timestamp
      }
      createdAt
      updatedAt
    }
  }
`;
export const listKnowledgeBaseCategorys = /* GraphQL */ `
  query ListKnowledgeBaseCategorys(
    $filter: ModelknowledgeBaseCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKnowledgeBaseCategorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        group
        subcategories {
          title
        }
        deleted
        deletedBy {
          asurite
          timestamp
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getKnowledgeBase = /* GraphQL */ `
  query GetKnowledgeBase($id: ID!) {
    getKnowledgeBase(id: $id) {
      id
      categoryID
      categoryName
      subcategoryNames
      group
      groupKey
      comments
      commentsKey
      deletedBy {
        asurite
        timestamp
      }
      editedBy {
        asurite
        timestamp
      }
      editedByKey
      lastEdit
      lastEditKey
      approvedBy {
        asurite
        timestamp
      }
      question
      status
      statusKey
      submittedBy
      submittedByKey
      submittedOn
      submittedOnKey
      updatedOn
      variations
      variationsCount
      variationsCountKey
      answerData {
        answer
        flow
        flowVersion
        audience
        audienceEveryone
        type
        answerEscalation
        image
      }
      modality
      fallbackAnswer
      fallbackAnswerImage
      deleted
      escalation
      editing {
        asurite
        timestamp
      }
      processing
      processingError
      flow
      flowVersion
      createdAt
      updatedAt
    }
  }
`;
export const listKnowledgeBases = /* GraphQL */ `
  query ListKnowledgeBases(
    $filter: ModelknowledgeBaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKnowledgeBases(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        categoryID
        categoryName
        subcategoryNames
        group
        groupKey
        comments
        commentsKey
        deletedBy {
          asurite
          timestamp
        }
        editedBy {
          asurite
          timestamp
        }
        editedByKey
        lastEdit
        lastEditKey
        approvedBy {
          asurite
          timestamp
        }
        question
        status
        statusKey
        submittedBy
        submittedByKey
        submittedOn
        submittedOnKey
        updatedOn
        variations
        variationsCount
        variationsCountKey
        answerData {
          answer
          flow
          flowVersion
          audience
          audienceEveryone
          type
          answerEscalation
          image
        }
        modality
        fallbackAnswer
        fallbackAnswerImage
        deleted
        escalation
        editing {
          asurite
          timestamp
        }
        processing
        processingError
        flow
        flowVersion
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      asurite
      displayName
      role
      createdAt
      createdBy
      editedAt
      editedBy
      isActive
      tier1Support
      lastLogin
      groups
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModeluserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        asurite
        displayName
        role
        createdAt
        createdBy
        editedAt
        editedBy
        isActive
        tier1Support
        lastLogin
        groups
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChatbotGroup = /* GraphQL */ `
  query GetChatbotGroup($id: ID!) {
    getChatbotGroup(id: $id) {
      id
      name
      isActive
      email
      dialoguesFolders {
        name
        tagValue
        parent
      }
      createdAt
      updatedAt
    }
  }
`;
export const listChatbotGroups = /* GraphQL */ `
  query ListChatbotGroups(
    $filter: ModelchatbotGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatbotGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        isActive
        email
        dialoguesFolders {
          name
          tagValue
          parent
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCriticalEscalation = /* GraphQL */ `
  query GetCriticalEscalation($id: ID!) {
    getCriticalEscalation(id: $id) {
      id
      triggerPhrase
      escalation
      variations
      status
      statusKey
      editing {
        asurite
        timestamp
      }
      processing
      processingError
      escalateSms
      answerData {
        answer
        flow
        flowVersion
        audience
        audienceEveryone
        type
        answerEscalation
        image
      }
      fallbackAnswer
      fallbackAnswerImage
      deleted
      group
      createdAt
      createdAtKey
      submittedBy
      submittedByKey
      response
      editedBy {
        asurite
        timestamp
      }
      deletedBy {
        asurite
        timestamp
      }
      timestamp
      timestampKey
      lastEdit
      updatedAt
    }
  }
`;
export const listCriticalEscalations = /* GraphQL */ `
  query ListCriticalEscalations(
    $filter: ModelcriticalEscalationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCriticalEscalations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        triggerPhrase
        escalation
        variations
        status
        statusKey
        editing {
          asurite
          timestamp
        }
        processing
        processingError
        escalateSms
        answerData {
          answer
          flow
          flowVersion
          audience
          audienceEveryone
          type
          answerEscalation
          image
        }
        fallbackAnswer
        fallbackAnswerImage
        deleted
        group
        createdAt
        createdAtKey
        submittedBy
        submittedByKey
        response
        editedBy {
          asurite
          timestamp
        }
        deletedBy {
          asurite
          timestamp
        }
        timestamp
        timestampKey
        lastEdit
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFlowNode = /* GraphQL */ `
  query GetFlowNode($id: ID!) {
    getFlowNode(id: $id) {
      id
      flowId
      type
      customType
      text
      parent
      child
      ignore
      match
      matches {
        id
        match
      }
      matchText
      deleted
      deleteAudit {
        asurite
        timestamp
      }
      jumpToNodeId
      messageBody
      postSkipJump
      skipAfterTimes
      response
      escalationName
      escalationType
      image
      responseImage
      escalationDump
      templateId
      saveAsTemplate
      messageName
      ownerGroup
      audience
      branchDataCollector
      createdAt
      updatedAt
    }
  }
`;
export const listFlowNodes = /* GraphQL */ `
  query ListFlowNodes(
    $filter: ModelflowNodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFlowNodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        flowId
        type
        customType
        text
        parent
        child
        ignore
        match
        matches {
          id
          match
        }
        matchText
        deleted
        deleteAudit {
          asurite
          timestamp
        }
        jumpToNodeId
        messageBody
        postSkipJump
        skipAfterTimes
        response
        escalationName
        escalationType
        image
        responseImage
        escalationDump
        templateId
        saveAsTemplate
        messageName
        ownerGroup
        audience
        branchDataCollector
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCampaignUserInput = /* GraphQL */ `
  query GetCampaignUserInput($id: ID!, $userId: String!) {
    getCampaignUserInput(id: $id, userId: $userId) {
      id
      userId
      flowId
      campaignId
      nodeId
      timestamp
      button
      inputDump
      type
      flowVersion
      botType
      createdAt
      updatedAt
    }
  }
`;
export const listCampaignUserInputs = /* GraphQL */ `
  query ListCampaignUserInputs(
    $id: ID
    $userId: ModelStringKeyConditionInput
    $filter: ModelcampaignUserInputFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCampaignUserInputs(
      id: $id
      userId: $userId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userId
        flowId
        campaignId
        nodeId
        timestamp
        button
        inputDump
        type
        flowVersion
        botType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEscalation = /* GraphQL */ `
  query GetEscalation($id: ID!) {
    getEscalation(id: $id) {
      id
      escalationName
      group
      groupKey
      escalationType
      emailTo
      ccemail
      emailSubject
      emailBody
      attachChatLog
      sendTextMessage
      status
      statusKey
      textMessageContent {
        to
        textBody
      }
      submittedBy
      submittedByKey
      submittedAt
      submittedAtKey
      liveAgentCategory
      attachedAlertIds
      editedBy {
        asurite
        timestamp
      }
      deleted
      timestamp
      timestampKey
      createdAt
      updatedAt
    }
  }
`;
export const listEscalations = /* GraphQL */ `
  query ListEscalations(
    $filter: ModelescalationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEscalations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        escalationName
        group
        groupKey
        escalationType
        emailTo
        ccemail
        emailSubject
        emailBody
        attachChatLog
        sendTextMessage
        status
        statusKey
        textMessageContent {
          to
          textBody
        }
        submittedBy
        submittedByKey
        submittedAt
        submittedAtKey
        liveAgentCategory
        attachedAlertIds
        editedBy {
          asurite
          timestamp
        }
        deleted
        timestamp
        timestampKey
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEscalationHistory = /* GraphQL */ `
  query GetEscalationHistory($id: ID!) {
    getEscalationHistory(id: $id) {
      id
      escalationId
      escalationName
      escalator
      hitType
      message
      messageId
      response
      user
      convoId
      timestamp
      group
      escalationType
      emailTo
      ccemail
      emailSubject
      emailBody
      attachChatLog
      sendTextMessage
      textMessageContent {
        to
        textBody
      }
      liveAgentCategory
      createdAt
      updatedAt
    }
  }
`;
export const listEscalationHistorys = /* GraphQL */ `
  query ListEscalationHistorys(
    $filter: ModelescalationHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEscalationHistorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        escalationId
        escalationName
        escalator
        hitType
        message
        messageId
        response
        user
        convoId
        timestamp
        group
        escalationType
        emailTo
        ccemail
        emailSubject
        emailBody
        attachChatLog
        sendTextMessage
        textMessageContent {
          to
          textBody
        }
        liveAgentCategory
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomDefaultMessage = /* GraphQL */ `
  query GetCustomDefaultMessage($owner: String!, $type: String!) {
    getCustomDefaultMessage(owner: $owner, type: $type) {
      id
      owner
      author
      type
      message
      number
      createdAt
      updatedAt
    }
  }
`;
export const listCustomDefaultMessages = /* GraphQL */ `
  query ListCustomDefaultMessages(
    $owner: String
    $type: ModelStringKeyConditionInput
    $filter: ModelcustomDefaultMessageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCustomDefaultMessages(
      owner: $owner
      type: $type
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        author
        type
        message
        number
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPromptResponse = /* GraphQL */ `
  query GetPromptResponse($id: ID!) {
    getPromptResponse(id: $id) {
      id
      messageId
      asurite
      message
      createdAt
      updatedAt
    }
  }
`;
export const listPromptResponses = /* GraphQL */ `
  query ListPromptResponses(
    $filter: ModelpromptResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPromptResponses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        messageId
        asurite
        message
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChatbotMessage = /* GraphQL */ `
  query GetChatbotMessage($convoId: ID!, $createdAt: String!) {
    getChatbotMessage(convoId: $convoId, createdAt: $createdAt) {
      convoId
      createdAt
      messageId
      content
      sender
      type
      connectionId
      file
      agentAsurite
      agentAction
      agent
      botType
      sessionId
      updatedAt
    }
  }
`;
export const listChatbotMessages = /* GraphQL */ `
  query ListChatbotMessages(
    $convoId: ID
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelchatbotMessageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listChatbotMessages(
      convoId: $convoId
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        convoId
        createdAt
        messageId
        content
        sender
        type
        connectionId
        file
        agentAsurite
        agentAction
        agent
        botType
        sessionId
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChatbotMessageTag = /* GraphQL */ `
  query GetChatbotMessageTag($id: ID!) {
    getChatbotMessageTag(id: $id) {
      id
      convoId
      messageId
      tag
      deleted
      addedBy
      deletedBy
      botType
      createdAt
      updatedAt
    }
  }
`;
export const listChatbotMessageTags = /* GraphQL */ `
  query ListChatbotMessageTags(
    $filter: ModelchatbotMessageTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatbotMessageTags(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        convoId
        messageId
        tag
        deleted
        addedBy
        deletedBy
        botType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChatbotLexSession = /* GraphQL */ `
  query GetChatbotLexSession($id: ID!, $botType: String!) {
    getChatbotLexSession(id: $id, botType: $botType) {
      id
      botType
      missedCount
      expireTime
      createdAt
      updatedAt
    }
  }
`;
export const listChatbotLexSessions = /* GraphQL */ `
  query ListChatbotLexSessions(
    $id: ID
    $botType: ModelStringKeyConditionInput
    $filter: ModelchatbotLexSessionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listChatbotLexSessions(
      id: $id
      botType: $botType
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        botType
        missedCount
        expireTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChatbotSession = /* GraphQL */ `
  query GetChatbotSession($id: ID!) {
    getChatbotSession(id: $id) {
      id
      asurite
      baseStartDate
      startTime
      endTime
      origin
      originLocation
      flowStatus
      connectionId
      totalTime
      caseNumber
      liveagentEsc
      sentMessages
      receivedMessages
      totalMessages
      dialogueEntered
      agentAsurite
      leftBeforeTransfer
      userId
      botType
      createdAt
      updatedAt
    }
  }
`;
export const listChatbotSessions = /* GraphQL */ `
  query ListChatbotSessions(
    $filter: ModelchatbotSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatbotSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        asurite
        baseStartDate
        startTime
        endTime
        origin
        originLocation
        flowStatus
        connectionId
        totalTime
        caseNumber
        liveagentEsc
        sentMessages
        receivedMessages
        totalMessages
        dialogueEntered
        agentAsurite
        leftBeforeTransfer
        userId
        botType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChatbotUserHistorical = /* GraphQL */ `
  query GetChatbotUserHistorical($id: ID!) {
    getChatbotUserHistorical(id: $id) {
      id
      botType
      asurite
      phoneNumber
      removedAt
      removeReason
      convoId
      type
      createdAt
      updatedAt
    }
  }
`;
export const listChatbotUserHistoricals = /* GraphQL */ `
  query ListChatbotUserHistoricals(
    $filter: ModelchatbotUserHistoricalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatbotUserHistoricals(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        botType
        asurite
        phoneNumber
        removedAt
        removeReason
        convoId
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChatbotUserStaging = /* GraphQL */ `
  query GetChatbotUserStaging($id: ID!) {
    getChatbotUserStaging(id: $id) {
      id
      searchId
      peoplesoftData
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const listChatbotUserStagings = /* GraphQL */ `
  query ListChatbotUserStagings(
    $filter: ModelchatbotUserStagingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatbotUserStagings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        searchId
        peoplesoftData
        timestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChatbotUser = /* GraphQL */ `
  query GetChatbotUser($id: ID!) {
    getChatbotUser(id: $id) {
      id
      paused
      optOut
      userOptOut
      stopped
      botType
      type
      latest
      asurite
      phoneNumber
      homeNumber
      convoId
      firstName
      lastName
      displayName
      agentPauseAsurite
      agentOptOutAsurite
      reinstateDate
      altConvoIds
      tags {
        items {
          id
          userId
          tag
          deleted
          addedBy
          deletedBy
          botType
          userInfo {
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listChatbotUsers = /* GraphQL */ `
  query ListChatbotUsers(
    $filter: ModelchatbotUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatbotUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        paused
        optOut
        userOptOut
        stopped
        botType
        type
        latest
        asurite
        phoneNumber
        homeNumber
        convoId
        firstName
        lastName
        displayName
        agentPauseAsurite
        agentOptOutAsurite
        reinstateDate
        altConvoIds
        tags {
          items {
            id
            userId
            tag
            deleted
            addedBy
            deletedBy
            botType
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChatbotUserTag = /* GraphQL */ `
  query GetChatbotUserTag($id: ID!) {
    getChatbotUserTag(id: $id) {
      id
      userId
      tag
      deleted
      addedBy
      deletedBy
      botType
      userInfo {
        items {
          id
          paused
          optOut
          userOptOut
          stopped
          botType
          type
          latest
          asurite
          phoneNumber
          homeNumber
          convoId
          firstName
          lastName
          displayName
          agentPauseAsurite
          agentOptOutAsurite
          reinstateDate
          altConvoIds
          tags {
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listChatbotUserTags = /* GraphQL */ `
  query ListChatbotUserTags(
    $filter: ModelchatbotUserTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatbotUserTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        tag
        deleted
        addedBy
        deletedBy
        botType
        userInfo {
          items {
            id
            paused
            optOut
            userOptOut
            stopped
            botType
            type
            latest
            asurite
            phoneNumber
            homeNumber
            convoId
            firstName
            lastName
            displayName
            agentPauseAsurite
            agentOptOutAsurite
            reinstateDate
            altConvoIds
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChatbotConvoTag = /* GraphQL */ `
  query GetChatbotConvoTag($id: ID!) {
    getChatbotConvoTag(id: $id) {
      id
      convoId
      tag
      deleted
      addedBy
      deletedBy
      botType
      createdAt
      updatedAt
    }
  }
`;
export const listChatbotConvoTags = /* GraphQL */ `
  query ListChatbotConvoTags(
    $filter: ModelchatbotConvoTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatbotConvoTags(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        convoId
        tag
        deleted
        addedBy
        deletedBy
        botType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChatbotConversation = /* GraphQL */ `
  query GetChatbotConversation($id: ID!) {
    getChatbotConversation(id: $id) {
      id
      latest
      asurite
      userId
      botType
      type
      paused
      new
      optOut
      unseenBy
      agents
      userOptOut
      agentPauseAsurite
      agentOptOutAsurite
      reinstateDate
      createdAt
      updatedAt
    }
  }
`;
export const listChatbotConversations = /* GraphQL */ `
  query ListChatbotConversations(
    $filter: ModelchatbotConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatbotConversations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        latest
        asurite
        userId
        botType
        type
        paused
        new
        optOut
        unseenBy
        agents
        userOptOut
        agentPauseAsurite
        agentOptOutAsurite
        reinstateDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChatbotDialogueRule = /* GraphQL */ `
  query GetChatbotDialogueRule($id: ID!) {
    getChatbotDialogueRule(id: $id) {
      id
      name
      displayName
      keywords
      active
      deleted
      assignedFolder
      assignedGroup
      assignedUser
      submittedBy
      editedBy {
        asurite
        timestamp
      }
      deletedBy {
        asurite
        timestamp
      }
      botType
      createdAt
      updatedAt
    }
  }
`;
export const listChatbotDialogueRules = /* GraphQL */ `
  query ListChatbotDialogueRules(
    $filter: ModelchatbotDialogueRuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatbotDialogueRules(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        displayName
        keywords
        active
        deleted
        assignedFolder
        assignedGroup
        assignedUser
        submittedBy
        editedBy {
          asurite
          timestamp
        }
        deletedBy {
          asurite
          timestamp
        }
        botType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChatbotWebsocketConnection = /* GraphQL */ `
  query GetChatbotWebsocketConnection($connectionId: ID!, $convoId: String!) {
    getChatbotWebsocketConnection(
      connectionId: $connectionId
      convoId: $convoId
    ) {
      connectionId
      convoId
      agentChatting
      agentDetails {
        asurite
        displayName
      }
      asurite
      domainName
      phoneNumber
      stage
      type
      webConnection
      createdAt
      updatedAt
    }
  }
`;
export const listChatbotWebsocketConnections = /* GraphQL */ `
  query ListChatbotWebsocketConnections(
    $connectionId: ID
    $convoId: ModelStringKeyConditionInput
    $filter: ModelchatbotWebsocketConnectionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listChatbotWebsocketConnections(
      connectionId: $connectionId
      convoId: $convoId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        connectionId
        convoId
        agentChatting
        agentDetails {
          asurite
          displayName
        }
        asurite
        domainName
        phoneNumber
        stage
        type
        webConnection
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTriggeredIntent = /* GraphQL */ `
  query GetTriggeredIntent($id: ID!) {
    getTriggeredIntent(id: $id) {
      id
      intentId
      asurite
      timestamp
      phrase
      kbId
      response
      originSite
      baseDate
      sessionId
      escalationInfo
      botType
      createdAt
      updatedAt
    }
  }
`;
export const listTriggeredIntents = /* GraphQL */ `
  query ListTriggeredIntents(
    $filter: ModeltriggeredIntentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTriggeredIntents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        intentId
        asurite
        timestamp
        phrase
        kbId
        response
        originSite
        baseDate
        sessionId
        escalationInfo
        botType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUtterances = /* GraphQL */ `
  query GetUtterances($phrase: ID!, $compKey: ID!) {
    getUtterances(phrase: $phrase, compKey: $compKey) {
      phrase
      compKey
      baseDate
      origin
      count
      detected
      missed
      botType
      users
      alternates
      createdAt
      updatedAt
    }
  }
`;
export const listUtterancess = /* GraphQL */ `
  query ListUtterancess(
    $phrase: ID
    $compKey: ModelIDKeyConditionInput
    $filter: ModelutterancesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUtterancess(
      phrase: $phrase
      compKey: $compKey
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        phrase
        compKey
        baseDate
        origin
        count
        detected
        missed
        botType
        users
        alternates
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPendingCampaign = /* GraphQL */ `
  query GetPendingCampaign($id: ID!) {
    getPendingCampaign(id: $id) {
      id
      sendTime
      flowId
      users
      type
      flowNodes
      parent
      expireTime
      status
      createdAt
      updatedAt
    }
  }
`;
export const listPendingCampaigns = /* GraphQL */ `
  query ListPendingCampaigns(
    $filter: ModelpendingCampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPendingCampaigns(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sendTime
        flowId
        users
        type
        flowNodes
        parent
        expireTime
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDialogueNotes = /* GraphQL */ `
  query GetDialogueNotes($id: ID!) {
    getDialogueNotes(id: $id) {
      id
      convoId
      messageId
      content
      author
      deleted
      botType
      editedBy {
        asurite
        timestamp
      }
      createdAt
      updatedAt
    }
  }
`;
export const listDialogueNotess = /* GraphQL */ `
  query ListDialogueNotess(
    $filter: ModeldialogueNotesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDialogueNotess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        convoId
        messageId
        content
        author
        deleted
        botType
        editedBy {
          asurite
          timestamp
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChatbotAnnouncements = /* GraphQL */ `
  query GetChatbotAnnouncements($id: ID!) {
    getChatbotAnnouncements(id: $id) {
      id
      name
      text
      active
      createdBy
      editedBy {
        asurite
        timestamp
      }
      group
      showOn
      createdAt
      updatedAt
    }
  }
`;
export const listChatbotAnnouncementss = /* GraphQL */ `
  query ListChatbotAnnouncementss(
    $filter: ModelchatbotAnnouncementsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatbotAnnouncementss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        text
        active
        createdBy
        editedBy {
          asurite
          timestamp
        }
        group
        showOn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChatbotAnnouncementsHistorical = /* GraphQL */ `
  query GetChatbotAnnouncementsHistorical($id: ID!, $createdAt: String!) {
    getChatbotAnnouncementsHistorical(id: $id, createdAt: $createdAt) {
      id
      name
      createdAt
      text
      startTime
      startedBy
      endTime
      endedBy
      group
      showOn
      versionId
      updatedAt
    }
  }
`;
export const listChatbotAnnouncementsHistoricals = /* GraphQL */ `
  query ListChatbotAnnouncementsHistoricals(
    $id: ID
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelchatbotAnnouncementsHistoricalFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listChatbotAnnouncementsHistoricals(
      id: $id
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        createdAt
        text
        startTime
        startedBy
        endTime
        endedBy
        group
        showOn
        versionId
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChatbotAnnouncementAnalytics = /* GraphQL */ `
  query GetChatbotAnnouncementAnalytics($id: ID!) {
    getChatbotAnnouncementAnalytics(id: $id) {
      id
      asurite
      announcementId
      versionId
      action
      createdAt
      updatedAt
    }
  }
`;
export const listChatbotAnnouncementAnalyticss = /* GraphQL */ `
  query ListChatbotAnnouncementAnalyticss(
    $filter: ModelchatbotAnnouncementAnalyticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatbotAnnouncementAnalyticss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        asurite
        announcementId
        versionId
        action
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChatbotIntroMessage = /* GraphQL */ `
  query GetChatbotIntroMessage($id: ID!) {
    getChatbotIntroMessage(id: $id) {
      id
      message
      status
      statusKey
      botType
      matches
      group
      createdBy
      deleted
      deletedBy {
        asurite
        timestamp
      }
      searchKey
      createdAt
      updatedAt
    }
  }
`;
export const listChatbotIntroMessages = /* GraphQL */ `
  query ListChatbotIntroMessages(
    $filter: ModelchatbotIntroMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatbotIntroMessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        message
        status
        statusKey
        botType
        matches
        group
        createdBy
        deleted
        deletedBy {
          asurite
          timestamp
        }
        searchKey
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChatbotIntroMessageHistorical = /* GraphQL */ `
  query GetChatbotIntroMessageHistorical($id: ID!) {
    getChatbotIntroMessageHistorical(id: $id) {
      id
      introId
      message
      status
      statusKey
      botType
      matches
      startTime
      startedBy
      endTime
      endedBy
      versionId
      createdAt
      updatedAt
    }
  }
`;
export const listChatbotIntroMessageHistoricals = /* GraphQL */ `
  query ListChatbotIntroMessageHistoricals(
    $filter: ModelchatbotIntroMessageHistoricalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatbotIntroMessageHistoricals(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        introId
        message
        status
        statusKey
        botType
        matches
        startTime
        startedBy
        endTime
        endedBy
        versionId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChatbotIntroMessageAnalytics = /* GraphQL */ `
  query GetChatbotIntroMessageAnalytics($id: ID!) {
    getChatbotIntroMessageAnalytics(id: $id) {
      id
      asurite
      introId
      action
      createdAt
      updatedAt
    }
  }
`;
export const listChatbotIntroMessageAnalyticss = /* GraphQL */ `
  query ListChatbotIntroMessageAnalyticss(
    $filter: ModelchatbotIntroMessageAnalyticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatbotIntroMessageAnalyticss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        asurite
        introId
        action
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getImage = /* GraphQL */ `
  query GetImage($id: ID!) {
    getImage(id: $id) {
      id
      imageId
      title
      description
      author
      group
      deleted
      deletedBy {
        asurite
        timestamp
      }
      botType
      createdAt
      updatedAt
    }
  }
`;
export const listImages = /* GraphQL */ `
  query ListImages(
    $filter: ModelimageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        imageId
        title
        description
        author
        group
        deleted
        deletedBy {
          asurite
          timestamp
        }
        botType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLink = /* GraphQL */ `
  query GetLink($id: ID!) {
    getLink(id: $id) {
      id
      originalURL
      linkText
      createdBy
      group
      createdAt
      updatedAt
    }
  }
`;
export const listLinks = /* GraphQL */ `
  query ListLinks(
    $filter: ModellinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        originalURL
        linkText
        createdBy
        group
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLinkAnalytics = /* GraphQL */ `
  query GetLinkAnalytics($id: ID!) {
    getLinkAnalytics(id: $id) {
      id
      linkId
      createdAt
      aliasId
      clickedBy
      userType
      updatedAt
    }
  }
`;
export const listLinkAnalyticss = /* GraphQL */ `
  query ListLinkAnalyticss(
    $filter: ModellinkAnalyticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLinkAnalyticss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        linkId
        createdAt
        aliasId
        clickedBy
        userType
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLinkCreation = /* GraphQL */ `
  query GetLinkCreation($id: ID!) {
    getLinkCreation(id: $id) {
      id
      linkId
      aliasId
      tableId
      tableName
      createdAt
      updatedAt
    }
  }
`;
export const listLinkCreations = /* GraphQL */ `
  query ListLinkCreations(
    $filter: ModellinkCreationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLinkCreations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        linkId
        aliasId
        tableId
        tableName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChatbotAnalyticsTracker = /* GraphQL */ `
  query GetChatbotAnalyticsTracker($id: ID!) {
    getChatbotAnalyticsTracker(id: $id) {
      id
      event
      timestamp
      asurite
      phoneNumber
      convoId
      userId
      agentAsurite
      type
      firstName
      lastName
      emplid
      agentPauseAsurite
      agentOptOut
      userOptOut
      isPaused
      botType
      tempField
      duringCampaign
      campaigns {
        id
        status
        startTime
        endTime
        error
      }
      createdAt
      updatedAt
    }
  }
`;
export const listChatbotAnalyticsTrackers = /* GraphQL */ `
  query ListChatbotAnalyticsTrackers(
    $filter: ModelchatbotAnalyticsTrackerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatbotAnalyticsTrackers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        event
        timestamp
        asurite
        phoneNumber
        convoId
        userId
        agentAsurite
        type
        firstName
        lastName
        emplid
        agentPauseAsurite
        agentOptOut
        userOptOut
        isPaused
        botType
        tempField
        duringCampaign
        campaigns {
          id
          status
          startTime
          endTime
          error
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChatbotInteractionSessions = /* GraphQL */ `
  query GetChatbotInteractionSessions($sessionId: ID!) {
    getChatbotInteractionSessions(sessionId: $sessionId) {
      sessionId
      type
      callingNumber
      convoId
      status
      sendTranscript
      createdAt
      updatedAt
    }
  }
`;
export const listChatbotInteractionSessionss = /* GraphQL */ `
  query ListChatbotInteractionSessionss(
    $sessionId: ID
    $filter: ModelchatbotInteractionSessionsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listChatbotInteractionSessionss(
      sessionId: $sessionId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        sessionId
        type
        callingNumber
        convoId
        status
        sendTranscript
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byStatusNumber = /* GraphQL */ `
  query ByStatusNumber(
    $status: Int
    $sortDirection: ModelSortDirection
    $filter: ModelcampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byStatusNumber(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        titleKey
        description
        status
        statusKey
        audienceTargetType
        audience
        audienceFileContent
        audienceFileName
        fileUploadedBy
        flow
        flowVersion
        introFlow
        introFlowId
        introFlowVersion
        processing
        processingError
        startTime
        sendNumbers
        startTimeKey
        endTime
        endTimeKey
        timestamp
        timestampKey
        createdBy
        paused
        dryRun
        campaignTagAnswer
        campaignSelect
        pausedBy {
          asurite
          timestamp
        }
        approvedBy {
          asurite
          timestamp
        }
        type
        editedBy {
          asurite
          timestamp
        }
        deleted
        deletedBy {
          asurite
          timestamp
        }
        active
        group
        groupKey
        isSendingIntroMessage
        estSendMinutes
        smsButtonPresser
        smsButtonMsg
        numberProcessed
        numberToProcess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byStatusEndTime = /* GraphQL */ `
  query ByStatusEndTime(
    $status: Int
    $endTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelcampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byStatusEndTime(
      status: $status
      endTime: $endTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        titleKey
        description
        status
        statusKey
        audienceTargetType
        audience
        audienceFileContent
        audienceFileName
        fileUploadedBy
        flow
        flowVersion
        introFlow
        introFlowId
        introFlowVersion
        processing
        processingError
        startTime
        sendNumbers
        startTimeKey
        endTime
        endTimeKey
        timestamp
        timestampKey
        createdBy
        paused
        dryRun
        campaignTagAnswer
        campaignSelect
        pausedBy {
          asurite
          timestamp
        }
        approvedBy {
          asurite
          timestamp
        }
        type
        editedBy {
          asurite
          timestamp
        }
        deleted
        deletedBy {
          asurite
          timestamp
        }
        active
        group
        groupKey
        isSendingIntroMessage
        estSendMinutes
        smsButtonPresser
        smsButtonMsg
        numberProcessed
        numberToProcess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const manualSendbyStatusEndTime = /* GraphQL */ `
  query ManualSendbyStatusEndTime(
    $status: Int
    $endTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelcampaignManualSendFilterInput
    $limit: Int
    $nextToken: String
  ) {
    manualSendbyStatusEndTime(
      status: $status
      endTime: $endTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        campaignId
        audience
        status
        flow
        flowVersion
        audienceFileContent
        startTime
        endTime
        createdBy
        group
        type
        isTest
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listManaulSendByAudience = /* GraphQL */ `
  query ListManaulSendByAudience(
    $audienceFileContent: String
    $sortDirection: ModelSortDirection
    $filter: ModelcampaignManualSendFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listManaulSendByAudience(
      audienceFileContent: $audienceFileContent
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        campaignId
        audience
        status
        flow
        flowVersion
        audienceFileContent
        startTime
        endTime
        createdBy
        group
        type
        isTest
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const compCampByUserId = /* GraphQL */ `
  query CompCampByUserId(
    $userId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelcampaignCompletedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    compCampByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userId
        campaignId
        group
        flowId
        flowVersion
        lastNode
        exitType
        finishTime
        expireTime
        startTime
        type
        error
        isManualCamp
        expiredBeforeSend
        campaignInfo {
          items {
            id
            title
            titleKey
            description
            status
            statusKey
            audienceTargetType
            audience
            audienceFileContent
            audienceFileName
            fileUploadedBy
            flow
            flowVersion
            introFlow
            introFlowId
            introFlowVersion
            processing
            processingError
            startTime
            sendNumbers
            startTimeKey
            endTime
            endTimeKey
            timestamp
            timestampKey
            createdBy
            paused
            dryRun
            campaignTagAnswer
            campaignSelect
            type
            deleted
            active
            group
            groupKey
            isSendingIntroMessage
            estSendMinutes
            smsButtonPresser
            smsButtonMsg
            numberProcessed
            numberToProcess
            createdAt
            updatedAt
          }
          nextToken
        }
        asurite
        phoneNumber
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const compCampById = /* GraphQL */ `
  query CompCampById(
    $campaignId: ID
    $finishTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelcampaignCompletedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    compCampById(
      campaignId: $campaignId
      finishTime: $finishTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userId
        campaignId
        group
        flowId
        flowVersion
        lastNode
        exitType
        finishTime
        expireTime
        startTime
        type
        error
        isManualCamp
        expiredBeforeSend
        campaignInfo {
          items {
            id
            title
            titleKey
            description
            status
            statusKey
            audienceTargetType
            audience
            audienceFileContent
            audienceFileName
            fileUploadedBy
            flow
            flowVersion
            introFlow
            introFlowId
            introFlowVersion
            processing
            processingError
            startTime
            sendNumbers
            startTimeKey
            endTime
            endTimeKey
            timestamp
            timestampKey
            createdBy
            paused
            dryRun
            campaignTagAnswer
            campaignSelect
            type
            deleted
            active
            group
            groupKey
            isSendingIntroMessage
            estSendMinutes
            smsButtonPresser
            smsButtonMsg
            numberProcessed
            numberToProcess
            createdAt
            updatedAt
          }
          nextToken
        }
        asurite
        phoneNumber
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const queuedCampById = /* GraphQL */ `
  query QueuedCampById(
    $campaignId: ID
    $startTime: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelcampaignUserQueueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queuedCampById(
      campaignId: $campaignId
      startTime: $startTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userId
        campaignId
        flowId
        flowVersion
        expireTime
        startTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const queuedCampByUserId = /* GraphQL */ `
  query QueuedCampByUserId(
    $userId: ID
    $startTime: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelcampaignUserQueueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queuedCampByUserId(
      userId: $userId
      startTime: $startTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userId
        campaignId
        flowId
        flowVersion
        expireTime
        startTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const runningCampById = /* GraphQL */ `
  query RunningCampById(
    $campaignId: String
    $sortDirection: ModelSortDirection
    $filter: ModelcampaignRunningFilterInput
    $limit: Int
    $nextToken: String
  ) {
    runningCampById(
      campaignId: $campaignId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userId
        campaignId
        flowId
        flowVersion
        lastNode
        isManualCamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const runningVoiceCampById = /* GraphQL */ `
  query RunningVoiceCampById(
    $campaignId: String
    $sortDirection: ModelSortDirection
    $filter: ModelcampaignRunningVoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    runningVoiceCampById(
      campaignId: $campaignId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userId
        campaignId
        flowId
        flowVersion
        lastNode
        isManualCamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const answerVarByVarName = /* GraphQL */ `
  query AnswerVarByVarName(
    $varName: ID
    $sortDirection: ModelSortDirection
    $filter: ModelanswerVariablesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    answerVarByVarName(
      varName: $varName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        varName
        active
        group
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const customVarByUserAndKey = /* GraphQL */ `
  query CustomVarByUserAndKey(
    $user: String
    $key: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelcustomVariableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customVarByUserAndKey(
      user: $user
      key: $key
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        key
        value
        botType
        group
        campaignId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const customVarByBotType = /* GraphQL */ `
  query CustomVarByBotType(
    $botType: String
    $sortDirection: ModelSortDirection
    $filter: ModelcustomVariableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customVarByBotType(
      botType: $botType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        key
        value
        botType
        group
        campaignId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const customVarByGroup = /* GraphQL */ `
  query CustomVarByGroup(
    $group: String
    $sortDirection: ModelSortDirection
    $filter: ModelcustomVariableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customVarByGroup(
      group: $group
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        key
        value
        botType
        group
        campaignId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const customVarByCampId = /* GraphQL */ `
  query CustomVarByCampId(
    $campaignId: String
    $sortDirection: ModelSortDirection
    $filter: ModelcustomVariableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customVarByCampId(
      campaignId: $campaignId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        key
        value
        botType
        group
        campaignId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const customVarByUserAndCampId = /* GraphQL */ `
  query CustomVarByUserAndCampId(
    $user: String
    $campaignId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelcustomVariableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customVarByUserAndCampId(
      user: $user
      campaignId: $campaignId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        key
        value
        botType
        group
        campaignId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFlowBySearchTitle = /* GraphQL */ `
  query GetFlowBySearchTitle(
    $searchTitle: String
    $sortDirection: ModelSortDirection
    $filter: ModelflowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFlowBySearchTitle(
      searchTitle: $searchTitle
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        titleKey
        searchTitle
        description
        type
        statusX
        statusKey
        version
        versionInfo {
          id
          version
          createdAt
          title
          description
          variablesIncluded
          flowNodes
          submitted
          liveAgentEsc
          approved
          published
          disabled
          denied
          queryableData
          submittedBy {
            asurite
            timestamp
          }
          approvedBy {
            asurite
            timestamp
          }
          publishedBy {
            asurite
            timestamp
          }
          deniedBy {
            asurite
            timestamp
          }
          botType
          updatedAt
        }
        category
        timestamp
        submittedOn
        submittedOnKey
        editing {
          asurite
          timestamp
        }
        submittedBy
        submittedByKey
        paused
        approvedBy {
          asurite
          timestamp
        }
        editedBy {
          asurite
          timestamp
        }
        deleted
        deletedBy {
          asurite
          timestamp
        }
        group
        groupKey
        signOffMsg
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const respMessagesByGroupAndType = /* GraphQL */ `
  query RespMessagesByGroupAndType(
    $group: String
    $type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelresponseMessagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    respMessagesByGroupAndType(
      group: $group
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        author
        asurite
        type
        message
        number
        flowId
        flowLabel
        flowVersion
        flowVersionLabel
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const respMessagesByGroup = /* GraphQL */ `
  query RespMessagesByGroup(
    $group: String
    $sortDirection: ModelSortDirection
    $filter: ModelresponseMessagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    respMessagesByGroup(
      group: $group
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        author
        asurite
        type
        message
        number
        flowId
        flowLabel
        flowVersion
        flowVersionLabel
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userByAsurite = /* GraphQL */ `
  query UserByAsurite(
    $asurite: String
    $sortDirection: ModelSortDirection
    $filter: ModeluserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByAsurite(
      asurite: $asurite
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        asurite
        displayName
        role
        createdAt
        createdBy
        editedAt
        editedBy
        isActive
        tier1Support
        lastLogin
        groups
        updatedAt
      }
      nextToken
    }
  }
`;
export const groupByName = /* GraphQL */ `
  query GroupByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelchatbotGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        isActive
        email
        dialoguesFolders {
          name
          tagValue
          parent
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const flowByFlowId = /* GraphQL */ `
  query FlowByFlowId(
    $flowId: String
    $sortDirection: ModelSortDirection
    $filter: ModelflowNodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    flowByFlowId(
      flowId: $flowId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        flowId
        type
        customType
        text
        parent
        child
        ignore
        match
        matches {
          id
          match
        }
        matchText
        deleted
        deleteAudit {
          asurite
          timestamp
        }
        jumpToNodeId
        messageBody
        postSkipJump
        skipAfterTimes
        response
        escalationName
        escalationType
        image
        responseImage
        escalationDump
        templateId
        saveAsTemplate
        messageName
        ownerGroup
        audience
        branchDataCollector
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byFlowId = /* GraphQL */ `
  query ByFlowId(
    $flowId: String
    $sortDirection: ModelSortDirection
    $filter: ModelcampaignUserInputFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byFlowId(
      flowId: $flowId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        flowId
        campaignId
        nodeId
        timestamp
        button
        inputDump
        type
        flowVersion
        botType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byCampaignId = /* GraphQL */ `
  query ByCampaignId(
    $campaignId: String
    $sortDirection: ModelSortDirection
    $filter: ModelcampaignUserInputFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byCampaignId(
      campaignId: $campaignId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        flowId
        campaignId
        nodeId
        timestamp
        button
        inputDump
        type
        flowVersion
        botType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const escHistoryByConvoId = /* GraphQL */ `
  query EscHistoryByConvoId(
    $convoId: String
    $sortDirection: ModelSortDirection
    $filter: ModelescalationHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    escHistoryByConvoId(
      convoId: $convoId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        escalationId
        escalationName
        escalator
        hitType
        message
        messageId
        response
        user
        convoId
        timestamp
        group
        escalationType
        emailTo
        ccemail
        emailSubject
        emailBody
        attachChatLog
        sendTextMessage
        textMessageContent {
          to
          textBody
        }
        liveAgentCategory
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const responseByMessageId = /* GraphQL */ `
  query ResponseByMessageId(
    $messageId: String
    $sortDirection: ModelSortDirection
    $filter: ModelpromptResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    responseByMessageId(
      messageId: $messageId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        messageId
        asurite
        message
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const messageBySender = /* GraphQL */ `
  query MessageBySender(
    $sender: String
    $sortDirection: ModelSortDirection
    $filter: ModelchatbotMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messageBySender(
      sender: $sender
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        convoId
        createdAt
        messageId
        content
        sender
        type
        connectionId
        file
        agentAsurite
        agentAction
        agent
        botType
        sessionId
        updatedAt
      }
      nextToken
    }
  }
`;
export const messageByCreatedAt = /* GraphQL */ `
  query MessageByCreatedAt(
    $createdAt: String
    $sortDirection: ModelSortDirection
    $filter: ModelchatbotMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messageByCreatedAt(
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        convoId
        createdAt
        messageId
        content
        sender
        type
        connectionId
        file
        agentAsurite
        agentAction
        agent
        botType
        sessionId
        updatedAt
      }
      nextToken
    }
  }
`;
export const messageBySessionIdAndCreatedAt = /* GraphQL */ `
  query MessageBySessionIdAndCreatedAt(
    $sessionId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelchatbotMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messageBySessionIdAndCreatedAt(
      sessionId: $sessionId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        convoId
        createdAt
        messageId
        content
        sender
        type
        connectionId
        file
        agentAsurite
        agentAction
        agent
        botType
        sessionId
        updatedAt
      }
      nextToken
    }
  }
`;
export const messageById = /* GraphQL */ `
  query MessageById(
    $messageId: String
    $sortDirection: ModelSortDirection
    $filter: ModelchatbotMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messageById(
      messageId: $messageId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        convoId
        createdAt
        messageId
        content
        sender
        type
        connectionId
        file
        agentAsurite
        agentAction
        agent
        botType
        sessionId
        updatedAt
      }
      nextToken
    }
  }
`;
export const tagByMessageId = /* GraphQL */ `
  query TagByMessageId(
    $messageId: String
    $sortDirection: ModelSortDirection
    $filter: ModelchatbotMessageTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tagByMessageId(
      messageId: $messageId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        convoId
        messageId
        tag
        deleted
        addedBy
        deletedBy
        botType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const tagByConvoId = /* GraphQL */ `
  query TagByConvoId(
    $convoId: String
    $sortDirection: ModelSortDirection
    $filter: ModelchatbotMessageTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tagByConvoId(
      convoId: $convoId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        convoId
        messageId
        tag
        deleted
        addedBy
        deletedBy
        botType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const messageByTag = /* GraphQL */ `
  query MessageByTag(
    $tag: String
    $sortDirection: ModelSortDirection
    $filter: ModelchatbotMessageTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messageByTag(
      tag: $tag
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        convoId
        messageId
        tag
        deleted
        addedBy
        deletedBy
        botType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byAsurite = /* GraphQL */ `
  query ByAsurite(
    $asurite: String
    $sortDirection: ModelSortDirection
    $filter: ModelchatbotSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byAsurite(
      asurite: $asurite
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        asurite
        baseStartDate
        startTime
        endTime
        origin
        originLocation
        flowStatus
        connectionId
        totalTime
        caseNumber
        liveagentEsc
        sentMessages
        receivedMessages
        totalMessages
        dialogueEntered
        agentAsurite
        leftBeforeTransfer
        userId
        botType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byBaseStartDate = /* GraphQL */ `
  query ByBaseStartDate(
    $baseStartDate: String
    $sortDirection: ModelSortDirection
    $filter: ModelchatbotSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byBaseStartDate(
      baseStartDate: $baseStartDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        asurite
        baseStartDate
        startTime
        endTime
        origin
        originLocation
        flowStatus
        connectionId
        totalTime
        caseNumber
        liveagentEsc
        sentMessages
        receivedMessages
        totalMessages
        dialogueEntered
        agentAsurite
        leftBeforeTransfer
        userId
        botType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byOrigin = /* GraphQL */ `
  query ByOrigin(
    $origin: String
    $sortDirection: ModelSortDirection
    $filter: ModelchatbotSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byOrigin(
      origin: $origin
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        asurite
        baseStartDate
        startTime
        endTime
        origin
        originLocation
        flowStatus
        connectionId
        totalTime
        caseNumber
        liveagentEsc
        sentMessages
        receivedMessages
        totalMessages
        dialogueEntered
        agentAsurite
        leftBeforeTransfer
        userId
        botType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const sessionByConnectionId = /* GraphQL */ `
  query SessionByConnectionId(
    $connectionId: String
    $sortDirection: ModelSortDirection
    $filter: ModelchatbotSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sessionByConnectionId(
      connectionId: $connectionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        asurite
        baseStartDate
        startTime
        endTime
        origin
        originLocation
        flowStatus
        connectionId
        totalTime
        caseNumber
        liveagentEsc
        sentMessages
        receivedMessages
        totalMessages
        dialogueEntered
        agentAsurite
        leftBeforeTransfer
        userId
        botType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userStagingDataBySearchId = /* GraphQL */ `
  query UserStagingDataBySearchId(
    $searchId: String
    $sortDirection: ModelSortDirection
    $filter: ModelchatbotUserStagingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userStagingDataBySearchId(
      searchId: $searchId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        searchId
        peoplesoftData
        timestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const chatbotUserByAsurite = /* GraphQL */ `
  query ChatbotUserByAsurite(
    $asurite: String
    $sortDirection: ModelSortDirection
    $filter: ModelchatbotUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatbotUserByAsurite(
      asurite: $asurite
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        paused
        optOut
        userOptOut
        stopped
        botType
        type
        latest
        asurite
        phoneNumber
        homeNumber
        convoId
        firstName
        lastName
        displayName
        agentPauseAsurite
        agentOptOutAsurite
        reinstateDate
        altConvoIds
        tags {
          items {
            id
            userId
            tag
            deleted
            addedBy
            deletedBy
            botType
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const chatbotUserByPhone = /* GraphQL */ `
  query ChatbotUserByPhone(
    $phoneNumber: String
    $sortDirection: ModelSortDirection
    $filter: ModelchatbotUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatbotUserByPhone(
      phoneNumber: $phoneNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        paused
        optOut
        userOptOut
        stopped
        botType
        type
        latest
        asurite
        phoneNumber
        homeNumber
        convoId
        firstName
        lastName
        displayName
        agentPauseAsurite
        agentOptOutAsurite
        reinstateDate
        altConvoIds
        tags {
          items {
            id
            userId
            tag
            deleted
            addedBy
            deletedBy
            botType
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const chatbotUserByConvoId = /* GraphQL */ `
  query ChatbotUserByConvoId(
    $convoId: String
    $sortDirection: ModelSortDirection
    $filter: ModelchatbotUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatbotUserByConvoId(
      convoId: $convoId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        paused
        optOut
        userOptOut
        stopped
        botType
        type
        latest
        asurite
        phoneNumber
        homeNumber
        convoId
        firstName
        lastName
        displayName
        agentPauseAsurite
        agentOptOutAsurite
        reinstateDate
        altConvoIds
        tags {
          items {
            id
            userId
            tag
            deleted
            addedBy
            deletedBy
            botType
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const usersByType = /* GraphQL */ `
  query UsersByType(
    $botType: String
    $latest: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelchatbotUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByType(
      botType: $botType
      latest: $latest
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        paused
        optOut
        userOptOut
        stopped
        botType
        type
        latest
        asurite
        phoneNumber
        homeNumber
        convoId
        firstName
        lastName
        displayName
        agentPauseAsurite
        agentOptOutAsurite
        reinstateDate
        altConvoIds
        tags {
          items {
            id
            userId
            tag
            deleted
            addedBy
            deletedBy
            botType
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const chatbotUserByHomePhone = /* GraphQL */ `
  query ChatbotUserByHomePhone(
    $homeNumber: String
    $sortDirection: ModelSortDirection
    $filter: ModelchatbotUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatbotUserByHomePhone(
      homeNumber: $homeNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        paused
        optOut
        userOptOut
        stopped
        botType
        type
        latest
        asurite
        phoneNumber
        homeNumber
        convoId
        firstName
        lastName
        displayName
        agentPauseAsurite
        agentOptOutAsurite
        reinstateDate
        altConvoIds
        tags {
          items {
            id
            userId
            tag
            deleted
            addedBy
            deletedBy
            botType
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const tagByUserId = /* GraphQL */ `
  query TagByUserId(
    $userId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelchatbotUserTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tagByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        tag
        deleted
        addedBy
        deletedBy
        botType
        userInfo {
          items {
            id
            paused
            optOut
            userOptOut
            stopped
            botType
            type
            latest
            asurite
            phoneNumber
            homeNumber
            convoId
            firstName
            lastName
            displayName
            agentPauseAsurite
            agentOptOutAsurite
            reinstateDate
            altConvoIds
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userByTag = /* GraphQL */ `
  query UserByTag(
    $tag: String
    $sortDirection: ModelSortDirection
    $filter: ModelchatbotUserTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByTag(
      tag: $tag
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        tag
        deleted
        addedBy
        deletedBy
        botType
        userInfo {
          items {
            id
            paused
            optOut
            userOptOut
            stopped
            botType
            type
            latest
            asurite
            phoneNumber
            homeNumber
            convoId
            firstName
            lastName
            displayName
            agentPauseAsurite
            agentOptOutAsurite
            reinstateDate
            altConvoIds
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const convoTagByConvoId = /* GraphQL */ `
  query ConvoTagByConvoId(
    $convoId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelchatbotConvoTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    convoTagByConvoId(
      convoId: $convoId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        convoId
        tag
        deleted
        addedBy
        deletedBy
        botType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const convoByTag = /* GraphQL */ `
  query ConvoByTag(
    $tag: String
    $sortDirection: ModelSortDirection
    $filter: ModelchatbotConvoTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    convoByTag(
      tag: $tag
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        convoId
        tag
        deleted
        addedBy
        deletedBy
        botType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const convoByAsurite = /* GraphQL */ `
  query ConvoByAsurite(
    $asurite: String
    $sortDirection: ModelSortDirection
    $filter: ModelchatbotConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    convoByAsurite(
      asurite: $asurite
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        latest
        asurite
        userId
        botType
        type
        paused
        new
        optOut
        unseenBy
        agents
        userOptOut
        agentPauseAsurite
        agentOptOutAsurite
        reinstateDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const dialogueRuleByGroup = /* GraphQL */ `
  query DialogueRuleByGroup(
    $assignedGroup: String
    $sortDirection: ModelSortDirection
    $filter: ModelchatbotDialogueRuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dialogueRuleByGroup(
      assignedGroup: $assignedGroup
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        displayName
        keywords
        active
        deleted
        assignedFolder
        assignedGroup
        assignedUser
        submittedBy
        editedBy {
          asurite
          timestamp
        }
        deletedBy {
          asurite
          timestamp
        }
        botType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const dialogueRuleByName = /* GraphQL */ `
  query DialogueRuleByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelchatbotDialogueRuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dialogueRuleByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        displayName
        keywords
        active
        deleted
        assignedFolder
        assignedGroup
        assignedUser
        submittedBy
        editedBy {
          asurite
          timestamp
        }
        deletedBy {
          asurite
          timestamp
        }
        botType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const websocketConnectionByConnectionId = /* GraphQL */ `
  query WebsocketConnectionByConnectionId(
    $connectionId: ID
    $stage: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelchatbotWebsocketConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    websocketConnectionByConnectionId(
      connectionId: $connectionId
      stage: $stage
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        connectionId
        convoId
        agentChatting
        agentDetails {
          asurite
          displayName
        }
        asurite
        domainName
        phoneNumber
        stage
        type
        webConnection
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const websocketConnectionByConvoId = /* GraphQL */ `
  query WebsocketConnectionByConvoId(
    $convoId: String
    $sortDirection: ModelSortDirection
    $filter: ModelchatbotWebsocketConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    websocketConnectionByConvoId(
      convoId: $convoId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        connectionId
        convoId
        agentChatting
        agentDetails {
          asurite
          displayName
        }
        asurite
        domainName
        phoneNumber
        stage
        type
        webConnection
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const intentByDate = /* GraphQL */ `
  query IntentByDate(
    $intentId: String
    $timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModeltriggeredIntentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    intentByDate(
      intentId: $intentId
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        intentId
        asurite
        timestamp
        phrase
        kbId
        response
        originSite
        baseDate
        sessionId
        escalationInfo
        botType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byBaseDate = /* GraphQL */ `
  query ByBaseDate(
    $baseDate: String
    $sortDirection: ModelSortDirection
    $filter: ModeltriggeredIntentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byBaseDate(
      baseDate: $baseDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        intentId
        asurite
        timestamp
        phrase
        kbId
        response
        originSite
        baseDate
        sessionId
        escalationInfo
        botType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const originByDate = /* GraphQL */ `
  query OriginByDate(
    $origin: String
    $baseDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelutterancesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    originByDate(
      origin: $origin
      baseDate: $baseDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        phrase
        compKey
        baseDate
        origin
        count
        detected
        missed
        botType
        users
        alternates
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const utterancebyBaseDate = /* GraphQL */ `
  query UtterancebyBaseDate(
    $baseDate: String
    $sortDirection: ModelSortDirection
    $filter: ModelutterancesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    utterancebyBaseDate(
      baseDate: $baseDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        phrase
        compKey
        baseDate
        origin
        count
        detected
        missed
        botType
        users
        alternates
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const campByFlowId = /* GraphQL */ `
  query CampByFlowId(
    $flowId: String
    $sortDirection: ModelSortDirection
    $filter: ModelpendingCampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    campByFlowId(
      flowId: $flowId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sendTime
        flowId
        users
        type
        flowNodes
        parent
        expireTime
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const notesByConvoId = /* GraphQL */ `
  query NotesByConvoId(
    $convoId: String
    $sortDirection: ModelSortDirection
    $filter: ModeldialogueNotesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notesByConvoId(
      convoId: $convoId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        convoId
        messageId
        content
        author
        deleted
        botType
        editedBy {
          asurite
          timestamp
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const annoucementByLocation = /* GraphQL */ `
  query AnnoucementByLocation(
    $showOn: String
    $sortDirection: ModelSortDirection
    $filter: ModelchatbotAnnouncementsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    annoucementByLocation(
      showOn: $showOn
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        text
        active
        createdBy
        editedBy {
          asurite
          timestamp
        }
        group
        showOn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const introMessageByBotType = /* GraphQL */ `
  query IntroMessageByBotType(
    $botType: String
    $sortDirection: ModelSortDirection
    $filter: ModelchatbotIntroMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    introMessageByBotType(
      botType: $botType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        message
        status
        statusKey
        botType
        matches
        group
        createdBy
        deleted
        deletedBy {
          asurite
          timestamp
        }
        searchKey
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const introMessageByStatus = /* GraphQL */ `
  query IntroMessageByStatus(
    $status: String
    $sortDirection: ModelSortDirection
    $filter: ModelchatbotIntroMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    introMessageByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        message
        status
        statusKey
        botType
        matches
        group
        createdBy
        deleted
        deletedBy {
          asurite
          timestamp
        }
        searchKey
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const introMessageHistoricalByIntroId = /* GraphQL */ `
  query IntroMessageHistoricalByIntroId(
    $introId: String
    $sortDirection: ModelSortDirection
    $filter: ModelchatbotIntroMessageHistoricalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    introMessageHistoricalByIntroId(
      introId: $introId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        introId
        message
        status
        statusKey
        botType
        matches
        startTime
        startedBy
        endTime
        endedBy
        versionId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const linkByLinkId = /* GraphQL */ `
  query LinkByLinkId(
    $linkId: String
    $sortDirection: ModelSortDirection
    $filter: ModellinkCreationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    linkByLinkId(
      linkId: $linkId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        linkId
        aliasId
        tableId
        tableName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const linkByAliasId = /* GraphQL */ `
  query LinkByAliasId(
    $aliasId: String
    $sortDirection: ModelSortDirection
    $filter: ModellinkCreationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    linkByAliasId(
      aliasId: $aliasId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        linkId
        aliasId
        tableId
        tableName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const linkByTableName = /* GraphQL */ `
  query LinkByTableName(
    $tableName: String
    $sortDirection: ModelSortDirection
    $filter: ModellinkCreationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    linkByTableName(
      tableName: $tableName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        linkId
        aliasId
        tableId
        tableName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const analyticsByAsurite = /* GraphQL */ `
  query AnalyticsByAsurite(
    $asurite: String
    $sortDirection: ModelSortDirection
    $filter: ModelchatbotAnalyticsTrackerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    analyticsByAsurite(
      asurite: $asurite
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        event
        timestamp
        asurite
        phoneNumber
        convoId
        userId
        agentAsurite
        type
        firstName
        lastName
        emplid
        agentPauseAsurite
        agentOptOut
        userOptOut
        isPaused
        botType
        tempField
        duringCampaign
        campaigns {
          id
          status
          startTime
          endTime
          error
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const analyticsByPhoneNumber = /* GraphQL */ `
  query AnalyticsByPhoneNumber(
    $phoneNumber: String
    $sortDirection: ModelSortDirection
    $filter: ModelchatbotAnalyticsTrackerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    analyticsByPhoneNumber(
      phoneNumber: $phoneNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        event
        timestamp
        asurite
        phoneNumber
        convoId
        userId
        agentAsurite
        type
        firstName
        lastName
        emplid
        agentPauseAsurite
        agentOptOut
        userOptOut
        isPaused
        botType
        tempField
        duringCampaign
        campaigns {
          id
          status
          startTime
          endTime
          error
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const analyticsByEvent = /* GraphQL */ `
  query AnalyticsByEvent(
    $event: String
    $timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelchatbotAnalyticsTrackerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    analyticsByEvent(
      event: $event
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        event
        timestamp
        asurite
        phoneNumber
        convoId
        userId
        agentAsurite
        type
        firstName
        lastName
        emplid
        agentPauseAsurite
        agentOptOut
        userOptOut
        isPaused
        botType
        tempField
        duringCampaign
        campaigns {
          id
          status
          startTime
          endTime
          error
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const interactionSessionByStatus = /* GraphQL */ `
  query InteractionSessionByStatus(
    $status: String
    $sortDirection: ModelSortDirection
    $filter: ModelchatbotInteractionSessionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    interactionSessionByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        sessionId
        type
        callingNumber
        convoId
        status
        sendTranscript
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchCampaigns = /* GraphQL */ `
  query SearchCampaigns(
    $filter: SearchablecampaignFilterInput
    $sort: SearchablecampaignSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCampaigns(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        title
        titleKey
        description
        status
        statusKey
        audienceTargetType
        audience
        audienceFileContent
        audienceFileName
        fileUploadedBy
        flow
        flowVersion
        introFlow
        introFlowId
        introFlowVersion
        processing
        processingError
        startTime
        sendNumbers
        startTimeKey
        endTime
        endTimeKey
        timestamp
        timestampKey
        createdBy
        paused
        dryRun
        campaignTagAnswer
        campaignSelect
        pausedBy {
          asurite
          timestamp
        }
        approvedBy {
          asurite
          timestamp
        }
        type
        editedBy {
          asurite
          timestamp
        }
        deleted
        deletedBy {
          asurite
          timestamp
        }
        active
        group
        groupKey
        isSendingIntroMessage
        estSendMinutes
        smsButtonPresser
        smsButtonMsg
        numberProcessed
        numberToProcess
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchCampaignManualSends = /* GraphQL */ `
  query SearchCampaignManualSends(
    $filter: SearchablecampaignManualSendFilterInput
    $sort: SearchablecampaignManualSendSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCampaignManualSends(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        campaignId
        audience
        status
        flow
        flowVersion
        audienceFileContent
        startTime
        endTime
        createdBy
        group
        type
        isTest
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchFlows = /* GraphQL */ `
  query SearchFlows(
    $filter: SearchableflowFilterInput
    $sort: SearchableflowSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchFlows(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        title
        titleKey
        searchTitle
        description
        type
        statusX
        statusKey
        version
        versionInfo {
          id
          version
          createdAt
          title
          description
          variablesIncluded
          flowNodes
          submitted
          liveAgentEsc
          approved
          published
          disabled
          denied
          queryableData
          submittedBy {
            asurite
            timestamp
          }
          approvedBy {
            asurite
            timestamp
          }
          publishedBy {
            asurite
            timestamp
          }
          deniedBy {
            asurite
            timestamp
          }
          botType
          updatedAt
        }
        category
        timestamp
        submittedOn
        submittedOnKey
        editing {
          asurite
          timestamp
        }
        submittedBy
        submittedByKey
        paused
        approvedBy {
          asurite
          timestamp
        }
        editedBy {
          asurite
          timestamp
        }
        deleted
        deletedBy {
          asurite
          timestamp
        }
        group
        groupKey
        signOffMsg
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchKnowledgeBaseCategorys = /* GraphQL */ `
  query SearchKnowledgeBaseCategorys(
    $filter: SearchableknowledgeBaseCategoryFilterInput
    $sort: SearchableknowledgeBaseCategorySortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchKnowledgeBaseCategorys(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        title
        group
        subcategories {
          title
        }
        deleted
        deletedBy {
          asurite
          timestamp
        }
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchKnowledgeBases = /* GraphQL */ `
  query SearchKnowledgeBases(
    $filter: SearchableknowledgeBaseFilterInput
    $sort: SearchableknowledgeBaseSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchKnowledgeBases(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        categoryID
        categoryName
        subcategoryNames
        group
        groupKey
        comments
        commentsKey
        deletedBy {
          asurite
          timestamp
        }
        editedBy {
          asurite
          timestamp
        }
        editedByKey
        lastEdit
        lastEditKey
        approvedBy {
          asurite
          timestamp
        }
        question
        status
        statusKey
        submittedBy
        submittedByKey
        submittedOn
        submittedOnKey
        updatedOn
        variations
        variationsCount
        variationsCountKey
        answerData {
          answer
          flow
          flowVersion
          audience
          audienceEveryone
          type
          answerEscalation
          image
        }
        modality
        fallbackAnswer
        fallbackAnswerImage
        deleted
        escalation
        editing {
          asurite
          timestamp
        }
        processing
        processingError
        flow
        flowVersion
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableuserFilterInput
    $sort: SearchableuserSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        asurite
        displayName
        role
        createdAt
        createdBy
        editedAt
        editedBy
        isActive
        tier1Support
        lastLogin
        groups
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchCriticalEscalations = /* GraphQL */ `
  query SearchCriticalEscalations(
    $filter: SearchablecriticalEscalationFilterInput
    $sort: SearchablecriticalEscalationSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCriticalEscalations(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        triggerPhrase
        escalation
        variations
        status
        statusKey
        editing {
          asurite
          timestamp
        }
        processing
        processingError
        escalateSms
        answerData {
          answer
          flow
          flowVersion
          audience
          audienceEveryone
          type
          answerEscalation
          image
        }
        fallbackAnswer
        fallbackAnswerImage
        deleted
        group
        createdAt
        createdAtKey
        submittedBy
        submittedByKey
        response
        editedBy {
          asurite
          timestamp
        }
        deletedBy {
          asurite
          timestamp
        }
        timestamp
        timestampKey
        lastEdit
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchEscalations = /* GraphQL */ `
  query SearchEscalations(
    $filter: SearchableescalationFilterInput
    $sort: SearchableescalationSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchEscalations(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        escalationName
        group
        groupKey
        escalationType
        emailTo
        ccemail
        emailSubject
        emailBody
        attachChatLog
        sendTextMessage
        status
        statusKey
        textMessageContent {
          to
          textBody
        }
        submittedBy
        submittedByKey
        submittedAt
        submittedAtKey
        liveAgentCategory
        attachedAlertIds
        editedBy {
          asurite
          timestamp
        }
        deleted
        timestamp
        timestampKey
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchChatbotSessions = /* GraphQL */ `
  query SearchChatbotSessions(
    $filter: SearchablechatbotSessionFilterInput
    $sort: SearchablechatbotSessionSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchChatbotSessions(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        asurite
        baseStartDate
        startTime
        endTime
        origin
        originLocation
        flowStatus
        connectionId
        totalTime
        caseNumber
        liveagentEsc
        sentMessages
        receivedMessages
        totalMessages
        dialogueEntered
        agentAsurite
        leftBeforeTransfer
        userId
        botType
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchImages = /* GraphQL */ `
  query SearchImages(
    $filter: SearchableimageFilterInput
    $sort: SearchableimageSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchImages(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        imageId
        title
        description
        author
        group
        deleted
        deletedBy {
          asurite
          timestamp
        }
        botType
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
