import React, { useState, useEffect, useContext } from 'react';
import { useTheme } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useFormContext } from 'react-hook-form';

import { store } from 'contexts/GlobalStore';
// import TextOptions from "./TextOptions";
// import FlowOptions from "./FlowOptions";
import { Select, ErrorLabel } from 'stories';
import { answerGenerateUID } from './utility';
import Options from './Options';

export default function Answer(props) {
  const theme = useTheme();
  const { register, errors, setValue, setError, clearError } = useFormContext();
  const GlobalState = useContext(store);
  const { dispatch } = GlobalState;
  const { checkForErrors } = GlobalState.state;

  const [type, setType] = useState('');
  const [options, setOptions] = useState([]);
  const [data, setData] = useState('');

  useEffect(() => {
    clearError();
    dispatch({
      type: 'setCheckForErrors',
      checkForErrors: false,
    });
    if (props.stateValue) {
      let newData = {};
      let newOptions = [];
      props.stateValue.forEach((el, index) => {
        const uid = answerGenerateUID();
        newData[uid] = {
          answer: el.answer,
          audience: el.audience,
          audienceEveryone: el.audienceEveryone, //el.audience === "EV" ? true : false,
          flow: el.flow,
          flowVersion: el.flowVersion,
          type: el.type,
          image: el.image,
          escalation: el.answerEscalation,
        };
        const newOption = {
          componentProps: { ...el },
          uid,
        };
        newOptions.push(newOption);
      });

      setOptions(newOptions);
      setData(prevData => ({
        ...prevData,
        ...newData,
      }));
    }
  }, []);

  const handleAdd = () => {
    if (options.length >= 6) {
      setError(
        'answerData',
        'maxOptions',
        'You can only have 6 answer options'
      );
      setTimeout(() => {
        clearError('answerData');
      }, 5000);
      return;
    } else if (type !== '') {
      dispatch({
        type: 'setCheckForErrors',
        checkForErrors: false,
      });
      clearError('answerData');

      let answerType = undefined;

      if (type === 'SMS Answer') {
        answerType = 'sms';
      } else if (type === 'Web Answer') {
        answerType = 'chat';
      } else if (type === 'Voice Answer') {
        answerType = 'voice';
      } else {
        answerType = type; // other types
      }

      const newOption = {
        // component: type === "Text Answer" ? TextOptions : FlowOptions,
        componentProps: { type: answerType }, //!
        uid: answerGenerateUID(),
      };

      setOptions([...options, newOption]);

      setType('');

      return undefined;
    }
  };

  const onChangeType = type => {
    if (typeof type === 'string') {
      setType(type);
    } else {
      setType(type.currentTarget.value);
    }
  };

  const computeAnswerData = () => {
    if (!data) {
      checkForErrors &&
        setError('answerData', 'maxOptions', 'This field is required');
      return '';
    } else if (!data || Object.entries(data).length === 0) {
      setData('');
      setValue('answerData', null);
      return '';
    } else {
      if (window.location.href.includes('CustomIntro')) {
        setValue('answerData', JSON.stringify(data));
      }
      return JSON.stringify(data);
    }
  };

  const renderInput = () => {
    if (props.type === 'text') {
      return (
        <input
          type="text"
          className="input input--textAnswer"
          value={type}
          onChange={onChangeType}
        />
      );
    } else {
      return (
        <Select
          options={[
            { value: 'SMS Answer', label: 'SMS Answer' },
            { value: 'Web Answer', label: 'Web Answer' },
            { value: 'Voice Answer', label: 'Voice Answer' },
          ]}
          onChange={onChangeType}
          style={{
            backgroundColor: 'white',
            width: '100%',
          }}
        />
      );
    }
  };

  const {
    answer,
    getEscalationName,
    escalationData,
    stateValue,
    ...validProps
  } = props;

  return (
    <div style={{ ...props.style }}>
      <div
        style={{
          display: 'flex',
          backgroundColor: theme.palette.general.lightGrey,
          padding: '0.5rem',
          border: `1px solid ${theme.palette.general.deepGrey}`,
          borderRadius: '4px',
        }}
      >
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {renderInput()}
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <AddCircleOutlineIcon
                style={{
                  fontSize: '30px',
                  marginLeft: '0.5rem',
                  color: theme.palette.general.deepGrey,
                  cursor: 'pointer',
                }}
                onClick={handleAdd}
              />
            </div>
          </div>
          {errors.answerData && errors.answerData.message ? (
            <div
              style={{
                display: 'flex',
                flex: 1,
                alignItems: 'center',
              }}
            >
              <ErrorLabel message={errors.answerData.message} />
            </div>
          ) : null}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '1px',
            }}
          >
            <div style={{ display: 'flex', flex: 1 }} />
            <input
              {...validProps}
              name="answerData"
              ref={props.required ? register({ required: true }) : register()}
              value={computeAnswerData()}
              readOnly
              autoComplete="off"
              style={{
                opacity: 0,
                height: '1px',
                width: '1px',
                cursor: 'default',
              }}
            />
            <div style={{ display: 'flex', flex: 1 }} />
          </div>
        </div>
      </div>
      <div style={{ marginTop: '0.5rem' }}>
        {options.map((el, index) => {
          return (
            <Options
              required={props.required ? true : undefined}
              key={el.uid}
              uid={el.uid}
              dataKey={index}
              setType={setType}
              options={options}
              setOptions={setOptions}
              data={data}
              setData={setData}
              type={el.componentProps.type}
              escalationData={escalationData}
              getEscalationName={getEscalationName}
              answerEscalationId={el.componentProps.answerEscalation}
            />
          );
        })}
      </div>
    </div>
  );
}
