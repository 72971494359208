import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';

import { Dashboard } from 'pages/Dashboard';
import { KnowledgeBase, KBForm } from 'pages/KnowledgeBase';
import { Flows, Holder } from 'pages/Flows';
import { FlowMessages, FlowMessagesForm } from 'pages/FlowMessages';
import { Campaigns, CampaignForm } from 'pages/Campaigns';
import { Dialogues } from 'pages/Dialogues';
import { UsersGroups } from 'pages/UsersGroups';
import { UserAdd } from 'pages/UserAdd';
import { Analyze } from 'pages/Analyze';
import { Escalations, EscalationAdd } from 'pages/Escalations';
import { ImageGallery, AddImage } from 'pages/ImageGallery';
import { Announcements } from 'pages/Announcements';
import {
  CustomIntroductions,
  CustomIntroForm,
} from 'pages/CustomIntroductions';
import { Alerts, AlertAdd } from 'pages/Alerts';
import { Settings } from 'pages/Settings';
import { Landing } from 'pages/Landing';
import { Responses, AddResponse } from 'pages/Responses';
import { AdminMaintenance } from 'pages/Admin';
import AuthContext from 'contexts/AuthContext';
// import Holder from "pages/Flows/FlowDiagram/Holder";

export default function Main(props) {
  const tokens = useContext(AuthContext);

  const roleCheck = component => {
    if (tokens.role === 'chat agent') {
      return <Redirect exact to="/dialogues" />;
    } else {
      return component;
    }
  };

  const roleCheck2 = component => {
    if (!tokens.role.includes('publisher') && !tokens.role.includes('admin')) {
      return <Redirect exact to="/dialogues" />;
    } else {
      return component;
    }
  };

  return (
    <div
      className="Main-container"
      style={{ marginTop: props.hasStatusBar ? '7em' : '4.5em' }}
    >
      {/* <div
        style={{
          backgroundColor: "#f4f4f4",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh"
        }}
      ></div> */}
      <div style={{ position: 'relative' }} className="positionInner">
        <Switch>
          {/* {
            tokens.role === "chat agent" ? (
            <Redirect exact from="/" to="/dialogues" />
          ) : ( */}
          <Redirect exact from="/" to="/knowledge-base" />
          {/* )
          } */}
          <Route
            path="/dashboard"
            render={() =>
              roleCheck(
                <Dashboard
                  navigation={props.navigation}
                  history={props.history}
                  location={props.location}
                />
              )
            }
          />
          <Route
            path="/knowledge-base"
            render={() =>
              roleCheck(
                <KnowledgeBase
                  navigation={props.navigation}
                  history={props.history}
                  location={props.location}
                />
              )
            }
          />
          <Route
            path="/knowledge-base/add-FAQ"
            render={() =>
              roleCheck(
                <KBForm
                  navigation={props.navigation}
                  history={props.history}
                  {...props}
                />
              )
            }
          />
          <Route
            path="/flows"
            render={() =>
              roleCheck(
                <Flows
                  navigation={props.navigation}
                  history={props.history}
                  location={props.location}
                />
              )
            }
          />
          <Route
            path="/flow-messages"
            render={() =>
              roleCheck(
                <FlowMessages
                  navigation={props.navigation}
                  history={props.history}
                  location={props.location}
                />
              )
            }
          />

          <Route
            path="/flow-message-form"
            render={() =>
              roleCheck(
                <FlowMessagesForm
                  navigation={props.navigation}
                  history={props.history}
                  location={props.location}
                />
              )
            }
          />
          <Route
            path="/view-flow"
            render={() =>
              roleCheck(
                <Holder
                  navigation={props.navigation}
                  history={props.history}
                  location={props.location}
                />
              )
            }
          />
          <Route
            path="/add-edit-flows"
            render={() =>
              roleCheck(
                <Flows
                  navigation={props.navigation}
                  history={props.history}
                  location={props.location}
                />
              )
            }
          />
          <Route
            path="/campaigns"
            render={() =>
              roleCheck(
                <Campaigns
                  navigation={props.navigation}
                  history={props.history}
                  location={props.location}
                />
              )
            }
          />
          <Route
            path="/campaign-form"
            render={() =>
              roleCheck(
                <CampaignForm
                  navigation={props.navigation}
                  history={props.history}
                  location={props.location}
                />
              )
            }
          />
          <Route
            path="/dialogues"
            render={() => (
              <Dialogues
                navigation={props.navigation}
                history={props.history}
                location={props.location}
              />
            )}
          />
          <Route
            path="/users-groups"
            render={
              () => (
                // roleCheck(
                <UsersGroups
                  navigation={props.navigation}
                  history={props.history}
                  location={props.location}
                />
              )
              // )
            }
          />
          <Route
            path="/user-add"
            render={() =>
              roleCheck(
                <UserAdd
                  navigation={props.navigation}
                  history={props.history}
                />
              )
            }
          />
          <Route
            path="/escalation-add"
            render={() =>
              roleCheck(
                <EscalationAdd
                  navigation={props.navigation}
                  history={props.history}
                />
              )
            }
          />
          <Route
            path="/escalations"
            render={() =>
              roleCheck(
                <Escalations
                  navigation={props.navigation}
                  history={props.history}
                  location={props.location}
                />
              )
            }
          />
          <Route
            path="/alerts"
            render={() =>
              roleCheck(
                <Alerts
                  navigation={props.navigation}
                  history={props.history}
                  location={props.location}
                />
              )
            }
          />
          <Route
            path="/alert-add"
            render={() => roleCheck(<AlertAdd navigation={props.navigation} />)}
          />
          <Route
            path="/analyze"
            render={() =>
              roleCheck(
                <Analyze
                  navigation={props.navigation}
                  history={props.history}
                  location={props.location}
                />
              )
            }
          />
          <Route
            path="/responses"
            render={() =>
              roleCheck(
                <Responses
                  navigation={props.navigation}
                  history={props.history}
                  location={props.location}
                />
              )
            }
          />
          <Route
            path="/response-add"
            render={() =>
              roleCheck(
                <AddResponse
                  navigation={props.navigation}
                  history={props.history}
                  location={props.location}
                />
              )
            }
          />
          <Route
            path="/image-gallery"
            render={
              () => (
                // roleCheck(
                <ImageGallery
                  navigation={props.navigation}
                  history={props.history}
                  location={props.location}
                />
              )
              // )
            }
          />
          <Route
            path="/add-image"
            render={
              () => (
                // roleCheck(
                <AddImage
                  navigation={props.navigation}
                  history={props.history}
                  location={props.location}
                />
              )
              // )
            }
          />
          <Route
            path="/announcements"
            render={() =>
              roleCheck2(
                <Announcements
                  navigation={props.navigation}
                  history={props.history}
                  location={props.location}
                />
              )
            }
          />
          <Route
            path="/CustomIntroductions"
            render={() =>
              roleCheck(
                <CustomIntroductions
                  navigation={props.navigation}
                  history={props.history}
                  location={props.location}
                />
              )
            }
          />
          <Route
            path="/CustomIntroForm"
            render={() =>
              roleCheck(
                <CustomIntroForm
                  navigation={props.navigation}
                  history={props.history}
                  location={props.location}
                />
              )
            }
          />
          <Route
            path="/settings"
            render={() =>
              roleCheck(
                <Settings
                  navigation={props.navigation}
                  history={props.history}
                  location={props.location}
                />
              )
            }
          />

          <Route
            path="/admin-maintenance"
            render={() =>
              roleCheck(
                <AdminMaintenance
                  type="maintenance"
                  navigation={props.navigation}
                  history={props.history}
                  location={props.location}
                />
              )
            }
          />

          <Route
            path="/admin-status"
            render={() =>
              roleCheck(
                <AdminMaintenance
                  type="status"
                  navigation={props.navigation}
                  history={props.history}
                  location={props.location}
                />
              )
            }
          />

          <Redirect to="/" />
        </Switch>
      </div>
    </div>
  );
}

Main.propTypes = {
  navigation: PropTypes.object,
};
