import React, { useState, useEffect, useContext } from 'react';
import AuthContext from 'contexts/AuthContext';
import { MiniTable, PanelButton } from 'stories';
import moment from 'moment';

import { API, graphqlOperation, Storage } from 'aws-amplify';
import * as queries from 'graphql/queries';

import '../CampaignTabs.scss';

export default function ImportSummaryErrors(props) {
  const [csvData, setCsvData] = useState(null);
  const [liveImportFails, setLiveImportFails] = useState(props.importFails);
  const [addedLiveData, setAddedLiveData] = useState(false);

  const tokens = useContext(AuthContext);

  useEffect(() => {
    let keys = ['phone', 'date', 'errorCode'];

    let data = [keys];

    let fullarry = props.importFails;

    for (var i = 0; i < fullarry.length; ++i) {
      let tArr = [];
      for (var k = 0; k < keys.length; ++k) {
        tArr.push(fullarry[i][keys[k]]);
      }
      data.push(tArr);
    }

    console.log(data, props.summaryData);

    setCsvData(data);
  }, [props.importFails]);

  const getUserData = async phoneNumber => {
    let resp = await API.graphql(
      graphqlOperation(queries.searchMessages, {
        operation: 'queryForUsers',
        payload: JSON.stringify({
          phoneNumber: phoneNumber,
          botType: 'sunny',
        }),
      })
    );
    return resp;
  };

  useEffect(() => {
    async function getAllData() {
      let allData = [];
      const { found, errors, newNums, sentNums } = props.summaryData;

      if (found !== undefined) {
        allData = allData.concat(found);
      }
      if (errors !== undefined) {
        allData = allData.concat(errors);
      }
      if (newNums !== undefined) {
        allData = allData.concat(newNums);
      }
      if (sentNums !== undefined) {
        allData = allData.concat(sentNums);
      }

      let foundNums = [];
      for (var i = allData.length - 1; i >= 0; --i) {
        if (foundNums.indexOf(allData[i].user) === -1) {
          foundNums.push(allData[i].user);
        } else {
          allData.splice(i, 1);
        }
      }

      for (const data of allData) {
        let phoneNumber = data.user;
        let promises = [];

        if (typeof phoneNumber !== undefined || phoneNumber != null) {
          promises.push(getUserData(phoneNumber));
        }

        let result = (await Promise.all(promises))[0];

        if (typeof result !== undefined) {
          let user = JSON.parse(result.data.searchMessages)[0];
          let extendedUser = null;
          let createdDate = '';
          let optOutStatus = '';

          if (JSON.parse(result.data.searchMessages).length > 1) {
            extendedUser = JSON.parse(result.data.searchMessages)[1];
            createdDate = extendedUser.createdAt ? extendedUser.createdAt : '';
          }

          if (typeof user !== 'undefined') {
            optOutStatus =
              user.userOptOut === true
                ? 'User'
                : user.optOut === true
                ? 'Agent'
                : '';
          }

          if (optOutStatus !== '') {
            let newImportFails = [...liveImportFails];
            let alreadyHasNumber = false;
            newImportFails.some(row => {
              if (row.phoneNumber === user.phoneNumber) {
                alreadyHasNumber = true;
                return true;
              }
            });
            if (!alreadyHasNumber) {
              newImportFails.push({
                phone: user.phoneNumber,
                date: createdDate,
                errorCode:
                  optOutStatus === 'Agent'
                    ? 'Opt-Out by Agent'
                    : optOutStatus === 'User'
                    ? 'User opted out'
                    : '',
              });

              setLiveImportFails(newImportFails);
              setAddedLiveData(true);
            }
          }
        }

        console.log('111 result', result);
      }
    }

    getAllData();

    /*let phoneNumbers = []


    props.importFails.forEach((row) => {

    });

    props.importFails = props.importFails.forEach*/
  }, []);

  if (!addedLiveData) {
    return (
      <div style={{ position: 'relative' }} key="nonlivedata">
        <div
          style={{
            position: 'absolute',
            top: '0px',
            right: '1.2em',
            zIndex: '2',
          }}
        >
          <PanelButton
            type="csv"
            csvData={csvData}
            csvFileName={`${
              props.editInfo && props.editInfo.title
                ? props.editInfo.title
                : 'chatbot'
            }-importErrors-${moment().format()}.csv`}
            label="Export Data"
          />
        </div>

        <div className="panelSection__title">
          <div className="panelHeader panelHeaderText">
            Campaign failures: {liveImportFails.length}
          </div>
        </div>

        <MiniTable
          tableData={liveImportFails}
          columnTitles={['Phone', 'Date', 'Error Code']}
          dataKeys={['phone', 'date', 'errorCode']}
        />
      </div>
    );
  } else {
    return (
      <div style={{ position: 'relative' }} key="livedata">
        <div
          style={{
            position: 'absolute',
            top: '0px',
            right: '1.2em',
            zIndex: '2',
          }}
        >
          <PanelButton
            type="csv"
            csvData={csvData}
            csvFileName={`${
              props.editInfo && props.editInfo.title
                ? props.editInfo.title
                : 'chatbot'
            }-importErrors-${moment().format()}.csv`}
            label="Export Data"
          />
        </div>

        <div className="panelSection__title">
          <div className="panelHeader panelHeaderText">
            Campaign failures: {liveImportFails.length}
          </div>
        </div>

        <MiniTable
          tableData={liveImportFails}
          columnTitles={['Phone', 'Date', 'Error Code']}
          dataKeys={['phone', 'date', 'errorCode']}
        />
      </div>
    );
  }
}
