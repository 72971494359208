import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

const colors = {
  boxShadow: "0 0 0 0.2rem rgba(124,174,233,.25)",
  darkGrey: "#3B4252",
  mediumDarkGrey: "#5d5d5d",
  deepGrey: "#8D8D8D",
  mediumGrey: "#d5d5d5",
  lightGrey: "#EDEDED",
  lighterGrey: "#f4f4f4",
  lightGreen: "##78AB63",
  darkGreen: "#447706",
  darkBlue: "#3E97BD",
  mediumBlue: "#3F88DF",
  lightBlue: "#7caee9",
  lightOrange: "#F37F33",
  darkOrange: "#CD5105",
  lightMaroon: "#970f32",
  mediumMaroon: "#8c1d40",
  maroon: "#51081B",
  purple: "#603792",
  lightPurple: "#220446",
  gold: "#F6BF03",
  darkGold: "#BF9B30",
  mediumGold: "#F6BF03",
  lightGold: "#F7CF40",
  errorColor: "#e01037",
  background: {
    main: "#f4f4f4"
  }
};

let theme = createMuiTheme({
  fontSize: {
    paragraph: "12px",
    default: "14px",
    header: "16px"
  },
  palette: {
    general: {
      boxShadow: "0 0 0 0.2rem rgba(124,174,233,.25)",
      darkGrey: "#3B4252",
      mediumDarkGrey: "#5d5d5d",
      deepGrey: "#8D8D8D",
      mediumGrey: "#d5d5d5",
      lightGrey: "#EDEDED",
      lighterGrey: "#f4f4f4",
      lightGreen: "#78AB63",
      darkGreen: "#447706",
      darkBlue: "#3E97BD",
      mediumBlue: "#3F88DF",
      lightBlue: "#7caee9",
      lightOrange: "#F37F33",
      darkOrange: "#CD5105",
      lightMaroon: "#970f32",
      mediumMaroon: "#8c1d40",
      maroon: "#51081B",
      purple: "#603792",
      lightPurple: "#220446",
      gold: "#F6BF03",
      darkGold: "#BF9B30",
      mediumGold: "#F6BF03",
      lightGold: "#F7CF40",
      errorColor: "#e01037",
      background: {
        main: "#f4f4f4"
      }
    },
    primary: {
      contrastText: "#fff",
      dark: "#3E97BD",
      light: "#3F88DF",
      main: "#7caee9"
    }
  },
  components: {
    pageContainer: {
      background: colors.lighterGrey,
      height: "calc(100vh - 64px)",
      overflowY: "scroll",
      overflowX: "hidden"
    },
    paper: {
      width: "95%",
      margin: "5rem auto",
      borderRadius: "0px"
    }
  }
});

theme = responsiveFontSizes(theme);
export default theme;
