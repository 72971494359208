import React from "react";
import Select from "./Select";
import SelectForm from "./SelectForm";
import SelectCheckbox from "./SelectCheckbox";

export default function SelectX(props) {
  if (props.form) {
    return <SelectForm {...props} />;
  } else if (props.checkbox) {
    return <SelectCheckbox {...props} />;
  } else {
    return <Select {...props} />;
  }
}
