import React from 'react';
import AuthService from 'services/auth.service';
import Amplify from 'aws-amplify';
import awsmobile from 'aws-exports';
import AuthContext from 'contexts/AuthContext';
import PubSub from '@aws-amplify/pubsub';
import { API } from 'aws-amplify';
import { AWS } from '@aws-amplify/core';

let userId = null;

const Weblogin = function(props) {
  function renderChildren(tokens) {
    if (userId) {
      window.location.href =
        window.location.origin + '/dialogues?userId=' + userId;
    }
    return (
      <AuthContext.Provider value={tokens}>
        <div>{props.children}</div>
      </AuthContext.Provider>
    );
  }

  function setCredentials() {
    let tokens = JSON.parse(localStorage.getItem('usertokens'));
    AWS.config.credentials = new AWS.Credentials(
      tokens.AccessKeyId,
      tokens.SecretAccessKey,
      tokens.SessionToken
    );
    Amplify.configure({
      Auth: {
        identityPoolId: process.env.REACT_APP_COGNITO,
      },
      aws_appsync_graphqlEndpoint: awsmobile.aws_appsync_graphqlEndpoint,
      aws_appsync_region: awsmobile.aws_project_region,
      aws_appsync_authenticationType: awsmobile.aws_appsync_authenticationType,
      aws_cloud_logic_custom: [
        {
          name: awsmobile.aws_cloud_logic_custom.name,
          endpoint: awsmobile.aws_cloud_logic_custom.endpoint,
          region: awsmobile.aws_cloud_logic_custom.region,
        },
      ],
      Storage: {
        AWSS3: {
          bucket: process.env.REACT_APP_S3_PUBLIC_ASSETS,
          region: process.env.REACT_APP_AWS_REGION,
        },
      },
    });

    API.configure(awsmobile);
    PubSub.configure(awsmobile);
    return renderChildren(tokens);
  }

  if (AuthService.isAuthenticated()) {
    return setCredentials();
  } else {
    var urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('authd')) {
      const tokens = atob(urlParams.get('authd'));
      userId = urlParams.get('userId');
      localStorage.setItem('authed', 'true');
      localStorage.setItem('usertokens', tokens);
      return setCredentials();
    } else {
      let userId = window.location.href.split('=')[1];

      window.location.href =
        process.env.REACT_APP_ASU_WEBLOGIN +
        '/serviceauth/oauth2/code/allow?response_type=code&redirect_uri=' +
         process.env.REACT_APP_APIGATEWAY_CHATBOT +
         '/auth&client_id=chatbot-sunny&state=' +
        (userId ? userId : 'test') +
        '&code_challenge=test';
    }
  }
};

export default Weblogin;
