import { API, graphqlOperation } from 'aws-amplify';
import {
  listKnowledgeBaseCategorys,
  validateLexQuestion,
} from 'graphql/queries';

export function runQuestion(e, id, keyword) {
  console.log('e: ', e);
  return new Promise(function(resolve, reject) {
    let payload = {
      operation: 'runQuestion',
      inquiry: e,
      env: 'dev',
    };
    if (id) {
      payload.current = id;
    }
    API.graphql(graphqlOperation(validateLexQuestion, payload)).then(resp => {
      let myResp = {
        status: null,
        msg: null,
      };

      try {
        let responseData = resp.data.validateLexQuestion;

        if (responseData.isSelf === true) {
          myResp.msg = 'Question is already in current FAQ';
          myResp.status = 'success';
        } else if (
          responseData.isTaken &&
          responseData.intentName.indexOf('CRITICAL_') > -1
        ) {
          myResp.msg = 'Question is part of a critical alert';
          myResp.status = 'error';
        } else if (responseData.isTaken) {
          myResp.msg = keyword
            ? `${keyword} is not available`
            : 'Question is not available';
          myResp.status = 'error';
        } else {
          myResp.msg = keyword
            ? `${keyword} is available!`
            : 'Question is available!';
          myResp.status = 'success';
        }
      } catch (err) {
        myResp.msg = 'There was a critical error checking the question';
        myResp.status = 'error';
      }

      resolve(myResp);
    });
  });
}
