import React, { useState, useEffect, useContext } from 'react';

import AuthContext from 'contexts/AuthContext';
import Paper from '@material-ui/core/Paper';

import { faSearch, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as SettingsIcon } from 'assets/icons/icon-settings.svg';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Modal, ModalContent, Input } from 'stories';
import DialogueGroups from './DialogueGroups';
import ExportDataSection from './ExportData';

import { API, graphqlOperation } from 'aws-amplify';
import { updateChatbotGroup } from 'graphql/mutations';

import { useModal } from 'hooks';

import './TriageQueue.scss';
import '../Dialogues.scss';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function TriageQueue(props) {
  const tokens = useContext(AuthContext);
  const [activeSelection, setActiveSelection] = useState(null);
  const [activeFolder, setActiveFolder] = useState(null);
  const [clickedGroup, setClickedGroup] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalInfo, setModalInfo] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [openType, setOpenType] = useState(null);
  const [folderName, setFolderName] = useState(null);
  const [openError, setOpenError] = React.useState(false);
  const { isShowing, toggle } = useModal();

  useEffect(() => {}, [props.convoList]);

  const toggleView = async (type, tag, name, activeType, folders) => {
    if (!props.isLoading) {
      setActiveFolder(null);
      setActiveSelection({ type: type, tag: tag, name: name });
      props.setListFilter(tag);
      props.setListFilterName(name ? name : 'All');
      props.setInboxSearch('');
      props.setFolderFilter(null);
      if (activeType === 'inactive') {
        setOpenError(true);
      }

      if (folders) {
        for (var i = 0; i < folders.length; ++i) {
          if (!folders[i].total) {
            folders[i].total = await props.getDialogueCount(
              folders[i].tagValue
            );
          }
        }
      }
    }
  };

  const setFolder = (tagValue, name) => {
    setActiveFolder(tagValue);
    props.setFolderFilter(tagValue);
    props.setListFilterName(activeSelection.name + ' (' + name + ')');
    props.setInboxSearch('');
  };

  const showSettings = tagValue => {
    console.log('SHOULD TOGGLE');
  };

  const handleMenuOpen = (event, convo, folders, folder) => {
    setClickedGroup({ convo: convo, folders: folders, folder: folder });
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setClickedGroup(null);
    setAnchorEl(null);
  };

  const getModalContent = () => {
    if (modalInfo && modalInfo.type !== 'delete') {
      let formData = [
        {
          title: 'FOLDER',
          required: true,
          component: (
            <Input
              name="folder"
              value={
                modalInfo && modalInfo.folder ? modalInfo.folder.name : null
              }
              form="true"
              required
              placeholder="Folder Name"
              maxlength={50}
            />
          ),
        },
      ];

      return (
        <ModalContent
          form={true}
          formData={formData}
          title={`${
            modalInfo && modalInfo.type === 'edit' ? 'Edit' : 'Add'
          } Folder`}
          onSubmit={addFolder}
          toggle={toggle}
        />
      );
    } else {
      return (
        <ModalContent
          confirmation
          title={`Are you sure you want to delete ${
            modalInfo && modalInfo.folder ? modalInfo.folder.name : 'folder'
          }`}
          onSubmit={deleteFolder}
          toggle={toggle}
        />
      );
    }
  };

  const deleteFolder = e => {
    addFolder({ folder: 'delete' });

    props.deleteFolderTags(modalInfo);
    setClickedGroup(null);
    toggle();
  };

  const addFolder = async e => {
    if (e.folder) {
      let newFolder = {
        name: e.folder,
        tagValue:
          'folder ' +
          Math.random()
            .toString(36)
            .substr(2, 9),
        parent: modalInfo.convo,
      };

      modalInfo.folders = modalInfo.folders ? modalInfo.folders : [];

      let deltaFolders = [...modalInfo.folders];
      if (modalInfo.type === 'add') {
        deltaFolders = [...modalInfo.folders, newFolder];
      } else if (modalInfo.type === 'edit') {
        for (var i = 0; i < deltaFolders.length; ++i) {
          if (deltaFolders[i].tagValue === modalInfo.folder.tagValue) {
            deltaFolders[i].name = e.folder;
          }
        }
      } else if (modalInfo.type === 'delete') {
        for (var i = deltaFolders.length - 1; i >= 0; --i) {
          if (deltaFolders[i].tagValue === modalInfo.folder.tagValue) {
            deltaFolders.splice(i, 1);
          }
        }
      }
      let resp = await API.graphql(
        graphqlOperation(updateChatbotGroup, {
          input: {
            id: modalInfo.convo,
            dialoguesFolders: deltaFolders,
          },
        })
      );

      setClickedGroup(null);
      toggle();
      props.updateGroups();
    }
  };

  const renderMenuOptn = (type, folders, folder) => {
    return (
      <FontAwesomeIcon
        size="1x"
        icon={faEllipsisV}
        onClick={e => {
          handleMenuOpen(e, type, folders, folder);
        }}
        style={{ marginLeft: '5px' }}
      />
    );
  };

  const renderActiveGroup = (name, type, tag, folders, activeType, count) => {
    if (
      (activeType === 'active' && (count || type === 'self')) ||
      (activeType === 'inactive' && !count)
    ) {
      return (
        <div
          key={name + '_' + Math.floor(Math.random() * 20000)}
          style={{ width: '100%', padding: '0' }}
        >
          <button
            style={{
              background:
                (activeSelection && activeSelection.type === type) ||
                (!activeSelection && type === 'all')
                  ? '#ffc626'
                  : null,
              width: '100%',
            }}
            key={name + '_' + Math.floor(Math.random() * 20000)}
            onClick={() => toggleView(type, tag, name, activeType, folders)}
          >
            <p className="simpleEllipsis" style={{ textAlign: 'left' }}>
              {name}
            </p>{' '}
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '0px',
              }}
            >
              {activeType === 'active' && (
                <p className="triage-queue-tags">{count ? count : 0}</p>
              )}

              {type !== 'self' && renderMenuOptn(type, folders)}
            </div>
          </button>
          {activeSelection &&
            activeSelection.type === type &&
            folders &&
            folders.map(folder => {
              return (
                <button
                  className="folder"
                  key={folder.tagValue}
                  style={{
                    background:
                      activeFolder === folder.tagValue ? '#ffc626' : null,
                  }}
                  onClick={() => setFolder(folder.tagValue, folder.name)}
                >
                  <p
                    className="simpleEllipsis folderName"
                    style={{ textAlign: 'left' }}
                  >
                    {folder.name +
                      ' (' +
                      (folder.total ? folder.total : 0) +
                      ')'}
                  </p>{' '}
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: '0px',
                    }}
                  >
                    {type !== 'self' && renderMenuOptn(type, folders, folder)}
                  </div>
                </button>
              );
            })}
        </div>
      );
    } else {
      return null;
    }
  };

  const ellipsisMenu = () => {
    return (
      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {clickedGroup && !clickedGroup.folder && (
          <MenuItem
            onClick={() => {
              setModalInfo({ ...clickedGroup, type: 'add' });
              toggle();
              handleMenuClose();
            }}
          >
            Add Folder
          </MenuItem>
        )}
        {clickedGroup && clickedGroup.folder && (
          <MenuItem
            onClick={() => {
              setModalInfo({ ...clickedGroup, type: 'edit' });
              toggle();
              handleMenuClose();
            }}
          >
            Edit Folder
          </MenuItem>
        )}
        {clickedGroup && clickedGroup.folder && (
          <MenuItem
            onClick={() => {
              setModalInfo({ ...clickedGroup, type: 'delete' });
              toggle();
              handleMenuClose();
            }}
          >
            Delete Folder
          </MenuItem>
        )}
      </Menu>
    );
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenError(false);
  };

  const getConvoLen = cList => {
    let count = 0;
    for (var i = 0; i < cList.length; ++i) {
      let el = cList[i];
      let groupAssigned;
      let didFindGroup = false;
      let foundGroup = false;
      if (el.tags && el.tags.length > 0) {
        try {
          let groupTag = el.tags.find(a => a.includes('groupAssigned'));
          groupAssigned = groupTag.split(' ')[1];
          for (var j = 0; j < props.groups.length; ++j) {
            if (props.groups[j].id === groupAssigned) {
              foundGroup = true;
            }
          }
        } catch (e) {}
      }

      if (!groupAssigned || foundGroup) {
        didFindGroup = true;
      }

      if (didFindGroup) {
        count++;
      }
    }

    return count;
  };

  //let autoAssignId = "pro_off1536177925092";

  if (
    tokens.role === 'admin' ||
    (tokens.role === 'publisher' &&
      tokens.groups.includes('pro_off1536177925092'))
  ) {
    return (
      <>
        <Paper className="dg-container">
          {' '}
          <DialogueGroups
            convoList={props.convoList}
            groupNum={props.groupNum}
            triageTotal={props.triageTotal}
            groups={props.groups}
            keyedUsers={props.keyedUsers}
            allUsers={props.allUsers}
            keyedUserGroups={props.keyedUserGroups}
            keyedGroups={props.keyedGroups}
            triggeredAddRule={props.triggeredAddRule}
            presentUserGroups={props.presentUserGroups}
          />
          <ExportDataSection />
          <div className="triage-overflow-section">
            <div className="triage-queue grayBottomBorder">
              <h3>Triage Queue</h3>

              {
                <button
                  style={{
                    background:
                      !activeSelection || !activeSelection.type
                        ? '#ffc626'
                        : null,
                  }}
                  onClick={() => toggleView(null, null)}
                >
                  <p>All</p>{' '}
                  <p className="triage-queue-tags">{props.triageTotal}</p>
                </button>
              }
              {props.groups &&
                props.groups.map(item => {
                  return renderActiveGroup(
                    item.display,
                    item.id,
                    'groupAssigned ' + item.id,
                    item.folders,
                    'active',
                    item.count
                  );
                })}

              {renderActiveGroup(
                'Assigned to Me',
                'self',
                'agentAssigned ' + tokens.asurite,
                [],
                'active',
                props.assignedToMeCount
              )}
            </div>
            <div className="triage-queue">
              <h3>Inactive</h3>
              {props.groups &&
                props.groups.map(item => {
                  return renderActiveGroup(
                    item.display,
                    item.id,
                    'groupAssigned ' + item.id,
                    item.folders,
                    'inactive',
                    item.count
                  );
                })}
            </div>
          </div>
          {ellipsisMenu()}
          <Modal
            isShowing={isShowing}
            hide={toggle}
            content={getModalContent()}
          />
        </Paper>
        <Snackbar
          open={openError}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            No users assigned to group
          </Alert>
        </Snackbar>
      </>
    );
  }
  return (
    <>
      <Paper className="dg-container">
        {' '}
        <DialogueGroups
          convoList={props.convoList}
          groupNum={props.groupNum}
          triageTotal={props.triageTotal}
          groups={props.groups}
          keyedUsers={props.keyedUsers}
          allUsers={props.allUsers}
          keyedUserGroups={props.keyedUserGroups}
          keyedGroups={props.keyedGroups}
          triggeredAddRule={props.triggeredAddRule}
          presentUserGroups={props.presentUserGroups}
        />
        <ExportDataSection />
        <div className="triage-overflow-section">
          <div className="triage-queue grayBottomBorder">
            <h3>Triage Queue</h3>

            {props.groups &&
              props.groups.map(item => {
                return renderActiveGroup(
                  item.display,
                  item.id,
                  'groupAssigned ' + item.id,
                  item.folders,
                  'active',
                  item.count
                );
              })}

            {renderActiveGroup(
              'Assigned to Me',
              'self',
              'agentAssigned ' + tokens.asurite,
              [],
              'active',
              props.assignedToMeCount
            )}
          </div>
          <div className="triage-queue">
            <h3>Inactive</h3>
            {props.groups &&
              props.groups.map(item => {
                return renderActiveGroup(
                  item.display,
                  item.id,
                  'groupAssigned ' + item.id,
                  item.folders,
                  'inactive',
                  item.count
                );
              })}
          </div>
        </div>
        {ellipsisMenu()}
        <Modal
          isShowing={isShowing}
          hide={toggle}
          content={getModalContent()}
        />
      </Paper>
      <Snackbar open={openError} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          No users assigned to group
        </Alert>
      </Snackbar>
    </>
  );
}
