export const inboxBtn = {
  name: "Inbox",
  filter: "unseenBy",
  id: "inbox",
};
export const reviewBtn = {
  name: "Review",
  filter: "needsReview",
  id: "review",
};
export const assistBtn = {
  name: "Previously assisted",
  filter: "previouslyAssisted",
  id: "assisted",
};
export const allBtn = {
  name: "All",
  filter: null,
  id: "all",
};
export const searchBtn = {
  name: "Search Results",
  filter: "searchResults",
  id: "sr",
};
