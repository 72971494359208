import React, { useEffect, useState, useContext } from 'react';
import { Button, Input } from 'stories';
import './Variables.css';
import SearchIcon from '@material-ui/icons/Search';
import AuthContext from 'contexts/AuthContext';

import { API, graphqlOperation } from 'aws-amplify';
import { listAnswerVariabless } from 'graphql/queries';
import { createAnswerVariables } from 'graphql/mutations';

import { insertAtPosition } from '../utility';

var savedVars = [];

export default function Variables(props) {
  let { currentValue, acceptOptn, fieldRef } = props;

  const tokens = useContext(AuthContext);

  const [variables, setVariables] = useState([]);
  const [search, setSearch] = useState('');
  const [inputValue, setInputValue] = useState('');

  const fetchVars = async () => {
    if (savedVars.length === 0) {
      let result = await API.graphql(
        graphqlOperation(listAnswerVariabless, {
          filter: {
            active: { eq: true },
          },
          limit: 1000,
        })
      );

      let arr = result.data.listAnswerVariabless.items;
      let renderArr = [];
      for (var i = 0; i < arr.length; ++i) {
        renderArr.push(arr[i].group + '_' + arr[i].varName);
      }

      renderArr.sort(function(a, b) {
        return a.length - b.length;
      });

      savedVars = JSON.parse(JSON.stringify(renderArr));
      setSearch('all');
    }
  };

  fetchVars();
  useEffect(() => {
    fetchVars();
  }, []);

  const clicked = e => {
    let newStr = e;

    if (props.ignoreInsert !== true) {
      newStr = insertAtPosition(e, currentValue, fieldRef);
    }

    if (acceptOptn) {
      acceptOptn(newStr);
    }
  };

  const varOptn = (item, index) => {
    let str = '{' + item + '}';
    let width = item.length > 20 ? '100%' : '50%';

    if (
      item.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
      search === 'all'
    ) {
      return (
        <Button
          key={'varOption' + index}
          onClick={() => clicked(str)}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: width,
            fontSize: '12px',
            textTransform: 'unset',
          }}
        >
          <div>{str}</div>
        </Button>
      );
    }
  };

  const filter = e => {
    let val = document.getElementById('variableSearchInput').value;

    if (!val || val === '') {
      val = 'all';
    }
    setSearch(val);
  };

  const enterEvent = e => {
    if (e.key === 'Enter') {
      filter();
    }
  };

  return (
    <div className="variablesHolder">
      <div className="varsTitle">
        <Input
          id="variableSearchInput"
          placeholder="Search Variables"
          style={{ paddingRight: '35px' }}
          onKeyDown={enterEvent}
        />
        <SearchIcon
          className="searchIcon"
          aria-hidden="true"
          onClick={filter}
        />
      </div>
      <div className="varsSection">
        {savedVars.map((item, index) => {
          return varOptn(item, index);
        })}
      </div>
    </div>
  );
}
