import React, { useState } from "react";
import "./AccordionDetail.css";

import { H6 } from "stories";

export default function AccordionDetail(props) {
  let responses = props.responses ? props.responses : [];

  return (
    <>
      {props.type === "count" &&
        responses.map((response, index) => {
          return (
            <div className="detailsContent">
              <H6 additionalClasses="summaryTitle">
                {response.label.toLowerCase()}:{" "}
              </H6>
              <p className="matched-count">
                {response.count ? response.count : 0}
              </p>
            </div>
          );
        })}

      {props.type === "textarea" && (
        <>
          {props.label ? props.label : "Responses"}
          <textarea
            style={{
              marginTop: "1em",
              marginBottom: "1em",
              height: "10em",
              resize: "none",
            }}
            readonly="readonly"
          >
            {props.value}
          </textarea>
        </>
      )}

      {props.total !== undefined && (
        <div className="detailsContent">
          <H6 additionalClasses="summaryTitle">{"Total"}: </H6>
          <p className="matched-count">{props.total}</p>
        </div>
      )}
    </>
  );
}
