// import { API, graphqlOperation } from "aws-amplify";
import NodeType from "./Edit/NodeType.js";
import NodeProperties from "./Edit/NodeProperties.js";
import DeleteWarning from "./DeleteWarning.js";
import Diagram from "./Diagram/Diagram.js";
import Undo from "./Undo.js";
import ExtendSession from "pages/KnowledgeBase/ExtendSession";

import { store as GlobalStore } from "contexts/GlobalStore";
// import usePrevious from "./previousValue";

import { useTheme } from "@material-ui/core/styles";

import React, { useEffect, useState, useRef, useContext } from "react";
import "./Canvas.scss";
import { idGenerator, getDeletes, collapseFromNode } from "./utility.js";

import { ReactComponent as CompressButton } from "./tempIcons/compress-arrows-alt-solid.svg";
import { ReactComponent as ExpandButton } from "./tempIcons/expand-arrows-alt-solid.svg";
import { ReactComponent as CenterButton } from "./tempIcons/center_focus_strong-24px.svg";
import AdjustIcon from "@material-ui/icons/Adjust";

import { OpenSunny } from "components/Sunny";
import { CreateNewButton } from "stories";

import axios from "axios";

import {
  getTree,
  postNode,
  startDelete,
  startUndo,
  regenerateBranchId,
  resetBranchId,
  editFlow,
  releaseEdit,
  alterFlowMessages,
} from "./graphql-util";

let undoTimeout = null;

let HELD_FLOW_ID = null;
// let flowId = null;
// let flowType = null;
// let viewOnly = false;
// let flowVersion = null;

export default function Holder(props) {
  const theme = useTheme();

  const [showProperties, setShowProperties] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showUndo, setShowUndo] = useState(false);
  const [branchUpdates, setBranchUpdates] = useState(null);
  const [latest, setLatest] = useState();
  const [rawTree, setRawTree] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);

  const [forceRender, setForceRender] = useState(0);

  const [flowVersions, setFlowVersions] = useState([]);
  // const [flowId, setFlowId] = useState(null);

  const [flowId, setFlowId] = useState(null);
  const [flowType, setFlowType] = useState(null);
  const [viewOnly, setViewOnly] = useState(null);
  const [flowVersion, setFlowVersion] = useState(null);

  const [clickedFillNodeId, setClickedFillNodeId] = useState(null);

  const [showAddInfo, setShowAddInfo] = useState(false);
  const [addToParent, setAddToParent] = useState(null);
  const [markedForDelete, setMarkedForDelete] = useState([]);
  const [matchDeletes, setMatchDeletes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteParentInfo, setDeleteParentInfo] = useState({
    id: null,
    isBranch: false,
  });

  var prevId = usePrevious(latest);

  const GlobalState = useContext(GlobalStore);
  const dispatch = GlobalState.dispatch;

  const initTree = () => {
    // console.log("IS GETTING TREE");
    if (flowId) {
      setLoading(true);
      getTree(flowId)
        .then((tree) => {
          setLoading(false);
          setRawTree(tree);
        })
        .catch((err) => {
          setLoading(false);
          console.log("errored", err);
        });
    }
  };

  const removeItemIfIsNew = (nodeToCheck) => {
    let tree = [...rawTree];
    let ind = -1;

    if (nodeToCheck.isNew) {
      for (var i = tree.length - 1; i >= 0; --i) {
        if (tree[i].id === nodeToCheck.id) {
          ind = i;
          break;
        }
      }
    }

    if (ind > -1) {
      if (ind === tree.length - 1) {
        tree.pop();
      } else {
        tree.splice(ind, -1);
      }
      setRawTree(tree);
    }
  };

  useEffect(() => {
    try {
      let temp_flowId = props.history.location.state.flowId;
      let temp_flowType = props.history.location.state.type;
      let temp_viewOnly = props.history.location.state.viewOnly;
      let temp_flowVersion = props.history.location.state.flowVersion;

      HELD_FLOW_ID = temp_flowId;
      setFlowId(temp_flowId);
      setFlowType(temp_flowType);
      setViewOnly(temp_viewOnly);
      setFlowVersion(temp_flowVersion);

      if (flowVersion < 1) {
        // console.log("WILL GET TREE", flowVersion);
        initTree();
      }

      editFlow(temp_flowId);

      window.addEventListener("beforeunload", (ev) => {
        ev.preventDefault();
        releaseEdit(HELD_FLOW_ID);
      });

      watchPath();
      function watchPath() {
        let path = window.location.pathname;

        if (path.indexOf("view-flow") > -1) {
          setTimeout(() => {
            watchPath();
          }, 500);
        } else {
          // if (leftWithoutSaving) {
          if (path === "/flows") {
            let timesThru = 0;
            // Need to wait for rows to render & subscription ready
            // or it wont release from UI of current screen
            function waitForReady() {
              if (document.getElementById(HELD_FLOW_ID) || timesThru > 500) {
                releaseEdit(HELD_FLOW_ID);
              } else {
                ++timesThru;
                setTimeout(() => {
                  waitForReady();
                }, 50);
              }
            }
            waitForReady();
          } else {
            releaseEdit(HELD_FLOW_ID);
          }
          // }
        }
      }
    } catch (e) {
      props.history.push({
        pathname: "flows",
      });
    }

    return () => releaseEdit(HELD_FLOW_ID);
  }, []);

  useEffect(() => {
    if (prevId && prevId.isNew) {
      removeItemIfIsNew(prevId);
    }
  }, [selectedIds]);

  const addNode = (info) => {
    console.log(" ************* ADDING INFO: ", info);
    setShowProperties(false);
    setShowAddInfo(true);
    setLatest({ ...info });
    setAddToParent(info.parent);
  };

  const insertNode = (insertInfo) => {
    let info = { ...latest };
    let type = insertInfo.type;

    info.type = insertInfo.type;
    info.customType = insertInfo.customType;
    info.isNew = true;

    if (!info.id) {
      info.id = idGenerator();
    }

    if ((type === "branch" || type.indexOf("ifthen") > -1) && !info.matches) {
      if (type === "ifthen-existsnot") {
        info.matches = [
          {
            id: idGenerator(),
            match: "EXISTS",
          },
          {
            id: idGenerator(),
            match: "NOT EXISTS",
          },
        ];
      } else {
        info.matches = [
          {
            id: idGenerator(),
            match: "NO MATCH",
          },
        ];
      }
    }

    setRawTree(rawTree.concat(info));
    setLatest({ ...info });
    setShowProperties(true);
    setShowAddInfo(false);
    // setAddToParent(null);
  };

  const closeSelectNode = () => {
    dispatch({ type: "updateEditInfo", editInfo: { empty: true } });
    setLatest(null);
    setShowProperties(false);
    setSelectedIds([]);
    setShowAddInfo(false);
    setAddToParent(null);
  };

  const saveNode = (info) => {
    for (let key in info) {
      if (info[key] === "") {
        info[key] = null;
      }
    }

    if (info.saveAsTemplate === "true") {
      let id = latest.templateId ? latest.templateId : idGenerator();
      let saveAsTemplateInfo = {
        id: id,
        name: info.messageName,
        group: JSON.parse(info.ownerGroup).value,
        message: info.text,
        nodeType: latest.type,
        audience: JSON.parse(info.audience).value,
        image: info.image,
        response: info.response,
        matches: info.matches,
        responseImage: info.responseImage,
      };

      info.templateId = id;

      alterFlowMessages(saveAsTemplateInfo, latest.templateId ? false : true);
    } else if (info.saveAsTemplate === "false") {
      let saveAsTemplateInfo = {
        id: latest.templateId,
        deleted: true,
      };
      info.ownerGroup = null;
      info.messageName = null;
      info.templateId = null;
      alterFlowMessages(saveAsTemplateInfo, false);
    }

    let payload = {
      ...latest,
      ...info,
    };

    postNode(payload, flowId, addToParent, flowVersion)
      .then((data) => {
        // console.log("PSTED NDOES", data);

        let copy = [...rawTree];

        for (var i = 0; i < data.length; ++i) {
          let id = data[i].id;
          let found = false;
          for (var j = 0; j < copy.length; ++j) {
            if (copy[j].id === id) {
              copy[j] = data[i];
              found = true;
              break;
            }
          }
          if (found === false) {
            copy.push(data[i]);
          }
        }

        setRawTree(copy);
      })
      .catch((err) => {
        console.log("Critial error posting node", err);
      });

    closeSelectNode();
  };

  const closeEditNode = () => {
    setShowProperties(false);
    setSelectedIds([]);
    removeItemIfIsNew(latest);

    //Timeout so info still shows during animation
    setTimeout(() => {
      setLatest(null);
    }, 350);
  };

  const deleteWarningBranch = (newBranch) => {
    let bUpdates = {
      id: newBranch.branchId,
      matches: newBranch.matches,
      newMatches: newBranch.newMatches,
    };

    setBranchUpdates(bUpdates);
    deleteItem(newBranch);
  };

  const deleteItem = (item) => {
    let toBeDeleted = getDeletes(item, rawTree);

    setMarkedForDelete(toBeDeleted.markedForDelete);
    setMatchDeletes(toBeDeleted.matchDeletes);
    setDeleteParentInfo(toBeDeleted.parentInfo);
    setShowProperties(false);
    setShowWarning(true);
  };

  const confirmDelete = () => {
    let dpi = regenerateBranchId(
      deleteParentInfo,
      rawTree,
      markedForDelete,
      flowId
    );
    setDeleteParentInfo(dpi);

    setShowProperties(false);
    setSelectedIds([]);
    setShowWarning(false);
    startDelete(branchUpdates, markedForDelete, flowId)
      .then((resp) => {
        initTree();
        setShowUndo(true);
        setSelectedIds([]);

        undoTimeout = setTimeout(() => {
          setShowUndo(false);
          setMatchDeletes([]);
          setDeleteParentInfo({ id: null, isBranch: false });
          setBranchUpdates(null);
          setMarkedForDelete([]);
        }, 5000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const undo = () => {
    clearTimeout(undoTimeout);
    setShowUndo(false);

    resetBranchId(deleteParentInfo, rawTree, markedForDelete, flowId);

    startUndo(branchUpdates, markedForDelete, flowId)
      .then((resp) => {
        initTree();
        setMatchDeletes([]);
        setDeleteParentInfo({ id: null, isBranch: false });
        setBranchUpdates(null);
        setSelectedIds([]);
        setMarkedForDelete([]);
      })
      .catch((err) => {
        console.log("Error undoing delete");
      });
  };

  const resetMarkForDelete = () => {
    setMarkedForDelete([]);
    setMatchDeletes([]);
    setDeleteParentInfo({ id: null, isBranch: false });
    setShowWarning(false);
  };

  const openRunBot = () => {
    let n = {};
    for (var i = 0; i < rawTree.length; ++i) {
      if (rawTree[i].parent === null) {
        n = rawTree[i];
      }
    }
    setLatest({
      type: "runBot",
      flowId: flowId,
      text: n.text,
      flowVersion: viewOnly ? flowVersion : null,
    });
    setShowProperties(true);
    setSelectedIds([]);
  };

  const getDeleteNumber = () => {
    let arrDupe = JSON.parse(JSON.stringify(markedForDelete));
    if (latest && latest.id) {
      let ind = arrDupe.indexOf(latest.id);
      if (ind > -1) arrDupe.splice(ind, 1);
    }
    return arrDupe.length;
  };

  const changedVersion = (e) => {
    let useRawTree = false;
    setShowProperties(false);
    setSelectedIds([]);

    setFlowVersion(e.version);

    if (e.approved === true || e.submitted === true) {
      useRawTree = true;
      setViewOnly(true);
    } else {
      setViewOnly(false);
    }

    if (useRawTree) {
      setRawTree(JSON.parse(e.flowNodes));
    } else {
      initTree();
    }
  };

  const fillNodeId = (id) => {
    console.log("ID", id);
    setClickedFillNodeId(id);
  };

  let viewClasses = "viewOnlyMode ";
  if (!viewOnly) viewClasses += "viewOnlyHide";

  return (
    <div>
      <NodeType
        latest={latest}
        show={showAddInfo}
        addNode={insertNode}
        close={closeSelectNode}
      />

      <NodeProperties
        node={latest}
        show={showProperties}
        saveNode={saveNode}
        clickedFillNodeId={clickedFillNodeId}
        flowType={flowType}
        viewOnly={loading | viewOnly}
        deleteWarning={deleteItem}
        deleteWarningBranch={deleteWarningBranch}
        close={closeEditNode}
      />

      <OpenSunny onClick={openRunBot} />

      <DeleteWarning
        show={showWarning}
        children={getDeleteNumber()}
        resetMarkForDelete={resetMarkForDelete}
        confirmDelete={confirmDelete}
      />

      <Undo show={showUndo} kickOver={showProperties} undo={undo} />

      <Diagram
        addNode={addNode}
        rawTree={rawTree}
        flowId={flowId}
        title={props.history.location.state.title}
        fillNodeId={fillNodeId}
        changedVersion={changedVersion}
        viewOnly={loading | viewOnly}
        setShowProperties={setShowProperties}
        setLatest={setLatest}
        flowVersion={flowVersion}
        showProperties={showProperties}
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
        deleteItem={deleteItem}
      />

      <div className={viewClasses}>View Only Mode</div>
      <ExtendSession sessionType="updateFlow" id={HELD_FLOW_ID} />
    </div>
  );
}

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}
