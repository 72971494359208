import React, { createContext, useReducer } from "react";
const initialState = {
  tableData: [],
  currentPage: 1,
  totalResults: null,
  nextTokenList: [],
  tableSort: null,
  tableFilter: "All",
  clearNextTokens: false,
  searchActive: false,
  formTitle: "",
  loadingTableData: false,
  foundPublishedRows: [],
  tableDataEmpty: false,
  loadingTableItems: false,
  prevPageClicked: false,
  editInfo: { empty: true },
  viewOnly: false,
  spliceTableData: null,
  loadingFormSubmission: false,
  checkForErrors: false,
  refetchKey: Date.now(),
  includeFlow: "",
  showRefreshTokenMessage: ""
};

const store = createContext(initialState);
const { Provider, Consumer } = store;

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      // =============================================================
      case "setTableData":
        const tableDataState = {
          ...state,
          tableData: { ...action.tableData },
        };
        return tableDataState;
      // =============================================================
      case "updateEditInfo":
        const updateEditInfoState = {
          ...state,
          viewOnly: action.viewOnly,
          editInfo: { ...action.editInfo },
        };
        return updateEditInfoState;
      // =============================================================
      case "changeTableSort":
        const changeTableSortState = {
          ...state,
          tableSort: action.tableSort,
        };
        return changeTableSortState;
      // =============================================================
      case "changeTableFilter":
        const changeTableFilterState = {
          ...state,
          tableFilter: action.tableFilter,
        };
        return changeTableFilterState;
      // =============================================================
      case "setLoadingFormSubmission":
        const loadingFormSubmissionState = {
          ...state,
          loadingFormSubmission: action.loadingFormSubmission,
        };
        return loadingFormSubmissionState;
      // =============================================================
      case "setCheckForErrors":
        const checkForErrorsState = {
          ...state,
          checkForErrors: action.checkForErrors,
        };
        return checkForErrorsState;
      // =============================================================
      case "setSpliceTableData":
        const setSpliceTableDataState = {
          ...state,
          spliceTableData: action.spliceTableData,
        };
        return setSpliceTableDataState;
      // =============================================================
      case "setTableDataEmpty":
        const setTableDataEmptyState = {
          ...state,
          tableDataEmpty: action.tableDataEmpty,
        };
        return setTableDataEmptyState;
      // =============================================================
      case "setLoadingTableData":
        const setLoadingTableDataState = {
          ...state,
          loadingTableData: action.loadingTableData,
        };
        return setLoadingTableDataState;
      // =============================================================
      case "setFoundPublishedRows":
        console.log(action);
        const setFoundPublishedRows = {
          ...state,
          foundPublishedRows: action.foundPublishedRows,
        };
        return setFoundPublishedRows;
      // =============================================================
      case "setSearchActive":
        const setSearchActiveState = {
          ...state,
          searchActive: action.searchActive,
        };
        return setSearchActiveState;
      // =============================================================
      case "setFormTitle":
        const setFormTitleState = {
          ...state,
          formTitle: action.formTitle,
        };
        return setFormTitleState;
      // =============================================================
      case "setItemToDelete":
        const setItemToDeleteState = {
          ...state,
          itemToDelete: action.itemToDelete,
        };
        return setItemToDeleteState;
      // =============================================================
      case "setCurrentPage":
        const setCurrentPageState = {
          ...state,
          currentPage: action.currentPage,
        };
        return setCurrentPageState;
      // =============================================================
      case "setTotalResults":
        const setTotalResultsState = {
          ...state,
          totalResults: action.totalResults,
        };
        return setTotalResultsState;
      // =============================================================
      case "setNextTokenList":
        const setNextTokenListState = {
          ...state,
          nextTokenList: action.nextTokenList,
        };
        return setNextTokenListState;
      // =============================================================
      case "clearNextTokens":
        const clearNextTokensState = {
          ...state,
          clearNextTokens: action.clearNextTokens,
        };
        return clearNextTokensState;
      // =============================================================
      case "setPrevPageClicked":
        const setPrevPageClickedState = {
          ...state,
          prevPageClicked: action.prevPageClicked,
        };
        return setPrevPageClickedState;
      // =============================================================
      case "setRefetchKey":
        const setRefetchKeyState = {
          ...state,
          refetchKey: action.refetchKey,
        };
        return setRefetchKeyState;
      // =============================================================
      case "setIncludeFlow":
        const setIncludeFlowState = {
          ...state,
          includeFlow: action.includeFlow,
        };
        return setIncludeFlowState;
      // =============================================================
      case "setShowRefreshTokenMessage":
        const setShowRefreshTokenMessageState = {
          ...state,
          showRefreshTokenMessage: action.showRefreshTokenMessage,
        };
        return setShowRefreshTokenMessageState;
      // =============================================================
      case "setGlobalTableState":
        const setGlobalTableState = {
          ...state,
          globalTableState: action.globalTableState,
        };
        return setGlobalTableState;
      // =============================================================
      default:
        console.log("ERROR: dispatch type is not included in store...");
        console.log("GlobalStore action: ", action);
        throw new Error();
      // =============================================================
    }
  }, initialState);
  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};
export { store, StateProvider, Consumer };
