import React, { useState, useEffect, useContext } from 'react';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { useFormContext } from 'react-hook-form';
import { ErrorLabel } from 'stories';
import { readString } from 'react-papaparse';
import AuthContext from 'contexts/AuthContext';
import { Storage } from 'aws-amplify';
import { isValidPhoneNumber } from 'libphonenumber-js';

import GetAppIcon from '@material-ui/icons/GetApp';

export default function UploadFile(props) {
  const theme = useTheme();
  const { register, errors, setError } = useFormContext();
  const [fileName, setFileName] = useState(props.placeholder);
  const [fileContent, setFileContent] = useState();
  const [campType, setCampType] = useState(props.campaigntype);
  const [fileError, setFileError] = useState(null);
  const [warning, setWarning] = useState(null);
  const [rerenderKey, setRerenderKey] = useState(Date.now());
  const tokens = useContext(AuthContext);
  const chatbotBucket =
    'chatbot-dashboard-assets' + (tokens.env === 'truedev' ? '-dev' : '');

  Storage.configure({ level: 'public' });

  useEffect(() => {
    if (props.fileName && props.fileContent) {
      setFileName(props.fileName);
      setFileContent(props.fileContent);
    }
    if (props.updateImg && props.fileName && !props.isDuplicate)
      setFileName(props.fileName);

    if (props.fileContent == null && !props.updateImg) resetButtonHandler();
  }, []);

  useEffect(() => {
    if (props.isDuplicate) {
      setFileName(null);
    }
  }, [props.fileName]);

  const setErrorAndReset = msg => {
    setFileContent('{}');
    setFileName(null);
    setFileError(msg);
  };

  useEffect(() => {
    if (props.campaigntype !== campType) {
      setCampType(props.campaigntype);
      setErrorAndReset(null);
    }
  }, [props.campaigntype]);

  const useStyles = makeStyles(theme => ({
    required: {
      ...theme.typography.subtitle2,
      backgroundColor: theme.palette.general.lightOrange,
      textAlign: 'center',
      padding: '0.5rem 1rem',
      margin: '4rem 0',
      borderRadius: '4px',
    },
    button: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '5rem',
      fontFamily: 'Roboto',
      color: 'white',
      backgroundColor: theme.palette.general.darkGrey,
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px',
      cursor: 'pointer',
    },
    downloadBtn: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '2rem',
      fontFamily: 'Roboto',
      color: theme.palette.general.darkGrey,
      border: `1px solid ${theme.palette.general.deepGrey}`,
      borderLeft: 'none',
      cursor: 'pointer',
      pointerEvents: 'all',
    },
    multiButtons: {
      display: 'flex',
    },
  }));
  const classes = useStyles();

  const AddButton = props => {
    return (
      <label {...props} className={classes.button}>
        {props.uploadText ? 'Upload' : 'Add'}
      </label>
    );
  };

  const ResetButton = props => {
    return (
      <label {...props} className={classes.button} onClick={resetButtonHandler}>
        Reset
      </label>
    );
  };

  const DownloadButton = props => {
    return (
      <label
        {...props}
        className={classes.downloadBtn}
        onClick={downloadButtonHandler}
      >
        <GetAppIcon />
      </label>
    );
  };

  const resetButtonHandler = () => {
    // setRerenderKey(Date.now());
    setErrorAndReset(null);
    if (props.didReset) {
      props.didReset();
    }
  };

  const downloadButtonHandler = async () => {
    try {
      let dataInfo = null;
      let id = props.campaignId;
      if (id == undefined && props.fileContent.indexOf('uploaded') !== -1 > 0)
        id = props.fileContent.split('_')[1];

      if (id) {
        const cdata = await Storage.get(
          tokens.env + '/campaign-originals/' + id + '.csv',
          {
            download: true,
            bucket: chatbotBucket,
            cacheControl: 'no-cache',
          }
        );

        dataInfo = new Buffer.from(cdata.Body).toString();

        var a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        document.body.appendChild(a);
        var blob = new Blob([dataInfo], { type: 'text/csv' });
        var url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = 'Chatbot-Campaign-' + fileName + '.csv';
        a.click();
      }
    } catch (ex) {
      console.log('downloadButtonHandler: ' + ex);
    }
  };

  const parseCSV = (data, key) => {
    let validMixedKeys = [];

    let enterpriseValidKeys = [
      'asurite',
      'salesforce',
      'emplid',
      'phonenumber',
    ];

    validMixedKeys = enterpriseValidKeys;

    let re = /^[a-zA-Z0-9]+$/;

    let phoneRegex = /([+]?\d{1,2}[.-\s]?)(\d{3}[.-]?){2}\d{4}/;
    let str = '';
    let invalidCount = 0;

    let invalidKeys = [];

    let all = [];

    let tester = key === 'phonenumber' ? phoneRegex : re;

    let customKeys = [];

    //console.log("!!! inside parseCSV");

    //console.log("!!! data is", data, data.length);

    for (var i = 0; i < data.length; ++i) {
      let isValid = true;
      let item = null;

      let allKeys = Object.keys(data[i]);
      let customKeysX = allKeys.filter(item => item.indexOf('csv_') > -1);

      for (var x = 0; x < customKeysX.length; ++x) {
        if (customKeys.indexOf(customKeysX[x]) === -1) {
          customKeys.push(customKeysX[x]);
        }
      }
      //console.log("!!! key is", key);
      if (data[i].value == '' && (data[i].type == null || data[i].type == ''))
        continue;
      if (key === 'mixed') {
        //console.log("!!!", validMixedKeys.indexOf(data[i].type));
        if (validMixedKeys.indexOf(data[i].type) > -1) {
          if (data[i].type === 'phonenumber') {
            data[i].value = data[i].value.replace(/[^a-zA-Z0-9]/g, '');
            if (data[i].value !== '+') {
              let addOn = '+';
              if (data[i].value.length === 10) {
                addOn = '+1';
              }
              data[i].value = addOn + data[i].value;
            }
          }
          all.push(data[i]);
        } else {
          if (invalidKeys.indexOf(data[i].type) === -1) {
            invalidKeys.push(data[i].type);
          }
          invalidCount++;
        }
      } else {
        console.log('hitting extras', data[i][key]);
        let extraFields = {};
        if (data[i][key]) {
          try {
            for (var j = 0; j < customKeys.length; ++j) {
              extraFields[customKeys[i]] = data[i][customKeys[i]]
                ? data[i][customKeys[i]]
                : '';
            }

            item = data[i][key];
            item = item.replace(/[^a-zA-Z0-9]/g, '');
            if (key === 'phonenumber' && item[0] !== '+') {
              let addOn = '+';
              if (item.length === 10) {
                addOn = '+1';
              }
              item = addOn + item;
            }
          } catch (e) {
            console.log('failed here:', e);
          }
        }
        if (item) {
          console.log('pushing', item);
          all.push({ user: item, isValid: isValid, ...extraFields });
        }
      }
    }

    if (all.length === 0) {
      if (key === 'phonenumber') {
        setErrorAndReset(
          'Phone numbers are required to have the country code. File will not be uploaded.'
        );
      } else if (key === 'asurite') {
        setErrorAndReset('No valid entries, file will not be uploaded');
      } else if (key === 'mixed') {
        setErrorAndReset(
          `No valid entries, file will not be uploaded. Invalid types found: ${invalidKeys.toString()}`
        );
      }
      return { all: null, customKeys: null };
    } else {
      if (invalidCount > 0) {
        if (key === 'phonenumber') {
          setWarning(
            `${invalidCount} Phone number(s) are missing the country code and will not be uploaded.`
          );
        } else if (key === 'asurite') {
          setWarning(`${invalidCount} asurites(s) are invalid.`);
        } else if (key === 'mixed') {
          setWarning(
            `${invalidCount} item(s) had invalid type of: ${invalidKeys.toString()}`
          );
        }
      }
      return { all: all, customKeys: customKeys };
    }
  };

  const handleCSVFileLoad = (e, csvFileName) => {
    let { result } = e.target;

    result = result.replace(/CSV_/g, 'csv_');
    let jsonArr = readString(result, { header: true });
    let data = jsonArr.data;
    let validInfo = [];
    let type = null;
    let dataType = null;

    props.saveOriginalCSV(result);

    if (!campType) {
      setWarning('Before adding a csv, first select a campaign message type.');
      setFileName(null);
      return;
    }

    if (!props.group) {
      setWarning('Before adding a csv, first select a group.');
      setFileName(null);
      return;
    }

    let keysOfFile = Object.keys(data[0]);
    let validMixedKeys = [
      'asurite',
      'phonenumber',
      'emplid',
      'salesforce',
      'crmid',
    ];

    for (var i = 0; i < keysOfFile.length; ++i) {
      keysOfFile[i] = keysOfFile[i].toLowerCase();
    }

    //console.log("!!! keysOfFile", keysOfFile);

    if (keysOfFile.indexOf('value') > -1 && keysOfFile.indexOf('type') > -1) {
      type = 'mixed';
    } else if (
      keysOfFile.indexOf('phonenumber') > -1 ||
      keysOfFile.indexOf('asurite') > -1 ||
      keysOfFile.indexOf('emplid') > -1 ||
      keysOfFile.indexOf('salesforce') > -1 ||
      keysOfFile.indexOf('crmid') > -1
    ) {
      type = 'mixed';

      const filteredArray = validMixedKeys.filter(value =>
        keysOfFile.includes(value)
      );

      //console.log("!!! filteredArray", filteredArray);

      let keyToUse = filteredArray[0];

      //console.log("!!! keyToUse", keyToUse);

      let newData = [];

      for (var i = 0; i < data.length; ++i) {
        //console.log("!!! data is", data);
        //console.log("!!! data[i][keyToUse] is", data[i][keyToUse]);
        if (data[i][keyToUse]) {
          newData.push({
            ...data[i],
            type: keyToUse,
            value: data[i][keyToUse],
          });
        }
      }

      data = newData;
    }

    if (
      campType.toLowerCase() === 'chat' &&
      type !== 'mixed' &&
      type !== 'asurite'
    ) {
      setErrorAndReset(
        "Chat requires 'asurite' header or mixed data file with 'value' & 'type' header"
      );
    } else if (
      campType.toLowerCase() === 'sms' &&
      type !== 'mixed' &&
      type !== 'phonenumber' &&
      type !== 'crmid'
    ) {
      setErrorAndReset(
        "SMS requires 'phonenumber' header or mixed data file with 'value' & 'type' header"
      );
    }

    //console.log("!!! type is", type);

    if (type) {
      //console.log("!!! parseCSV");
      let returnData = parseCSV(data, type);

      validInfo = returnData.all;
      let values = {};

      if (validInfo) {
        for (var i = validInfo.length - 1; i >= 0; --i) {
          if (!values[validInfo[i].value]) {
            values[validInfo[i].value] = true;
          } else {
            validInfo.splice(i, 1);
          }
        }

        console.log('valid info', validInfo.length);

        let allInfo = {
          type: type,
          data: validInfo,
        };

        setFileContent(JSON.stringify(allInfo));

        if (props.holdCsvData) {
          props.holdCsvData({
            data: allInfo.data,
            fileName: csvFileName,
            customFields: returnData.customKeys,
            type: type,
            conversionType: campType,
          });
        }
      }
    } else {
      setErrorAndReset(
        "File not valid, need either 'asurite' header, 'phonenumber' header or 'value' and 'type' headers"
      );
    }
  };

  const onChangeCSV = e => {
    setFileError(null);
    setWarning(null);
    const index = e.target.value.lastIndexOf('\\');
    const fileName = e.target.value.slice(index + 1);
    setFileName(fileName);

    const reader = new FileReader();
    //capture file
    reader.readAsText(e.target.files[0]);
    //remove files from the file input so that a repeated upload of the same csv file will trigger the onchange event.
    e.target.value = '';
    reader.onload = e => {
      handleCSVFileLoad(e, fileName);
    };
  };

  const onChangeImage = e => {
    setFileError(null);
    setWarning(null);
    const index = e.target.value.lastIndexOf('\\');
    const fileName = e.target.value.slice(index + 1);

    let isImage = !props.csvUpload;
    // console.log(isImage);

    const reader = new FileReader();

    let file = e.target.files[0];

    if (file) {
      let size = (file.size / 1024 / 1024).toFixed(4);

      if (isImage ? size < 5 : size < 1) {
        //* Successful Image
        if (props.setUploadFileLoaded) props.setUploadFileLoaded(true);
        setFileName(fileName);
        reader.readAsDataURL(file);
        reader.onload = async e => {
          props.setImgUploadData({
            fileType: file.type,
            file: e.target.result,
          });
        };
      } else {
        console.log('BAD SIZE', size);
        setWarning(`Image too large, must be under ${isImage ? '5' : '1'}MB`);
        setTimeout(() => {
          setWarning(null);
        }, 3000);
      }
    }
  };

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', cursor: 'default' }}
      key={rerenderKey}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div
          className="input"
          style={{
            ...theme.typography.body1,
            width: '100%',
            borderTopRightRadius: '0px',
            borderBottomRightRadius: '0px',
            border: `1px solid ${theme.palette.general.deepGrey}`,
            borderRight: 'none',
            color: theme.palette.general.darkGrey,
            ...props.style,
          }}
        >
          <p style={{ color: fileName ? 'black' : 'lightgrey' }}>
            {fileName ? fileName : props.placeholder}
          </p>
          {/* {fileName ? (
            fileName
          ) : (
            <p style={{ color: "white" }}>{props.placeholder}</p>
          )} */}

          {props.csvUpload ? (
            <input
              type="file"
              accept=".csv"
              id="upload"
              onChange={onChangeCSV}
              onInput={e => {
                console.log(e.target.value);
              }}
              style={{
                opacity: 0,
                position: 'absolute',
                pointerEvents: 'none',
              }}
            />
          ) : (
            <input
              type="file"
              onChange={onChangeImage}
              id="upload"
              // fileName={null}
              // fileContent={null}
              // ref={(fId) => {
              // setCurrentImagePicker(fId);
              // register(fId);
              // }}
              style={{
                opacity: 0,
                position: 'absolute',
                pointerEvents: 'none',
              }}
              accept=".jpeg,.jpg,.png,.gif"
            />
          )}
        </div>
        {fileName === props.placeholder || !fileName ? (
          <AddButton uploadText={props.uploadText} htmlFor="upload" />
        ) : (
          <div className={classes.multiButtons}>
            {props.noDownload ? null : <DownloadButton />}
            {/* <DownloadButton /> */}
            <ResetButton />
          </div>
        )}
      </div>
      {!props.csvUpload && (
        <p>
          Supported formats: .jpeg, .jpg, .png, .gif &#40;Max file size:
          5MB&#41;
        </p>
      )}
      {errors.audienceFileName && props.csvUpload && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: 'fit-content',
          }}
        >
          <ErrorLabel message="Please upload a .csv file" />
        </div>
      )}
      {errors.audienceFileContent && props.csvUpload && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: 'fit-content',
          }}
        >
          <ErrorLabel message="This field is required" />
        </div>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          height: '1px',
        }}
      >
        <div style={{ display: 'flex', flex: 1 }} />
        <input
          name={'audienceFileContent'}
          type={'text'}
          ref={
            props.form
              ? props.required
                ? register({ required: true })
                : register
              : null
          }
          value={fileContent}
          style={{
            opacity: 0,
            height: '1px',
            width: '1px',
          }}
        />
        <div style={{ display: 'flex', flex: 1 }} />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          height: '1px',
        }}
      >
        <div style={{ display: 'flex', flex: 1 }} />
        <input
          name={'audienceFileName'}
          type={'text'}
          ref={
            props.form
              ? props.required
                ? register({ required: true })
                : register
              : null
          }
          value={fileName}
          style={{
            opacity: 0,
            height: '1px',
            width: '1px',
          }}
        />
        <div style={{ display: 'flex', flex: 1 }} />
      </div>
      {fileError ? <ErrorLabel message={fileError} /> : null}
      {warning ? <ErrorLabel message={warning} warning="true" /> : null}
    </div>
  );
}
