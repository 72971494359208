import React, { useState, useEffect, useContext } from 'react';
import './NodeType.css';

import AuthContext from 'contexts/AuthContext';
import { ReactComponent as CloseIcon } from '../tempIcons/times-solid.svg';

// import { idGenerator } from "../utility.js";
import { allNodeOptions } from './utility.js';

export default function NodeType(props) {
  const tokens = useContext(AuthContext);
  const [showType, setShowType] = useState('base');

  useEffect(() => {
    setShowType('base');
  }, [props.show]);

  const canRender = (type, parentType, match) => {
    if (type === 'jump') {
      if (parentType === undefined && !match) {
        return false;
      } else {
        return true;
      }
    } else if (type === 'runBot') {
      return false;
    } else {
      return true;
    }
  };

  const _nodeOption = item => {
    let parentType = props.latest.type;

    if (canRender(item.type, parentType, props.latest.match)) {
      return (
        <div
          className="type_container"
          key={'option_' + item.type + '_' + new Date().getTime()}
          onClick={() => {
            if (item.section)
              handleClick({ type: item.type, customType: item.customType });
            else {
              setShowType(item.children);
            }
          }}
        >
          <div className="type_icon">
            {React.cloneElement(item.icon, { height: '50%' }, null)}
          </div>
          <div className="type_text_holder">
            <div className="type_title">{item.name}</div>
            <div className="type_info">{item.info}</div>
          </div>
          <div className="type_add_btn">ADD</div>
        </div>
      );
    } else {
      return null;
    }
  };

  const handleClick = t => {
    setShowType('base');
    props.addNode(t);
  };

  let botType = 'sunny';

  if (props.show) {
    return (
      <div>
        <div className="hider" onClick={() => props.close()}></div>
        <div className="popUpholder">
          <div className="inner_padding">
            <div className="exit" onClick={() => props.close()}>
              <CloseIcon />
            </div>
            <div className="mainText">
              {' '}
              {'CHOOSE ' + (showType !== 'base' ? 'SUB' : 'NODE') + ' TYPE'}
            </div>
            <div className="itemsContainer">
              {allNodeOptions.map((item, index) => {
                if (item.group === showType) {
                  return _nodeOption(item);
                } else return null;
              })}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
  // }
}
