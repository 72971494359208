import React, { useState, useEffect, useContext } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import KBForm from './KBForm';
import * as mutations from 'graphql/mutations';
import AuthContext from 'contexts/AuthContext';
import { store as KBStore } from 'contexts/KBStore';
import { store as GlobalStore } from 'contexts/GlobalStore';
import { useModal } from 'hooks';
import { Status, Modal, Input, ModalContent } from 'stories';
import { roleCheck } from 'services/utility/RoleMatrix';

import './KnowledgeBase.css';

const Alert = props => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

export default function CategoryInfo(props) {
  const tokens = useContext(AuthContext);
  const theme = useTheme();
  let history = useHistory();
  const { isShowing, toggle } = useModal();
  const KBState = useContext(KBStore);
  const dispatchKB = KBState.dispatch;
  const GlobalState = useContext(GlobalStore);
  const dispatchGlobal = GlobalState.dispatch;

  const [modalContentType, setModalContentType] = useState(null);
  const [FAQDataCopy, setFAQDataCopy] = useState([]);
  // const [parsedFAQs, setParsedFAQs] = useState([]);
  const [subcategoriesCopy, setSubcategoriesCopy] = useState([]);
  const [allFAQsInCategory, setAllFAQsInCategory] = useState([]);
  const [activeFAQs, setActiveFAQs] = useState(null);
  const [error, setError] = useState(null);
  const [showCatActions, setShowCatActions] = useState(false);
  const [showSubcatActions, setShowSubcatActions] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    if (
      props.activeCategory &&
      props.activeCategory.categoryInfo &&
      props.activeCategory.categoryInfo.subcategories
    ) {
      const initialActiveSubcats =
        props.activeCategory.categoryInfo.subcategories;

      const sortedActiveSubcats = sort(initialActiveSubcats);
      setSubcategoriesCopy(sortedActiveSubcats);
      setFAQDataCopy(props.FAQData);

      // const FAQData = props.FAQData;
      const category = props.activeCategory.title;
      const activeFAQs = [];
      // let parsedFAQs = [];
      // FAQData.forEach((el) => {
      //   if (
      //     el.categoryName === category &&
      //     el.subcategoryNames[0] === props.activeSubcategory
      //   ) {
      //     let tempEl = el;
      //     delete tempEl.parentCategory;
      //     parsedFAQs.push(tempEl);
      //   }
      // });
      // console.log("parsedFAQs: ", parsedFAQs);
      // setParsedFAQs(parsedFAQs);

      let allFAQsInCategory = [];
      FAQDataCopy.forEach(el => {
        delete el.parentCategory;
        if (el.categoryName === props.activeCategory.title)
          allFAQsInCategory.push(el);
      });

      setAllFAQsInCategory(allFAQsInCategory);

      props.FAQData.forEach(el => {
        if (
          el.categoryName === category &&
          el.subcategoryNames[0] === props.activeSubcategory
        ) {
          if (roleCheck(tokens.role, 'knowledgeBase', 'viewAllContent')) {
            el.editable = true;
          } else {
            if (
              roleCheck(tokens.role, 'knowledgeBase', 'editFAQ') &&
              (el.submittedBy === tokens.asurite ||
                tokens.groups.includes(el.group))
            ) {
              el.editable = true;
            } else {
              el.editable = false;
            }
          }
          activeFAQs.push({ ...el });
        }
      });
      setActiveFAQs(activeFAQs);
    }
  }, [props]);

  const sort = arr => {
    return arr.concat().sort((a, b) => {
      return a.title > b.title ? 1 : -1;
    });
  };

  const onClickFAQ = data => {
    dispatchGlobal({
      type: 'updateEditInfo',
      editInfo: {
        id: data.id,
        status: data.status,
        categoryName: data.categoryName,
        subcategoryNames: data.subcategoryNames,
        group: data.group,
        question: data.question,
        variations: data.variations,
        fallbackAnswer: data.fallbackAnswer,
        answerData: data.answerData,
        escalation: data.escalation,
        editedBy: data.editedBy,
        editedByKey: data.editedByKey,
        deletedBy: data.deletedBy,
        submittedOn: data.submittedOn,
        submittedOnKey: data.submittedOnKey,
        submittedBy: data.submittedBy,
        submittedByKey: data.submittedByKey,
        processingError: data.processingError,
        flow: data.flow ? JSON.parse(data.flow) : data.flow,
        flowVersion: data.flowVersion,
      },
    });
    props.setActiveFAQ(data.question);
    props.setRerenderKey(Date.now());
  };

  const getFAQstyles = el => {
    if (props.activeFAQ === el.question) {
      return {
        display: 'flex',
        flexDirection: 'row',
        margin: '0px -2rem 0px -4.5rem',
        padding: '0.5rem 0.5rem 0.5rem 4rem',
        backgroundColor: theme.palette.general.darkGrey,
        color: 'white',
      };
    } else {
      return {
        display: 'flex',
        flexDirection: 'row',
        margin: '0px -2rem 0px -4.5rem',
        padding: '0.5rem 0.5rem 0.5rem 4rem',
        // backgroundColor: theme.palette.general.darkGrey,
        // color: "white"
      };
    }
  };

  const toTitleCase = text => {
    text = text
      .toLowerCase()
      .split(' ')
      .map(s => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    return text;
  };

  const onClickEdit = () => {
    setModalContentType('editCategoryName');
    toggle();
  };

  const onClickAddSub = () => {
    setModalContentType('addSubCategory');
    toggle();
  };

  const onClickDelete = () => {
    let cancelDelete = false;
    allFAQsInCategory.forEach(el => {
      if (el.processing || el.status === 6) {
        cancelDelete = true;
      }
    });

    if (cancelDelete) {
      setSnackbarMessage('Cannot delete while FAQ is processing or deleting');
      setSnackbarOpen(true);
      return;
    } else {
      setModalContentType('deleteCategory');
      toggle();
      return;
    }
  };

  const onClickDeleteSub = () => {
    let cancelDelete = false;
    activeFAQs.forEach(el => {
      if (el.processing || el.status === 6) {
        cancelDelete = true;
      }
    });

    if (cancelDelete) {
      setSnackbarMessage('Cannot delete while FAQ is processing or deleting');
      setSnackbarOpen(true);
      return;
    } else {
      setModalContentType('deleteSubcategory');
      toggle();
      return;
    }
  };

  const onCloseModal = () => {
    setError(false);
  };

  const onSubmitDelCategory = async () => {
    const updatedCatData = {
      id: props.activeCategory.categoryInfo.id,
      deleted: true,
      deletedBy: {
        asurite: tokens.asurite,
        timestamp: new Date().getTime() + '',
      },
    };
    let delCategory;
    try {
      dispatchGlobal({
        type: 'setLoadingFormSubmission',
        loadingFormSubmission: true,
      });
      delCategory = await API.graphql(
        graphqlOperation(mutations.updateKnowledgeBaseCategory, {
          input: updatedCatData,
        })
      );

      let uncatCatId = null;

      for (var i = 0; i < props.categories.length; ++i) {
        if (props.categories[i].title === 'Uncategorized') {
          uncatCatId = props.categories[i].id;
        }
      }

      let updates = [];

      for (let i = 0; i < allFAQsInCategory.length; i++) {
        const updatedFAQData = {
          id: allFAQsInCategory[i].id,
          subcategoryNames: ['Empty'],
          categoryName: 'Uncategorized',
          categoryID: uncatCatId,
        };

        dispatchGlobal({
          type: 'setSpliceTableData',
          spliceTableData: {
            category: { title: 'Uncategorized' },
            FAQs: [allFAQsInCategory[i]],
            type: 'editFAQ',
          },
        });

        updates.push(
          API.graphql(
            graphqlOperation(mutations.updateKnowledgeBase, {
              input: updatedFAQData,
            })
          )
        );
      }
      await Promise.all(updates);
    } catch (error) {
      console.log('updateKnowledgeBaseCategory error: ', error);
    } finally {
      dispatchGlobal({
        type: 'setLoadingFormSubmission',
        loadingFormSubmission: false,
      });

      if (delCategory && delCategory.data) {
        dispatchGlobal({
          type: 'clearNextTokens',
          clearNextTokens: true,
        });
        history.push({
          pathname: `/knowledge-base/`,
        });
        toggle();
      } else {
        console.log('Something went wrong with your delete request');
        alert('Something went wrong with your delete request');
      }
    }
  };

  const onSubmitDelSubcategory = async () => {
    try {
      for (let i = 0; i < activeFAQs.length; i++) {
        const updatedFAQData = {
          id: activeFAQs[i].id,
          subcategoryNames: [],
          // subcategories: updatedSubcats
        };

        const uppdatedFAQ = await API.graphql(
          graphqlOperation(mutations.updateKnowledgeBase, {
            input: updatedFAQData,
          })
        );
      }

      const updatedSubcats = [];
      props.activeCategory.categoryInfo.subcategories.forEach(el => {
        if (el.title !== props.activeSubcategory) {
          updatedSubcats.push(el);
        }
      });

      const updatedCatData = {
        id: props.activeCategory.categoryInfo.id,
        subcategories: updatedSubcats,
      };

      const updatedCategory = await API.graphql(
        graphqlOperation(mutations.updateKnowledgeBaseCategory, {
          input: updatedCatData,
        })
      );

      if (updatedCategory) {
        dispatchGlobal({
          type: 'clearNextTokens',
          clearNextTokens: true,
        });
        history.push({
          pathname: `/knowledge-base/`,
        });
        toggle();
      } else {
        console.log('Something went wrong with your delete request');
        alert('Something went wrong with your delete request');
      }
    } catch (error) {
      console.error('updateFAQs error: ', error);
    }
  };

  const onSubmitEditCat = async (data, e) => {
    if (data.type !== 'submit') {
      const categories = props.categories.map(el => el.title.toLowerCase());
      if (categories.includes(data.categoryName.toLowerCase())) {
        setError(false);
        setError(true);
        return console.log('This category already exists');
      } else {
        setError(false);
      }

      dispatchGlobal({
        type: 'setLoadingFormSubmission',
        loadingFormSubmission: true,
      });
      const updatedCategory = {
        title: toTitleCase(data.categoryName),
        id: props.activeCategory.categoryInfo.id,
      };

      let updateCategory;
      try {
        updateCategory = await API.graphql(
          graphqlOperation(mutations.updateKnowledgeBaseCategory, {
            input: updatedCategory,
          })
        );
      } catch (error) {
        console.log('updateKnowledgeBaseCategory error: ', error);
      } finally {
        dispatchGlobal({
          type: 'setLoadingFormSubmission',
          loadingFormSubmission: false,
        });
        if (updateCategory && updateCategory.data) {
          props.setRerenderKey(Date.now());
          props.setActiveCategory({
            ...props.activeCategory,
            title: toTitleCase(data.categoryName),
          });
          history.push({
            pathname: `/knowledge-base/category`,
            search: `?category=${data.categoryName}`,
          });
          toggle();
        } else {
          console.log('Something went wrong with your request');
          alert('Something went wrong with your request');
        }
      }
    }
  };

  const onSubmitAddSubCat = async (data, e) => {
    if (data.type !== 'submit') {
      const { categoryInfo } = props.activeCategory;

      const subcatsLowerCase = subcategoriesCopy.map(el =>
        el.title.toLowerCase()
      );
      if (subcatsLowerCase.includes(data.subcategoryName.toLowerCase())) {
        setError(false);
        setError(true);
        return console.log('This subcategory already exists');
      } else {
        setError(false);
      }

      const addSubcategory = {
        ...categoryInfo,
        subcategories: [
          ...categoryInfo.subcategories,
          {
            // key: categoryInfo.subcategories.length,
            title: toTitleCase(data.subcategoryName),
          },
        ],
      };

      let updateCategory;
      try {
        dispatchGlobal({
          type: 'setLoadingFormSubmission',
          loadingFormSubmission: true,
        });
        updateCategory = await API.graphql(
          graphqlOperation(mutations.updateKnowledgeBaseCategory, {
            input: addSubcategory,
          })
        );
      } catch (error) {
        console.log('updateKnowledgeBaseCategory error: ', error);
      } finally {
        dispatchGlobal({
          type: 'setLoadingFormSubmission',
          loadingFormSubmission: false,
        });
        if (updateCategory && updateCategory.data) {
          props.setRerenderKey(Date.now());
          props.setActiveCategory({
            title: props.activeCategory.title,
            categoryInfo: {
              ...props.activeCategory.categoryInfo,
              subcategories: [
                ...categoryInfo.subcategories,
                {
                  // key: categoryInfo.subcategories.length,
                  title: toTitleCase(data.subcategoryName),
                },
              ],
            },
          });
          toggle();
        } else {
          console.log('Something went wrong with your request');
          alert('Something went wrong with your request');
        }
      }
    }
  };

  const addSubCatFormData = [
    {
      title: 'TYPE NAME',
      component: (
        <Input
          form
          required
          name="subcategoryName"
          cannotEqualList={subcategoriesCopy.map(el => el.title)}
          errorMessage="This type already exists"
          setError={error}
        />
      ),
    },
  ];

  const editCatFormData = [
    {
      title: 'SECTION NAME',
      component: (
        <Input
          form
          required
          name="categoryName"
          stateValue={props.activeCategory ? props.activeCategory.title : null}
          cannotEqualList={props.categories.map(el => el.title.toLowerCase())}
          errorMessage="This section already exists"
          setError={error}
        />
      ),
    },
  ];

  const createModalContent = () => {
    let title = '';
    let formData = null;
    let form = null;
    let confirmation = null;
    let onSubmitFunc = null;

    if (modalContentType === 'editCategoryName') {
      formData = editCatFormData;
      form = true;
      title = 'EDIT SECTION NAME';
      onSubmitFunc = onSubmitEditCat;
    } else if (modalContentType === 'addSubCategory') {
      formData = addSubCatFormData;
      form = true;
      title = 'ADD TYPE';
      onSubmitFunc = onSubmitAddSubCat;
    } else if (modalContentType === 'deleteCategory') {
      confirmation = true;
      title = 'Confirm delete section';
      onSubmitFunc = onSubmitDelCategory;
    } else if (modalContentType === 'deleteSubcategory') {
      confirmation = true;
      title = 'Confirm delete type';
      onSubmitFunc = onSubmitDelSubcategory;
    }

    return (
      <ModalContent
        confirmation={confirmation}
        form={form}
        formData={formData}
        title={title}
        onSubmit={onSubmitFunc}
        toggle={toggle}
      />
    );
  };

  if (props.activeCategory) {
    return (
      <div style={{ display: 'flex', flex: 1, width: '100%' }}>
        <div
          style={{
            flex: 1,
            width: '50%',
            padding: '2rem',
            borderLeft: `1px solid ${theme.palette.general.mediumGrey}`,
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              fontWeight: '700',
              color: theme.palette.general.darkGrey,
              marginBottom: '2rem',
            }}
          >
            <div style={{ display: 'flex' }}>
              <div>
                <div
                  className={
                    roleCheck(
                      tokens.role,
                      'knowledgeBase',
                      'createAddDeleteSection'
                    )
                      ? 'clickable'
                      : ''
                  }
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: '1rem',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row',
                    }}
                    onClick={() => {
                      return roleCheck(
                        tokens.role,
                        'knowledgeBase',
                        'createAddDeleteSection'
                      ) &&
                        props.activeCategory &&
                        props.activeCategory.title !== 'Uncategorized'
                        ? setShowCatActions(!showCatActions)
                        : null;
                    }}
                  >
                    <div style={{ fontWeight: '900' }}>
                      {props.activeCategory && props.activeCategory.title}
                    </div>
                    {roleCheck(
                      tokens.role,
                      'knowledgeBase',
                      'createAddDeleteSection'
                    ) &&
                    props.activeCategory &&
                    props.activeCategory.title !== 'Uncategorized' ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {showCatActions ? (
                          <KeyboardArrowUpIcon style={{ color: '#d1d1d1' }} />
                        ) : (
                          <KeyboardArrowDownIcon style={{ color: '#d1d1d1' }} />
                        )}
                      </div>
                    ) : null}
                  </div>
                  {showCatActions && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginBottom: '0.5rem',
                      }}
                    >
                      <div
                        style={{
                          fontSize: '0.85rem',
                          marginRight: '0.25rem',
                        }}
                      >
                        Section:
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          color: theme.palette.general.maroon,
                          fontSize: '0.85rem',
                          height: '1rem',
                        }}
                        className="hoverButton"
                        onClick={onClickEdit}
                      >
                        edit
                      </div>

                      <div
                        className="hoverButton"
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          color: theme.palette.general.maroon,
                          fontSize: '0.85rem',
                          marginLeft: '0.25rem',
                          height: '1rem',
                        }}
                        onClick={onClickDelete}
                      >
                        delete
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className={
                    roleCheck(
                      tokens.role,
                      'knowledgeBase',
                      'createAddDeleteSection'
                    )
                      ? 'clickable'
                      : null
                  }
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    fontWeight: '400',
                    fontSize: '1rem',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      cursor: 'default',
                    }}
                    // onClick={() => {
                    //   if (
                    //     roleCheck(
                    //       tokens.role,
                    //       "knowledgeBase",
                    //       "createAddDeleteSection"
                    //     )
                    //   ) {
                    //     setShowSubcatActions(!showSubcatActions);
                    //   }
                    // }}
                  >
                    <div>{props.activeSubcategory}</div>
                    {/* {roleCheck(
                      tokens.role,
                      "knowledgeBase",
                      "createAddDeleteSection"
                    ) && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {showSubcatActions ? (
                          <KeyboardArrowUpIcon style={{ color: "#d1d1d1" }} />
                        ) : (
                          <KeyboardArrowDownIcon style={{ color: "#d1d1d1" }} />
                        )}
                      </div>
                    )} */}
                  </div>
                  {/* {showSubcatActions && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "0.85rem",
                          marginRight: "0.25rem",
                        }}
                      >
                        <div style={{ fontWeight: "bold" }}>Type:</div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          color: theme.palette.general.maroon,
                          fontSize: "0.85rem",
                          height: "1rem",
                        }}
                        className="hoverButton"
                        onClick={onClickAddSub}
                      >
                        add
                      </div>
                      {
                        // <div
                        //   style={{
                        //     display: "flex",
                        //     flexDirection: "row",
                        //     alignItems: "center",
                        //     color: theme.palette.general.maroon,
                        //     fontSize: "0.85rem",
                        //     height: "1rem",
                        //     marginLeft: "0.25rem",
                        //   }}
                        //   className="hoverButton"
                        //   onClick={onClickDeleteSub}
                        // >
                        //   delete
                        // </div>
                      }
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </div>
          <ul>
            {activeFAQs &&
              activeFAQs.map((el, index) => {
                return (
                  <div
                    key={index}
                    className="clickable hoverBackground"
                    style={getFAQstyles(el)}
                    onClick={() => {
                      if (el.status !== 6 && !el.processing && el.editable) {
                        return onClickFAQ(el);
                      } else {
                        setSnackbarMessage('Cannot edit this FAQ');
                        setSnackbarOpen(true);
                        return null;
                      }
                    }}
                  >
                    <li>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div>{el.question}</div>
                        <div style={{ marginLeft: '1rem' }}>
                          {el.status === 6 ? <Status status={6} /> : null}
                          {el.processing ? <Status processing={true} /> : null}
                        </div>
                      </div>
                    </li>
                  </div>
                );
              })}
          </ul>
        </div>
        <div
          style={{
            flex: 1,
            width: '50%',
            padding: '2rem',
            borderLeft: `1px solid ${theme.palette.general.mediumGrey}`,
          }}
        >
          {props.activeSubcategory && (
            <div
              style={{
                fontWeight: '700',
                color: theme.palette.general.darkGrey,
              }}
            >
              EDIT FAQ
            </div>
          )}
          <div>
            {props.activeFAQ && (
              <KBForm
                noFormTitle
                deleteButton="true"
                rerenderKey={props.rerenderKey}
                setRerenderKey={props.setRerenderKey}
              />
            )}
          </div>
        </div>
        <Modal
          isShowing={isShowing}
          hide={toggle}
          content={createModalContent()}
          onCloseModal={onCloseModal}
        />
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
        >
          <Alert onClose={() => setSnackbarOpen(false)} severity="error">
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
    );
  } else {
    return (
      <div
        style={{
          flex: 1,
          borderLeft: `1px solid ${theme.palette.general.mediumGrey}`,
        }}
      />
    );
  }
}
