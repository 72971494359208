import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import GroupIcon from '@material-ui/icons/Group';
import ChatIcon from '@material-ui/icons/Chat';
import InfoIcon from '@material-ui/icons/Info';
import SvgIcon from '@material-ui/core/SvgIcon';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SmsOutlined from '@material-ui/icons/SmsOutlined';

// import something from '../assets/icons/'

//! New Icons Imports
//* Light

//* Dark
import { ReactComponent as DashIcon } from '../assets/icons/icon-dash.svg';
import { ReactComponent as KBIcon } from '../assets/icons/icon-kb.svg';
import { ReactComponent as FlowsIcon } from '../assets/icons/icon-flows.svg';
import { ReactComponent as CampaignIcon } from '../assets/icons/icon-alerts.svg';
import { ReactComponent as DialoguesIcon } from '../assets/icons/icon-dialog.svg';
import { ReactComponent as UsersIcon } from '../assets/icons/icon-user.svg';
// import { ReactComponent as AnalyzeIcon} from "../assets/icons/icon-analyze.svg";
import { ReactComponent as AnnoucementsIcon } from '../assets/icons/announcement-blk.svg';
import { ReactComponent as EscalationsIcon } from '../assets/icons/icon-escalations.svg';
import { ReactComponent as SettingsIcon } from '../assets/icons/icon-settings.svg';
import { ReactComponent as ResponseIcon } from '../assets/icons/icon-response-dark.svg';
import { ReactComponent as ImageGalleryIcon } from '../assets/icons/image-gallery-menu-dark@3x.svg';
import AuthContext from 'contexts/AuthContext';
import { Announcement } from '@material-ui/icons';
import { Responses } from 'pages/Responses';

export default function ListItems(props) {
  const tokens = useContext(AuthContext);

  const [viewFlowDrop, setViewFlowDrop] = useState(false);
  const adminMaintItem = (
    <Link to="/admin-maintenance">
      <ListItem button>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="MAINTENANCE" />
      </ListItem>
    </Link>
  );

  const adminStatusItem = (
    <Link to="/admin-status">
      <ListItem button>
        <ListItemIcon>
          <InfoIcon />
        </ListItemIcon>
        <ListItemText primary="STATUS" />
      </ListItem>
    </Link>
  );

  function attachAdminMaint(t) {
    if (t && t.devAccess) {
      return adminMaintItem;
    } else {
      return null;
    }
  }

  function attachAdminStatus(t) {
    // eventually let all admins add statuses
    if (t && t.devAccess) {
      return adminStatusItem;
    } else {
      return null;
    }
  }

  function renderCustomIntros() {
    // if (
    //   window.location.href.includes('localhost') ||
    //   window.location.href.includes('-test')
    // ) {
    //   return tokens.groups === 'pro_off1536177925092' ||
    //     tokens.groups.includes('Fin_1593621826') ||
    //     tokens.groups.includes('fin_aid1571851582312') ||
    //     tokens.role === 'admin' ? (
    //     <Link to="/CustomIntroductions">
    //       <strong title="Custom Introductions">
    //         <ListItem button>
    //           <ListItemIcon>
    //             <SvgIcon component={SmsOutlined} />
    //           </ListItemIcon>
    //           <ListItemText primary="CUSTOM INTROS" />
    //         </ListItem>
    //       </strong>
    //     </Link>
    //   ) : null;
    // } else {
    //   return null;
    // }

    return tokens.groups === 'pro_off1536177925092' ||
      tokens.groups.includes('Fin_1593621826') ||
      tokens.groups.includes('fin_aid1571851582312') ||
      tokens.role === 'admin' ? (
      <Link to="/CustomIntroductions">
        <strong title="Custom Introductions">
          <ListItem button>
            <ListItemIcon>
              <SvgIcon component={SmsOutlined} />
            </ListItemIcon>
            <ListItemText primary="CUSTOM INTROS" />
          </ListItem>
        </strong>
      </Link>
    ) : null;
  }

  const mainListItems = () => {
    return (
      <div>
        <Link to="/knowledge-base">
          <strong title="Knowledge Base">
            <ListItem button>
              <ListItemIcon>
                <SvgIcon component={KBIcon} />
              </ListItemIcon>
              <ListItemText primary="KNOWLEDGE BASE" />
            </ListItem>
          </strong>
        </Link>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Link to="/flows" style={{ flex: 2 }}>
            <strong title="Flows">
              <ListItem button>
                <ListItemIcon>
                  {/* <CallSplitIcon /> */}
                  <SvgIcon component={FlowsIcon} />
                </ListItemIcon>
                <ListItemText primary="FLOWS" />
              </ListItem>
            </strong>
          </Link>
          <div onClick={() => setViewFlowDrop(!viewFlowDrop)}>
            <ArrowDropDownIcon
              style={{
                transform: viewFlowDrop ? 'rotateX(180deg)' : 'rotateX(0deg)',
              }}
            />
          </div>
        </div>
        {viewFlowDrop ? (
          <Link to="/flow-messages">
            <ListItem button>
              <ListItemIcon></ListItemIcon>
              <ListItemText primary="MESSAGES" />
            </ListItem>
          </Link>
        ) : null}
        <Link to="/campaigns">
          <strong title="Campaigns">
            <ListItem button>
              <ListItemIcon>
                <SvgIcon component={CampaignIcon} />
              </ListItemIcon>
              <ListItemText primary="CAMPAIGNS" />
            </ListItem>
          </strong>
        </Link>
        <Link to="/dialogues">
          <strong title="Dialogues">
            <ListItem button>
              <ListItemIcon>
                <SvgIcon component={DialoguesIcon} />
              </ListItemIcon>
              <ListItemText primary="DIALOGUES" />
            </ListItem>
          </strong>
        </Link>
        <Link to="/users-groups">
          <strong title="Users / Groups">
            <ListItem button>
              <ListItemIcon>
                <SvgIcon component={UsersIcon} />
              </ListItemIcon>
              <ListItemText primary="USERS / GROUPS" />
            </ListItem>
          </strong>
        </Link>
        <Link to="/escalations">
          <strong title="Escalations">
            <ListItem button>
              <ListItemIcon>
                <SvgIcon component={EscalationsIcon} />
              </ListItemIcon>
              <ListItemText primary="ESCALATIONS" />
            </ListItem>
          </strong>
        </Link>
        <Link to="/alerts">
          <strong title="Alerts">
            <ListItem button>
              <ListItemIcon>
                <AnnouncementIcon />
              </ListItemIcon>
              <ListItemText primary="ALERTS" />
            </ListItem>
          </strong>
        </Link>
        <Link to="/responses">
          <strong title="Responses">
            <ListItem button>
              <ListItemIcon>
                <ResponseIcon />
              </ListItemIcon>
              <ListItemText primary="RESPONSES" />
            </ListItem>
          </strong>
        </Link>
        <Link to="/image-gallery">
          <strong title="Image Gallery">
            <ListItem button>
              <ListItemIcon>
                {/* <PresentToAllIcon /> */}
                <SvgIcon component={ImageGalleryIcon} />
              </ListItemIcon>
              <ListItemText primary="IMAGE GALLERY" />
            </ListItem>
          </strong>
        </Link>

        {renderCustomIntros()}

        {(tokens.role === 'publisher' &&
          tokens.groups.includes('Fin_1593621826')) ||
        tokens.role === 'admin' ? (
          <Link to="/announcements">
            <strong title="Announcements">
              <ListItem button>
                <ListItemIcon>
                  <SvgIcon component={AnnoucementsIcon} />
                </ListItemIcon>
                <ListItemText primary="ANNOUNCEMENTS" />
              </ListItem>
            </strong>
          </Link>
        ) : null}
      </div>
    );
  };

  const secondaryListItems = (
    <div>
      <Link to="/knowledge-base">
        <ListItem button>
          <ListItemIcon>
            <EmojiObjectsIcon />
          </ListItemIcon>
          <ListItemText primary="KNOWLEDGE BASE" />
        </ListItem>
      </Link>
      <Link to="/dialogues">
        <ListItem button>
          <ListItemIcon>
            <ChatIcon />
          </ListItemIcon>
          <ListItemText primary="DIALOGUES" />
        </ListItem>
      </Link>
      <Link to="/users-groups">
        <ListItem button>
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText primary="USERS / GROUPS" />
        </ListItem>
      </Link>
    </div>
  );

  const chatAgentItems = () => {
    return (
      <div>
        <Link to="/dialogues">
          <ListItem button>
            <ListItemIcon>
              <SvgIcon component={DialoguesIcon} />
            </ListItemIcon>
            <ListItemText primary="DIALOGUES" />
          </ListItem>
        </Link>
        <Link to="/users-groups">
          <ListItem button>
            <ListItemIcon>
              <SvgIcon component={UsersIcon} />
            </ListItemIcon>
            <ListItemText primary="USERS / GROUPS" />
          </ListItem>
        </Link>
      </div>
    );
  };

  if (props.type === 'secondary') {
    return secondaryListItems;
  } else {
    return (
      <div>
        {props.tokens.role !== 'chat agent'
          ? mainListItems()
          : chatAgentItems()}
        {attachAdminMaint(props.tokens)}
        {attachAdminStatus(props.tokens)}
      </div>
    );
  }
}
