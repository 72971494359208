import React, { useState, useEffect } from "react";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { useFormContext } from "react-hook-form";
import "./InputVerify.css";
import { idGenerator } from "services/utility/util.js";
import { API, graphqlOperation } from "aws-amplify";

export default function InputVerifyForm(props) {
  let { invalidmatch } = props;

  const theme = useTheme();
  const { register } = useFormContext();

  const [value, setValue] = useState("");
  const [invalid, setInvalid] = useState(" ");
  const [valid, setValid] = useState(false);
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    console.log("VAL CHANGE", props.value);
    setValue(props.value);
  }, [props.value]);

  const useStyles = makeStyles((theme) => ({
    required: {
      ...theme.typography.subtitle2,
      backgroundColor: theme.palette.general.lightOrange,
      textAlign: "center",
      padding: "0.5rem 1rem",
      margin: "4rem 0",
      borderRadius: "4px",
    },
    button: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "5rem",
      fontFamily: "Roboto",
      color: "white",
      backgroundColor: theme.palette.general.darkGrey,
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
      cursor: "pointer",
      pointerEvents: props.viewOnly ? "none" : "all"
    },
  }));

  const classes = useStyles();

  const resetInvalid = () => {
    setValue("");
    setTimeout(() => {
      setInvalid(" ");
    }, 5000);
  };

  const verify = () => {
    setIsRunning(true);
    if (props.verifyFn) {
    } else if (props.verifyQuery) {
      API.graphql(
        graphqlOperation(props.verifyQuery.query, {
          [props.verifyQuery.idName]: value,
        })
      )
        .then((resp) => {
          let data = resp.data[props.verifyQuery.name];
          console.log(data);
          setIsRunning(false);
          if (!data) {
            setInvalid("Could not find");
            resetInvalid();
          } else {
            if (props.filter) {
              if (data[props.filter.key] !== props.filter.value) {
                setInvalid("Could not find");
                resetInvalid();
              } else {
                setValid(true);
                setTimeout(() => {
                  setValid(false);
                }, 5000);
              }
            } else {
              setValid(true);
              setTimeout(() => {
                setValid(false);
              }, 5000);
            }
          }
          console.log("DONE", data);
        })
        .catch((err) => {
          setIsRunning(false);
          setInvalid("There was an issue verifying");
          resetInvalid();
        });
    } else {
      setIsRunning(false);
      setInvalid("Could not verify");
      resetInvalid();
    }
  };

  const AddButton = (props) => {
    return (
      <label {...props} className={classes.button} onClick={verify}>
        Verify
      </label>
    );
  };

  let invalidClasses = "invalid ";
  if (invalid !== " ") {
    invalidClasses += "showInvalid";
  }

  const onfocus = (e) => {
    if (props.isFocused) {
      props.isFocused(true);
    }
  };

  const onblur = (e) => {
    if (props.isFocused) {
      props.isFocused(false);
    }
  };

  return (
    <div style={{ cursor: "default" }}>
      <div style={{ display: "flex" }}>
        <input
          className="input"
          value={value}
          name={props.name}
          readOnly={props.viewOnly ? "readonly" : false}
          style={{
            ...theme.typography.body1,
            width: "100%",
            borderTopRightRadius: "0px",
            borderBottomRightRadius: "0px",
            border: `1px solid ${theme.palette.general.deepGrey}`,
            color: theme.palette.general.darkGrey,
            ...props.style,
          }}
          onFocus={onfocus}
          onBlur={onblur}
          ref={
            props.form
              ? props.required
                ? register({ required: true })
                : register
              : null
          }
          type="text"
          onChange={(e) => {
            setInvalid(" ");
            setValue(e.target.value);
          }}
        />
        <AddButton htmlFor="add" />
      </div>
      {valid ? (
        <div className="valid">Validated</div>
      ) : (
        <div className={invalidClasses}>{invalid}</div>
      )}
    </div>
  );
}
