import React, { useState, useEffect, useContext } from 'react';
import AuthContext from 'contexts/AuthContext';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import * as mutations from 'graphql/mutations';
import * as queries from 'graphql/queries';
import moment from 'moment-timezone';
import * as util from './CampaignAnalyticsUtil.js';
import { CSVLink, CSVDownload } from 'react-csv';

import {
  H6,
  Accordion,
  PanelButton,
  MiniTable,
  ThreeSquare,
  ItemNumberDisplay,
  AccordionDetail,
  Modal,
  ModalContent,
} from 'stories';

//import "../CampaignTabs.scss";
import './Overview.scss';

export default function CampaignAnalyticsOverview(props) {
  const [panelSectionData, setPanelSectionData] = useState(null);
  const [searchingForDisplayNames, setSearchingForDisplayNames] = useState(
    true
  );
  const [userDisplayNames, setUserDisplayNames] = useState({});
  const [csvData, setCsvData] = useState(null);
  const [csvHeaders, setCsvHeaders] = useState(null);
  const [dataIsDone, setDataIsDone] = useState(false);
  const [download, setDownload] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isShowing, setIsShowing] = useState(0);

  const tokens = useContext(AuthContext);

  function getSendLevelErrors() {
    let allInfo = [];
    let nextToken = null;
    return new Promise(async function tryagain(resolve, reject) {
      // resolve([]);
      let params = {
        campaignId: props.id,
        filter: {
          or: [
            { exitType: { eq: 'error' } },
            { expiredBeforeSend: { eq: true } },
          ],
        },
      };

      if (nextToken) {
        params.nextToken = nextToken;
      }

      let result = await API.graphql(
        graphqlOperation(queries.compCampById, params)
      );

      allInfo = allInfo.concat(result.data.compCampById.items);
      nextToken = result.data.compCampById.nextToken;

      if (nextToken) {
        tryagain(resolve, reject);
      } else {
        for (var i = 0; i < allInfo.length; ++i) {
          if (allInfo[i].expiredBeforeSend)
            allInfo[i].error = 'Expired before send';
        }

        resolve(allInfo);
      }
    });
  }

  function getCampaignMessages(campaignId) {
    let allInfo = [];
    let page = 0;
    return new Promise(async function tryagain(resolve, reject) {
      let results = await API.graphql(
        graphqlOperation(queries.searchMessages, {
          operation: 'queryForCampMsgs',
          payload: JSON.stringify({
            campaignId: campaignId,
            page: page,
          }),
        })
      );

      let resultsx = JSON.parse(results.data.searchMessages);

      allInfo = allInfo.concat(resultsx);

      if (resultsx.length > 0 && page < 100) {
        ++page;
        tryagain(resolve, reject);
      } else {
        resolve(allInfo);
      }
    });
  }

  const getInfo = (query, payload, allInfo = [], token = null) => {
    return new Promise(async function tryagain(resolve, reject) {
      if (token) {
        payload.nextToken = token;
      }
      let resp = await API.graphql(graphqlOperation(queries[query], payload));

      let tmp = resp.data[query].items;
      allInfo = allInfo.concat(tmp);

      if (resp.data[query].nextToken) {
        token = resp.data[query].nextToken;
        tryagain(resolve, reject);
      } else {
        resolve(allInfo);
      }
    });
  };

  const getTwilioLevelErrors = () => {
    return new Promise(async function(resolve, reject) {
      let resp = await API.graphql(
        graphqlOperation(queries.searchMessages, {
          operation: 'getTwilioLevelErrors',
          payload: JSON.stringify({
            campaignId: props.id,
          }),
        })
      );
      resolve(JSON.parse(resp.data.searchMessages));
    });
  };

  const getErrorMessage = async errorCode => {
    const errorMessageFromDynamo = await API.graphql(
      graphqlOperation(queries.getChatbotErrorMessages, {
        errorCode: errorCode,
      })
    );
    let msg = errorCode + '';

    if (errorMessageFromDynamo.data.getChatbotErrorMessages !== null) {
      //update panelSectionData array
      msg +=
        ' ' + errorMessageFromDynamo.data.getChatbotErrorMessages.errorMessage;
    }

    return msg;
  };

  const getQueryableData = (flowId, flowVersion) => {
    let queryableData = [];
    let flowTracker = [];
    let allFlowsIncluded = [{ flowId: flowId, flowVersion: flowVersion }];

    return new Promise(async function tryagain(resolve, reject) {
      while (allFlowsIncluded[0]) {
        if (allFlowsIncluded[0].flowVersion === '1-100000000') {
          let latestFlowVersionResponse = await API.graphql(
            graphqlOperation(queries.listFlowVersions, {
              id: allFlowsIncluded[0].flowId,
            })
          );

          allFlowsIncluded[0].flowVersion = latestFlowVersionResponse.data.listFlowVersions.items.at(
            -2
          ).version;
        }

        let resp = await API.graphql(
          graphqlOperation(queries.getFlowVersion, {
            id: allFlowsIncluded[0].flowId,
            version: allFlowsIncluded[0].flowVersion,
          })
        );

        // TODO: Here there is an error when opening a created campaign using latest flow
        let queryableDataResponse = JSON.parse(
          resp.data.getFlowVersion.queryableData
        );

        queryableData = queryableData.concat(queryableDataResponse);

        let nodes = JSON.parse(resp.data.getFlowVersion.flowNodes);

        for (var i = 0; i < nodes.length; ++i) {
          if (
            nodes[i].type === 'jump' &&
            nodes[i].jumpToNodeId &&
            flowTracker.indexOf(nodes[i].jumpToNodeId) === -1
          ) {
            flowTracker.push(nodes[i].jumpToNodeId);
            try {
              let flow = JSON.parse(nodes[i].jumpToNodeId);
              let item = {
                flowId: JSON.parse(flow.flow).value,
                flowVersion: JSON.parse(flow.version).value,
              };
              allFlowsIncluded.push(item);
            } catch (e) {}
          }
        }

        allFlowsIncluded.shift(0, 1);
      }

      resolve(queryableData);
    });
  };

  async function fetchData() {
    const campaignId = props.id;
    const flowId = props.editInfo.flow.value;
    const flowVersion = JSON.parse(props.editInfo.flowVersion).value;

    let allPayloads = {
      campaignId,
      limit: 1000,
    };

    const [
      userInputs,
      listOfCompleted,
      listOfRunning,
      listOfPending,
      sendLevelErrors,
      twilioLevelErrors,
      campaignMessages,
    ] = await Promise.all([
      getInfo('byCampaignId', allPayloads),
      getInfo('compCampById', allPayloads),
      getInfo('runningCampById', allPayloads),
      getInfo('queuedCampById', allPayloads),
      getSendLevelErrors(),
      getTwilioLevelErrors(),
      getCampaignMessages(campaignId),
    ]);

    const dataObj = await util.parseOutResponses(
      userInputs,
      listOfCompleted,
      listOfRunning,
      listOfPending,
      sendLevelErrors,
      twilioLevelErrors,
      campaignMessages,
      flowId,
      flowVersion,
      getQueryableData
    );

    setPanelSectionData(dataObj);

    let newTabDataCache = Object.assign({}, props.tabDataCache);
    newTabDataCache[props.panelTitle] = dataObj;
    props.setTabDataCache(newTabDataCache);
  }

  const queryForNames = (arrGroup, type, botType) => {
    return new Promise(async function(resolve, reject) {
      let payload = {
        type: type,
        botType: botType,
        values: arrGroup,
      };

      let resp = await API.graphql(
        graphqlOperation(queries.searchMessages, {
          operation: 'queryForUsersV2',
          payload: JSON.stringify(payload),
        })
      );

      resolve(JSON.parse(resp.data.searchMessages));
    });
  };

  const getUserNames = () => {
    return new Promise(async function(resolve, reject) {
      let BATCH_GET_LIMIT = 500;
      let BATCH_ES_LIMIT = 10;
      let arr = [];

      let start = new Date().getTime();

      let importedNumbers = props.importSummaryData.found.concat(
        props.importSummaryData.newNums
      );

      for (var i = 0; i < importedNumbers.length; ++i) {
        arr.push(importedNumbers[i].user);
      }

      let count = 0;

      let proms = [];
      let type = !isNaN(arr[0]) ? 'phoneNumber' : 'asurite';
      let botType = 'sunny';

      let allUsers = {};

      for (var i = 0; i < arr.length; i += BATCH_GET_LIMIT) {
        let x = [...arr].splice(i, BATCH_GET_LIMIT);

        proms.push(queryForNames(x, type, botType));

        count += x.length;

        if (proms.length === BATCH_ES_LIMIT || arr.length === count) {
          let resp = await Promise.all(proms);
          proms = [];

          setProgress((count / arr.length).toFixed(2) * 100);

          for (var j = 0; j < resp.length; ++j) {
            allUsers = {
              ...allUsers,
              ...resp[j],
            };
          }
        }
      }

      for (var i = 0; i < importedNumbers.length; ++i) {
        if (!allUsers[importedNumbers[i].user]) {
          allUsers[importedNumbers[i].user] = {
            [type]: importedNumbers[i].user,
          };
        }
      }

      setUserDisplayNames(allUsers);
      resolve();
    });
  };

  useEffect(() => {
    if (csvData) {
      setTimeout(() => {
        setIsShowing(false);
        setProgress(0);
        setDownload(true);
      }, 750);
    }
  }, [csvData]);

  useEffect(() => {
    if (
      userDisplayNames &&
      Object.keys(userDisplayNames).length > 0 &&
      panelSectionData &&
      panelSectionData.campaignAnalyticsResponses
    ) {
      let csvDatax = util.getCsvData(
        panelSectionData,
        tokens,
        props.importSummaryData,
        userDisplayNames
      );

      let headers = csvDatax.shift();
      setCsvHeaders(headers);
      setCsvData(csvDatax);
    }
  }, [userDisplayNames]);

  useEffect(() => {
    if (download === true) {
      setDownload(false);
    }
  }, [download]);

  useEffect(() => {
    if (panelSectionData === null) {
      if (
        typeof props.tabDataCache[props.panelTitle] === 'undefined' ||
        props.tabDataCache[props.panelTitle] === null
      ) {
        fetchData();
      } else {
        setPanelSectionData(props.tabDataCache[props.panelTitle]);
      }
    }
  }, [panelSectionData]);

  const event__refreshData = () => {
    //update tabDataCache
    let newTabDataCache = Object.assign({}, props.tabDataCache);
    newTabDataCache[props.panelTitle] = null;
    props.setTabDataCache(newTabDataCache);

    setPanelSectionData(null);
  };

  let overview =
    panelSectionData && panelSectionData.campaignAnalyticsOverview
      ? panelSectionData.campaignAnalyticsOverview
      : {};

  let quickDisplay = [
    {
      label: 'clicks',
      value: overview.clicks,
    },
    {
      label: 'delivered',
      value: overview.delivered,
    },
    {
      label: 'queued',
      value: overview.inQueue,
    },
    {
      label: 'manual delivers',
      value: overview.manualDelivers,
    },
  ];

  const startDownload = () => {
    setProgress(0);
    setIsShowing(true);
    getUserNames();
  };

  const modalContent = () => {
    return (
      <ModalContent
        confirmation={false}
        form={false}
        progressBar={true}
        progress={progress}
        title="Loading CSV Data"
        toggle={() => setIsShowing(!isShowing)}
      />
    );
  };

  return (
    <div className="panelSection__title">
      {props.panelTitle}
      {panelSectionData === null ? (
        typeof props.tabDataCache['Failures'] === 'undefined' ||
        props.tabDataCache['Failures'] === null ? (
          <ThreeSquare />
        ) : null
      ) : (
        <>
          <div style={{ position: 'absolute', top: '0px', right: '9.5em' }}>
            <PanelButton
              type="refresh"
              label="Refresh"
              onClick={event__refreshData}
            />
          </div>
          <div
            style={{
              position: 'absolute',
              top: '0px',
              right: '1.2em',
            }}
          >
            <PanelButton
              type="csv"
              csvData={csvData || []}
              csvFileName={`${
                props.editInfo && props.editInfo.title
                  ? props.editInfo.title
                  : 'chatbot'
              }-campaignOverview-${moment().format()}.csv`}
              label="Export Data"
              onClick={startDownload}
              shouldValidateCSV={false}
            />
          </div>
          <div className="campaignAnalyticsOverview" style={{ width: '100%' }}>
            {quickDisplay.map(item => {
              return (
                <ItemNumberDisplay label={item.label} value={item.value} />
              );
            })}
          </div>

          <div
            className="panelSection__title"
            style={{ fontSize: '1em', marginTop: '1em', marginBottom: '1em' }}
          >
            Responses
          </div>
          <div className="campaignAnalyticsResponses">
            {typeof panelSectionData.responseAccordions !== 'undefined'
              ? panelSectionData.responseAccordions.map(item => {
                  let summaryContent = (
                    <>
                      <H6 additionalClasses="summaryTitle">
                        Question:{' '}
                        <p className={'summaryContent--question'}>
                          {item.summaryQuestion}
                        </p>
                      </H6>
                      <H6 additionalClasses="summaryTitle">
                        Type:{' '}
                        <p className="summaryContent--type">
                          {item.summaryType}
                        </p>
                      </H6>
                    </>
                  );
                  return (
                    <Accordion
                      summaryContent={
                        <div className="AccordionSummary">{summaryContent}</div>
                      }
                      expandedSummaryContent={
                        <div className="AccordionSummary--expanded">
                          {summaryContent}
                        </div>
                      }
                      detailsContent={
                        <AccordionDetail
                          responses={item.responsesWithNumbers}
                          type={item.type}
                          value={item.promptInput}
                          total={item.total}
                        />
                      }
                    />
                  );
                })
              : null}
          </div>
        </>
      )}
      <Modal
        isShowing={isShowing}
        hide={() => setIsShowing(!isShowing)}
        content={modalContent()}
      />
      {download && (
        <CSVDownload
          data={csvData}
          headers={csvHeaders}
          filename={`${
            props.editInfo && props.editInfo.title
              ? props.editInfo.title
              : 'chatbot'
          }-campaignOverview-${moment().format()}.csv`}
        />
      )}
    </div>
  );
}
