import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { scroller as scroll, Events } from "react-scroll";
import { MiniTable, Modal, PanelButton, Accordion, Button } from "stories";
import { useModal } from "hooks";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import CircularProgress from "@material-ui/core/CircularProgress";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckIcon from "@material-ui/icons/Check";
import { store as GlobalStore } from "contexts/GlobalStore";
import Tooltip from "@material-ui/core/Tooltip";

import "../../Dialogues.scss";
import * as tagHelper from "../../Helpers/tagHelper.js";
import "./LogTables.scss";

import {
  queryWithAthena,
  escHistoryByConvoId,
  compCampByUserId,
} from "graphql/queries";

import { API, graphqlOperation } from "aws-amplify";

export default function TableHelper(props) {
  const GlobalState = useContext(GlobalStore);
  const dispatchGlobal = GlobalState.dispatch;

  let {
    csvKeys,
    rawData,
    convo,
    title,
    tableKeys,
    nonTableData,
    onEditHandler,
    onViewHandler,
    extraButton,
    loading,
  } = props;

  const [renderCsvData, setCsvData] = useState([]);
  const [forceTableUpdate, setForceTableUpdate] = useState(0);

  useEffect(() => {
    buildCsvData();
  }, [props.convo, rawData]);

  const buildCsvData = () => {
    if (csvKeys && rawData) {
      let humanKeys = [];
      let keys = Object.keys(csvKeys);
      for (var i = 0; i < keys.length; ++i) {
        humanKeys.push(keys[i]);
      }
      convertData(rawData, humanKeys, keys);
      setForceTableUpdate((prev) => prev + 1);
    }
  };

  const convertData = (data, humanKeys, keys) => {
    let csvData = [humanKeys];

    if (typeof data !== "undefined" && data !== null && data.length > 0) {
      data.forEach((item) => {
        let arr = [];
        for (var key in keys) {
          let dotKeys = keys[key].split(".");
          let curKey = JSON.parse(JSON.stringify(item));
          for (var i = 0; i < dotKeys.length; ++i) {
            curKey =
              curKey[dotKeys[i]] !== null && curKey[dotKeys[i]] !== undefined
                ? curKey[dotKeys[i]]
                : "";
          }
          let val = curKey + "";
          if (keys[key] === "timestamp") {
            val = props.dateFormatter(parseInt(curKey), true);
          }

          arr.push(val);
        }
        csvData.push(arr);
      });
    }

    setCsvData(csvData);
  };

  let columnTitles = [];
  let dataKeys = [];

  if (tableKeys) {
    for (var key in tableKeys) {
      columnTitles.push(tableKeys[key]);
      dataKeys.push(key);
    }
  }

  return (
    <>
      <div className="headerDivStyle">
        <h2 style={{ margin: "0" }}>{title}</h2>

        <div style={{ display: "flex" }}>
          {extraButton}
          <PanelButton
            type="csv"
            label="Export"
            csvData={renderCsvData}
            csvFileName={`${
              convo.type !== "sms" ? convo.asurite : convo.phoneNumber
            }-${title.replace(/ /g, "-")}-${props.dateFormatter(
              moment(),
              true
            )}.csv`}
          />
        </div>
      </div>

      <MiniTable
        tableData={rawData}
        rawData={rawData}
        forceTableUpdate={forceTableUpdate}
        nonTableData={nonTableData}
        columnTitles={columnTitles}
        dataKeys={dataKeys}
        onEditHandler={onEditHandler}
        onViewHandler={onViewHandler}
        loading={loading}
        name={title}
      />
    </>
  );
}
