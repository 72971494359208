import React, { useState, useEffect, useContext } from 'react';

import AuthContext from 'contexts/AuthContext';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

export default function ConvoActionMenu(props) {
  const { anchorEl, handleMenuClose, openSubMenu } = props;
  const tokens = useContext(AuthContext);

  useEffect(() => {
    console.log('anchor change:', anchorEl);
  }, [anchorEl]);

  if (props.selectedConvo) {
    console.log(
      props.selectedConvo.tags.filter(tag => tag.includes('agentAssigned'))
    );
  }

  return (
    <Menu
      keepMounted
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={props.addRule}>Add Rule</MenuItem>
      <MenuItem style={{ width: '12em' }} onClick={e => openSubMenu(e, 'user')}>
        <div className="subMenuParent">
          <div className="subMenuParentTitle">Assign to user</div>
          <ChevronRightIcon style={{ width: '0.7em' }} />
        </div>
      </MenuItem>
      <MenuItem onClick={e => openSubMenu(e, 'group')}>
        <div className="subMenuParent">
          <div className="subMenuParentTitle">Assign to group</div>
          <ChevronRightIcon style={{ width: '0.7em' }} />
        </div>
      </MenuItem>
      <MenuItem onClick={e => openSubMenu(e, 'folder')}>
        <div className="subMenuParent">
          <div className="subMenuParentTitle">Add to folder</div>
          <ChevronRightIcon style={{ width: '0.7em' }} />
        </div>
      </MenuItem>
      <MenuItem
        onClick={() => {
          props.toggleUnassignModal();
          props.handleMenuClose();
        }}
      >
        <div className="subMenuParent">
          <div className="subMenuParentTitle">Unassign</div>
        </div>
      </MenuItem>
    </Menu>
  );
}
