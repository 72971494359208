import React from 'react';
import { Link } from 'react-router-dom';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useTheme } from '@material-ui/core/styles';

import { Button } from '..';

export default function CreateNewButton(props) {
  const theme = useTheme();

  let icon = props.icon ? (
    props.icon
  ) : (
    <AddCircleOutlineIcon style={{ color: theme.palette.general.maroon }} />
  );

  const buttonStyleObject = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  };
  if (props.isCapitalized) {
    buttonStyleObject.textTransform = 'capitalize';
  }
  if (props.isNotBold) {
    buttonStyleObject.fontWeight = '400';
  }
  if (props.labelFontSize) {
    buttonStyleObject.fontSize = props.labelFontSize;
  }

  const btnContent = (
    <Button style={buttonStyleObject}>
      {icon}
      <span
        style={{
          marginLeft: '0.5rem',
          color: 'black',
          whiteSpace: props.noWrap ? 'pre' : 'normal',
        }}
      >
        {props.label}
      </span>
    </Button>
  );

  return (
    <Link
      to={props.to}
      style={{
        textDecoration: 'none',
        color: 'black',
      }}
    >
      {btnContent}
    </Link>
  );
}
