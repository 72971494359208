import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from 'graphql/mutations';

import AuthContext from 'contexts/AuthContext';
import { store as KBStore } from 'contexts/KBStore';
import { store as GlobalStore } from 'contexts/GlobalStore';
import { useModal } from 'hooks';
import { Button, Modal, Form, Input } from 'stories';
import { roleCheck } from 'services/utility/RoleMatrix';

export default function Categories(props) {
  const theme = useTheme();
  let history = useHistory();
  const { isShowing, toggle } = useModal();
  const tokens = useContext(AuthContext);
  const KBState = useContext(KBStore);
  const { spliceLocalData } = KBState.state;
  const GlobalState = useContext(GlobalStore);
  const dispatchGlobal = GlobalState.dispatch;

  const [categories, setCategories] = useState([]);
  const [categoryCount, setCategoryCount] = useState([]);
  const [subcategoryCount, setSubcategoryCount] = useState([]);
  const [error, setError] = useState(false);

  const {
    FAQData,
    setFAQData,
    setActiveCategory,
    activeCategory,
    setRerenderKey,
  } = props;

  useEffect(() => {
    getCategories();
    getSubcategories();
  }, [FAQData, props.categories]);

  useEffect(() => {
    if (spliceLocalData) {
      const updatedCategories = [];
      props.categories.forEach(el => {
        if (spliceLocalData.category.title !== el.title) {
          updatedCategories.push(el);
        }
      });
      props.setCategories(updatedCategories);

      const updatedFAQs = [];
      FAQData.forEach(el => {
        let pushFAQ = true;
        spliceLocalData.FAQs.forEach(FAQ => {
          if (el.question === FAQ.question) {
            pushFAQ = false;
          }
        });
        pushFAQ && updatedFAQs.push(el);
      });
      setFAQData(updatedFAQs);
    }
  }, [spliceLocalData]);

  const getCategories = () => {
    let categoryCount = {};
    let allCategories = [];
    let activeCategories = [];

    FAQData.forEach(el => {
      activeCategories.push(el.categoryName);
    });
    props.categories.forEach(el => {
      allCategories.push(el.title);
    });
    activeCategories.forEach(el => {
      categoryCount[el] = categoryCount[el] ? categoryCount[el] + 1 : 1;
    });

    setCategoryCount(categoryCount);
    const categories = [...new Set(allCategories)].sort();
    setCategories(categories);
  };

  const getSubcategories = () => {
    let _subcategoryCount = {};
    let allSubcategories = [];

    FAQData.forEach(el => {
      let c = el.categoryName;
      let s = el.subcategoryNames[0];

      if (!_subcategoryCount[c]) {
        _subcategoryCount[c] = {};
      }

      if (!_subcategoryCount[c][s]) {
        _subcategoryCount[c][s] = 0;
      }

      ++_subcategoryCount[c][s];
    });

    setSubcategoryCount(_subcategoryCount);
  };

  const onClickCategory = value => {
    props.setActiveFAQ(null);
    props.setActiveSubcategory(null);
    history.push({
      pathname: `/knowledge-base/category`,
      search: `?category=${value}`,
    });
    props.setRerenderKey(Date.now());
  };

  const onClickSubcat = el => {
    props.setActiveFAQ(null);
    history.push({
      pathname: `/knowledge-base/category`,
      search: `?category=${props.activeCategory.title}&subcategory=${el.title}`,
    });
    setRerenderKey(Date.now());
  };

  const onClickAddCat = () => {
    toggle();
  };

  const onClickHeader = () => {
    dispatchGlobal({
      type: 'updateEditInfo',
      editInfo: { empty: true },
    });
    setActiveCategory(null);
  };

  const getCategoryStyle = name => {
    if (activeCategory && activeCategory.title === name) {
      return {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '7px 20px',
        color: 'white',
        backgroundColor: theme.palette.general.darkGrey,
        borderLeft: `5px solid ${theme.palette.general.darkGreen}`,
        borderBottom: '1px solid white',
      };
    } else {
      return {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '7px 20px',
        color: theme.palette.general.mediumDarkGrey,
      };
    }
  };

  const generateUID = () => {
    let firstPart = (Math.random() * 46656) | 0;
    let secondPart = (Math.random() * 46656) | 0;
    firstPart = ('000' + firstPart.toString(36)).slice(-3);
    secondPart = ('000' + secondPart.toString(36)).slice(-3);
    for (let i = 0; i < categories.length; i++) {
      if (categories[i] === firstPart + secondPart) {
        firstPart = (Math.random() * 46656) | 0;
        secondPart = (Math.random() * 46656) | 0;
        i = 0;
      }
    }
    return firstPart + secondPart;
  };

  const truncateCategory = name => {
    if (name.length > 28) {
      return name.slice(0, 28) + '...';
    } else {
      return name;
    }
  };

  const toTitleCase = text => {
    text = text
      .toLowerCase()
      .split(' ')
      .map(s => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    return text;
  };

  const getCatsMaxHeight = () => {
    if (tokens.role === 'content creator' || tokens.role === 'approver') {
      return '50.65rem';
    } else {
      return '47rem';
    }
  };

  const addCategoryFormData = [
    {
      title: 'SECTION NAME',
      component: (
        <Input
          form
          required
          name="categoryName"
          cannotEqualList={categories.map(el => el.toLowerCase())}
          errorMessage="This section already exists"
          setError={error}
        />
      ),
    },
  ];

  const modalContent = () => (
    <div style={{ width: '75%' }}>
      <Form
        title="ADD SECTION"
        data={addCategoryFormData}
        onSubmit={onSubmit}
      />
    </div>
  );

  const onSubmit = async (data, e) => {
    if (data.type !== 'submit') {
      const catsLowerCase = categories.map(el => el.toLowerCase());
      if (catsLowerCase.includes(data.categoryName.toLowerCase())) {
        setError(false);
        setError(true);
        return console.log('This section already exists');
      } else {
        setError(false);
      }

      const newCategoryData = {
        id: generateUID(),
        group: 'Generic',
        title: toTitleCase(data.categoryName),
        subcategories: [],
        deleted: false,
      };
      let createCategory;
      try {
        createCategory = await API.graphql(
          graphqlOperation(mutations.createKnowledgeBaseCategory, {
            input: newCategoryData,
          })
        );
      } catch (error) {
        console.log('createKnowledgeBaseCategory error: ', error);
      } finally {
        if (createCategory && createCategory.data) {
          setRerenderKey(Date.now());
          toggle();
        } else {
          console.log('Something went wrong with you request');
          alert('Something went wrong with you request');
        }
      }
    }
  };

  const getSubcatStyles = el => {
    if (props.activeSubcategory && props.activeSubcategory === el.title) {
      return {
        display: 'flex',
        flex: 1,
        padding: '0.5rem',
        backgroundColor: `${theme.palette.general.darkGrey}`,
        color: 'white',
      };
    } else {
      return {
        display: 'flex',
        flex: 1,
        padding: '0.5rem',
      };
    }
  };

  const Category = properties => {
    return (
      <div>
        <div
          to="/knowledge-base/category"
          onClick={() => onClickCategory(properties.name)}
          key={properties.index}
          className="hoverCategory"
          style={getCategoryStyle(properties.name)}
        >
          <p style={{ paddingRight: '1rem' }}>
            {truncateCategory(properties.name)}
          </p>
          <p>
            {categoryCount[properties.name]
              ? categoryCount[properties.name]
              : '0'}
          </p>
        </div>
        {props.activeCategory &&
          props.activeCategory.title === properties.name && (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ flex: 0.15 }} />
              <div
                style={{
                  borderLeft: `1px solid ${theme.palette.general.mediumGrey}`,
                  display: 'flex',
                  flex: 1,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  {props.activeCategory.categoryInfo.subcategories.map(el => {
                    return (
                      <div
                        className="clickable"
                        style={getSubcatStyles(el)}
                        onClick={() => onClickSubcat(el)}
                      >
                        {el.title} (
                        {subcategoryCount[props.activeCategory.title] &&
                        subcategoryCount[props.activeCategory.title][el.title]
                          ? subcategoryCount[props.activeCategory.title][
                              el.title
                            ]
                          : '0'}
                        )
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
      </div>
    );
  };

  return (
    <div
      key={props.rerenderKey}
      style={{ width: '15em', height: '100%', minWidth: '180px' }}
    >
      <Link
        to="/knowledge-base"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '3.5rem',
          padding: '0px 20px',
          borderBottom: `1px solid ${theme.palette.general.mediumGrey}`,
          cursor: 'pointer',
        }}
        onClick={onClickHeader}
      >
        <p style={{ fontSize: '14', fontWeight: 'bold' }}>SECTION</p>
        <p style={{ fontSize: '14', fontWeight: 'bold' }}>
          {FAQData ? FAQData.length : 0}
        </p>
      </Link>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {roleCheck(tokens.role, 'knowledgeBase', 'createAddDeleteSection') && (
          <div>
            <Button
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '100%',
                padding: '1rem',
                borderRadius: '0px',
              }}
              onClick={onClickAddCat}
            >
              <AddCircleOutlineIcon
                style={{ color: theme.palette.general.maroon }}
              />
              <span style={{ marginLeft: '0.5rem' }}>Add Section</span>
            </Button>
            <Modal
              isShowing={isShowing}
              hide={toggle}
              content={modalContent()}
            />
          </div>
        )}
        <div
          style={{
            maxHeight: getCatsMaxHeight(),
            overflowY: 'scroll',
            borderTop: roleCheck(
              tokens.role,
              'knowledgeBase',
              'createAddDeleteSection'
            )
              ? `1px solid ${theme.palette.general.mediumGrey}`
              : null,
          }}
        >
          {categories.map((el, index) => {
            return <Category name={el} index={index} key={index} />;
          })}
        </div>
      </div>
    </div>
  );
}

// trick to hide scrollbar
//#parent{
//   width: 100%;
//   height: 100%;
//   overflow: hidden;
// }

// #child{
//   width: 100%;
//   height: 100%;
//   overflow-y: scroll;
//   padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
//   box-sizing: content-box; /* So the width will be 100% + 17px */
// }
