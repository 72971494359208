import React, { useState, useEffect, useContext } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import AuthContext from 'contexts/AuthContext';

import { API, graphqlOperation } from 'aws-amplify';
import { listChatbotGroups } from 'graphql/queries';

const useUserGroups = () => {
  const tokens = useContext(AuthContext);

  const [groups, setGroups] = useState([]);
  const [selectListGroups, setSelectListGroups] = useState([]);
  const [keyedNames, setKeyedNames] = useState({});

  useEffect(() => {
    getGroups();
  }, []);

  const getAllGroups = async () => {
    let storedGroups = sessionStorage.getItem('allGroups-' + tokens.env);
    let resp = [];

    let groupIds = [];

    if (!storedGroups) {
    } else if (Array.isArray(JSON.parse(storedGroups))) {
      groupIds = JSON.parse(storedGroups).map(group => {
        return group.id;
      });
    }

    if (!storedGroups || groupIds.includes('Benji_1593621826')) {
      let rawresp = await API.graphql(
        graphqlOperation(listChatbotGroups, {
          filter: {
            id: { ['ne']: 'Benji_1593621826' },
          },
        })
      );
      resp = rawresp.data.listChatbotGroups.items;
      sessionStorage.setItem('allGroups-' + tokens.env, JSON.stringify(resp));
    } else {
      resp = JSON.parse(storedGroups);
    }

    return resp;
  };

  const getGroups = async () => {
    let myGroupsRaw = {
      all: [],
    };
    let myGroupsDrop = {
      all: [],
    };
    let keyedGroupNames = {};

    let resp = await getAllGroups();

    for (var i = 0; i < resp.length; ++i) {
      let key = 'all';

      let canPushGroup = false;

      if (tokens.role === 'content creator' || tokens.role === 'approver') {
        if (
          tokens.groups.indexOf(resp[i].name) > -1 ||
          tokens.groups.indexOf(resp[i].id) > -1
        ) {
          canPushGroup = true;
        }
      } else if (tokens.role === 'admin' || tokens.role === 'publisher') {
        canPushGroup = true;
      }

      if (canPushGroup) {
        myGroupsRaw[key].push(resp[i]);
        myGroupsDrop[key].push({
          label: resp[i].name,
          value: resp[i].id,
        });
      }

      keyedGroupNames[resp[i].id] = resp[i].name;
    }

    let g = myGroupsRaw['all'];
    let sg = myGroupsDrop['all'];

    sortAlphabetically(g, 'name');
    sortAlphabetically(sg, 'label');

    setGroups(g);
    setSelectListGroups(sg);
    setKeyedNames(keyedGroupNames);
  };

  const sortAlphabetically = (arr, key) => {
    arr = arr.sort(function(a, b) {
      if (a[key] < b[key]) {
        return -1;
      }
      if (a[key] > b[key]) {
        return 1;
      }
      return 0;
    });
  };

  const getGroup = g => {
    for (var i = 0; i < selectListGroups.length; ++i) {
      if (selectListGroups[i].value === g || selectListGroups[i].label === g) {
        return selectListGroups[i];
      }
    }
    return g;
  };

  return {
    groups,
    selectListGroups,
    keyedNames,
    getGroup,
  };
};

export default useUserGroups;
