import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import "./ButtonSelect.scss";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  root: {
    border: "1px solid gray",
    fontSize: "12px",
    padding: "5px 15px",
    textTransform: "none !important",
    borderRadius: "15px",
  },
  button: {
    "&:hover": {
      backgroundColor: "#ffc626",
    },
  },
}));

export default function ButtonSelect(props) {
  const classes = useStyles();
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [maxSelected, setMaxSelected] = useState(1);

  useEffect(() => {
    setOptions(props.options);
  }, [props.options]);

  const onClick = (item) => {
    console.log("OPTION", item);
    if (props.acceptClick) {
      props.acceptClick(item);
    }
  };

  console.log("OPTIONS: ", options);

  return (
    <div className="buttonHolder">
      {options.map((btn) => {
        return (
          <div className="buttonItem">
            <Button
              onClick={(e) => onClick(btn)}
              size="small"
              className={classes.button}
              classes={{
                margin: classes.margin,
                root: classes.root,
              }}
            >
              {btn.label ? btn.label : btn}
            </Button>
          </div>
        );
      })}
    </div>
  );
}
