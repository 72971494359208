import React, { useState, useEffect, useRef } from "react";
import ImportSummary from "./ImportSummary";
import ImportSummaryErrors from "./ImportSummaryErrors";
import ImportSummaryCustomVars from "./ImportSummaryCustomVars";
import { API, graphqlOperation, Storage } from "aws-amplify";
import * as mutations from "graphql/mutations";
import * as queries from "graphql/queries";

import { CSVLink, CSVDownload } from "react-csv";
import { readString } from "react-papaparse";

import moment from "moment";

Storage.configure({ level: "public" });

export default function ImportSummaryX(props) {
  const [rawFileData, setRawFileData] = useState([]);
  const [importFails, setImportFails] = useState([]);
  const [newNumsData, setNewNumsData] = useState([]);
  const [summaryData, setSummaryData] = useState({ awaitingImport: true });

  useEffect(() => {
    if (props.fileName) {
      parseOutFails(
        !props.showProcessingMessage ? props.importSummaryData.errors : []
      );
      parseNewNums(
        !props.showProcessingMessage ? props.importSummaryData.newNums : []
      );
      setSummaryData(
        props.showProcessingMessage
          ? { awaitingImport: true }
          : props.importSummaryData
      );
    } else {
      setRawFileData([]);
      setImportFails([]);
      setSummaryData({ awaitingImport: true });
    }

    if (props.showProcessingMessage) {
      setRawFileData([]);
      setImportFails([]);
      setSummaryData({ awaitingImport: true });
    }
  }, [props.fileName, props.importSummaryData]);

  const parseNewNums = async (newNums) => {
    try {
      let arr = [];

      for (var i = 0; i < newNums.length; ++i) {
        arr.push({
          phone: getPhoneNumber(newNums[i].user),
          date: moment(parseInt(newNums[i].timestamp) / 1000, "X").format(
            "MM/DD/YYYY"
          ),
          messageId: newNums[i].messageId ? newNums[i].messageId : false,
          status: "Pending",
          displayName: newNums[i].displayName,
        });
      }

      setNewNumsData(arr);
    } catch (e) {
      console.error(e);
    }
  };

  const parseOutFails = (erroredData) => {
    try {
      let arr = [];

      for (var i = 0; i < erroredData.length; ++i) {
        arr.push({
          phone: getPhoneNumber(erroredData[i].user),
          date: moment(parseInt(erroredData[i].timestamp) / 1000, "X").format(
            "MM/DD/YYYY"
          ),
          errorCode: "Invalid Number",
        });
      }

      setImportFails(arr);
    } catch (e) {
      console.error(e);
    }
  };

  const getPhoneNumber = (num) => {
    let phone_number = num.replace("+1", "");
    phone_number = `${phone_number.slice(0, 3)}-${phone_number.slice(
      3,
      6
    )}-${phone_number.slice(6)}`;
    return phone_number;
  };

  if (props.type === "error") {
    return (
      <ImportSummaryErrors
        {...props}
        importFails={importFails}
        summaryData={summaryData}
      />
    );
  } else if (props.type === "vars") {
    return <ImportSummaryCustomVars {...props} />;
  } else {
    return (
      <ImportSummary
        {...props}
        fileName={props.fileName}
        newNumsData={newNumsData}
        summaryData={summaryData}
        reloadCsv={props.reloadCsv}
        canPublish={props.canPublish}
        showBlueBanner={props.showBlueBanner}
      />
    );
  }
}
