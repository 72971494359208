import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { Redirect } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import { useTheme } from '@material-ui/core/styles';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { API, graphqlOperation } from 'aws-amplify';

import * as mutations from 'graphql/mutations';
import { userByAsurite } from 'graphql/queries';
import { SectionHeader } from 'components/SectionHeader';
import AuthContext from 'contexts/AuthContext';
import { store } from 'contexts/GlobalStore';
import { Form, Select, Input } from 'stories';
import { getUserGroups } from 'hooks';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function UserAdd(props) {
  const tokens = useContext(AuthContext);
  const theme = useTheme();
  const GlobalStore = useContext(store);
  const { keyedNames, selectListGroups, getGroup } = getUserGroups();
  const { dispatch } = GlobalStore;
  const { editInfo, formTitle } = GlobalStore.state;
  const { asurite, role, groups } = editInfo;

  const [userGroups, setUserGroups] = useState([]);
  const [displayName, setDisplayName] = useState({});
  const [openError, setOpenError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  useEffect(() => {}, []);

  useEffect(() => {
    getGroups();
  }, []);

  const getGroups = () => {
    const initialGroups =
      tokens.role === 'publisher' && !groups
        ? getAllGroups(tokens.groups)
        : getAllGroups(groups)
        ? getAllGroups(groups)
        : null;

    setUserGroups(initialGroups);
  };

  const onChangeGroups = changedGroups => {
    const parsedGroups = [];
    if (changedGroups !== null) {
      changedGroups.forEach(el => {
        parsedGroups.push(el.value);
      });
      setUserGroups(parsedGroups);
    }
  };

  const onSubmit = async (data, e) => {
    if (data.type !== 'submit') {
      console.log(data);
      dispatch({
        type: 'setLoadingFormSubmission',
        loadingFormSubmission: true,
      });

      try {
        await axios
          .get(
            process.env.REACT_APP_APIGATEWAY_CHATBOT +
           '/isearch?asurite=' +
              data.asurite.toLowerCase()
          )
          .then(async function respond(resp) {
            if (resp.data && resp.data.displayName) {
              const result = await API.graphql(
                graphqlOperation(userByAsurite, {
                  limit: 500,
                  filter: { isActive: { eq: true } },
                  asurite: data.asurite,
                })
              );
              let userDetail = result.data.userByAsurite.items[0];

              if (userDetail && userDetail.isActive && editInfo.empty) {
                setErrorMessage('User already exists in the system');
                return setOpenError(true);
              }

              const groupsArr = JSON.parse(data.groups);
              const parsedGroups = [];
              groupsArr.forEach(el => {
                parsedGroups.push(el.value);
              });
              data.groups = parsedGroups;

              let postData = {};
              postData.asurite = data.asurite.toLowerCase();
              postData.displayName = resp.data.displayName;
              postData.role = JSON.parse(data.role).value.toLowerCase();
              postData.groups = data.groups;
              postData.createdAt = Date.now();
              postData.createdBy = tokens.asurite;
              postData.isActive = true;

              let userOperation;

              if (!editInfo.empty) {
                postData.id = editInfo.id;
                userOperation = await API.graphql(
                  graphqlOperation(mutations.updateUser, {
                    input: postData,
                  })
                );
              } else {
                userOperation = await API.graphql(
                  graphqlOperation(mutations.createUser, {
                    input: postData,
                  })
                );
              }

              if (userOperation && !userOperation.errors) {
                props.history.push({
                  pathname: '/users-groups',
                });
                dispatch({ type: 'updateEditInfo', editInfo: { empty: true } });
              } else {
                console.log('something went wrong with userOperation');
              }
            }
          })
          .catch(function(error) {
            setErrorMessage('User details not found. Please check the asurite');
            setOpenError(true);
            console.log('add user error: ', error);
          });
      } catch (error) {
        setErrorMessage('Something went wrong with user creation.');
        setOpenError(true);
        console.log('create user error: ', error);
      } finally {
        dispatch({
          type: 'setLoadingFormSubmission',
          loadingFormSubmission: false,
        });
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenError(false);
    // setSuccess(false);
  };

  const getAllGroups = g => {
    let newArr = [];

    if (g) {
      for (var i = 0; i < g.length; ++i) {
        console.log(g[i], getGroup(g[i]));
        newArr.push(getGroup(g[i]));
      }
    }

    return newArr;
  };

  const formData = [
    {
      title: 'ASURITE',
      required: true,
      component: (
        <Input
          form
          stateValue={asurite ? asurite : null}
          name="asurite"
          required
        />
      ),
    },
    {
      title: 'ROLE',
      required: true,
      component: (
        <Select
          form
          stateValue={role ? role : null}
          name="role"
          required
          options={[
            { label: 'Admin', value: 'Admin' },
            { label: 'Publisher', value: 'Publisher' },
            { label: 'Approver', value: 'Approver' },
            { label: 'Content Creator', value: 'Content Creator' },
            { label: 'Chat Agent', value: 'Chat Agent' },
          ]}
        />
      ),
    },
    {
      title: 'GROUPS',
      required: true,
      component: (
        <Select
          form
          // stateValue={groups ? groups : null}
          updateStateValue={
            tokens.role === 'publisher' && !userGroups
              ? getAllGroups(tokens.groups)
              : getAllGroups(userGroups)
              ? getAllGroups(userGroups)
              : null
          }
          name="groups"
          required
          onChange={onChangeGroups}
          options={selectListGroups}
          isMulti
        />
      ),
    },
  ];

  return (
    <div style={theme.components.pageContainer}>
      <SectionHeader noSort noFilter noSearch title="User Form" />

      <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Paper style={theme.components.paper}>
        <div style={{ padding: '2rem', width: '50%' }}>
          <Form
            title={formTitle}
            data={formData}
            onSubmit={onSubmit}
            style={{ width: '50%' }}
          />
        </div>
      </Paper>
    </div>
  );
}
