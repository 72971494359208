import React, { useState, useEffect } from "react";
import { Modal, ModalContent } from "stories";

export default function DeleteWarning(props) {
  const [showModal, setShowModal] = useState(false);
  const [willDelete, setWillDelete] = useState(0);

  useEffect(() => {
    setShowModal(props.show);
    setWillDelete(props.children);
  },[props.show,props.children])

  const toggle = () => {
    setShowModal(!showModal);
    props.resetMarkForDelete();
  };

  const title = `Deleting this node will delete ${willDelete} other node${(willDelete === 1 ? "" : "s")}. Are you sure you want to continue?`;

  return (
    <Modal
      isShowing={showModal}
      hide={toggle}
      content={
        <ModalContent
          confirmation
          title={title}
          onSubmit={() => props.confirmDelete()}
          toggle={toggle}
        />
      }
    />
  );
}
