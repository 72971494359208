import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';

import _ from 'lodash';
import axios from 'axios';

// import { idGenerator } from "./FlowDiagram/utility";

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useTheme } from '@material-ui/core/styles';

import { roleCheck } from 'services/utility/RoleMatrix';
import AuthContext from 'contexts/AuthContext';
import { store as GlobalStore } from 'contexts/GlobalStore';
import moment from 'moment';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from 'graphql/mutations';
import * as queries from 'graphql/queries';
import { onUpdateFlow } from 'graphql/subscriptions';
import { getUserGroups } from 'hooks';

import PaginationPanel from 'stories/Table/PaginationPanel';
import { SectionHeader } from 'components/SectionHeader';
import './Flows.css';
import {
  Button,
  Modal,
  Input,
  Textarea,
  Select,
  Table,
  CreateNewButton,
  ModalContent,
} from 'stories';

const pageLimit = 7;
let subscription = null;

let heldRawData = [];
var latestFound = null;
const VERSION_ID_STARTER = '1-1';

export default function Flows(props) {
  const theme = useTheme();
  const tokens = useContext(AuthContext);
  const GlobalState = useContext(GlobalStore);
  const dispatchGlobal = GlobalState.dispatch;
  const {
    tableSort,
    spliceTableData,
    editInfo,
    formTitle,
    tableDataEmpty,
    globalTableState,
  } = GlobalState.state;

  const [data, setData] = useState([]);
  const [cats, setCats] = useState([]);
  const [rerenderKey, setRerenderKey] = useState(Date.now());
  const [refetchKey, setRefetchKey] = useState(Date.now());
  const [rawData, setRawData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [flowToPause, setFlowToPause] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [showFlowTitleError, setShowFlowTitleError] = useState(false);
  const [prevPageClicked, setPrevPageClicked] = useState(false);
  const [filter, setFilter] = useState('All');
  const [group, setGroup] = useState('All');
  const [searchActive, setSearchActive] = useState(false);
  const [totalResults, setTotalResults] = useState(null);
  const [title, setTitle] = useState(null);

  const [userGroups, setUserGroups] = useState(null);

  const [viewFlow, setViewFlow] = useState(null);

  const { keyedNames, selectListGroups, getGroup } = getUserGroups();

  const [loadedTableState, setLoadedTableState] = useState(false);
  const [loadedTableSearchState, setLoadedTableSearchState] = useState(false);

  useEffect(() => {
    dispatchGlobal({
      type: 'setTableDataEmpty',
      tableDataEmpty: false,
    });
  }, []);

  // useEffect(() => {
  //   console.log(data);
  // }, [data]);

  useEffect(() => {
    const fetchData = async () => {
      const categories = await API.graphql(
        graphqlOperation(queries.listKnowledgeBaseCategorys, {
          filter: {
            deleted: { eq: false },
          },
        })
      );
      setCats(categories.data.listKnowledgeBaseCategorys.items);
    };
    fetchData();
  }, [refetchKey, rerenderKey]);

  useEffect(() => {
    createFetchData();
  }, [rerenderKey, searchActive, currentPage, filter, group, tableSort]);

  useEffect(() => {
    if (tableDataEmpty) {
      dispatchGlobal({
        type: 'setLoadingTableData',
        loadingTableData: false,
      });
    } else if (!tableDataEmpty && !loadedTableState) {
      const pathName = window.location.pathname.replace('/', '');
      let storedPage =
        globalTableState &&
        globalTableState[pathName] &&
        globalTableState[pathName].pageNumber;
      if (storedPage) {
        setCurrentPage(storedPage);
        setLoadedTableState(true);
      }
    }
  }, [tableDataEmpty]);

  useEffect(() => {
    const pathName = window.location.pathname.replace('/', '');
    let newGlobalTableState = Object.assign({}, globalTableState);
    if (!newGlobalTableState[pathName]) {
      newGlobalTableState[pathName] = {};
    }
    newGlobalTableState[pathName].pageNumber = currentPage;
    dispatchGlobal({
      type: 'setGlobalTableState',
      globalTableState: newGlobalTableState,
    });
  }, [currentPage]);

  const fetchData = async (searchText, filter, sort) => {
    // console.log("fetchData called");
    if (tableDataEmpty) {
      dispatchGlobal({
        type: 'setLoadingTableData',
        loadingTableData: false,
      });
    } else {
      dispatchGlobal({
        type: 'setLoadingTableData',
        loadingTableData: true,
      });
    }

    try {
      let sortQuery = { field: 'timestamp', direction: 'desc' };
      if (sort) {
        sortQuery = { field: sort.field, direction: sort.direction };
      }

      let result = '';

      if (tokens.role === 'approver' || tokens.role === 'content creator') {
        result = await API.graphql(
          graphqlOperation(queries.searchFlows, {
            filter: createFilterQuery(searchText, filter, true),
            sort: sortQuery,
          })
        );
      } else {
        result = await API.graphql(
          graphqlOperation(queries.searchFlows, {
            filter: createFilterQuery(searchText, filter, false),
            sort: sortQuery,
          })
        );
      }

      if (!result.data.searchFlows.items.length) {
        return dispatchGlobal({
          type: 'setTableDataEmpty',
          tableDataEmpty: true,
        });
      } else {
        dispatchGlobal({
          type: 'setTableDataEmpty',
          tableDataEmpty: false,
        });
      }

      let loadedUpdate = true;
      let loadedDelete = false;
      let loadedNew = false;

      if (spliceTableData) {
        if (spliceTableData.type === 'deleteFlow') {
          let itemTimestamps = [];
          result.data.searchFlows.items.forEach(el => {
            itemTimestamps.push(el.id);
          });
          if (!itemTimestamps.includes(spliceTableData.id)) {
            loadedDelete = true;
            dispatchGlobal({
              type: 'setSpliceTableData',
              spliceTableData: null,
            });
          } else {
            loadedDelete = false;
            dispatchGlobal({
              type: 'setSpliceTableData',
              spliceTableData: { ...spliceTableData },
            });
            return setRerenderKey(Date.now());
          }
        } else {
          result.data.searchFlows.items.forEach((el, index) => {
            if (spliceTableData.type === 'delete') {
              loadedNew = true;
            } else if (spliceTableData.type === 'editFlow') {
              if (el.timestamp === spliceTableData.item.timestamp.toString()) {
                loadedNew = true;
                dispatchGlobal({
                  type: 'setSpliceTableData',
                  spliceTableData: null,
                });
              }
            } else if (spliceTableData.type === 'addFlow') {
              if (el.id === spliceTableData.item.id) {
                loadedNew = true;
                dispatchGlobal({
                  type: 'setSpliceTableData',
                  spliceTableData: null,
                });
              }
            } else {
              if (el.timestamp === spliceTableData.item.timestamp.toString()) {
                loadedNew = true;
              }
            }
          });
        }
      } else if (!spliceTableData) {
        loadedNew = true;
        loadedDelete = true;
      }

      if (!loadedUpdate || !loadedNew || !loadedDelete) {
        return setRerenderKey(Date.now());
      } else if (loadedUpdate && loadedNew && loadedDelete) {
        dispatchGlobal({
          type: 'setSpliceTableData',
          spliceTableData: null,
        });

        let resultItems = result.data.searchFlows.items;

        if (latestFound && latestFound.id) {
          for (var i = 0; i < resultItems.length; ++i) {
            if (resultItems[i].id === latestFound.id) {
              if (
                parseInt(latestFound.timestamp) > parseInt(resultItems[i].id)
              ) {
                resultItems[i] = latestFound;
                latestFound = null;
                break;
              }
            }
          }
        }

        const total = resultItems.length;

        if (total) {
          setTotalResults(total);
        }

        if (total == 0 && currentPage > 1) setCurrentPage(currentPage - 1);

        setRawData();
        heldRawData = resultItems;
        let parsedData = parseData(resultItems);
        setData(parsedData);

        dispatchGlobal({
          type: 'setLoadingTableData',
          loadingTableData: false,
        });
      }
    } catch (error) {
      console.log('flows fetch data error: ', error);
    } finally {
      tableDataEmpty &&
        dispatchGlobal({
          type: 'setLoadingTableData',
          loadingTableData: false,
        });
    }
  };

  const createFetchData = () => {
    //restore previous search
    const pathName = window.location.pathname.replace('/', '');
    let localSearchActive = searchActive;
    let localSearchKeyword = searchKeyword;
    if (
      !loadedTableSearchState &&
      globalTableState &&
      globalTableState[pathName] &&
      globalTableState[pathName].search !== ''
    ) {
      localSearchActive = true;
      localSearchKeyword = globalTableState[pathName].search;
      setSearchActive(true);
      setSearchKeyword(globalTableState[pathName].search);
    }
    setLoadedTableSearchState(true);

    if (localSearchActive && filter !== 'All' && tableSort) {
      fetchData(localSearchKeyword, filter, tableSort);
    } else if (localSearchActive && filter !== 'All' && !tableSort) {
      fetchData(localSearchKeyword, filter, null);
    } else if (localSearchActive && filter === 'All' && tableSort) {
      fetchData(localSearchKeyword, null, tableSort);
    } else if (localSearchKeyword === '' && filter !== 'All' && tableSort) {
      fetchData(null, filter, tableSort);
    } else if (localSearchActive) {
      fetchData(localSearchKeyword);
    } else if (filter !== 'All') {
      fetchData(null, filter);
    } else if (tableSort) {
      fetchData(null, null, tableSort);
    } else {
      fetchData();
    }
  };

  const getType = (t, i) => {
    try {
      return JSON.parse(t)[i];
    } catch (e) {
      return t;
    }
  };

  const getVersionInfo = (v, i) => {
    if (v) {
      return v[i];
    } else {
      return null;
    }
  };

  const canEdit = (editing, asurite) => {
    if (tokens.asurite === asurite) {
      if (editing) {
        return false;
      } else {
        return true;
      }
    } else {
      if (editing) {
        return false;
      } else {
        if (roleCheck(tokens.role, 'flows', 'edit')) {
          return true;
        } else {
          return false;
        }
      }
    }
  };

  const canDelete = (editing, asurite) => {
    if (tokens.asurite === asurite) {
      if (editing) {
        return false;
      } else {
        return true;
      }
    } else {
      if (editing) {
        return false;
      } else {
        if (roleCheck(tokens.role, 'flows', 'delete')) {
          return true;
        } else {
          return false;
        }
      }
    }
  };

  const canView = (editing, asurite) => {
    if (tokens.asurite === asurite) {
      if (editing) {
        return false;
      } else {
        return true;
      }
    } else {
      if (editing) {
        return false;
      } else {
        if (roleCheck(tokens.role, 'flows', 'viewTable')) {
          return true;
        } else {
          return false;
        }
      }
    }
  };

  const parseData = data => {
    return data.map(el => {
      // console.log("el: ", el);
      let parsedVNum = el.version;

      try {
        parsedVNum = el.version.split(VERSION_ID_STARTER)[1];
        parsedVNum = parseInt(parsedVNum);
      } catch (e) {}

      return {
        id: el.id,
        name: el.title, //this is the title of the flow
        description: el.description,
        status: {
          statusID: el.status,
          versionstatus: {
            submitted: getVersionInfo(el.versionInfo, 'submitted'),
            approved: getVersionInfo(el.versionInfo, 'approved'),
            published: parsedVNum > 1,
            denied: getVersionInfo(el.versionInfo, 'denied'),
          },
          editing: el.editing,
          // processing: el.processing,
          // error: el.processingError
        },
        group: el.group,
        versionNumber: parsedVNum ? parsedVNum : el.version,
        submittedBy: el.submittedBy,
        submittedOn: el.submittedOn,
        actions: {
          edit: canEdit(el.editing, el.submittedBy),
          pause: false,
          view: canView(el.editing),
          delete: canDelete(el.editing),
        },
      };
    });
  };

  const createFilterQuery = (searchText, filter, groupFilterFlag) => {
    const filterConditions = [];

    //Remove Benji
    filterConditions.push({ group: { ne: 'Benji_1593621826' } });

    if (!roleCheck(tokens.role, 'flows', 'viewAllContent')) {
      tokens.groups.forEach(el => {
        filterConditions.push({ group: { eq: el } });
      });
      filterConditions.push({ submittedBy: { eq: tokens.asurite } });
      // filterConditions.push({ statusX: { eq: getStatusKey("Published") } });
      // filterConditions.push({ statusX: { eq: getStatusKey("In Progress") } });
    }

    let filterQuery = {
      deleted: { eq: false },
    };

    filterQuery.and = [
      {
        or: [...filterConditions],
      },
    ];

    if (groupFilterFlag) {
      filterQuery.or = [];
      tokens.groups.forEach(element => {
        filterQuery.or.push({ group: { eq: element } });
      });
    } else if (group !== 'All') {
      filterQuery.or = [];
      let grp = group;
      filterQuery.or.push({
        or: [{ group: { eq: grp } }],
      });
    }

    if (searchText && filter) {
      filterQuery.title = { matchPhrasePrefix: searchText };
      filterQuery.statusKey = { matchPhrasePrefix: getStatusKey(filter) };
    } else if (filter) {
      filterQuery.statusKey = { matchPhrasePrefix: getStatusKey(filter) };
    } else if (searchText) {
      filterQuery.title = { matchPhrasePrefix: searchText };
    }

    console.log('QUERY: ', filterQuery);

    return filterQuery;
  };

  useEffect(() => {
    if (!subscription || subscription._state === 'closed') {
      subscription = API.graphql(graphqlOperation(onUpdateFlow)).subscribe(
        subData => {
          let kbItem = subData.value.data.onUpdateFlow;
          let foundId = true;

          for (var i = 0; i < heldRawData.length; ++i) {
            if (heldRawData[i].id === kbItem.id) {
              foundId = true;
              latestFound = kbItem;
              for (let key in kbItem) {
                if (key === 'editedBy') {
                  heldRawData[i].editedBy = [];
                  if (!kbItem.editedBy) kbItem.editedBy = [];
                  for (var j = 0; j < kbItem.editedBy.length; ++j) {
                    heldRawData[i].editedBy.push({
                      asurite: kbItem.editedBy[j].asurite,
                      timestamp: kbItem.editedBy[j].timestamp,
                    });
                  }
                } else {
                  heldRawData[i][key] = kbItem[key];
                }
              }
              break;
            }
          }

          if (foundId) {
            if (kbItem.deleted) {
              createFetchData();
            } else {
              let parsedData = parseData(heldRawData);
              //Remove Benji
              parsedData = parsedData.filter(
                el => el.group !== 'Benji' && el.group !== 'Benji_1593621826'
              );

              let groupCheck = (group, arr) => {
                for (let i of arr) {
                  if (i === group) {
                    return true;
                  }
                }
                return false;
              };

              if (
                tokens.role === 'approver' ||
                tokens.role === 'content creator'
              ) {
                // console.log(tokens.role)
                parsedData = parsedData.filter(el =>
                  groupCheck(el.group, tokens.groups)
                );
              }
              setData(parsedData);

              setRawData(heldRawData);
            }
          }
        }
      );

      return () => {
        if (subscription) {
          subscription.unsubscribe();
        } else {
          console.warn('Failed to clean up subscription to Campaigns');
        }
      };
    }
  }, []);

  const onSubmit = async (data, e) => {
    if (data.type !== 'submit') {
      try {
        let titleToRun = data.flowName
          .toLowerCase()
          .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '');

        let resp = await await API.graphql(
          graphqlOperation(queries.getFlowBySearchTitle, {
            searchTitle: titleToRun,
          })
        );

        let matchingFlow = resp.data.getFlowBySearchTitle.items[0];

        if (matchingFlow && (!editInfo.id || matchingFlow.id !== editInfo.id)) {
          setShowFlowTitleError(true);
        } else {
          dispatchGlobal({
            type: 'setLoadingFormSubmission',
            loadingFormSubmission: true,
          });
          const flowData = {};
          const dataArray = Object.keys(data);
          dataArray.forEach(el => {
            if (el === 'flowName') {
              flowData.title = data[el];
              flowData.titleKey = data[el] + '-' + generateUID();
            } else if (el === 'description') {
              flowData.description = data[el];
            } else if (el === 'section') {
              flowData.category = data[el];
            } else if (el === 'group') {
              flowData.group = JSON.parse(data[el]).value;
              flowData.groupKey =
                JSON.parse(data[el]).value + '-' + generateUID();
            } else if (el === 'signOffMsg') {
              flowData.signOffMsg = data[el];
            }
          });

          flowData.type = JSON.stringify({ label: 'chat', value: 'Chat' });
          flowData.timestamp = moment().valueOf();
          flowData.submittedOn = moment().valueOf();
          flowData.submittedOnKey = moment().valueOf() + '-' + generateUID();
          flowData.submittedBy = tokens.asurite;
          flowData.submittedByKey = tokens.asurite + '-' + generateUID();
          flowData.deleted = false;

          flowData.approvedBy = {
            asurite: tokens.asurite,
            timestamp: moment().valueOf(),
          };
          flowData.editedBy = {
            asurite: tokens.asurite,
            timestamp: moment().valueOf(),
          };

          let flowResult;

          if (editInfo.empty) {
            // status is a reserved keyword for dynamo api in the aws sdk, so i made it statusX:
            flowData.statusX = 13;
            flowData.statusKey = '13' + '-' + generateUID();
          }

          flowData.searchTitle = flowData.title
            .toLowerCase()
            .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '');

          if (editInfo.id) {
            flowData.submittedOn = editInfo.submittedOn;
            flowData.submittedOnKey = editInfo.submittedOnKey;
            flowData.statusX = editInfo.statusX;
            flowData.statusKey = editInfo.statusKey;

            try {
              flowResult = await API.graphql(
                graphqlOperation(mutations.updateFlow, {
                  input: { id: editInfo.id, ...flowData },
                })
              );
            } catch (error) {
              console.log('onSubmit Flow edit error: ', error);
            }
          } else {
            // let versionId = idGenerator();
            flowData.version = VERSION_ID_STARTER + '00000001';

            try {
              flowResult = await API.graphql(
                graphqlOperation(mutations.createFlow, { input: flowData })
              );

              let versionPayload = {
                id: flowResult.data.createFlow.id,
                version: VERSION_ID_STARTER + '00000001',
                submitted: false,
                approved: false,
                denied: false,
                createdAt: new Date().getTime() + '',
                flowNodes: '[]',
              };

              let versionResult = await API.graphql(
                graphqlOperation(mutations.createFlowVersion, {
                  input: versionPayload,
                })
              );
            } catch (error) {
              console.log('onSubmit Flow add error: ', error);
            } finally {
              dispatchGlobal({
                type: 'setLoadingFormSubmission',
                loadingFormSubmission: false,
              });
            }
          }

          if (flowResult.data && !flowResult.errors) {
            dispatchGlobal({
              type: 'setLoadingFormSubmission',
              loadingFormSubmission: false,
            });
            dispatchGlobal({
              type: 'updateEditInfo',
              editInfo: { empty: true },
            });
            dispatchGlobal({
              type: 'setSpliceTableData',
              spliceTableData: {
                item: flowResult.data.createFlow
                  ? flowResult.data.createFlow
                  : flowResult.data.updateFlow,
                type: 'addFlow',
              },
            });
            setShowModal(false);
            setRerenderKey(Date.now());
          }

          setTitle(flowResult.data);

          props.history.push({
            pathname: '/view-flow',
            state: {
              flowId: flowResult.data.createFlow.id,
              title:
                flowResult.data && flowResult.data.createFlow
                  ? flowResult.data.createFlow.searchTitle
                  : null,
              type: 'chat',
              viewOnly: false,
            },
          });
        }
      } catch (error) {
        console.log('onSubmit Flow error: ', error);
      } finally {
        // set loading state here
      }
    }
  };

  const onSubmitDelete = async item => {
    try {
      const updatedData = {
        id: item.id,
        deleted: true,
        deletedBy: { asurite: tokens.asurite, timestamp: moment().valueOf() },
      };

      await API.graphql(
        graphqlOperation(mutations.updateFlow, {
          input: updatedData,
        })
      );
      dispatchGlobal({
        type: 'setSpliceTableData',
        spliceTableData: { ...updatedData, type: 'deleteFlow' },
      });
    } catch (error) {
      console.log('updateFlow error: ', error);
    } finally {
      setRerenderKey(Date.now());
    }
  };

  const getStatusKey = name => {
    if (name === 'Approved') {
      return 10;
    } else if (name === 'Pending') {
      return 11;
    } else if (name === 'Published') {
      return 12;
    } else if (name === 'Denied') {
      return 13;
    } else if (name === 'In Progress') {
      return 15;
    } else {
      return null;
    }
  };

  const getSortKey = label => {
    label = label.toUpperCase();
    if (label === 'STATUS') {
      return 'statusKey';
    } else if (label === 'SUBMITTED BY') {
      return 'submittedByKey';
    } else if (label === 'SUBMITTED ON') {
      return 'submittedOnKey';
    } else if (label === 'NAME') {
      return 'titleKey';
    } else if (label === 'GROUP') {
      return 'groupKey';
    } else {
      return null;
    }
  };

  const onChangeSearch = value => {
    setSearchKeyword(value);
  };

  const onClickSearch = () => {
    if (searchKeyword === '') {
      return;
    } else {
      setSearchActive(true);
      if (currentPage === 1) {
        setRerenderKey(Date.now());
      } else {
        setCurrentPage(1);
      }
    }
  };

  const onClickReset = () => {
    setSearchActive(false);
    setRerenderKey(Date.now());
    dispatchGlobal({
      type: 'changeTableSort',
      tableSort: null,
    });

    if (group !== 'All') {
      setGroup('All');
    } else if (currentPage === 1) {
      setRerenderKey(Date.now());
    } else {
      setCurrentPage(1);
    }
  };

  const onSelectFilter = value => {
    setCurrentPage(1);
    if (value === filter || !value) {
      setFilter('All');
      dispatchGlobal({ type: 'changeTableFilter', tableFilter: 'All' });
    } else {
      setFilter(value);
      dispatchGlobal({ type: 'changeTableFilter', tableFilter: value });
    }
    dispatchGlobal({ type: 'setRefetchKey', refetchKey: Date.now() });
    dispatchGlobal({ type: 'setCurrentPage', currentPage: 1 });
  };

  const onSelectGroup = value => {
    setCurrentPage(1);
    dispatchGlobal({
      type: 'clearNextTokens',
      clearNextTokens: true,
    });
    if (value === group || !value) {
      setGroup('All');
      dispatchGlobal({ type: 'changeTableFilter', tableFilter: 'All' });
    } else {
      setGroup(value);
      dispatchGlobal({ type: 'changeTableFilter', tableFilter: value });
    }
  };

  const onClickEdit = data => {
    let flowToEdit = {};
    heldRawData.forEach(el => {
      if (data.id === el.id) {
        flowToEdit = el;
      }
    });
    dispatchGlobal({ type: 'updateEditInfo', editInfo: { ...flowToEdit } });
    setShowModal(true);
  };

  const onClickView = view => {
    // console.log("VIEW", view);
    props.history.push({
      pathname: '/view-flow',
      state: {
        flowId: view.id,
        type: 'chat',
        viewOnly: false,
        title: view.name,
      },
    });
  };

  const onConfirmPause = async () => {
    try {
      const pauseFlow = await API.graphql(
        graphqlOperation(mutations.updateFlow, {
          input: {
            id: flowToPause.id,
            paused: true,
            // status: 2,
            timestamp: moment().valueOf(),
          },
        })
      );

      if (pauseFlow.data) {
        dispatchGlobal({
          type: 'setSpliceTableData',
          spliceTableData: {
            item: pauseFlow.data.updateFlow,
            type: 'editFlow',
          },
        });
        toggleModal();
        setRerenderKey(Date.now());
        setFlowToPause(null);
      }
    } catch (error) {
      console.log('onConfirmPause error: ', error);
    }
  };

  const toggleModal = () => {
    setShowFlowTitleError(false);
    setShowModal(!showModal);
  };

  const onClickFetchMore = () => {
    setPrevPageClicked(false);
    if (currentPage === Math.ceil(totalResults / pageLimit)) {
      return;
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const onClickFetchLess = async () => {
    setPrevPageClicked(true);
    if (currentPage <= 1) {
      setCurrentPage(1);
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const onClickCreateNew = () => {
    dispatchGlobal({
      type: 'updateEditInfo',
      editInfo: { empty: true },
    });
    dispatchGlobal({
      type: 'changeTableSort',
      tableSort: null,
    });
    dispatchGlobal({ type: 'changeTableFilter', tableFilter: 'All' });
    dispatchGlobal({ type: 'setFormTitle', formTitle: 'ADD FLOW' });
    toggle();
  };

  const onChangeJumpToPage = pageNumber => {
    setCurrentPage(pageNumber);
  };

  const jumpToPageOptions = () => {
    const totalPages = Math.ceil(totalResults / pageLimit);
    const options = [];

    for (let i = 1; i <= totalPages; i++) {
      options.push(i);
    }
    const mappedOptions = options.map(el => {
      return { value: el, label: el };
    });
    return mappedOptions;
  };

  const filterOptions = [
    { value: 'Approved', label: 'Approved' },
    { value: 'Pending', label: 'Pending' },
    { value: 'Published', label: 'Published' },
    { value: 'Denied', label: 'Denied' },
    { value: 'In Progress', label: 'In Progress' },
  ];

  let groupOptions = JSON.parse(
    sessionStorage.getItem('allGroups-' + tokens.env)
  ).map(object => {
    let newObject = {};
    newObject.value = object.id;
    newObject.label = object.name;
    return newObject;
  });

  let propertyName = 'label';

  groupOptions.sort((a, b) => {
    if (a[propertyName] < b[propertyName]) {
      return -1;
    }
    if (a[propertyName] > b[propertyName]) {
      return 1;
    }
    return 0;
  });

  const tableLabels = [
    { title: 'Name', wide: true },
    { title: 'Status', notClickable: true },
    { title: 'Group' },
    { title: 'Latest Version', notClickable: true },
    { title: 'Submitted By' },
    { title: 'Submitted On' },
    { title: 'Actions', alignRight: true, notClickable: true },
  ];

  const generateUID = () => {
    let firstPart = (Math.random() * 46656) | 0;
    let secondPart = (Math.random() * 46656) | 0;
    firstPart = ('000' + firstPart.toString(36)).slice(-3);
    secondPart = ('000' + secondPart.toString(36)).slice(-3);
    return firstPart + secondPart;
  };

  const sectionItems = () => {
    if (cats.length) {
      return cats.map(el => {
        return { label: el.title, value: el.title };
      });
    }
  };

  const groupItems = () => {
    return tokens.groups.map(el => {
      return { label: el, value: el };
    });
  };

  const types = () => {
    return [
      { label: 'SMS', value: 'sms' },
      { label: 'Chat', value: 'chat' },
    ];
  };

  const toggle = () => {
    setShowModal(!showModal);
    if (!editInfo.empty) {
      dispatchGlobal({
        type: 'updateEditInfo',
        editInfo: { empty: true },
      });
    }
  };

  const getTypeValue = t => {
    try {
      return JSON.parse(t).label;
    } catch (e) {
      return t;
    }
  };

  const getStyle = () => {
    if (editInfo && editInfo.type) {
      return {
        background: 'gray',
        pointerEvents: 'none',
      };
    } else {
      return {};
    }
  };

  const formData = [
    {
      title: 'GROUP',
      tooltip: 'Select a Group',
      required: true,
      component: (
        <Select
          form
          name="group"
          options={selectListGroups}
          required
          stateValue={
            tokens.role === 'publisher' && !editInfo.group
              ? getGroup(tokens.groups[0])
              : getGroup(editInfo.group)
              ? getGroup(editInfo.group)
              : null
          }
        />
      ),
    },
    {
      title: 'SECTION',
      tooltip: 'Select a Section',
      required: true,
      component: (
        <Select
          form
          name="section"
          options={sectionItems()}
          required
          stateValue={
            editInfo.id && editInfo.category
              ? JSON.parse(editInfo.category).value
              : null
          }
        />
      ),
    },
    {
      title: 'FLOW NAME',
      tooltip: 'Write a Flow Name',
      subtext: showFlowTitleError && (
        <div
          style={{
            backgroundColor: 'red',
            color: 'white',
            width: 'fit-content',
            padding: '5px 15px',
            borderRadius: '3px',
          }}
        >
          Flow name already taken
        </div>
      ),
      required: true,
      component: (
        <Textarea
          form
          name="flowName"
          onChange={() => setShowFlowTitleError(false)}
          required
          stateValue={editInfo.id ? editInfo.title : null}
        />
      ),
    },
    {
      title: 'SHORT DESCRIPTION',
      tooltip: 'Write a Short Description',
      required: true,
      component: (
        <Textarea
          form
          name="description"
          required
          stateValue={editInfo.id ? editInfo.description : null}
        />
      ),
    },
    {
      title: 'SIGN OFF MESSAGE',
      tooltip: 'Write a Sign off Message',
      subtext:
        'Message that will be sent to the user after the flow is completed',
      required: false,
      component: (
        <Textarea
          form
          maxcharacters="255"
          name="signOffMsg"
          stateValue={editInfo.signOffMsg ? editInfo.signOffMsg : null}
        />
      ),
    },
  ];

  const rightViewContent = () => (
    <div onClick={onClickCreateNew}>
      <button className="CreateNewFlow">
        <Button
          style={{ display: 'flex', flex: 1, width: '100%' }}
          onClick={onClickCreateNew}
        >
          <AddCircleOutlineIcon
            style={{ color: theme.palette.general.maroon }}
          />
          <span
            style={{
              marginLeft: '0.5rem',
            }}
          >
            Create New Flow
          </span>
        </Button>
      </button>
    </div>
  );

  const modalContent = () => {
    if (flowToPause) {
      return (
        <ModalContent
          confirmation
          title="Confirm pause flow"
          onSubmit={onConfirmPause}
          toggle={toggleModal}
        />
      );
    } else {
      return (
        <ModalContent
          form
          title={formTitle}
          formData={formData}
          onSubmit={onSubmit}
          toggle={toggle}
        />
      );
    }
  };

  const indexOfLastRecord = currentPage * pageLimit;
  const indexOfFirstRecord = indexOfLastRecord - pageLimit;
  const currentPageRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);

  return (
    <div style={theme.components.pageContainer} key={rerenderKey}>
      <div className="SectionHeader">
        <SectionHeader
          onChangeSearch={onChangeSearch}
          onClickSearch={onClickSearch}
          rightView={rightViewContent()}
          onClickReset={onClickReset}
          onSelectGroup={onSelectGroup}
          onSelectFilter={onSelectFilter}
          filterOptions={filterOptions}
          groupOptions={groupOptions}
          noSort
          noModality
        />
      </div>
      <Modal
        isShowing={showModal}
        hide={toggleModal}
        content={modalContent()}
      />
      <div className="TablePage">
        <Paper style={theme.components.paper}>
          <Table
            title="Flows Table"
            labels={tableLabels}
            data={currentPageRecords}
            useversionstatus={true}
            onChangeSearch={onChangeSearch}
            onClickSearch={onClickSearch}
            onClickReset={onClickReset}
            onSelectFilter={onSelectFilter}
            onSubmitDelete={onSubmitDelete}
            onClickEdit={onClickEdit}
            onClickView={onClickView}
            filterOptions={filterOptions}
            getSortKey={getSortKey}
            defaultSortKey="timestampKey"
            noSort
            noSearch
          />
          <PaginationPanel
            theme={theme}
            currentPage={currentPage}
            pageCount={Math.ceil(totalResults / pageLimit)}
            onClickFetchMore={onClickFetchMore}
            onClickFetchLess={onClickFetchLess}
            jumpToPageOptions={jumpToPageOptions}
            onChangeJumpToPage={onChangeJumpToPage}
          />
        </Paper>
      </div>
    </div>
  );
}

Flows.propTypes = {
  navigation: PropTypes.object,
};
