import React from "react";
// import { useTheme } from "@material-ui/core/styles";

export default function ErrorLabel(props) {
  // const theme = useTheme();

  let color = "#e01037";

  if( props.warning ) {
    color = "#ffa500";
  }

  if( props.status ) {
    color = "#3d88df";
  }

  if( props.success ) {
    color = "#729850";
  }

  return (
    <div
      style={{
        display: "flex",
        padding: "0.5rem",
        marginTop: "0.5rem",
        borderRadius: "4px",
        color: "white",
        // backgroundColor: theme.palette.general.errorColor
        backgroundColor: color
      }}
    >
      {props.message}
    </div>
  );
}
