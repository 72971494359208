import React, { useState, useEffect } from "react";
import { useTheme } from "@material-ui/core/styles";
import { useFormContext } from "react-hook-form";
import Select from "react-select";

import { ErrorLabel } from "stories";
import "../forms.css";

export default function SelectForm(props) {
  const theme = useTheme();

  const { sidebutton, sidebuttonaction } = props;

  const { register, errors, clearError } = useFormContext();

  const [value, setValue] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if (props.stateValue) {
      if (props.stateValue instanceof Array) {
        let newValue = [];
        if (props.stateValue.length > 0) {
          for (let i = 0; i < props.stateValue.length; i++) {
            if (props.stateValue[i] !== "") {
              newValue.push({
                value: props.stateValue[i].value
                  ? props.stateValue[i].value
                  : props.stateValue[i],
                label: props.stateValue[i].label
                  ? props.stateValue[i].label
                  : props.stateValue[i],
              });
            }
          }
          if (newValue != null && newValue.length > 0) {
            setValue(newValue);
          }
        }
      } else if (props.stateValue.value) {
        setValue({
          value: props.stateValue.value,
          label: props.stateValue.label,
        });
      } else {
        setValue({ value: props.stateValue, label: props.stateValue });
      }
    }
  }, []);

  useEffect(() => {
    if (props.updateStateValue && props.updateStateValue !== "IGNORE") {
      if (props.updateStateValue instanceof Array) {
        let newValue = [];
        if (props.updateStateValue.length > 0) {
          for (let i = 0; i < props.updateStateValue.length; i++) {
            if (props.updateStateValue[i] !== "") {
              newValue.push({
                value: props.updateStateValue[i].value
                  ? props.updateStateValue[i].value
                  : props.updateStateValue[i],
                label: props.updateStateValue[i].label
                  ? props.updateStateValue[i].label
                  : props.updateStateValue[i],
              });
            }
          }
          if (newValue != null && newValue.length > 0) {
            setValue(newValue);
          }
        }
      } else if (props.updateStateValue.value) {
        setValue({
          value: props.updateStateValue.value,
          label: props.updateStateValue.label,
        });
      } else {
        setValue({
          value: props.updateStateValue,
          label: props.updateStateValue,
        });
      }
    }
  }, [props.updateStateValue]);

  useEffect(() => {
    if (props.reset && props.setReset) {
      setValue(null);
      props.setReset(false);
    }
  }, [props.reset]);

  const options = props.options
    ? props.options
    : ["add options as a prop...", "blah", "blah", "blah"];

  const customStyles = {
    // to access width/height, add either as a prop to the component
    control: (_, { selectProps: { width, height } }) => ({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "0.5rem",
      border: "1px solid #8d8d8d",
      borderRadius: controlBR(),
      background: props.disabled ? "#c3c3c3" : null,
      flex: 9,
      cursor: "pointer",
      borderTopRightRadius: sidebutton ? "0px" : null,
      borderBottomRightRadius: sidebutton ? "0px" : null,
    }),
    menu: (provided, state) => ({
      ...provided,
      width: "100%",
      margin: "0px",
      marginTop: "-10px",
      borderTopLeftRadius: props.menuPlacement === "top" ? null : "0px",
      borderTopRightRadius: props.menuPlacement === "top" ? null : "0px",
      borderBottomLeftRadius: props.menuPlacement !== "top" ? null : "0px",
      borderBottomRightRadius: props.menuPlacement !== "top" ? null : "0px",
      border: "1px solid #8d8d8d",
      borderTop: props.menuPlacement === "top" ? null : "0px",
      zIndex: 1000000,
      borderBottom: props.menuPlacement !== "top" ? null : "0px",
    }),
    option: (provided, state) => {
      return {
        ...provided,
        borderBottom:
          state.children === props.emphasizedOptionLabel
            ? "2px solid #e1e1e1"
            : null,
        paddingBottom:
          state.children === props.emphasizedOptionLabel ? "0.75rem" : null,
        paddingTop:
          state.children === props.emphasizedOptionLabel ? "0.75rem" : null,
        marginBottom:
          state.children === props.emphasizedOptionLabel ? "0.5rem" : null,
        cursor: "pointer",
        backgroundColor: optionBG(state),
        color: "#3B4252",
        "&:active": {
          backgroundColor: "#d5d5d5",
        },
      };
    },
    dropdownIndicator: () => ({
      color: "#8d8d8d",
      marginRight: "0.5rem",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    placeholder: () => ({
      color: "#3B4252",
    }),
    container: (provided, state) => ({
      ...provided,
      flex: 9,
      pointerEvents: props.disabled ? "none" : null,
    }),
  };

  const controlBR = () => {
    if (menuOpen) {
      return "4px 4px 0 0";
    } else {
      return "4px";
    }
  };

  const optionBG = (state) => {
    if (state.isSelected) return "#d5d5d5";
    else if (state.isFocused) return "#ededed";
    else return "white";
  };

  const onMenuOpen = () => {
    setMenuOpen(true);
  };

  const onMenuClose = () => {
    setMenuOpen(false);
  };

  const onChange = (e) => {
    clearError(props.name);
    setValue(e);
    if (props.onChange) {
      props.onChange(e);
    }
  };

  const getValue = () => {
    if (value) {
      return JSON.stringify(value);
    } else {
      return "";
    }
  };

  const {
    form,
    isDisabled,
    stateValue,
    noOptionsText,
    reset,
    setReset,
    ...validProps
  } = props;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Select
        {...props}
        options={options}
        onChange={onChange}
        menuPlacement={props.menuPlacement ? props.menuPlacement : "bottom"}
        value={value}
        placeholder={
          props.placeholder ? props.placeholder : "-- Select an option --"
        }
        noOptionsMessage={() =>
          props.noOptionsText ? props.noOptionsText : "No options..."
        }
        isSearchable={true}
        disabled={props.disabled ? true : false}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        className="selectContainer"
        styles={customStyles}
      />

      {sidebutton && sidebuttonaction ? (
        <div
          style={{
            width: "fit-content",
            color: "white",
            backgroundColor: theme.palette.general.darkGrey,
            borderTopRightRadius: "4px",
            borderBottomRightRadius: "4px",
            cursor: "pointer",
            padding: "7px",
            pointerEvents: "all",
          }}
          onClick={() => sidebuttonaction()}
        >
          {React.cloneElement(sidebutton, null, null)}
        </div>
      ) : null}

      {errors[props.name] && errors[props.name].type === "required" && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "fit-content",
          }}
        >
          <ErrorLabel message="This field is required" />
        </div>
      )}

      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ display: "flex", flex: 1 }} />
        <input
          {...validProps}
          type="select"
          ref={props.required ? register({ required: true }) : register}
          value={getValue()}
          readOnly
          autoComplete="off"
          style={{
            display: "flex",
            flex: 1,
            opacity: 0,
            height: "1px",
            width: "1px",
            cursor: "default",
            display: "none",
          }}
        />
        <div style={{ display: "flex", flex: 1 }} />
      </div>
    </div>
  );
}
