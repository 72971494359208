import React, { useState, useEffect, useContext } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useFormContext } from "react-hook-form";
import "../forms.css";

export default function CheckboxInput(props) {
  const { register, errors } = useFormContext();

  const [value, setValue] = useState(props.value ? props.value : false);
  const [label, setLabel] = useState(props.label ? props.label : null);

  useEffect(() => {
    if (props.value) { setValue(props.value); } else {setValue(false);}
  }, [props.value]);

  useEffect(() => {
    if (props.label) { setLabel(props.label); }
  }, []);

  const handleChangeValue = event => {
    if( props.onChange ) {
      props.onChange(event.target.checked);
    }
    setValue(event.target.checked);
  };

  return (
    <div style={{pointerEvents: props.viewOnly ? "none" : "all"}}>
    <FormControlLabel
      control={
        <Checkbox
          name={props.name ? props.name : "checkboxValue"}
          disabled={false}
          checked={props.viewOnly ? false : value}
          value={value}
          onChange={handleChangeValue}
          ref={props.required ? register({ required: true }) : register}
          color="primary"
        />
      }
      label={label}
    />
    <div
      style={{
        display: props.viewOnly ? "none" : "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        height: "1px"
      }}
    >
      <div style={{ display: props.viewOnly ? "none" : "flex", flex: 1 }} />
      <input
          name={props.name ? props.name : "checkboxValue"}
          disabled={false}
          checked={value}
          value={value}
          onChange={handleChangeValue}
          ref={props.required ? register({ required: true }) : register}
        style={{
          opacity: 0,
          height: "1px",
          width: "1px",
          cursor: "default"
        }}
      />
      <div style={{ display: "flex", flex: 1 }} />
    </div>
  </div>
  );
}
