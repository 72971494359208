import React, { useContext } from "react";

export default function IconAlert2(props) {
  return (
    <svg width="16px" height="14px" viewBox="0 0 16 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>{props.tooltip}</title>
        <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="dialogues/list/users" transform="translate(-435.000000, -59.000000)" fill="#C70242" fill-rule="nonzero">
                <path d="M450.380478,71.0316056 C450.878958,71.9064415 450.253252,73 449.257643,73 L436.297762,73 C435.300235,73 434.677419,71.9047462 435.174927,71.0316056 L441.654948,59.65584 C442.153671,58.7805939 443.402787,58.7821798 443.900619,59.65584 L450.380478,71.0316056 Z M442.777783,68.6796876 C442.091692,68.6796876 441.5355,69.2428322 441.5355,69.9375001 C441.5355,70.6321681 442.091692,71.1953126 442.777783,71.1953126 C443.463875,71.1953126 444.020067,70.6321681 444.020067,69.9375001 C444.020067,69.2428322 443.463875,68.6796876 442.777783,68.6796876 Z M441.598343,64.1585079 L441.798675,67.8772579 C441.808046,68.0512736 441.950152,68.1875001 442.122263,68.1875001 L443.433304,68.1875001 C443.605414,68.1875001 443.747521,68.0512736 443.756892,67.8772579 L443.957224,64.1585079 C443.967351,63.970547 443.819546,63.8125001 443.633636,63.8125001 L441.921904,63.8125001 C441.735993,63.8125001 441.588216,63.970547 441.598343,64.1585079 L441.598343,64.1585079 Z" id="icon-alert-2"></path>
            </g>
        </g>
    </svg>
  );
}
