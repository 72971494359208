import React, { useState, useEffect } from "react";
import { Form, Textarea, Checkbox, Input } from "stories";
import SaveNode from "./SaveNode";

export default function PromptProperties(props) {
  const { node, extras } = props;
  const [text, setText] = useState(node.text);
  const [response, setResponse] = useState(node.response);
  const [image, setImage] = useState(null);
  const [responseImage, setRespImage] = useState(null);
  const [showTag, setShowTag] = useState(false);
  const [tag, setTag] = useState(null);

  const initNode = () => {
    if (node.customType) {
      try {
        let t = node.customType.split("tag:");
        setTag(t[1]);
        setShowTag(true);
      } catch (e) {}
    }

    setText(node.text);
    setImage(node.image);

    setResponse(node.response);
    setRespImage(node.responseImage);
  };

  useEffect(() => {
    initNode();
  }, [props.node]);

  const handleChange = (e) => {
    setShowTag(e);
  };

  const acceptPreload = (x) => {
    let preFillData = props.rawPreloadedMessages[x];
    setImage(preFillData.image);
    setText(preFillData.message);
    setResponse(preFillData.response);
    setRespImage(preFillData.responseImage);
  };

  let formData = [
    {
      title: "MESSAGE BODY",
      component: (
        <Textarea
          name="text"
          form="true"
          image={image}
          charLimit="160"
          isSMS
          allowextras={props.viewOnly ? false : extras}
          preloadOptions={!props.viewOnly ? props.preloadedMessages.prompt : false}
          acceptPreload={acceptPreload}
          value={text}
          required
          readOnly={props.viewOnly ? "readonly" : false}
        />
      ),
    },
    {
      title: "RESPONSE",
      subtext: "Message to display after the users response.",
      component: (
        <Textarea
          name="response"
          form="true"
          image={responseImage}
          imageName={"responseImage"}
          charLimit="160"
          isSMS
          allowextras={props.viewOnly ? false : extras}
          value={response}
          required
          readOnly={props.viewOnly ? "readonly" : false}
        />
      ),
    },
    {
      title: "TAG RESPONSE",
      subtext: "Save response as a tag on the user.",
      component: (
        <Checkbox
          name="showTag"
          form="true"
          value={showTag}
          label="Save Response as Tag"
          onChange={handleChange}
          checkboxInput
          viewOnly={props.viewOnly}
        />
      ),
    },
  ];

  if (showTag && !props.viewOnly) {
    formData.push({
      title: "",
      component: (
        <Input
          name="responseTag"
          form="true"
          required
          placeholder="Tag Key Value"
          maxlength={20}
          value={tag}
          readOnly={props.viewOnly ? "readonly" : false}
        />
      ),
    });
  }

  let saveNode = SaveNode(props.groups, props.node, props.viewOnly);
  formData = formData.concat(saveNode);

  const onSubmit = (data, e) => {
    if (data.type !== "submit") {
      if (data.showTag === "true") {
        data.customType = "tag:" + data.responseTag;
      } else {
        data.customType = null;
      }
      delete data.showTag;
      delete data.responseTag;
      props.saveInfo(data);
    }
  };

  return (
    <Form
      data={formData}
      onSubmit={onSubmit}
      deleteWarning={props.deleteWarning}
      type="node"
      style={{ width: "100%" }}
    />
  );
}
