import React, { useState, useEffect } from "react";
import { useTheme } from "@material-ui/core/styles";
import DatePicker, { registerLocale } from "react-datepicker";

import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";

import moment from "moment-timezone";

registerLocale("es", es);

export default function StartComponent(props) {
  const theme = useTheme();
  const [date, setDate] = useState(new Date());

  const handleDateChange = newDate => {
    setDate(newDate);
    if (props.onChange) {
      props.onChange(newDate);
    }
  };


  useEffect(() => {
    if (props.stateValue !== null && typeof props.stateValue === "number") {
      const formatted = moment(props.stateValue.toString(), "x").format();
      const initialDate = new Date(formatted);
      const timestamp = moment(initialDate).valueOf();
      setDate(timestamp);
    }
  }, [props.stateValue]);

  const CustomInput = ({ value, onClick }) => {
    return (
      <button
        style={{
          ...theme.typography.body1,
          width: "100%",
          color: theme.palette.general.darkGrey,
          border: `1px solid ${theme.palette.general.deepGrey}`,
          borderRadius: "4px",
          padding: "0.5rem 1rem",
          outline: 0,
          cursor: "pointer",
          ...props.style
        }}
        onClick={() => {
          return onClick();
        }}
      >
        {value}
      </button>
    );
  };

  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          display: "flex",
          marginRight: "1rem"
        }}
      >
        {
          props.hideTimeSelect ?
          <DatePicker
            selected={date}
            onChange={handleDateChange}
            customInput={<CustomInput />}
          />
        :
          props.hideDateSelect ?
            <DatePicker
              selected={date}
              onChange={handleDateChange}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={60}
              timeCaption="Time"
              dateFormat="h:mm aa"
              customInput={<CustomInput />}
            />
          :
          <DatePicker
            selected={date}
            onChange={handleDateChange}
            showTimeSelect
            timeIntervals={15}
            dateFormat="Pp"
            customInput={<CustomInput />}
          />
        }
        <input
          name={`${props.name}Date`}
          value={date}
          style={{ display: "none" }}
        />
      </div>
    </div>
  );
}
