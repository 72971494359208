import React, { useState } from "react";
import { useTheme, makeStyles } from "@material-ui/core/styles";

export default function UploadFile(props) {
  const theme = useTheme();
  const [fileName, setFileName] = useState(props.placeholder);

  const useStyles = makeStyles(theme => ({
    required: {
      ...theme.typography.subtitle2,
      backgroundColor: theme.palette.general.lightOrange,
      textAlign: "center",
      padding: "0.5rem 1rem",
      margin: "4rem 0",
      borderRadius: "4px"
    },
    button: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "5rem",
      fontFamily: "Roboto",
      color: "white",
      backgroundColor: theme.palette.general.darkGrey,
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
      cursor: "pointer"
    }
  }));
  const classes = useStyles();

  const AddButton = props => {
    return (
      <label {...props} className={classes.button}>
        Add
      </label>
    );
  };

  const ResetButton = props => {
    return (
      <label {...props} className={classes.button} onClick={resetButtonHandler}>
        Reset
      </label>
    );
  };

  const resetButtonHandler = () => {
    document.getElementById("upload").value = null;
    setFileName(props.placeholder);
  };

  return (
    <div style={{ cursor: "default" }}>
      <div style={{ display: "flex" }}>
        <div
          className="input"
          style={{
            ...theme.typography.body1,
            width: "100%",
            borderTopRightRadius: "0px",
            borderBottomRightRadius: "0px",
            border: `1px solid ${theme.palette.general.deepGrey}`,
            color: theme.palette.general.darkGrey,
            ...props.style
          }}
        >
          {fileName}
          <input
            {...props}
            type="file"
            id="upload"
            onChange={e => {
              const index = e.target.value.lastIndexOf("\\");
              const fileName = e.target.value.slice(index + 1);
              setFileName(fileName);
              if (props.onChange) {
                props.onChange(e);
              }
            }}
            style={{
              opacity: 0,
              position: "absolute",
              pointerEvents: "none"
            }}
          />
        </div>
        {fileName === props.placeholder ? (
          <AddButton htmlFor="upload" />
        ) : (
          <ResetButton />
        )}
      </div>
    </div>
  );
}
