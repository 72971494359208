export const dateFormatter = (date, isInt) => {
  if (!date) return null;

  let lastMessageDate = isInt ? new Date(parseInt(date)) : new Date(date);
  let month = lastMessageDate.getMonth() + 1;
  let day = lastMessageDate.getDate();
  let year = lastMessageDate.getFullYear();
  let time = lastMessageDate.toLocaleTimeString([], {
    hour: "numeric",
    minute: "2-digit",
    timeZone: "America/Phoenix"
  });

  let formattedMonth = month.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
  let formattedDay = day.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});

  return `${formattedMonth}-${formattedDay}-${year}, ${time}`;
};
