import React from "react";
import UploadFile from "./UploadFile";
import UploadFileForm from "./UploadFileForm";

export default function UploadFileX(props) {
  if (props.form) {
    return <UploadFileForm {...props} />;
  } else {
    return <UploadFile {...props} />;
  }
}
