import React, { useState, useEffect, useContext } from "react";

import AuthContext from "contexts/AuthContext";
import { AlertBanner } from "stories";

export default function AgentBanner(props) {
  const {
    liveAgentTimers,
    liveAgentTimerDuration,
    selectedConvo,
    allConvosList,
    setInboxSearch,
    setOtherAgentChatting,
    modifyActiveConvos,
    searchInboxRef,
    currentButton,
    setWriteInboxSearch,
  } = props;
  /* Alert Banner */
  const [AlertBannerName, setAlertBannerName] = useState("A user");
  const [AlertBannerMessage, setAlertBannerMessage] = useState("has responded");
  const [AlertBannerUserWaiting, setAlertBannerUserWaiting] = useState(false);
  const [AlertBannerButtonMessage, setAlertBannerButtonMessage] = useState(
    "Click to join"
  );
  const [AlertBannerNameStorage, setAlertBannerNameStorage] = useState({});
  const [AlertBannerConvoId, setAlertBannerConvoId] = useState("");

  useEffect(() => {
    if (props.updateBanner) handleSetLive(props.updateBanner);
  }, [props.updateBanner]);

  const handleSetLive = (item) => {
    console.log("handling set live", item);
    if (
      typeof item.lastMessageContent !== "undefined" &&
      typeof item.convoId !== "undefined"
    ) {
      if (item.lastMessageContent.includes("has entered the conversation")) {
        let newAlertBannerNameStorage = Object.assign(
          {},
          AlertBannerNameStorage
        );
        newAlertBannerNameStorage[item.convoId] =
          item.type === "sms"
            ? item.phoneNumber
            : item.displayName
            ? item.displayName
            : item.asurite;
        setAlertBannerNameStorage(newAlertBannerNameStorage);
      } else if (
        item.lastMessageContent.includes("has left the conversation")
      ) {
        let newAlertBannerNameStorage = Object.assign(
          {},
          AlertBannerNameStorage
        );
        newAlertBannerNameStorage[item.convoId] = null;
        setAlertBannerNameStorage(newAlertBannerNameStorage);
      } else if (
        typeof AlertBannerNameStorage[item.convoId] !== "undefined" &&
        AlertBannerNameStorage[item.convoId] !== null
      ) {
        let indexAt = -1;

        liveAgentTimers.some((timer, index) => {
          if (timer.getLabel() === item.convoId) {
            let maxTotalInSeconds = liveAgentTimerDuration;
            let currentMinutes = timer.format("%m");
            let currentSeconds = timer.format("%s");
            let currentTotalInSeconds =
              parseInt(currentSeconds) + parseInt(currentMinutes) * 60;

            if (currentTotalInSeconds >= maxTotalInSeconds) {
              indexAt = -1;
            } else {
              indexAt = index;
            }
            return true;
          }
        });

        if (typeof item.convoId !== "undefined" && indexAt !== -1) {
          if (
            typeof selectedConvo.convoId === "undefined" ||
            selectedConvo.convoId !== item.convoId
          ) {
            setAlertBannerName(
              typeof AlertBannerNameStorage[item.convoId] !== "undefined" &&
                AlertBannerNameStorage[item.convoId] !== null
                ? AlertBannerNameStorage[item.convoId]
                : "A user"
            );
            setAlertBannerConvoId(item.convoId);
            setAlertBannerUserWaiting(true);
          }
        }
      }
    }
  };

  const findConvo = (arr, givenConvoId) => {
    return arr.filter((convos) => {
      if (convos.convoId === givenConvoId) {
        return true;
      }
    });
  };

  const AlertBannerSwitchConvoFunction = (givenConvoId) => {
    let page = currentButton;

    let convoToSwitchTo = findConvo(
      allConvosList[currentButton].data,
      givenConvoId
    );

    if (convoToSwitchTo.length === 0) {
      convoToSwitchTo = findConvo(allConvosList.all.data, givenConvoId);
      page = "all";
    }

    if (!convoToSwitchTo[0]) {
      let needToSearch = true;

      if (needToSearch === true) {
        let searchTerm = "convoId:" + givenConvoId;
        setInboxSearch(searchTerm);
        setWriteInboxSearch(searchTerm);

        let tries = 0;

        function checkForReady() {
          convoToSwitchTo = findConvo(allConvosList.sr.data, givenConvoId);
          if (convoToSwitchTo.length === 0) {
            if (++tries < 50) {
              setTimeout(() => {
                checkForReady();
              }, 100);
            }
          } else {
            setOtherAgentChatting(false);
            modifyActiveConvos(convoToSwitchTo[0], "sr");
            setAlertBannerUserWaiting(false);
          }
        }

        checkForReady();
      }
    } else {
      setOtherAgentChatting(false);
      modifyActiveConvos(convoToSwitchTo[0], page);
      setAlertBannerUserWaiting(false);
    }
  };

  const AlertBannerDismissFunction = () => {
    setAlertBannerUserWaiting(false);
  };

  return (
    <AlertBanner
      userWaiting={AlertBannerUserWaiting}
      messageBold={AlertBannerName}
      message={AlertBannerMessage}
      userDismissFunction={AlertBannerDismissFunction}
      actionButtonMessage={AlertBannerButtonMessage}
      actionFunction={AlertBannerSwitchConvoFunction}
      convoId={AlertBannerConvoId}
    />
  );
}
