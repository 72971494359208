import React from "react";
import { useTheme } from "@material-ui/core/styles";

import "../forms.css";

export default function Checkbox(props) {
  const theme = useTheme();

  return (
    <div style={{ display: "flex", flex: 1 }}>
      <input
        {...props}
        className="checkmark"
        type="checkbox"
        placeholder={props.placeholder ? props.placeholder : null}
        autoComplete="off"
        style={{
          //...theme.typography.body1,
        //   flex: 1,
        //   color: theme.palette.general.darkGrey,
        //   border: `1px solid ${theme.palette.general.deepGrey}`,
        //   padding: "0.5rem 1rem",
          //...props.style
        }}
      />
    </div>
  );
}
