import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import _, { filter, set } from 'lodash';
import { useTheme, withStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import Alert from '@material-ui/lab/Alert';
import Switch from '@material-ui/core/Switch';
import DialogueSummary from './DialogueSummary';
import DialogueLogs from './DialogueLogs';
import DialogueActions from './DialogueActions';
import DialogueOpportunity from './DialogueOpportunity';
import { useModal } from 'hooks';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import { Button, Select, Textarea, Modal, ModalContent } from 'stories';
import { Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import { API, graphqlOperation } from 'aws-amplify';
import {
  chatbotUserByAsurite,
  chatbotUserByPhone,
  listCampaignCompleteds,
  listCampaignRunnings,
  getCampaignCompleted,
  getCampaignRunning,
  listCampaigns,
  compCampByUserId,
  listManaulSendByAudience,
  updateUserInfo,
} from 'graphql/queries';

import {
  updateChatbotUser,
  updateDialogueNotes,
  deleteDialogueNotes,
} from 'graphql/mutations';

import AuthContext from 'contexts/AuthContext';

import { store } from 'contexts/GlobalStore';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function DialogueProperties(props) {
  const GlobalState = useContext(store);
  const tokens = useContext(AuthContext);
  const { dispatch } = GlobalState;
  const classes = useStyles();
  const { isShowing, toggle } = useModal();

  const [tab, setTab] = useState('3');

  let [isLoading, setIsLoading] = useState(false);
  let [filteredCampaigns, setFilteredCampaigns] = useState(false);

  let [isOptedIn, setIsOptedIn] = useState(false);
  let [isUserOptedIn, setIsUserOptedIn] = useState(false);
  let [isPaused, setIsPaused] = useState(false);
  let [reinstateDate, setReinstateDate] = useState(null);
  let [uId, setUId] = useState(null);

  let [rawConvoCampaigns, setRawConvoCampaigns] = useState({});
  let [parsedCampaigns, setParsedCampaigns] = useState([]);
  let [convoNotes, setConvoNotes] = useState([]);
  let [lastConvoId, setLastConvoId] = useState(null);

  let [updatingUser, setUpdatingUser] = useState(false);

  let [selectedEditMesssage, setSelectedEditMessage] = useState({});

  const convo = props.selectedConvo;
  const theme = useTheme();

  useEffect(() => {
    setConvoNotes(props.convoNotes);
  });

  useEffect(() => {}, [props.msgsInReview]);

  useEffect(() => {
    let filtered;
    if (tokens.tier1Support) {
      filtered = props.availableCampaigns;
    } else {
      filtered = props.availableCampaigns.filter(val =>
        tokens.groups.includes(val.group)
      );
    }

    setFilteredCampaigns(filtered);

    if (
      typeof convo.asurite !== 'undefined' ||
      typeof convo.phoneNumber !== 'undefined'
    ) {
      getUser();
    } else {
      setIsPaused(false);
      setIsOptedIn(true);
      setIsUserOptedIn(true);
    }
    setIsLoading(false);

    if (convo.convoId !== lastConvoId) {
      getAndUpdateUserInfo();
      setLastConvoId(convo.convoId);
      setTab('1');
    }
  }, [convo, props]);

  useEffect(() => {
    parseCampaignsTableData(rawConvoCampaigns);
  }, [rawConvoCampaigns]);

  const getAndUpdateUserInfo = async () => {
    let user =
      convo.asurite && convo.asurite !== 'NONE'
        ? convo.asurite
        : convo.phoneNumber && convo.phoneNumber !== 'NONE'
        ? convo.phoneNumber
        : convo.homeNumber;

    if (user) {
      setUpdatingUser(true);

      let payload = {
        user: user,
        botType: 'sunny',
        operation: 'updateUserInfo',
      };

      props.ConvosClickActionToggle(false);
      console.log('!!!Convo list disabled');
      const resp = API.graphql(graphqlOperation(updateUserInfo, payload))
        .then(resp => {
          props.ConvosClickActionToggle(true);
          console.log('!!!Convo list enabled: update user info completed');
          let newUserInfo = JSON.parse(resp.data.updateUserInfo);

          let newConvo = {
            ...convo,
            ...newUserInfo,
          };

          if (convo.auth) {
            newConvo.auth = {
              ...convo.auth,
              ...newUserInfo,
            };
          }

          if (convo.sms) {
            newConvo.sms = {
              ...convo.sms,
              ...newUserInfo,
            };
          }

          props.setSelectedConvo(newConvo);
          setUpdatingUser(false);
        })
        .catch(err => {
          props.ConvosClickActionToggle(true);
          console.log('!!!Convo list enabled: update user info failed');
        });
    } else {
      props.ConvosClickActionToggle(true);
      console.log('!!!Convo list enabled: no user');
    }
  };
  const formatNotesTableData = data => {
    if (data) {
      let formattedData = [];

      data
        .sort((a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf())
        .forEach(note => {
          let noteDate = new Date(note.date);

          let month = noteDate.getMonth() + 1;
          let day = noteDate.getDate();
          let year = noteDate.getFullYear();
          let time = noteDate.toLocaleTimeString([], {
            hour: 'numeric',
            minute: '2-digit',
          });

          let dateString = `${month}/${day}/${year} ${time}`;

          formattedData.push({
            date: dateString,
            agent: note.author,
            agentDisplay: props.getAssigneeName(note.author),
          });
        });

      return formattedData;
    }
  };

  const parseCampaignsTableData = async data => {
    let { allCampaigns } = props;
    let allCampaignIds = allCampaigns.map(campaign => campaign.id);
    let userCampaigns = [];

    if (data.completed) {
      data.completed.forEach(camp => {
        userCampaigns.push(camp.campaignId);
      });
    }
    if (data.running) {
      userCampaigns.push(data.running.campaignId);
    }

    if (userCampaigns.length > 0) {
      //* loop converting user campaign ids to indices
      for (let i = 0; i < userCampaigns.length; i++) {
        let index = allCampaignIds.indexOf(userCampaigns[i]);
        userCampaigns[i] = index;
      }

      //* loop converting indicies to campaigns
      for (let i = 0; i < userCampaigns.length; i++) {
        if (userCampaigns[i] > -1) {
          userCampaigns[i] = allCampaigns[userCampaigns[i]];
        }
      }
    }
    setParsedCampaigns(userCampaigns.filter(camp => camp !== -1)); //! removes campaigns/indices that were pulled in under completed or running campaigns but weren't found in allCampaigns query
  };

  const formatCampaignsTableData = data => {
    if (data) {
      let formattedData = [];

      data
        .sort(
          (a, b) =>
            new Date(b.startTime).valueOf() - new Date(a.startTime).valueOf()
        )
        .forEach(campaign => {
          let date = new Date(parseInt(campaign.startTime)); //* using campaign start date instead of creation date
          let month = date.getMonth() + 1;
          let day = date.getDate();
          let year = date.getFullYear();

          let dateString = `${month}/${day}/${year}`;

          formattedData.push({
            name: campaign.title,
            group: campaign.groupKey.split('-', 1),
            date: dateString,
          });
        });

      return formattedData;
    }
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const getErrorMessage = () => {
    if (props.otherAgentChatting) {
      return 'Cannot join conversation since another user has currently joined';
    } else if (isUserOptedIn === false || isOptedIn === false) {
      return 'User has opted out of receiving messages';
    } else if (!convo.webId) {
      return 'User is not active and will not receive any messages sent at this time';
    }
  };

  const getUser = async () => {
    let op = null;
    let pay = {};
    let opTxt = '';

    try {
      let u = props.selectedConvo;
      setUId(u.userId ? u.userId : u.id);
      setIsPaused(typeof u.paused !== 'undefined' ? u.paused : false);
      setIsOptedIn(u.userOptOut ? false : u.optOut ? false : true);
      setIsUserOptedIn(u.userOptOut ? false : true);
      setReinstateDate(u.reinstateDate);
    } catch (e) {}
  };

  const fetchUserCampaigns = async userId => {
    let result = { completed: [], running: {} };

    let completedResp = await API.graphql(
      graphqlOperation(compCampByUserId, { userId })
    );

    let runningResp = await API.graphql(
      graphqlOperation(getCampaignRunning, {
        userId: userId,
      })
    );

    let completedRespArr = completedResp.data.compCampByUserId.items;

    if (completedRespArr.length > 0) result.completed = completedRespArr;
    result.running = runningResp.data.getCampaignRunning;

    setRawConvoCampaigns(result);
  };

  const getUsername = () => {
    if (convo.type === 'sms') {
      return convo.phoneNumber;
    } else if (convo.type === 'visitor') {
      return 'Financial Aid Visitor';
    } else {
      let nameArr = convo.displayName ? convo.displayName.split(' ') : [];
      return {
        firstName: nameArr[0],
        middleName:
          nameArr.length < 2
            ? null
            : nameArr.slice(1, nameArr.length - 1).join(' '),
        lastName: nameArr[nameArr.length - 1],
      };
    }
  };

  const dateFormatter = (date, withTime) => {
    let dateObj = new Date(date);

    if (dateObj.getTime() < 16000000000)
      dateObj.setTime(dateObj.getTime() * 1000);
    if (!isNaN(dateObj.getTime())) {
      let month = dateObj.getMonth() + 1;
      let day = dateObj.getDate();
      let year = dateObj.getFullYear();
      let time = dateObj.toLocaleTimeString([], {
        hour: 'numeric',
        minute: '2-digit',
      });

      if (withTime) return `${month}/${day}/${year}, ${time}`;
      else return `${month}-${day}-${year}`;
    } else {
      return 'Invalid Date';
    }
  };

  const convertDecimal = value => {
    let roundValue = Math.floor(roundValue);
    let decimalValue = value - roundValue;
    if (decimalValue > 0) {
      return value * 1000;
    } else {
      return value;
    }
  };

  const getModalContent = message => {
    if (message) {
      let content = message.content;

      let canEditOrDelete = false;

      if (
        tokens.role === 'admin' ||
        tokens.role === 'publisher' ||
        tokens.asurite === message.author
      ) {
        canEditOrDelete = true;
      }

      let formData = [
        {
          component: (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {/* Notes being pulled from ES do not have createdAt, will need to query single note to get createdAt */}
              {/* <p>{`Created: ${dateFormatter(message.createdAt)}`}</p> */}
              <p>{`Last updated: ${dateFormatter(message.date)}`}</p>
              {/* <p>{`User: `}</p> */}
              <p>{`Agent: ${props.getAssigneeName(message.author)}`}</p>
            </div>
          ),
        },
        {
          title: 'MESSAGE',
          required: true,
          component: (
            <Textarea
              form
              required
              stateValue={content}
              disabled={!canEditOrDelete}
              name="message"
            />
          ),
        },
      ];

      return (
        <ModalContent
          form={true}
          formData={formData}
          title={canEditOrDelete ? 'Edit Note' : 'Message Content'}
          onSubmit={updateMessageNoteOnSubmit}
          hideSubmit={!canEditOrDelete}
          onClickDelete={deleteNoteOnSubmit}
          deleteButton={canEditOrDelete}
          toggle={toggle}
        />
      );
    }
  };

  const deleteNoteOnSubmit = async () => {
    if (selectedEditMesssage.id) {
      try {
        let messageNoteOperation = await API.graphql(
          graphqlOperation(deleteDialogueNotes, {
            input: {
              id: selectedEditMesssage.id,
            },
          })
        );

        let deletedNote = messageNoteOperation.data.deleteDialogueNotes;

        if (deletedNote) {
          // If not null, create a copy using filter function because it returns a new array, so when updating the state, the table should re-render

          let notesCopy = props.convoNotes.filter(
            note => note.id !== deletedNote.id
          );

          props.setConvoNotes(notesCopy);
          toggle(); //! closes modal
          setSelectedEditMessage(null);
        } else {
          throw new Error(
            `Error deleting message note with id ${selectedEditMesssage.id}`
          );
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const updateMessageNoteOnSubmit = async data => {
    if (data.message) {
      try {
        if (data.message !== selectedEditMesssage.content) {
          let payload = {
            id: selectedEditMesssage.id,
            content: data.message,
          };

          let messageNoteOperation = await API.graphql(
            graphqlOperation(updateDialogueNotes, {
              input: payload,
            })
          );
          // console.log("updateDialoguesNotes: ", messageNoteOperation);

          let updatedNote = messageNoteOperation.data.updateDialogueNotes;

          updatedNote.date = new Date(updatedNote.updatedAt).valueOf();

          let notesCopy = props.convoNotes.map(note => {
            if (note.id === updatedNote.id) {
              return updatedNote;
            }
            return note;
          });

          // console.log(notesCopy);
          props.setConvoNotes(notesCopy);
          toggle(); //! closes modal
          setSelectedEditMessage(null);
        }

        // console.log(postData)
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleSelectEditMessage = message => {
    setSelectedEditMessage(message);
    toggle();
  };

  const Slider = withStyles(theme => ({
    root: {
      width: 45,
      height: 23,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(20px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#52d869',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#52d869',
        border: '6px solid #fff',
        // border: 'none',
      },
    },
    thumb: {
      marginTop: 0.5,
      marginLeft: 2,
      width: 19,
      height: 19,
    },
    track: {
      borderRadius: 23 / 2,
      // border: `1px solid ${theme.palette.grey[400]}`,
      border: `none`,
      // backgroundColor: theme.palette.grey[50],
      backgroundColor: '#D6D5D4',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });

  const toggleSlider = async (e, type) => {
    let toUpdate = {
      [type]: e,
    };

    let rd = null;

    if (type === 'paused') {
      if (isPaused) {
        toUpdate.reinstateDate = null;
        setReinstateDate(rd);
      } else {
        if (tokens.env !== 'prod') {
          rd = moment()
            .add(30, 'minutes')
            .seconds(0)
            .milliseconds(0)
            .unix();
        } else {
          rd = moment()
            .add(2, 'weeks')
            .hours(23)
            .seconds(0)
            .milliseconds(0)
            .minutes(59)
            .unix();
        }

        setReinstateDate(rd);
        toUpdate.reinstateDate = rd;
      }
      setIsPaused(!isPaused);
    } else {
      let newOpt = !isOptedIn;
      convo.optOut = newOpt;
      setIsOptedIn(newOpt);
    }

    toUpdate[type === 'paused' ? 'agentPauseAsurite' : 'agentOptOutAsurite'] =
      tokens.asurite;

    await API.graphql(
      graphqlOperation(updateChatbotUser, { input: { id: uId, ...toUpdate } })
    );
  };

  return (
    <div
      id="dialoguePropertiesWindow"
      style={{
        flex: 1,
        borderLeft: '1px solid #d5d5d5',
        overflowY: 'scroll',
        width: '30vw',
      }}
    >
      <TabContext value={tab}>
        <TabList variant="scrollable" onChange={handleTabChange}>
          <Tab label="Summary" value="1" />
          <Tab label="Opportunity" value="2" />
          <Tab label="Logs" value="3" />
          <Tab label="Action" value="4" />
        </TabList>
        <TabPanel value="1">
          {!isLoading ? (
            updatingUser ? (
              <div
                style={{
                  padding: '24px',
                  textAlign: 'center',
                  marginTop: '90px',
                }}
              >
                <CircularProgress
                  style={{ color: theme.palette.general.lightMaroon }}
                />
                <div>Getting latest user info</div>
              </div>
            ) : (
              <DialogueSummary
                convo={convo}
                getAvatar={props.getAvatar}
                isLoading={isLoading}
                getUsername={getUsername}
                getErrorMessage={getErrorMessage}
                isOptedIn={isOptedIn}
                isUserOptedIn={isUserOptedIn}
                setIsOptedIn={setIsOptedIn}
                isPaused={isPaused}
                reinstateDate={reinstateDate}
                setIsPaused={setIsPaused}
                uId={uId}
                otherAgentChatting={props.otherAgentChatting}
                selectedConvo={props.selectedConvo}
                modifyActiveConvos={props.modifyActiveConvos}
                toggleSlider={toggleSlider}
              />
            )
          ) : null}
        </TabPanel>
        <TabPanel value="2">
          {!isLoading ? <DialogueOpportunity convo={convo} /> : null}
        </TabPanel>
        <TabPanel value="3">
          {!isLoading ? (
            <DialogueLogs
              updateReviewCounts={props.updateReviewCounts}
              isLoading={isLoading}
              convo={convo}
              getUsername={getUsername}
              setUpdateNeedsReview={props.setUpdateNeedsReview}
              searchForMessage={props.searchForMessage}
              setSearchForMessage={props.setSearchForMessage}
              msgsInReview={props.msgsInReview}
              history={props.history}
              convoNotes={{
                rawData: convoNotes,
                notesTableData: formatNotesTableData(convoNotes),
              }}
              convoCampaigns={{
                rawData: parsedCampaigns,
                campaignsTableData: formatCampaignsTableData(parsedCampaigns),
              }}
              handleSelectEditMessage={handleSelectEditMessage}
              formatNotesTableData={formatNotesTableData}
              getAssigneeName={props.getAssigneeName}
              dateFormatter={dateFormatter}
              listFilter={props.listFilter}
              myReviewTags={props.myReviewTags}
              setConvoNotes={props.setConvoNotes}
            />
          ) : null}
        </TabPanel>
        <TabPanel value="4">
          {!isLoading ? (
            <DialogueActions
              // allCampaigns={props.allCampaigns}
              availableCampaigns={filteredCampaigns}
              campaignsRan={rawConvoCampaigns}
              escalationData={props.escalationData}
              isOptedOutNow={!isOptedIn}
              isPausedNow={isPaused}
              // refetchManualSendCampaigns={filterAvailableCampaigns}
              convo={convo}
            />
          ) : null}
        </TabPanel>
      </TabContext>
      <Modal
        isShowing={isShowing}
        hide={toggle}
        content={getModalContent(selectedEditMesssage)}
      />
    </div>
  );
}

DialogueProperties.propTypes = {
  navigation: PropTypes.object,
};
