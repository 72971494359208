import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Truncate from 'react-truncate';
import moment from 'moment';

import './Table.css';
import { Status } from '../';
import theme from '../theme';

export default function TableRow(props) {
  const useStyles = makeStyles(theme => ({
    row: {
      fontSize: '14px',
      fontFamily: theme.typography.fontFamily,
      display: 'flex',
      justifyContent: 'space-between',
      borderBottomStyle: 'solid',
      borderBottomWidth: borderWidth(),
      borderColor: '#d5d5d5',
      padding: '1rem 2rem',
    },
    name: {
      margin: '10px 0',
      color: '#8d8d8d',
    },
    excerpt: {
      margin: '10px 0',
    },
  }));

  const borderWidth = () => {
    return '1px';
  };
  const classes = useStyles();

  const onClickCustom1 = () => {
    if (props.onClickCustom1) {
      props.onClickCustom1(props.data);
    } else {
      console.log('no onClickCustom1 handler provided for TableRow.js');
    }
  };

  const onClickEdit = () => {
    if (props.onClickEdit) {
      props.onClickEdit(props.data);
    } else {
      console.log('no onClickEdit handler provided for TableRow.js');
    }
  };

  const onClickView = () => {
    if (props.onClickView) {
      props.onClickView(props.data);
    } else {
      console.log('no onClickView handler provided for TableRow.js');
    }
  };

  const onClickResolve = () => {
    if (props.onClickResolve) {
      props.onClickResolve(props.data);
    } else {
      console.log('no onClickResolve handler provided for TableRow.js');
    }
  };

  const onClickPause = () => {
    if (props.onClickPause) {
      props.onClickPause(props.data);
    } else {
      console.log('no onClickPause handler provided for TableRow.js');
    }
  };

  const onClickStart = () => {
    if (props.onClickStart) {
      props.onClickStart(props.data);
    } else {
      console.log('no onClickStart handler provided for TableRow.js');
    }
  };

  const onClickDelete = () => {
    if (props.onClickDelete) {
      props.onClickDelete();
    } else {
      console.log('no onClickDelete handler provided for TableRow.js');
    }
  };

  const formatTime = time => {
    const formatted = moment(time, 'x').format('LLL');
    return formatted;
  };

  const isValidTimeStamp = timestamp => {
    if (
      new Date(moment(timestamp, 'x').format('LLL')).getTime() > 0 &&
      !props.noTimestamps
    ) {
      return true;
    } else {
      return false;
    }
  };

  const statusCheck = () => {
    if (
      props.data &&
      props.data.status &&
      (props.data.status.statusID === 6 || props.data.status.processing)
    ) {
      return true;
    } else {
      return false;
    }
  };

  let dataToArray = [];
  if (props.data) {
    dataToArray = Object.keys(props.data);
  }

  if (props.responseTable) {
    dataToArray.splice(5, 2);
  } //cleaning up Response Table Data

  const getNameTruncateWidth = () => {
    let screenWidth = window.screen.width;
    if (screenWidth > 1439) return 250;
    else if (screenWidth > 1000 && screenWidth < 1440) return 200;
    else return 150;
  };

  const getRowLines = (dataLength) => {
    const screenWidth = window.screen.width;
    const cellWidth = 120;
    const availableWidth = screenWidth - 20;
    const maxCellsPerRow = Math.floor(availableWidth / cellWidth);
    return Math.ceil(dataLength / maxCellsPerRow);
  }

  return (
    <div
      className={classes.row}
      style={
        props.imageTable
          ? { height: '6rem', alignItems: 'center', ...props.style }
          : { ...props.style }
      }
      id={props.data.id}
    >
      {dataToArray.map((el, index) => {
        const data = props.data[el];
        if (el === 'name') {
          return (
            <div key={index} style={{ flex: 2, flexDirection: 'column' }}>
              <b style={{ display: 'flex', paddingRight: '1rem' }}>
                <Truncate
                  lines={getRowLines(data.length)}
                  ellipsis={<span>... </span>}
                  width={getNameTruncateWidth()}
                  // onTruncate={handleTruncate}
                >
                  {data}
                </Truncate>
              </b>
              {dataToArray[index + 1] === 'description' ? (
                <div
                  style={{ display: 'flex', flex: 1, paddingRight: '1rem' }}
                  className={classes.name}
                >
                  <Truncate
                    lines={1}
                    ellipsis={<span>... </span>}
                    width={getNameTruncateWidth()}
                    // onTruncate={handleTruncate}
                  >
                    <p style={{ color: theme.palette.general.deepGrey }}>
                      {props.data[dataToArray[index + 1]]}
                    </p>
                  </Truncate>
                </div>
              ) : null}
            </div>
          );
        } else if (el === 'excerpt' || el === 'wide_message') {
          return (
            <div key={index} style={{ flex: 2, flexDirection: 'column' }}>
              <p style={{ display: 'flex', paddingRight: '1rem' }}>
                <Truncate lines={1} ellipsis={<span>... </span>} width={650}>
                  {data}
                </Truncate>
              </p>
              {dataToArray[index + 1] === 'description' ? (
                <div
                  style={{ display: 'flex', flex: 1, paddingRight: '1rem' }}
                  className={classes.name}
                >
                  <Truncate
                    lines={1}
                    ellipsis={<span>... </span>}
                    width={650}
                    // onTruncate={handleTruncate}
                  >
                    <p style={{ color: theme.palette.general.deepGrey }}>
                      {props.data[dataToArray[index + 1]]}
                    </p>
                  </Truncate>
                </div>
              ) : null}
            </div>
          );
        } else if (el === 'actions') {
          return (
            <div
              key={index}
              style={{ display: 'flex', flexDirection: 'row', flex: 1 }}
            >
              <div style={{ flex: 1 }} />
              {statusCheck() ? null : (
                <div style={{ display: 'flex' }}>
                  {data.custom1 && (
                    <div
                      style={{ color: 'maroon', marginRight: '1em' }}
                      className="actionButton"
                      onClick={onClickCustom1}
                    >
                      {data.custom1_name}
                    </div>
                  )}
                  {data.edit && (
                    <div
                      style={{ color: 'maroon', marginRight: '0.75rem' }}
                      onClick={onClickEdit}
                      className="actionButton"
                    >
                      Properties
                    </div>
                  )}
                  {data.editAsName && (
                    <div
                      style={{ color: 'maroon', marginRight: '0.75rem' }}
                      onClick={onClickEdit}
                      className="actionButton"
                    >
                      Edit
                    </div>
                  )}
                  {data.pause && (
                    <div
                      style={{ color: 'maroon', marginRight: '0.75rem' }}
                      onClick={onClickPause}
                      className="actionButton"
                    >
                      Pause
                    </div>
                  )}
                  {data.start && (
                    <div
                      style={{ color: 'maroon', marginRight: '0.75rem' }}
                      onClick={onClickStart}
                      className="actionButton"
                    >
                      Start
                    </div>
                  )}
                  {data.view && (
                    <div
                      style={{ color: 'maroon', marginRight: '0.75rem' }}
                      onClick={onClickView}
                      className="actionButton"
                    >
                      View
                    </div>
                  )}
                  {data.resolve && (
                    <div
                      style={{ color: 'maroon', marginRight: '0.75rem' }}
                      onClick={onClickResolve}
                      className="actionButton"
                    >
                      Resolve
                    </div>
                  )}
                  {data.delete && (
                    <div
                      style={{ color: 'maroon' }}
                      className="actionButton"
                      onClick={onClickDelete}
                    >
                      Delete
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        } else if (el === 'status') {
          return (
            <div key={index} style={{ display: 'flex', flex: 1 }}>
              <Status
                status={data.statusID}
                tooltip={data.tooltip}
                processing={data.processing}
                error={data.error}
                editing={data.editing}
                paused={data.paused}
                versionstatus={data.versionstatus}
                useversionstatus={props.useversionstatus}
              />
            </div>
          );
        } else if (el === 'imgPreview') {
          return (
            <div key={index} style={{ display: 'flex', flex: 1 }}>
              <img
                src={data}
                style={{ maxHeight: '5rem', width: '5rem' }}
                alt="image-gallery-preview"
              />
            </div>
          );
        } else if (el === 'submittedOn' || el === 'editedOn') {
          return (
            <div key={index} style={{ display: 'flex', flex: 1 }}>
              {formatTime(data)}
              <div style={{ width: '0.75rem' }} />
            </div>
          );
        } else if (el === 'variations') {
          return (
            <div
              key={index}
              style={{ display: 'flex', flex: 1, justifyContent: 'center' }}
            >
              {data}
            </div>
          );
        } else if (el === 'targetAudience') {
          return (
            <div key={index} style={{ display: 'flex', flex: 1 }}>
              {data}
              <div style={{ width: '0.5rem' }} />
            </div>
          );
        } else if (
          el === 'description' ||
          el === 'id' ||
          el === 'active' ||
          el === 'text'
        ) {
          return null;
        } else if (el === 'group') {
          // ! Logic for group id vs group name
          let groupName = data;
          if (props.keyedNames[groupName]) {
            groupName = props.keyedNames[groupName];
          }
          groupName =
            typeof groupName !== 'string' ? groupName.label : groupName;
          return (
            <div key={index} style={{ display: 'flex', flex: 1 }}>
              {groupName}
              <div style={{ width: '0.5rem' }} />
            </div>
          );
        } else if (el === 'groups') {
          let groupNames = data.split(', ');
          let dataG = '';
          for (let i = 0; i < groupNames.length; ++i) {
            let groupName = groupNames[i];
            if (props.keyedNames[groupName]) {
              if (i == 0) dataG = '' + props.keyedNames[groupName];
              else dataG = dataG + ',' + props.keyedNames[groupName];
            } else {
              if (i == 0) dataG = '' + groupName;
              else dataG = dataG + ',' + groupName;
            }
          }
          return (
            <div key={index} style={{ display: 'flex', flex: 1 }}>
              {dataG}
              <div style={{ width: '0.5rem' }} />
            </div>
          );
        } else {
          let dataX = data;
          if (isValidTimeStamp(data)) {
            dataX = formatTime(data);
          }
          return (
            <div key={index} style={{ display: 'flex', flex: 1 }}>
              {dataX}
              <div style={{ width: '0.5rem' }} />
            </div>
          );
        }
      })}
    </div>
  );
}
