import React, { useState, useEffect, useContext } from 'react';
import { useTheme } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { useFormContext } from 'react-hook-form';

import { API, graphqlOperation } from 'aws-amplify';
import { validateLexQuestion } from 'graphql/queries';

import AuthContext from 'contexts/AuthContext';

import { Input } from 'stories';
import { FieldExtras } from '../FieldExtras';
var charTimeout = null;

export default function Variations(props) {
  const tokens = useContext(AuthContext);
  const theme = useTheme();
  const { register } = useFormContext();
  const [value, setValue] = useState('');
  const [variations, setVariations] = useState([]);

  const [runningQuestion, setRunningQuestion] = useState(false);
  const [questionStatus, setQuestionStatus] = useState('');
  const [statusMsg, setStatusMsg] = useState('done');
  const [currentTextField, setCurrentTextField] = useState(null);

  const [regex, setRegex] = useState(null);
  const [charNotSupported, setCharNotSupported] = useState(null);

  useEffect(() => {
    if (props.regex) {
      setRegex(new RegExp(props.regex));
    }
  }, [props.regex]);

  let runningClasses = 'statusBase ';
  if (runningQuestion) {
    runningClasses += 'q' + questionStatus.toLowerCase();
  } else {
    runningClasses += 'qWhiteOut';
  }

  if (charNotSupported) {
    runningClasses = 'statusBase qerror';
  }

  useEffect(() => {
    if (props.stateValue) {
      setVariations(props.stateValue);
    }
  }, []);

  const style = index => {
    if (variations.length === 1) {
      return {
        display: 'flex',
        padding: '0.5rem',
        border: `1px solid ${theme.palette.general.deepGrey}`,
        borderRadius: '4px',
      };
    } else if (index === 0) {
      return {
        display: 'flex',
        padding: '0.5rem',
        border: `1px solid ${theme.palette.general.deepGrey}`,
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
      };
    } else if (variations.length >= 3 && index <= variations.length - 2) {
      return {
        display: 'flex',
        padding: '0.5rem',
        border: `1px solid ${theme.palette.general.deepGrey}`,
        borderTopWidth: '0px',
      };
    } else {
      return {
        display: 'flex',
        padding: '0.5rem',
        borderStyle: 'solid',
        borderWidth: '0 1px 1px 1px',
        borderColor: theme.palette.general.deepGrey,
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px',
      };
    }
  };

  const handleInput = event => {
    let val = event.target.value;
    if (val === '') {
      return setValue(val);
    } else if (regex && !regex.test(val)) {
      let unChar = val[val.length - 1];
      let message = 'Unsupported character: ' + unChar;

      if (!isNaN(parseInt(unChar))) {
        message = 'Numbers must be written out';
      }

      setCharNotSupported(message);
      clearTimeout(charTimeout);
      charTimeout = setTimeout(() => {
        setCharNotSupported(null);
      }, 4000);

      val = value;
    }

    setValue(val);
  };

  const addVariation = () => {
    if (value === '') return null;
    const tempValue = value.replace(/,/g, '~~~');
    setVariations([...variations, tempValue]);
    setValue('');
  };

  const handleAdd = () => {
    if (value === '') return null;

    if (props.kbVariation) {
      setRunningQuestion(true);
      setCharNotSupported(null);
      setQuestionStatus('sending');
      setStatusMsg('Checking for availability');

      let payload = {
        operation: 'runQuestion',
        inquiry: value,
        env: 'dev',
        current: props.editId,
      };
      API.graphql(graphqlOperation(validateLexQuestion, payload)).then(resp => {
        try {
          let responseData = resp.data.validateLexQuestion;
          if (responseData.isSelf === true) {
            setStatusMsg('Question is already in current FAQ');
            setQuestionStatus('success');
            setValue('');
          } else if (
            responseData.isTaken &&
            responseData.intentName.indexOf('CRITICAL_') > -1
          ) {
            setStatusMsg('Question is part of a critical alert');
            setQuestionStatus('error');
            setValue('');
          } else if (responseData.isTaken) {
            setStatusMsg('Question is not available');
            setQuestionStatus('error');
            setValue('');
          } else {
            setStatusMsg('Question is available!');
            setQuestionStatus('success');
            addVariation();
          }
        } catch (err) {
          setStatusMsg('There was a critical error checking the question');
          setQuestionStatus('error');
        }

        setTimeout(() => {
          setRunningQuestion(false);
          setQuestionStatus('');
          setStatusMsg('done');
        }, 2000);
      });
    } else {
      addVariation();
    }
  };

  const handleRemove = index => {
    const start = variations.slice(0, index);
    const end = variations.slice(index + 1);
    const newVariations = start.concat(end);
    setVariations(newVariations);
  };

  const { kbVariation, editId, stateValue, ...validProps } = props;

  return (
    <div style={{ ...props.style }}>
      <div
        style={{
          display: 'flex',
          backgroundColor: theme.palette.general.lightGrey,
          padding: '.5rem',
          border: `1px solid ${theme.palette.general.deepGrey}`,
          borderRadius: '4px',
          opacity: props.disabled ? 0.5 : 1,
        }}
      >
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Input
              id="variations-input"
              value={value}
              disabled={props.disabled}
              onChange={handleInput}
              setTextFieldRef={e => setCurrentTextField(e)}
              maxlength={props.maxlength ? props.maxlength : ''}
              style={{
                backgroundColor: 'white',
                width: '100%',
              }}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <AddCircleOutlineIcon
                style={{
                  fontSize: '30px',
                  marginLeft: '0.5rem',
                  color: theme.palette.general.deepGrey,
                  cursor: 'pointer',
                }}
                onClick={handleAdd}
              />
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'center',
              alignItems: 'center',
              height: '1px',
            }}
          >
            <div style={{ display: 'flex', flex: 1 }} />
            <input
              {...validProps}
              type="select"
              ref={props.required ? register({ required: true }) : register}
              value={variations}
              readOnly
              autoComplete="off"
              style={{
                display: 'flex',
                flex: 1,
                opacity: 0,
                height: '1px',
                width: '1px',
                cursor: 'default',
              }}
            />
            <div style={{ display: 'flex', flex: 1 }} />
          </div>
        </div>
      </div>

      {props.allowextras ? (
        <FieldExtras
          onChange={props.onChange}
          setValue={setValue}
          allowextras={props.allowextras}
          value={value}
          currentTextField={currentTextField}
        />
      ) : null}

      {props.charLimit ? (
        <div
          style={{
            width: props.allowextras ? 'fit-content' : '100%',
            textAlign: 'right',
            color: value.length > props.charLimit - 1 ? 'red' : 'black',
          }}
        >
          {value.length + '/' + props.charLimit}
        </div>
      ) : null}

      <div style={{ marginTop: '0.5rem' }}>
        <div className={runningClasses} style={{ marginTop: '0.5rem' }}>
          {charNotSupported ? charNotSupported : statusMsg}
        </div>

        {variations.map((el, index) => {
          return (
            <div key={index} style={style(index)}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                {el.replace(/~~~/g, ',')}
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <RemoveCircleOutlineIcon
                  style={{
                    fontSize: '30px',
                    marginLeft: '0.5rem',
                    color: theme.palette.general.deepGrey,
                    cursor: 'pointer',
                  }}
                  onClick={() => handleRemove(index)}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
