export const audiences = [
  { label: 'Everyone', value: 'everyone', flag: 'EV' },
  { label: 'Employee', value: 'employee', flag: 'EMPLOYEE' },
  { label: 'Undergrad Applicants', value: 'under_applicant', flag: 'UAPP' },
  { label: 'Grad Applicants', value: 'grad_applicant', flag: 'GAPP' },
  { label: 'Enrolled Students', value: 'enrolled', flag: 'ENROLLED' },
  { label: 'Campus Student', value: 'campus', flag: 'CS' },
  { label: 'Online Student', value: 'online', flag: 'OS' },
  { label: 'Graduate Student', value: 'graduate', flag: 'GS' },
  { label: 'Undergraduate Student', value: 'undergraduate', flag: 'UGS' },
];

export const translateAudience = a => {
  let myAud = audiences;
  for (var i = 0; i < myAud.length; ++i) {
    if (a === myAud[i].value) {
      return myAud[i].label;
    }
  }
  return a;
};
