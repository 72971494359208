import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Accordion as MUIAccordion } from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { H6 } from "stories";

import "./Accordion.scss";

const useAccordionStyles = makeStyles((theme) => ({
  root: {
    width: "98%", //! A.
    border: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: "10px",
    boxShadow: "none",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  expanded: {
    marginBottom: "10px",
  },
}));

const useSummaryStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));
const useDetailsStyles = makeStyles((theme) => ({
  root: {
    flexDirection: "column",
    minHeight: "50px",
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function Accordion(props) {
  const classes = useStyles();
  const accordionClasses = useAccordionStyles();
  const summaryClasses = useSummaryStyles();
  const detailsStyles = useDetailsStyles();

  const [isExpanded, setExpanded] = useState(
    props.showPreExpanded ? props.showPreExpanded : false
  );

  useEffect(() => {
    if (props.showPreExpanded !== null) {
      setExpanded(props.showPreExpanded);
    }
  }, [props.showPreExpanded]);

  return (
    <div style={{ width: "100%" }}>
      <MUIAccordion
        classes={{
          root: accordionClasses.root,
          expanded: accordionClasses.expanded,
        }}
        expanded={isExpanded}
        onChange={(event, expanded) => {
          setExpanded(expanded);
        }}
      >
        <AccordionSummary
          className={summaryClasses.root}
          expandIcon={<ExpandMoreIcon />}
        >
          <div className="AccordionSummary--expanded">
            {props.expandedSummaryContent && isExpanded
              ? props.expandedSummaryContent
              : props.summaryContent}
          </div>
        </AccordionSummary>
        <AccordionDetails className={detailsStyles.root}>
          {props.detailsContent}
        </AccordionDetails>
      </MUIAccordion>
    </div>
  );
}
