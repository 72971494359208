import React, { useState, useEffect, useContext } from "react";
import GifLoader from "./assets/Sonny-No-Hair.gif";
import UhOhImage from "./assets/sunnyfrown3x.png";
import Surprised from "./assets/sunnysurprised3x.png";

import "./style.css";

const DEFAULT_TEXT = "Just one moment, preloading dashboard";
const MAINT_TEXT = "Uh oh! Looks like I'm having some work done. Please check back in later.";
const ERROR_TEXT = "Hmm.. looks like I'm having some issues loading";

export default function Landing(props) {

  const [opacity,setOpacity] = useState(1);
  const [text, setText] = useState(DEFAULT_TEXT);
  const [image, setImage] = useState("gif");

  const setAndReset = (text,img) => {
    setOpacity(0);
    setTimeout(() => {
      setText(text);
      setImage(img);
      setOpacity(1);
    },500)
  }

  useEffect(()=> {
    if( props.maintenanceMode && opacity === 1 ) {
      setAndReset(MAINT_TEXT,"surprised");
    }
  },[props.maintenanceMode]);

  useEffect(()=> {
    if( props.errorMode && opacity === 1 ) {
      setAndReset(ERROR_TEXT,"uhOh");
    }
  },[props.errorMode]);

  const getImage = () => {
    let className = "loaderImage";
    let m_image = GifLoader;

    if( image!=="gif" ) {
      className="uhOhImage";
      if( image === "uhOh" ) {
        m_image = UhOhImage;
      } else if ( image === "surprised" ) {
        m_image = Surprised;
      }
    }

    return <img src={m_image} alt="Sunny Loader" className={className} />
  }

  return (
    <div className="landingholder" style={{opacity: opacity}}>
      <div className="centerTextLanding">
        {getImage()}
        <div className="landingText">{text}</div>
      </div>
    </div>
  );
}
