import React, { useState, useEffect, useContext } from 'react';
import {
  H6,
  Accordion,
  PanelButton,
  Textarea,
  Form,
  MiniTable,
  FlowSelect,
} from 'stories';

import AuthContext from 'contexts/AuthContext';
import moment from 'moment-timezone';

import { API, graphqlOperation, Storage } from 'aws-amplify';
import * as queries from 'graphql/queries';
import * as mutations from 'graphql/mutations';

import { store as GlobalStore } from 'contexts/GlobalStore';
import CheckIcon from '@material-ui/icons/Check';
import CrossIcon from '@material-ui/icons/Cancel';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

//import "../CampaignTabs.scss";

export default function ImportSummary(props) {
  console.log('IS SENDING: ', props.isSendingIntroMessage);
  const [csvData, setCsvData] = useState(null);
  const [clearValue, setClearValue] = useState(new Date().getTime());
  const [accordionLayout, setAccordionLayout] = useState(null);
  const [statusMessageOverrides, setStatusMessageOverrides] = useState({});
  const [newNumsDataWithOverrides, setNewNumsDataWithOverrides] = useState([]);
  const [statusUpdated, setStatusUpdated] = useState(false);
  const [rebuildAccordion, setRebuildAccordion] = useState(false);
  const [foundUsers, setfoundUsers] = useState([]);

  const tokens = useContext(AuthContext);

  const GlobalState = useContext(GlobalStore);
  const dispatchGlobal = GlobalState.dispatch;

  const { editInfo, loadingFormSubmission, includeFlow } = GlobalState.state;

  /*Override the status of Intro Messages*/
  if (props.newNumsData.length > 0 && statusUpdated === false) {
    if (Object.keys(statusMessageOverrides).length > 0) {
      let tempNumsDataWithOverrides = JSON.parse(
        JSON.stringify(props.newNumsData)
      ).map(object => {
        if (
          object.messageId !== false &&
          typeof statusMessageOverrides[object.messageId] !== 'undefined'
        ) {
          object.status = statusMessageOverrides[object.messageId];
          return object;
        } else {
          return object;
        }
      });

      setStatusUpdated(true);
      setNewNumsDataWithOverrides(tempNumsDataWithOverrides);
    }
  }

  const parseMessageIds = async (statusMessageOverrides, rebuildAccordion) => {
    /* If intro messages were sent, the messageIds for those messages will be in the csv and
      will be in props.newNumsData */

    //array to hold messageIds

    let messageId_array = [];

    if (props.newNumsData.length > 0) {
      props.newNumsData.forEach(object => {
        /*date, messageId, phone, status*/
        if (object.messageId !== false) {
          messageId_array.push(object.messageId);
        }
      });
    }

    if (messageId_array.length > 0) {
      //send array of messageIds to the lambda that will query the messageTag table
      let messageTagResponse = await API.graphql(
        graphqlOperation(queries.getIntroMessageTag, {
          payload: JSON.stringify({ messageId: messageId_array }),
        })
      );

      let item_array = messageTagResponse.data.getIntroMessageTag;
      //console.log("getIntroMessageTag result", item_array);
      if (item_array.includes('Items')) {
        item_array = JSON.parse(item_array);

        const newStatusMessageOverrides = Object.assign(
          {},
          statusMessageOverrides
        );
        item_array.forEach((item, item_index) => {
          console.log(
            `getIntroMessageTag response array item ${item_index}: `,
            item
          );
          /* here is where we override the "Pending" status message */
          let newStatusMessage = 'Sent Message';

          if (item['Items'].length > 0) {
            item['Items'].forEach(object => {
              if (object.tag.includes('error')) {
                newStatusMessage = 'Failed: ' + object.tag;
              }
            });
          }

          newStatusMessageOverrides[
            messageId_array[item_index]
          ] = newStatusMessage;
        });

        setStatusMessageOverrides(newStatusMessageOverrides);

        if (rebuildAccordion) {
          if (Object.keys(newStatusMessageOverrides).length > 0) {
            let tempNumsDataWithOverrides = JSON.parse(
              JSON.stringify(props.newNumsData)
            ).map(object => {
              if (
                object.messageId !== false &&
                typeof newStatusMessageOverrides[object.messageId] !==
                  'undefined'
              ) {
                object.status = newStatusMessageOverrides[object.messageId];
                return object;
              }
            });

            setNewNumsDataWithOverrides(tempNumsDataWithOverrides);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (rebuildAccordion) {
      parseMessageIds(statusMessageOverrides, true);
      setRebuildAccordion(false);
    }
  }, [rebuildAccordion]);

  useEffect(() => {
    dispatchGlobal({
      type: 'setIncludeFlow',
      includeFlow: '',
    });
  }, []);

  /*useEffect(() => {
    console.log("!!! include flow is", includeFlow);
  }, [includeFlow]);*/

  const formData = [
    {
      component: (
        <Textarea
          name="text"
          form="true"
          charLimit="160"
          isSMS
          clearValue={clearValue}
          required={false}
          includeFlow={true}
        />
      ),
    },
    {
      title: '',
      required: false,
      component: (
        <div style={{ fontSize: '0.875rem' }}>
          <FlowSelect
            form
            menuPlacement="top"
            name="flow"
            noDefaultState={true}
          />
        </div>
      ),
    },
  ];

  const onSubmit = async (data, e) => {
    if (data.type !== 'submit' && !loadingFormSubmission) {
      dispatchGlobal({
        type: 'setLoadingFormSubmission',
        loadingFormSubmission: true,
      });

      let payload = {
        campaignId: props.id ? props.id : props.uploadId,
        message: data.text,
      };

      if (data.flow && data.includeFlow) {
        payload.flow = JSON.stringify({
          flow: data.flow,
          flowVersion: data.flowVersion,
        });
      }

      let resp = await API.graphql(
        graphqlOperation(queries.sendIntroMessage, payload)
      );

      let sendMins = Math.ceil(parseInt(resp.data.sendIntroMessage) / 4) + 2;

      let updPay = {
        id: props.id ? props.id : props.uploadId,
        isSendingIntroMessage: true,
        estSendMinutes: sendMins,
        smsButtonPresser: tokens.asurite,
        smsButtonMsg: data.text,
      };

      await API.graphql(
        graphqlOperation(mutations.updateCampaign, { input: updPay })
      );

      dispatchGlobal({
        type: 'updateEditInfo',
        editInfo: {
          ...editInfo,
          isSendingIntroMessage: true,
          estSendMinutes: sendMins,
        },
      });

      setRebuildAccordion(true);

      dispatchGlobal({
        type: 'setLoadingFormSubmission',
        loadingFormSubmission: false,
      });
      setClearValue(new Date().getTime());
    }
  };

  const reset = async () => {
    if (tokens.asurite === 'kcoblent') {
      let updPay = {
        id: props.id ? props.id : props.uploadId,
        isSendingIntroMessage: false,
        estSendMinutes: 0,
      };

      await API.graphql(
        graphqlOperation(mutations.updateCampaign, { input: updPay })
      );

      dispatchGlobal({
        type: 'updateEditInfo',
        editInfo: { ...editInfo, isSendingIntroMessage: false },
      });
    }
  };

  useEffect(() => {
    let keys = [
      'user',
      'displayName',
      'isValid',
      'newUser',
      'type',
      'timestamp',
      'alreadySent',
      'messageId',
      'originalValue',
    ];

    let data = [keys];
    let fullarry = [];

    for (var key in props.summaryData) {
      if (key !== 'sentNums') {
        if (props.summaryData[key].length > 0) {
          //convert timestamps using moment
          let csvRowWithFormattedTimestamp = props.summaryData[key].map(
            csvRowAsObject => {
              if (
                typeof csvRowAsObject['timestamp'] !== 'undefined' &&
                /[0-9]{9,13}/.test(csvRowAsObject['timestamp'])
              ) {
                csvRowAsObject['timestamp'] = moment
                  .unix(csvRowAsObject['timestamp'] / 1000)
                  .tz('America/Phoenix')
                  .format('MM/DD/YYYY HH:mm:ss');
              }

              csvRowAsObject['isValid'] = csvRowAsObject['isValid']
                ? true
                : false;

              return csvRowAsObject;
            }
          );

          fullarry = fullarry.concat(csvRowWithFormattedTimestamp);
        } else {
          fullarry = fullarry.concat(props.summaryData[key]);
        }
      }
    }

    for (var i = 0; i < fullarry.length; ++i) {
      let tArr = [];
      for (var k = 0; k < keys.length; ++k) {
        tArr.push(fullarry[i][keys[k]]);
      }
      data.push(tArr);
    }

    setCsvData(data);

    if (statusUpdated === false) {
      parseMessageIds(statusMessageOverrides);
    }

    /* Build Accordion */
    //console.log("newNumbers",props.newNumsData);
    const summaryContent = (
      <>
        <H6 additionalClasses="summaryTitle">
          {`New Numbers (${props.newNumsData.length})`}
        </H6>
      </>
    );

    const detailsContent = (
      <div className="detailsContent detailsContent--max-width">
        <div style={{ width: '100%' }}>
          <div style={{ width: '100%' }}>
            {props.isSendingIntroMessage ? (
              <div onClick={() => reset()}>
                Intro message is currently sending. Estimated time:{' '}
                {props.estSendMinutes} minutes
              </div>
            ) : (
              <Form
                data={formData}
                onSubmit={onSubmit}
                text={'SEND NOW'}
                width={'100%'}
                style={{ width: '100%' }}
              />
            )}
          </div>

          <div style={{ width: '100%', marginTop: '50px' }}>
            <MiniTable
              tableData={
                newNumsDataWithOverrides.length > 0
                  ? newNumsDataWithOverrides
                  : props.newNumsData
              }
              columnTitles={['Phone', 'Display Name', 'Import Date', 'Status']}
              dataKeys={['phone', 'displayName', 'date', 'status']}
            />
          </div>
        </div>
      </div>
    );

    setAccordionLayout(
      <Accordion
        summaryContent={summaryContent}
        detailsContent={detailsContent}
      />
    );
  }, [
    props.summaryData,
    newNumsDataWithOverrides,
    props.isSendingIntroMessage,
  ]);

  const newUsersMsg = (
    <div>
      <div>Please note: if new numbers are imported,</div>
      <div>
        the campaign cannot be published until an intro message is sent.
      </div>
    </div>
  );

  const needsToProcessMsg = (
    <div>
      <div>File needs processing</div>
      <div>
        Please save the campaign, the file will be queued for processing. You
        will recieve an email when processing is complete.
      </div>
    </div>
  );

  useEffect(() => {
    let allData = [];
    const { found, errors, newNums, sentNums } = props.summaryData;

    console.log('props.summaryData', props.summaryData);

    if (found !== undefined) {
      allData = allData.concat(found);
    }
    if (errors !== undefined) {
      allData = allData.concat(errors);
    }
    if (newNums !== undefined) {
      allData = allData.concat(newNums);
    }
    if (sentNums !== undefined) {
      allData = allData.concat(sentNums);
    }

    let foundNums = [];

    for (var i = allData.length - 1; i >= 0; --i) {
      let id = allData[i].displayName + '_' + allData[i].originalValue;
      if (foundNums.indexOf(id) === -1) {
        foundNums.push(id);
      } else {
        allData.splice(i, 1);
      }
    }

    console.log('allData', allData);

    if (allData.length > 0) {
      let allUsers = displayFoundUsers(allData);
      console.log('allUsers', allUsers);
      allUsers.then(function(result) {
        setfoundUsers(result);
      });
    }
  }, [props.summaryData]);

  const getUserData = async phoneNumber => {
    let resp = await API.graphql(
      graphqlOperation(queries.searchMessages, {
        operation: 'queryForUsers',
        payload: JSON.stringify({
          phoneNumber: phoneNumber,
          botType: 'sunny',
        }),
      })
    );
    return resp;
  };

  const displayFoundUsers = async foundData => {
    let csvData = [];

    console.log('FOUND DATA: ', foundData);

    if (
      typeof foundData !== 'undefined' &&
      foundData !== null &&
      foundData.length > 0
    ) {
      for (const data of foundData) {
        let validity = <HighlightOffIcon style={{ color: 'red' }} />;
        if (data.isValid == 'TRUE' || data.isValid) validity = <CheckIcon />;
        let dateString = moment(data.timestamp).format('MM/DD/YYYY HH:mm:ss');
        let userValue = data.originalValue;
        let phoneNumber = data.user;

        let optOutStatus = '';

        // pre 8/4 we didn't save displayName
        if (moment(data.timestamp) < moment('2021/08/04')) {
          let result = await getUserData(phoneNumber);
          let user = JSON.parse(result.data.searchMessages)[0];
          userValue =
            typeof user !== undefined &&
            user != null &&
            user.displayName != null
              ? user.displayName
              : userValue;
          if (typeof user !== 'undefined') {
            optOutStatus =
              user.userOptOut === true
                ? 'User'
                : user.optOut === true
                ? 'Agent'
                : '';
          }
        }

        let csvRow = {
          user: data.displayName ? data.displayName : userValue,
          sendValue: data.user,
          dateTime: dateString,
          optOutStatus: data.optOutStatus,
          isValid: validity,
        };

        csvData.push(csvRow);
      }
    }
    return csvData;
  };

  return (
    <div>
      {(props.canPublish === false || props.showBlueBanner === true) &&
      props.fileName &&
      !props.showProcessingMessage ? (
        <div
          style={{
            display: 'flex',
            width: '100%',
            backgroundColor: '#ffc626',
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '1rem',
            fontSize: '12px',
            fontWeight: 'bold',
            marginBottom: '30px',
          }}
        >
          {' '}
          {newUsersMsg}
        </div>
      ) : null}

      {props.showProcessingMessage === true ? (
        <div
          style={{
            display: 'flex',
            width: '100%',
            backgroundColor: '#cb0345',
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '1rem',
            fontSize: '12px',
            fontWeight: 'bold',
            marginBottom: '30px',
            color: 'white',
          }}
        >
          {' '}
          {needsToProcessMsg}
        </div>
      ) : null}

      {typeof props.summaryData.awaitingImport !== 'undefined' &&
      props.summaryData.awaitingImport === true &&
      !props.showProcessingMessage ? (
        <div>
          <div className="campaignAnalyticsOverview">
            <div className="CAO-Row">
              <div className="CAO-Item">Awaiting Import</div>
            </div>
          </div>
        </div>
      ) : !props.showProcessingMessage ? (
        <div style={{ position: 'relative' }}>
          <div
            style={{
              position: 'absolute',
              top: '0px',
              right: '1.2em',
              zIndex: '2',
              fontSize: '1.15em',
              fontWeight: 'bold',
            }}
          >
            <PanelButton
              type="csv"
              csvData={csvData}
              csvFileName={`chatbot-importSummary-${moment().format()}.csv`}
              label="Export Data"
            />
          </div>

          <div className="panelSection__title">
            File
            <div className="campaignAnalyticsOverview importSummaryFile">
              <div className="CAO-Row--Large panelHeaderText">
                <div className="CAO-Item panelHeaderText">file name:</div>
                <div className="CAO-Item CAO-Item--Large CAO-Item--right panelHeaderText">
                  {props.fileName}
                </div>
              </div>
            </div>
          </div>
          {props.summaryData && (
            <div
              className="panelSection__title"
              style={{ marginTop: '1em', marginBottom: '1em' }}
            >
              Contacts
              <div className="campaignAnalyticsOverview">
                <div className="CAO-Row">
                  <div className="CAO-Item">found:</div>
                  <div className="CAO-Item CAO-Item--right">
                    {props.summaryData.found
                      ? props.summaryData.found.length
                      : 0}
                  </div>
                </div>
                <div className="CAO-Row">
                  <div className="CAO-Item">errors:</div>
                  <div className="CAO-Item CAO-Item--right">
                    {props.summaryData.errors
                      ? props.summaryData.errors.length
                      : 0}
                  </div>
                </div>
                <div className="CAO-Row">
                  <div className="CAO-Item">new numbers:</div>
                  <div className="CAO-Item CAO-Item--right">
                    {props.summaryData.newNums
                      ? props.summaryData.newNums.length
                      : 0}
                  </div>
                </div>
              </div>
            </div>
          )}

          {editInfo.introFlowId && editInfo.introFlowVersion && (
            <>
              <strong>Intro Flow Sent:</strong>{' '}
              {JSON.parse(editInfo.introFlowId).label +
                ' - ' +
                JSON.parse(editInfo.introFlowVersion).label}
            </>
          )}
          {
            <div className="panelHeader">
              <div
                className={
                  includeFlow === 'yes'
                    ? 'campaignAnalyticsResponses includeFlowSwitch'
                    : 'campaignAnalyticsResponses excludeFlowSwitch'
                }
                style={{ marginTop: '15px' }}
              >
                {accordionLayout}
              </div>
            </div>
          }

          {foundUsers.length > 0 ? (
            <MiniTable
              tableData={foundUsers}
              columnTitles={[
                'User',
                'Phone Number',
                'Import Date',
                'Opt Out Status',
                'Valid',
              ]}
              dataKeys={[
                'user',
                'sendValue',
                'dateTime',
                'optOutStatus',
                'isValid',
              ]}
            />
          ) : null}
        </div>
      ) : null}
    </div>
  );
}
