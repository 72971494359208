import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { scroller as scroll, Events } from 'react-scroll';
import { MiniTable, Modal, PanelButton, Accordion, Button } from 'stories';
import { useModal } from 'hooks';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import CircularProgress from '@material-ui/core/CircularProgress';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckIcon from '@material-ui/icons/Check';
import { store as GlobalStore } from 'contexts/GlobalStore';
import Tooltip from '@material-ui/core/Tooltip';

import '../../Dialogues.scss';

import * as tagHelper from '../../Helpers/tagHelper.js';

import TableHelper from './TableHelper.js';

import {
  queryWithAthena,
  escHistoryByConvoId,
  compCampByUserId,
} from 'graphql/queries';

import { API, graphqlOperation } from 'aws-amplify';

export default function EscalationHistory(props) {
  const GlobalState = useContext(GlobalStore);
  const dispatchGlobal = GlobalState.dispatch;

  const [escalations, setEscalations] = useState([]);
  const [loading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setEscalations([]);
    queryForEscHistory();
  }, [props.convo]);

  let keys = [];

  let csvKeys = {
    user: 'User',
    escalationName: 'Escalation Name',
    escalationType: 'Escalation Type',
    message: 'Message',
    response: 'Response',
    hitType: 'Escalated From',
    escalator: 'Escalated By',
    emailBody: 'Email Body',
    emailSubject: 'Email Subject',
    emailTo: 'Email To',
    ccemail: 'CC Emails',
    'textMessageContent.to': 'SMS To',
    'textMessageContent.textBody': 'SMS Content',
    timestamp: 'Date/Time',
  };
  let tableKeys = {};

  const queryForEscHistory = async () => {
    let queryRes = await API.graphql(
      graphqlOperation(escHistoryByConvoId, { convoId: props.convo.convoId })
    );

    let escs = queryRes.data.escHistoryByConvoId.items.sort(
      (a, b) => parseInt(b.timestamp) - parseInt(a.timestamp)
    );

    setEscalations(escs);
    setIsLoading(false);
  };

  const _renderEsc = esc => {
    const summaryContent = (
      <div className="AccordionSummary">
        <div className="opportunity-collapsable-name">{esc.escalationName}</div>
        <div className="simpleEllipsis">
          {props.dateFormatter(parseInt(esc.timestamp), true)}
        </div>
      </div>
    );

    let keyMapping = [
      {
        human: 'Escalated From',
        value: esc.hitType,
      },
      {
        human: 'Type',
        value: esc.escalationType,
      },
    ];

    if (esc.hitType === 'Conversation') {
      keyMapping.push({
        human: 'Escalated By',
        value: props.getAssigneeName(esc.escalator),
      });
    } else if (esc.hitType === 'Message') {
      keyMapping = keyMapping.concat([
        {
          human: 'Escalated By',
          value: props.getAssigneeName(esc.escalator),
        },
        {
          human: 'Message',
          value: esc.message,
        },
      ]);
    } else {
      keyMapping = keyMapping.concat([
        {
          human: 'Message',
          value: esc.message,
        },
        {
          human: 'Response',
          value: esc.response,
        },
      ]);
    }

    if (esc.escalationType === 'Email') {
      keyMapping = keyMapping.concat([
        {
          human: 'Email to',
          value:
            esc.emailTo +
            (esc.ccemail ? ', ' + esc.ccemail.replace(/\;/g, ', ') : ''),
        },
        {
          human: 'SMS',
          value:
            esc.textMessageContent && esc.textMessageContent.to
              ? esc.textMessageContent.to
              : 'NONE',
        },
      ]);
    }

    const getTooltip = (type, name) => {
      if (type === 'Escalated From') {
        switch (name) {
          case 'Conversation':
            return "Was manually escalated through 'Actions' in Dialogue Properties";
          case 'Message':
            return 'Was manually escalated through message dropdown.';
          case 'FAQ':
            return 'User hit a FAQ that contained an escalation';
          case 'Alert':
            return 'User hit an alert';
          case 'Flow':
            return 'User hit a trigger inside a flow either in a campaign or FAQ';
        }
      }
      return null;
    };

    const detailsContent = (
      <div>
        {keyMapping.map((km, index) => {
          if (km.value) {
            return (
              <div
                className="opportunity-collapsable-row"
                style={{ flexDirection: 'row' }}
              >
                <div className="opportunity-collapsable-name">
                  {km.human + ':'}
                </div>{' '}
                {getTooltip(km.human, km.value) ? (
                  <Tooltip title={getTooltip(km.human, km.value)} arrow>
                    <div className="opportunity-collapsable-content">
                      {km.value}
                    </div>
                  </Tooltip>
                ) : (
                  <div className="opportunity-collapsable-content">
                    {km.value}
                  </div>
                )}
              </div>
            );
          } else {
            return null;
          }
        })}
        {esc.messageId ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              save={true}
              text="Jump to Message"
              onClick={() => props.handleScrollToMessage(esc)}
              style={{
                width: '200px',
                margin: '20px',
              }}
            />
          </div>
        ) : null}
      </div>
    );

    return (
      <Accordion
        summaryContent={summaryContent}
        expandedSummaryContent={summaryContent}
        detailsContent={detailsContent}
      />
    );
  };

  return (
    <TableHelper
      rawData={escalations}
      title={'Escalation History'}
      tableKeys={tableKeys}
      csvKeys={csvKeys}
      convo={props.convo}
      dateFormatter={props.dateFormatter}
      loading={loading}
      nonTableData={escalations.map((item, index) => {
        return _renderEsc(item);
      })}
    />
  );
}
