import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./main/App";
import * as serviceWorker from "./serviceWorker";
import Weblogin from "./main/auth/Weblogin";
import { StateProvider as KBStateProvider } from "./contexts/KBStore";
import { StateProvider as GlobalStateProvider } from "./contexts/GlobalStore";
import { ThemeProvider } from "@material-ui/styles";

import { theme } from "./stories";

console.log("HITTING INDJEX MAIN",window.location.pathname)

ReactDOM.render(
  <BrowserRouter>
    <Weblogin>
      <ThemeProvider theme={theme}>
        <GlobalStateProvider>
          <KBStateProvider>
            <App requestedPath={window.location.pathname}/>
          </KBStateProvider>
        </GlobalStateProvider>
      </ThemeProvider>
    </Weblogin>
  </BrowserRouter>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
