import React from "react";
import { makeStyles } from "@material-ui/core/styles";

export default function InputLabel(props) {
  const useStyles = makeStyles((theme) => ({
    required: {
      color: theme.palette.general.errorColor,
      marginRight: "0.25rem",
    },
    header: {
      ...theme.typography.subtitle2,
      margin: "1.5rem 0 0.5rem 0",
      fontWeight: "bold",
    },
  }));
  const classes = useStyles();

  return (
    <div {...props} className={classes.header} style={{ display: "flex" }}>
      {props.required && <div className={classes.required}>*</div>}
      {props.title}
    </div>
  );
}
