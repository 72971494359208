import React from "react";
import Textarea from "./Textarea";
import TextareaForm from "./TextareaForm";

export default function TextareaX(props) {

  return <TextareaForm {...props} />;

  // Can do a check on if it's a form to set the ref,
  // Shouldn't need multiple components

  // if (props.form) {
  //   return <TextareaForm {...props} />;
  // } else {
  //   return <Textarea {...props} />;
  // }
}
