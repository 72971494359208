import React, { useState } from "react";

import "./AlertBanner.css";

export default function AlertBanner(props) {
  return (
    <div
      className={
        props.userWaiting === true
          ? "AlertBanner AlertBanner--userWaiting"
          : "AlertBanner"
      }
    >
      <div className="AlertBanner__messageWrapper">
        <div className="AlertBanner__messageContent AlertBanner__messageContent--bold">
          {props.messageBold ? props.messageBold : ""}
        </div>
        <div className="AlertBanner__messageContent">
          {props.message ? props.message : ""}
        </div>
      </div>
      <div
        className="AlertBanner__button AlertBanner__button--inverted"
        onClick={() => {
          props.actionFunction(props.convoId);
        }}
      >
        {props.actionButtonMessage
          ? props.actionButtonMessage
          : "Click to join"}
      </div>
      <div className="AlertBanner__button" onClick={props.userDismissFunction}>
        Exit
      </div>
    </div>
  );
}
