function getAsurite() {
  const tokens = JSON.parse(localStorage.getItem("usertokens"));
  return tokens.asurite;
}

export const filterConvos = (
  listFilter,
  convoFilter,
  folderFilter,
  filteredConvos,
  rawConvos,
  allGroups,
  myReviewTags,
  topButtons
) => {
  let newConvos = {
    inbox: [],
    review: [],
    assisted: [],
    all: [],
    sr: filteredConvos.sr,
  };

  let asurite = getAsurite();

  console.log("folder filter ********* ", folderFilter);
  console.log("convo filter ********* ", convoFilter);
  console.log("list filter ********* ", listFilter);

  for (var i = 0; i < rawConvos.length; ++i) {
    let convo = rawConvos[i];
    let passFilter = filterConvosByTag(convo, convoFilter, asurite);
    let passTriage = checkFilter(convo, listFilter);
    let passFolder = checkFilter(convo, folderFilter);

    if (passFilter && passTriage && passFolder) {
      for (var j = 0; j < topButtons.length; ++j) {
        let x = topButtons[j];

        let passSub = passSubFilter(
          listFilter,
          convo,
          x.filter,
          allGroups,
          myReviewTags
        );
        if (passSub) {
          newConvos[x.id].push(convo);
        }
      }
    }
  }

  // console.log("DONE: ", newConvos);
  return newConvos;
};

export const passSubFilter = (
  listFilter,
  convo,
  xFilter,
  allGroups,
  myReviewTags
) => {
  if (xFilter) {
    let ender = "";

    if (listFilter) {
      ender += " " + listFilter.split(" ")[1];
    } else {
      ender += " true";
    }

    let didPass = checkFilters(
      convo,
      listFilter,
      xFilter + (ender ? ender : null),
      allGroups,
      myReviewTags
    );

    return didPass;
  } else {
    return true;
  }
};

export const checkFilter = (el, filter) => {
  let passFilter = false;

  if (!filter || (el.tags && el.tags.indexOf(filter) > -1)) {
    passFilter = true;
  }

  return passFilter;
};

export const fillConvosForSearch = (
  rawConvos,
  inboxSearch,
  inboxSearchIgnoreCharMin,
  inboxSearchKeywordMatches
) => {
  let newObj = [];
  for (var i = 0; i < rawConvos.length; ++i) {
    let passSearch = searchResults(
      rawConvos[i],
      inboxSearchIgnoreCharMin,
      inboxSearchKeywordMatches,
      inboxSearch
    );
    if (passSearch) {
      newObj.push(rawConvos[i]);
    }
  }
  return newObj;
};

export const searchResults = (
  el,
  inboxSearchIgnoreCharMin,
  inboxSearchKeywordMatches,
  inboxSearch
) => {
  let foundMatch = false;

  let inboxSearch_alphanumeric = inboxSearch.replace(
    /[,.[]\(\)*!@#$%&"'{}?-+^`|\/\\]/g,
    ""
  );

  let inboxSearchTerms = inboxSearch_alphanumeric.split(" ");

  inboxSearchTerms.forEach((term) => {
    if (inboxSearchIgnoreCharMin || term.length > 3) {
      if (el.asurite && el.asurite.includes(term)) {
        foundMatch = true;
      }
      if (!foundMatch && el.phoneNumber && el.phoneNumber.includes(term)) {
        foundMatch = true;
      }
      if (!foundMatch && el.firstName && el.firstName.includes(term)) {
        foundMatch = true;
      }
      if (!foundMatch && el.lastName && el.lastName.includes(term)) {
        foundMatch = true;
      }
    }
  });

  if (
    Array.isArray(inboxSearchKeywordMatches) &&
    inboxSearchKeywordMatches.includes(el.convoId)
  ) {
    foundMatch = true;
  }

  // console.log(el, foundMatch, inboxSearchKeywordMatches);

  return foundMatch;
};

export const checkFilters = (el, filter1, filter2, allGroups, myReviewTags) => {
  let passFilter1 = false;
  let passFilter2 = false;
  if (!filter1 || (el.tags && el.tags.indexOf(filter1) > -1)) {
    passFilter1 = true;
  }

  let groupAssigned;

  if (!filter2) {
    passFilter2 = true;
  } else {
    if (
      filter1 &&
      filter1.indexOf("agentAssigned") > -1 &&
      filter2.indexOf("needsReview") > -1
    ) {
      let filteredArray = [];
      if (el.tags) {
        filteredArray = el.tags.filter((value) => myReviewTags.includes(value));
      }
      passFilter2 = filteredArray.length > 0;
    } else if (el.tags && el.tags.indexOf(filter2) > -1) {
      passFilter2 = true;
    }
  }

  return passFilter2;
};

export const filterConvosByTag = (convo, tagString, asurite) => {
  let filteredConvos = [];

  if (!tagString) {
    return true;
  } else if (tagString === "All") {
    return true;
  } else if (tagString === "needsReview true") {
    if (!convo.tags) {
      return false;
    } else {
      for (let tag of convo.tags) {
        if (tag === tagString) {
          return true;
        }
      }
      return false;
    }
  } else if (tagString === "unread" || tagString === "read") {
    if (!convo.tags) {
      return false;
    } else {
      let isUnread = false;
      for (let tag of convo.tags) {
        if (
          tag.includes("unseenBy") &&
          (tag.includes("true") || tag.includes(asurite))
        ) {
          isUnread = true;
        }
      }
      return tagString === "unread" ? isUnread : !isUnread;
    }
  } else if (tagString === "hasAlert") {
    return convo.didError;
  } else {
    if (!convo.tags) {
      return false;
    } else {
      for (let tag of convo.tags) {
        if (tag.split(" ").includes(tagString)) {
          return true;
        }
      }
      return false;
    }
  }

  return false;
};
