import React, { useState, useEffect, useContext, useRef } from 'react';

import GetAppIcon from '@material-ui/icons/GetApp';
import CloseIcon from '@material-ui/icons/Close';

import { useTheme, withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Radio from '@material-ui/core/Radio';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import moment from 'moment-timezone';

import { API, graphqlOperation } from 'aws-amplify';
import {
  usersByType,
  analyticsByAsurite,
  analyticsByPhoneNumber,
  convoTagByConvoId,
  byStatusNumber,
  analyticsByEvent,
  chatbotUserByAsurite,
  chatbotUserByPhone,
} from 'graphql/queries';
import AuthContext from 'contexts/AuthContext';

import { DateTime, Table, Button, PanelButton } from 'stories';

import Select from 'react-select';

import './TriageQueue.scss';
import '../Dialogues.scss';
import './EDModal.scss';

import { getUserGroups } from 'hooks';

const OPT_OUT_EVENTS = ['userOptOut', 'agentOptOut']; // "userOptIn","agentOptIn"
const PAUSED_EVENTS = ['paused']; // "unpaused"
const isPhoneNumber = /^\+?[0-9]{11}/;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const PUBLISHED = 4;
const SENDING = 7;
const ACTIVE = 8;
const FINISHED = 9;
const PAUSED = 14;

const Slider = withStyles(theme => ({
  root: {
    width: '2.7em',
    height: '1.4em',
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(0.8em)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#1988e6',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#1988e6',
      border: '6px solid #fff',
      // border: 'none',
    },
  },
  thumb: {
    marginTop: '0.05em',
    marginLeft: 2,
    width: '0.7em',
    height: '0.7em',
    borderRadius: '50%',
  },
  track: {
    borderRadius: 23 / 2,
    // border: `1px solid ${theme.palette.grey[400]}`,
    border: `none`,
    // backgroundColor: theme.palette.grey[50],
    backgroundColor: '#D6D5D4',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const customStyles = {
  // to access width/height, add either as a prop to the component
  control: (_, { selectProps: { width, height } }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.5rem',
    backgroundColor: 'white',
    border: '1px solid #8d8d8d',
    borderRadius: '4px',
    cursor: 'pointer',
  }),
  menu: (provided, state) => ({
    ...provided,
    width: '100%',
    margin: '0px',
    borderTopLeftRadius: '0px',
    borderTopRightRadius: '0px',
    border: '1px solid #8d8d8d',
    borderTop: '0px',
    zIndex: 10000,
  }),
  menuList: (provided, state) => ({
    ...provided,
    height: '16em',
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    backgroundColor: '#fff',
    color: '#3B4252',
    '&:active': {
      backgroundColor: '#d5d5d5',
    },
  }),
  dropdownIndicator: () => {
    if (false) {
      return {
        display: 'none',
      };
    } else {
      return {
        color: '#8d8d8d',
        marginRight: '0.5rem',
      };
    }
  },
  indicatorSeparator: () => ({
    display: 'none',
  }),
  placeholder: () => ({
    color: '#3B4252',
  }),
};

const event__exportData = e => {
  const mouseClickEvents = ['mousedown', 'click', 'mouseup'];
  function simulateMouseClick(element) {
    mouseClickEvents.forEach(mouseEventType =>
      element.dispatchEvent(
        new MouseEvent(mouseEventType, {
          view: window,
          bubbles: true,
          cancelable: true,
          buttons: 1,
        })
      )
    );
  }

  var element = document.getElementById('exportDialoguesDataButton')
    .firstElementChild;
  simulateMouseClick(element);
};

export default function ExportDataSection(props) {
  const [modalState, setModalState] = useState(false);
  const [exportData, setExportData] = useState([]);
  const [isExportingData, setIsExportingData] = useState(false);

  let baseCurrentTime = new Date();

  let actualBaseStartDate = new Date(
    baseCurrentTime.getFullYear(),
    baseCurrentTime.getMonth(),
    baseCurrentTime.getDate(),
    0,
    0
  );
  let actualBaseEndDate = new Date(
    baseCurrentTime.getFullYear(),
    baseCurrentTime.getMonth(),
    baseCurrentTime.getDate(),
    23,
    59
  );

  const [optedOutToggleState, setOptedOutToggleState] = useState(true);
  const [optedOutStartDate, setOptedOutStartDate] = useState(
    actualBaseStartDate
  );
  const [optedOutEndDate, setOptedOutEndDate] = useState(actualBaseEndDate);

  const [pauseToggleState, setPauseToggleState] = useState(true);
  const [pauseStartDate, setPauseStartDate] = useState(actualBaseStartDate);
  const [pauseEndDate, setPauseEndDate] = useState(actualBaseEndDate);

  const [radioState, setRadioState] = useState(0);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [campaignList, setCampaignList] = useState([]);
  const [allCamps, setAllCamps] = useState([]);
  const [campsById, setCampsById] = useState([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [openError, setOpenError] = useState(false);

  const [exportButtonState, setExportButtonState] = useState('');

  const { keyedNames, selectListGroups, getGroup } = getUserGroups();

  const ed_modal_ref = useRef(null);

  const tokens = useContext(AuthContext);
  const theme = useTheme();

  useEffect(() => {
    setIsExportingData(false);
  }, [exportData]);

  const queryCamp = (status, all = [], token = null, retries = 0) => {
    return new Promise(async function tryagain(resolve, reject) {
      let runPay = {
        limit: 1000,
        status: status,
        botType: 'sunny',
      };

      if (token) {
        runPay.nextToken = token;
      }

      let currRun = await API.graphql(graphqlOperation(byStatusNumber, runPay));

      let resp = currRun.data.byStatusNumber;
      all = all.concat(resp.items);

      if (resp.nextToken && ++retries < 5) {
        token = resp.nextToken;
        tryagain(resolve, reject);
      } else {
        resolve(all);
      }
    });
  };

  const populateCampaigns = async () => {
    var allCampaigns = [];

    let [
      getPublishedCampaigns,
      getSendingCampaigns,
      getActiveCampaigns,
      getFinishedCampaigns,
      getPausedCampaigns,
    ] = await Promise.all([
      await queryCamp(PUBLISHED),
      await queryCamp(SENDING),
      await queryCamp(ACTIVE),
      await queryCamp(FINISHED),
      await queryCamp(PAUSED),
    ]);

    /* Published 4 */
    if (getPublishedCampaigns.length > 0) {
      allCampaigns = allCampaigns.concat(getPublishedCampaigns);
    }

    /* Sending 7 */
    if (getSendingCampaigns.length > 0) {
      allCampaigns = allCampaigns.concat(getSendingCampaigns);
    }

    /* Active 8 */
    if (getActiveCampaigns.length > 0) {
      allCampaigns = allCampaigns.concat(getActiveCampaigns);
    }

    /* Finished 9 */
    if (getFinishedCampaigns.length > 0) {
      allCampaigns = allCampaigns.concat(getFinishedCampaigns);
    }

    /* Paused 14 */
    if (getPausedCampaigns.length > 0) {
      allCampaigns = allCampaigns.concat(getPausedCampaigns);
    }

    //console.log("===", allCampaigns);
    let arrCamps = [];
    let idCamps = {};

    for (var i = 0; i < allCampaigns.length; ++i) {
      let camp = allCampaigns[i];
      arrCamps.push({ label: camp.title, value: camp.id, group: camp.group });
      idCamps[camp.id] = camp.title;
    }

    arrCamps = arrCamps.sort(function(a, b) {
      if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
      if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
      return 0;
    });

    setCampaignList(arrCamps);
    setAllCamps(arrCamps);
    setCampsById(idCamps);
  };

  useEffect(() => {
    if (modalState && campaignList.length === 0) {
      populateCampaigns();
    }
  }, [modalState]);

  useEffect(() => {
    let newCamps = [];

    for (var i = 0; i < allCamps.length; ++i) {
      if (selectedGroups.indexOf(allCamps[i].group) > -1) {
        newCamps.push(allCamps[i]);
      }
    }

    setCampaignList(newCamps);
  }, [selectedGroups]);

  const handleGroupSelect = e => {
    if (e === null) {
      setSelectedGroups([]);
    } else {
      e = e.map(object => {
        return object.value;
      });
      setSelectedGroups(e);
    }
  };

  const handleFilterUpdate = (newDate, state) => {
    let actualStartDate = new Date(
      newDate.getFullYear(),
      newDate.getMonth(),
      newDate.getDate(),
      0,
      0
    );
    let actualEndDate = new Date(
      newDate.getFullYear(),
      newDate.getMonth(),
      newDate.getDate(),
      23,
      59
    );
    if (state === 'optedOutStartDate') {
      setOptedOutStartDate(actualStartDate);
    } else if (state === 'optedOutEndDate') {
      setOptedOutEndDate(actualEndDate);
    } else if (state === 'pauseStartDate') {
      setPauseStartDate(actualStartDate);
    } else if (state === 'pauseEndDate') {
      setPauseEndDate(actualEndDate);
    }
  };

  const handleCampaignSelect = e => {
    if (e === null) {
      setSelectedCampaigns([]);
    } else {
      e = e.map(object => {
        return object.value;
      });
      setSelectedCampaigns(e);
    }
  };

  const queryAnalytics = async (actionEvent, min, max) => {
    let resp = await API.graphql(
      graphqlOperation(analyticsByEvent, {
        event: actionEvent,
        timestamp: {
          between: [min, max],
        },
        options: {
          fetchPolicy: 'no-cache',
        },
      })
    );
    return resp.data.analyticsByEvent.items;
  };

  const getBasedOnEvents = async (userEvents, min, max) => {
    let optProms = [];

    for (var i = 0; i < userEvents.length; ++i) {
      optProms.push(queryAnalytics(userEvents[i], min, max));
    }

    let allIndResps = await Promise.all(optProms);
    let allUsers = [];

    for (var i = 0; i < allIndResps.length; ++i) {
      allUsers = allUsers.concat(allIndResps[i]);
    }

    return allUsers;
  };

  const lib__exportData = async e => {
    setIsExportingData(true);
    let getUserInfoPromises = [];

    if (optedOutToggleState) {
      getUserInfoPromises.push(
        getBasedOnEvents(
          OPT_OUT_EVENTS,
          optedOutStartDate.getTime(),
          optedOutEndDate.getTime()
        )
      );
    } else {
      getUserInfoPromises.push([]);
    }

    if (pauseToggleState) {
      getUserInfoPromises.push(
        getBasedOnEvents(
          PAUSED_EVENTS,
          pauseStartDate.getTime(),
          pauseEndDate.getTime()
        )
      );
    } else {
      getUserInfoPromises.push([]);
    }

    let [optOutData, pausedData] = await Promise.all(getUserInfoPromises);

    if (selectedCampaigns && selectedCampaigns.length) {
      for (var i = pausedData.length - 1; i >= 0; --i) {
        let camps = pausedData[i].campaigns;
        let foundCamp = false;
        for (var j = 0; j < camps.length; ++j) {
          if (selectedCampaigns.indexOf(camps[j].id) > -1) {
            foundCamp = true;
            break;
          }
        }
        if (!foundCamp) {
          pausedData.splice(i, 1);
        }
      }
    }

    let fullUserEvents = optOutData.concat(pausedData);

    let uniqueUsers = [];
    let keyedUsers = {};

    for (var i = 0; i < fullUserEvents.length; ++i) {
      let id =
        fullUserEvents[i].asurite && fullUserEvents[i].asurite !== 'NONE'
          ? fullUserEvents[i].asurite
          : fullUserEvents[i].phoneNumber;

      if (uniqueUsers.indexOf(id) === -1) {
        uniqueUsers.push(id);
        keyedUsers[id] = {
          agentOptOut: false,
          userOptOut: false,
          paused: false,
          optOutTime: null,
          pausedTime: null,
          campaigns: [],
          duringCampaign: 'No',
        };
      }

      keyedUsers[id][fullUserEvents[i].event] = true;
      keyedUsers[id][
        fullUserEvents[i].event.indexOf('OptOut') > -1
          ? 'optOutTime'
          : 'pausedTime'
      ] = fullUserEvents[i].timestamp;
      keyedUsers[id].campaigns = keyedUsers[id].campaigns.concat(
        fullUserEvents[i].campaigns
      );
      keyedUsers[id].duringCampaign = fullUserEvents[i].duringCampaign;
    }

    let userData = await queryForUserData(uniqueUsers);

    let csvData = [];
    for (var user in keyedUsers) {
      let row = {
        firstName: userData[user] ? userData[user].firstName : '',
        lastName: userData[user] ? userData[user].lastName : '',
        contactId: userData[user]
          ? userData[user].asurite && userData[user].asurite !== 'NONE'
            ? userData[user].asurite
            : userData[user].phoneNumber
          : user,
        pauseTimestamp: keyedUsers[user].pausedTime
          ? moment(parseInt(keyedUsers[user].pausedTime)).format(
              'MM/DD/YYYY HH:mm:ss'
            )
          : '',
        optOutTimestamp: keyedUsers[user].optOutTime
          ? moment(parseInt(keyedUsers[user].optOutTime)).format(
              'MM/DD/YYYY HH:mm:ss'
            )
          : '',
        adminDidPause: keyedUsers[user].paused ? 'Yes' : 'No',
        contactDidOptOut: keyedUsers[user].userOptOut ? 'Yes' : 'No',
        adminDidOptOut: keyedUsers[user].agentOptOut ? 'Yes' : 'No',
        campaign: getCamps(keyedUsers[user].campaigns),
        duringCampaign: keyedUsers[user].duringCampaign,
      };
      csvData.push(row);
    }
    setExportData(csvData);

    if (csvData.length === 0) {
      setOpenError(true);
    }
    setTimeout(event__exportData, 300);
  };

  const getCamps = camps => {
    let validCamps = [];

    for (var i = 0; i < camps.length; ++i) {
      if (campsById[camps[i].id]) {
        validCamps.push(campsById[camps[i].id]);
      }
    }

    console.log('VALID CAMPS: ', validCamps);
    return validCamps.toString();
  };

  const queryForUserData = async users => {
    let userInfo = {};
    let userProms = [];

    for (var i = 0; i < users.length; ++i) {
      userProms.push(queryForUser(users[i]));
    }

    let tempUsers = await Promise.all(userProms);
    for (var i = 0; i < tempUsers.length; ++i) {
      if (tempUsers[i].asurite && tempUsers[i].asurite !== 'NONE') {
        userInfo[tempUsers[i].asurite] = tempUsers[i];
      }

      if (tempUsers[i].phoneNumber && tempUsers[i].phoneNumber !== 'NONE') {
        userInfo[tempUsers[i].phoneNumber] = tempUsers[i];
      }
    }
    return userInfo;
  };

  const queryForUser = async user => {
    let query = null;

    let isPhone = isPhoneNumber.test(user);

    if (isPhone) {
      query = chatbotUserByPhone;
    } else {
      query = chatbotUserByAsurite;
    }

    let info = await API.graphql(
      graphqlOperation(query, {
        [isPhone ? 'phoneNumber' : 'asurite']: user,
      })
    );

    let u = {};

    try {
      let key = Object.keys(info.data)[0];

      let items = info.data[key].items;

      for (var i = 0; i < items.length; ++i) {
        u = {
          ...u,
          ...items[i],
        };
      }
    } catch (e) {}

    return u;
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenError(false);
  };

  const lib__buildCSVData = exportData => {
    let csvData = [
      [
        'First Name',
        'Last Name',
        'Contact ID',
        'Pause Timestamp',
        'Opt Out Timestamp',
        'Admin Did Pause',
        'Contact Did Opt Out',
        'Admin Did Opt Out',
        'Campaign',
        'During Campaign',
      ],
    ];

    exportData.forEach(object => {
      csvData.push([
        object.firstName,
        object.lastName,
        object.contactId,
        object.pauseTimestamp,
        object.optOutTimestamp,
        object.adminDidPause,
        object.contactDidOptOut,
        object.adminDidOptOut,
        object.campaign,
        object.duringCampaign,
      ]);
    });

    return csvData;
  };

  if (!modalState) {
    return (
      <>
        <div
          className="grayBottomBorder dg-export-data"
          onClick={e => {
            setModalState(true);
          }}
        >
          <div className="export-data__left">
            <GetAppIcon />
          </div>
          <div className="export-data__right">Export Data</div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="grayBottomBorder dg-export-data">
          <div className="export-data__left">
            <GetAppIcon />
          </div>
          <div className="export-data__right">Export Data</div>
        </div>

        <div className="dg-export-data__modal" ref={ed_modal_ref}>
          <div className="dg-export-data__modal-content">
            <div className="ed-modal__title">
              Export, Pause and Opt-Out Data
            </div>

            <div
              className="ed-modal__close-button"
              onClick={e => {
                setModalState(false);
              }}
            >
              <span className="close-button--circle"></span>
              <CloseIcon />
            </div>

            <div className="ed-modal__gray-bar"></div>

            <div className="ed-modal__section-title">
              Opted Out
              <div className="ed-modal__toggle">
                <Slider
                  checked={optedOutToggleState}
                  onChange={e => setOptedOutToggleState(!optedOutToggleState)}
                />
              </div>
            </div>

            <div className="ed-modal__section-sub-title">
              Define a date range to export user opt-out data.
            </div>

            {optedOutToggleState && (
              <>
                <div class="ed-modal__filter-labels" data-css="overwrite">
                  <div class="ed-modal__label">Start Date:</div>
                  <div class="ed-modal__label">End Date:</div>
                </div>

                <div class="ed-modal__filter-inputs">
                  <div class="ed-modal__input">
                    <DateTime
                      hideTimeSelect
                      onChange={newDate => {
                        handleFilterUpdate(newDate, 'optedOutStartDate');
                      }}
                      name="ed-modal-optedOutStartDate"
                      stateValue={optedOutStartDate}
                      style={{
                        backgroundColor: 'white',
                        fontSize: '0.9em',
                      }}
                    />
                  </div>

                  <div class="ed-modal__input">
                    <DateTime
                      hideTimeSelect
                      onChange={newDate => {
                        handleFilterUpdate(newDate, 'optedOutEndDate');
                      }}
                      name="ed-modal-optedOutEndDate"
                      stateValue={optedOutEndDate}
                      style={{
                        backgroundColor: 'white',
                        fontSize: '0.9em',
                      }}
                    />
                  </div>
                </div>
              </>
            )}

            <div
              className="ed-modal__gray-bar"
              style={{ marginTop: '1.3rem' }}
            ></div>

            <div className="ed-modal__section-title">
              Pause Activity
              <div className="ed-modal__toggle">
                <Slider
                  checked={pauseToggleState}
                  onChange={e => setPauseToggleState(!pauseToggleState)}
                />
              </div>
            </div>

            <div className="ed-modal__section-sub-title">
              Define a date range to export user paused activity data.
            </div>

            {pauseToggleState && (
              <>
                <div class="ed-modal__filter-labels" data-css="overwrite">
                  <div class="ed-modal__label">Start Date:</div>
                  <div class="ed-modal__label">End Date:</div>
                </div>

                <div class="ed-modal__filter-inputs">
                  <div class="ed-modal__input">
                    <DateTime
                      hideTimeSelect
                      onChange={newDate => {
                        handleFilterUpdate(newDate, 'pauseStartDate');
                      }}
                      name="ed-modal-pauseStartDate"
                      stateValue={pauseStartDate}
                      style={{
                        backgroundColor: 'white',
                        fontSize: '0.9em',
                      }}
                    />
                  </div>

                  <div class="ed-modal__input">
                    <DateTime
                      hideTimeSelect
                      onChange={newDate => {
                        handleFilterUpdate(newDate, 'pauseEndDate');
                      }}
                      name="ed-modal-pauseEndDate"
                      stateValue={pauseEndDate}
                      style={{
                        backgroundColor: 'white',
                        fontSize: '0.9em',
                      }}
                    />
                  </div>
                </div>

                <div className="ed-modal__section-title">Group:</div>
                <div
                  className="ed-modal__select"
                  onClick={() => {
                    /*ed_modal_ref.current.scrollTo(0, 5000);*/
                  }}
                >
                  <Select
                    /*classNamePrefix="myCustomPrefix"*/
                    /*value={props.text.toUpperCase()}*/
                    placeholder={'Select Group(s)...'}
                    onChange={handleGroupSelect}
                    options={selectListGroups}
                    isSearchable
                    isMulti
                    styles={customStyles}
                  />
                </div>

                <div className="ed-modal__radio-group" data-css="overwrite">
                  <div className="ed-modal__radio">
                    <Radio
                      color={'gray'}
                      checked={radioState === 0}
                      onChange={e => {
                        setRadioState(0);
                      }}
                    />
                  </div>
                  <div
                    className="ed-modal__radio-text"
                    onClick={e => {
                      setRadioState(0);
                    }}
                  >
                    Export All Campaigns
                  </div>
                </div>

                <div className="ed-modal__radio-group">
                  <div className="ed-modal__radio">
                    <Radio
                      checked={radioState === 1}
                      color={'gray'}
                      onChange={e => {
                        setRadioState(1);
                      }}
                    />
                  </div>
                  <div
                    className="ed-modal__radio-text"
                    onClick={e => {
                      setRadioState(1);
                    }}
                  >
                    Select campaigns to export
                  </div>
                </div>
              </>
            )}

            {pauseToggleState && radioState === 1 && (
              <div className="ed-modal__select" style={{ marginTop: '0.5em' }}>
                <Select
                  /*classNamePrefix="myCustomPrefix"*/
                  /*value={props.text.toUpperCase()}*/
                  placeholder={'Select Campaign(s)...'}
                  onChange={handleCampaignSelect}
                  options={campaignList}
                  isSearchable
                  isMulti
                  styles={customStyles}
                />
              </div>
            )}

            <div
              className="ed-modal__gray-bar"
              style={{ marginTop: '1.3rem' }}
            ></div>

            <div className="ed-modal__footer">
              {isExportingData ? (
                <CircularProgress
                  size={'2rem'}
                  style={{
                    color: theme.palette.general.lightMaroon,
                    marginLeft: '2.5em',
                  }}
                />
              ) : (
                !(!optedOutToggleState && !pauseToggleState) && (
                  <Button
                    text="EXPORT DATA"
                    onClick={lib__exportData}
                    style={{
                      background: '#3F88DF',
                      color: 'white',
                      width: '10em',
                      padding: '0.5em',
                      marginLeft: '2em',
                    }}
                    disabled={exportButtonState === 'disabled'}
                  />
                )
              )}
              <div style={{ display: 'none' }} id="exportDialoguesDataButton">
                <PanelButton
                  type="csv"
                  csvData={lib__buildCSVData(exportData)}
                  csvFileName={`chatbotDialogues-${moment().format()}.csv`}
                  label="Export Data"
                />
              </div>
            </div>
          </div>
        </div>
        <Snackbar
          open={openError}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            No data found
          </Alert>
        </Snackbar>
      </>
    );
  }
}
