import React, { useState, useEffect, useContext } from 'react';
import './Properties.css';
import { Form, Input, InputAdd, Textarea, Checkbox, Select } from 'stories';
import SaveNode from './SaveNode';
import { idGenerator } from 'pages/Flows/FlowDiagram/utility.js';
import AuthContext from 'contexts/AuthContext';

const invalidMatch = /^\[[0-9]*\]\s?[a-zA-Z0-9]+/;

export default function BranchProperties(props) {
  const tokens = useContext(AuthContext);

  const { node, extras } = props;
  const [text, setText] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [matches, setMatches] = useState([]);
  const [noMatch, setNoMatch] = useState({});
  const [image, setImage] = useState(null);
  const [showTag, setShowTag] = useState(false);
  const [showMatchError, setShowMatchError] = useState(false);
  const [tag, setTag] = useState(null);
  const [customType, setCustomType] = useState(null);
  const [branchDataCollector, setBranchDataCollector] = useState(
    node.branchDataCollector ? JSON.parse(node.branchDataCollector) : null
  );

  const initNode = () => {
    setText(node.text);
    setImage(node.image);

    if (node.customType) {
      try {
        let t = node.customType.split('tag:');
        setTag(t[1]);
        setShowTag(true);
      } catch (e) {}
    }

    if (node.matches === undefined) node.matches = [];

    handleMatches(node.matches, false);
    setImage(node.image);
  };

  useEffect(() => {
    initNode();
  }, [props.node]);

  const handleChange = e => {
    setShowTag(e);
  };

  const handleMatches = (m, isLoading) => {
    let mArr = [];
    for (var i = 0; i < m.length; ++i) {
      if (m[i].match !== 'NO MATCH') {
        mArr.push({
          id: isLoading ? idGenerator() : m[i].id,
          text: m[i].match,
        });
      } else if (!isLoading) {
        setNoMatch(m[i]);
      }
    }

    setMatches(mArr);
  };

  const deleteWarning = deleteNode => {
    let tempNodes = JSON.parse(JSON.stringify(node.matches));

    for (var i = 0; i < tempNodes.length; ++i) {
      if (tempNodes[i].id === deleteNode.id) {
        tempNodes.splice(i, 1);
      }
    }

    let markAsNewBranch = {
      branchId: node.id,
      id: deleteNode.id,
      newMatches: tempNodes,
      matches: JSON.parse(JSON.stringify(node.matches)),
    };

    props.deleteWarningBranch(markAsNewBranch);
  };

  const acceptPreload = x => {
    let preFillData = props.rawPreloadedMessages[x];

    if (matches.length === 0) {
      handleMatches(preFillData.matches, true);
    } else {
      setShowMatchError(true);
      setTimeout(() => {
        setShowMatchError(false);
      }, 5000);
    }

    setImage(preFillData.image);
    setText(preFillData.message);
  };

  let formDataStart = [
    {
      title: 'MESSAGE BODY',
      component: (
        <Textarea
          name="text"
          image={image}
          allowextras={props.viewOnly ? false : extras}
          preloadOptions={
            !props.viewOnly ? props.preloadedMessages.branch : false
          }
          acceptPreload={acceptPreload}
          charLimit="160"
          isSMS
          required
          form="true"
          value={text}
          readOnly={props.viewOnly ? 'readonly' : false}
        />
      ),
    },
  ];

  let formDataMid = [];

  let formDataEnd = [
    {
      title: 'MATCH AGAINST',
      subtext:
        "Format: Yes, I'm going. SMS will display this as text. Mobile/Web will convert this to buttons.",
      component: (
        <InputAdd
          name="matches"
          isNew={node.isNew}
          contref={props.containerRef}
          required
          form="true"
          maxlength="100"
          matches={matches}
          invalidmatch={invalidMatch}
          deletewarning={deleteWarning}
          viewOnly={props.viewOnly}
        />
      ),
    },
    {
      title: 'TAG RESPONSE',
      subtext: 'Save response as a tag on the user.',
      component: (
        <Checkbox
          name="showTag"
          form="true"
          value={showTag}
          label="Save Response as Tag"
          onChange={handleChange}
          checkboxInput
          viewOnly={props.viewOnly}
        />
      ),
    },
  ];

  let saveNode = SaveNode(props.groups, props.node, props.viewOnly);

  let formData = formDataStart.concat(formDataMid);
  formData = formData.concat(formDataEnd);
  formData = formData.concat(saveNode);

  if (showTag && !props.viewOnly) {
    formData.push({
      title: '',
      component: (
        <Input
          name="responseTag"
          form="true"
          required
          placeholder="Tag Key Value"
          maxlength={20}
          value={tag}
        />
      ),
    });
  }

  const onSubmit = (data, e) => {
    if (data.type !== 'submit') {
      data.matches = JSON.parse(data.matches);
      for (var i = 0; i < data.matches.length; ++i) {
        data.matches[i].match = data.matches[i].text;
        delete data.matches[i].text;
        delete data.matches[i].didEdit;
      }

      data.matches = [...data.matches, noMatch];

      if (data.showTag === 'true') {
        data.customType = 'tag:' + data.responseTag;
      } else {
        data.customType = null;
      }
      delete data.showTag;
      delete data.responseTag;

      try {
        let val = JSON.parse(data.branchDataCollector).value;

        if (!val) {
          data.branchDataCollector = null;
        }
      } catch (e) {}

      props.saveInfo(data);
    }
  };

  const matchError = () => {
    return (
      <div
        className="errorPopup"
        style={{ bottom: showMatchError ? '0px' : '-150px' }}
      >
        Text has been updated, but matches have not. Matches cannot be updated
        while matches already exist. Please remove all matches to update.
      </div>
    );
  };

  return (
    <>
      <Form
        data={formData}
        onSubmit={onSubmit}
        deleteWarning={props.deleteWarning}
        type="node"
        style={{ width: '100%' }}
      />
      {matchError()}
    </>
  );
}
