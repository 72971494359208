import React, { useContext, useEffect, useState } from 'react';

import { useTheme } from '@material-ui/core/styles';

import { DateTime, Select, Table, PanelButton } from 'stories';

import moment from 'moment-timezone';

import CircularProgress from '@material-ui/core/CircularProgress';

import { BlueCheckbox, getEngagementModalData } from './modal-util.js';

import AuthContext from 'contexts/AuthContext';

export default function FlowEngagementModal(props) {
  const tokens = useContext(AuthContext);
  const theme = useTheme();

  let { flowId, flowVersionInfo, versionTitles } = props;

  let [FE_modal_date, set_FE_modal_date] = useState(null);
  let [FE_modal_start, set_FE_modal_start] = useState(null);
  let [FE_modal_endDate, set_FE_modal_endDate] = useState(null);
  let [FE_modal_end, set_FE_modal_end] = useState(null);
  const [FE_modal_data, set_FE_modal_data] = useState([]);
  const [FE_modal_table_data, set_FE_modal_table_data] = useState([]);
  const [FE_modal_filtered_table, set_FE_modal_filtered_table] = useState([]);
  const [feDatInd, setFEDateInd] = useState(7);
  const [FE_modal_loading_message, set_FE_modal_loading_message] = useState(
    'Loading table data. (0%)'
  );
  const [FE_modal_filter_status, set_FE_modal_filter_status] = useState('Off');

  useEffect(() => {
    getEngagementModalData(
      versionTitles,
      set_FE_modal_start,
      set_FE_modal_date,
      set_FE_modal_endDate,
      set_FE_modal_end,
      set_FE_modal_loading_message,
      set_FE_modal_data,
      set_FE_modal_table_data,
      FE_modal_data,
      flowId,
      flowVersionInfo,
      null,
      setFEDateInd
    );
  }, []);

  const filter_FE_modal_table = (d1, d2, d3, d4) => {
    FE_modal_date = d1 ? moment(d1) : FE_modal_date;
    FE_modal_start = d2 ? moment(d2) : FE_modal_start;
    FE_modal_endDate = d3 ? moment(d3) : FE_modal_endDate;
    FE_modal_end = d4 ? moment(d4) : FE_modal_end;

    FE_modal_date = moment.isMoment(FE_modal_date)
      ? FE_modal_date
      : moment(FE_modal_date, 'x');
    FE_modal_start = moment.isMoment(FE_modal_start)
      ? FE_modal_start
      : moment(FE_modal_start, 'x');
    FE_modal_endDate = moment.isMoment(FE_modal_endDate)
      ? FE_modal_endDate
      : moment(FE_modal_endDate, 'x');
    FE_modal_end = moment.isMoment(FE_modal_end)
      ? FE_modal_end
      : moment(FE_modal_end, 'x');

    //get user filters
    let userDate =
      FE_modal_date === null
        ? moment().format('MM/DD/YYYY')
        : FE_modal_date.format('MM/DD/YYYY');
    let userStart =
      FE_modal_start === null
        ? moment().format('HH:mm:ss')
        : FE_modal_start.format('HH:mm:ss');
    let userEndDate =
      FE_modal_endDate === null
        ? moment().format('MM/DD/YYYY')
        : FE_modal_endDate.format('MM/DD/YYYY');
    let userEnd =
      FE_modal_end === null
        ? moment().format('HH:mm:ss')
        : FE_modal_end.format('HH:mm:ss');

    //console.log(18, userDate, userStart);

    let begin = moment(userDate + ' ' + userStart, 'MM/DD/YYYY HH:mm:ss');
    let end = moment(userEndDate + ' ' + userEnd, 'MM/DD/YYYY HH:mm:ss');

    //reduce current dataset from user filters
    let newTableDataSet = FE_modal_table_data.filter(row => {
      //console.log(18, moment(row[7], "MM/DD/YYYY HH:mm:ss").format("LLLL"), begin.format("LLLL"), end.format("LLLL"));
      if (
        moment(row[feDatInd], 'MM/DD/YYYY HH:mm:ss').isSameOrAfter(begin) &&
        moment(row[feDatInd], 'MM/DD/YYYY HH:mm:ss').isSameOrBefore(end)
      ) {
        return true;
      } else {
        return false;
      }
    });

    set_FE_modal_filtered_table(newTableDataSet);
  };

  const handleTimeChange = (newDate, inputName) => {
    //console.log(18, newDate, inputName);
    if (inputName === 'date') {
      set_FE_modal_date(moment(newDate));
      if (FE_modal_filter_status === 'On') {
        filter_FE_modal_table(newDate, null, null, null);
      }
    } else if (inputName === 'start') {
      set_FE_modal_start(moment(newDate));
      if (FE_modal_filter_status === 'On') {
        filter_FE_modal_table(null, newDate, null, null);
      }
    } else if (inputName === 'endDate') {
      set_FE_modal_endDate(moment(newDate));
      if (FE_modal_filter_status === 'On') {
        filter_FE_modal_table(null, null, newDate, null);
      }
    } else if (inputName === 'end') {
      set_FE_modal_end(moment(newDate));
      if (FE_modal_filter_status === 'On') {
        filter_FE_modal_table(null, null, null, newDate);
      }
    }
  };

  const handleModalSort = value => {
    let newDataArray;
    if (FE_modal_filter_status === 'Off') {
      newDataArray = [...FE_modal_table_data];
    } else {
      newDataArray = [...FE_modal_filtered_table];
    }

    if (value === 'most_recent') {
      newDataArray.sort((a, b) => {
        if (
          moment(a[feDatInd], 'MM/DD/YYYY HH:mm:ss').isBefore(
            b[feDatInd],
            'MM/DD/YYYY HH:mm:ss'
          )
        ) {
          return 1;
        } else if (
          moment(a[feDatInd], 'MM/DD/YYYY HH:mm:ss').isAfter(
            b[feDatInd],
            'MM/DD/YYYY HH:mm:ss'
          )
        ) {
          return -1;
        } else {
          return 0;
        }
      });
    } else if (value === 'asurite') {
      newDataArray.sort((a, b) => {
        if (a[0] > b[0]) {
          return 1;
        } else if (a[0] < b[0]) {
          return -1;
        } else {
          return 0;
        }
      });
    } else if (value === 'question') {
      newDataArray.sort((a, b) => {
        if (a[3] > b[3]) {
          return 1;
        } else if (a[3] < b[3]) {
          return -1;
        } else {
          return 0;
        }
      });
    }

    if (FE_modal_filter_status === 'Off') {
      set_FE_modal_table_data(newDataArray);
    } else {
      set_FE_modal_filtered_table(newDataArray);
    }
  };

  const handleCheckboxChange = () => {
    if (FE_modal_filter_status === 'Off') {
      set_FE_modal_filter_status('On');

      filter_FE_modal_table();
    } else {
      set_FE_modal_filter_status('Off');
    }
  };

  return (
    <>
      <div class="FE-modal-header">Flow Engagement</div>
      <div class="FE-modal-subheader">{/*flowVersionInfo.title*/}</div>
      <div class="FE-export-button__wrapper">
        <PanelButton
          type="csv"
          csvData={[
            [
              'asurite',
              'Display Name',
              'emplid',
              'Question',
              'Response',
              'Node Type',
              'Button Match',
              'Date/Time',
              'Version',
            ],
          ].concat(FE_modal_table_data)}
          csvFileName={`${
            flowVersionInfo.title
          }-engagement-${moment().format()}.csv`}
          label="Export Data"
          style={{ border: '0' }}
        />
      </div>
      <div class="FE-modal-option-bar__wrapper">
        <div class="FE-modal-option-bar FE-modal-option-bar--left">
          <div class="FE-modal-option-bar__item">
            <div class="FE-modal-option-bar-item__label">Start Date:</div>
            <div class="FE-modal-option-bar-item__input">
              {
                <DateTime
                  hideTimeSelect
                  onChange={newDate => {
                    handleTimeChange(newDate, 'date');
                  }}
                  name="FE-modal-date"
                  stateValue={FE_modal_date}
                  style={{
                    backgroundColor: 'white',
                    fontSize: '0.9em',
                  }}
                />
              }
            </div>
          </div>
          <div class="FE-modal-option-bar__item">
            <div class="FE-modal-option-bar-item__label">Start time:</div>
            <div class="FE-modal-option-bar-item__input">
              {
                <DateTime
                  hideDateSelect
                  onChange={newDate => {
                    handleTimeChange(newDate, 'start');
                  }}
                  name="FE-modal-start"
                  stateValue={FE_modal_start}
                  style={{
                    backgroundColor: 'white',
                    fontSize: '0.9em',
                  }}
                />
              }
            </div>
          </div>
          <div class="FE-modal-option-bar__item">
            <div class="FE-modal-option-bar-item__label">End Date:</div>
            <div class="FE-modal-option-bar-item__input">
              {
                <DateTime
                  hideTimeSelect
                  onChange={newDate => {
                    handleTimeChange(newDate, 'endDate');
                  }}
                  name="FE-modal-endDate"
                  stateValue={FE_modal_endDate}
                  style={{
                    backgroundColor: 'white',
                    fontSize: '0.9em',
                  }}
                />
              }
            </div>
          </div>
          <div class="FE-modal-option-bar__item">
            <div class="FE-modal-option-bar-item__label">End time:</div>
            <div class="FE-modal-option-bar-item__input">
              {
                <DateTime
                  hideDateSelect
                  onChange={newDate => {
                    handleTimeChange(newDate, 'end');
                  }}
                  name="FE-modal-end"
                  stateValue={FE_modal_end}
                  style={{
                    backgroundColor: 'white',
                    fontSize: '0.9em',
                  }}
                />
              }
            </div>
          </div>
          <div
            class="FE-modal-option-bar__item"
            style={{ cursor: 'pointer' }}
            onClick={handleCheckboxChange}
          >
            <div class="FE-modal-option-bar-item__label">
              Date Filter {FE_modal_filter_status}
            </div>
            <div class="FE-modal-option-bar-item__input">
              {
                <BlueCheckbox
                  name="fe_modal_chekbox"
                  label={'Date Filter' + FE_modal_filter_status}
                  checked={FE_modal_filter_status === 'Off' ? false : true}
                  color="secondary"
                  //onChange={handleCheckboxChange}
                />
              }
            </div>
          </div>
        </div>
      </div>
      {/*<div class="FE-modal-option-bar__wrapper">
        <div class="FE-modal-option-bar FE-modal-option-bar--right">


          </div>
        </div>
            </div>*/}
      <div class="FE-modal-sort">
        <div class="FE-modal-option-bar-item__label">Sort by:</div>
        <div
          class="FE-modal-option-bar-item__input"
          style={{ fontSize: '0.9em' }}
        >
          {
            <Select
              /*classNamePrefix="myCustomPrefix"*/
              /*value={props.text.toUpperCase()}*/
              placeholder={'Most Recent'}
              onChange={handleModalSort}
              options={[
                { label: 'Most Recent', value: 'most_recent' },
                { label: 'Asurite', value: 'asurite' },
                { label: 'Question', value: 'question' },
              ]}
              isSearchable={true}
            />
          }
        </div>
      </div>
      <div className="FE-modal-table">
        {FE_modal_table_data.length === 0 ? (
          <div style={{ textAlign: 'center', width: '100%' }}>
            {FE_modal_loading_message.includes('returned') ? (
              'No data returned.'
            ) : (
              <CircularProgress
                size={'2rem'}
                style={{ color: theme.palette.general.lightMaroon }}
              />
            )}
            {/*
            <div className="FE-modal-loading">
              {FE_modal_loading_message}
            </div>
            <div
              style={{
                borderBottom: "3px solid #3f88df",
                transition: "width 250ms ease-in-out",
                width: FE_modal_loading_message ? FE_modal_loading_message.replace(/[^0-9\%]/g, "") : "auto",
              }}
            ></div>
            */}
          </div>
        ) : (
          <Table
            labels={[
              { title: 'asurite', notClickable: true },
              { title: 'Display Name', notClickable: true },
              { title: 'emplid', notClickable: true },
              { title: 'Question', notClickable: true },
              { title: 'Response', notClickable: true },
              { title: 'Node Type', notClickable: true },
              { title: 'Button Match', notClickable: true },
              { title: 'Date/Time', notClickable: true },
              { title: 'Version', notClickable: true },
            ]}
            data={
              FE_modal_filter_status === 'On'
                ? FE_modal_filtered_table
                : FE_modal_table_data
            }
            defaultSortKey="asurite"
            noFilter
            noSearch
            noTimestamps
            //style={{padding: "0"}}
          />
        )}
      </div>
    </>
  );
}
