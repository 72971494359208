export const combineData = (knowledgeBase, categories) => {
  return knowledgeBase.map(FAQ => {
    let categoryName;
    let parentCategory;
    categories.forEach(el => {
      if (el.id === FAQ.categoryID) {
        categoryName = el.title;
        parentCategory = el;
      }
    });

    const subcategoryNames = FAQ.subcategoryNames;

    return {
      ...FAQ,
      parentCategory,
      categoryName,
      subcategoryNames
    };
  });
};

export const isEqual = (a, b) => {
  try {
    console.log(a, typeof a);

    if (typeof a !== typeof b) {
      return false;
    } else if (typeof a == "object") {
      if (a.length) {
        return JSON.stringify(a) === JSON.stringify(b);
      } else {
        console.log("OBEJCT IS");
        return Object.is(a, b);
      }
    } else {
      return a === b;
    }
  } catch (err) {
    return false;
  }
};

// const sortBy = (field, reverse, primer) => {
//   const key = primer
//     ? function(x) {
//         return primer(x[field]);
//       }
//     : function(x) {
//         return x[field];
//       };
//   reverse = !reverse ? 1 : -1;
//   return function(a, b) {
//     return (a = key(a)), (b = key(b)), reverse * ((a > b) - (b > a));
//   };
// };
