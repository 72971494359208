import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { API, graphqlOperation } from 'aws-amplify';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { roleCheck } from 'services/utility/RoleMatrix';

import PaginationPanel from 'stories/Table/PaginationPanel';
import { SectionHeader } from 'components/SectionHeader';
import * as queries from 'graphql/queries';
import * as mutations from 'graphql/mutations';
import AuthContext from 'contexts/AuthContext';
import { store } from 'contexts/GlobalStore';
import { CreateNewButton, Table } from 'stories';
import './Escalations.css';

const pageLimit = 7;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Escalations(props) {
  const tokens = useContext(AuthContext);
  const theme = useTheme();
  const GlobalState = useContext(store);
  const { dispatch } = GlobalState;
  const {
    tableSort,
    spliceTableData,
    tableDataEmpty,
    globalTableState,
  } = GlobalState.state;

  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchActive, setSearchActive] = useState(false);
  const [filter, setFilter] = useState('All');
  const [group, setGroup] = useState('All');
  const [totalResults, setTotalResults] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [prevPageClicked, setPrevPageClicked] = useState(false);
  const [rerenderKey, setRerenderKey] = useState(Date.now());
  const [refetchKey, setRefetchKey] = useState(Date.now());
  const [data, setData] = useState([]);
  const [rawData, setRawData] = useState([]);
  const [open, setOpen] = useState(false);

  const [loadedTableState, setLoadedTableState] = useState(false);
  const [loadedTableSearchState, setLoadedTableSearchState] = useState(false);

  useEffect(() => {
    dispatch({
      type: 'setTableDataEmpty',
      tableDataEmpty: false,
    });
  }, []);

  useEffect(() => {
    createFetchData();
    dispatch({
      type: 'setLoadingFormSubmission',
      loadingFormSubmission: false,
    });
  }, [
    rerenderKey,
    refetchKey,
    searchActive,
    currentPage,
    filter,
    group,
    tableSort,
  ]);

  useEffect(() => {
    if (tableDataEmpty) {
      dispatch({
        type: 'setLoadingTableData',
        loadingTableData: false,
      });
    } else if (!tableDataEmpty && !loadedTableState) {
      const pathName = window.location.pathname.replace('/', '');
      let storedPage =
        globalTableState &&
        globalTableState[pathName] &&
        globalTableState[pathName].pageNumber;
      if (storedPage) {
        setCurrentPage(storedPage);
        setLoadedTableState(true);
      }
    }
  }, [tableDataEmpty]);

  useEffect(() => {
    const pathName = window.location.pathname.replace('/', '');
    let newGlobalTableState = Object.assign({}, globalTableState);
    if (!newGlobalTableState[pathName]) {
      newGlobalTableState[pathName] = {};
    }
    newGlobalTableState[pathName].pageNumber = currentPage;
    dispatch({
      type: 'setGlobalTableState',
      globalTableState: newGlobalTableState,
    });
  }, [currentPage]);

  const fetchData = async (searchText, filter, sort) => {
    if (tableDataEmpty) {
      dispatch({
        type: 'setLoadingTableData',
        loadingTableData: false,
      });
    } else {
      dispatch({
        type: 'setLoadingTableData',
        loadingTableData: true,
      });
    }
    try {
      let sortQuery = { field: 'timestamp', direction: 'desc' };
      if (sort) {
        sortQuery = { field: sort.field, direction: sort.direction };
      }

      let isGroupFilterOn =
        tokens.role === 'approver' || tokens.role === 'content creator';

      let result = await API.graphql(
        graphqlOperation(queries.searchEscalations, {
          filter: createFilterQuery(searchText, filter, isGroupFilterOn),
          sort: sortQuery,
        })
      );

      let loadedUpdate = true;
      let loadedDelete = true;
      let loadedNew = false;

      if (spliceTableData) {
        if (spliceTableData.type === 'deleteEscalation') {
          result.data.searchEscalations.items.forEach((el, index) => {
            if (el.id === spliceTableData.escalation.id) {
              loadedDelete = false;
            }
          });
          if (loadedDelete) {
            dispatch({
              type: 'setSpliceTableData',
              spliceTableData: null,
            });
          }
        } else if (spliceTableData.type === 'addEscalation') {
          result.data.searchEscalations.items.forEach(el => {
            if (el.id === spliceTableData.escalation.id) {
              loadedNew = true;
            }
          });
        } else if (spliceTableData.type === 'updateEscalation') {
          result.data.searchEscalations.items.forEach(el => {
            if (el.id === spliceTableData.escalation.id) {
              if (el.timestamp === spliceTableData.escalation.timestamp) {
                loadedNew = true;
              }
            }
          });
        } else {
          result.data.searchEscalations.items.forEach((el, index) => {
            if (spliceTableData.type === 'delete') {
              loadedNew = true;
            } else {
              if (el.timestamp === spliceTableData.item.timestamp.toString()) {
                loadedNew = true;
              }
            }
          });
        }
      } else if (!spliceTableData) {
        loadedNew = true;
      }

      if (!loadedUpdate || !loadedNew || !loadedDelete) {
        return setRerenderKey(Date.now());
      } else if (loadedUpdate && loadedNew && loadedDelete) {
        dispatch({
          type: 'setSpliceTableData',
          spliceTableData: null,
        });

        setRawData(result.data.searchEscalations.items);

        let parsedData = parseData(result.data.searchEscalations.items);
        let total = parsedData.length;

        if (total) {
          setTotalResults(total);
        }

        setData(parsedData);

        dispatch({
          type: 'setLoadingTableData',
          loadingTableData: false,
        });
      }
    } catch (error) {
      console.log('esclations fetch data error: ', error);
    } finally {
      tableDataEmpty &&
        dispatch({
          type: 'setLoadingTableData',
          loadingTableData: false,
        });
    }
  };

  const createFetchData = () => {
    //restore previous search
    const pathName = window.location.pathname.replace('/', '');
    let localSearchActive = searchActive;
    let localSearchKeyword = searchKeyword;
    if (
      !loadedTableSearchState &&
      globalTableState &&
      globalTableState[pathName] &&
      globalTableState[pathName].search !== ''
    ) {
      localSearchActive = true;
      localSearchKeyword = globalTableState[pathName].search;
      setSearchActive(true);
      setSearchKeyword(globalTableState[pathName].search);
    }
    setLoadedTableSearchState(true);

    if (localSearchActive && filter !== 'All' && tableSort) {
      fetchData(localSearchKeyword, filter, tableSort);
    } else if (localSearchActive && filter !== 'All' && !tableSort) {
      fetchData(localSearchKeyword, filter, null);
    } else if (localSearchActive && filter === 'All' && tableSort) {
      fetchData(localSearchKeyword, null, tableSort);
    } else if (localSearchKeyword === '' && filter !== 'All' && tableSort) {
      fetchData(null, filter, tableSort);
    } else if (localSearchActive) {
      fetchData(localSearchKeyword);
    } else if (filter !== 'All') {
      fetchData(null, filter);
    } else if (tableSort) {
      fetchData(null, null, tableSort);
    } else {
      fetchData();
    }
  };

  const createFilterQuery = (searchText, filter, groupFilterFlag) => {
    const filterConditions = [];

    let groupCheck = [];

    //Remove Benji
    groupCheck.push({ group: { ne: 'Benji_1593621826' } });

    if (!roleCheck(tokens.role, 'escalations', 'viewAllContent')) {
      filterConditions.push({ submittedBy: { eq: tokens.asurite } });
      filterConditions.push({ status: { eq: getStatusKey('Published') } });
      filterConditions.push({ status: { eq: getStatusKey('Approved') } });
    }

    let filterQuery = {
      deleted: { eq: false },
    };

    filterQuery.and = [
      {
        and: [...groupCheck],
      },
    ];

    if (filterConditions.length > 0) {
      filterQuery.and.push({
        or: [...filterConditions],
      });
    }

    if (groupFilterFlag) {
      filterQuery.or = [];
      tokens.groups.forEach(element => {
        filterQuery.or.push({ group: { eq: element } });
      });
    } else if (group !== 'All') {
      filterQuery.or = [];
      let grp = group;
      filterQuery.or.push({
        or: [{ group: { eq: grp } }],
      });
    }

    if (searchText && filter) {
      filterQuery.escalationName = { matchPhrasePrefix: searchText };
      filterQuery.status = { eq: getStatusKey(filter) };
    } else if (filter) {
      filterQuery.status = { eq: getStatusKey(filter) };
    } else if (searchText) {
      filterQuery.escalationName = { matchPhrasePrefix: searchText };
    }
    console.log('FILTER COND: ', filterQuery);
    return filterQuery;
  };

  const getStatusKey = name => {
    if (name === 'Pending') {
      return 1;
    } else if (name === 'Unapproved') {
      return 2;
    } else if (name === 'Approved') {
      return 3;
    } else if (name === 'Published') {
      return 4;
    } else if (name === 'Disabled') {
      return 5;
    } else {
      return null;
    }
  };

  // const canEditDel = (group) => {
  //   if (tokens.role === "content creator" || tokens.role === "approver") {
  //     if (tokens.groups.includes(group)) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     return true;
  //   }
  // };

  const canEdit = (group, asurite) => {
    if (roleCheck(tokens.role, 'escalations', 'edit')) {
      if (tokens.asurite === asurite) {
        return true;
      } else {
        if (tokens.role === 'content creator' || tokens.role === 'approver') {
          if (tokens.groups.includes(group)) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
    } else {
      return false;
    }
  };

  const canDelete = (group, asurite, attachedAlertIds) => {
    if (attachedAlertIds && attachedAlertIds.length) {
      return false;
    } else if (roleCheck(tokens.role, 'escalations', 'delete')) {
      if (tokens.asurite === asurite) {
        return true;
      } else {
        if (tokens.role === 'content creator' || tokens.role === 'approver') {
          if (tokens.groups.includes(group)) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
    } else {
      return false;
    }
  };

  const parseData = data => {
    return data.map(el => {
      return {
        id: el.id,
        name: el.escalationName,
        status: {
          statusID: el.status,
        },
        group: el.group,
        submittedBy: el.submittedBy,
        submittedOn: el.timestamp,
        actions: {
          edit: canEdit(el.group, el.submittedBy) ? true : false,
          delete: canDelete(el.group, el.submittedBy, el.attachedAlertIds)
            ? true
            : false,
        },
      };
    });
  };

  const onSubmitDelete = async el => {
    let rawEsc;
    rawData.forEach(data => {
      if (el.id === data.id) {
        rawEsc = data;
      }
    });
    const escalationToDelete = {
      id: el.id,
      deleted: true,
      editedBy: !rawEsc.editedBy ? [] : rawEsc.editedBy,
    };
    escalationToDelete.editedBy.push({
      asurite: tokens.asurite,
      timestamp: Date.now().toString(),
    });
    // console.log(escalationToDelete)
    await API.graphql(
      graphqlOperation(mutations.updateEscalation, {
        input: escalationToDelete,
      })
    );

    dispatch({
      type: 'setSpliceTableData',
      spliceTableData: {
        escalation: el,
        type: 'deleteEscalation',
      },
    });

    setRerenderKey(Date.now());
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const onClickEdit = el => {
    rawData.forEach(data => {
      if (el.id === data.id) {
        dispatch({
          type: 'updateEditInfo',
          editInfo: {
            id: data.id,
            editedBy: data.editedBy,
            status: data.status,
            escalationName: data.escalationName,
            group: data.group,
            escalationType: data.escalationType,
            emailTo: data.emailTo,
            ccemail: data.ccemail,
            emailSubject: data.emailSubject,
            emailBody: data.emailBody,
            sendTextMessage: data.sendTextMessage,
            textMessageContent: data.textMessageContent,
            submittedBy: data.submittedBy,
            liveAgentCategory: data.liveAgentCategory,
          },
        });
      }
    });
    props.history.push({
      pathname: 'escalation-add',
    });
  };

  const onChangeSearch = value => {
    setSearchKeyword(value);
  };

  const onClickSearch = () => {
    if (searchKeyword === '') {
      return;
    } else {
      setSearchActive(true);
      if (currentPage === 1) {
        setRerenderKey(Date.now());
      } else {
        setCurrentPage(1);
      }
    }
  };

  const onClickReset = () => {
    setSearchActive(false);
    setRerenderKey(Date.now());
    dispatch({
      type: 'changeTableSort',
      tableSort: null,
    });

    if (group !== 'All') {
      setGroup('All');
    } else if (currentPage === 1) {
      setRerenderKey(Date.now());
    } else {
      setCurrentPage(1);
    }
  };

  const onClickCreateNew = () => {
    dispatch({ type: 'updateEditInfo', editInfo: { empty: true } });
    dispatch({
      type: 'changeTableSort',
      tableSort: null,
    });
    dispatch({ type: 'changeTableFilter', tableFilter: 'All' });
  };

  const onSelectFilter = value => {
    setCurrentPage(1);
    if (value === filter || !value) {
      setFilter('All');
      dispatch({ type: 'changeTableFilter', tableFilter: 'All' });
    } else {
      setFilter(value);
      dispatch({ type: 'changeTableFilter', tableFilter: value });
    }
  };

  const onSelectGroup = value => {
    setCurrentPage(1);
    dispatch({
      type: 'clearNextTokens',
      clearNextTokens: true,
    });
    if (value === group || !value) {
      setGroup('All');
      dispatch({ type: 'changeTableFilter', tableFilter: 'All' });
    } else {
      setGroup(value);
      dispatch({ type: 'changeTableFilter', tableFilter: value });
    }
  };

  const onClickView = view => {
    props.history.push({
      pathname: '/view-flow',
      state: {
        flowId: view.id,
        type: view.type,
        viewOnly: false,
      },
    });
  };

  const onClickFetchMore = () => {
    setPrevPageClicked(false);
    if (currentPage === Math.ceil(totalResults / pageLimit)) {
      return;
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const onClickFetchLess = async () => {
    setPrevPageClicked(true);
    if (currentPage <= 1) {
      setCurrentPage(1);
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const onChangeJumpToPage = pageNumber => {
    setCurrentPage(pageNumber);
  };

  const jumpToPageOptions = () => {
    const totalPages = Math.ceil(totalResults / pageLimit);
    const options = [];

    for (let i = 1; i <= totalPages; i++) {
      options.push(i);
    }
    const mappedOptions = options.map(el => {
      return { value: el, label: el };
    });
    return mappedOptions;
  };

  const getSortKey = label => {
    label = label.toUpperCase();
    if (label === 'STATUS') {
      return 'statusKey';
    } else if (label === 'CREATED BY') {
      return 'submittedByKey';
    } else if (label === 'CREATED ON') {
      return 'timestampKey';
    } else if (label === 'GROUP') {
      return 'groupKey';
    } else if (label === 'NAME') {
      return 'escalationName';
    }
  };

  const filterOptions = [
    { value: 'Pending', label: 'Pending' },
    { value: 'Approved', label: 'Approved' },
    { value: 'Unapproved', label: 'Unapproved' },
    { value: 'Published', label: 'Published' },
    // { value: "Disabled", label: "Disabled" }
  ];

  let groupOptions = JSON.parse(
    sessionStorage.getItem('allGroups-' + tokens.env)
  ).map(object => {
    let newObject = {};
    newObject.value = object.id;
    newObject.label = object.name;
    return newObject;
  });

  let propertyName = 'label';

  groupOptions.sort((a, b) => {
    if (a[propertyName] < b[propertyName]) {
      return -1;
    }
    if (a[propertyName] > b[propertyName]) {
      return 1;
    }
    return 0;
  });

  const tableLabels = [
    { title: 'Name', wide: true },
    { title: 'Status', notClickable: true },
    { title: 'Group' },
    { title: 'Created By' },
    { title: 'Created On' },
    { title: 'Actions', alignRight: true, notClickable: true },
  ];

  const indexOfLastRecord = currentPage * pageLimit;
  const indexOfFirstRecord = indexOfLastRecord - pageLimit;
  const currentPageRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);

  return (
    <div style={theme.components.pageContainer} key={rerenderKey}>
      <div className="SectionHeader">
        <SectionHeader
          onChangeSearch={onChangeSearch}
          onClickSearch={onClickSearch}
          onClickReset={onClickReset}
          onSelectGroup={onSelectGroup}
          onSelectFilter={onSelectFilter}
          filterOptions={filterOptions}
          groupOptions={groupOptions}
          noSort
          noModality
          rightView={
            roleCheck(tokens.role, 'escalations', 'create') && (
              <div onClick={onClickCreateNew}>
                <button class="CreateUser">
                  <CreateNewButton
                    to="/escalation-add"
                    label="Create New Escalation"
                    noWrap={true}
                  />
                </button>
              </div>
            )
          }
        />
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Escalation has been deleted successfully!
        </Alert>
      </Snackbar>
      <div className="TablePage">
        <Paper style={theme.components.paper}>
          <div>
            <div />
            <Table
              title="Escalations Table"
              labels={tableLabels}
              data={currentPageRecords}
              useversionstatus={true}
              onChangeSearch={onChangeSearch}
              onClickSearch={onClickSearch}
              onClickReset={onClickReset}
              onSelectFilter={onSelectFilter}
              onSubmitDelete={onSubmitDelete}
              onClickEdit={onClickEdit}
              onClickView={onClickView}
              filterOptions={filterOptions}
              getSortKey={getSortKey}
              defaultSortKey="timestampKey"
              noSort
              // noFilter
              noSearch
            />
            <PaginationPanel
              theme={theme}
              currentPage={currentPage}
              pageCount={Math.ceil(totalResults / pageLimit)}
              onClickFetchMore={onClickFetchMore}
              onClickFetchLess={onClickFetchLess}
              jumpToPageOptions={jumpToPageOptions}
              onChangeJumpToPage={onChangeJumpToPage}
            />
          </div>
        </Paper>
      </div>
    </div>
  );
}

Escalations.propTypes = {
  navigation: PropTypes.object,
};
