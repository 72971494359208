import React, { useEffect, useState, useContext } from 'react';
import './FlowControls.scss';

import { API, graphqlOperation } from 'aws-amplify';

import {
  CreateNewButton,
  DateTime,
  Select,
  Button,
  Input,
  Textarea,
  Modal,
  ModalContent,
  Table,
  Checkbox,
} from 'stories';

import FlowEngagementModal from 'stories/ModalContent/FlowEngagementModal.js';
import DialogueActions from 'pages/Dialogues/DialoguePropertiesContainer/DialogueActions';

import AuthContext from 'contexts/AuthContext';
import { roleCheck } from 'services/utility/RoleMatrix';

import { store } from 'contexts/GlobalStore';
import { listFlowVersions, getFlow, runCampaign } from 'graphql/queries';
import {
  updateFlowVersion,
  createFlowVersion,
  updateFlow,
  createCampaignManualSend,
} from 'graphql/mutations';

import { byFlowId, searchMessages } from 'graphql/queries';

import moment from 'moment-timezone';

import { idGenerator } from '../utility.js';

const VERSION_ID_STARTER = '1-1';

export default function FlowControls(props) {
  const { flowId, selectedVersion } = props;

  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [refreshKey, setRefreshKey] = useState(1);

  const [flowVersionInfo, setFlowVersionInfo] = useState({});
  const [flowVersion, setFlowVersion] = useState(null);
  const [flowVersions, setFlowVersions] = useState([]);

  const [selectedVersionLabel, setSelectedVersionLabel] = useState();
  const [usePropLabel, setUsePropLabel] = useState(false);
  const [validating, setValidating] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [modalMessageColor, setModalMessageColor] = useState('#5ec100');
  const [modalMessage, setModalMessage] = useState('');
  const [hideModalSubmit, setHideModalSubmit] = useState(false);

  const tokens = useContext(AuthContext);
  const GlobalState = useContext(store);
  const dispatchGlobal = GlobalState.dispatch;
  const { dispatch } = GlobalState;
  const role = tokens.role;
  // const role = "content_creator";

  let headerText = props.title;

  useEffect(() => {
    if (props.selectedVersion) {
      setUsePropLabel(true);
    }
  }, [props.selectedVersion]);

  const onSelectVersion = e => {
    setFlowVersionInfo(typeof e === 'string' ? JSON.parse(e) : e);
    props.changedVersion(typeof e === 'string' ? JSON.parse(e) : e);
  };

  const translateVNum = num => {
    try {
      num = num.split(VERSION_ID_STARTER)[1];
      num = parseInt(num);
    } catch (e) {}

    return num;
  };

  const initFlowVersions = () => {
    API.graphql(
      graphqlOperation(listFlowVersions, {
        id: props.flowId,
        version: { beginsWith: VERSION_ID_STARTER },
        sortDirection: 'DESC',
        limit: 10,
      })
    )
      .then(resp => {
        let items = resp.data.listFlowVersions.items;
        let ind = 0;

        let flowOptions = [];
        for (var i = 0; i < items.length; ++i) {
          let parsedVNum = translateVNum(items[i].version);

          let label =
            (items[i].title ? items[i].title : '') +
            ' (Version ' +
            parsedVNum +
            ')';

          flowOptions.push({
            label: label,
            value: JSON.stringify(items[i]),
          });
          if (selectedVersion && selectedVersion === items[i].version) {
            ind = i;
            setSelectedVersionLabel(label);
          }
        }
        setFlowVersions(flowOptions);
        onSelectVersion(items[ind]);
      })
      .catch(er => {
        console.log('ERROR', er);
      });
  };

  useEffect(() => {
    if (props.flowId) {
      initFlowVersions();
    }
  }, [props.flowId]);

  const toggleModal = t => {
    if (t === 'FE') {
      //check if version is published
      if (!flowVersionInfo.published) {
        //switch version to next version that is published
        //get from all versions
        let currentVersionIndex;
        flowVersions.some((versionObject, index) => {
          if (
            (typeof versionObject.value === 'string'
              ? JSON.parse(versionObject.value).version
              : versionObject.value.version) === flowVersionInfo.version
          ) {
            currentVersionIndex = index;
            return true;
          }
        });
        setFlowVersionInfo(
          typeof flowVersions[currentVersionIndex + 1].value === 'string'
            ? JSON.parse(flowVersions[currentVersionIndex + 1].value)
            : flowVersions[currentVersionIndex + 1].value
        );
        props.changedVersion(
          typeof flowVersions[currentVersionIndex + 1].value === 'string'
            ? JSON.parse(flowVersions[currentVersionIndex + 1].value)
            : flowVersions[currentVersionIndex + 1].value
        );
      }
    }
    setSubmitError(false);
    setModalType(t);
    setShowModal(!showModal);
    setModalMessage('');
    setHideModalSubmit(false);
  };

  const checkMatches = async () => {
    let treeCopy = JSON.parse(JSON.stringify(props.rawTree));
    let matchIds = [];
    let nodes = {};

    for (var i = 0; i < treeCopy.length; ++i) {
      nodes[treeCopy[i].id] = treeCopy[i].id;
      if (treeCopy[i].matches && treeCopy[i].matches.length > 0) {
        for (var j = 0; j < treeCopy[i].matches.length; ++j) {
          matchIds.push(treeCopy[i].matches[j].id);
        }
      }
    }

    let noConnectingNodes = [];
    for (var i = 0; i < matchIds.length; ++i) {
      if (!nodes[matchIds[i]]) {
        noConnectingNodes.push(matchIds[i]);
      }
    }

    return noConnectingNodes;
  };

  const prepForSubmit = (payload, user, updateOnly) => {
    return new Promise(async function(resolve, reject) {
      checkMatches();
      dispatchGlobal({
        type: 'setLoadingFormSubmission',
        loadingFormSubmission: true,
      });

      setValidating(true);

      let msgs = await checkMatches();

      if (msgs.length > 0) {
        setValidating(false);
        setSubmitError(true);

        dispatchGlobal({
          type: 'setLoadingFormSubmission',
          loadingFormSubmission: false,
        });
      } else {
        if (!updateOnly) {
          payload.submitted = true;
        }
        let signOff = await API.graphql(
          graphqlOperation(getFlow, { id: flowVersionInfo.id })
        );
        let sMsg = signOff.data.getFlow.signOffMsg;

        let treeCopy = [];

        if (typeof props.rawTree === 'object') {
          treeCopy = JSON.parse(JSON.stringify(props.rawTree));
        } else {
          treeCopy = props.rawTree + '';
          treeCopy = JSON.parse(treeCopy);
        }

        treeCopy.push({
          id: 'SIGNOFFMSG',
          parent: 'DEFAULTMESSAGE-NOPARENT',
          text: sMsg,
          type: 'text',
        });

        let queryableNodes = [];
        let varsUsed = [];
        let hasLiveAgent = false;
        // let connectingFlows = [];

        for (var i = 0; i < treeCopy.length; ++i) {
          if (treeCopy[i].type === 'branch' || treeCopy[i].type === 'prompt') {
            queryableNodes.push(treeCopy[i]);
          }
          if (treeCopy[i].type === 'trigger' && !hasLiveAgent) {
            try {
              let data = JSON.parse(treeCopy[i].escalationDump);
              if (data.escalationType === 'Live Agent') {
                hasLiveAgent = true;
              }
            } catch (e) {}

            queryableNodes.push(treeCopy[i]);
          }
          // if (treeCopy[i].type === "jump" && treeCopy[i].jumpToNodeId) {
          //   if (connectingFlows.indexOf(treeCopy[i].jumpToNodeId) === -1) {
          //     connectingFlows.push(treeCopy[i].jumpToNodeId);
          //   }
          // }
          if (treeCopy[i].text) varsUsed = getVars(treeCopy[i].text, varsUsed);
          if (treeCopy[i].response)
            varsUsed = getVars(treeCopy[i].response, varsUsed);
        }

        payload.flowNodes = JSON.stringify(treeCopy);
        payload.queryableData = JSON.stringify(queryableNodes);
        payload.variablesIncluded = varsUsed;
        payload.liveAgentEsc = hasLiveAgent;

        if (!updateOnly) {
          payload.submittedBy = flowVersionInfo.submittedBy
            ? flowVersionInfo.submittedBy.concat(user)
            : [user];
        }
        dispatchGlobal({
          type: 'setLoadingFormSubmission',
          loadingFormSubmission: false,
        });

        resolve(payload);
      }
    });
  };

  const getVars = (txt, varsUsed) => {
    let items = txt.split('{');

    for (var i = 1; i < items.length; ++i) {
      try {
        let v = items[i].split('}')[0];
        if (varsUsed.indexOf(v) === -1) varsUsed.push(v);
      } catch (e) {}
    }

    return varsUsed;
  };

  const onSubmit = async data => {
    if (data.type !== 'submit') {
      let payload = {
        id: flowVersionInfo.id,
        version: flowVersionInfo.version,
        title: data.title,
        description: data.description,
      };

      let user = {
        asurite: tokens.asurite,
        timestamp: new Date().getTime(),
      };

      if (modalType === 'SUBMIT' && !flowVersionInfo.approved) {
        payload = await prepForSubmit(payload, user);
      } else if (modalType === 'APPROVE') {
        payload.approved = true;
        payload.approvedBy = [user];
      } else if (modalType === 'PUBLISH') {
        payload.published = true;
        payload.publishedBy = user;
      }

      API.graphql(graphqlOperation(updateFlowVersion, { input: payload }))
        .then(async resp => {
          if (modalType === 'PUBLISH') {
            let v = 1;

            if (flowVersionInfo.version.indexOf(VERSION_ID_STARTER) > -1) {
              v = flowVersionInfo.version.split(VERSION_ID_STARTER)[1];
              v = parseInt(v) + 1;
            } else {
              v = parseInt(flowVersionInfo.version) + 1;
            }

            v = ('00000000' + v).slice(-8);
            let versionId = '1-1' + v;

            let versionPayload = {
              id: flowVersionInfo.id,
              version: versionId,
              submitted: false,
              approved: false,
              published: false,
              denied: false,
              botType: 'sunny',
              createdAt: new Date().getTime() + '',
              flowNodes: '[]',
            };

            let flowPayload = {
              id: flowVersionInfo.id,
              version: versionId,
            };

            await API.graphql(
              graphqlOperation(createFlowVersion, { input: versionPayload })
            );

            let updateFlowResponse = await API.graphql(
              graphqlOperation(updateFlow, { input: flowPayload })
            );
            console.log('!!!Update Flow', updateFlowResponse);
            setShowModal(false);
            setModalType(null);

            initFlowVersions();
          } else {
            initFlowVersions();

            setShowModal(false);
            setModalType(null);
          }
        })
        .catch(err => {
          console.log('UPDATE ERROR', err);
        });
    }
  };

  const submitFlowTest = async data => {
    // Update flow version with local data
    let updatePayload = {
      id: flowVersionInfo.id,
      version: flowVersionInfo.version,
      title: data.title,
      description: data.description,
    };

    let user = {
      asurite: tokens.asurite,
      timestamp: new Date().getTime(),
    };

    const updateOnly = true;
    updatePayload = await prepForSubmit(updatePayload, user, updateOnly);
    dispatchGlobal({
      type: 'setLoadingFormSubmission',
      loadingFormSubmission: true,
    });
    await API.graphql(
      graphqlOperation(updateFlowVersion, {
        input: updatePayload,
      })
    );
    // Send to runner
    if (data && data.phoneNumbers) {
      let phoneNumberArray = data.phoneNumbers
        .replace(/[^0-9,]/gi, '')
        .split(',');

      if (phoneNumberArray.length > 0) {
        phoneNumberArray = phoneNumberArray.map(phoneNumber => {
          if (phoneNumber.substring(0) !== '+') {
            let addOn = '+';
            if (phoneNumber.length === 10) {
              addOn = '+1';
            }
            return addOn + phoneNumber;
          }
        });

        let flowData = await API.graphql(
          graphqlOperation(getFlow, { id: flowVersionInfo.id })
        );
        let flow = flowData.data.getFlow;
        const currentDate = new Date();
        const currentDatePlus3Hours = new Date(currentDate).setHours(
          currentDate.getHours() + 3
        );
        const payload = {
          campaignId: idGenerator(),
          status: 4,
          flow: JSON.stringify({ value: flow.id, label: flow.title }),
          flowVersion: JSON.stringify({
            value: JSON.parse(flowVersions[0].value).version,
            label: flowVersions[0].label,
          }),
          audienceFileContent: JSON.stringify({
            type: 'sms',
            data: phoneNumberArray,
          }),
          startTime: currentDate.valueOf(),
          endTime: currentDatePlus3Hours,
          createdBy: tokens.asurite,
          type: JSON.stringify({ value: 'sms' }),
          group: flow.group,
          isTest: true,
        };
        let manualSendCampaign = null;
        try {
          manualSendCampaign = await API.graphql(
            graphqlOperation(createCampaignManualSend, { input: payload })
          );
        } catch (error) {
          console.log(error);
        }
        dispatchGlobal({
          type: 'setLoadingFormSubmission',
          loadingFormSubmission: false,
        });

        if (manualSendCampaign !== null) {
          setModalMessage('Flow has been queued to Send!');
          setModalMessageColor('#5ec100');
          setHideModalSubmit(true);
        } else {
          setModalMessage('Message failed to send.');
          setModalMessageColor('#de0f23');
        }
      } else {
        dispatchGlobal({
          type: 'setLoadingFormSubmission',
          loadingFormSubmission: false,
        });
        setModalMessage('Flow failed to send - no valid numbers.');
        setModalMessageColor('#de0f23');
      }
    }
  };

  const formData = [
    {
      title: 'TITLE',
      component: (
        <Input
          form
          name="title"
          maxlength="30"
          value={flowVersionInfo.title ? flowVersionInfo.title : ''}
          required
        />
      ),
    },
  ];

  const formDataTest = [
    {
      title: 'PHONE NUMBER(S)',
      component: (
        <Input
          form
          name="phoneNumbers"
          maxlength="1200"
          placeholder={flowVersionInfo.title ? flowVersionInfo.title : ''}
          required
        />
      ),
    },
  ];

  if (submitError) {
    formData.push({
      component: (
        <div className="submitError">
          Flow is not valid and could not be submitted. Please make sure you
          have at least one node of type text, prompt, or trigger on each MATCH
          and NO MATCH nodes.
        </div>
      ),
    });
  }

  const getDefault = () => {
    let returnVal = null;

    if (usePropLabel && selectedVersionLabel) {
      returnVal = selectedVersionLabel;
    } else {
      if (flowVersions.length > 0) {
        returnVal = flowVersions[0].label;
      } else {
        returnVal = null;
      }
    }

    return returnVal;
  };

  const button = b => {
    return (
      <div className="publishBox">
        <Button
          label={b}
          style={{
            color: 'white',
            background: '#3F88DF',
            width: 'fit-content',
            height: '43px',
            border: '1px solid #3F88DF',
          }}
          onClick={() => {
            toggleModal(b);
          }}
        />
      </div>
    );
  };

  const getTitle = () => {
    let title = '';

    let num = translateVNum(flowVersionInfo.version);

    if (modalType === 'PUBLISH') {
      title = `Publish Version ${num}`;
    } else if (modalType === 'APPROVE') {
      title = `Approve Version ${num}`;
    } else if (modalType === 'SUBMIT') {
      title = `Submit Version ${num}`;
    } else if (modalType === 'DENY') {
      title = 'Deny Version back to Edit?';
    } else if (modalType === 'TEST') {
      title = 'Test Flow';
    } else {
      title = 'Return Version to Edit?';
    }

    return title;
  };

  const releaseToEdit = () => {
    let user = {
      asurite: tokens.asurite,
      timestamp: new Date().getTime(),
    };

    let payload = {
      id: flowVersionInfo.id,
      version: flowVersionInfo.version,
      submitted: false,
      approved: false,
      published: false,
    };

    if (modalType === 'DENY') {
      payload.denied = true;
      payload.deniedBy = flowVersionInfo.deniedBy
        ? flowVersionInfo.deniedBy.concat(user)
        : [user];
    } else {
      user.asurite += '-selfCancel';
      payload.submittedBy = flowVersionInfo.submittedBy
        ? flowVersionInfo.submittedBy.concat(user)
        : [user];
    }

    API.graphql(graphqlOperation(updateFlowVersion, { input: payload }))
      .then(async resp => {
        initFlowVersions();
        setShowModal(false);
        setModalType(null);
      })
      .catch(err => {
        console.log('ERRORD', err);
      });
  };

  const getButton = () => {
    if (flowVersionInfo.submitted) {
      if (!flowVersionInfo.published) {
        let allButtons = [];

        if (roleCheck(tokens.role, 'flows', 'approveDenyContent')) {
          if (
            flowVersionInfo.approved &&
            roleCheck(tokens.role, 'flows', 'publishVersion')
          ) {
            allButtons.push(button('PUBLISH'));
          } else if (!flowVersionInfo.approved) {
            allButtons.push(button('APPROVE'));
          }

          allButtons.push(button('DENY'));
        }

        allButtons.push(button('CANCEL'));

        return allButtons.map(item => {
          return item;
        });
      } else {
        return null;
      }
    } else {
      return button('SUBMIT');
    }
  };

  const getContent = () => {
    if (modalType === 'FE') {
      return (
        <FlowEngagementModal
          flowVersionInfo={flowVersionInfo}
          flowId={flowId}
          versionTitles={flowVersions.map(flowVersionOptions => {
            flowVersionOptions = flowVersionOptions.value;
            flowVersionOptions =
              typeof flowVersionOptions === 'string'
                ? JSON.parse(flowVersionOptions)
                : flowVersionOptions;
            return {
              version: flowVersionOptions.version,
              title: flowVersionOptions.title,
            };
          })}
        />
      );
    } else if (modalType === 'TEST') {
      console.log('!!! A');
      return (
        <>
          <ModalContent
            form
            summary={
              <div style={{ marginBottom: '4em', marginRight: '-10em' }}>
                {' '}
                Send a Test Campaign to one or more people to test the delivery
                of the campaign before I send it to a group of students.
                Multiple phone numbers can be added by seperating each with a
                comma. All tests sent will be prepended with the message "This
                is a test."
              </div>
            }
            title={'FLOW TEST'}
            text="send"
            formData={formDataTest}
            onSubmit={submitFlowTest}
            toggle={toggleModal}
            hideSubmit={hideModalSubmit}
          />
          {modalMessage != '' && (
            <div
              style={{
                marginTop: '1.5em',
                padding: '.8em 2em',
                backgroundColor: modalMessageColor,
                color: '#fff',
                fontWeight: 'bold',
                fontSize: '1.2em',
              }}
            >
              {modalMessage}
            </div>
          )}
        </>
      );
    } else if (modalType !== 'CANCEL' && modalType !== 'DENY') {
      return (
        <ModalContent
          form
          title={getTitle()}
          formData={formData}
          onSubmit={onSubmit}
          toggle={toggleModal}
        />
      );
    } else {
      return (
        <ModalContent
          confirmation
          title={getTitle()}
          formData={formData}
          onSubmit={releaseToEdit}
          toggle={toggleModal}
        />
      );
    }
  };

  const getEngagementFlowBtn = () => {
    if (flowVersionInfo.published) {
      return (
        <Button
          label={'Flow Engagement'}
          style={{
            color: 'white',
            background: '#3F88DF',
            width: 'fit-content',
            height: '43px',
            border: '1px solid #3F88DF',
            marginLeft: '0.8em',
          }}
          onClick={() => {
            toggleModal('FE');
          }}
        />
      );
    }
    return null;
  };
  const getTitleStyle = text => {
    let fontSize = '1.5em',
      marginLeft = '-2.5em';
    if(text.length > 40){
      fontSize = '1.3em';
      marginLeft = '-.5em';
    }
    return { fontSize, marginLeft };
  }

  return (
    <div>
      <div className="versionControl" key={refreshKey}>
        <div
          style={{
            background: "white",
            backgroundPosition: "rear",
            width: "fit-content",
            position: "fixed",
            marginTop: "-.5em",
            height: "8.7em",
            width: "100%",
            marginLeft: "-.8em",
          }}
        />
<div style={{ display: 'flex', marginBottom: '0.5em' }}>
          <Button
            label={headerText}
            style={{
              color: 'black',
              fontSize: getTitleStyle(headerText).fontSize,
              fontWeight: 'bold',
              width: '90%',
              maxHeight: '2.5em',
              overflow: 'hidden',
              marginLeft: getTitleStyle(headerText).marginLeft,
              marginRight: '-.5em',
              textAlign: 'start',
              whiteSpace: 'pre-line',
            }}
          />
        </div>

        <div style={{ display: 'flex'}}>
          <div className="selectBox">
            <Select
              name="flowVersions"
              options={flowVersions}
              style={{ paddingLeft: '2rem' }}
              placeholder={getDefault()}
              noOptionsText="No Versions Published"
              onChange={e => {
                onSelectVersion(e);
                setUsePropLabel(false);
              }}
            />
          </div>
          {props.rawTree.length > 0 ? getButton() : null}
          {props.rawTree.length > 0 && getEngagementFlowBtn()}
          {props.rawTree.length > 0 && (
            <Button
              label={'Test'}
              style={{
                color: 'white',
                background: '#3F88DF',
                width: 'fit-content',
                height: '43px',
                border: '1px solid #3F88DF',
                marginLeft: '0.8em',
              }}
              onClick={() => {
                toggleModal('TEST');
              }}
            />
          )}
          {
            flowVersionInfo.published && (
              <button type="button" style={{ marginLeft: ".5em" }}>
                status: {flowVersionInfo.published}
              </button>
            )
          }
        </div>
      </div>

      <Modal
        isShowing={showModal}
        hide={toggleModal}
        content={getContent()}
        noOverflowY={modalType === 'FE' ? true : false}
        style={{
          width: modalType === 'FE' ? 'calc(100% - 280px)' : '40%',
          top: '80px',
          right: modalType === 'FE' ? '20px' : 'auto',
          height: modalType === 'FE' ? '80vh' : 'auto',
          maxHeight: modalType === 'FE' ? 'auto' : '80vh',
        }}
      />
    </div>
  );
}
