import React, { useState, useEffect, useContext } from 'react';

import AuthContext from 'contexts/AuthContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { API, graphqlOperation } from 'aws-amplify';
import { searchMessages } from 'graphql/queries';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
export default function SearchContainer(props) {
  const [value, setValue] = useState('');
  const [subanchorEl, setSubAnchorEl] = useState(false);
  const [subMenuForWebAndSMS, setSubMenuForWebAndSMS] = useState(false);
  const [subMenuForProspective, setSubMenuForProspective] = useState(false);
  const [filterButtonsActiveArray, setFilterButtonsActiveArray] = useState([]);
  const [refreshHighlight, setRefreshHighlight] = useState(0);

  const tokens = useContext(AuthContext);

  const menuHighlightColor = 'rgb(229, 232, 254);';
  const menuHighlightColor2 = 'rgb(255, 189, 39);';
  const isNonSunnyUser = false;

  const menuHightlightStyle = makeStyles(() => ({
    highlight: {
      background: menuHighlightColor,
    },
    empty: {},
  }));

  const menuHightlightStyle2 = makeStyles(() => ({
    highlight2: {
      //color: menuHighlightColor2,
      fontWeight: 'bold',
      display: 'flex',
      //background: "gold",
      borderRadius: '5em',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      fontSize: '0.8rem',
    },
    empty: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      fontSize: '0.8rem',
      color: 'none',
    },
  }));

  const classes = menuHightlightStyle();
  const classes2 = menuHightlightStyle2();

  const {
    searchInboxRef,
    setInboxSearch,
    setInboxSearchIgnoreCharMin,
    setInboxSearchKeywordMatches,
    handleFilterMenuOpen,
    handleFilterMenuOpen2,
    handleFilterMenuOpen3,
    handleFilterMenuOpen4,
    handleFilterMenuOpen5,
    filterAnchorEl,
    filterAnchorEl2,
    filterAnchorEl3,
    filterAnchorEl4,
    filterAnchorEl5,
    handleFilterMenuClose,
    handleFilterMenuClose2,
    handleFilterMenuClose3,
    handleFilterMenuClose4_Handler,
    handleFilterMenuClose5,
    filterConvosByTag,
    filterConvosByRole,
    filterConvosByRole2,
    filterConvosByModality,
    filterConvosByLevel,
    filterConvosByUndergradStatus,
    filterConvosByProspectiveStatus,
  } = props;

  let currentFilterName = 'All';
  if (props.currentFilterName) {
    currentFilterName = props.currentFilterName;
  }

  let currentUIFilterName = null;
  if (props.currentUIFilterName) {
    currentUIFilterName = props.currentUIFilterName;
  }

  let currentUIFilterName2 = null;
  if (props.currentUIFilterName2) {
    currentUIFilterName2 = props.currentUIFilterName2;
  }

  useEffect(() => {
    setValue(props.writeInboxSearch);
  }, [props.writeInboxSearch]);

  useEffect(() => {
    let highlight_array = props.filterButtonsActive(true) || [];
    //console.log("setting highlights:", highlight_array);
    setFilterButtonsActiveArray(highlight_array);
  }, [refreshHighlight]);

  useEffect(() => {
    setRefreshHighlight(getRandomNumber());
  }, [
    props.currentUIFilterName,
    props.currentUIFilterName2,
    props.currentFilterName,
    props.filterRoles,
    props.filterRoles2,
    props.filterModalityType,
    props.undergradStatus,
  ]);

  const openSubMenu = (event, type) => {
    setSubAnchorEl(event.currentTarget);
  };

  const closeSubMenu = () => {
    setSubAnchorEl(null);
  };

  const openSubMenuForProspective = (event, type) => {
    setSubMenuForProspective(event.currentTarget);
  };

  const openSubMenuForWebAndSMS = (event, type) => {
    setSubMenuForWebAndSMS(event.currentTarget);
  };

  const closeSubMenuForProspective = () => {
    setSubMenuForProspective(null);

    if (!isNonSunnyUser) {
      handleFilterMenuClose5();
    } else {
      console.log('Prospective SubMenu', subMenuForProspective);
      if (subMenuForProspective === null) {
        handleFilterMenuClose5();
      }
    }
  };

  const closeSubMenuForWebAndSMS = () => {
    setSubMenuForWebAndSMS(null);
    if (!isNonSunnyUser) {
      handleFilterMenuClose4_Handler();
    }
  };

  const handleFilterMenuClose4 = () => {
    console.log('SubMenu', subMenuForWebAndSMS);
    if (subMenuForWebAndSMS === null) {
      handleFilterMenuClose4_Handler();
    }
  };
  //Sample query for opportunity
  async function queryForData(asurite) {
    let payload = { asurite: asurite };
    let resp = await API.graphql(
      graphqlOperation(searchMessages, {
        operation: 'getOpportunityInfo',
        payload: JSON.stringify(payload),
      })
    );
  }
  const getRandomNumber = () => {
    return Math.random(0, 1);
  };

  //enterprise
  return (
    <div className="search-container">
      {/* Test ES Notes Search */}
      <div style={{ width: '100%', display: 'flex' }}>
        <div style={{ paddingLeft: '10px', width: '100%' }}>
          <FontAwesomeIcon icon={faSearch} color="grey" />
          <input
            style={{ height: '2.5em', width: '80%' }}
            placeholder="Search Dialogues"
            ref={searchInboxRef}
            value={value || ''}
            onChange={e => setValue(e.target.value)}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                setInboxSearch(e.target.value);
                setInboxSearchIgnoreCharMin(true);
                setInboxSearchKeywordMatches(false);
              }
            }}
          />
        </div>

        <div className="sort-div" onClick={handleFilterMenuOpen}>
          Filter
          <KeyboardArrowDownIcon fontSize="small" />
        </div>

        <Menu
          anchorEl={filterAnchorEl}
          keepMounted
          open={Boolean(filterAnchorEl)}
          onClose={handleFilterMenuClose}
          style={{ marginTop: '30px' }}
        >
          <MenuItem
            className={
              currentFilterName === 'All' && currentUIFilterName === null
                ? classes.highlight
                : classes.empty
            }
            onClick={() => filterConvosByTag(null)}
          >
            All
          </MenuItem>
          <MenuItem
            className={
              currentFilterName === 'read' ? classes.highlight : classes.empty
            }
            onClick={() => filterConvosByTag('read')}
          >
            Read
          </MenuItem>
          <MenuItem
            className={
              currentFilterName === 'unseenBy'
                ? classes.highlight
                : classes.empty
            }
            onClick={() => filterConvosByTag('unseenBy')}
          >
            Unread
          </MenuItem>
          <MenuItem
            className={
              currentFilterName === 'needsReview'
                ? classes.highlight
                : classes.empty
            }
            onClick={() => filterConvosByTag('needsReview', ['Kendra'])}
          >
            Needs Review
          </MenuItem>
          <MenuItem
            className={
              currentFilterName === 'Kendra'
                ? classes.highlight
                : classes.empty
            }
            onClick={() => filterConvosByTag('Kendra')}
          >
            Needs Kendra Review
          </MenuItem>
          <MenuItem
            className={
              currentFilterName === 'agentAssigned'
                ? classes.highlight
                : classes.empty
            }
            onClick={() => filterConvosByTag('agentAssigned')}
          >
            Assigned
          </MenuItem>
          <MenuItem
            className={
              currentFilterName === 'hasAlert'
                ? classes.highlight
                : classes.empty
            }
            onClick={() => filterConvosByTag('hasAlert')}
          >
            Has alert
          </MenuItem>
        </Menu>
      </div>

      <div className="search-filter-row">
        <div
          className={
            filterButtonsActiveArray.includes('SMS') ||
            filterButtonsActiveArray.includes('Web/Auth') ||
            filterButtonsActiveArray.includes('Voice')
              ? classes2.highlight2
              : classes2.empty
          }
          onClick={handleFilterMenuOpen2}
        >
          Modality
          <KeyboardArrowDownIcon fontSize="small" />
        </div>

        <Menu
          anchorEl={filterAnchorEl2}
          keepMounted
          open={Boolean(filterAnchorEl2)}
          onClose={handleFilterMenuClose2}
          style={{ marginTop: '30px' }}
        >
          <MenuItem
            className={
              filterButtonsActiveArray.includes('SMS')
                ? classes.highlight
                : classes.empty
            }
            onClick={() => {
              closeSubMenuForWebAndSMS();
              filterConvosByModality('sms');
              setRefreshHighlight(getRandomNumber());
            }}
          >
            SMS
          </MenuItem>

          <MenuItem
            className={
              filterButtonsActiveArray.includes('Web/Auth')
                ? classes.highlight
                : classes.empty
            }
            onClick={() => {
              closeSubMenuForWebAndSMS();
              filterConvosByModality('web');
              setRefreshHighlight(getRandomNumber());
            }}
          >
            Web/Auth
          </MenuItem>

          <MenuItem
            className={
              filterButtonsActiveArray.includes('Voice')
                ? classes.highlight
                : classes.empty
            }
            onClick={() => {
              closeSubMenuForWebAndSMS();
              filterConvosByModality('voice');
              setRefreshHighlight(getRandomNumber());
            }}
          >
            Voice
          </MenuItem>
        </Menu>

        <div
          className={
            filterButtonsActiveArray.includes('Current Student') ||
            filterButtonsActiveArray.includes('Prospective Student') ||
            filterButtonsActiveArray.includes('Freshman') ||
            filterButtonsActiveArray.includes('Transfer') ||
            filterButtonsActiveArray.includes('Graduate') ||
            filterButtonsActiveArray.includes('Other')
              ? classes2.highlight2
              : classes2.empty
          }
          onClick={handleFilterMenuOpen3}
        >
          Audience Type
          <KeyboardArrowDownIcon fontSize="small" />
        </div>

        <Menu
          anchorEl={filterAnchorEl3}
          keepMounted
          variant="selectedMenu"
          open={Boolean(filterAnchorEl3)}
          onClose={handleFilterMenuClose3}
          style={{ marginTop: '30px' }}
        >
          <MenuItem
            className={
              filterButtonsActiveArray.includes('Current Student')
                ? classes.highlight
                : classes.empty
            }
            onClick={() => {
              filterConvosByRole(['ENROLLED']);
              setRefreshHighlight(getRandomNumber());
            }}
          >
            Current student
          </MenuItem>

          <MenuItem
            className={
              filterButtonsActiveArray.includes('Prospective Student')
                ? classes.highlight
                : classes.empty
            }
            onClick={e => {
              filterConvosByRole(['UAPP', 'GAPP']);
              //filterConvosByRole2([]);
              filterConvosByProspectiveStatus(null);
              openSubMenuForProspective(e);
              setRefreshHighlight(getRandomNumber());
            }}
          >
            <div className="subMenuParent">
              <div className="subMenuParentTitle">Prospective Student</div>
              <ChevronRightIcon style={{ width: '0.7em' }} />
            </div>
          </MenuItem>

          <Menu
            keepMounted
            anchorEl={subMenuForProspective}
            open={Boolean(subMenuForProspective)}
            onClose={closeSubMenuForProspective}
            //anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            PaperProps={{
              style: {
                maxHeight: '20em',
              },
            }}
          >
            <MenuItem
              className={
                filterButtonsActiveArray.includes('First Year')
                  ? classes.highlight
                  : classes.empty
              }
              onClick={() => {
                closeSubMenuForProspective();
                filterConvosByProspectiveStatus('first year');
                setRefreshHighlight(getRandomNumber());
              }}
            >
              First Year
            </MenuItem>
            <MenuItem
              className={
                filterButtonsActiveArray.includes('Transfer')
                  ? classes.highlight
                  : classes.empty
              }
              onClick={() => {
                closeSubMenuForProspective();
                filterConvosByProspectiveStatus('transfer');
                setRefreshHighlight(getRandomNumber());
              }}
            >
              Transfer
            </MenuItem>

            <MenuItem
              className={
                filterButtonsActiveArray.includes('Graduate')
                  ? classes.highlight
                  : classes.empty
              }
              onClick={() => {
                closeSubMenuForProspective();
                filterConvosByProspectiveStatus('graduate');
                setRefreshHighlight(getRandomNumber());
              }}
            >
              Graduate
            </MenuItem>

            <MenuItem
              className={
                filterButtonsActiveArray.includes('Other')
                  ? classes.highlight
                  : classes.empty
              }
              onClick={() => {
                closeSubMenuForProspective();
                filterConvosByProspectiveStatus('other');
                setRefreshHighlight(getRandomNumber());
              }}
            >
              Other
            </MenuItem>
          </Menu>
        </Menu>

        {filterButtonsActiveArray.includes('Current Student') && (
          <React.Fragment>
            <div
              className={
                filterButtonsActiveArray.includes('Undergraduate') ||
                filterButtonsActiveArray.includes('Graduate') ||
                filterButtonsActiveArray.includes('Freshman') ||
                filterButtonsActiveArray.includes('Transfer')
                  ? classes2.highlight2
                  : classes2.empty
              }
              onClick={handleFilterMenuOpen4}
            >
              Academic Level
              <KeyboardArrowDownIcon fontSize="small" />
            </div>
            <Menu
              anchorEl={filterAnchorEl4}
              keepMounted
              variant="selectedMenu"
              open={Boolean(filterAnchorEl4)}
              onClose={handleFilterMenuClose4}
              style={{ marginTop: '30px' }}
            >
              <MenuItem
                className={
                  filterButtonsActiveArray.includes('Undergraduate')
                    ? classes.highlight
                    : classes.empty
                }
                onClick={e => {
                  filterConvosByRole2(['UGS']);
                  openSubMenuForWebAndSMS(e);
                  setRefreshHighlight(getRandomNumber());
                }}
                /*
                onMouseEnter={(e) => {
                  openSubMenuForWebAndSMS(e);
                }}
                */
              >
                <div className="subMenuParent">
                  <div className="subMenuParentTitle">Undergraduate</div>
                  <ChevronRightIcon style={{ width: '0.7em' }} />
                </div>
              </MenuItem>

              <Menu
                keepMounted
                anchorEl={subMenuForWebAndSMS}
                open={Boolean(subMenuForWebAndSMS)}
                onClose={closeSubMenuForWebAndSMS}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                PaperProps={{
                  style: {
                    maxHeight: '20em',
                  },
                }}
              >
                <MenuItem
                  className={
                    filterButtonsActiveArray.includes('Freshman')
                      ? classes.highlight
                      : classes.empty
                  }
                  onClick={() => {
                    closeSubMenuForWebAndSMS();
                    filterConvosByUndergradStatus('freshman');
                    setRefreshHighlight(getRandomNumber());
                  }}
                >
                  Freshman
                </MenuItem>
                <MenuItem
                  className={
                    filterButtonsActiveArray.includes('Transfer')
                      ? classes.highlight
                      : classes.empty
                  }
                  onClick={() => {
                    closeSubMenuForWebAndSMS();
                    filterConvosByUndergradStatus('transfer');
                    setRefreshHighlight(getRandomNumber());
                  }}
                >
                  Transfer
                </MenuItem>
              </Menu>
              <MenuItem
                className={
                  filterButtonsActiveArray.includes('Graduate')
                    ? classes.highlight
                    : classes.empty
                }
                onClick={() => {
                  //closeSubMenu();
                  filterConvosByRole2(['GS']);
                  filterConvosByUndergradStatus(null);
                  setRefreshHighlight(getRandomNumber());
                }}
              >
                Graduate
              </MenuItem>
            </Menu>
          </React.Fragment>
        )}

        {filterButtonsActiveArray.includes('Place Holder') && ( // "Prospective Student"
          <React.Fragment>
            <div className="sort-div" onClick={handleFilterMenuOpen5}>
              Opportunity
              <KeyboardArrowDownIcon fontSize="small" />
            </div>

            {
              <Menu
                anchorEl={filterAnchorEl5}
                keepMounted
                open={Boolean(filterAnchorEl5)}
                onClose={handleFilterMenuClose5}
                style={{ marginTop: '30px' }}
              >
                <MenuItem
                  className={
                    currentUIFilterName === 'opport' //need to change
                      ? classes.highlight
                      : classes.empty
                  }
                  onClick={() => {
                    filterConvosByRole(['ENROLLED']); //need to change
                    setRefreshHighlight(getRandomNumber());
                  }}
                >
                  Opportunity 1
                </MenuItem>

                <MenuItem
                  className={
                    currentUIFilterName === 'prospectiveStudent' // need to change
                      ? classes.highlight
                      : classes.empty
                  }
                  onClick={() => {
                    filterConvosByRole(['UAPP', 'GAPP']); // need to change
                    setRefreshHighlight(getRandomNumber());
                  }}
                >
                  Opportunity 2
                </MenuItem>
              </Menu>
            }
          </React.Fragment>
        )}
      </div>
    </div>
  );
}
