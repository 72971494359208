import React, { useState, useEffect } from "react";
import moment from "moment";
import { MiniTable, Input, Button, Textarea, Form } from "../../../stories";
import "../Dialogues.scss";
import { API, graphqlOperation } from "aws-amplify";
import { updateDialogueNotes } from "graphql/mutations";

export default function SearchModalContent(props) {
  const [isEdit, setIsEdit] = useState(false);
  const [currentNote, setCurrentNote] = useState({});
  const [searchTableData, setSearchTableData] = useState(props.tableData);
  const [searchRawData, setSearchRawData] = useState(props.rawData);
  const [searchInput, setSearchInput] = useState("");

  const searchNotes = () => {
    if (searchInput.length > 0) {
      let result = props.rawData.filter((note) => {
        return note.content.includes(searchInput);
      });
      let resultsTableData = props.formatNotesTableData(result);
      // console.log(result);
      setSearchTableData(resultsTableData);
      setSearchRawData(result);
    }
  };

  const onEditHandler = (note) => {
    setIsEdit(true);
    setCurrentNote(note);
  };

  const formData = [
    {
      title: "DATE",
      component: <p>{props.dateFormatter(currentNote.updatedAt, true)}</p>,
    },
    {
      title: "USER",
      component: <p>{props.getUsername()}</p>,
    },
    {
      title: "AGENT",
      component: <p>{props.getAssigneeName(currentNote.author)}</p>,
    },
    {
      title: "CONTENT",
      component: (
        <Textarea form name="content" stateValue={currentNote.content} />
      ),
    },
  ];

  const updateNoteOnSubmit = async (data) => {
    if (data.content) {
      try {
        let payload = {
          id: currentNote.id,
          content: data.content,
        };

        let messageNoteOperation = await API.graphql(
          graphqlOperation(updateDialogueNotes, {
            input: payload,
          })
        );
        let updatedNote = messageNoteOperation.data.updateDialogueNotes;
        let notesCopy = props.rawData;

        for (let i = 0; i < notesCopy.length; i++) {
          if (notesCopy[i].id === updatedNote.id) {
            notesCopy[i] = updatedNote;
          }
        }

        props.setConvoNotes(notesCopy);
        setIsEdit(false);
        setCurrentNote({});
      } catch (err) {
        console.log(err);
      }
    }
  };

  const backHandler = () => {
    setIsEdit(false);
    setCurrentNote({});
  };

  const closeHandler = () => {
    props.toggle();
  };

  const handleInputKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      searchNotes();
    }
  };

  return (
    <>
      {!isEdit ? (
        <div style={{ padding: "1rem" }}>
          <h2>SEARCH NOTES</h2>
          <div style={{ display: "flex", marginBottom: "1rem" }}>
            <Input
              onChange={(e) => setSearchInput(e.target.value)}
              onKeyPress={handleInputKeyPress}
              id="searchInput"
            />
            <Button
              style={{
                backgroundColor: "#3F88DF",
                color: "white",
                width: "fit-content",
                padding: "0.2rem 1.4rem",
                marginLeft: "1rem",
              }}
              label="SEARCH"
              onClick={searchNotes}
            />
            <Button
              style={{
                backgroundColor: "#EDEDED",
                width: "fit-content",
                padding: "0.2rem 1.4rem",
                marginLeft: "1rem",
                border: "1px solid #8D8D8D",
                color: "black",
              }}
              label="RESET"
              onClick={() => {
                document.getElementById("searchInput").value = "";
                setSearchTableData(props.tableData);
                setSearchRawData(props.rawData);
                setSearchInput("");
              }}
            />
          </div>
          <MiniTable
            tableData={searchTableData}
            rawData={searchRawData}
            columnTitles={["Date", "Agent", ""]} // empty quotes is a work around for spacing
            dataKeys={["date", "agentDisplay"]}
            onEditHandler={onEditHandler}
            // onViewHandler={}
          />
        </div>
      ) : (
        <Form
          title="NOTE"
          back
          close
          backHandler={backHandler}
          closeHandler={closeHandler}
          onSubmit={updateNoteOnSubmit}
          data={formData}
        />
      )}
    </>
  );
}
