import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import { useTheme } from "@material-ui/core/styles";

import { SectionHeader } from "components/SectionHeader";
// import { Snackbar } from "stories";

export default function Dashboard(props) {
  const theme = useTheme();

  return (
    <div style={{ background: "#F4F4F4", height: "100vh" }}>
      <SectionHeader
        title="Dashboard"
        description="This is where you might add a short description."
      />

      <Paper style={theme.components.paper}>
        <h3 style={{ padding: "2rem" }}>add dashboard content...</h3>
      </Paper>
    </div>
  );
}

Dashboard.propTypes = {
  navigation: PropTypes.object
};
