import React, { useEffect, useState, useContext } from 'react';

import { Checkbox } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { API, graphqlOperation } from 'aws-amplify';

import { byFlowId, searchMessages } from 'graphql/queries';

import moment from 'moment-timezone';

function getEngagementModalData(
  versionTitles,
  set_FE_modal_start,
  set_FE_modal_date,
  set_FE_modal_endDate,
  set_FE_modal_end,
  set_FE_modal_loading_message,
  set_FE_modal_data,
  set_FE_modal_table_data,
  FE_modal_data,
  flowId,
  flowVersionInfo,
  nextToken,
  setFEDateInd
) {
  if (flowVersionInfo.published) {
    let params = {
      flowId: flowId,
      filter: {
        userId: {
          notContains: '-dashboard',
        },
      },
      limit: 100,
    };

    if (nextToken) {
      params.nextToken = nextToken;
    }

    API.graphql(graphqlOperation(byFlowId, params))
      .then(async resp => {
        set_FE_modal_loading_message('Loading table data (0%)');

        let flowNodes = [];
        nextToken = resp.data.byFlowId.nextToken;
        let all = resp.data.byFlowId.items;

        for (var i = 0; i < all.length; ++i) {
          //if (!all[i].deleted) {
          flowNodes.push(all[i]);
          //}
        }

        let newModalData = [...FE_modal_data];
        if (!nextToken) {
          //dump previous data
          newModalData = [...flowNodes];
        } else {
          //keep previous data
          newModalData = newModalData.concat(flowNodes);
        }

        //console.log("FE_modal_data is", newModalData);

        //add question to row
        //swap version numbers & titles
        //console.log("flowVersionInfo is", flowVersionInfo);
        newModalData.map(flowResponse => {
          // if (typeof flowResponse.question === "undefined") {
          flowResponse.question = '';
          flowResponse.buttonMatch = '';
          let flowData = JSON.parse(flowVersionInfo.queryableData);

          flowData.some(node => {
            if (node.id === flowResponse.nodeId) {
              flowResponse.question = node.text;
              if (
                flowResponse.type === 'branch' &&
                flowResponse.button.includes('-')
              ) {
                flowResponse.inputDump = node.matches.filter(button => {
                  if (button.id === flowResponse.button) {
                    return true;
                  }
                })[0];

                if (flowResponse.inputDump) {
                  flowResponse.inputDump = flowResponse.inputDump.match;
                } else {
                  console.log('failed to find match: ', flowResponse, node);
                  flowResponse.inputDump = null;
                }
              } else {
                if (node.match !== null) {
                  flowResponse.buttonMatch = node.match;
                }
              }
            }
          });

          let getTitle = versionTitles.filter(object => {
            if (object.version === flowResponse.flowVersion) {
              return true;
            }
          });

          if (getTitle[0]) {
            flowResponse.flowVersion =
              getTitle[0].title +
              ' (Version ' +
              parseInt(flowResponse.flowVersion.substr(3)) +
              ')';
          }
          // }

          //change timestamp
          flowResponse.createdAt = moment(flowResponse.createdAt)
            .tz('America/Phoenix')
            .format('MM/DD/YYYY HH:mm:ss');

          return flowResponse;
        });

        //add user data to each row
        let userCache = {};
        for (let getUser_i = 0; getUser_i < newModalData.length; getUser_i++) {
          if (typeof newModalData[getUser_i].displayName === 'undefined') {
            let user = newModalData[getUser_i].userId;

            let pay = {};
            let wasDashboard = false;

            pay.botType = 'sunny';

            if (user.includes('+')) {
              pay.phoneNumber = user;
            } else {
              if (user.includes('-dashboard')) {
                wasDashboard = true;
                pay.asurite = user.replace('-dashboard', '');
              }
            }

            let resp = [{}];

            if (typeof userCache[user] !== 'undefined') {
              resp[0] = userCache[user];
            } else {
              resp = await API.graphql(
                graphqlOperation(searchMessages, {
                  operation: 'queryForUsers',
                  payload: JSON.stringify(pay),
                })
              );

              resp = JSON.parse(resp.data.searchMessages);

              if (resp.length > 0) {
                if (wasDashboard === true) {
                  resp[0].asurite += '-dashboard';
                }
              }

              //console.log(resp[0]);

              userCache[user] = resp[0];
            }

            set_FE_modal_loading_message(
              'Loading table data (' +
                parseInt((getUser_i / (newModalData.length - 1)) * 100) +
                '%)'
            );

            try {
              newModalData[
                getUser_i
              ].displayName = resp[0].displayName.includes('undefined')
                ? 'N/A'
                : resp[0].displayName;
            } catch (e) {
              newModalData[getUser_i].displayName = 'N/A';
            }

            try {
              newModalData[getUser_i].asurite = resp[0].asurite.includes(
                'undefined'
              )
                ? 'N/A'
                : resp[0].asurite;
            } catch (e) {
              newModalData[getUser_i].asurite = 'N/A';
            }

            if (
              newModalData[getUser_i].asurite === 'NONE' &&
              resp[0].type === 'sms'
            ) {
              newModalData[getUser_i].asurite = resp[0].phoneNumber;
            }

            try {
              newModalData[getUser_i].emplid = resp[0].emplid.includes(
                'undefined'
              )
                ? 'N/A'
                : resp[0].emplid;
            } catch (e) {
              newModalData[getUser_i].emplid = 'N/A';
            }
            try {
              newModalData[getUser_i].crmId = resp[0].crmId.includes(
                'undefined'
              )
                ? 'N/A'
                : resp[0].crmId;
            } catch (e) {
              newModalData[getUser_i].crmId = 'N/A';
            }
          }
        }

        set_FE_modal_data(newModalData);
        if (!nextToken) {
          set_FE_modal_loading_message('Loading table data (100%)');
          if (newModalData.concat(flowNodes).length === 0) {
            set_FE_modal_loading_message('No data returned.');
          }
        }

        //assume table ready reach time
        let myNewTableArray = [];

        let dateind = -1;

        newModalData.forEach(enhancedFlowData => {
          let myTableRow = [];
          myTableRow.push(enhancedFlowData.displayName); //display name

          myTableRow.push(enhancedFlowData.asurite); //asurite
          myTableRow.push(enhancedFlowData.emplid); //emplid

          myTableRow.push(enhancedFlowData.emplid); //emplid
          myTableRow.push(enhancedFlowData.question); //question
          myTableRow.push(enhancedFlowData.inputDump); //response
          myTableRow.push(enhancedFlowData.type); //node type
          myTableRow.push(enhancedFlowData.buttonMatch); //button match
          myTableRow.push(enhancedFlowData.createdAt); //date/time
          dateind = [...myTableRow].length - 1;
          myTableRow.push(enhancedFlowData.flowVersion); //version
          myNewTableArray.push(myTableRow);
        });

        setFEDateInd(dateind);

        //create timestamp array & sort data by timestamp
        let allTimes = myNewTableArray.map(row => {
          return moment(row[dateind], 'MM/DD/YYYY HH:mm:ss').format('x');
        });

        myNewTableArray.sort((a, b) => {
          if (
            moment(a[dateind], 'MM/DD/YYYY HH:mm:ss').isBefore(
              b[dateind],
              'MM/DD/YYYY HH:mm:ss'
            )
          ) {
            return 1;
          } else if (
            moment(a[dateind], 'MM/DD/YYYY HH:mm:ss').isAfter(
              b[dateind],
              'MM/DD/YYYY HH:mm:ss'
            )
          ) {
            return -1;
          } else {
            return 0;
          }
        });

        console.log(allTimes);

        set_FE_modal_start(Math.min.apply(Math, allTimes), 'x');
        set_FE_modal_date(Math.min.apply(Math, allTimes), 'x');
        set_FE_modal_endDate(Math.max.apply(Math, allTimes), 'x');
        set_FE_modal_end(Math.max.apply(Math, allTimes), 'x');

        set_FE_modal_table_data(myNewTableArray);

        if (nextToken) {
          getEngagementModalData(flowVersionInfo, nextToken);
        }
      })
      .catch(err => {
        console.log('There was an issue getting tree data', err);
      });
  }
}

const BlueCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#3f88df',
      opacity: 1,
      '&:hover': {
        background: '#dddddd',
        opacity: 1,
      },
    },
    '&:hover': {
      background: '#dddddd',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

export { BlueCheckbox, getEngagementModalData };
