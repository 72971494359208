/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": process.env.REACT_APP_AWS_REGION,
    "aws_appsync_graphqlEndpoint": process.env.REACT_APP_APPSYNC,
    "aws_appsync_region": process.env.REACT_APP_AWS_REGION,
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_cloud_logic_custom": [
        {
            "name": process.env.REACT_APP_LINK_API_NAME,
            "endpoint": process.env.REACT_APP_LINK_API_URL,
            "region": process.env.REACT_APP_AWS_REGION
        }
    ],
    "aws_content_delivery_bucket": process.env.REACT_APP_CMS_S3_BUCKET_NAME,
    "aws_content_delivery_bucket_region": process.env.REACT_APP_AWS_REGION,
    "aws_content_delivery_url": process.env.REACT_APP_CMS_DISTRIBUTION_DOMAIN
};


export default awsmobile;
