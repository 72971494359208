import React, { useState, useEffect, useContext } from 'react';
import { useTheme } from '@material-ui/core/styles';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useFormContext } from 'react-hook-form';
import AuthContext from 'contexts/AuthContext';

import { Select, Textarea, ErrorLabel, FlowSelect } from 'stories';
import { store as GlobalStore } from 'contexts/GlobalStore';
// import Variables from "./Variables";
import {
  optionsGenerateUID,
  onChangeAudience,
  onClickRemove,
  computeAudienceData,
  computeTextareaData,
  // renderAudience,
} from './utility';

export default function Options(props) {
  const theme = useTheme();
  const { register, setValue, clearError, errors } = useFormContext();
  const GlobalState = useContext(GlobalStore);
  const tokens = useContext(AuthContext);
  const dispatchGlobal = GlobalState.dispatch;
  const { checkForErrors } = GlobalState.state;

  const [propertyID, setPropertyID] = useState(null);
  const [audience, setAudience] = useState(null);
  const [answer, setAnswer] = useState(null);
  const [image, setImage] = useState(null);
  const [data, setData] = useState(null);

  const [buttonText, setButtonText] = useState(props.type);

  useEffect(() => {
    clearError();
    let ID;
    if (props.uid) {
      setPropertyID(props.uid);
      ID = props.uid;
    } else {
      ID = optionsGenerateUID(props);
      setPropertyID(ID);
    }
    if (props.data && props.data[props.uid]) {
      setAnswer(props.data[props.uid].answer);
      setValue(`textareaAnswer ${ID}`, props.data[props.uid].answer);
      setImage(props.data[props.uid].image);
    } else {
      if (!data) {
        let foundType = props.type === 'chat' ? 'chat' : 'sms';
        foundType = props.type === 'voice' ? 'voice' : props.type;

        return props.setData(prevData => ({
          ...prevData,
          [ID]: {
            audience: null,
            answer: null,
            flow: null,
            flowVersion: null,
            type: foundType,
          },
        }));
      } else {
        return props.setData(prevData => ({
          ...prevData,
          [propertyID]: data,
        }));
      }
    }
  }, []);

  useEffect(() => {
    if (Array.isArray(audience) && !audience.length) {
      setAudience(null);
      setData(prevData => ({ ...prevData, audience: null }));
    } else {
      const audienceEveryone = audience && audience[0] === 'EV' ? true : false;
      setData(prevData => ({ ...prevData, audience, audienceEveryone }));
    }
  }, [audience]);

  useEffect(() => {
    if (window.location.href.includes('CustomIntro')) {
      if (!data) {
        return;
      } else {
        props.setData(prevData => ({
          ...prevData,
          [propertyID]: {
            ...data,
            type: props.type === 'chat' ? 'chat' : 'sms',
          },
        }));
      }
    } else {
      if (!data || !data.answer || !data.audience) {
        return;
      } else {
        let foundType = props.type === 'chat' ? 'chat' : 'sms';
        foundType = props.type === 'voice' ? 'voice' : props.type;
        props.setData(prevData => ({
          ...prevData,
          [propertyID]: {
            ...data,
            type: foundType,
          },
        }));
      }
    }
  }, [data]);

  const handleClickAway = () => {
    if (props.data && props.data[props.uid]) {
      setData(prevData => ({ ...prevData, answer }));
    } else {
      if (answer === '' || !answer) {
        dispatchGlobal({
          type: 'setCheckForErrors',
          checkForErrors: true,
        });
        setValue(`textareaAnswer ${propertyID}`, null);
        setAnswer(null);
        setData(prevData => ({ ...prevData, answer: null }));
      }
      setData(prevData => ({ ...prevData, answer }));
    }
  };

  const onChangeTextarea = text => {
    dispatchGlobal({
      type: 'setCheckForErrors',
      checkForErrors: false,
    });
    if (typeof text === 'string') {
      if (text === '') {
        dispatchGlobal({
          type: 'setCheckForErrors',
          checkForErrors: true,
        });
        setValue(`textareaAnswer ${propertyID}`, null);
        setAnswer(null);
        setData(prevData => ({ ...prevData, answer: null }));
        return setAnswer(null);
      } else {
        return setAnswer(text);
      }
    }
  };

  const updateFlow = e => {
    let toAdd = {};

    if (e.value === '') {
      e = {};
      toAdd = {
        flow: null,
        flowVersion: null,
      };
    } else {
      toAdd = {
        flow: JSON.stringify(e),
      };
    }

    setData(prevData => ({ ...prevData, ...toAdd }));
  };

  const updateFlowVersion = e => {
    setData(prevData => {
      return { ...prevData, flowVersion: JSON.stringify(e) };
    });
  };

  function getAnswerType(type) {
    let returnString = '';

    if (type === 'chat') {
      returnString = 'WEB ANSWER';
    } else if (type === 'sms') {
      returnString = 'SMS ANSWER';
    } else if (type === 'voice') {
      returnString = 'VOICE ANSWER';
    }

    return returnString.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
      //capitalize the first letter of every word
      return a.toUpperCase();
    });
  }

  const renderFirstElement = () => {
    console.log('props.type is', props.type);
    if (
      props.type === 'chat' ||
      props.type === 'sms' ||
      props.type === 'text'
    ) {
      return (
        <label
          style={{
            ...theme.typography.subtitle1,
            fontWeight: '600',
          }}
        >
          {getAnswerType(props.type)}
        </label>
      );
    } else {
      return (
        <input
          name="buttonText"
          type="text"
          className="input input--textAnswer"
          value={buttonText}
          onChange={onChangeButtonText}
          ref={props.required ? register({ required: true }) : register()}
        />
      );
    }
  };

  const onChangeButtonText = input => {
    if (typeof input === 'string') {
      setButtonText(input);
    } else {
      setButtonText(input.currentTarget.value);
    }
  };

  const { dataKey, setType, setOptions, required, ...validProps } = props;

  if (window.location.href.includes('CustomIntroForm')) {
    return (
      <div
        style={{
          // display: 'flex',
          //lexDirection: 'column',
          marginTop: '0.5rem',
          padding: '1rem',
          border: `1px solid ${theme.palette.general.deepGrey}`,
          borderRadius: '4px',
          position: 'relative',
        }}
      >
        <div
          className="CircleOutline"
          style={{ position: 'absolute', top: '0.53em', right: '0.54em' }}
        >
          <RemoveCircleOutlineIcon
            style={{
              fontSize: '30px',
              color: theme.palette.general.deepGrey,
              float: 'right',
              cursor: 'pointer',
            }}
            onClick={() => onClickRemove(props)}
          />
        </div>
        <div>
          <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            <div
              style={{
                display: 'flex',
                flex: 0.33,
                fontWeight: 700,
                marginBottom: '1em',
                marginTop: 0,
              }}
            >
              Button Text
            </div>
            <div
              style={
                {
                  //display: 'flex',
                  //flex: 0.33,
                }
              }
            >
              {renderFirstElement()}
            </div>

            {(!data || !data.audience) && checkForErrors && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  width: 'fit-content',
                }}
              >
                <ErrorLabel message="This field is required" />
              </div>
            )}
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '1px',
                }}
              >
                <div style={{ display: 'flex', flex: 1 }} />

                <input
                  {...validProps}
                  required={props.required ? true : undefined}
                  name={'textareaAnswer ' + propertyID}
                  ref={
                    props.required ? register({ required: true }) : register()
                  }
                  value={
                    props.data && props.data[props.uid]
                      ? computeTextareaData(data, props.data[props.uid].answer)
                      : computeTextareaData(data)
                  }
                  readOnly
                  autoComplete="off"
                  style={{
                    opacity: 0,
                    height: '1px',
                    width: '1px',
                    cursor: 'default',
                  }}
                />

                <div style={{ display: 'flex', flex: 1 }} />
              </div>

              <label
                style={{
                  ...theme.typography.subtitle2,
                  fontWeight: '600',
                  margin: '1rem 0',
                }}
              >
                Flow to Execute:{' '}
              </label>
              <FlowSelect
                form
                updateFlow={updateFlow}
                updateFlowVersion={updateFlowVersion}
                showResetButton={true}
                // menuPlacement="top"
                name="flow"
                type={props.type}
                data={data}
                setData={setData}
                propertyID={propertyID}
                validProps={{ ...validProps }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '0.5rem',
          padding: '1rem',
          border: `1px solid ${theme.palette.general.deepGrey}`,
          borderRadius: '4px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <div
            style={{
              display: 'flex',
              flex: 0.33,
            }}
          >
            <label
              style={{
                ...theme.typography.subtitle1,
                fontWeight: '600',
              }}
            >
              {getAnswerType(props.type)}
            </label>
          </div>
          <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            <div
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                alignItems: 'flex-start',
              }}
            >
              <Select
                checkbox
                stateValue={
                  props.data && props.data[props.uid]
                    ? props.data[props.uid].audience
                    : null
                }
                onChange={newAudience =>
                  onChangeAudience(
                    newAudience,
                    props,
                    data,
                    setData,
                    setAudience,
                    propertyID
                  )
                }
                style={{
                  width: '100%',
                  background: 'white',
                }}
              />
              <RemoveCircleOutlineIcon
                style={{
                  fontSize: '30px',
                  marginLeft: '0.5rem',
                  color: theme.palette.general.deepGrey,
                  cursor: 'pointer',
                }}
                onClick={() => onClickRemove(props)}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                height: '1px',
              }}
            >
              <div style={{ display: 'flex', flex: 1 }} />
              <input
                {...validProps}
                required={props.required ? true : undefined}
                name={'audienceAnswer ' + propertyID}
                ref={props.required ? register({ required: true }) : register()}
                value={computeAudienceData(data)}
                readOnly
                autoComplete="off"
                style={{
                  opacity: 0,
                  height: '1px',
                  width: '1px',
                  cursor: 'default',
                }}
              />
              <div style={{ display: 'flex', flex: 1 }} />
            </div>
            {(!data || !data.audience) && checkForErrors && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  width: 'fit-content',
                }}
              >
                <ErrorLabel message="This field is required" />
              </div>
            )}
          </div>
        </div>
        {audience !== [] && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '1rem',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '1rem',
                justifyContent: 'center',
                alignItems: 'flex-start',
              }}
            >
              <label
                style={{
                  ...theme.typography.subtitle2,
                  fontWeight: '600',
                  marginBottom: '1rem',
                }}
              >
                Answer:{' '}
              </label>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <ClickAwayListener onClickAway={handleClickAway}>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ flex: 1 }}>
                      <Textarea
                        name="testing123"
                        propertyID={propertyID}
                        value={answer}
                        //used for edit image below
                        image={image}
                        onChange={onChangeTextarea}
                        charLimit={props.type === 'voice' ? undefined : 160}
                        wordLimit={props.type === 'voice' ? 100 : undefined}
                        allowextras={
                          window.location.href.includes('alert-add')
                            ? 'image,emoji,var,imgGallery'
                            : 'image,emoji,var,imgGallery,links'
                        }
                        form="true"
                        isSMS
                        style={{ display: 'flex', width: '100%' }}
                      />
                    </div>

                    {(!data || !data.answer) && checkForErrors && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          width: 'fit-content',
                        }}
                      >
                        <ErrorLabel message="This field is required" />
                      </div>
                    )}
                  </div>
                </ClickAwayListener>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '1px',
                  }}
                >
                  <div style={{ display: 'flex', flex: 1 }} />

                  <input
                    {...validProps}
                    required={props.required ? true : undefined}
                    name={'textareaAnswer ' + propertyID}
                    ref={
                      props.required ? register({ required: true }) : register()
                    }
                    value={
                      props.data && props.data[props.uid]
                        ? computeTextareaData(
                            data,
                            props.data[props.uid].answer
                          )
                        : computeTextareaData(data)
                    }
                    readOnly
                    autoComplete="off"
                    style={{
                      opacity: 0,
                      height: '1px',
                      width: '1px',
                      cursor: 'default',
                    }}
                  />

                  <div style={{ display: 'flex', flex: 1 }} />
                </div>
                <label
                  style={{
                    ...theme.typography.subtitle2,
                    fontWeight: '600',
                    marginBottom: '1rem',
                  }}
                >
                  Escalation:{' '}
                </label>
                <Select
                  name="escalation"
                  placeholder="-- Select an Escalation --"
                  options={props.escalationData}
                  onChange={e => {
                    setData(prevData => ({ ...prevData, answerEscalation: e }));
                  }}
                  data={data}
                  setData={setData}
                  updateEscalationId={props.answerEscalationId}
                />
                <label
                  style={{
                    ...theme.typography.subtitle2,
                    fontWeight: '600',
                    margin: '1rem 0',
                  }}
                >
                  Flow to Execute:{' '}
                </label>
                <FlowSelect
                  form
                  updateFlow={updateFlow}
                  updateFlowVersion={updateFlowVersion}
                  showResetButton={true}
                  // menuPlacement="top"
                  name="flow"
                  type={props.type}
                  data={data}
                  setData={setData}
                  propertyID={propertyID}
                  validProps={{ ...validProps }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
