import React, { useState, useEffect, useContext } from 'react';
import { Select, Checkbox, Input } from 'stories';
import AuthContext from 'contexts/AuthContext';
import { audiences } from 'pages/FlowMessages/audiences.js';

export default function SaveNode(groups, node, viewOnly) {
  const [shouldSave, setShouldSave] = useState(false);
  const tokens = useContext(AuthContext);

  useEffect(() => {
    if (node && node.saveAsTemplate === 'true') {
      setShouldSave(true);
    }
  }, [node]);

  const handleChange = e => {
    setShouldSave(e);
  };

  const getGroup = g => {
    for (var i = 0; i < groups.length; ++i) {
      if (g === groups[i].value) {
        return groups[i];
      }
    }
    return g;
  };

  const audienceOptions = audiences;

  let formData = [
    {
      title: 'SAVE AS TEMPLATE',
      subtext:
        'Would you like to save the MESSAGE BODY content as a template to your groups campaign library?',
      component: (
        <Checkbox
          name="saveAsTemplate"
          form="true"
          value={shouldSave}
          label="Add to library"
          onChange={handleChange}
          checkboxInput
          viewOnly={viewOnly ? true : false}
        />
      ),
    },
  ];

  if (shouldSave && !viewOnly) {
    formData.push({
      component: (
        <Input
          name="messageName"
          placeholder="Message Name"
          required
          form="true"
          value={node ? node.messageName : null}
        />
      ),
    });

    formData.push({
      component: (
        <Select
          form
          // required
          name="audience"
          menuPlacement="top"
          placeholder="Select audience"
          options={audienceOptions}
          stateValue={node && node.audience ? JSON.parse(node.audience) : null}
        />
      ),
    });

    formData.push({
      component: (
        <Select
          form
          required
          name="ownerGroup"
          menuPlacement="top"
          options={groups}
          stateValue={
            node && node.ownerGroup ? JSON.parse(node.ownerGroup) : null
          }
        />
      ),
    });
  }

  return formData;
}
