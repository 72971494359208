import React, { useState, useEffect, useContext } from 'react';
import { useTheme } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
// import { store } from "contexts/KBStore";
import AuthContext from 'contexts/AuthContext';
import { audiences } from 'pages/FlowMessages/audiences.js';

import '../forms.css';

export default function SelectCheckbox(props) {
  const theme = useTheme();
  const tokens = useContext(AuthContext);
  // const KBState = useContext(store);
  // const { blockAudienceSelect } = KBState.state;
  const [open, setOpen] = useState(false);
  const [roles, setRoles] = useState({
    everyone: false,
    undergraduate: false,
    graduate: false,
    online: false,
    graduate_opportunity: false,
    undergraduate_opportunity: false,
  });
  const [rolesArray, setRolesArray] = useState([]);

  const myAud = audiences;

  const {
    everyone,
    undergraduate,
    graduate,
    online,
    graduate_opportunity,
    undergraduate_opportunity,
  } = roles;
  useEffect(() => {
    if (props.stateValue) {
      const newRoles = roles;
      props.stateValue.forEach(el => {
        for (var i = 0; i < myAud.length; ++i) {
          if (myAud[i].flag === el) {
            newRoles[myAud[i].value] = true;
          }
        }
      });
      setRoles(newRoles);
    }
  }, []);

  useEffect(() => {
    if (props.onChange) {
      const rolesArray = [];
      Object.keys(roles).forEach(item => {
        if (roles[item]) {
          rolesArray.push(item);
        }
      });
      props.onChange(rolesArray);
      setRolesArray(rolesArray);
    }
  }, [roles]);

  const renderAudience = () => {
    if (!rolesArray || rolesArray === []) {
      return null;
    } else {
      return rolesArray.map((role, index) => {
        for (var i = 0; i < myAud.length; ++i) {
          if (role === myAud[i].value) {
            role = myAud[i].label;
          }
        }

        return (
          <label
            key={index}
            style={{
              ...theme.typography.subtitle2,
              padding: '0.5rem',
              marginRight: '0.5rem',
              backgroundColor: theme.palette.general.mediumBlue,
              color: 'white',
              borderRadius: '4px',
            }}
          >
            {role}
          </label>
        );
      });
    }
  };

  const handleClick = () => {
    setOpen(!open);
  };
  const renderBorderOpen = () => {
    if (open) {
      return '0px';
    }
  };
  const renderBorderBottom = () => {
    if (open) {
      return '0px';
    } else {
      return '1px';
    }
  };
  const handleChange = name => event => {
    // if (blockAudienceSelect) {
    //   return;
    // } else
    if (name === 'everyone' && event.target.checked === false) {
      setRoles({
        everyone: false,
        undergraduate: false,
        graduate: false,
        online: false,
      });
    } else if (name === 'everyone' && event.target.checked === true) {
      setRoles({
        everyone: true,
        undergraduate: false,
        graduate: false,
        online: false,
      });
    } else {
      setRoles({ ...roles, [name]: event.target.checked });
    }
  };
  const CheckboxComponent = () => {
    return (
      <FormGroup style={{ flexDirection: 'row' }}>
        {myAud.map((item, index) => {
          return (
            <FormControlLabel
              checked={item.value !== 'everyone' ? null : everyone}
              disabled={everyone && item.value !== 'everyone' ? true : false}
              style={{ width: item.value === 'everyone' ? '100%' : '45%' }}
              control={
                <Checkbox
                  checked={roles[item.value]}
                  onChange={handleChange(item.value)}
                  value={item.value}
                  color="primary"
                />
              }
              label={item.label}
            />
          );
        })}
      </FormGroup>
    );
  };

  const { checkbox, stateValue, ...validProps } = props;
  return (
    <div {...validProps}>
      <Button
        variant="text"
        onClick={handleClick}
        style={{
          ...theme.typography.body1,
          display: 'flex',
          width: '100%',
          height: '100%',
          border: `1px solid ${theme.palette.general.deepGrey}`,
          borderRadius: '4px',
          borderBottomWidth: renderBorderBottom(),
          borderBottomLeftRadius: renderBorderOpen(),
          borderBottomRightRadius: renderBorderOpen(),
          textTransform: 'none',
          color: theme.palette.general.darkGrey,
          ...props.style,
        }}
      >
        {!rolesArray.length && <div>-- Select Audience --</div>}
        {renderAudience()}
        <div style={{ flex: 10, height: '2.5rem' }} />
        {open ? (
          <KeyboardArrowUpIcon style={{ color: '#8d8d8d' }} />
        ) : (
          <KeyboardArrowDownIcon style={{ color: '#8d8d8d' }} />
        )}
      </Button>
      {open && (
        <div
          style={{
            padding: '0 1rem 1rem 1rem',
            border: `1px solid ${theme.palette.general.deepGrey}`,
            borderTopWidth: '0px',
            borderBottomLeftRadius: '4px',
            borderBottomRightRadius: '4px',
          }}
        >
          <CheckboxComponent />
        </div>
      )}
    </div>
  );
}
