import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import axios from 'axios';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import Modal from '@material-ui/core/Modal';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Drawer from '@material-ui/core/Drawer';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
// import { mainListItems, secondaryListItems, getListItems } from "./listItems";
import ListItems from './MenuItems';
import { sendAnalytics } from '../services/api/analytics';
import Routes from './Routes';
import AuthContext from 'contexts/AuthContext';
import AuthService from '../services/auth.service';
import { H6, Button } from 'stories';
import { store as GlobalStore } from 'contexts/GlobalStore';
import { Landing } from 'pages/Landing';
import Logo from '../assets/asu-logo.png';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as DropdownIcon } from '../assets/icons/icon-dropdown-down.svg';
//import PropTypes from 'prop-types';
import './App.scss';

import { API, graphqlOperation } from 'aws-amplify';
import { updateUser } from 'graphql/mutations';
import { userByAsurite } from 'graphql/queries';

// import { onUpdateMaintenance, onCreateMaintenance } from "graphql/subscriptions";

const drawerWidth = 240;
// var subscription = null;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    zIndex: theme.zIndex.drawer - 1,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  mainCont: {
    // zIndex: theme.zIndex.drawer - 100,
    marginLeft: 72,
    width: `calc(100% - 72px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  mainContShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  dropdownIcon: {},
}));

export default function MainApp(props) {
  const tokens = useContext(AuthContext);
  const theme = useTheme();
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const GlobalState = useContext(GlobalStore);
  const dispatchGlobal = GlobalState.dispatch;

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const history = useHistory();
  const [expandNavBar, setExpandNavBar] = useState(false);
  const [logoClicked, setLogoClicked] = useState(false);

  const [statusBarToggle, setStatusBarToggle] = useState(false);
  const [universalStatus, setUniversalStatus] = useState(null);

  const [navHistoryArray, setNavHistorArray] = useState(['']);
  const [anchorEl, setAnchorEl] = useState(false);
  const [userProfileImage, setUserProfileImage] = useState('');

  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [previousLocations, setPreviousLocations] = useState([]);

  const zeroLevelPages = [
    '/',
    '/knowledge-base',
    '/flows',
    '/campaigns',
    '/users-groups',
    '/escalations',
    '/dialogues',
    '/alerts',
    '/responses',
    '/image-gallery',
    '/campaigns',
    '/announcements',
    '/admin-maintenance',
    '/admin-status',
  ];

  const onClickBreadCrumb = (event, index) => {
    event.stopPropagation();
    const clickedBreadcrumb = breadcrumbs[index];
    const pathSegments = clickedBreadcrumb.location.split('/');
    console.log('clickedBread', clickedBreadcrumb);
    console.log('Path', pathSegments);
    pathSegments.shift(); // Remove first empty segment

    // Check if the clicked breadcrumb is a zero-level page
    const isZeroLevelPage = zeroLevelPages.includes(`/${pathSegments[0]}`);

    if (isZeroLevelPage && pathSegments.length === 1) {
      history.push(`/${pathSegments.join('/')}`);
    }
  };

  const renderBreadcrumbs = array => {
    if (array && array.length > 0) {
      const breadcrumbsToRender = array.length > 2 ? array.slice(0, 2) : array;
      return breadcrumbsToRender.map((v, i) => {
        const isLast = i === breadcrumbsToRender.length - 1;
        const slash = !isLast ? ' / ' : '';
        return (
          <span
            style={{
              fontWeight: isLast ? 'bold' : '400',
              paddingRight: 0,
              cursor: 'pointer',
            }}
            key={i}
            onClick={e => onClickBreadCrumb(e, i)}
          >
            <p>{v.name}</p>
            <p>{slash}</p>
          </span>
        );
      });
    } else {
      return '';
    }
  };

  const [navItems, setNavItems] = useState([
    // {
    //   path: "/dashboard",
    //   title: "DASHBOARD",
    //   name: "Dashboard",
    //   img: "DAS",
    //   selected: true
    // },
    {
      path: '/knowledge-base',
      title: 'KNOWLEDGE BASE',
      name: 'Knowledge Base',
      img: 'KNO',
      selected: false,
    },
    {
      path: '/landing',
      title: 'LANDING',
      name: 'Landing',
      img: 'LAN',
      selected: false,
    },
    {
      path: '/flows',
      title: 'FLOWS',
      name: 'Flows',
      img: 'FLW',
      selected: false,
    },
    {
      path: '/flow-messages',
      title: 'FLOW MESSAGES',
      name: 'Flow Messages',
      img: 'FM',
      selected: false,
    },
    {
      path: '/campaigns',
      title: 'CAMPAIGNS',
      name: 'Campaigns',
      img: 'CMP',
      selected: false,
    },
    // {
    //   path: "/campaigns/add-edit",
    //   title: "ADD/EDIT CAMPAIGNS",
    //   name: "Add/Edit Campaigns",
    //   img: "CMP",
    //   selected: false
    // },
    {
      path: '/dialogues',
      title: 'DIALOGUES',
      name: 'Dialogues',
      img: 'DLG',
      selected: false,
    },
    {
      path: '/users-groups',
      title: 'USERS/GROUPS',
      name: 'Users',
      img: 'USR',
      selected: false,
    },
    // {
    //   path: "/analyze",
    //   title: "ANALYZE",
    //   name: "Analaze",
    //   img: "ANA",
    //   selected: false
    // },
    {
      path: '/escalations',
      title: 'ESCALATIONS',
      name: 'Escalations',
      img: 'ESC',
      selected: false,
    },
    // {
    //   path: "/settings",
    //   title: "SETTINGS",
    //   name: "Settings",
    //   img: "STG",
    //   selected: false
    // }
  ]);

  useEffect(() => {
    const location = history.location.pathname;
    let name = history.location.pathname
      .split('/')
      .pop()
      .replace(/-/g, ' ');

    let capitalized = name.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
      //capitalize the first letter of every word
      return a.toUpperCase();
    });

    capitalized =
      name === 'CustomIntroductions' ? 'Custom Introductions' : capitalized;
    capitalized =
      name === 'CustomIntroForm' ? 'Add/Edit Custom Introduction' : capitalized;

    let previousLocationArray = previousLocations.map(
      object => object.location
    );

    if (!previousLocationArray.includes(location)) {
      // Add a new breadcrumb to the end of the array
      setBreadcrumbs(prevBreadcrumbs => [
        ...prevBreadcrumbs.slice(-2), // only keep the last two breadcrumbs
        { location, name: capitalized },
      ]);
    } else {
      // Replace the old breadcrumb with the new one
      const previousIndex = previousLocationArray.indexOf(location);
      if (previousIndex === 1) {
        setBreadcrumbs([...previousLocations]);
      } else {
        setBreadcrumbs(prevBreadcrumbs => [
          ...prevBreadcrumbs.slice(0, previousIndex), // keep the breadcrumbs before the previous index
          { location, name: capitalized }, // add the new breadcrumb
        ]);
      }
    }
    setPreviousLocations([...breadcrumbs]);
  }, [history.location]);

  let location = useLocation();
  useEffect(() => {
    const currentPathname = location.pathname;
    const isZeroLevelPage = zeroLevelPages.includes(currentPathname);

    if (isZeroLevelPage) {
      setBreadcrumbs([
        {
          location: currentPathname,
          name: currentPathname
            .split('/')
            .pop()
            .replace(/-/g, ' ')
            .toLowerCase()
            .replace(/(?:^|\s)\S/g, function(a) {
              return a.toUpperCase();
            }),
        },
      ]);
    } else if (currentPathname === '/CustomIntroductions') {
      setBreadcrumbs([
        {
          location: currentPathname,
          name: 'Custom Introductions',
        },
      ]);
    }
  }, [location]);

  useEffect(() => {
    setNavHistorArray(getHeaderTitle(history.location.pathname, navItems));
    moveNavHighliter(history.location.pathname, navItems);
    // checkForMaintenanceMode();
  }, []);

  useEffect(() => {
    setUniversalStatus(props.status);
  }, [props.status]);

  useEffect(() => {
    window.onpopstate = e => {
      moveNavHighliter(history.location.pathname, navItems);
      removeNavigation();
    };
  });

  useEffect(() => {
    sendAnalytics({
      'action-type': 'View',
      eventtime: new Date().getTime(),
      'starting-screen': getLastItem(navHistoryArray),
      'ending-screen': history.location.pathname,
    });
  }, [history.location.pathname, navHistoryArray]);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_ASU_MOBILEAPP + '/user-details?asurite=' + tokens.asurite)
      .then(response => {
        setUserProfileImage(response.data.photoUrl);
      });
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const getLastItem = array =>
    array && array.length > 0 ? array[array.length - 1] : '';

  const getHeaderTitle = (currentPath, arrayOfNavButtons) => {
    currentPath = currentPath === '/' ? '/dashboard' : currentPath;
    for (let i = 0; i < arrayOfNavButtons.length; i++) {
      if (currentPath.includes(arrayOfNavButtons[i].path)) {
        return [arrayOfNavButtons[i].name];
      }
    }
    return [arrayOfNavButtons[0].name];
  };

  const moveNavHighliter = (currentPath, arrayOfNavButtons) => {
    for (let i = 0; i < arrayOfNavButtons.length; i++) {
      if (currentPath.includes(arrayOfNavButtons[i].path)) {
        selectNav(arrayOfNavButtons[i].title);
        break;
      }
    }
  };

  const selectNav = title => {
    const changedNavItems = navItems.map(v => {
      v.selected =
        v.title !== title ? (v.selected = false) : (v.selected = true);
      return v;
    });
    setNavItems(changedNavItems);
  };

  const addNavigation = string => {
    string && setNavHistorArray([...navHistoryArray, string]);
  };

  const removeNavigation = (howMany = 1) => {
    if (navHistoryArray.length > 0 && navHistoryArray.length > howMany) {
      setNavHistorArray(
        navHistoryArray.splice(0, navHistoryArray.length - howMany)
      );
    }
  };

  const replaceNavigation = arrayOfStrings => {
    navHistoryArray.pop();
    if (Array.isArray(arrayOfStrings)) {
      arrayOfStrings.forEach(v => {
        navHistoryArray.push(v);
      });
    } else {
      navHistoryArray.push(arrayOfStrings);
    }
  };

  const getToolbarTitle = urlString => {
    const pathname = urlString;

    if (pathname === '/CustomIntroductions') {
      return 'Custom Introductions';
    } else {
      return urlString;
    }
  };

  const onClickLogo = () => {
    setLogoClicked(true);
    history.push({
      pathname: '/knowledge-base',
    });

    setTimeout(() => {
      setLogoClicked(false);
    }, 10);
  };

  const onClick = () => {
    dispatchGlobal({
      type: 'changeTableSort',
      tableSort: null,
    });
  };

  const logout = () => {
    AuthService.destroy();
  };

  const toggleShow = () => {
    setStatusBarToggle(!statusBarToggle);
  };

  const handleMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const navigation = { addNavigation, removeNavigation, replaceNavigation };
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        elevation={3}
        className={clsx(classes.appBar, open && classes.appBarShift)}
        style={{ backgroundColor: 'white', maxHeight: '64px' }}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            // color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <div
              onClick={onClickLogo}
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: 'fit-content',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
              className="clickable"
            >
              <img
                src={Logo}
                alt="ASU logo"
                style={{ width: '6em', height: '4em' }}
              />
              <H6
                style={{
                  color: '#873450',
                  margin: '0px',
                  fontSize: '1em',
                  fontWeight: 400,
                  lineHeight: '1em',
                }}
              >
                CHATBOT
                <br />
                CAMPAIGN BUILDER
              </H6>
              <div
                className="divider_MainApp"
                style={{
                  borderLeft: '1.5px solid #873450',
                  height: '1.4em',
                  marginLeft: '1em',
                }}
              ></div>
              <div className="BreadCrumbs" onClick={e => e.stopPropagation()}>
                <H6
                  style={{
                    color: '#873450',
                    marginLeft: '10px',
                    fontSize: '1em',
                    fontWeight: 400,
                    cursor: 'pointer',
                  }}
                  onClick={e => onClickBreadCrumb(e, breadcrumbs.length - 1)}
                >
                  {renderBreadcrumbs(breadcrumbs)}
                </H6>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                width: 'fit-content',
                lineHeight: '1em',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  height: '3.3em',
                  width: '3.3em',
                  borderRadius: '1.6em',
                  backgroundColor: 'grey',
                  backgroundImage: `url("${userProfileImage}")`,
                  backgroundSize: '3.3em',
                }}
              ></div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  marginLeft: '0.8em',
                }}
              >
                <H6 style={{ color: 'black', margin: 0, fontSize: '1em' }}>
                  {tokens.displayName}
                </H6>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <p
                    style={{
                      color: 'black',
                      margin: 0,
                      textTransform: 'capitalize',
                    }}
                  >
                    {tokens.role}
                  </p>
                  <SvgIcon
                    viewBox="-5 -8 20 20"
                    onClick={handleMenuOpen}
                    color="secondary"
                    component={DropdownIcon}
                  />
                  <Menu
                    keepMounted
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem
                    // onClick={}
                    >
                      View Profile
                    </MenuItem>

                    <MenuItem onClick={logout}>Logout</MenuItem>
                  </Menu>
                  {/* JUST GOTTA STYLE MODAL AND ADD LOGOUT FEATURES */}
                </div>
              </div>
            </div>
          </div>

          {/* New User Profile here */}
        </Toolbar>
        {universalStatus ? (
          <div
            className={
              'mainStatusBar ' +
              universalStatus.severity +
              ' ' +
              (statusBarToggle ? 'mainStatusBarOpen' : '')
            }
            style={{
              marginLeft: open ? 'unset' : '72px',
            }}
            onClick={toggleShow}
          >
            <div className="statusTitle">
              {universalStatus.title.toUpperCase()}
            </div>
            <div className="statusDesc">{universalStatus.message}</div>
          </div>
        ) : null}
      </AppBar>

      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <div onClick={onClick}>
          <List>
            {tokens.role === 'chat agent' ? (
              <ListItems
                type="seconday"
                tokens={tokens}
                setBreadcrumbs={setBreadcrumbs}
              />
            ) : (
              <ListItems
                type="main"
                tokens={tokens}
                setBreadcrumbs={setBreadcrumbs}
              />
            )}
          </List>
        </div>
        {/* {open && (
            <div
              className="Nav-bar-login-container"
              style={{ backgroundColor: theme.palette.general.darkGrey }}
            >
              <div className="Nav-bar-login-row">
                <p
                  className="Nav-bar-login-text"
                  style={{ color: theme.palette.general.mediumGrey }}
                >
                  Logged in as:
                </p>
                <p className="Nav-bar-login-text">{tokens.displayName}</p>
              </div>
              <div className="Nav-bar-login-row">
                <p
                  className="Nav-bar-login-text"
                  style={{ color: theme.palette.general.mediumGrey }}
                >
                  Role:
                </p>
                <p
                  className="Nav-bar-login-text"
                  style={{ textTransform: "capitalize" }}
                >
                  {tokens.role}
                </p>
              </div>
              <div
                className="Nav-bar-login-row"
                style={{ borderColor: "white", borderWidth: "1px" }}
              >
                <Button delete="true" text="Logout" onClick={logout}></Button>
              </div>
            </div>
          )} */}
      </Drawer>

      <main className={clsx(classes.mainCont, open && classes.mainContShift)}>
        <Routes
          navigation={navigation}
          history={history}
          hasStatusBar={props.maintenanceMode || universalStatus}
        />
      </main>
    </div>
  );
}
