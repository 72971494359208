import React, { useState, useEffect, useRef } from "react";
import { CSVLink, CSVDownload } from "react-csv";

import AutorenewIcon from "@material-ui/icons/Autorenew";
import GetAppIcon from "@material-ui/icons/GetApp";
import SearchIcon from "@material-ui/icons/Search";

import "./PanelButton.css";

export default function PanelButton(props) {
  const { shouldValidateCSV = true ,csvFileName, label, type, disabled, onClick, csvData } = props;

  if (typeof csvData !== "undefined" && csvData !== null) {
    if (Array.isArray(csvData) && csvData.length > 0) {
      if (!Array.isArray(csvData[0])) {
        /* csvData is an array of objects */

        //make a copy of the array of objects
        let oldCSVData = [...csvData];

        //overwrite original with array of arrays
        csvData = [];
        let headerArray = [];

        oldCSVData.forEach((object) => {
          //get all headers
          Object.keys(object).forEach((header) => {
            if (!headerArray.includes(header)) {
              headerArray.push(header);
            }
          });

          //create CSV rows
          const csvRow = [];

          headerArray.forEach((headerAsKey) => {
            csvRow.push(object[headerAsKey]);
          });

          csvData.push(csvRow);
        });

        //append headers to first row
        csvData.unshift(headerArray);
      }
    }
  }

  let buttonClassName = "";

  switch (type.toLowerCase()) {
    case "csv":
      buttonClassName = "exIcon";
      break;
    case "search":
      buttonClassName = "arIcon marginRight";
      break;
    case "refresh":
      buttonClassName = "arIcon";
      break;
    case "resolve":
      buttonClassName = "noIcon marginRight";
      break;
  }

  let isCsvDataEmpty = false;

  if (shouldValidateCSV && (!csvData || csvData.length === 0)) {
    isCsvDataEmpty = true;
  }

  let isButtonDisabled = false;

  if (typeof disabled === "undefined" || disabled === false) {
    isButtonDisabled = false;
  } else {
    isButtonDisabled = true;
  }

  if (
    isButtonDisabled ||
    (shouldValidateCSV && type.toLowerCase() === 'csv' && isCsvDataEmpty)
  ) {
    buttonClassName += ' ' + buttonClassName + '--disabled';
  }

  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
      return false;
    }
  };

  if (type.toLowerCase() === "csv") {
    if (isButtonDisabled || isCsvDataEmpty) {
      return (
        <div
          className={buttonClassName}
          style={{ width: "9.6em" }}
          onClick={handleClick}
        >
          <GetAppIcon />
          {label}
        </div>
      );
    } else {
      return (
        <CSVLink
          data={csvData ? csvData : []}
          filename={csvFileName ? csvFileName : "chatbot.csv"}
          onClick={handleClick}
        >
          <div className={buttonClassName} style={{ width: "9.6em" }}>
            <GetAppIcon />
            {label}
          </div>
        </CSVLink>
      );
    }
  } else if (type.toLowerCase() === "search") {
    return (
      <div className={buttonClassName} onClick={onClick}>
        <SearchIcon />
        {label}
      </div>
    );
  } else if (type.toLowerCase() === "refresh") {
    return (
      <div className={buttonClassName} onClick={onClick}>
        <AutorenewIcon />
        {label}
      </div>
    );
  } else if (type.toLowerCase() === "resolve") {
    return (
      <div className={buttonClassName} onClick={onClick}>
        {label}
      </div>
    );
  } else {
    return (
      <div className="arIcon" onClick={onClick}>
        {label}
      </div>
    );
  }
}
