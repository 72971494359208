import React from "react";
import { makeStyles } from "@material-ui/core/styles";

export default function H6(props) {
  const useStyles = makeStyles(theme => ({
    header: {
      ...theme.typography.h6,
      ...props.style
    }
  }));
  const classes = useStyles();

  //remove additionalClasses from props
  const { additionalClasses, ...propsList } = props;

  return (
    <h6 {...propsList} className={additionalClasses ? classes.header + " " + additionalClasses : classes.header}>
      {propsList.children}
    </h6>
  );
}
