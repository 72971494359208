import ReactPanZoom from "./ReactPanZoom.js";
import GenerateTree from "./GenerateTree.js";
import FlowControls from "./FlowControls.js";
// import ZoomButtons from "./ZoomButtons.js";

import { useTheme } from "@material-ui/core/styles";

import React, { useEffect, useState, useRef, useContext } from "react";
import "../Canvas.scss";

import { idGenerator, getDeletes, collapseFromNode } from "../utility.js";

import { ReactComponent as CompressButton } from "../tempIcons/compress-arrows-alt-solid.svg";
import { ReactComponent as ExpandButton } from "../tempIcons/expand-arrows-alt-solid.svg";
import { ReactComponent as CenterButton } from "../tempIcons/center_focus_strong-24px.svg";
import AdjustIcon from "@material-ui/icons/Adjust";

import { store as GlobalStore } from "contexts/GlobalStore";

import { wheel, zoomUtil } from "./matrix-util";

const SCROLL_AMT = 0.01;
const ZOOM_AMT = 0.2;
const MAX_ZOOM_AMT = 2;
const MIN_ZOOM_AMT = 0.4;

let undoTimeout = null;
var myZoom = 1;

export default function Diagram(props) {
  const [cZoom, setCZoom] = useState(1);
  const [center, setCenter] = useState(0);
  const [fitChart, setFitChart] = useState(0);
  const [zoomUnavail, setZoomUnavail] = useState(null);

  const [rawTree, setRawTree] = useState([]);
  const [treeElem, setTreeElem] = useState(null);
  const [canCollapse, setCanCollapse] = useState(false);

  const scrollRef = useRef(null);

  const GlobalState = useContext(GlobalStore);
  const dispatch = GlobalState.dispatch;

  const manageWheel = (e) => {
    if (e.target.id.indexOf("react-select") > -1) {
      return true;
    } else {
      let amts = wheel(e, myZoom);
      setCZoom((prevZoom) => amts.amt);
      setZoomUnavail(amts.zoomUnavail);
    }
  };

  const manageZoom = (t) => {
    let amts = zoomUtil(t, cZoom);
    setCZoom(amts.amt);
    setZoomUnavail(amts.zoomUnavail);
  };

  useEffect(() => {
    myZoom = cZoom;
  }, [cZoom]);

  useEffect(() => {
    setRawTree(props.rawTree);
  }, [props.rawTree]);

  useEffect(() => {}, [props.selectedIds]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.addEventListener("wheel", manageWheel);
    }

    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener("wheel", manageWheel);
      }
    };
  }, []);

  const setSelected = (s) => {
    let mycanCollapse = s.length > 0;
    let myshowProperties = s.length === 1;
    let mylatest = null;

    let tree = rawTree;
    for (var i = 0; i < tree.length; ++i) {
      if (s.indexOf(tree[i].id) > -1) {
        mylatest = tree[i];
        if (tree[i].parent === null) {
          mycanCollapse = false;
        }
      }
    }

    if (mylatest && mylatest.type === "jump") {
      let m_editInfo = {};
      try {
        let jumpInfo = JSON.parse(mylatest.jumpToNodeId);

        m_editInfo = {
          flow: JSON.parse(jumpInfo.flow),
          flowVersion: jumpInfo.version,
        };
      } catch (e) {}

      console.log("INFO", m_editInfo);
      dispatch({ type: "updateEditInfo", editInfo: { ...m_editInfo } });
    }

    setCanCollapse(mycanCollapse);

    props.setSelectedIds(s);
    props.setLatest(mylatest);
    props.setShowProperties(myshowProperties);

    // setShowProperties(myshowProperties);
    // setLatest(mylatest);
  };

  const setTree = (tElem) => {
    setTreeElem(tElem);
    setCenter(center + 1);
  };

  const incCenter = () => {
    setCenter(center + 1);
  };

  const collapseSingle = (itemToCollapse) => {
    let t = JSON.parse(JSON.stringify(rawTree));

    for (var i = 0; i < t.length; ++i) {
      if (itemToCollapse === t[i].id) {
        if (t[i].show === false) {
          t[i].show = true;
        } else {
          t[i].show = false;
        }
      }
    }

    setRawTree(t);
    setCenter(center + 1);
  };

  const requestCollapse = (itemToCollapse) => {
    let t = collapseFromNode(rawTree, itemToCollapse);

    setRawTree(t);
    setCenter(center + 1);
  };

  const expand = () => {
    let t = JSON.parse(JSON.stringify(rawTree));

    for (var i = 0; i < t.length; ++i) {
      t[i].show = true;
    }

    setRawTree(t);
    setCenter(center + 1);
  };

  let compressClasses = "basicButton ";

  if (!canCollapse) {
    compressClasses += "invalidBtn";
  }

  const zoomBtns = () => {
    return (
      <div
        className="Buttons"
        style={{ right: props.showProperties ? "400px" : "0px" }}
      >
        <div className="buttonOptn">
          <div
            className={compressClasses}
            onClick={() => requestCollapse(props.selectedIds)}
          >
            <div className="iconHolder">
              <CompressButton className="alterChartBtn" />
            </div>
            <div className="textHolder">Collapse all but current branch</div>
          </div>
        </div>

        <div className="buttonOptn">
          <div className="basicButton" onClick={() => expand()}>
            <div className="iconHolder">
              <ExpandButton className="alterChartBtn" />
            </div>
            <div className="textHolder">Expand</div>
          </div>
        </div>

        <div className="buttonOptn">
          <div className="basicButton" onClick={() => incCenter()}>
            <div className="iconHolder">
              <CenterButton
                className="alterChartBtn"
                style={{ height: "13px", width: "13px" }}
              />
            </div>
            <div className="textHolder">Center</div>
          </div>
        </div>

        <div className="buttonOptn">
          <div className="zoomCont">
            <div
              className={
                "zoomBtn zoomBtnBorder " +
                (zoomUnavail === "in" ? "zoomUnavail" : null)
              }
              onClick={() => manageZoom("in")}
            >
              +
            </div>
            <div
              className={
                "zoomBtn " + (zoomUnavail === "out" ? "zoomUnavail" : null)
              }
              onClick={() => manageZoom("out")}
            >
              -
            </div>
          </div>
        </div>
      </div>
    );
  };

  const continueFitting = (cont) => {
    if (cont && !zoomUnavail) {
      manageZoom("out");
      setTimeout(() => {
        setFitChart(fitChart + 1);
      }, 5);
    } else {
      setCenter(center + 1);
    }
  };

  return (
    <div>
      <div className="scrollHolder" ref={scrollRef}>
        {zoomBtns()}
        <FlowControls
          flowId={props.flowId}
          selectedVersion={props.flowVersion}
          changedVersion={props.changedVersion}
          rawTree={rawTree}
          title={props.title}
        ></FlowControls>
        <ReactPanZoom
          zoom={cZoom}
          className="PanHolder"
          treeElem={treeElem}
          center={center}
          fitChart={fitChart}
          continueFitting={(t) => continueFitting(t)}
        >
          <GenerateTree
            id="rest"
            key="rest"
            rawTree={rawTree}
            fillNodeId={props.fillNodeId}
            addNode={props.addNode}
            viewOnly={props.viewOnly}
            collapse={collapseSingle}
            selected={props.selectedIds}
            setSelected={setSelected}
            setTree={setTree}
            deleteItem={props.deleteItem}
            doneWithTree={() => setFitChart(fitChart + 1)}
          />
        </ReactPanZoom>
      </div>
    </div>
  );
}
