import React, { useState, useEffect, useContext } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import AuthContext from 'contexts/AuthContext';

import { API, graphqlOperation } from 'aws-amplify';
import * as queries from 'graphql/queries';

const useDialogueSocket = urlToUse => {
  const tokens = useContext(AuthContext);
  const [wssToken, setWssToken] = useState(null);

  //console.log("!!!a urlToUse is", urlToUse);

  const getToken = async () => {
    let queryNames = ['getTokenForWss', 'getTokenForWSS'];
    let resp = await API.graphql(
      graphqlOperation(
        Object.keys(queries).includes(queryNames[0])
          ? queries[queryNames[0]]
          : queries[queryNames[1]],
        { operation: 'getJWT' }
      )
    );
    let token = resp.data.getTokenForWSS;
    setWssToken(token);
  };

  useEffect(() => {
    getToken();
  }, []);

  let url;

  if (urlToUse === null) {
    url = null;
  } else if (urlToUse === 'stored' || urlToUse === 'refresh') {
    url =
      process.env.REACT_APP_APIGATEWAY_DASHBOARD_DIALOGUES +
      '?Authorizer=Bearer ' +
      wssToken;
  }

  const [sendMessage, lastMessage, readyState] = useWebSocket(url);

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
  }[readyState];

  if (connectionStatus === 'Open') {
    sendMessage(
      JSON.stringify({
        action: 'addConvo',
        convoId: 'NONE',
        agentChatting: false,
        asurite: 'SELF',
        phoneNumber: 'NONE',
        token: wssToken,
        type: 'BASIC',
        agentDetails: {
          asurite: tokens.asurite,
          displayName: tokens.displayName,
        },
      })
    );
  }

  return {
    lastMessage,
    sendMessage,
    wssToken,
    readyState,
    connectionStatus,
  };
};

export default useDialogueSocket;
