/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateCampaign = /* GraphQL */ `
  subscription OnCreateCampaign {
    onCreateCampaign {
      id
      title
      titleKey
      description
      status
      statusKey
      audienceTargetType
      audience
      audienceFileContent
      audienceFileName
      fileUploadedBy
      flow
      flowVersion
      introFlow
      introFlowId
      introFlowVersion
      processing
      processingError
      startTime
      sendNumbers
      startTimeKey
      endTime
      endTimeKey
      timestamp
      timestampKey
      createdBy
      paused
      dryRun
      campaignTagAnswer
      campaignSelect
      pausedBy {
        asurite
        timestamp
      }
      approvedBy {
        asurite
        timestamp
      }
      type
      editedBy {
        asurite
        timestamp
      }
      deleted
      deletedBy {
        asurite
        timestamp
      }
      active
      group
      groupKey
      isSendingIntroMessage
      estSendMinutes
      smsButtonPresser
      smsButtonMsg
      numberProcessed
      numberToProcess
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCampaign = /* GraphQL */ `
  subscription OnUpdateCampaign {
    onUpdateCampaign {
      id
      title
      titleKey
      description
      status
      statusKey
      audienceTargetType
      audience
      audienceFileContent
      audienceFileName
      fileUploadedBy
      flow
      flowVersion
      introFlow
      introFlowId
      introFlowVersion
      processing
      processingError
      startTime
      sendNumbers
      startTimeKey
      endTime
      endTimeKey
      timestamp
      timestampKey
      createdBy
      paused
      dryRun
      campaignTagAnswer
      campaignSelect
      pausedBy {
        asurite
        timestamp
      }
      approvedBy {
        asurite
        timestamp
      }
      type
      editedBy {
        asurite
        timestamp
      }
      deleted
      deletedBy {
        asurite
        timestamp
      }
      active
      group
      groupKey
      isSendingIntroMessage
      estSendMinutes
      smsButtonPresser
      smsButtonMsg
      numberProcessed
      numberToProcess
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCampaign = /* GraphQL */ `
  subscription OnDeleteCampaign {
    onDeleteCampaign {
      id
      title
      titleKey
      description
      status
      statusKey
      audienceTargetType
      audience
      audienceFileContent
      audienceFileName
      fileUploadedBy
      flow
      flowVersion
      introFlow
      introFlowId
      introFlowVersion
      processing
      processingError
      startTime
      sendNumbers
      startTimeKey
      endTime
      endTimeKey
      timestamp
      timestampKey
      createdBy
      paused
      dryRun
      campaignTagAnswer
      campaignSelect
      pausedBy {
        asurite
        timestamp
      }
      approvedBy {
        asurite
        timestamp
      }
      type
      editedBy {
        asurite
        timestamp
      }
      deleted
      deletedBy {
        asurite
        timestamp
      }
      active
      group
      groupKey
      isSendingIntroMessage
      estSendMinutes
      smsButtonPresser
      smsButtonMsg
      numberProcessed
      numberToProcess
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCampaignManualSend = /* GraphQL */ `
  subscription OnCreateCampaignManualSend {
    onCreateCampaignManualSend {
      id
      campaignId
      audience
      status
      flow
      flowVersion
      audienceFileContent
      startTime
      endTime
      createdBy
      group
      type
      isTest
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCampaignManualSend = /* GraphQL */ `
  subscription OnUpdateCampaignManualSend {
    onUpdateCampaignManualSend {
      id
      campaignId
      audience
      status
      flow
      flowVersion
      audienceFileContent
      startTime
      endTime
      createdBy
      group
      type
      isTest
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCampaignManualSend = /* GraphQL */ `
  subscription OnDeleteCampaignManualSend {
    onDeleteCampaignManualSend {
      id
      campaignId
      audience
      status
      flow
      flowVersion
      audienceFileContent
      startTime
      endTime
      createdBy
      group
      type
      isTest
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCampaignCompleted = /* GraphQL */ `
  subscription OnCreateCampaignCompleted {
    onCreateCampaignCompleted {
      userId
      campaignId
      group
      flowId
      flowVersion
      lastNode
      exitType
      finishTime
      expireTime
      startTime
      type
      error
      isManualCamp
      expiredBeforeSend
      campaignInfo {
        items {
          id
          title
          titleKey
          description
          status
          statusKey
          audienceTargetType
          audience
          audienceFileContent
          audienceFileName
          fileUploadedBy
          flow
          flowVersion
          introFlow
          introFlowId
          introFlowVersion
          processing
          processingError
          startTime
          sendNumbers
          startTimeKey
          endTime
          endTimeKey
          timestamp
          timestampKey
          createdBy
          paused
          dryRun
          campaignTagAnswer
          campaignSelect
          pausedBy {
            asurite
            timestamp
          }
          approvedBy {
            asurite
            timestamp
          }
          type
          editedBy {
            asurite
            timestamp
          }
          deleted
          deletedBy {
            asurite
            timestamp
          }
          active
          group
          groupKey
          isSendingIntroMessage
          estSendMinutes
          smsButtonPresser
          smsButtonMsg
          numberProcessed
          numberToProcess
          createdAt
          updatedAt
        }
        nextToken
      }
      asurite
      phoneNumber
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCampaignCompleted = /* GraphQL */ `
  subscription OnUpdateCampaignCompleted {
    onUpdateCampaignCompleted {
      userId
      campaignId
      group
      flowId
      flowVersion
      lastNode
      exitType
      finishTime
      expireTime
      startTime
      type
      error
      isManualCamp
      expiredBeforeSend
      campaignInfo {
        items {
          id
          title
          titleKey
          description
          status
          statusKey
          audienceTargetType
          audience
          audienceFileContent
          audienceFileName
          fileUploadedBy
          flow
          flowVersion
          introFlow
          introFlowId
          introFlowVersion
          processing
          processingError
          startTime
          sendNumbers
          startTimeKey
          endTime
          endTimeKey
          timestamp
          timestampKey
          createdBy
          paused
          dryRun
          campaignTagAnswer
          campaignSelect
          pausedBy {
            asurite
            timestamp
          }
          approvedBy {
            asurite
            timestamp
          }
          type
          editedBy {
            asurite
            timestamp
          }
          deleted
          deletedBy {
            asurite
            timestamp
          }
          active
          group
          groupKey
          isSendingIntroMessage
          estSendMinutes
          smsButtonPresser
          smsButtonMsg
          numberProcessed
          numberToProcess
          createdAt
          updatedAt
        }
        nextToken
      }
      asurite
      phoneNumber
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCampaignCompleted = /* GraphQL */ `
  subscription OnDeleteCampaignCompleted {
    onDeleteCampaignCompleted {
      userId
      campaignId
      group
      flowId
      flowVersion
      lastNode
      exitType
      finishTime
      expireTime
      startTime
      type
      error
      isManualCamp
      expiredBeforeSend
      campaignInfo {
        items {
          id
          title
          titleKey
          description
          status
          statusKey
          audienceTargetType
          audience
          audienceFileContent
          audienceFileName
          fileUploadedBy
          flow
          flowVersion
          introFlow
          introFlowId
          introFlowVersion
          processing
          processingError
          startTime
          sendNumbers
          startTimeKey
          endTime
          endTimeKey
          timestamp
          timestampKey
          createdBy
          paused
          dryRun
          campaignTagAnswer
          campaignSelect
          pausedBy {
            asurite
            timestamp
          }
          approvedBy {
            asurite
            timestamp
          }
          type
          editedBy {
            asurite
            timestamp
          }
          deleted
          deletedBy {
            asurite
            timestamp
          }
          active
          group
          groupKey
          isSendingIntroMessage
          estSendMinutes
          smsButtonPresser
          smsButtonMsg
          numberProcessed
          numberToProcess
          createdAt
          updatedAt
        }
        nextToken
      }
      asurite
      phoneNumber
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCampaignUserQueue = /* GraphQL */ `
  subscription OnCreateCampaignUserQueue {
    onCreateCampaignUserQueue {
      userId
      campaignId
      flowId
      flowVersion
      expireTime
      startTime
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCampaignUserQueue = /* GraphQL */ `
  subscription OnUpdateCampaignUserQueue {
    onUpdateCampaignUserQueue {
      userId
      campaignId
      flowId
      flowVersion
      expireTime
      startTime
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCampaignUserQueue = /* GraphQL */ `
  subscription OnDeleteCampaignUserQueue {
    onDeleteCampaignUserQueue {
      userId
      campaignId
      flowId
      flowVersion
      expireTime
      startTime
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChatbotErrorMessages = /* GraphQL */ `
  subscription OnCreateChatbotErrorMessages {
    onCreateChatbotErrorMessages {
      errorCode
      errorMessage
      type
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChatbotErrorMessages = /* GraphQL */ `
  subscription OnUpdateChatbotErrorMessages {
    onUpdateChatbotErrorMessages {
      errorCode
      errorMessage
      type
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChatbotErrorMessages = /* GraphQL */ `
  subscription OnDeleteChatbotErrorMessages {
    onDeleteChatbotErrorMessages {
      errorCode
      errorMessage
      type
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCampaignRunning = /* GraphQL */ `
  subscription OnCreateCampaignRunning {
    onCreateCampaignRunning {
      userId
      campaignId
      flowId
      flowVersion
      lastNode
      isManualCamp
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCampaignRunning = /* GraphQL */ `
  subscription OnUpdateCampaignRunning {
    onUpdateCampaignRunning {
      userId
      campaignId
      flowId
      flowVersion
      lastNode
      isManualCamp
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCampaignRunning = /* GraphQL */ `
  subscription OnDeleteCampaignRunning {
    onDeleteCampaignRunning {
      userId
      campaignId
      flowId
      flowVersion
      lastNode
      isManualCamp
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCampaignRunningVoice = /* GraphQL */ `
  subscription OnCreateCampaignRunningVoice {
    onCreateCampaignRunningVoice {
      userId
      campaignId
      flowId
      flowVersion
      lastNode
      isManualCamp
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCampaignRunningVoice = /* GraphQL */ `
  subscription OnUpdateCampaignRunningVoice {
    onUpdateCampaignRunningVoice {
      userId
      campaignId
      flowId
      flowVersion
      lastNode
      isManualCamp
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCampaignRunningVoice = /* GraphQL */ `
  subscription OnDeleteCampaignRunningVoice {
    onDeleteCampaignRunningVoice {
      userId
      campaignId
      flowId
      flowVersion
      lastNode
      isManualCamp
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAnswerVariables = /* GraphQL */ `
  subscription OnCreateAnswerVariables {
    onCreateAnswerVariables {
      id
      varName
      active
      group
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAnswerVariables = /* GraphQL */ `
  subscription OnUpdateAnswerVariables {
    onUpdateAnswerVariables {
      id
      varName
      active
      group
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAnswerVariables = /* GraphQL */ `
  subscription OnDeleteAnswerVariables {
    onDeleteAnswerVariables {
      id
      varName
      active
      group
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCustomVariable = /* GraphQL */ `
  subscription OnCreateCustomVariable {
    onCreateCustomVariable {
      id
      user
      key
      value
      botType
      group
      campaignId
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCustomVariable = /* GraphQL */ `
  subscription OnUpdateCustomVariable {
    onUpdateCustomVariable {
      id
      user
      key
      value
      botType
      group
      campaignId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCustomVariable = /* GraphQL */ `
  subscription OnDeleteCustomVariable {
    onDeleteCustomVariable {
      id
      user
      key
      value
      botType
      group
      campaignId
      createdAt
      updatedAt
    }
  }
`;
export const onCreateStatus = /* GraphQL */ `
  subscription OnCreateStatus {
    onCreateStatus {
      id
      startTime
      submittedBy
      title
      active
      message
      severity
      endTime
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateStatus = /* GraphQL */ `
  subscription OnUpdateStatus {
    onUpdateStatus {
      id
      startTime
      submittedBy
      title
      active
      message
      severity
      endTime
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteStatus = /* GraphQL */ `
  subscription OnDeleteStatus {
    onDeleteStatus {
      id
      startTime
      submittedBy
      title
      active
      message
      severity
      endTime
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSawStatus = /* GraphQL */ `
  subscription OnCreateSawStatus {
    onCreateSawStatus {
      id
      asurite
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSawStatus = /* GraphQL */ `
  subscription OnUpdateSawStatus {
    onUpdateSawStatus {
      id
      asurite
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSawStatus = /* GraphQL */ `
  subscription OnDeleteSawStatus {
    onDeleteSawStatus {
      id
      asurite
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFlow = /* GraphQL */ `
  subscription OnCreateFlow {
    onCreateFlow {
      id
      title
      titleKey
      searchTitle
      description
      type
      statusX
      statusKey
      version
      versionInfo {
        id
        version
        createdAt
        title
        description
        variablesIncluded
        flowNodes
        submitted
        liveAgentEsc
        approved
        published
        disabled
        denied
        queryableData
        submittedBy {
          asurite
          timestamp
        }
        approvedBy {
          asurite
          timestamp
        }
        publishedBy {
          asurite
          timestamp
        }
        deniedBy {
          asurite
          timestamp
        }
        botType
        updatedAt
      }
      category
      timestamp
      submittedOn
      submittedOnKey
      editing {
        asurite
        timestamp
      }
      submittedBy
      submittedByKey
      paused
      approvedBy {
        asurite
        timestamp
      }
      editedBy {
        asurite
        timestamp
      }
      deleted
      deletedBy {
        asurite
        timestamp
      }
      group
      groupKey
      signOffMsg
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFlow = /* GraphQL */ `
  subscription OnUpdateFlow {
    onUpdateFlow {
      id
      title
      titleKey
      searchTitle
      description
      type
      statusX
      statusKey
      version
      versionInfo {
        id
        version
        createdAt
        title
        description
        variablesIncluded
        flowNodes
        submitted
        liveAgentEsc
        approved
        published
        disabled
        denied
        queryableData
        submittedBy {
          asurite
          timestamp
        }
        approvedBy {
          asurite
          timestamp
        }
        publishedBy {
          asurite
          timestamp
        }
        deniedBy {
          asurite
          timestamp
        }
        botType
        updatedAt
      }
      category
      timestamp
      submittedOn
      submittedOnKey
      editing {
        asurite
        timestamp
      }
      submittedBy
      submittedByKey
      paused
      approvedBy {
        asurite
        timestamp
      }
      editedBy {
        asurite
        timestamp
      }
      deleted
      deletedBy {
        asurite
        timestamp
      }
      group
      groupKey
      signOffMsg
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFlow = /* GraphQL */ `
  subscription OnDeleteFlow {
    onDeleteFlow {
      id
      title
      titleKey
      searchTitle
      description
      type
      statusX
      statusKey
      version
      versionInfo {
        id
        version
        createdAt
        title
        description
        variablesIncluded
        flowNodes
        submitted
        liveAgentEsc
        approved
        published
        disabled
        denied
        queryableData
        submittedBy {
          asurite
          timestamp
        }
        approvedBy {
          asurite
          timestamp
        }
        publishedBy {
          asurite
          timestamp
        }
        deniedBy {
          asurite
          timestamp
        }
        botType
        updatedAt
      }
      category
      timestamp
      submittedOn
      submittedOnKey
      editing {
        asurite
        timestamp
      }
      submittedBy
      submittedByKey
      paused
      approvedBy {
        asurite
        timestamp
      }
      editedBy {
        asurite
        timestamp
      }
      deleted
      deletedBy {
        asurite
        timestamp
      }
      group
      groupKey
      signOffMsg
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFlowMessage = /* GraphQL */ `
  subscription OnCreateFlowMessage {
    onCreateFlowMessage {
      id
      name
      message
      nodeType
      group
      deleted
      deletedBy {
        asurite
        timestamp
      }
      submittedBy
      image
      audience
      response
      matches {
        id
        match
      }
      responseImage
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFlowMessage = /* GraphQL */ `
  subscription OnUpdateFlowMessage {
    onUpdateFlowMessage {
      id
      name
      message
      nodeType
      group
      deleted
      deletedBy {
        asurite
        timestamp
      }
      submittedBy
      image
      audience
      response
      matches {
        id
        match
      }
      responseImage
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFlowMessage = /* GraphQL */ `
  subscription OnDeleteFlowMessage {
    onDeleteFlowMessage {
      id
      name
      message
      nodeType
      group
      deleted
      deletedBy {
        asurite
        timestamp
      }
      submittedBy
      image
      audience
      response
      matches {
        id
        match
      }
      responseImage
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFlowVersion = /* GraphQL */ `
  subscription OnCreateFlowVersion {
    onCreateFlowVersion {
      id
      version
      createdAt
      title
      description
      variablesIncluded
      flowNodes
      submitted
      liveAgentEsc
      approved
      published
      disabled
      denied
      queryableData
      submittedBy {
        asurite
        timestamp
      }
      approvedBy {
        asurite
        timestamp
      }
      publishedBy {
        asurite
        timestamp
      }
      deniedBy {
        asurite
        timestamp
      }
      botType
      updatedAt
    }
  }
`;
export const onUpdateFlowVersion = /* GraphQL */ `
  subscription OnUpdateFlowVersion {
    onUpdateFlowVersion {
      id
      version
      createdAt
      title
      description
      variablesIncluded
      flowNodes
      submitted
      liveAgentEsc
      approved
      published
      disabled
      denied
      queryableData
      submittedBy {
        asurite
        timestamp
      }
      approvedBy {
        asurite
        timestamp
      }
      publishedBy {
        asurite
        timestamp
      }
      deniedBy {
        asurite
        timestamp
      }
      botType
      updatedAt
    }
  }
`;
export const onDeleteFlowVersion = /* GraphQL */ `
  subscription OnDeleteFlowVersion {
    onDeleteFlowVersion {
      id
      version
      createdAt
      title
      description
      variablesIncluded
      flowNodes
      submitted
      liveAgentEsc
      approved
      published
      disabled
      denied
      queryableData
      submittedBy {
        asurite
        timestamp
      }
      approvedBy {
        asurite
        timestamp
      }
      publishedBy {
        asurite
        timestamp
      }
      deniedBy {
        asurite
        timestamp
      }
      botType
      updatedAt
    }
  }
`;
export const onCreateResponseMessages = /* GraphQL */ `
  subscription OnCreateResponseMessages {
    onCreateResponseMessages {
      id
      group
      author
      asurite
      type
      message
      number
      flowId
      flowLabel
      flowVersion
      flowVersionLabel
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateResponseMessages = /* GraphQL */ `
  subscription OnUpdateResponseMessages {
    onUpdateResponseMessages {
      id
      group
      author
      asurite
      type
      message
      number
      flowId
      flowLabel
      flowVersion
      flowVersionLabel
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteResponseMessages = /* GraphQL */ `
  subscription OnDeleteResponseMessages {
    onDeleteResponseMessages {
      id
      group
      author
      asurite
      type
      message
      number
      flowId
      flowLabel
      flowVersion
      flowVersionLabel
      createdAt
      updatedAt
    }
  }
`;
export const onCreateKnowledgeBaseCategory = /* GraphQL */ `
  subscription OnCreateKnowledgeBaseCategory {
    onCreateKnowledgeBaseCategory {
      id
      title
      group
      subcategories {
        title
      }
      deleted
      deletedBy {
        asurite
        timestamp
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateKnowledgeBaseCategory = /* GraphQL */ `
  subscription OnUpdateKnowledgeBaseCategory {
    onUpdateKnowledgeBaseCategory {
      id
      title
      group
      subcategories {
        title
      }
      deleted
      deletedBy {
        asurite
        timestamp
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteKnowledgeBaseCategory = /* GraphQL */ `
  subscription OnDeleteKnowledgeBaseCategory {
    onDeleteKnowledgeBaseCategory {
      id
      title
      group
      subcategories {
        title
      }
      deleted
      deletedBy {
        asurite
        timestamp
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateKnowledgeBase = /* GraphQL */ `
  subscription OnCreateKnowledgeBase {
    onCreateKnowledgeBase {
      id
      categoryID
      categoryName
      subcategoryNames
      group
      groupKey
      comments
      commentsKey
      deletedBy {
        asurite
        timestamp
      }
      editedBy {
        asurite
        timestamp
      }
      editedByKey
      lastEdit
      lastEditKey
      approvedBy {
        asurite
        timestamp
      }
      question
      status
      statusKey
      submittedBy
      submittedByKey
      submittedOn
      submittedOnKey
      updatedOn
      variations
      variationsCount
      variationsCountKey
      answerData {
        answer
        flow
        flowVersion
        audience
        audienceEveryone
        type
        answerEscalation
        image
      }
      modality
      fallbackAnswer
      fallbackAnswerImage
      deleted
      escalation
      editing {
        asurite
        timestamp
      }
      processing
      processingError
      flow
      flowVersion
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateKnowledgeBase = /* GraphQL */ `
  subscription OnUpdateKnowledgeBase {
    onUpdateKnowledgeBase {
      id
      categoryID
      categoryName
      subcategoryNames
      group
      groupKey
      comments
      commentsKey
      deletedBy {
        asurite
        timestamp
      }
      editedBy {
        asurite
        timestamp
      }
      editedByKey
      lastEdit
      lastEditKey
      approvedBy {
        asurite
        timestamp
      }
      question
      status
      statusKey
      submittedBy
      submittedByKey
      submittedOn
      submittedOnKey
      updatedOn
      variations
      variationsCount
      variationsCountKey
      answerData {
        answer
        flow
        flowVersion
        audience
        audienceEveryone
        type
        answerEscalation
        image
      }
      modality
      fallbackAnswer
      fallbackAnswerImage
      deleted
      escalation
      editing {
        asurite
        timestamp
      }
      processing
      processingError
      flow
      flowVersion
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteKnowledgeBase = /* GraphQL */ `
  subscription OnDeleteKnowledgeBase {
    onDeleteKnowledgeBase {
      id
      categoryID
      categoryName
      subcategoryNames
      group
      groupKey
      comments
      commentsKey
      deletedBy {
        asurite
        timestamp
      }
      editedBy {
        asurite
        timestamp
      }
      editedByKey
      lastEdit
      lastEditKey
      approvedBy {
        asurite
        timestamp
      }
      question
      status
      statusKey
      submittedBy
      submittedByKey
      submittedOn
      submittedOnKey
      updatedOn
      variations
      variationsCount
      variationsCountKey
      answerData {
        answer
        flow
        flowVersion
        audience
        audienceEveryone
        type
        answerEscalation
        image
      }
      modality
      fallbackAnswer
      fallbackAnswerImage
      deleted
      escalation
      editing {
        asurite
        timestamp
      }
      processing
      processingError
      flow
      flowVersion
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      asurite
      displayName
      role
      createdAt
      createdBy
      editedAt
      editedBy
      isActive
      tier1Support
      lastLogin
      groups
      updatedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      asurite
      displayName
      role
      createdAt
      createdBy
      editedAt
      editedBy
      isActive
      tier1Support
      lastLogin
      groups
      updatedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      asurite
      displayName
      role
      createdAt
      createdBy
      editedAt
      editedBy
      isActive
      tier1Support
      lastLogin
      groups
      updatedAt
    }
  }
`;
export const onCreateChatbotGroup = /* GraphQL */ `
  subscription OnCreateChatbotGroup {
    onCreateChatbotGroup {
      id
      name
      isActive
      email
      dialoguesFolders {
        name
        tagValue
        parent
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChatbotGroup = /* GraphQL */ `
  subscription OnUpdateChatbotGroup {
    onUpdateChatbotGroup {
      id
      name
      isActive
      email
      dialoguesFolders {
        name
        tagValue
        parent
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChatbotGroup = /* GraphQL */ `
  subscription OnDeleteChatbotGroup {
    onDeleteChatbotGroup {
      id
      name
      isActive
      email
      dialoguesFolders {
        name
        tagValue
        parent
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCriticalEscalation = /* GraphQL */ `
  subscription OnCreateCriticalEscalation {
    onCreateCriticalEscalation {
      id
      triggerPhrase
      escalation
      variations
      status
      statusKey
      editing {
        asurite
        timestamp
      }
      processing
      processingError
      escalateSms
      answerData {
        answer
        flow
        flowVersion
        audience
        audienceEveryone
        type
        answerEscalation
        image
      }
      fallbackAnswer
      fallbackAnswerImage
      deleted
      group
      createdAt
      createdAtKey
      submittedBy
      submittedByKey
      response
      editedBy {
        asurite
        timestamp
      }
      deletedBy {
        asurite
        timestamp
      }
      timestamp
      timestampKey
      lastEdit
      updatedAt
    }
  }
`;
export const onUpdateCriticalEscalation = /* GraphQL */ `
  subscription OnUpdateCriticalEscalation {
    onUpdateCriticalEscalation {
      id
      triggerPhrase
      escalation
      variations
      status
      statusKey
      editing {
        asurite
        timestamp
      }
      processing
      processingError
      escalateSms
      answerData {
        answer
        flow
        flowVersion
        audience
        audienceEveryone
        type
        answerEscalation
        image
      }
      fallbackAnswer
      fallbackAnswerImage
      deleted
      group
      createdAt
      createdAtKey
      submittedBy
      submittedByKey
      response
      editedBy {
        asurite
        timestamp
      }
      deletedBy {
        asurite
        timestamp
      }
      timestamp
      timestampKey
      lastEdit
      updatedAt
    }
  }
`;
export const onDeleteCriticalEscalation = /* GraphQL */ `
  subscription OnDeleteCriticalEscalation {
    onDeleteCriticalEscalation {
      id
      triggerPhrase
      escalation
      variations
      status
      statusKey
      editing {
        asurite
        timestamp
      }
      processing
      processingError
      escalateSms
      answerData {
        answer
        flow
        flowVersion
        audience
        audienceEveryone
        type
        answerEscalation
        image
      }
      fallbackAnswer
      fallbackAnswerImage
      deleted
      group
      createdAt
      createdAtKey
      submittedBy
      submittedByKey
      response
      editedBy {
        asurite
        timestamp
      }
      deletedBy {
        asurite
        timestamp
      }
      timestamp
      timestampKey
      lastEdit
      updatedAt
    }
  }
`;
export const onCreateFlowNode = /* GraphQL */ `
  subscription OnCreateFlowNode {
    onCreateFlowNode {
      id
      flowId
      type
      customType
      text
      parent
      child
      ignore
      match
      matches {
        id
        match
      }
      matchText
      deleted
      deleteAudit {
        asurite
        timestamp
      }
      jumpToNodeId
      messageBody
      postSkipJump
      skipAfterTimes
      response
      escalationName
      escalationType
      image
      responseImage
      escalationDump
      templateId
      saveAsTemplate
      messageName
      ownerGroup
      audience
      branchDataCollector
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFlowNode = /* GraphQL */ `
  subscription OnUpdateFlowNode {
    onUpdateFlowNode {
      id
      flowId
      type
      customType
      text
      parent
      child
      ignore
      match
      matches {
        id
        match
      }
      matchText
      deleted
      deleteAudit {
        asurite
        timestamp
      }
      jumpToNodeId
      messageBody
      postSkipJump
      skipAfterTimes
      response
      escalationName
      escalationType
      image
      responseImage
      escalationDump
      templateId
      saveAsTemplate
      messageName
      ownerGroup
      audience
      branchDataCollector
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFlowNode = /* GraphQL */ `
  subscription OnDeleteFlowNode {
    onDeleteFlowNode {
      id
      flowId
      type
      customType
      text
      parent
      child
      ignore
      match
      matches {
        id
        match
      }
      matchText
      deleted
      deleteAudit {
        asurite
        timestamp
      }
      jumpToNodeId
      messageBody
      postSkipJump
      skipAfterTimes
      response
      escalationName
      escalationType
      image
      responseImage
      escalationDump
      templateId
      saveAsTemplate
      messageName
      ownerGroup
      audience
      branchDataCollector
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCampaignUserInput = /* GraphQL */ `
  subscription OnCreateCampaignUserInput {
    onCreateCampaignUserInput {
      id
      userId
      flowId
      campaignId
      nodeId
      timestamp
      button
      inputDump
      type
      flowVersion
      botType
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCampaignUserInput = /* GraphQL */ `
  subscription OnUpdateCampaignUserInput {
    onUpdateCampaignUserInput {
      id
      userId
      flowId
      campaignId
      nodeId
      timestamp
      button
      inputDump
      type
      flowVersion
      botType
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCampaignUserInput = /* GraphQL */ `
  subscription OnDeleteCampaignUserInput {
    onDeleteCampaignUserInput {
      id
      userId
      flowId
      campaignId
      nodeId
      timestamp
      button
      inputDump
      type
      flowVersion
      botType
      createdAt
      updatedAt
    }
  }
`;
export const onCreateEscalation = /* GraphQL */ `
  subscription OnCreateEscalation {
    onCreateEscalation {
      id
      escalationName
      group
      groupKey
      escalationType
      emailTo
      ccemail
      emailSubject
      emailBody
      attachChatLog
      sendTextMessage
      status
      statusKey
      textMessageContent {
        to
        textBody
      }
      submittedBy
      submittedByKey
      submittedAt
      submittedAtKey
      liveAgentCategory
      attachedAlertIds
      editedBy {
        asurite
        timestamp
      }
      deleted
      timestamp
      timestampKey
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEscalation = /* GraphQL */ `
  subscription OnUpdateEscalation {
    onUpdateEscalation {
      id
      escalationName
      group
      groupKey
      escalationType
      emailTo
      ccemail
      emailSubject
      emailBody
      attachChatLog
      sendTextMessage
      status
      statusKey
      textMessageContent {
        to
        textBody
      }
      submittedBy
      submittedByKey
      submittedAt
      submittedAtKey
      liveAgentCategory
      attachedAlertIds
      editedBy {
        asurite
        timestamp
      }
      deleted
      timestamp
      timestampKey
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteEscalation = /* GraphQL */ `
  subscription OnDeleteEscalation {
    onDeleteEscalation {
      id
      escalationName
      group
      groupKey
      escalationType
      emailTo
      ccemail
      emailSubject
      emailBody
      attachChatLog
      sendTextMessage
      status
      statusKey
      textMessageContent {
        to
        textBody
      }
      submittedBy
      submittedByKey
      submittedAt
      submittedAtKey
      liveAgentCategory
      attachedAlertIds
      editedBy {
        asurite
        timestamp
      }
      deleted
      timestamp
      timestampKey
      createdAt
      updatedAt
    }
  }
`;
export const onCreateEscalationHistory = /* GraphQL */ `
  subscription OnCreateEscalationHistory {
    onCreateEscalationHistory {
      id
      escalationId
      escalationName
      escalator
      hitType
      message
      messageId
      response
      user
      convoId
      timestamp
      group
      escalationType
      emailTo
      ccemail
      emailSubject
      emailBody
      attachChatLog
      sendTextMessage
      textMessageContent {
        to
        textBody
      }
      liveAgentCategory
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEscalationHistory = /* GraphQL */ `
  subscription OnUpdateEscalationHistory {
    onUpdateEscalationHistory {
      id
      escalationId
      escalationName
      escalator
      hitType
      message
      messageId
      response
      user
      convoId
      timestamp
      group
      escalationType
      emailTo
      ccemail
      emailSubject
      emailBody
      attachChatLog
      sendTextMessage
      textMessageContent {
        to
        textBody
      }
      liveAgentCategory
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteEscalationHistory = /* GraphQL */ `
  subscription OnDeleteEscalationHistory {
    onDeleteEscalationHistory {
      id
      escalationId
      escalationName
      escalator
      hitType
      message
      messageId
      response
      user
      convoId
      timestamp
      group
      escalationType
      emailTo
      ccemail
      emailSubject
      emailBody
      attachChatLog
      sendTextMessage
      textMessageContent {
        to
        textBody
      }
      liveAgentCategory
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCustomDefaultMessage = /* GraphQL */ `
  subscription OnCreateCustomDefaultMessage {
    onCreateCustomDefaultMessage {
      id
      owner
      author
      type
      message
      number
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCustomDefaultMessage = /* GraphQL */ `
  subscription OnUpdateCustomDefaultMessage {
    onUpdateCustomDefaultMessage {
      id
      owner
      author
      type
      message
      number
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCustomDefaultMessage = /* GraphQL */ `
  subscription OnDeleteCustomDefaultMessage {
    onDeleteCustomDefaultMessage {
      id
      owner
      author
      type
      message
      number
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePromptResponse = /* GraphQL */ `
  subscription OnCreatePromptResponse {
    onCreatePromptResponse {
      id
      messageId
      asurite
      message
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePromptResponse = /* GraphQL */ `
  subscription OnUpdatePromptResponse {
    onUpdatePromptResponse {
      id
      messageId
      asurite
      message
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePromptResponse = /* GraphQL */ `
  subscription OnDeletePromptResponse {
    onDeletePromptResponse {
      id
      messageId
      asurite
      message
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChatbotMessage = /* GraphQL */ `
  subscription OnCreateChatbotMessage {
    onCreateChatbotMessage {
      convoId
      createdAt
      messageId
      content
      sender
      type
      connectionId
      file
      agentAsurite
      agentAction
      agent
      botType
      sessionId
      updatedAt
    }
  }
`;
export const onUpdateChatbotMessage = /* GraphQL */ `
  subscription OnUpdateChatbotMessage {
    onUpdateChatbotMessage {
      convoId
      createdAt
      messageId
      content
      sender
      type
      connectionId
      file
      agentAsurite
      agentAction
      agent
      botType
      sessionId
      updatedAt
    }
  }
`;
export const onDeleteChatbotMessage = /* GraphQL */ `
  subscription OnDeleteChatbotMessage {
    onDeleteChatbotMessage {
      convoId
      createdAt
      messageId
      content
      sender
      type
      connectionId
      file
      agentAsurite
      agentAction
      agent
      botType
      sessionId
      updatedAt
    }
  }
`;
export const onCreateChatbotMessageTag = /* GraphQL */ `
  subscription OnCreateChatbotMessageTag {
    onCreateChatbotMessageTag {
      id
      convoId
      messageId
      tag
      deleted
      addedBy
      deletedBy
      botType
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChatbotMessageTag = /* GraphQL */ `
  subscription OnUpdateChatbotMessageTag {
    onUpdateChatbotMessageTag {
      id
      convoId
      messageId
      tag
      deleted
      addedBy
      deletedBy
      botType
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChatbotMessageTag = /* GraphQL */ `
  subscription OnDeleteChatbotMessageTag {
    onDeleteChatbotMessageTag {
      id
      convoId
      messageId
      tag
      deleted
      addedBy
      deletedBy
      botType
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChatbotLexSession = /* GraphQL */ `
  subscription OnCreateChatbotLexSession {
    onCreateChatbotLexSession {
      id
      botType
      missedCount
      expireTime
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChatbotLexSession = /* GraphQL */ `
  subscription OnUpdateChatbotLexSession {
    onUpdateChatbotLexSession {
      id
      botType
      missedCount
      expireTime
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChatbotLexSession = /* GraphQL */ `
  subscription OnDeleteChatbotLexSession {
    onDeleteChatbotLexSession {
      id
      botType
      missedCount
      expireTime
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChatbotSession = /* GraphQL */ `
  subscription OnCreateChatbotSession {
    onCreateChatbotSession {
      id
      asurite
      baseStartDate
      startTime
      endTime
      origin
      originLocation
      flowStatus
      connectionId
      totalTime
      caseNumber
      liveagentEsc
      sentMessages
      receivedMessages
      totalMessages
      dialogueEntered
      agentAsurite
      leftBeforeTransfer
      userId
      botType
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChatbotSession = /* GraphQL */ `
  subscription OnUpdateChatbotSession {
    onUpdateChatbotSession {
      id
      asurite
      baseStartDate
      startTime
      endTime
      origin
      originLocation
      flowStatus
      connectionId
      totalTime
      caseNumber
      liveagentEsc
      sentMessages
      receivedMessages
      totalMessages
      dialogueEntered
      agentAsurite
      leftBeforeTransfer
      userId
      botType
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChatbotSession = /* GraphQL */ `
  subscription OnDeleteChatbotSession {
    onDeleteChatbotSession {
      id
      asurite
      baseStartDate
      startTime
      endTime
      origin
      originLocation
      flowStatus
      connectionId
      totalTime
      caseNumber
      liveagentEsc
      sentMessages
      receivedMessages
      totalMessages
      dialogueEntered
      agentAsurite
      leftBeforeTransfer
      userId
      botType
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChatbotUserHistorical = /* GraphQL */ `
  subscription OnCreateChatbotUserHistorical {
    onCreateChatbotUserHistorical {
      id
      botType
      asurite
      phoneNumber
      removedAt
      removeReason
      convoId
      type
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChatbotUserHistorical = /* GraphQL */ `
  subscription OnUpdateChatbotUserHistorical {
    onUpdateChatbotUserHistorical {
      id
      botType
      asurite
      phoneNumber
      removedAt
      removeReason
      convoId
      type
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChatbotUserHistorical = /* GraphQL */ `
  subscription OnDeleteChatbotUserHistorical {
    onDeleteChatbotUserHistorical {
      id
      botType
      asurite
      phoneNumber
      removedAt
      removeReason
      convoId
      type
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChatbotUserStaging = /* GraphQL */ `
  subscription OnCreateChatbotUserStaging {
    onCreateChatbotUserStaging {
      id
      searchId
      peoplesoftData
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChatbotUserStaging = /* GraphQL */ `
  subscription OnUpdateChatbotUserStaging {
    onUpdateChatbotUserStaging {
      id
      searchId
      peoplesoftData
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChatbotUserStaging = /* GraphQL */ `
  subscription OnDeleteChatbotUserStaging {
    onDeleteChatbotUserStaging {
      id
      searchId
      peoplesoftData
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChatbotUser = /* GraphQL */ `
  subscription OnCreateChatbotUser {
    onCreateChatbotUser {
      id
      paused
      optOut
      userOptOut
      stopped
      botType
      type
      latest
      asurite
      phoneNumber
      homeNumber
      convoId
      firstName
      lastName
      displayName
      agentPauseAsurite
      agentOptOutAsurite
      reinstateDate
      altConvoIds
      tags {
        items {
          id
          userId
          tag
          deleted
          addedBy
          deletedBy
          botType
          userInfo {
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChatbotUser = /* GraphQL */ `
  subscription OnUpdateChatbotUser {
    onUpdateChatbotUser {
      id
      paused
      optOut
      userOptOut
      stopped
      botType
      type
      latest
      asurite
      phoneNumber
      homeNumber
      convoId
      firstName
      lastName
      displayName
      agentPauseAsurite
      agentOptOutAsurite
      reinstateDate
      altConvoIds
      tags {
        items {
          id
          userId
          tag
          deleted
          addedBy
          deletedBy
          botType
          userInfo {
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChatbotUser = /* GraphQL */ `
  subscription OnDeleteChatbotUser {
    onDeleteChatbotUser {
      id
      paused
      optOut
      userOptOut
      stopped
      botType
      type
      latest
      asurite
      phoneNumber
      homeNumber
      convoId
      firstName
      lastName
      displayName
      agentPauseAsurite
      agentOptOutAsurite
      reinstateDate
      altConvoIds
      tags {
        items {
          id
          userId
          tag
          deleted
          addedBy
          deletedBy
          botType
          userInfo {
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChatbotUserTag = /* GraphQL */ `
  subscription OnCreateChatbotUserTag {
    onCreateChatbotUserTag {
      id
      userId
      tag
      deleted
      addedBy
      deletedBy
      botType
      userInfo {
        items {
          id
          paused
          optOut
          userOptOut
          stopped
          botType
          type
          latest
          asurite
          phoneNumber
          homeNumber
          convoId
          firstName
          lastName
          displayName
          agentPauseAsurite
          agentOptOutAsurite
          reinstateDate
          altConvoIds
          tags {
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChatbotUserTag = /* GraphQL */ `
  subscription OnUpdateChatbotUserTag {
    onUpdateChatbotUserTag {
      id
      userId
      tag
      deleted
      addedBy
      deletedBy
      botType
      userInfo {
        items {
          id
          paused
          optOut
          userOptOut
          stopped
          botType
          type
          latest
          asurite
          phoneNumber
          homeNumber
          convoId
          firstName
          lastName
          displayName
          agentPauseAsurite
          agentOptOutAsurite
          reinstateDate
          altConvoIds
          tags {
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChatbotUserTag = /* GraphQL */ `
  subscription OnDeleteChatbotUserTag {
    onDeleteChatbotUserTag {
      id
      userId
      tag
      deleted
      addedBy
      deletedBy
      botType
      userInfo {
        items {
          id
          paused
          optOut
          userOptOut
          stopped
          botType
          type
          latest
          asurite
          phoneNumber
          homeNumber
          convoId
          firstName
          lastName
          displayName
          agentPauseAsurite
          agentOptOutAsurite
          reinstateDate
          altConvoIds
          tags {
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChatbotConvoTag = /* GraphQL */ `
  subscription OnCreateChatbotConvoTag {
    onCreateChatbotConvoTag {
      id
      convoId
      tag
      deleted
      addedBy
      deletedBy
      botType
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChatbotConvoTag = /* GraphQL */ `
  subscription OnUpdateChatbotConvoTag {
    onUpdateChatbotConvoTag {
      id
      convoId
      tag
      deleted
      addedBy
      deletedBy
      botType
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChatbotConvoTag = /* GraphQL */ `
  subscription OnDeleteChatbotConvoTag {
    onDeleteChatbotConvoTag {
      id
      convoId
      tag
      deleted
      addedBy
      deletedBy
      botType
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChatbotConversation = /* GraphQL */ `
  subscription OnCreateChatbotConversation {
    onCreateChatbotConversation {
      id
      latest
      asurite
      userId
      botType
      type
      paused
      new
      optOut
      unseenBy
      agents
      userOptOut
      agentPauseAsurite
      agentOptOutAsurite
      reinstateDate
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChatbotConversation = /* GraphQL */ `
  subscription OnUpdateChatbotConversation {
    onUpdateChatbotConversation {
      id
      latest
      asurite
      userId
      botType
      type
      paused
      new
      optOut
      unseenBy
      agents
      userOptOut
      agentPauseAsurite
      agentOptOutAsurite
      reinstateDate
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChatbotConversation = /* GraphQL */ `
  subscription OnDeleteChatbotConversation {
    onDeleteChatbotConversation {
      id
      latest
      asurite
      userId
      botType
      type
      paused
      new
      optOut
      unseenBy
      agents
      userOptOut
      agentPauseAsurite
      agentOptOutAsurite
      reinstateDate
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChatbotDialogueRule = /* GraphQL */ `
  subscription OnCreateChatbotDialogueRule {
    onCreateChatbotDialogueRule {
      id
      name
      displayName
      keywords
      active
      deleted
      assignedFolder
      assignedGroup
      assignedUser
      submittedBy
      editedBy {
        asurite
        timestamp
      }
      deletedBy {
        asurite
        timestamp
      }
      botType
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChatbotDialogueRule = /* GraphQL */ `
  subscription OnUpdateChatbotDialogueRule {
    onUpdateChatbotDialogueRule {
      id
      name
      displayName
      keywords
      active
      deleted
      assignedFolder
      assignedGroup
      assignedUser
      submittedBy
      editedBy {
        asurite
        timestamp
      }
      deletedBy {
        asurite
        timestamp
      }
      botType
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChatbotDialogueRule = /* GraphQL */ `
  subscription OnDeleteChatbotDialogueRule {
    onDeleteChatbotDialogueRule {
      id
      name
      displayName
      keywords
      active
      deleted
      assignedFolder
      assignedGroup
      assignedUser
      submittedBy
      editedBy {
        asurite
        timestamp
      }
      deletedBy {
        asurite
        timestamp
      }
      botType
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChatbotWebsocketConnection = /* GraphQL */ `
  subscription OnCreateChatbotWebsocketConnection {
    onCreateChatbotWebsocketConnection {
      connectionId
      convoId
      agentChatting
      agentDetails {
        asurite
        displayName
      }
      asurite
      domainName
      phoneNumber
      stage
      type
      webConnection
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChatbotWebsocketConnection = /* GraphQL */ `
  subscription OnUpdateChatbotWebsocketConnection {
    onUpdateChatbotWebsocketConnection {
      connectionId
      convoId
      agentChatting
      agentDetails {
        asurite
        displayName
      }
      asurite
      domainName
      phoneNumber
      stage
      type
      webConnection
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChatbotWebsocketConnection = /* GraphQL */ `
  subscription OnDeleteChatbotWebsocketConnection {
    onDeleteChatbotWebsocketConnection {
      connectionId
      convoId
      agentChatting
      agentDetails {
        asurite
        displayName
      }
      asurite
      domainName
      phoneNumber
      stage
      type
      webConnection
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTriggeredIntent = /* GraphQL */ `
  subscription OnCreateTriggeredIntent {
    onCreateTriggeredIntent {
      id
      intentId
      asurite
      timestamp
      phrase
      kbId
      response
      originSite
      baseDate
      sessionId
      escalationInfo
      botType
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTriggeredIntent = /* GraphQL */ `
  subscription OnUpdateTriggeredIntent {
    onUpdateTriggeredIntent {
      id
      intentId
      asurite
      timestamp
      phrase
      kbId
      response
      originSite
      baseDate
      sessionId
      escalationInfo
      botType
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTriggeredIntent = /* GraphQL */ `
  subscription OnDeleteTriggeredIntent {
    onDeleteTriggeredIntent {
      id
      intentId
      asurite
      timestamp
      phrase
      kbId
      response
      originSite
      baseDate
      sessionId
      escalationInfo
      botType
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUtterances = /* GraphQL */ `
  subscription OnCreateUtterances {
    onCreateUtterances {
      phrase
      compKey
      baseDate
      origin
      count
      detected
      missed
      botType
      users
      alternates
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUtterances = /* GraphQL */ `
  subscription OnUpdateUtterances {
    onUpdateUtterances {
      phrase
      compKey
      baseDate
      origin
      count
      detected
      missed
      botType
      users
      alternates
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUtterances = /* GraphQL */ `
  subscription OnDeleteUtterances {
    onDeleteUtterances {
      phrase
      compKey
      baseDate
      origin
      count
      detected
      missed
      botType
      users
      alternates
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePendingCampaign = /* GraphQL */ `
  subscription OnCreatePendingCampaign {
    onCreatePendingCampaign {
      id
      sendTime
      flowId
      users
      type
      flowNodes
      parent
      expireTime
      status
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePendingCampaign = /* GraphQL */ `
  subscription OnUpdatePendingCampaign {
    onUpdatePendingCampaign {
      id
      sendTime
      flowId
      users
      type
      flowNodes
      parent
      expireTime
      status
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePendingCampaign = /* GraphQL */ `
  subscription OnDeletePendingCampaign {
    onDeletePendingCampaign {
      id
      sendTime
      flowId
      users
      type
      flowNodes
      parent
      expireTime
      status
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDialogueNotes = /* GraphQL */ `
  subscription OnCreateDialogueNotes {
    onCreateDialogueNotes {
      id
      convoId
      messageId
      content
      author
      deleted
      botType
      editedBy {
        asurite
        timestamp
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDialogueNotes = /* GraphQL */ `
  subscription OnUpdateDialogueNotes {
    onUpdateDialogueNotes {
      id
      convoId
      messageId
      content
      author
      deleted
      botType
      editedBy {
        asurite
        timestamp
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDialogueNotes = /* GraphQL */ `
  subscription OnDeleteDialogueNotes {
    onDeleteDialogueNotes {
      id
      convoId
      messageId
      content
      author
      deleted
      botType
      editedBy {
        asurite
        timestamp
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChatbotAnnouncements = /* GraphQL */ `
  subscription OnCreateChatbotAnnouncements {
    onCreateChatbotAnnouncements {
      id
      name
      text
      active
      createdBy
      editedBy {
        asurite
        timestamp
      }
      group
      showOn
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChatbotAnnouncements = /* GraphQL */ `
  subscription OnUpdateChatbotAnnouncements {
    onUpdateChatbotAnnouncements {
      id
      name
      text
      active
      createdBy
      editedBy {
        asurite
        timestamp
      }
      group
      showOn
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChatbotAnnouncements = /* GraphQL */ `
  subscription OnDeleteChatbotAnnouncements {
    onDeleteChatbotAnnouncements {
      id
      name
      text
      active
      createdBy
      editedBy {
        asurite
        timestamp
      }
      group
      showOn
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChatbotAnnouncementsHistorical = /* GraphQL */ `
  subscription OnCreateChatbotAnnouncementsHistorical {
    onCreateChatbotAnnouncementsHistorical {
      id
      name
      createdAt
      text
      startTime
      startedBy
      endTime
      endedBy
      group
      showOn
      versionId
      updatedAt
    }
  }
`;
export const onUpdateChatbotAnnouncementsHistorical = /* GraphQL */ `
  subscription OnUpdateChatbotAnnouncementsHistorical {
    onUpdateChatbotAnnouncementsHistorical {
      id
      name
      createdAt
      text
      startTime
      startedBy
      endTime
      endedBy
      group
      showOn
      versionId
      updatedAt
    }
  }
`;
export const onDeleteChatbotAnnouncementsHistorical = /* GraphQL */ `
  subscription OnDeleteChatbotAnnouncementsHistorical {
    onDeleteChatbotAnnouncementsHistorical {
      id
      name
      createdAt
      text
      startTime
      startedBy
      endTime
      endedBy
      group
      showOn
      versionId
      updatedAt
    }
  }
`;
export const onCreateChatbotAnnouncementAnalytics = /* GraphQL */ `
  subscription OnCreateChatbotAnnouncementAnalytics {
    onCreateChatbotAnnouncementAnalytics {
      id
      asurite
      announcementId
      versionId
      action
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChatbotAnnouncementAnalytics = /* GraphQL */ `
  subscription OnUpdateChatbotAnnouncementAnalytics {
    onUpdateChatbotAnnouncementAnalytics {
      id
      asurite
      announcementId
      versionId
      action
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChatbotAnnouncementAnalytics = /* GraphQL */ `
  subscription OnDeleteChatbotAnnouncementAnalytics {
    onDeleteChatbotAnnouncementAnalytics {
      id
      asurite
      announcementId
      versionId
      action
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChatbotIntroMessage = /* GraphQL */ `
  subscription OnCreateChatbotIntroMessage {
    onCreateChatbotIntroMessage {
      id
      message
      status
      statusKey
      botType
      matches
      group
      createdBy
      deleted
      deletedBy {
        asurite
        timestamp
      }
      searchKey
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChatbotIntroMessage = /* GraphQL */ `
  subscription OnUpdateChatbotIntroMessage {
    onUpdateChatbotIntroMessage {
      id
      message
      status
      statusKey
      botType
      matches
      group
      createdBy
      deleted
      deletedBy {
        asurite
        timestamp
      }
      searchKey
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChatbotIntroMessage = /* GraphQL */ `
  subscription OnDeleteChatbotIntroMessage {
    onDeleteChatbotIntroMessage {
      id
      message
      status
      statusKey
      botType
      matches
      group
      createdBy
      deleted
      deletedBy {
        asurite
        timestamp
      }
      searchKey
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChatbotIntroMessageHistorical = /* GraphQL */ `
  subscription OnCreateChatbotIntroMessageHistorical {
    onCreateChatbotIntroMessageHistorical {
      id
      introId
      message
      status
      statusKey
      botType
      matches
      startTime
      startedBy
      endTime
      endedBy
      versionId
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChatbotIntroMessageHistorical = /* GraphQL */ `
  subscription OnUpdateChatbotIntroMessageHistorical {
    onUpdateChatbotIntroMessageHistorical {
      id
      introId
      message
      status
      statusKey
      botType
      matches
      startTime
      startedBy
      endTime
      endedBy
      versionId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChatbotIntroMessageHistorical = /* GraphQL */ `
  subscription OnDeleteChatbotIntroMessageHistorical {
    onDeleteChatbotIntroMessageHistorical {
      id
      introId
      message
      status
      statusKey
      botType
      matches
      startTime
      startedBy
      endTime
      endedBy
      versionId
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChatbotIntroMessageAnalytics = /* GraphQL */ `
  subscription OnCreateChatbotIntroMessageAnalytics {
    onCreateChatbotIntroMessageAnalytics {
      id
      asurite
      introId
      action
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChatbotIntroMessageAnalytics = /* GraphQL */ `
  subscription OnUpdateChatbotIntroMessageAnalytics {
    onUpdateChatbotIntroMessageAnalytics {
      id
      asurite
      introId
      action
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChatbotIntroMessageAnalytics = /* GraphQL */ `
  subscription OnDeleteChatbotIntroMessageAnalytics {
    onDeleteChatbotIntroMessageAnalytics {
      id
      asurite
      introId
      action
      createdAt
      updatedAt
    }
  }
`;
export const onCreateImage = /* GraphQL */ `
  subscription OnCreateImage {
    onCreateImage {
      id
      imageId
      title
      description
      author
      group
      deleted
      deletedBy {
        asurite
        timestamp
      }
      botType
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateImage = /* GraphQL */ `
  subscription OnUpdateImage {
    onUpdateImage {
      id
      imageId
      title
      description
      author
      group
      deleted
      deletedBy {
        asurite
        timestamp
      }
      botType
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteImage = /* GraphQL */ `
  subscription OnDeleteImage {
    onDeleteImage {
      id
      imageId
      title
      description
      author
      group
      deleted
      deletedBy {
        asurite
        timestamp
      }
      botType
      createdAt
      updatedAt
    }
  }
`;
export const onCreateLink = /* GraphQL */ `
  subscription OnCreateLink {
    onCreateLink {
      id
      originalURL
      linkText
      createdBy
      group
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateLink = /* GraphQL */ `
  subscription OnUpdateLink {
    onUpdateLink {
      id
      originalURL
      linkText
      createdBy
      group
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteLink = /* GraphQL */ `
  subscription OnDeleteLink {
    onDeleteLink {
      id
      originalURL
      linkText
      createdBy
      group
      createdAt
      updatedAt
    }
  }
`;
export const onCreateLinkAnalytics = /* GraphQL */ `
  subscription OnCreateLinkAnalytics {
    onCreateLinkAnalytics {
      id
      linkId
      createdAt
      aliasId
      clickedBy
      userType
      updatedAt
    }
  }
`;
export const onUpdateLinkAnalytics = /* GraphQL */ `
  subscription OnUpdateLinkAnalytics {
    onUpdateLinkAnalytics {
      id
      linkId
      createdAt
      aliasId
      clickedBy
      userType
      updatedAt
    }
  }
`;
export const onDeleteLinkAnalytics = /* GraphQL */ `
  subscription OnDeleteLinkAnalytics {
    onDeleteLinkAnalytics {
      id
      linkId
      createdAt
      aliasId
      clickedBy
      userType
      updatedAt
    }
  }
`;
export const onCreateLinkCreation = /* GraphQL */ `
  subscription OnCreateLinkCreation {
    onCreateLinkCreation {
      id
      linkId
      aliasId
      tableId
      tableName
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateLinkCreation = /* GraphQL */ `
  subscription OnUpdateLinkCreation {
    onUpdateLinkCreation {
      id
      linkId
      aliasId
      tableId
      tableName
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteLinkCreation = /* GraphQL */ `
  subscription OnDeleteLinkCreation {
    onDeleteLinkCreation {
      id
      linkId
      aliasId
      tableId
      tableName
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChatbotAnalyticsTracker = /* GraphQL */ `
  subscription OnCreateChatbotAnalyticsTracker {
    onCreateChatbotAnalyticsTracker {
      id
      event
      timestamp
      asurite
      phoneNumber
      convoId
      userId
      agentAsurite
      type
      firstName
      lastName
      emplid
      agentPauseAsurite
      agentOptOut
      userOptOut
      isPaused
      botType
      tempField
      duringCampaign
      campaigns {
        id
        status
        startTime
        endTime
        error
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChatbotAnalyticsTracker = /* GraphQL */ `
  subscription OnUpdateChatbotAnalyticsTracker {
    onUpdateChatbotAnalyticsTracker {
      id
      event
      timestamp
      asurite
      phoneNumber
      convoId
      userId
      agentAsurite
      type
      firstName
      lastName
      emplid
      agentPauseAsurite
      agentOptOut
      userOptOut
      isPaused
      botType
      tempField
      duringCampaign
      campaigns {
        id
        status
        startTime
        endTime
        error
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChatbotAnalyticsTracker = /* GraphQL */ `
  subscription OnDeleteChatbotAnalyticsTracker {
    onDeleteChatbotAnalyticsTracker {
      id
      event
      timestamp
      asurite
      phoneNumber
      convoId
      userId
      agentAsurite
      type
      firstName
      lastName
      emplid
      agentPauseAsurite
      agentOptOut
      userOptOut
      isPaused
      botType
      tempField
      duringCampaign
      campaigns {
        id
        status
        startTime
        endTime
        error
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChatbotInteractionSessions = /* GraphQL */ `
  subscription OnCreateChatbotInteractionSessions {
    onCreateChatbotInteractionSessions {
      sessionId
      type
      callingNumber
      convoId
      status
      sendTranscript
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChatbotInteractionSessions = /* GraphQL */ `
  subscription OnUpdateChatbotInteractionSessions {
    onUpdateChatbotInteractionSessions {
      sessionId
      type
      callingNumber
      convoId
      status
      sendTranscript
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChatbotInteractionSessions = /* GraphQL */ `
  subscription OnDeleteChatbotInteractionSessions {
    onDeleteChatbotInteractionSessions {
      sessionId
      type
      callingNumber
      convoId
      status
      sendTranscript
      createdAt
      updatedAt
    }
  }
`;
