import React from "react";
import Input from "./Input";
import InputForm from "./InputForm";

export default function InputX(props) {
  if (props.form) {
    return <InputForm {...props} />;
  } else {
    return <Input {...props} />;
  }
}
