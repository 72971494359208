import React, { useContext, useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import TableRow from './TableRow';
import TableLabel from './TableLabel';
import { SearchBar, Modal, ModalContent } from '../';
import { store } from 'contexts/GlobalStore';
import { getUserGroups } from 'hooks';

export default function Table(props) {
  const theme = useTheme();

  const GlobalStore = useContext(store);
  const { dispatch } = GlobalStore;
  const GlobalState = GlobalStore.state;
  const {
    currentPage,
    tableDataEmpty,
    loadingTableData,
    clearNextTokens,
  } = GlobalState;

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const { keyedNames, selectListGroups } = getUserGroups();

  useEffect(() => {
    if (clearNextTokens) {
      dispatch({ type: 'setNextTokenList', nextTokenList: [] });
      dispatch({
        type: 'clearNextTokens',
        clearNextTokens: false,
      });
      if (currentPage === 1) {
        dispatch({ type: 'setRefetchKey', refetchKey: Date.now() });
      } else {
        dispatch({ type: 'setCurrentPage', currentPage: 1 });
      }
    }
  }, [clearNextTokens]);

  const onChangeSearch = value => {
    if (props.onChangeSearch) {
      return props.onChangeSearch(value);
    } else {
      return console.log('no onChangeSearch prop provided to Table.js');
    }
  };

  const onClickSearch = () => {
    if (props.onClickSearch) {
      return props.onClickSearch();
    } else {
      return console.log('no onClickSearch prop provided to Table.js');
    }
  };

  const onClickResolve = value => {
    if (props.onClickResolve) {
      return props.onClickResolve(value);
    } else {
      return console.log('no onClickResolve prop provided to Table.js');
    }
  };

  const onClickReset = () => {
    if (props.onClickReset) {
      props.onSelectFilter();
      return props.onClickReset();
    } else {
      return console.log('no onClickReset prop provided to Table.js');
    }
  };

  const onSelectFilter = value => {
    if (props.onSelectFilter) {
      return props.onSelectFilter(value);
    } else {
      return console.log('no onSelectFilter prop provided to Table.js');
    }
  };

  const onSelectFilter2 = value => {
    if (props.onSelectFilter2) {
      return props.onSelectFilter2(value);
    } else {
      return console.log('no onSelectFilter2 prop provided to Table.js');
    }
  };

  const enterEvent = e => {
    onClickSearch();
  };

  const getFilterOptions = () => {
    if (props.filterOptions) {
      return props.filterOptions;
    } else {
      return null;
    }
  };

  const getSortOptions = () => {
    if (props.sortOptions) {
      return props.sortOptions;
    } else {
      return null;
    }
  };

  const getSortKey = label => {
    if (props.getSortKey) {
      return props.getSortKey(label);
    } else {
      console.log('no getSortKey prop provided to Table.js');
    }
  };

  const getDefaultSortKey = () => {
    if (props.defaultSortKey) {
      return props.defaultSortKey;
    } else {
      console.log('no defaultSortKey prop provided to Table.js');
    }
  };

  const onClickDelete = item => {
    setItemToDelete(item);
    setShowDeleteModal(true);
  };

  const onClickCustom1 = data => {
    //console.log("PROPS", props);
    if (props.onClickCustom1) {
      props.onClickCustom1(data);
    } else {
      console.log('no onClickCustom1 handler provided for Table.js');
    }
  };

  const onClickEdit = data => {
    if (props.onClickEdit) {
      props.onClickEdit(data);
    } else {
      console.log('no onClickEdit prop provided to Table.js');
    }
  };

  const onSubmitDelete = () => {
    if (props.onSubmitDelete) {
      setShowDeleteModal(false);
      props.onSubmitDelete(itemToDelete);
    } else {
      console.log('no onSubmitDelete prop provided to Table.js');
    }
  };

  const onClickPause = data => {
    if (props.onClickPause) {
      props.onClickPause(data);
    } else {
      console.log('no onClickPause prop provided to Table.js');
    }
  };

  const onClickStart = data => {
    if (props.onClickStart) {
      props.onClickStart(data);
    } else {
      console.log('no onClickStart prop provided to Table.js');
    }
  };

  const onClickView = data => {
    if (props.onClickView) {
      props.onClickView(data);
    } else {
      console.log('no onClickView prop provided to Table.js');
    }
  };

  const renderTableData = () => {
    if (!loadingTableData && !tableDataEmpty) {
      return (
        <div style={{ padding: '0 2rem' }}>
          {props.data.map((el, index) => {
            return (
              <TableRow
                key={index}
                data={el}
                keyedNames={keyedNames}
                useversionstatus={props.useversionstatus}
                onClickDelete={() => onClickDelete(el)}
                onClickEdit={onClickEdit}
                onClickCustom1={onClickCustom1}
                onClickPause={onClickPause}
                onClickView={onClickView}
                onClickStart={onClickStart}
                onClickResolve={onClickResolve}
                responseTable={props.responseTable}
                imageTable={props.imageTable}
                noTimestamps={props.noTimestamps ? true : false}
              />
            );
          })}
        </div>
      );
    }
  };

  return (
    <div style={{ ...props.style }}>
      {!props.noSearch && (
        <SearchBar
          onChange={onChangeSearch}
          onClickSearch={onClickSearch}
          onClickReset={onClickReset}
          filterOptions={getFilterOptions()}
          sortOptions={getSortOptions()}
          filterOptions2={props.filterOptions2}
          filterButtonText={props.filterButtonText}
          filterButtonText2={props.filterButtonText2}
          onSelectFilter2={onSelectFilter2}
          onSelectFilter={onSelectFilter}
          enterEvent={enterEvent}
          noSort={props.noSort ? true : false}
          noFilter={props.noFilter ? true : false}
          style={{ borderWidth: '0 0 1px 0' }}
        />
      )}
      <TableLabel
        title={props.title ? props.title : null}
        body={props.body ? props.body : null}
        items={props.labels}
        getSortKey={getSortKey}
        defaultSortKey={getDefaultSortKey()}
        sortLocal={props.sortLocal ? props.sortLocal : function() {}}
      />
      {loadingTableData && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '6rem',
          }}
        >
          <CircularProgress
            style={{ color: theme.palette.general.lightMaroon }}
          />
        </div>
      )}
      {tableDataEmpty && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '4rem 0',
          }}
        >
          No data returned
        </div>
      )}
      {renderTableData()}
      <Modal
        isShowing={showDeleteModal}
        hide={() => setShowDeleteModal(false)}
        content={
          <ModalContent
            confirmation
            title="Confirm delete"
            onSubmit={onSubmitDelete}
            toggle={() => setShowDeleteModal(false)}
          />
        }
      />
    </div>
  );
}
