import { API, graphqlOperation } from 'aws-amplify';
import { listUsers, listChatbotGroups, listEscalations } from 'graphql/queries';

export const getGroups = async tokens => {
  let gArr = [];
  let myGroupsx = ['needsReview true'];
  let baseFilters = [];
  let kG = {};

  let rawresp = await API.graphql(
    graphqlOperation(listChatbotGroups, {
      filter: {
        id: { ['ne']: 'Benji_1593621826' },
      },
    })
  );
  let resp = rawresp.data.listChatbotGroups.items;

  for (var i = 0; i < resp.length; ++i) {
    if (tokens.role === 'content creator' || tokens.role === 'approver') {
      if (
        tokens.groups.indexOf(resp[i].name) > -1 ||
        tokens.groups.indexOf(resp[i].id) > -1
      ) {
        myGroupsx.push('needsReview ' + resp[i].id);
        gArr.push({
          id: resp[i].id,
          display: resp[i].name,
          folders: resp[i].dialoguesFolders,
        });
      }
    } else if (tokens.role === 'admin' || tokens.role === 'publisher') {
      tokens.groups.forEach(string => {
        if (string.indexOf(resp[i].name) > -1) {
          myGroupsx.push('needsReview ' + resp[i].id);
        }
      });

      gArr.push({
        id: resp[i].id,
        display: resp[i].name,
        folders: resp[i].dialoguesFolders,
      });
    }

    if (tokens.asurite) {
      myGroupsx.push('needsReview ' + tokens.asurite);
    }

    kG[resp[i].id] = resp[i].name;
  }

  sortAlphabetically(gArr, 'display');

  return {
    gArr: gArr,
    myGroupsx: myGroupsx,
    baseFilters: baseFilters,
    kG: kG,
  };
};

export const getUsers = async () => {
  const result = await API.graphql(
    graphqlOperation(listUsers, {
      limit: 200,
      filter: { isActive: { eq: true } },
    })
  );

  let rawUserData = result.data.listUsers.items;

  let tmp = [];
  let allU = [];
  let kU = {};
  let kUG = {};
  for (var i = 0; i < rawUserData.length; ++i) {
    tmp.push(rawUserData[i].asurite);
    allU.push({
      id: rawUserData[i].asurite,
      display: rawUserData[i].displayName,
    });
    kU[rawUserData[i].asurite] = rawUserData[i].displayName;
    let g = rawUserData[i].groups;
    for (var j = 0; j < g.length; ++j) {
      if (!kUG[g[j]]) {
        kUG[g[j]] = [];
      }

      kUG[g[j]].push({
        value: rawUserData[i].asurite,
        label: rawUserData[i].displayName,
      });
    }
  }

  sortAlphabetically(allU, 'display');
  return {
    tmp: tmp,
    allU: allU,
    kU: kU,
    kUG: kUG,
  };
};

export const getEscalations = async tokens => {
  const result = await API.graphql(
    graphqlOperation(listEscalations, {
      limit: 1000,
      filter: { deleted: { eq: false }, status: { eq: 4 }, group: { ne: 'Benji_1593621826' } },
    })
  );

  let escalationNames = [];
  let results = result.data.listEscalations.items;
  escalationNames.push({ value: '', label: '-- No Escalation --' });

  if (tokens.role === 'approver' || tokens.role === 'content creator') {
    results = results.filter(el => tokens.groups.includes(el.group));
  }

  if (results) {
    for (let i = 0; i < results.length; i++) {
      escalationNames.push({
        value: results[i].id,
        label: results[i].escalationName,
      });
    }
  }
  return escalationNames;
};

const sortAlphabetically = (arr, key) => {
  arr = arr.sort(function(a, b) {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  });
};

export const getAssigneeName = (a, g, keyedUsers, keyedGroups) => {
  if (a) {
    if (keyedUsers[a]) {
      return keyedUsers[a];
    } else {
      return a;
    }
  } else if (g) {
    if (keyedGroups[g]) {
      return keyedGroups[g];
    } else {
      return g;
    }
  } else {
    return 'Unassigned';
  }
};
