import React, {useState,useEffect,useRef} from "react";

import "./ThreeSquare.scss";

export default function ThreeSquare(props) {
    return (
        <div className="threeSquareWrapper">
            <div className="threeSquare threeSquare-1"></div>
            <div className="threeSquare threeSquare-2"></div>
            <div className="threeSquare threeSquare-3"></div>
        </div>
    );
}
