import React, { useState, useEffect } from "react";
import { useTheme } from "@material-ui/core/styles";
import { useFormContext } from "react-hook-form";
// import CropOriginalIcon from "@material-ui/icons/CropOriginal";
// import { Variables } from "../Variables";
// import { Emojis } from "../Emojis";
import { FieldExtras } from "../FieldExtras";
// import SentimentSatisfiedOutlinedIcon from "@material-ui/icons/SentimentSatisfiedOutlined";

import "../forms.css";

// import { ReactComponent as VariableSelect } from "pages/Flows/FlowDiagram/tempIcons/varSelect.svg";
import { ErrorLabel } from "stories";

export default function Input(props) {
  const theme = useTheme();
  const { register, errors, setError, clearError } = useFormContext();
  const [value, setValue] = useState("");
  const [charNotSupported, setCharNotSupported] = useState(null);

  // const [regex, setRegex] = useState(null);

  const [textImage, setTextImage] = useState(props.image);

  // const [showPreview, setShowPreview] = useState(false);
  // const [showEmojis, setShowEmojis] = useState(false);
  // const [showVariables, setShowVariables] = useState(false);

  const [currentImagePicker, setCurrentImagePicker] = useState(null);
  const [currentTextField, setCurrentTextField] = useState(null);

  // const [imageError, setImageError] = useState(null);
  // const [isUpload, setIsUpload] = useState(false);

  useEffect(() => {
    if (props.stateValue) {
      setValue(props.stateValue);
    } else if (props.isDuplicate && props.stateValue === null){
      setValue("");
    }
  }, [props.stateValue]);

  useEffect(() => {
    if (props.value) {
      setValue(props.value);
    }
  }, [props.value]);

  useEffect(() => {
    if (props.setError) {
      setError(
        props.name,
        "maxOptions",
        props.errorMessage
          ? props.errorMessage
          : "There was an error with this field"
      );
    } else {
      clearError(props.name);
    }
  }, [props.setError, clearError, props.errorMessage, props.name, setError]);

  // const openImagePicker = () => {
  //   if (textImage) {
  //     setTextImage("");
  //   } else {
  //     currentImagePicker.click();
  //   }
  // };

  const onChange = (e) => {
    const { value } = e.target;

    if (
      props.type === "number" &&
      props.min !== null &&
      props.min !== undefined
    ) {
      if (!value || parseInt(value) >= parseInt(props.min)) {
        setValue(value);
        clearError(props.name);
      } else {
        setError(props.name, "maxOptions", "No Negatives");
      }
    } else {
      setValue(value);
      if (
        props.cannotEqualList &&
        props.cannotEqualList
          .map((el) => el.toLowerCase())
          .includes(value.toLowerCase())
      ) {
        setError(
          props.name,
          "maxOptions",
          props.errorMessage
            ? props.errorMessage
            : "There was an error with this field"
        );
      } else {
        props.cannotEqualList && clearError(props.name);
      }
    }
  };

  let runningClasses = "statusBase ";
  if (props.showRunningStatus) {
    runningClasses += "q" + props.validStatus.toLowerCase();
  }

  const {
    form,
    cannotEqualList,
    errorMessage,
    disabled,
    required,
    name,
    ...validProps
  } = props;

  return (
    <div
      {...validProps}
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <input
          {...validProps}
          ref={(e) => {
            setCurrentTextField(e);
            if (props.setTextFieldRef) props.setTextFieldRef(e);
            if (props.required) register(e);
          }}
          className={"input" + (disabled === "disabled" ? " disabled" : "")}
          title={props.title || ""}
          value={value}
          onChange={onChange}
          disabled={disabled}
          placeholder={props.placeholder ? props.placeholder : null}
          autoComplete="new-password"
          autocomplete="off"
          style={{
            ...theme.typography.body1,
            display: "flex",
            flex: 1,
            color: theme.palette.general.darkGrey,
            border: `1px solid ${theme.palette.general.deepGrey}`,
            padding: "0.5rem 1rem",
            ...props.style,
          }}
        />

        {errors[props.name] && errors[props.name].type === "required" ? (
          <div
            style={{
              display: "flex",
              // flex: 1,
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <ErrorLabel message="This field is required" />
          </div>
        ) : null}
      </div>

      {props.customErrorMsg ? (
        <div
          style={{
            display: "flex",
            // flex: 1,
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <ErrorLabel message={props.customErrorMsg} />
        </div>
      ) : null}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          height: "1px",
        }}
      >
        <div style={{ display: "flex", flex: 1 }} />
        <input
          name={props.name}
          ref={props.required ? register({ required: true }) : register}
          value={value}
          readOnly
          autoComplete="off"
          style={{
            opacity: 0,
            height: "1px",
            width: "1px",
            cursor: "default",
          }}
        />
        <div style={{ display: "flex", flex: 1 }} />
      </div>

      {props.allowextras || props.charLimit ? (
        <div
          className="imageHolder"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {props.allowextras ? (
            <FieldExtras
              onChange={props.onChange}
              imageName={props.imageName ? props.imageName : "image"}
              image={textImage}
              textImage={textImage}
              ignoreInsert={props.ignoreInsert}
              setValue={setValue}
              allowextras={props.allowextras}
              value={value}
              currentTextField={currentTextField}
            />
          ) : null}

          {props.charLimit ? (
            <div
              style={{
                width: props.allowextras ? "fit-content" : "100%",
                textAlign: "right",
                color: value.length > props.charLimit - 1 ? "red" : "black",
              }}
            >
              {value.length + "/" + props.charLimit}
            </div>
          ) : null}
        </div>
      ) : null}

      {props.showRunningStatus || charNotSupported ? (
        <div
          style={{ display: "flex", alignItems: "center", height: "1.75rem" }}
        >
          {props.showRunningStatus ? (
            <div className={runningClasses}>{props.validStatusMsg}</div>
          ) : (
            <div className="statusBase qerror">{charNotSupported}</div>
          )}
        </div>
      ) : (
        <div
        // style={{ height: "1.75rem" }} //remove spacing
        />
      )}
    </div>
  );
}
