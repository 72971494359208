import React, { useState, useEffect, useContext } from 'react';

import AuthContext from 'contexts/AuthContext';
import Paper from '@material-ui/core/Paper';
import _, { set } from 'lodash';
import axios from 'axios';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { faSearch, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as SettingsIcon } from 'assets/icons/icon-settings.svg';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Modal, ModalContent, Input, Select, MiniTable } from 'stories';
import { API, graphqlOperation } from 'aws-amplify';
import {
  updateChatbotDialogueRule,
  createChatbotDialogueRule,
} from 'graphql/mutations';
import { listChatbotDialogueRules } from 'graphql/queries';

import { useModal } from 'hooks';

import './TriageQueue.scss';
import '../Dialogues.scss';

const isPhoneNumber = /^[0-9]{10,}/;

export default function DialogueGroups(props) {
  const tokens = useContext(AuthContext);
  const { isShowing, toggle } = useModal();

  const [modalType, setModalType] = useState('view');
  const [modalInfo, setModalInfo] = useState(null);
  const [groups, setGroups] = useState(null);
  const [folders, setFolders] = useState(null);
  const [rules, setRules] = useState([]);
  const [viewRules, setViewRules] = useState([]);
  const [showFolders, setShowFolders] = useState(null);
  const [users, setUsers] = useState(null);
  const [error, setError] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const [editFolder, setEditFolder] = useState(null);
  const [editGroup, setEditGroup] = useState(null);
  const [rerenderKey, setReRenderKey] = useState(new Date().getTime());

  useEffect(() => {
    getRules();
  }, []);

  useEffect(() => {
    if (props.triggeredAddRule) {
      setModalInfo({
        name:
          props.triggeredAddRule.asurite &&
          props.triggeredAddRule.asurite !== 'NONE'
            ? props.triggeredAddRule.asurite
            : props.triggeredAddRule.phoneNumber,
      });
      setModalType('add');
      toggle();
    }
  }, [props.triggeredAddRule]);

  useEffect(() => {
    if (!isShowing) {
      setModalType('view');
      setModalInfo(null);
    }
  }, [isShowing]);

  useEffect(() => {
    if (props.groups) {
      let g = [];
      let f = {};
      for (var i = 0; i < props.groups.length; ++i) {
        g.push({
          label: props.groups[i].display,
          value: props.groups[i].id,
        });
        f[props.groups[i].id] = props.groups[i].folders;
      }
      setGroups(g);
      setFolders(f);
    }
  }, [props.groups]);

  useEffect(() => {
    if (modalInfo) {
      if (modalInfo.assignedGroup) {
        setSelectedGroup(modalInfo.assignedGroup);
        setShowFolders(folders[modalInfo.assignedGroup]);
        let folderOptns = folders[modalInfo.assignedGroup];
        for (var i = 0; i < groups.length; ++i) {
          if (groups[i].value === modalInfo.assignedGroup) {
            setUsers(
              props.keyedUserGroups[groups[i].label]
                ? props.keyedUserGroups[groups[i].label]
                : []
            );
            setEditGroup(groups[i]);
            if (modalInfo.assignedFolder) {
              let f = getFolder(modalInfo.assignedFolder, folderOptns);
              setEditFolder(f);
            }
          }
        }
      }
    }
  }, [modalInfo]);

  useEffect(() => {
    if (rules) {
      formatRules(rules);
    }
  }, [props.keyedUsers, props.keyedUserGroups, props.keyedGroups]);

  const getFolder = (tag, folderOptns) => {
    for (var f = 0; f < folderOptns.length; ++f) {
      if (folderOptns[f].tagValue === tag) {
        return {
          label: folderOptns[f].name,
          value: folderOptns[f].tagValue,
        };
      }
    }

    return {
      label: 'FOLDER NOT FOUND',
      value: 'UNKNOWN',
    };
  };

  const getHTMLStr = r => {
    return (
      <div className="miniTable-ruleItem">
        <div>
          <font>IF</font> user: <font>{r.displayName}</font>, contains words:{' '}
          <font>{r.keywords.length > 0 ? r.keywords.toString() : 'ANY'}</font>
        </div>
        <div>
          <font>THEN</font> assign to:{' '}
          <font>{props.keyedGroups[r.assignedGroup]}</font>
          {r.assignedFolder && (
            <>
              , add to:{' '}
              <font>
                {folders && folders[r.assignedGroup]
                  ? getFolder(r.assignedFolder, folders[r.assignedGroup]).label
                  : r.assignedFolder}
              </font>
            </>
          )}
          {r.assignedUser && (
            <>
              , forward to: <font>{props.keyedUsers[r.assignedUser]}</font>
            </>
          )}
        </div>
      </div>
    );
  };

  const confirmUser = async asurite => {
    let resp = await axios
      .get(
        process.env.REACT_APP_APIGATEWAY_CHATBOT +
        '/isearch?asurite=' +
          asurite.toLowerCase()
      )
      .then(async function respond(resp) {
        if (resp.data && resp.data.displayName) {
          return {
            displayName: resp.data.displayName,
          };
        }
      })
      .catch(function(error) {
        console.log('FALED: ', error);
        return {
          error: error,
        };
      });

    return resp;
  };

  const formatRules = r => {
    let vR = [];
    for (var i = 0; i < r.length; ++i) {
      let htmlStr = getHTMLStr(r[i]);
      vR.push({
        rule: htmlStr,
      });
    }
    setViewRules(vR);
    setRules(r);
  };

  const getRules = async () => {
    let resp;
    try {
      resp = await API.graphql(
        graphqlOperation(listChatbotDialogueRules, {
          filter: createFilterQuery(),
        })
      );
    } catch (e) {
      console.log(e);
    }
    let r = resp.data.listChatbotDialogueRules.items;
    formatRules(r);
  };

  const createFilterQuery = () => {
    let groupCheck = [];
    let filterQuery = {
      deleted: { eq: false },
    };
    if (tokens.role === 'approver' || tokens.role === 'content creator') {
      filterQuery.or = [];
      let grps = props.presentUserGroups;
      for (var i = 0; i < grps.length; ++i) {
        filterQuery.or.push({
          or: [{ assignedGroup: { eq: grps[i] } }],
        });
      }
    }

    return filterQuery;
  };

  const groupChange = g => {
    setSelectedGroup(g.value);

    let optns = folders[g.value];
    let f = [];

    for (var i = 0; i < optns.length; ++i) {
      f.push({
        label: optns[i].name,
        value: optns[i].tagValue,
      });
    }
    setShowFolders(f);
    setUsers(
      props.keyedUserGroups[g.label] ? props.keyedUserGroups[g.label] : []
    );
  };

  const getUserName = user => {
    if (props.keyedUsers && user)
      return { label: props.keyedUsers[user], value: user };
    else return user;
  };

  const editHandler = e => {
    setModalInfo(e);
    setModalType('edit');
  };

  const deleteHandler = e => {
    setModalInfo(e);
    setModalType('delete');
  };

  const getModalContent = () => {
    if (modalType === 'add' || modalType === 'edit') {
      let formData = [
        {
          component: (
            <div>
              <font style={{ fontWeight: 'bold' }}>IF</font> the incoming
              message matches:{' '}
            </div>
          ),
        },
        {
          title: 'ASURITE OR PHONE NUMBER',
          required: true,
          component: (
            <Input
              name="name"
              customErrorMsg={error}
              value={modalInfo && modalInfo.name ? modalInfo.name : null}
              form="true"
              required
              placeholder="jjdoe"
            />
          ),
        },
        {
          title: 'CONTAINS WORDS',
          subtitle: 'separate multiple words with commas',
          required: false,
          component: (
            <Input
              name="words"
              value={
                modalInfo && modalInfo.keywords
                  ? modalInfo.keywords.toString()
                  : null
              }
              form="true"
              required={false}
              placeholder="Hello, Hi, Good Morning"
            />
          ),
        },
        {
          component: (
            <div
              style={{ height: '1px', background: '#d2d2d2', width: '100%' }}
            ></div>
          ),
        },
        {
          component: (
            <div>
              <font style={{ fontWeight: 'bold' }}>THEN</font> do the following:{' '}
            </div>
          ),
        },
        {
          title: 'ASSIGN TO GROUP',
          required: true,
          component: (
            <Select
              form
              required
              name="group"
              placeholder="-- Select a Group --"
              options={groups}
              onChange={groupChange}
              menuPlacement="top"
              updateStateValue={editGroup ? editGroup : null}
            />
          ),
        },
      ];

      if (selectedGroup) {
        formData.push({
          title: 'MOVE CONVERSATION TO FOLDER',
          required: false,
          component: (
            <Select
              form
              name="folder"
              placeholder="-- Select a Folder --"
              options={showFolders}
              menuPlacement="top"
              updateStateValue={editFolder ? editFolder : null}
            />
          ),
        });
        formData.push({
          title: 'ASSIGN TO USER',
          required: false,
          component: (
            <Select
              form
              name="user"
              placeholder="-- Select a User --"
              options={users}
              menuPlacement="top"
              updateStateValue={
                modalInfo && modalInfo.assignedUser
                  ? getUserName(modalInfo.assignedUser)
                  : null
              }
            />
          ),
        });
      }

      return (
        <ModalContent
          form={true}
          formData={formData}
          title={`${
            modalInfo && modalInfo.type === 'edit' ? 'EDIT' : 'ADD NEW'
          } RULE`}
          onSubmit={addRule}
          toggle={toggle}
        />
      );
    } else if (modalType === 'view') {
      return (
        <ModalContent
          title={'RULES'}
          subtext={'Apply the following rules to incoming messages'}
          onSubmit={() => setModalType('add')}
          buttonLabel={'ADD NEW RULE'}
          toggle={toggle}
        >
          <div
            style={{
              margin: '1rem 0px',
            }}
          >
            <MiniTable
              tableData={viewRules}
              rerenderKey={rerenderKey}
              columnTitles={[]}
              dataKeys={['rule']}
              rawData={rules}
              onEditHandler={editHandler}
              onDeleteHandler={deleteHandler}
            />
          </div>
        </ModalContent>
      );
    } else if (modalType === 'delete') {
      return (
        <ModalContent
          confirmation
          title={`Are you sure you want to delete the rule for ${modalInfo.displayName}`}
          onSubmit={deleteRule}
          toggle={toggle}
        />
      );
    }
  };

  const deleteRule = async data => {
    let deleteData = {
      id: modalInfo.id,
      deleted: true,
      deletedBy: {
        asurite: tokens.asurite,
        timestamp: new Date().getTime() + '',
      },
    };
    let resp = await API.graphql(
      graphqlOperation(updateChatbotDialogueRule, { input: deleteData })
    );

    let rCp = _.clone(rules);
    for (var i = rCp.length - 1; i >= 0; --i) {
      if (rCp[i].id === modalInfo.id) {
        rCp.splice(i, 1);
      }
    }
    formatRules(rCp);

    toggle();
  };

  const parseValue = obj => {
    try {
      return JSON.parse(obj).value;
    } catch (e) {
      return obj;
    }
  };

  const updateRules = s => {
    let ruleCp = _.clone(rules);
    for (var i = 0; i < ruleCp.length; ++i) {
      if (ruleCp[i].id === s.id) {
        ruleCp[i] = {
          ...ruleCp[i],
          ...s,
        };
      }
    }
    formatRules(ruleCp);
  };

  const addRule = async data => {
    setError(null);
    if (data.name) {
      let g = parseValue(data.group);
      let f = parseValue(data.folder);
      let u = parseValue(data.user);

      let words = data.words.split(',');

      for (var i = words.length - 1; i >= 0; --i) {
        words[i] = words[i].trim();
        if (words[i] === '') {
          words.splice(i, 1);
        }
      }

      data.name = data.name.replace(/[^a-zA-Z0-9]/g, '');

      let isValid = true;
      let name = data.name;
      let displayName = name;

      if (isPhoneNumber.test(name)) {
        let addOn = '+';
        if (name.length === 10) {
          addOn = '+1';
        }
        name = addOn + name;
        if (!isValidPhoneNumber(name, 'US')) {
          isValid = false;
        }
      } else {
        let info = await confirmUser(name);
        if (info.error) {
          isValid = false;
        } else {
          displayName = info.displayName;
        }
      }

      if (isValid) {
        let saveData = {
          name: name,
          displayName: displayName,
          keywords: words,
          active: true,
          deleted: false,
          assignedFolder: f,
          assignedGroup: g,
          assignedUser: u,
          botType: 'sunny',
          editedBy: [],
        };

        if (modalInfo && modalInfo.id) {
          saveData.id = modalInfo.id;
          saveData.editedBy = [
            ...modalInfo.editedBy,
            { asurite: tokens.asurite, timestamp: new Date().getTime() + '' },
          ];
          updateRules(saveData);
        } else {
          saveData.submittedBy = tokens.asurite;
          let rcp = _.clone(rules);
          rcp.push(saveData);
          formatRules(rcp);
        }

        let resp = await API.graphql(
          graphqlOperation(
            modalType === 'add'
              ? createChatbotDialogueRule
              : updateChatbotDialogueRule,
            { input: saveData }
          )
        );
        toggle();
      } else {
        setError(
          'Invalid User, please enter either valid phone number or asurite'
        );
      }
    }
  };

  return (
    <div className="grayBottomBorder dg-groupsHolder">
      <div className="dg-dialougesGroups">
        <h3>Dialogues Groups</h3>
        <p>Your groups: {props.groupNum}</p>
        <p>Total triage queue: {props.triageTotal}</p>
      </div>
      <div>
        <SettingsIcon style={{ cursor: 'pointer' }} onClick={toggle} />
      </div>
      <Modal
        isShowing={isShowing}
        style={{ width: '50%' }}
        hide={toggle}
        content={getModalContent()}
      />
    </div>
  );
}
