import React from "react";
import { Input, Textarea, Select } from "stories";

const types = [{
  value: "info",
  label: "Information"
},{
  value: "warning",
  label: "Warning"
},{
  value: "critical",
  label: "Critical"
}]

export function getFormData(type) {

  let formData = [
    {
      title: "TITLE",
      required: true,
      component: (
        <Input
          form
          name="title"
          required
        />
      ),
    },{
      title: "DESCRIPTION",
      required: true,
      component: (
        <Textarea
          form
          name="message"
          required
        />
      ),
    },
  ];

  if( type === "status" ) {
    formData.push({
      title: "SEVERITY",
      required: true,
      component: (
        <Select
          form
          name="severity"
          required
          options={types}
        />
      )
    })
  }
  return formData;
}
