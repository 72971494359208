import React, { useState, useEffect, useContext, useRef } from 'react';

import * as campaignListHelper from '../Helpers/campaignListHelper.js';
import { default as FilterAnchorLink } from './FilterAnchorLink';

import AuthContext from 'contexts/AuthContext';
import moment from 'moment-timezone';

export default function CampaignFilterButton(props) {
  const tokens = useContext(AuthContext);

  const [filterTriageQueueOpen, setFilterTriageQueueOpen] = useState(false);
  const [campaignSelected, setCampaignSelected] = useState('');
  const [campaignListSearch, setCampaignListSearch] = useState('');
  const [campaignList, setCampaignList] = useState([]);
  const [dateInputs, setDateInputs] = useState(['', '']);
  const [closeInput, setCloseInput] = useState('');
  const [boxInput1, setBoxInput1] = useState('');
  const [boxInput2, setBoxInput2] = useState('');

  const inputRef = useRef(null);
  const boxRef1 = useRef(null);
  const boxRef2 = useRef(null);

  useEffect(() => {
    if (filterTriageQueueOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [filterTriageQueueOpen]);

  const filterAnchorLinks = [
    {
      duration: '24',
      unit: 'hours',
      label: 'Last 24 hours',
    },
    {
      duration: '1',
      unit: 'week',
      label: 'Last 7 days',
    },
    {
      duration: '1',
      unit: 'month',
      label: 'Last 30 days',
    },
  ];

  const usDateFormat = 'MM-DD-YYYY';

  const getDateLength = () => {
    let momentDates = [];
    let days = 0;

    if (dateInputs[0] !== '') {
      momentDates.push(moment(dateInputs[0], usDateFormat));
    }

    if (dateInputs[1] !== '') {
      momentDates.push(moment(dateInputs[1], usDateFormat));
    }

    if (momentDates.length > 1) {
      if (momentDates[1].isAfter(moment().endOf('day'))) {
        days = moment().diff(momentDates[0], 'days');
      } else {
        days = momentDates[1].diff(momentDates[0], 'days');
      }

      if (days > 0) {
        return getMatchingDate(usDateFormat, days);
      } else {
        return '';
      }
    } else {
      return '';
    }
  };

  const getMatchingDate = (usDateFormat, days) => {
    let returnValue = `Date: Last ${days} day${days !== 1 ? 's' : ''}`;

    if (!moment(dateInputs[1]).isSame(moment(), "day")) {
      return "Date: Custom Range"
    }

    let labels = [
      'Date: Last 30 days',
      'Date: Last 7 days',
      'Date: Last 24 hours',
    ];

    let conditions_0 = [
      moment()
        .subtract('1', 'month')
        .format(usDateFormat),
      moment()
        .subtract('1', 'week')
        .format(usDateFormat),
      moment()
        .subtract('24', 'hours')
        .format(usDateFormat),
    ];

    let condition_1 = dateInputs[1] === moment().format(usDateFormat);

    if (condition_1 === true) {
      for (let i = 0; i < conditions_0.length; i++) {
        if (
          conditions_0[i] === dateInputs[0] &&
          !moment(dateInputs[0]).isAfter(moment().endOf('day'))
        ) {
          returnValue = labels[i];
        }
      }
    }

    return returnValue;
  };

  const closeButton = () => {
    setFilterTriageQueueOpen(false);
    setCampaignListSearch('');
    setCloseInput('');
    let dates = ['', ''];
    setDateInputs(dates);
    boxRef1.current.value = dates[0];
    boxRef2.current.value = dates[1];
    setBoxInput1(dates[0]);
    setBoxInput2(dates[1]);
  };

  const filterButton = () => {
    setCampaignListSearch('');
    setCloseInput('');
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const filterCloseButton = () => {
    let dates = ['', ''];
    setDateInputs(dates);
    boxRef1.current.value = dates[0];
    boxRef2.current.value = dates[1];
    setBoxInput1(dates[0]);
    setBoxInput2(dates[1]);
  };

  const clearInput = () => {
    setCampaignListSearch('');
    setCloseInput('');
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const applyButton = () => {
    setCampaignListSearch(closeInput);
    setCampaignList(Array.from(campaignList));
    setDateInputs([boxInput1, boxInput2]);
  };

  const applyFromEnter = e => {
    if (e.key === 'Enter') {
      applyButton();
    }
  };

  const selectListItem = async event => {
    let campaignId = event.currentTarget.dataset.id;
    let campaignTitle = event.currentTarget.dataset.title;

    //campaign variable comes from render context
    props.setCampaignSwitch({ id: campaignId, title: campaignTitle });
    setFilterTriageQueueOpen(false);

    console.log(campaignId);
    setCampaignSelected(campaignId);

    //clear dialogues data
    props.setAllConvos({
      inbox: { page: 0, total: 0, data: [] },
      review: { page: 0, total: 0, data: [] },
      assisted: { page: 0, total: 0, data: [] },
      all: { page: 0, total: 0, data: [] },
      sr: { page: 0, total: 0, data: [] },
    });

    props.loadCampaign({ id: campaignId });
  };

  const clearCampaignFilter = async () => {
    if (props.isLoading) {
      return;
    }

    props.clearSearch();

    props.setCampaignSwitch(null);
    setCampaignSelected(null);
    setCampaignListSearch('');

    //clear dialogues data
    props.setAllConvos({
      inbox: { page: 0, total: 0, data: [] },
      review: { page: 0, total: 0, data: [] },
      assisted: { page: 0, total: 0, data: [] },
      all: { page: 0, total: 0, data: [] },
      sr: { page: 0, total: 0, data: [] },
    });

    props.fetchConversations();
    props.setSelectedConvo('');
  };

  const openFilterPanel = async () => {
    if (props.isLoading) {
      return;
    }

    setFilterTriageQueueOpen(true);
    let campaignList = await campaignListHelper.fetchCampaignList(tokens);
    if (props.campaignBridgeDetected) {
      campaignList.unshift(
        {
          title: 'Prod Campaign (14k)',
          startTime: 0,
          endTime: moment().format,
          description: 'Retention Sunny Major Check-in',
          id: '10100cb6-cf5e-ec87-4407-e4a5d404274a',
        },
        {
          title: 'Prod Campaign (2.5k)',
          startTime: 0,
          endTime: moment().format,
          description:
            'Retention Sunny Coordinate Care Check-in Campaign 11-2-22',
          id: 'b495bb93-80bd-4acf-d09f-eba4f94c12d9',
        },
        {
          title: 'Prod Campaign (38k)',
          startTime: 0,
          endTime: moment().format,
          description: 'ECOMM 58768 23-24 Sunny FAFSA is open text',
          id: '8e293ebb-9178-c469-5c25-1c8c16e9afab',
        },
        {
          title: 'Prod Campaign (18k)',
          startTime: 0,
          endTime: moment().format,
          description: 'ECOMM 56704 23-24 SUNNY FAFSA TEXT',
          id: '99013da7-d3e4-a44a-9a1b-6b73b32662f0',
        },
        {
          title: 'Prod Campaign (28k)',
          startTime: 0,
          endTime: moment().format,
          description: 'ECOMM 54672 College Signing Day submit photo text',
          id: 'd20c1b99-a2e6-1aae-d3e0-dbe6f600c52f',
        },
        {
          title: 'Prod Campaign (39k)',
          startTime: 0,
          endTime: moment().format,
          description: 'ECOMM 58372 FAFSA reminder text 1',
          id: 'df5ee942-8784-4b0b-1ef0-350bdcae1d2e',
        }
      );
    }

    setCampaignList(campaignList);
  };

  const commitTextValue = e => {
    setCloseInput(e.target.value);
  };

  const commitDateValue1 = e => {
    setBoxInput1(e.target.value);
  };

  const commitDateValue2 = e => {
    setBoxInput2(e.target.value);
  };

  const renderCampaignList = () => {
    let newCampaignList = campaignList.filter(campaign => {
      let textPass = true;
      let datePass = true;
      if (
        campaignListSearch === '' &&
        dateInputs[0] === '' &&
        dateInputs[1] === ''
      ) {
        return true;
      } else {
        if (campaignListSearch !== '') {
          //check text
          if (
            campaign.title
              .toLowerCase()
              .includes(campaignListSearch.toLowerCase())
          ) {
            //pass
          } else {
            textPass = false;
          }
        }

        if (dateInputs[0] !== '' && dateInputs[1] !== '') {
          //check dates
          if (
            moment(dateInputs[0], usDateFormat).format('x') <=
              campaign.startTime &&
            moment(dateInputs[1], usDateFormat)
              .endOf('day')
              .format('x') >= campaign.endTime
          ) {
            //pass
          } else {
            datePass = false;
          }
        }

        if (textPass && datePass) {
          return true;
        } else {
          return false;
        }
      }
    });

    if (newCampaignList.length !== 0) {
      newCampaignList = newCampaignList.map(campaign => {
        return (
          <div
            className="filter-triage-queue-panel__list-item"
            data-selected={campaign.id === campaignSelected ? 'true' : 'false'}
            data-id={campaign.id}
            data-title={campaign.title}
            onClick={selectListItem}
            key={campaign.id.match(/-[0-9a-z]+[^-]+$/)[0].replace('-', '')}
          >
            <div className="filter-triage-queue-panel__list-item-title">
              {campaign.title}
            </div>
            <div className="filter-triage-queue-panel__list-item-desc">
              {campaign.description}
            </div>
          </div>
        );
      });

      return newCampaignList;
    } else {
      return null;
    }
  };

  const renderFilterButtons = () => {
    return filterAnchorLinks.map(values => {
      return (
        <FilterAnchorLink
          duration={values.duration}
          unit={values.unit}
          label={values.label}
          setDateInputs={setDateInputs}
          setBoxInput1={setBoxInput1}
          setBoxInput2={setBoxInput2}
          boxRef1={boxRef1}
          boxRef2={boxRef2}
          key={values.unit}
        />
      );
    });
  };

  const renderCampaignFilterButton = () => {
    return (
      <div
        className={
          props.isLoading
            ? 'filter-triage-queue-button filter-triage-queue-button--gray'
            : 'filter-triage-queue-button'
        }
        onClick={props.campaignSwitch ? clearCampaignFilter : openFilterPanel}
      >
        {props.campaignSwitch ? 'Clear Campaign Filter' : 'Filter triage queue'}
      </div>
    );
  };

  const renderCampaignFilterPanel = () => {
    return (
      <div className="filter-triage-queue-panel">
        <div
          className="filter-triage-queue-panel__close-button"
          onClick={closeButton}
        ></div>
        <div className="filter-triage-queue-panel__row" data-align="left">
          <div className="filter-triage-queue-panel__text">Filter by:</div>
          <div
            className="filter-triage-queue-panel__gray-button"
            data-search={campaignListSearch}
            onClick={filterButton}
          >
            {campaignListSearch}
            <div className="filter-triage-queue-panel__gray-close-button">
              X
            </div>
          </div>
          <div
            className="filter-triage-queue-panel__gray-button"
            data-search={getDateLength()}
          >
            {getDateLength()}
            <div
              className="filter-triage-queue-panel__gray-close-button"
              onClick={filterCloseButton}
            >
              X
            </div>
          </div>
        </div>
        <div className="filter-triage-queue-panel__row">
          <input
            type="text"
            className="filter-triage-queue-panel__input"
            placeholder="Search campaigns..."
            onChange={commitTextValue}
            onKeyDown={applyFromEnter}
            ref={inputRef}
          />
          <div
            className="filter-triage-queue-panel__input-close-button"
            data-state={closeInput}
            onClick={clearInput}
          >
            X
          </div>
        </div>
        <div className="filter-triage-queue-panel__row">
          <div className="filter-triage-queue-panel__bold-text">Date from</div>
          <div className="filter-triage-queue-panel__bold-text">Date to</div>
        </div>
        <div className="filter-triage-queue-panel__row">
          <div className="filter-triage-queue-panel__bold-text">
            <input
              type="text"
              className="filter-triage-queue-panel__input filter-triage-queue-panel__input--date"
              placeholder="Date from"
              onChange={commitDateValue1}
              ref={boxRef1}
            />
          </div>
          <div className="filter-triage-queue-panel__bold-text">
            <input
              type="text"
              className="filter-triage-queue-panel__input filter-triage-queue-panel__input--date"
              placeholder="Date to"
              onChange={commitDateValue2}
              ref={boxRef2}
            />
          </div>
        </div>
        <div className="filter-triage-queue-panel__row" data-align="space">
          {renderFilterButtons()}
        </div>
        <div className="filter-triage-queue-panel__row">
          <button
            className="filter-triage-queue-panel__apply"
            onClick={applyButton}
          >
            Apply
          </button>
        </div>
        <div className="filter-triage-queue-panel__campaignList">
          {renderCampaignList()}
        </div>
      </div>
    );
  };

  return (
    <div className="filter-triage-queue-wrapper">
      {filterTriageQueueOpen === false
        ? renderCampaignFilterButton()
        : renderCampaignFilterPanel()}
    </div>
  );
}
