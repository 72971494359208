import React, { useState, useEffect, useContext } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useFormContext } from 'react-hook-form';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import SentimentSatisfiedOutlinedIcon from '@material-ui/icons/SentimentSatisfiedOutlined';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { ReactComponent as VariableSelect } from 'pages/Flows/FlowDiagram/tempIcons/varSelect.svg';
import { Variables } from '../Variables';
import { Emojis } from '../Emojis';
import { S3Image } from 'aws-amplify-react';
import { FieldExtras } from '../FieldExtras';
import { ErrorLabel, Select } from 'stories';

import { store as GlobalStore } from 'contexts/GlobalStore';

// import TextField from "@material-ui/core/TextField";

import '../forms.css';

var charTimeout = null;

export default function TextareaForm(props) {
  const theme = useTheme();
  const { register, errors, clearError } = useFormContext();
  const [value, setValue] = useState(props.stateValue ? props.stateValue : '');

  const [regex, setRegex] = useState(null);
  const [charNotSupported, setCharNotSupported] = useState(null);

  const [textImage, setTextImage] = useState(props.image);

  const [showPreview, setShowPreview] = useState(false);
  const [showEmojis, setShowEmojis] = useState(false);
  const [showVariables, setShowVariables] = useState(false);

  const [currentImagePicker, setCurrentImagePicker] = useState(null);
  const [currentTextField, setCurrentTextField] = useState(null);

  const [imageError, setImageError] = useState(null);
  const [isUpload, setIsUpload] = useState(false);

  const GlobalState = useContext(GlobalStore);
  const dispatchGlobal = GlobalState.dispatch;

  const { includeFlow } = GlobalState.state;

  // useEffect(() => {
  //   clearError();
  // }, []);

  useEffect(() => {
    if (props.regex) {
      setRegex(new RegExp(props.regex));
    }
  }, [props.regex]);

  useEffect(() => {
    if (props.value) {
      setValue(props.value);
    }
  }, [props.value]);

  useEffect(() => {
    if (props.clearValue) {
      setValue('');
    }
  }, [props.clearValue]);

  useEffect(() => {
    setTextImage(props.image);
  }, [props.image]);

  useEffect(() => {
    if (props.validStatus === 'error') {
      setValue('');
    }
  }, [props.validStatus]);

  const onChange = e => {
    clearError(props.name);
    let val = e.target.value; //.replace(/[{}]/g, "");

    if (val === '') {
      if (props.onChange) {
        props.onChange(val);
      }
      return setValue(val);
    }

    // else if (regex && !regex.test(val)) {
    //   let unChar = val[val.length - 1];
    //   let message = "Unsupported character: " + unChar;
    //
    //   if (!isNaN(parseInt(unChar))) {
    //     message = "Numbers must be written out";
    //   }
    //
    //   setCharNotSupported(message);
    //   clearTimeout(charTimeout);
    //   charTimeout = setTimeout(() => {
    //     setCharNotSupported(null);
    //   }, 4000);
    //
    //   val = value;
    // }

    setValue(val);

    if (props.onChange) {
      props.onChange(val);
    }
  };

  let runningClasses = 'statusBase ';
  if (props.showRunningStatus) {
    runningClasses += 'q' + props.validStatus.toLowerCase();
  }

  const getValue = () => {
    if (value) {
      return value;
    } else {
      return '';
    }
  };

  const validInputProps = () => {
    const {
      id,
      form,
      showRunningStatus,
      validStatusMsg,
      validStatus,
      stateValue,
      ...validInputProps
    } = props;
    return { ...validInputProps };
  };

  const validTextareaProps = () => {
    const {
      form,
      showRunningStatus,
      validStatusMsg,
      validStatus,
      stateValue,
      required,
      ...validTextareaProps
    } = props;
    return { ...validTextareaProps };
  };

  const openImagePicker = () => {
    if (textImage) {
      setTextImage('');
    } else {
      currentImagePicker.click();
    }
  };

  const handlePreloadMessage = e => {
    if (props.acceptPreload) {
      props.acceptPreload(e);
    }
  };

  const showWordLimit = wordLimit => {
    if (wordLimit) {
      return (
        <div
          style={{
            width: '100%',
            textAlign: 'right',
            color:
              value.trim().split(/\s+/).length > props.wordLimit - 1
                ? 'red'
                : 'black',
          }}
        >
          {value === "" ? "0/100" : value.trim().split(/\s+/).length + '/' + props.wordLimit} words
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div>
      {props.preloadOptions && props.preloadOptions.length > 0 && (
        <div style={{ display: 'flex', padding: '0.5rem 0rem' }}>
          <Select
            name="preload_msgs"
            options={props.preloadOptions}
            onChange={handlePreloadMessage}
            tall={true}
          />
        </div>
      )}
      {props.includeFlow && (
        <div style={{ fontSize: '0.875rem' }}>
          <input
            ref={register({ required: false })}
            type="radio"
            id="messageRadio"
            name="includeFlow"
            value=""
            defaultChecked
            onClick={() => {
              dispatchGlobal({
                type: 'setIncludeFlow',
                includeFlow: includeFlow === '' ? 'yes' : '',
              });
            }}
          />{' '}
          <label
            style={{
              textTransform: 'uppercase',
              fontWeight: 'bold',
              display: 'inline-block',
              marginBottom: '1em',
              cursor: 'pointer',
            }}
            for="messageRadio"
          >
            Send Message
          </label>
        </div>
      )}
      {showWordLimit(props.wordLimit)}
      <div style={{ display: 'flex' }}>
        <TextareaAutosize
          {...validTextareaProps()}
          ref={e => {
            setCurrentTextField(e);
            // if (props.form) register(e);
          }}
          className={
            'textarea' + (props.disabled === 'disabled' ? ' disabled' : '')
          }
          disabled={
            props.disabled || (props.includeFlow && includeFlow === 'yes')
          }
          value={value}
          onChange={onChange}
          rows={props.rows ? props.rows : 4}
          autocomplete="new-password"
          style={{
            ...theme.typography.body1,
            flex: 1,
            color: theme.palette.general.darkGrey,
            border: `1px solid ${theme.palette.general.deepGrey}`,
            padding: '0.5rem 1rem',
            resize: 'none',
            ...props.style,
          }}
        />
        <div
          style={{ display: 'flex', justifyContent: 'center', display: 'none' }}
        >
          <div style={{ display: 'flex', flex: 1 }} />
          <input
            {...validInputProps()}
            type="select"
            ref={
              props.form
                ? props.required
                  ? register({ required: true })
                  : register
                : null
            }
            value={getValue()}
            readOnly
            autoComplete="off"
            style={{
              display: 'flex',
              flex: 1,
              opacity: 0,
              height: '1px',
              width: '1px',
              cursor: 'default',
            }}
          />
          <div style={{ display: 'flex', flex: 1 }} />
        </div>
      </div>

      {errors[props.name] && errors[props.name].type === 'required' ? (
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <ErrorLabel message={'this field is required'} />
        </div>
      ) : null}

      {props.allowextras || props.charLimit ? (
        <div
          className="imageHolder"
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          {props.allowextras ? (
            <FieldExtras
              propertyID={props.propertyID}
              onChange={props.onChange}
              imageName={props.imageName ? props.imageName : 'image'}
              image={textImage}
              textImage={textImage}
              setValue={setValue}
              allowextras={props.allowextras}
              value={value}
              currentTextField={currentTextField}
            />
          ) : null}

          {props.charLimit ? (
            <div
              style={{
                width: props.allowextras ? 'fit-content' : '100%',
                textAlign: 'right',
                color: value.length > props.charLimit - 1 ? 'red' : 'black',
              }}
            >
              {value.length + '/' + props.charLimit}{' '}
              {value.length > props.charLimit &&
                props.isSMS &&
                `- ${Math.floor(value.length / props.charLimit) + 1} texts`}
            </div>
          ) : null}
        </div>
      ) : null}

      {props.showRunningStatus || charNotSupported ? (
        <div
          style={{ display: 'flex', alignItems: 'center', height: '1.75rem' }}
        >
          {props.showRunningStatus ? (
            <div className={runningClasses}>{props.validStatusMsg}</div>
          ) : (
            <div className="statusBase qerror">{charNotSupported}</div>
          )}
        </div>
      ) : (
        <div
        // style={{ height: "1.75rem" }} //removed spacing
        />
      )}

      {props.includeFlow && (
        <div style={{ fontSize: '0.875rem', marginTop: '0.5em' }}>
          <input
            ref={register({ required: false })}
            type="radio"
            name="includeFlow"
            id="flowRadio"
            value="yes"
            onClick={() => {
              dispatchGlobal({
                type: 'setIncludeFlow',
                includeFlow: includeFlow === '' ? 'yes' : '',
              });
            }}
          />{' '}
          <label
            style={{
              textTransform: 'uppercase',
              fontWeight: 'bold',
              cursor: 'pointer',
            }}
            for="flowRadio"
          >
            Send Flow
          </label>
        </div>
      )}
    </div>
  );
}
