const AuthService = {
  isAuthenticated: function() {
    const checkStorage = JSON.parse(localStorage.getItem("usertokens"));
    const expirationTime =
      typeof checkStorage === "undefined" || checkStorage === null
        ? new Date(0)
        : new Date(checkStorage.Expiration);
    return expirationTime.getTime() > Date.now();
  },
  refreshTokens: function() {
    //const fullData = JSON.parse(localStorage.getItem("usertokens"));
    // const payload = {
    //   username: fullData.username,
    //   token: fullData.Refresh,
    //   role: fullData.role,
    //   userId: fullData.userId
    // };
  },
  create: function(tokenStr) {
    localStorage.setItem("authed", "true");
    localStorage.setItem("usertokens", JSON.stringify(tokenStr));
  },
  destroy: function() {
    localStorage.setItem("authed", "false");
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = process.env.REACT_APP_ASU_WEBAPP + "/myasu/Signout";
  },
};
export default AuthService;
