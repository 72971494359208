import React from 'react';
// import PropTypes from "prop-types";
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

export default function StyledButton(props) {
  const useStyles = makeStyles(theme => ({
    root: {
      root: {
        display: 'flex',
        // "& > * + *": {
        //   marginLeft: theme.spacing(2)
        // }
      },
    },
    button: {
      ...theme.typography.button,
      fontWeight:
        (props.delete || props.save || props.test) &&
        theme.typography.fontWeightBold,
      width: props.text === 'SEND NOW' ? '130px' : '120px',
      background: background(theme),
      color: color(),
      borderStyle: borderStyle(),
      borderWidth: borderWidth(),
      borderColor: props.delete && theme.palette.general.deepGrey,
      '&:hover': {
        background: hoverBackground(theme),
      },
      ...props.style,
    },
    loadingSpinner: {
      ...theme.typography.button,
      color: props.spinnerColor ? props.spinnerColor : 'white',
      marginLeft: '0.8rem',
    },
  }));

  const background = theme => {
    if (props.save || props.submit || props.test) {
      return theme.palette.general.mediumBlue;
    } else if (props.delete) {
      return theme.palette.general.lightGrey;
    }
  };
  const color = () => {
    if (props.save || props.submit || props.test) {
      return 'white';
    } else if (props.delete) {
      return 'black';
    }
  };
  const borderStyle = () => {
    if (props.save || props.submit || props.test) {
      return 'none';
    } else if (props.delete) {
      return 'solid';
    }
  };
  const borderWidth = () => {
    if (props.save || props.submit || props.test) {
      return '0px';
    } else if (props.delete) {
      return '1px';
    }
  };
  const hoverBackground = theme => {
    if (props.save || props.submit || props.test) {
      return theme.palette.general.lightBlue;
    } else if (props.delete) {
      return theme.palette.general.mediumGrey;
    } else if (props.export) {
      return theme.palette.general.deepGrey;
    } else if (props.basic) {
      return 'black';
    }
  };

  const classes = useStyles();

  let label = '';
  if (props.text) {
    label = props.text;
  } else if (props.save) {
    label = props.text || 'SAVE';
  } else if (props.submit) {
    label = props.text || 'SUBMIT';
  } else if (props.delete) {
    label = props.text || 'DELETE';
  } else if (props.label) {
    label = props.label;
  }

  return (
    <Button {...props} className={classes.button}>
      {label}
      {props.children}
      {props.loading > 0 && (
        <div
          className={classes.root}
          style={{ marginLeft: '0.8rem', ...props.spinnerContainerStyle }}
        >
          <CircularProgress
            color={props.spinnerColor ? props.spinnerColor : 'inherit'}
            size={props.spinnerSize ? props.spinnerSize : '0.8rem'}
          />
        </div>
      )}
    </Button>
  );
}

// StyledButton.propTypes = {
//   save: PropTypes.bool
// };
