import React, { useState, useContext, useEffect } from "react";
import Select from "react-select";

// import { store } from "contexts/KBStore";
import "../forms.css";

export default function SelectX(props) {
  // const KBState = useContext(store);
  // const { dispatch } = KBState;

  const { updateEscalationId, setData } = props;

  const [menuOpen, setMenuOpen] = useState(false);
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (updateEscalationId && props.options.length > 0) {
      let initialEscalation = getEditEscalationOption(updateEscalationId, [...props.options]);

      if(initialEscalation.value) {
        setData((prevData) => ({
          ...prevData,
          answerEscalation: initialEscalation.value,
        }));
      }
      setValue(initialEscalation);
    }
  }, [props.options]);

  const getEditEscalationOption = (id, pOptions) => {
    let returnValue;
    for (var i = 0; i < pOptions.length; ++i) {
      console.log("props.options is", pOptions);
      if (pOptions[i].value === id) {
        returnValue = pOptions[i];
      }
    }
    return returnValue;
  };

  const customStyles = {
    // to access width/height, add either as a prop to the component
    control: (_, { selectProps: { width, height } }) => ({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "0.5rem",
      backgroundColor: "white",
      border: "1px solid #8d8d8d",
      borderRadius: controlBR(),
      cursor: "pointer",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: props.tall ? "40px" : "unset",
    }),
    menu: (provided, state) => ({
      ...provided,
      width: "100%",
      margin: "0px",
      borderTopLeftRadius: "0px",
      borderTopRightRadius: "0px",
      border: "1px solid #8d8d8d",
      borderTop: "0px",
      zIndex: 10000,
    }),
    option: (provided, state) => ({
      ...provided,
      cursor: "pointer",
      backgroundColor: optionBG(state),
      color: "#3B4252",
      "&:active": {
        backgroundColor: "#d5d5d5",
      },
    }),
    dropdownIndicator: () => {
      if (props.noDropdownIndicator) {
        return {
          display: "none",
        };
      } else {
        return {
          color: "#8d8d8d",
          marginRight: "0.5rem",
        };
      }
    },
    indicatorSeparator: () => ({
      display: "none",
    }),
    placeholder: () => ({
      color: "#3B4252",
    }),
  };

  const controlBR = () => {
    if (menuOpen) {
      return "4px 4px 0 0";
    } else {
      return "4px";
    }
  };

  const optionBG = (state) => {
    if (state.isSelected) return "#d5d5d5";
    else if (state.isFocused) return "#ededed";
    else return "white";
  };

  const options = props.options
    ? props.options
    : ["add options as a prop...", "blah", "blah", "blah"];

  const onMenuOpen = () => {
    // props.flow &&
    //   dispatch({
    //     type: "setBlockAudienceSelect",
    //     blockAudienceSelect: true
    //   });
    setMenuOpen(true);
  };

  const onMenuClose = () => {
    setMenuOpen(false);
  };

  const onChange = (e) => {
    if (props.onChange) {
      if (e !== null) {
        setValue(e);
        props.onChange(e.value);
      } else {
        props.onChange(null);
      }
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Select
        {...props}
        options={props.options}
        onChange={onChange}
        value={value}
        placeholder={
          props.placeholder ? props.placeholder : "-- Select an option --"
        }
        isSearchable={true}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        className="selectContainer"
        styles={customStyles}
      />
    </div>
  );
}
