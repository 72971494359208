import React, { useState, useEffect, useRef } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

import "./MiniTable.scss";
const allHandlerTypes = [
  {
    name: "Edit",
    handler: "onEditHandler",
  },
  {
    name: "View",
    handler: "onViewHandler",
  },
  {
    name: "Delete",
    handler: "onDeleteHandler",
  },
];

export default function MiniTable(props) {
  const { tableData, columnTitles, dataKeys } = props;

  const [currentPage, setCurrentPage] = useState(
    tableData.length === 0 ? 0 : 1
  );
  const [itemsPerPage, setItemsPerPage] = useState(4);
  const [pageCount, setPageCount] = useState(0);
  const [handlers, setHandlers] = useState([]);
  const [rawDataNum, setRawDataNum] = useState([]);

  useEffect(() => {
    let tmpHandlers = [];

    for (var i = 0; i < allHandlerTypes.length; ++i) {
      if (props[allHandlerTypes[i].handler]) {
        tmpHandlers.push({
          name: allHandlerTypes[i].name,
          handler: props[allHandlerTypes[i].handler],
        });
      }
    }
    setHandlers(tmpHandlers);
  }, []);

  useEffect(() => {
    setRawDataNum(props.forceTableUpdate);
  }, [props.rawData, props.forceTableUpdate]);

  useEffect(() => {
    setCurrentPage(tableData.length === 0 ? 0 : 1);
  }, [props.tableData]);

  /* table utilites */

  const campaignTablePageNavigation = (array, directionAsInteger) => {
    let newPageNumber = currentPage + directionAsInteger;
    newPageNumber = newPageNumber < 1 ? 1 : newPageNumber;
    newPageNumber =
      newPageNumber > calculatePageCount(array.length, itemsPerPage)
        ? calculatePageCount(array.length, itemsPerPage)
        : newPageNumber;
    setCurrentPage(newPageNumber);
  };

  const calculatePageCount = (length, itemsPerPage) => {
    return Math.ceil(length / itemsPerPage);
  };

  const changePageSizeInputHandler = (e, array) => {
    if (!isNaN(e.target.value) && e.target.value < 21 && e.target.value > 0) {
      setCurrentPage(1);
      setItemsPerPage(e.target.value);
      setPageCount(calculatePageCount(array.length, e.target.value));
    }
  };

  const Button = (props) => {
    return (
      <button
        onClick={props.onClick}
        style={{
          color: "maroon",
          border: "none",
          background: "none",
          cursor: "pointer",
        }}
      >
        {props.text}
      </button>
    );
  };

  const renderTableData = () => {
    return (
      <>
        <div className="campaignAnalyticsTable-header__wrapper">
          {columnTitles.map((columnTitle, index) => {
            return (
              <div
                className={
                  columnTitle !== "Error Code"
                    ? "campaignAnalyticsTable-header"
                    : "campaignAnalyticsTable-header campaignAnalyticsTable-header--right"
                }
              >
                {columnTitle}
              </div>
            );
          })}
        </div>
        {tableData.map((object, index) => {
          if (
            index <= currentPage * itemsPerPage - 1 &&
            index >= (currentPage - 1) * itemsPerPage
          ) {
            //if our row is on the currently selected page
            return (
              <div className="campaignAnalyticsTable-row__wrapper">
                {dataKeys.map((dataKey) => {
                  let classes = "campaignAnalyticsTable-item";

                  if (dataKey === "errorCode") {
                    classes += " campaignAnalyticsTable-item--large";
                  }

                  if (
                    object[dataKey] &&
                    typeof object[dataKey] === "string" &&
                    object[dataKey].indexOf("-truncate") > -1
                  ) {
                    classes += " ellipsis";
                  }

                  if (dataKey !== "IGNORE") {
                    return (
                      <>
                        <div
                          className={classes}
                          style={{
                            width: dataKeys.length === 1 ? "80%" : null,
                          }}
                        >
                          {object[dataKey] &&
                          typeof object[dataKey] === "string" &&
                          object[dataKey].indexOf("-truncate") > -1
                            ? object[dataKey].split("-truncate")[0]
                            : object[dataKey]}
                        </div>
                        {dataKey === "errorCode" ? (
                          <div className="errorCodeToolTip">
                            {object[dataKey]}
                          </div>
                        ) : null}
                      </>
                    );
                  } else {
                    return null;
                  }
                })}
                {/* Edit/View Functionality for Dialogue Properities */}

                {handlers.length > 0 && (
                  <div className="campaignAnalyticsTable-item">
                    {handlers.map((item, hindex) => {
                      return (
                        <>
                          <Button
                            text={item.name}
                            onClick={() =>
                              item.handler(
                                props.rawData ? props.rawData[index] : null
                              )
                            }
                          />
                          {hindex < handlers.length - 1 && "|"}
                        </>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          }
        })}
        {tableData.length === 0 && props.loading && (
          <div className="tableStatus">
            <CircularProgress size={"2rem"} />
          </div>
        )}
        {tableData.length === 0 && props.loading === false && (
          <div className="tableStatus">No data</div>
        )}
        {(() => {
          if (
            currentPage === calculatePageCount(tableData.length, itemsPerPage)
          ) {
            // we are on the last page
            if (tableData.length % itemsPerPage !== 0) {
              // if the last page is a partial page
              return Array(
                ((tableData.length % itemsPerPage) - itemsPerPage) * -1
              )
                .fill(0)
                .map((number, index) => {
                  // fill with blanks to match page size
                  return (
                    <div className="campaignAnalyticsTable-row__wrapper CAT-Blank">
                      <div className="campaignAnalyticsTable-item">-</div>
                      <div className="campaignAnalyticsTable-item">-</div>
                      <div className="campaignAnalyticsTable-item">-</div>
                    </div>
                  );
                });
            }
          }
        })()}
      </>
    );
  };

  const renderNonTableData = () => {
    let baseArr = (currentPage - 1) * itemsPerPage;
    let items = props.nonTableData.slice(baseArr, baseArr + itemsPerPage);
    return (
      <>
        {tableData.length === 0 && props.loading && (
          <div className="tableStatus">
            <CircularProgress size={"2rem"} />
          </div>
        )}
        {tableData.length === 0 && props.loading === false && (
          <div className="tableStatus">No data</div>
        )}
        {items.map((item) => {
          return item;
        })}
      </>
    );
  };

  return (
    <div className="campaignAnalyticsTable" key={"tableRender_" + rawDataNum}>
      {props.nonTableData ? renderNonTableData() : renderTableData()}
      <div
        style={{
          textAlign: "right",
          marginTop: "10px",
          fontSize: "0.8em",
          fontWeight: "normal",
        }}
      >
        Items Per Page:{" "}
        <input
          type="text"
          pattern="\d+"
          value={itemsPerPage}
          style={{
            borderRadius: "5px",
            border: "1px solid rgb(141, 141, 141)",
            outline: "0",
            textAlign: "center",
            width: "2em",
          }}
          onKeyUp={(e) => {
            if (e.key === "Backspace" || e.key === "Delete") {
              e.target.select();
            }
          }}
          onInput={(e) => {
            changePageSizeInputHandler(e, tableData);
          }}
          onClick={(e) => {
            e.target.select();
          }}
        />
      </div>
      <div className="campaignAnalyticsTableButtons">
        <div
          className={
            "CAT-Button" +
            (currentPage === 1 || currentPage === 0
              ? " CAT-Button--deactivated"
              : "")
          }
          onClick={() => {
            campaignTablePageNavigation(tableData, -1);
          }}
        >
          &lt; &nbsp;Previous
        </div>
        <div className="CAT-Pages">
          {currentPage} of {calculatePageCount(tableData.length, itemsPerPage)}
        </div>
        <div
          className={
            "CAT-Button" +
            (currentPage === calculatePageCount(tableData.length, itemsPerPage)
              ? " CAT-Button--deactivated"
              : "")
          }
          onClick={() => {
            campaignTablePageNavigation(tableData, 1);
          }}
        >
          Next &nbsp;&gt;
        </div>
      </div>
    </div>
  );
}
