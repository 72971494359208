import React, { useState, useEffect, useContext } from 'react';
import './RunSunny.css';
import { Input } from 'stories';
import { API, graphqlOperation } from 'aws-amplify';

import { ReactComponent as SendIcon } from 'pages/Flows/FlowDiagram/tempIcons/send-24px.svg';
import AuthContext from 'contexts/AuthContext';

import {
  validateLexQuestion,
  testLexQuestion,
  runCampaign,
} from 'graphql/queries';

import { idGenerator } from 'pages/Flows/FlowDiagram/utility.js';

// const endMessage =
//   ". Please be patient with me when testing, I can take a while to find the answer to a brand new question.";

const introMessage = {
  dev:
    'Welcome to Test! This is your own tester Sunny that ' +
    'will never be seen by anyone outside of users of this Chatbot Dashboard, including students! ' +
    'I have answers to every question listed in the dashboard, no matter the status. ' +
    'If you are trying to test out a brand new question, please be patient with me. ' +
    'I can take a while to update to the latest version of myself.',
  prod:
    'Welcome to Prod! I am my own Sunny, completely separate from Test Sunny. I only include ' +
    "questions and answers to those who have the status of 'Published'. You can also find me in the ASU Mobile App!" +
    "If you have just approved a brand new question into 'Published', please be patient with me. " +
    'I can take a while to update to the latest version of myself.',
};

const introMessageWithFlow = {
  dev:
    'Welcome to Test! At this time, Sunny will only run with the current flow.',
  prod:
    'Welcome to Prod! At this time, Sunny will only run with the current flow.',
};

export default function RunSunny(props) {
  const tokens = useContext(AuthContext);

  const [messages, setMessages] = useState([
    { isSelf: false, message: 'Intro message' },
  ]);
  const [message, setMessage] = useState('');
  const [canSend, setCanSend] = useState(false);
  const [hasStarted, setHasStarted] = useState(false);
  const [runningEnv, setRunningEnv] = useState('dev');
  const [runningQuestion, setRunningQuestion] = useState(false);

  const handleInput = e => {
    let m = e.target.value;
    setMessage(m);

    if (m.length > 0) setCanSend(true);
    else setCanSend(false);
  };

  useEffect(() => {
    let messageToUse = '';

    // console.log("PROPS", props);
    if (props.node && props.node.flowId) {
      messageToUse = introMessageWithFlow;
      flow(null, null, props.node.flowId, props.node.flowVersion);
    } else {
      messageToUse = introMessage;
    }

    setMessages([{ isSelf: false, message: messageToUse[props.env] }]);

    setTimeout(() => {
      setRunningEnv(props.env);
    }, 50);

    return () => {
      let payload = {
        operation: 'endCampaign',
        campaign: {},
      };
      API.graphql(graphqlOperation(runCampaign, payload))
        .then(resp => {
          // let info = resp.data.runCampaign;
          // console.log("DONE DELETING", info);
        })
        .catch(err => {
          console.log('Errored deleting', err);
        });
    };
  }, [props.env, props.flowId]);

  const setFocus = () => {
    setTimeout(() => {
      let input = document.getElementById('sunny-input');
      if (input) input.focus();
      else setFocus();
    }, 100);
  };

  const updateMessages = (mes, isSelf) => {
    // let type = mes.type ? mes.type : "unknown";
    // console.log("INCOMING", mes);

    let newMessage = {
      isSelf: isSelf,
      message: mes.message,
      matches: mes.matches,
      image: mes.image,
    };
    setMessages(messages => [...messages, newMessage]);

    var element = document.getElementById('sunnyMessageHolder');
    if (element)
      element.scrollTop = element.scrollHeight - element.clientHeight;

    setFocus();
  };

  const enterEvent = e => {
    if (message.length > 0 && e.key === 'Enter') {
      // runQuestion(message, true);
      whatToRun();
    }
  };

  const runQuestion = () => {
    let payload = {
      operation: 'simpleRun',
      inquiry: message,
      env: runningEnv,
    };
    // console.log("RUNNING PAY: ", payload);
    API.graphql(graphqlOperation(testLexQuestion, payload))
      .then(resp => {
        // console.log("RESP", resp);

        let info = resp.data.testLexQuestion;

        let startTime = 500;
        for (var i = 0; i < info.length; ++i) {
          slowMessages(info[i], startTime * i);
        }
        setRunningQuestion(false);
        // updateMessages(
        //   { message: resp.data.testLexQuestion.message },
        //   false
        // );
        // setRunningQuestion(false);
      })
      .catch(err => {
        console.log(err);
        updateMessages(
          {
            message: "I'm sorry, I cannot complete your request at this time.",
          },
          false
        );
        setRunningQuestion(false);
      });

    // setTimeout(() => {
    //   setMessage("");
    // }, 200);
  };

  let color = 'lightgray';
  if (canSend) color = 'black';

  const clickedMatch = m => {
    let lastMsg = messages[messages.length - 1];
    updateMessages({ message: m.match, id: m.id }, true);
    flow(m.id, null, null, null);
  };

  const renderMatch = (m, ind) => {
    let border = 'match';

    return (
      <div
        className={border}
        key={idGenerator()}
        onClick={() => clickedMatch(m)}
      >
        {m.match}
      </div>
    );
  };

  const renderMessage = (m, ind) => {
    let bubbleClass = '';
    bubbleClass += m.isSelf ? 'selfMessage' : 'otherMessage';

    if (m.isSelf && runningEnv === 'prod') {
      bubbleClass += ' prodMsgColor';
    }

    // console.log("MESSAGE: ", m);

    return (
      <div key={'sunny-' + ind}>
        {m.image ? (
          <div className="fullMsg" style={{ marginBottom: '2px' }}>
            <div className={bubbleClass} style={{ padding: '6px 10px' }}>
              <img
                style={{ width: '120px', paddingTop: '10px' }}
                src={m.image}
              />
            </div>
          </div>
        ) : null}

        <div className="fullMsg">
          <div className={bubbleClass}>
            <div>{m.message}</div>
          </div>
        </div>
        {m.matches && ind === messages.length - 1 ? (
          <div className="matchHolder">
            {m.matches.map((match, mInd) => {
              return renderMatch(match, mInd);
            })}
          </div>
        ) : null}
      </div>
    );
  };

  const flow = (choice, m, flowId = null, flowVersion = null) => {
    setRunningQuestion(true);
    let payload = {
      operation: flowId ? 'startCampaign' : 'campaign',
      campaign: {
        content: m,
        choice: choice,
        flowId: flowId,
        flowVersion: flowVersion,
      },
    };

    console.log('IS HTTING THISF UNCTION: ', payload);

    API.graphql(graphqlOperation(runCampaign, payload))
      .then(resp => {
        let info = resp.data.runCampaign;
        // console.log("RESPONSE", resp);

        let startTime = 500;
        for (var i = 0; i < info.length; ++i) {
          slowMessages(info[i], startTime * i);
        }
        setRunningQuestion(false);
      })
      .catch(err => {
        console.log(err);
        updateMessages(
          {
            message: "I'm sorry, I cannot complete your request at this time.",
            type: 'error',
          },
          false
        );
        setRunningQuestion(false);
      });
  };

  const slowMessages = (item, time) => {
    // let file = JSON.parse(item.file);

    setTimeout(() => {
      updateMessages(
        { message: item.content, matches: item.matches, image: item.image },
        false
      );
    }, time);
  };

  const whatToRun = () => {
    updateMessages({ message: message }, true);
    setRunningQuestion(true);

    if (props.node && props.node.flowId) {
      let lastMsg = messages[messages.length - 1];
      flow(null, message, null, null);
    } else {
      runQuestion();
    }

    setTimeout(() => {
      setMessage('');
    }, 200);
  };

  return (
    <div style={{ width: '100%' }}>
      <div className={runningEnv === "dev" ? "sunnyHeaderHolder sunnyHeaderHolder--test" : "sunnyHeaderHolder"} />
      <div className="messages" id="sunnyMessageHolder">
        <div>
          {messages.map((m, ind) => {
            return renderMessage(m, ind);
          })}
        </div>
      </div>
      <div className="sendMessage">
        {runningQuestion ? (
          <div className="runningSunny">Sunny is checking your question</div>
        ) : (
          <div className="inputHolder">
            <Input
              id="sunny-input"
              onChange={handleInput}
              value={message}
              onKeyDown={enterEvent}
              style={{
                backgroundColor: 'white',
                width: '360px',
                border: 'none',
                height: '70px',
                boxShadow: 'none',
              }}
            />
          </div>
        )}

        {runningQuestion ? null : (
          <div
            style={{ pointerEvents: canSend ? 'unset' : 'none' }}
            onClick={whatToRun}
          >
            <SendIcon className="svgHolder" fill={color} />
          </div>
        )}
      </div>
    </div>
  );
}
