import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import './Status.css';

export default function Status(props) {
  const useStyles = makeStyles(theme => ({
    status: {
      // fontSize: theme.typography.button.fontSize,
      fontSize: '.9rem',
      fontFamily: theme.typography.fontFamily,
      padding: '0rem 0.75rem',
      // borderStyle: 'solid',
      // borderWidth: '1px',
      // borderRadius: '25px',
      //  color: color(theme),
    },
  }));

  const useStylesDot = makeStyles(theme => ({
    status: {
      height: '0.8rem',
      width: '0.8rem',
      backgroundColor: color(theme),
      borderRadius: '50%',
      display: 'inline-block',
      verticalAlign: 'top',
      marginTop: '0.25rem',
      // color: color(theme),
    },
  }));

  const color = theme => {
    const { general } = theme.palette;

    if (props.useversionstatus && props.versionstatus) {
      if (props.versionstatus.approved) {
        return general.darkBlue;
      } else if (props.versionstatus.submitted) {
        return general.darkOrange;
      } else if (props.versionstatus.denied) {
        return general.errorColor;
      } else if (props.versionstatus.published) {
        return general.lightGreen;
      } else {
        return general.purple;
      }
    }

    if (props.editing) {
      return general.darkOrange;
    } else if (props.processing === true) {
      return general.darkOrange;
    } else if (props.status === 6) {
      return general.darkGrey;
    } else if (props.paused) {
      return general.darkOrange;
    } else if (props.error !== null && typeof props.error != 'undefined') {
      return general.errorColor;
    } else if (props.status === 1) {
      return general.darkBlue;
    } else if (props.status === 2) {
      return general.darkOrange;
    } else if (props.status === 3) {
      return general.purple;
    } else if (props.status === 4) {
      return general.lightGreen;
    } else if (props.status === 5) {
      return general.mediumMaroon;
    } else if (props.status === 7) {
      return general.lightGreen;
    } else if (props.status === 8) {
      return general.darkGreen;
    } else if (props.status === 9) {
      return general.purple;
    } else {
      return 'black';
    }
  };

  const classes = useStyles();
  const dots = useStylesDot();

  const val = (
    <div style="text-align:left">
      <span className="dot1"></span>
    </div>
  );

  let text = '';

  if (props.editing) {
    text = 'Editing';
  } else if (props.processing === true) {
    text = 'Processing';
  } else if (props.status === 6) {
    //Deleting needs to show before error
    text = 'Deleting';
  } else if (props.paused === true) {
    text = 'Paused';
  } else if (props.error !== null && typeof props.error != 'undefined') {
    text = 'Error';
  } else if (props.status === 1) {
    text = 'Pending';
  } else if (props.status === 2) {
    text = 'Unapproved';
  } else if (props.status === 3) {
    text = 'Approved';
  } else if (props.status === 4) {
    text = 'Published';
  } else if (props.status === 5) {
    text = 'Disabled';
  } else if (props.status === 7) {
    text = 'Sending';
  } else if (props.status === 8) {
    text = 'Active';
  } else if (props.status === 9) {
    text = 'Finished';
  }

  // approved = 10
  // pending = 11
  // published = 12
  // denied  = 13
  // paused = 14
  // in progress = 15

  if (props.useversionstatus && props.versionstatus) {
    if (props.editing) {
      text = 'Editing';
    } else if (props.versionstatus.approved) {
      text = 'Approved';
    } else if (props.versionstatus.submitted) {
      text = 'Pending';
    } else if (props.versionstatus.published) {
      text = 'Published';
    } else if (props.versionstatus.denied) {
      text = 'Denied';
    } else {
      text = 'In Progress';
    }
  }

  return (
    <div style={{ display: 'flex', cursor: 'default', ...props.style }}>
      <div className="dots_Color">
        <span className={dots.status}></span>
      </div>
      {props.tooltip ? (
        <Tooltip title={props.tooltip} arrow>
          <div className="classes">
            <div className={classes.status}>{text}</div>
          </div>
        </Tooltip>
      ) : (
        <div className="classes">
          <div className={classes.status}>{text}</div>
        </div>
      )}
    </div>
  );
}
