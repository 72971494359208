import React, { useState, useEffect } from "react";
import "./Properties.css";
import { Form, Input, InputAdd, Textarea, Select, MultiRange } from "stories";
import { idGenerator } from "../../utility.js";
const invalidMatch = /^\[[0-9]*\]\s?[a-zA-Z0-9]+/;

export default function IfThenProperties(props) {
  const { node, extras } = props;
  const [text, setText] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [matches, setMatches] = useState([]);
  const [noMatch, setNoMatch] = useState({});
  const [image, setImage] = useState(null);
  const [customType, setCustomType] = useState(null);

  const rangeTypes = [
    { label: "Number", value: "number" },
    { label: "Beginning Letter", value: "begletter" },
  ];

  const initNode = () => {
    setText(node.text);
    setImage(node.image);
    if (node.matches === undefined) node.matches = [];

    let mArr = [];
    for (var i = 0; i < node.matches.length; ++i) {
      if (node.matches[i].match !== "NO MATCH") {
        mArr.push({
          id: node.matches[i].id,
          text: node.matches[i].match,
        });
      } else {
        setNoMatch(node.matches[i]);
      }
    }

    setMatches(mArr);
    setImage(node.image);
    setCustomType(node.customType ? node.customType : null);
  };

  useEffect(() => {
    initNode();
  }, [props.node]);

  const translateVar = (v) => {
    for (var i = 0; i < rangeTypes.length; ++i) {
      if (rangeTypes[i].value === v) {
        return rangeTypes[i];
      }
    }
  };

  const deleteWarning = (deleteNode) => {
    let tempNodes = JSON.parse(JSON.stringify(node.matches));

    for (var i = 0; i < tempNodes.length; ++i) {
      if (tempNodes[i].id === deleteNode.id) {
        tempNodes.splice(i, 1);
      }
    }

    let markAsNewBranch = {
      branchId: node.id,
      id: deleteNode.id,
      newMatches: tempNodes,
      matches: JSON.parse(JSON.stringify(node.matches)),
    };

    props.deleteWarningBranch(markAsNewBranch);
  };

  const formData = [
    {
      title: "VARIABLE",
      component: (
        <Input
          name="text"
          disabled={true}
          ignoreInsert={true}
          image={image}
          allowextras={props.viewOnly ? false : "var"}
          required
          form="true"
          value={text}
          style={{
            background: "#dadada",
            cursor: "not-allowed",
          }}
        />
      ),
    },
  ];

  const handleRangeChange = (c) => {
    setCustomType(c.value);
  };

  if (
    node.type === "ifthen-existsnot" ||
    node.type === "ifthen-value" ||
    node.type === "ifthen-hasvalue"
  ) {
    formData.push({
      title: "MATCH AGAINST",
      subtext:
        "This will do a match on the variable value for the user asking the question. " +
        (node.type === "ifthen-hasvalue"
          ? "Split different values by a comma."
          : ""),
      component: (
        <InputAdd
          name="matches"
          contref={props.containerRef}
          required
          form="true"
          isNew={node.isNew}
          noedit={node.type === "ifthen-existsnot"}
          maxlength={node.type === "ifthen-hasvalue" ? "100" : "30"}
          matches={matches}
          invalidmatch={invalidMatch}
          deletewarning={deleteWarning}
          viewOnly={props.viewOnly}
        />
      ),
    });
  } else if (node.type.indexOf("ifthen-range") > -1) {
    formData.push({
      title: "RANGES",
      required: true,
      component: (
        <MultiRange
          name="matches"
          contref={props.containerRef}
          required
          form="true"
          isNew={node.isNew}
          noedit={node.type === "ifthen-existsnot"}
          maxlength="30"
          rangeType={customType}
          matches={matches}
          invalidmatch={invalidMatch}
          deletewarning={deleteWarning}
        />
      ),
    });
  }

  const onSubmit = (data, e) => {
    if (data.type !== "submit") {
      try {
        data.matches = JSON.parse(data.matches);
        for (var i = 0; i < data.matches.length; ++i) {
          data.matches[i].match = data.matches[i].text;
          delete data.matches[i].text;
          delete data.matches[i].didEdit;
        }

        data.matches = [...data.matches];

        if (customType !== "existsnot") {
          data.matches.push(noMatch);
        }
      } catch (e) {}

      try {
        data.customType = JSON.parse(data.customType).value;
      } catch (e) {}

      props.saveInfo(data);
    }
  };

  return (
    <Form
      data={formData}
      onSubmit={onSubmit}
      deleteWarning={props.deleteWarning}
      type="node"
      style={{ width: "100%" }}
    />
  );
}
