import React from 'react';
import SelectPage from 'components/knowledgeBase/SelectPage';
import { Button } from 'stories';
import './PaginationPanel.css';

export default function PaginationPanel({
  theme,
  currentPage,
  pageCount,
  onClickFetchMore,
  onClickFetchLess,
  jumpToPageOptions,
  onChangeJumpToPage,
}) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2rem 0',
      }}
    >
      <div className="NextPage__wrapper" onClick={onClickFetchLess}>
        <div className="NextPage NextPage--prev">
          <div></div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          margin: '0 1rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '4rem',
            height: '2rem',
          }}
        >
          {currentPage + ' of ' + pageCount}
        </div>
        <SelectPage
          text="page"
          options={jumpToPageOptions()}
          onSelect={onChangeJumpToPage}
          currentPage={currentPage}
        />
      </div>

      <div className="NextPage__wrapper" onClick={onClickFetchMore}>
        <div className="NextPage">
          <div></div>
        </div>
      </div>
    </div>
  );
}
