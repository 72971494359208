import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { scroller as scroll, Events } from 'react-scroll';
import { MiniTable, Modal, PanelButton, Accordion, Button } from 'stories';
import { useModal } from 'hooks';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import CircularProgress from '@material-ui/core/CircularProgress';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckIcon from '@material-ui/icons/Check';
import { store as GlobalStore } from 'contexts/GlobalStore';
import Tooltip from '@material-ui/core/Tooltip';

import '../../Dialogues.scss';

import * as tagHelper from '../../Helpers/tagHelper.js';

import TableHelper from './TableHelper.js';

import {
  queryWithAthena,
  escHistoryByConvoId,
  compCampByUserId,
} from 'graphql/queries';

import { API, graphqlOperation } from 'aws-amplify';

export default function CampaignHistory(props) {
  const GlobalState = useContext(GlobalStore);
  const dispatchGlobal = GlobalState.dispatch;

  const [campaigns, setCampaigns] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [prevConvoId, setPrevConvoId] = useState(null);

  useEffect(() => {
    if (props.convo.convoId !== prevConvoId) {
      setPrevConvoId(props.convo.convoId);
    }
  }, [props.convo]);

  useEffect(() => {
    setIsLoading(true);
    setCampaigns([]);
    queryForData();
  }, [prevConvoId]);

  let keys = [];

  let csvKeys = {
    userId: 'User',
    exitType: 'Exit Type',
    type: 'Type',
    error: 'Error',
    'campaignInfo.title': 'Campaign Title',
    isManualCamp: 'Manual Send',
    startTime: 'Start Time',
    finishTime: 'Finish Time',
  };

  let tableKeys = {
    name: 'Name',
    exitType: 'Exit Type',
    dateTime: 'Date',
  };

  const queryForData = async () => {
    let camps = [];
    let queryRes;

    try {
      queryRes = await API.graphql(
        graphqlOperation(compCampByUserId, {
          userId:
            props.convo.type === 'auth'
              ? props.convo.asurite
              : props.convo.phoneNumber,
        })
      );

      camps = queryRes.data.compCampByUserId.items;
    } catch (error) {
      console.log('Campaign History Error', error, queryRes);
      camps = error.data.compCampByUserId.items;
    }

    if (camps.length > 0) {
      camps = camps.filter((value, index) => {
        if (value !== null) {
          return true;
        }
      });
    }

    camps = camps.sort(function(a, b) {
      return parseInt(b.startTime) - parseInt(a.startTime);
    });

    let saveCampData = [];

    for (var i = 0; i < camps.length; ++i) {
      let record = camps[i];

      let name = null;

      let s =
        record.startTime.indexOf('.') > -1
          ? parseInt(record.startTime) * 1000
          : parseInt(record.startTime);
      let f =
        record.finishTime.indexOf('.') > -1
          ? parseInt(record.finishTime) * 1000
          : parseInt(record.finishTime);

      record.startTime = props.dateFormatter(s, true);
      record.finishTime = props.dateFormatter(f, true);

      record.isManualCamp = record.isManualCamp ? 'TRUE' : 'FALSE';
      record.dateTime = record.startTime;

      try {
        record.campaignInfo = record.campaignInfo.items[0];
        record.name = record.campaignInfo.title;
      } catch (e) {}

      if (record.name) {
        saveCampData.push(record);
      }
    }

    setCampaigns(saveCampData);
    setIsLoading(false);
  };

  return (
    <TableHelper
      rawData={campaigns}
      title={'Campaign History'}
      tableKeys={tableKeys}
      csvKeys={csvKeys}
      convo={props.convo}
      dateFormatter={props.dateFormatter}
      loading={loading}
    />
  );
}
