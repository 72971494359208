import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { scroller as scroll, Events } from "react-scroll";
import { MiniTable, Modal, PanelButton, Accordion, Button } from "stories";
import { useModal } from "hooks";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import CircularProgress from "@material-ui/core/CircularProgress";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckIcon from "@material-ui/icons/Check";
import { store as GlobalStore } from "contexts/GlobalStore";
import Tooltip from "@material-ui/core/Tooltip";

import "../../Dialogues.scss";

import * as tagHelper from "../../Helpers/tagHelper.js";

import TableHelper from "./TableHelper.js";

import {
  queryWithAthena,
  escHistoryByConvoId,
  compCampByUserId,
} from "graphql/queries";

import { API, graphqlOperation } from "aws-amplify";

export default function Notes(props) {
  const GlobalState = useContext(GlobalStore);
  const dispatchGlobal = GlobalState.dispatch;

  const [notes, setNotes] = useState([]);
  const [loading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setNotes([]);
    alterNotesData();
  }, [props.convo, props.notes]);

  const alterNotesData = () => {
    let myNotes = [];

    for (var i = 0; i < props.notes.length; ++i) {
      let record = props.notes[i];
      record.dateTime = props.dateFormatter(record.date, true);
      record.agentDisplay = props.getAssigneeName(record.author);
      myNotes.push(record);
    }

    setIsLoading(false);
    setNotes(myNotes);
  };

  let csvKeys = {
    agentDisplay: "Agent",
    content: "Content",
    dateTime: "Date/Time",
  };
  let tableKeys = {
    dateTime: "Date",
    agentDisplay: "Agent",
    IGNORE: " ",
  };

  let extraButton = (
    <PanelButton
      type="search"
      label="Search"
      style={{ marginRight: "1rem" }}
      onClick={() => {
        props.toggle();
      }}
    />
  );

  return (
    <TableHelper
      rawData={notes}
      title={"Notes"}
      tableKeys={tableKeys}
      csvKeys={csvKeys}
      convo={props.convo}
      extraButton={extraButton}
      dateFormatter={props.dateFormatter}
      onEditHandler={props.onEditHandler}
      onViewHandler={props.onViewHandler}
      loading={loading}
    />
  );
}
