import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { useTheme } from '@material-ui/core/styles';
import { API, graphqlOperation } from 'aws-amplify';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import moment from 'moment';

import PaginationPanel from 'stories/Table/PaginationPanel';
import { SectionHeader } from 'components/SectionHeader';
import { listFlowMessages, getCampaignRunning } from 'graphql/queries';
import { updateFlowMessage } from 'graphql/mutations';
import AuthContext from 'contexts/AuthContext';
import { store } from 'contexts/GlobalStore';
import { CreateNewButton, Button, Table } from 'stories';
import { roleCheck } from 'services/utility/RoleMatrix';
import { translateAudience } from './audiences.js';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function FlowMessages(props) {
  const [data, setData] = useState([]);
  const [rawData, setRawData] = useState([]);
  const [open, setOpen] = useState(false);

  const [totalResults, setTotalResults] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [prevPageClicked, setPrevPageClicked] = useState(false);
  const [rerenderKey, setRerenderKey] = useState(Date.now());
  const [refetchKey, setRefetchKey] = useState(Date.now());

  const [posts, setPosts] = useState([]);
  const [pageLimit] = useState(7);

  const tokens = useContext(AuthContext);
  const theme = useTheme();

  const GlobalState = useContext(store);
  const { dispatch } = GlobalState;
  const { tableSort, tableDataEmpty } = GlobalState.state;

  const onClickCreateNew = () => {
    dispatch({ type: 'updateEditInfo', editInfo: { empty: true } });
  };

  const onSubmitDelete = async e => {
    let payload = {
      id: e.id,
      deleted: true,
      deletedBy: {
        asurite: tokens.asurite,
        timestamp: new Date().getTime() + '',
      },
    };
    let result = await API.graphql(
      graphqlOperation(updateFlowMessage, {
        input: payload,
      })
    );

    getMessages();
  };

  const onClickEdit = e => {
    let selected = null;
    for (var i = 0; i < rawData.length; ++i) {
      if (rawData[i].id === e.id) {
        selected = rawData[i];
        break;
      }
    }
    dispatch({ type: 'updateEditInfo', editInfo: { ...selected } });
    props.history.push({
      pathname: '/flow-message-form',
    });
  };

  useEffect(() => {
    getMessages();
  }, [dispatch, rerenderKey, refetchKey, currentPage, tableSort]);

  useEffect(() => {
    let p = sortItems(JSON.parse(JSON.stringify(data)));
    setData(p);
    setRerenderKey(new Date().getTime());
  }, [tableSort]);

  const sortItems = p => {
    let isDesc = !tableSort || tableSort.direction !== 'asc';
    let f = 'created';

    if (tableSort && tableSort.field) {
      f = tableSort.field;
    }

    p.sort(function(a, b) {
      let ia = a[f];
      let ib = b[f];
      if (ia < ib) {
        return isDesc ? 1 : -1;
      }
      if (ia > ib) {
        return isDesc ? -1 : 1;
      }
      return 0;
    });

    return p;
  };

  useEffect(() => {
    tableDataEmpty &&
      dispatch({
        type: 'setLoadingTableData',
        loadingTableData: false,
      });
  }, [tableDataEmpty]);

  const getMessages = async () => {
    if (tableDataEmpty) {
      dispatch({
        type: 'setLoadingTableData',
        loadingTableData: false,
      });
    } else {
      dispatch({
        type: 'setLoadingTableData',
        loadingTableData: true,
      });
    }

    let filterQuery = {
      deleted: { eq: false },
    };

    if (tokens.role === 'approver' || tokens.role === 'content creator') {
      filterQuery.or = [];
      tokens.groups.forEach(element => {
        filterQuery.or.push({ group: { eq: element } });
      });
    }

    try {
      const result = await API.graphql(
        graphqlOperation(listFlowMessages, {
          limit: 9999999,
          filter: filterQuery,
        })
      );

      setTotalResults(result.data.listFlowMessages.items.length);

      let msgs = result.data.listFlowMessages.items;
      setRawData(msgs);

      let parsed = [];

      let editCheck = roleCheck(tokens.role, 'flowMessage', 'edit');
      let delCheck = roleCheck(tokens.role, 'flowMessage', 'delete');

      for (var i = 0; i < msgs.length; ++i) {
        parsed.push({
          id: msgs[i].id,
          name2: msgs[i].name,
          wide_message: msgs[i].message,
          nodeType: msgs[i].nodeType,
          audience: msgs[i].audience
            ? translateAudience(msgs[i].audience)
            : 'N/A',
          group: msgs[i].group,
          created: moment(msgs[i].createdAt).format('MM-DD-YYYY'),
          actions: {
            edit:
              editCheck === true ||
              (editCheck === 'group' &&
                tokens.groups.indexOf(msgs[i].group) > -1),
            delete:
              delCheck === true ||
              (delCheck === 'group' &&
                tokens.groups.indexOf(msgs[i].group) > -1),
          },
        });
      }

      sortItems(parsed);
      setPosts(parsed);
      dispatch({
        type: 'setLoadingTableData',
        loadingTableData: false,
      });
    } catch (error) {
      console.log('Messages fetch data error: ', error);
    } finally {
      tableDataEmpty &&
        dispatch({
          type: 'setLoadingTableData',
          loadingTableData: false,
        });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const getSortKey = label => {
    if (label === 'NODE TYPE') {
      return 'nodeType';
    } else if (label === 'NAME') {
      return 'name2';
    } else if (label === 'OWNER') {
      return 'group';
    } else if (label === 'CREATED') {
      return 'created';
    }
  };

  const tableLabels = [
    { title: 'NAME' },
    { title: 'MESSAGE', notClickable: true, wide: true },
    { title: 'NODE TYPE' },
    { title: 'AUDIENCE' },
    { title: 'OWNER' },
    { title: 'CREATED' },
    { title: 'ACTIONS', alignRight: true, notClickable: true },
  ];

  const onClickFetchMore = () => {
    setPrevPageClicked(false);
    if (currentPage === Math.ceil(totalResults / pageLimit)) {
      return;
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const onClickFetchLess = async () => {
    setPrevPageClicked(true);
    if (currentPage <= 1) {
      setCurrentPage(1);
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const onChangeJumpToPage = pageNumber => {
    setCurrentPage(pageNumber);
  };

  const jumpToPageOptions = () => {
    const totalPages = Math.ceil(totalResults / pageLimit);
    const options = [];

    for (let i = 1; i <= totalPages; i++) {
      options.push(i);
    }
    const mappedOptions = options.map(el => {
      return { value: el, label: el };
    });
    return mappedOptions;
  };

  const indexOfLastPost = currentPage * pageLimit;
  const indexOfFirstPost = indexOfLastPost - pageLimit;
  const currentPagePosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  return (
    <div style={theme.components.pageContainer} key={rerenderKey}>
      <div className="SectionHeader">
        <SectionHeader
          noSearch
          noSort
          noModality
          rightView={
            <div onClick={onClickCreateNew}>
              <button class="btn">
                <CreateNewButton
                  to="/flow-message-form"
                  label="Create New Message"
                />
              </button>
            </div>
          }
        />
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          User has been deleted successfully!
        </Alert>
      </Snackbar>
      <div className="TablePage">
        <Paper style={theme.components.paper}>
          <div
            style={{
              width: '100%',
              borderLeft: `1px solid ${theme.palette.general.mediumGrey}`,
              paddingBottom: '2rem',
              ...props.style,
            }}
          >
            <Table
              title="Flow Messages Table"
              labels={tableLabels}
              data={currentPagePosts}
              onSubmitDelete={onSubmitDelete}
              onClickEdit={onClickEdit}
              defaultSortKey="asurite"
              getSortKey={getSortKey}
              noFilter
              noSearch
            />
            <PaginationPanel
              theme={theme}
              currentPage={currentPage}
              pageCount={Math.ceil(totalResults / pageLimit)}
              onClickFetchMore={onClickFetchMore}
              onClickFetchLess={onClickFetchLess}
              jumpToPageOptions={jumpToPageOptions}
              onChangeJumpToPage={onChangeJumpToPage}
            />
          </div>
        </Paper>
      </div>
    </div>
  );
}

FlowMessages.propTypes = {
  navigation: PropTypes.object,
};
