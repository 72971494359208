import React, { useState, useEffect } from "react";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { useFormContext } from "react-hook-form";
import "./MultiRange.css";
import { idGenerator } from "services/utility/util.js";

export default function MultiRange(props) {
  let { invalidmatch } = props;

  const theme = useTheme();
  const { register } = useFormContext();

  const [lowerRange, setLowerRange] = useState("");
  const [upperRange, setUpperRange] = useState("");
  const [matches, setMatches] = useState(props.matches);
  const [invalid, setInvalid] = useState(false);
  const [editInd, setEditInd] = useState(-1);
  const [invalidReason, setInvalidReason] = useState(null);

  useEffect(() => {
    setMatches(props.matches);
  }, [props.matches]);

  useEffect(() => {
    if (!invalid) {
      setInvalidReason(null);
    }
  }, [invalid]);

  const useStyles = makeStyles((theme) => ({
    required: {
      ...theme.typography.subtitle2,
      backgroundColor: theme.palette.general.lightOrange,
      textAlign: "center",
      padding: "0.5rem 1rem",
      margin: "4rem 0",
      borderRadius: "4px",
    },
    button: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "5rem",
      fontFamily: "Roboto",
      color: "white",
      backgroundColor: theme.palette.general.darkGrey,
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
      cursor: "pointer",
    },
  }));

  const classes = useStyles();

  const convertToNum = (c) => {
    return props.rangeType === "number" ? parseInt(c) : c.charCodeAt(0);
  };

  const addToMatches = () => {
    let matchStr = invalidmatch !== undefined;
    let isValid = false;

    if (matchStr && !invalidmatch.test(lowerRange)) {
      isValid = true;
    }

    let lowerNum = convertToNum(lowerRange);
    let uppNum = convertToNum(upperRange);

    if (uppNum <= lowerNum) {
      isValid = false;
      setInvalidReason(
        "Upper bound cannot be less than or equal to lower bound"
      );
    }

    if (isValid) {
      for (var i = 0; i < matches.length; ++i) {
        let mRange = matches[i].text.split("-");
        let mLow = convertToNum(mRange[0]);
        let mUpp = convertToNum(mRange[1]);

        if (
          (mLow <= lowerNum && mUpp >= lowerNum) ||
          (mLow <= uppNum && mUpp >= uppNum)
        ) {
          isValid = false;
          setInvalidReason("Range overlap");
        }
      }
    }

    if (editInd > -1) {
      let m = JSON.parse(JSON.stringify(matches));
      m[editInd].text = lowerRange + "-" + upperRange;
      m[editInd].didEdit = true;
      setLowerRange("");
      setUpperRange("");
      setMatches(m);
      setEditInd(-1);
    } else if (lowerRange.length > 0 && (!matchStr || (matchStr && isValid))) {
      let m = matches.concat({
        id: idGenerator(),
        text: lowerRange + "-" + upperRange,
      });
      setLowerRange("");
      setUpperRange("");
      setMatches(m);
      if (props.contref) {
        setTimeout(() => {
          props.contref.scrollTop = props.contref.scrollHeight;
        }, 200);
      }
    } else {
      setInvalid(true);
    }
  };

  const AddButton = (props) => {
    return (
      <label {...props} className={classes.button} onClick={addToMatches}>
        Add
      </label>
    );
  };

  const removeMatch = (e, ind) => {
    e.stopPropagation();
    if (props.deletewarning && !props.isNew) {
      props.deletewarning(matches[ind]);
    } else {
      let m = JSON.parse(JSON.stringify(matches));
      m.splice(ind, 1);
      setMatches(m);
    }
  };

  const editMatch = (ind) => {
    let range = matches[ind].text.split("-");

    setLowerRange(range[0]);
    setUpperRange(range[1]);
    setEditInd(ind);
  };

  const _renderMapping = (i, ind) => {
    let mainClasses = "mainMatch ";
    if (ind === matches.length - 1) {
      mainClasses += "bottomMatch";
    }

    return (
      <div
        className={mainClasses}
        key={"matchOn_" + ind}
        onClick={() => editMatch(ind)}
      >
        <div className="matchInfo">
          [{ind + 1}] {i.text}
        </div>
        {props.noedit ? null : (
          <div
            className="removeBtn"
            style={{ color: theme.palette.general.lightMaroon }}
            onClick={(e) => removeMatch(e, ind)}
          >
            Remove
          </div>
        )}
      </div>
    );
  };

  let invalidClasses = "invalid ";
  if (invalid) {
    invalidClasses += "showInvalid";
  }

  return (
    <div style={{ cursor: "default" }}>
      {props.noedit ? null : (
        <div style={{ display: "flex" }}>
          <input
            className="input"
            value={lowerRange}
            style={{
              ...theme.typography.body1,
              width: "40%",
              borderTopRightRadius: "0px",
              borderBottomRightRadius: "0px",
              border: `1px solid ${theme.palette.general.deepGrey}`,
              color: theme.palette.general.darkGrey,
              ...props.style,
            }}
            type={props.rangeType}
            maxlength={1}
            onChange={(e) => {
              setInvalid(false);
              setLowerRange(e.target.value);
            }}
          />
          <div style={{ marginTop: "11px", display: "flex" }}> {" - "} </div>
          <input
            className="input"
            value={upperRange}
            style={{
              ...theme.typography.body1,
              width: "40%",
              borderTopRightRadius: "0px",
              borderBottomRightRadius: "0px",
              border: `1px solid ${theme.palette.general.deepGrey}`,
              color: theme.palette.general.darkGrey,
              ...props.style,
            }}
            type={props.rangeType}
            maxlength={1}
            onChange={(e) => {
              setInvalid(false);
              setUpperRange(e.target.value);
            }}
          />

          <AddButton htmlFor="add" />
        </div>
      )}

      <div className={invalidClasses}>
        Invalid Entry {invalidReason ? " - " + invalidReason : ""}{" "}
      </div>
      <div style={{ paddingTop: "20px" }}>
        {matches.map((item, index) => _renderMapping(item, index))}
      </div>
      <input
        style={{ display: "none" }}
        name={props.name}
        ref={
          props.form
            ? props.required
              ? register({ required: true })
              : register
            : null
        }
        type="hidden"
        value={JSON.stringify(matches)}
        onChange={(e) => console.log("change")}
      />
    </div>
  );
}
