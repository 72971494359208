import React, { useState, useEffect, useContext } from 'react';
import AuthContext from 'contexts/AuthContext';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from 'graphql/queries';
import moment from 'moment';
import { PanelButton, MiniTable, ThreeSquare } from 'stories';

//import "../CampaignTabs.scss";

export default function CampaignAnalyticsOptOut(props) {
  const [panelSectionData, setPanelSectionData] = React.useState(null);
  const tokens = useContext(AuthContext);

  function getSendLevelErrors() {
    let allInfo = [];
    let nextToken = null;
    return new Promise(async function tryagain(resolve, reject) {
      // resolve([]);
      let params = {
        campaignId: props.id,
        filter: {
          or: [
            { exitType: { eq: 'error' } },
            { expiredBeforeSend: { eq: true } },
          ],
        },
      };

      if (nextToken) {
        params.nextToken = nextToken;
      }

      let result = await API.graphql(
        graphqlOperation(queries.compCampById, params)
      );

      allInfo = allInfo.concat(result.data.compCampById.items);
      nextToken = result.data.compCampById.nextToken;

      if (nextToken) {
        tryagain(resolve, reject);
      } else {
        for (var i = 0; i < allInfo.length; ++i) {
          if (allInfo[i].expiredBeforeSend)
            allInfo[i].error = 'Expired before send';
        }

        resolve(allInfo);
      }
    });
  }

  function getUserMetaData(userId) {
    return new Promise(async function(resolve, reject) {
      let pay = {};
      if (!isNaN(userId)) {
        pay.phoneNumber = userId;
      } else {
        pay.asurite = userId;
      }
      pay.botType = 'sunny';

      let resp = await API.graphql(
        graphqlOperation(queries.searchMessages, {
          operation: 'queryForUsers',
          payload: JSON.stringify(pay),
        })
      );

      resp = JSON.parse(resp.data.searchMessages);

      resolve(resp || []);
    });
  }

  React.useEffect(() => {
    async function getFailures() {
      let result;
      const errorCodesToGet = [];

      let failuresArray = await getSendLevelErrors();

      failuresArray = failuresArray.map(object => {
        let phone_number = object.userId.replace('+1', '');
        phone_number = `${phone_number.slice(0, 3)}-${phone_number.slice(
          3,
          6
        )}-${phone_number.slice(6)}`;

        errorCodesToGet.push(object.error);

        let returnObject = {
          userId: object.userId,
          phone: phone_number,
          date:
            parseInt(object.startTime).toString().length < 11
              ? moment(object.startTime, 'X').format('MM/DD/YYYY')
              : moment(object.startTime, 'x').format('MM/DD/YYYY'),
          errorCode: object.error,
        };

        if (typeof object.displayName !== 'undefined') {
          returnObject.displayName = object.displayName;
        }

        return returnObject;
      });

      for (let i = 0; i < failuresArray.length; i++) {
        let userMetaData = await getUserMetaData(failuresArray[i].userId);
        delete failuresArray[i].userId;
        if (userMetaData[0] && userMetaData[0].displayName) {
          failuresArray[i].displayName = userMetaData[0].displayName;
        }
      }

      if (errorCodesToGet.length > 0) {
        //get error messages
        event__getErrorMessages(failuresArray);
      } else {
        setPanelSectionData(failuresArray);

        //update tabDataCache
        let newTabDataCache = Object.assign({}, props.tabDataCache);
        newTabDataCache[props.panelTitle] = failuresArray;
        props.setTabDataCache(newTabDataCache);
      }
    }

    if (panelSectionData === null) {
      if (
        typeof props.tabDataCache['Failures'] === 'undefined' ||
        props.tabDataCache['Failures'] === null
      ) {
        getFailures();
      } else {
        setPanelSectionData(props.tabDataCache['Failures']);
      }
    }
  }, [panelSectionData]);

  const event__getErrorMessages = async newDataArray => {
    /* get error message text from numeric error code*/

    for (let fa_index = 0; fa_index < newDataArray.length; fa_index++) {
      const errorMessageFromDynamo = await API.graphql(
        graphqlOperation(queries.getChatbotErrorMessages, {
          errorCode: newDataArray[fa_index].errorCode,
        })
      );

      if (errorMessageFromDynamo.data.getChatbotErrorMessages !== null) {
        //update panelSectionData array
        newDataArray[fa_index].errorCode +=
          ' ' +
          errorMessageFromDynamo.data.getChatbotErrorMessages.errorMessage;
      }
    }

    setPanelSectionData(newDataArray);

    //update tabDataCache
    let newTabDataCache = Object.assign({}, props.tabDataCache);
    newTabDataCache['Failures'] = newDataArray;
    props.setTabDataCache(newTabDataCache);
  };

  /*const lib__buildOptOutData = (panelSectionData) => {
    return panelSectionData.filter((object) => {
      if(object.errorCode.includes("21610"))
      {
        return true;
      }
    }).map((object) => {
      //object.errorCode = "";
      return object;
    });
  }*/

  const lib__buildCSVData = panelSectionData => {
    let csvData = [['phone', 'date']];

    if (
      typeof panelSectionData !== 'undefined' &&
      panelSectionData.length > 0 &&
      typeof panelSectionData[0].displayName !== 'undefined'
    ) {
      csvData[0].push('displayName');
    }

    if (
      typeof panelSectionData !== 'undefined' &&
      panelSectionData.length > 0 &&
      typeof panelSectionData[0].crmId !== 'undefined'
    ) {
      csvData[0].push('crmId');
    }

    panelSectionData.forEach(object => {
      if (csvData[0].includes('displayName') && csvData[0].includes('crmId')) {
        csvData.push([
          object.phone,
          object.date,
          object.displayName,
          object.crmId,
        ]);
      } else if (csvData[0].includes('displayName')) {
        csvData.push([object.phone, object.date, object.displayName]);
      } else {
        csvData.push([object.phone, object.date]);
      }
    });

    return csvData;
  };

  const event__refreshData = () => {
    //update tabDataCache
    let newTabDataCache = Object.assign({}, props.tabDataCache);
    newTabDataCache['Failures'] = null;
    props.setTabDataCache(newTabDataCache);

    setPanelSectionData(null);
  };

  return (
    <div className="panelSection__title">
      {props.panelTitle}
      {panelSectionData === null ? (
        typeof props.tabDataCache['Failures'] === 'undefined' ||
        props.tabDataCache['Failures'] === null ? (
          <ThreeSquare />
        ) : null
      ) : (
        <>
          <div style={{ position: 'absolute', top: '0px', right: '9.5em' }}>
            <PanelButton
              type="refresh"
              label="Refresh"
              onClick={event__refreshData}
            />
          </div>
          <div style={{ position: 'absolute', top: '0px', right: '1.2em' }}>
            <PanelButton
              type="csv"
              csvData={lib__buildCSVData(
                JSON.parse(JSON.stringify(panelSectionData))
                  .filter(object => {
                    if (
                      object.errorCode.includes('21610') ||
                      object.errorCode.includes('278-0003')
                    ) {
                      return true;
                    }
                  })
                  .map(object => {
                    object.errorCode = '';
                    return object;
                  })
              )}
              csvFileName={`${
                props.editInfo && props.editInfo.title
                  ? props.editInfo.title
                  : 'chatbot'
              }-campaignOptOuts-${moment().format()}.csv`}
              label="Export Data"
            />
          </div>
          <div className="panelHeader">
            Campaign Opt-Outs:{' '}
            {
              JSON.parse(JSON.stringify(panelSectionData))
                .filter(object => {
                  if (
                    object.errorCode.includes('21610') ||
                    object.errorCode.includes('278-0003')
                  ) {
                    return true;
                  }
                })
                .map(object => {
                  object.errorCode = '';
                  return object;
                }).length
            }
          </div>
          <MiniTable
            tableData={JSON.parse(JSON.stringify(panelSectionData))
              .filter(object => {
                if (
                  object.errorCode.includes('21610') ||
                  object.errorCode.includes('278-0003')
                ) {
                  return true;
                }
              })
              .map(object => {
                object.errorCode = '';
                return object;
              })}
            columnTitles={['Phone', 'Date', '']}
            dataKeys={['phone', 'date', '']}
          />
        </>
      )}
    </div>
  );
}
