import React, { useState, useEffect, useContext } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from 'graphql/mutations';
import { Redirect } from 'react-router-dom';

import AuthContext from 'contexts/AuthContext';

// import { useModal } from "hooks";
import { Modal, ModalContent } from 'stories';

var watchSession = null;
var expireSession = null;

export default function ExtendSession(props) {
  const type = props.sessionType ? props.sessionType : 'updateKnowledgeBase';

  const kickOutTimes = {
    updateKnowledgeBase: {
      warn: 1000 * 60 * 3,
      kickOut: 1000 * 60 * 4,
    },
    updateFlow: {
      warn: 1000 * 60 * 13,
      kickOut: 1000 * 60 * 14,
    },
  };
  const tokens = useContext(AuthContext);

  const [sendBack, setSendBack] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const toggle = () => {
    setShowModal(!showModal);
  };

  const setEdit = editing => {
    let person = {
      asurite: tokens.asurite,
      timestamp: new Date().getTime().toString(),
    };

    let payload = {
      id: props.id,
      editing: editing ? person : null,
    };
    API.graphql(
      graphqlOperation(mutations[type], {
        input: payload,
      })
    );

    if (editing === false) setSendBack(true);
  };

  const extendSubmit = () => {
    setShowModal(false);
    setEdit(true);
    setSendBack(false);
    setSession();
  };

  const forceOut = () => {
    setShowModal(false);
    setEdit(false);
  };

  const setSession = () => {
    clearTimeout(watchSession);
    clearTimeout(expireSession);

    console.log(kickOutTimes[type]);

    setTimeout(() => {
      // Warn after 3 minutes
      watchSession = setTimeout(() => {
        console.log('WARNING');
        setShowModal(true);
      }, kickOutTimes[type].warn);

      // Kick out after 4
      expireSession = setTimeout(() => {
        console.log('FORCING');
        forceOut();
      }, kickOutTimes[type].kickOut);
    }, 50);
  };

  useEffect(() => {
    if (props.id) {
      setSession();
    }

    return () => {
      watchSession = null;
      expireSession = null;
    };
  }, [props.id]);

  const getRedirectLink = () => {
    switch (type) {
      case 'updateKnowledgeBase':
        return '/knowledge-base';
      case 'updateFlow':
        return '/flows';
    }
  };

  if (sendBack) {
    return <Redirect to={getRedirectLink()} />;
  } else {
    return (
      <Modal
        isShowing={showModal}
        hide={toggle}
        content={
          <ModalContent
            confirmation
            title="Your edit session is about to expire, would you like to extend it?"
            onSubmit={extendSubmit}
            toggle={toggle}
          />
        }
      />
    );
  }
}
